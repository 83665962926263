import { Injectable ,EventEmitter} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchTriggerService {
  private dataSubject = new Subject<any>();

  constructor() { }
  setData(data: any) {
    console.log(data,"check data")
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }
}
