<div class="bnb-container">
    <p class="locationCopy-msg-container" *ngIf="showCoOrdsCopiedMsg && markedLocationCoOrds.length>0">
        Location Coordinates : <br>{{ markedLocationCoOrds }}
    </p>
    <div class="bnb-data-container" (resize)="onResize($event)" [id]="scrollDivId">
        <div [ngClass]="{'bnb-item layer-status': true, 'duplicate': currentUploadStatus==='DUPLICATE'}">
            <div class="progressbar" [ngStyle]="{'width': 170*(currentUploadProgress/100)+'px'}">
            </div>
            <div class="data" [ngStyle]="{'width': currentUploadProgress <= 0 ? '100%' : ''}">
                <span class="layer-name" title="Input Idle! Add data on geobar" style="color: black;"
                    *ngIf="currentUploadStatus!=='DUPLICATE' && currentUploadProgress <= 0">
                    <!-- No Activities -->
                    Input Idle! Add data on geobar
                </span>
                <span class="layer-name"
                    *ngIf="currentUploadStatus!=='DUPLICATE' && currentUploadProgress > 0">{{currentUploadLayerName}}</span>
                <span class="progress-num"
                    *ngIf="currentUploadStatus!=='DUPLICATE' && currentUploadProgress > 0 && currentUploadProgress < 100">
                    | Loading {{currentUploadProgress}}%</span>
                <span class="progress-num" *ngIf="currentUploadStatus!=='DUPLICATE' && currentUploadProgress >= 100"> |
                    Success!!!</span>
                <span class="progress-num" *ngIf="currentUploadStatus==='DUPLICATE'"> Duplicate layer found!!!</span>
            </div>
            <!-- <progress id="file" value="32" max="100"> 32% </progress> -->
        </div>
        <div class="bnb-item">View : <span class="value"> orthonormal (top) </span></div>
        <div class="bnb-item">Ref System : <span class="value"> {{currentReferenceSystem}} </span></div>
        <div class="bnb-item">Viewing Factor : <span class="value"> {{currentViewFactory}} </span></div>
        <div class="bnb-item">Base Canvas by : <span class="value"> {{activeBaseLayerName}} </span></div>
        <div class="bnb-item">North Rotated : <span class="value">{{northRotateValue}} degrees</span></div>
        <!-- <div class="bnb-item cursor-pos">Cursor position : 
            <span class="value" *ngIf="curserPosition.length>0">
                {{curserPosition}}
            </span>
            <span class="value" *ngIf="curserPosition.length<=0">
                Unknown
            </span>
        </div> -->
        <div class="bnb-item cursor-pos d-flex">
            <div>Cursor position :</div>
            <!-- <div><span class="value" *ngIf="curserPosition?.length>0">
                    {{curserPosition}},{{elevationVal}}
                </span></div> -->
                <div><span class="value" *ngIf="curserPosition?.length>0">
                    {{curserPosition}}
                </span></div>
            <span class="value" *ngIf="curserPosition?.length<=0">
                Unknown
            </span>
                    
        </div>
        <div class="bnb-item package-level">Package Level :
            <span class="value" *ngIf="isGuest">Play</span>
            <!-- <span class="value" *ngIf="!isGuest">Explore {{orgName}}</span> -->
            <span class="value" *ngIf="!isGuest">{{orgName}}</span>
        </div>
        <div [ngClass]="{'bnb-item': true, 'active': termsShown}" style="cursor: pointer;"
            (click)="showApplicationTerms($event, appInfoContent)"> Policies </div>
    </div>
    <div class="bnb-scroller-container">
        <div [hidden]="leftArrowHide" [ngClass]="{'backward-container':true, 'disabled':leftDisabled}"
            (click)="scrollLeft($event)">
            <img class="backward" [ngStyle]="{'color': leftDisabled?'#e8ecec':'#6c6e6e'}"
                src="/assets/svgs/geosol/left_orientation_white.svg" />
        </div>
        <div [hidden]="rightArrowHide" [ngClass]="{'forward-container':true, 'disabled':rightDisabled}"
            (click)="scrollRight($event)">
            <img class="forward" [ngStyle]="{'color': rightDisabled?'#e8ecec':'#6c6e6e'}"
                src="/assets/svgs/geosol/right_orientation_white.svg" />
        </div>
    </div>
</div>



<ng-template #appInfoContent let-c="close" let-d="dismiss">
    <div class="modal-header modalHeader">
        <h4 class="modal-title modalTitle" id="modal-basic-title">Policies</h4>
        <button type="button" class="close modalCloseButton" aria-label="Close"
            (click)="d('Cross click') ; tcAndPrivacyActivePage='tc';">
            <span aria-hidden="true" class="modalCloseSpan">&times;</span>
        </button>
    </div>
    <div class="modal-body modalBody">
        <div class="tc-privacy-head-container">
            <div [ngClass]="{'tc-head':true,'active': tcAndPrivacyActivePage=='tc' }"
                (click)="tcAndPrivacyActivePage='tc'">
                Terms & Conditions
            </div>
            <div [ngClass]="{'privacy-head':true,'active': tcAndPrivacyActivePage=='privacy' }"
                (click)="tcAndPrivacyActivePage='privacy'">
                Privacy Policy
            </div>
        </div>
        <!-- TERMS & CONDITIONS CONTENT START -->
        <div class="tc-privacy-body-container" *ngIf="tcAndPrivacyActivePage=='tc'">
            <div class="chapter-container">
                <div class="chapter-data-container">
                    <p>fuse.Earth is a product by Mapsol. If you have not otherwise executed a separate written
                        subscription agreement with us, then please read these Mapsol Terms and Conditions (this
                        “<b>Agreement</b>”) carefully because they govern your use of the fuse.Earth Subscription
                        Services and the website located at fuse.earth; (the “<b>Site</b>”). To make these Mapsol Terms
                        and Conditions easier to read, the terms “<b>Mapsol</b>,” “<b>we</b>,” and “<b>us</b>” refers to
                        Mapsol Geospatial Solutions, Inc., and the term “<b>you</b>” refers to you and any organization
                        that you are acting on behalf of. You and Mapsol may be referred to herein collectively as the
                        “<b>Parties</b>” or individually as a “<b>Party</b>”.</p>
                </div>
            </div>
            <div id="section_1" class="chapter-container">
                <p class="chapter-title">1. DEFINITIONS.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li>“<b>Authorized User</b>” means an employee or contractor that you authorize to Use the
                            Subscription Services.</li>
                        <li>“<b>Data Protection Claims</b>” means Mapsol’s breach of <a href="#section_7">Section
                                ‎7</a>, in each case, where such breach results in the unauthorized disclosure of Your
                            Data.</li>
                        <li>“<b>Documentation</b>” means the operator and user manuals, training materials,
                            specifications, minimum system configuration requirements, compatible device and hardware
                            list and other similar materials in hard copy or electronic form (including materials found
                            at https://mapsolgeo.com/index.html) if, as provided by Mapsol to you (including any revised
                            versions thereof), they relate to the Subscription Services, which may be updated by us from
                            time to time.</li>
                        <li>“<b>Intellectual Property Rights</b>” means patent rights (including patent applications and
                            disclosures), inventions, copyrights, trade secrets, know-how, data and database rights,
                            mask work rights, and any other intellectual property rights recognized in any country or
                            jurisdiction in the world.</li>
                        <li>“<b>Licensed Volume</b>” means the limits, volume or other measurement or conditions of
                            permitted Use for the applicable Subscription Service as may be set forth in the applicable
                            Documentation.</li>
                        <li>“<b>Person</b>” means any individual, corporation, partnership, trust, limited liability
                            company, association, governmental authority or other entity.</li>
                        <li>“<b>Platform Data</b>” means any data that is derived or aggregated in deidentified form
                            from (i) Your Data; or (ii) your and/or your Authorized Users’ use of the Subscription
                            Services, including any hardware metrics, software event logs, globally unique identifiers
                            for files, usage data or trends with respect to the Subscription Services.</li>
                        <li>“<b>Mapsol IP</b>” means the Subscription Services, the underlying software provided in
                            conjunction with the Subscription Services, algorithms, interfaces, technology, databases,
                            tools, know-how, processes and methods used to provide or deliver the Subscription Services,
                            Documentation and Platform Data, all improvements, modifications or enhancements to, or
                            derivative works of, the foregoing (regardless of inventorship or authorship), and all
                            Intellectual Property Rights in and to any of the foregoing.</li>
                        <li>“<b>Subscription Services</b>” means Mapsol’s cloud-based platform that provides mapping
                            technology and functionality through five primary components: Geobar, Geotower, Geotray,
                            Geosole and Geoframe, and any updates, patches, bug fixes and upgrades that Mapsol elects to
                            provide to you.</li>
                        <li>“<b>Use</b>” means to use and/or access the Subscription Services in accordance with this
                            Agreement and the Documentation.</li>
                        <li>“<b>Your Data</b>” means all information, data, content and other materials, in any form or
                            medium, that is submitted, posted, collected, transmitted or otherwise provided by or on
                            behalf of you through the Subscription Services or to us in connection with your use of the
                            Subscription Services, but excluding, for clarity, Platform Data and any other information,
                            data, data models, content or materials owned or controlled by us and made available through
                            or in connection with the Subscription Services.</li>
                    </ol>
                </div>
            </div>
            <div class="chapter-container">
                <p class="chapter-title">2. AGREEMENT TO TERMS; CHANGES.</p>
                <div class="chapter-data-container">
                    <p>By using the Site or Subscription Services, as applicable, you agree to be bound by these Terms.
                        If you don’t agree to be bound by these Terms, do not use the Site or Subscription Services. If
                        you are accessing and using the Subscription Services on behalf of a company (such as your
                        employer) or other legal entity, you represent and warrant that you have the authority to bind
                        that entity to these Terms. In that case, “you” and “your” will refer to that entity. We may
                        update the Terms from time to time in our sole discretion. If we do, we’ll let you know by
                        posting the updated Terms on the Site and/or may also send other communications. It’s important
                        that you review the Terms whenever we update them or you use the Subscription Services. If you
                        continue to use the Subscription Services after we have posted updated Terms it means that you
                        accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use
                        the Subscription Services anymore. Because our Subscription Services are evolving over time we
                        may change or discontinue all or any part of the Subscription Services, at any time and without
                        notice, at our sole discretion.</p>
                </div>
            </div>
            <div class="chapter-container">
                <p class="chapter-title">3. PRIVACY POLICY.</p>
                <div class="chapter-data-container">
                    <p>Please review our Privacy Policy, currently available under the “Policies” link at fuse.earth
                        website, which also governs your user of the Site, for the information on how we collect, use
                        and share your information.</p>
                </div>
            </div>
            <div id="section_4" class="chapter-container">
                <p class="chapter-title">4. SUBSCRIPTION SERVICES; ACCESS AND USE.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li id="section_4_a"><span class="sub-header">Subscription Services</span>. Subject to the terms
                            and conditions of this Agreement, Mapsol hereby grants you a limited, non-exclusive,
                            non-transferable (except in compliance with <a href="#section_13_f">Section ‎13(f)</a>)
                            right to Use the Subscription Services during the Term, solely for your internal business
                            purposes in accordance with, and subject to, the Licensed Volume.</li>
                        <li id="section_4_b"><span class="sub-header">Use Restrictions</span>. You will not at any time
                            and will not permit any Person (including Authorized Users) to, directly or indirectly: (i)
                            use the Site or Subscription Services in any manner beyond the scope of rights expressly
                            granted in this Agreement; (ii) modify or create derivative works of the Site, Subscription
                            Services or Documentation, in whole or in part; (iii) reverse engineer, disassemble,
                            decompile, decode or otherwise attempt to derive or gain improper access to any software
                            component of the Subscription Services, in whole or in part; (iv) frame, mirror, sell,
                            resell, rent or lease use of the Site or Subscription Services to any other Person, or
                            otherwise allow any Person to use the Subscription Services for any purpose other than for
                            your benefit in accordance with this Agreement; (v) use the Site, Subscription Services or
                            Documentation in any manner or for any purpose that infringes, misappropriates, or otherwise
                            violates any Intellectual Property Right or other right of any Person, or that violates any
                            applicable law; (vi) interfere with, or disrupt the integrity or performance of, the
                            Subscription Services, or any data or content contained therein or transmitted thereby;
                            (vii) access or search the Subscription Services (or download any data or content contained
                            therein or transmitted thereby) through the use of any engine, software, tool, agent, device
                            or mechanism (including spiders, robots, crawlers or any other similar data mining tools)
                            other than software or Subscription Services features provided, or authorized, by Mapsol for
                            use expressly for such purposes; or (viii) use the Site, Subscription Services,
                            Documentation or any other Mapsol Confidential Information for benchmarking or competitive
                            analysis with respect to competitive or related products or services, or to develop,
                            commercialize, license or sell any product, service or technology that could, directly or
                            indirectly, compete with the Subscription Services.</li>
                        <li id="section_4_c"><span class="sub-header">Authorized Users</span>. You will not allow any
                            Person other than Authorized Users to Use the Subscription Services. You may permit
                            Authorized Users to Use the Subscription Services, <i>provided</i> that (i) the Use does not
                            exceed the Licensed Volume; and (ii) you ensure each Authorized User complies with all
                            applicable terms and conditions of this Agreement and you are responsible for acts or
                            omissions by Authorized Users in connection with their Use of the Subscription Services. You
                            will, and will require all Authorized Users to, use all reasonable means to secure user
                            names and passwords, hardware and software used to access the Subscription Services in
                            accordance with customary security protocols, and will promptly notify us if you know or
                            reasonably suspect that any user name and/or password has been compromised. Mapsol will not
                            be liable, and you will be solely responsible, for (A) any unauthorized access, damage or
                            loss that may occur through the use or misuse of your user names, passwords, hardware or
                            software; or (B) any activities that occur under any account issued to or created by you in
                            connection with your use of the Subscription Services, including any unauthorized access or
                            usage of any such account, in each case, except to the extent proven to have been directly
                            caused by Mapsol’s gross negligence, willful misconduct or fraud.</li>
                        <li><span class="sub-header">Third-Party Services</span>. Certain features and functionalities
                            within the Site and Subscription Services may allow you and your Authorized Users to
                            interface or interact with, access and/or use compatible third-party services, products,
                            technology and content (collectively, “<b>Third-Party Services</b>”). We do not provide any
                            aspect of the Third-Party Services and are not responsible for any compatibility issues,
                            errors or bugs in the Site, Subscription Services or Third-Party Services caused in whole or
                            in part by the Third-Party Services or any update or upgrade thereto. You are solely
                            responsible for maintaining the Third-Party Services and obtaining any associated licenses
                            and consents necessary for you to use the Third-Party Services in connection with the
                            Subscription Services. </li>
                        <li><span class="sub-header">Reservation of Rights</span>. Subject to the limited rights
                            expressly granted hereunder, Mapsol reserves and, as between the Parties will solely own,
                            the Mapsol IP and all rights, title and interest in and to the Mapsol IP. No rights are
                            granted to you hereunder (whether by implication, estoppel, exhaustion or otherwise) other
                            than as expressly set forth herein. </li>
                        <li id="section_4_f"><span class="sub-header">Feedback</span>. From time to time you or your
                            employees, contractors, or representatives may provide us with suggestions, comments,
                            feedback or the like with regard to the Site or Subscription Services (collectively,
                            “<b>Feedback</b>”). You hereby grant Mapsol a perpetual, irrevocable, royalty-free and
                            fully-paid-up license to use and exploit all Feedback in connection with Mapsol’s business
                            purposes, including the testing, development, maintenance and improvement of the Site and
                            Subscription Services.</li>
                    </ol>
                </div>
            </div>
            <div id="section_5" class="chapter-container">
                <p class="chapter-title">5. NO FEES.</p>
                <div class="chapter-data-container">
                    <p>Mapsol is offering this as a free “beta” program solely to enable you to evaluate the
                        Subscription Services for the purchase of a full-term subscription. We reserve the right to
                        charge a fee for use of the Subscription Services at any time.</p>
                </div>
            </div>
            <div id="section_6" class="chapter-container">
                <p class="chapter-title">6. CONFIDENTIAL INFORMATION.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li>As used herein, “<b>Confidential Information</b>” means any information that one Party (the
                            “<b>Disclosing Party</b>”) provides to the other Party (the “<b>Receiving Party</b>”) in
                            connection with this Agreement, whether orally or in writing, that is designated as
                            confidential or that reasonably should be considered to be confidential given the nature of
                            the information and/or the circumstances of disclosure. For clarity, the Mapsol IP will be
                            deemed Confidential Information of Mapsol. However, Confidential Information will not
                            include any information or materials that: (i) were, at the date of disclosure, or have
                            subsequently become, generally known or available to the public through no act or failure to
                            act by the Receiving Party; (ii) were rightfully known by the Receiving Party prior to
                            receiving such information or materials from the Disclosing Party; (iii) are rightfully
                            acquired by the Receiving Party from a third party who has the right to disclose such
                            information or materials without breach of any confidentiality or non-use obligation to the
                            Disclosing Party; or (iv) are independently developed by or for the Receiving Party without
                            use of or access to any Confidential Information of the Disclosing Party.</li>
                        <li>The Receiving Party will maintain the Disclosing Party’s Confidential Information in strict
                            confidence, and will not use the Confidential Information of the Disclosing Party except as
                            necessary to perform its obligations or exercise its rights under this Agreement;
                            <i>provided</i> that Mapsol may use and modify your Confidential Information in deidentified
                            form for purposes of developing and deriving Platform Data. The Receiving Party will not
                            disclose or cause to be disclosed any Confidential Information of the Disclosing Party,
                            except (i) to those employees, representatives, or contractors of the Receiving Party who
                            have a bona fide need to know such Confidential Information to perform under this Agreement
                            and who are bound by written agreements with use and nondisclosure restrictions at least as
                            protective as those set forth in this Agreement, or (ii) as such disclosure may be required
                            by the order or requirement of a court, administrative agency or other governmental body,
                            subject to the Receiving Party providing to the Disclosing Party reasonable written notice
                            to allow the Disclosing Party to seek a protective order or otherwise contest the
                            disclosure. </li>
                        <li>Each Party’s obligations of non-disclosure with regard to Confidential Information are
                            effective as of the Effective Date and will expire five (5) years from the date first
                            disclosed to the Receiving Party; <i>provided</i>, however, with respect to any Confidential
                            Information that constitutes a trade secret (as determined under applicable law), such
                            obligations of non-disclosure will survive the termination or expiration of this Agreement
                            for as long as such Confidential Information remains subject to trade secret protection
                            under applicable law.</li>
                        <li>The terms and conditions of this Agreement will constitute Confidential Information of each
                            Party but may be disclosed on a confidential basis to a Party’s advisors, attorneys, actual
                            or bona fide potential acquirers, investors or other sources of funding (and their
                            respective advisors and attorneys) for due diligence purposes. </li>
                    </ol>
                </div>
            </div>
            <div id="section_7" class="chapter-container">
                <p class="chapter-title">7. YOUR MATERIALS AND DATA.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li>Mapsol acknowledges that, as between you and Mapsol and except as set forth in <a
                                href="#section_7_b">Section ‎7(b)</a>, you own and retain all right, title and interest
                            in and to all Your Data.</li>
                        <li id="section_7_b">You hereby grant Mapsol a non-exclusive, worldwide, royalty-free right and
                            license to use, host, reproduce, display, perform, modify Your Data solely for the purpose
                            of hosting, operating, improving and providing the Site and Subscription Services and
                            Mapsol’s other related products, services and technologies during the Term or as otherwise
                            required by applicable law.</li>
                        <li>You represent and warrant that you have obtained and will obtain and continue to have,
                            during the Term, all necessary rights, authority and licenses for the access to and use of
                            Your Data (including any personal data provided or otherwise collected pursuant to Mapsol’s
                            privacy policy) as contemplated by this Agreement. </li>
                        <li>You represent and warrant that Mapsol’s use of Your Data in accordance with this Agreement
                            will not violate any applicable laws or regulations (including but not limited to privacy
                            laws, HIPAA, and ITAR) or cause a breach of any agreement or obligations between you and any
                            third party.</li>
                    </ol>
                </div>
            </div>
            <div id="section_8" class="chapter-container">
                <p class="chapter-title">8. REPRESENTATIONS AND WARRANTIES.</p>
                <div class="chapter-data-container">
                    <p>Each Party hereby represents and warrants to the other Party as of the Effective Date that it is
                        duly organized, validly existing and in good standing under its jurisdiction of organization and
                        has the right to enter into this Agreement.</p>
                </div>
            </div>
            <div id="section_9" class="chapter-container">
                <p class="chapter-title">9. INDEMNIFICATION.</p>
                <div class="chapter-data-container">
                    <p>You will defend Mapsol against Claims arising from (i) Your Data, including (A) any Claim that
                        Your Data infringes, misappropriates or otherwise violates any third party’s Intellectual
                        Property Rights or privacy or other rights; or (B) any Claim that the use, provision,
                        transmission, display or storage of Your Data violates any applicable law, rule or regulation;
                        (ii) any of your products or services; and (iii) Use of the Site or Subscription Services by you
                        or your Authorized Users in a manner that is not in accordance with this Agreement or the
                        Documentation and in each case, will indemnify and hold harmless Mapsol against any damages and
                        costs awarded against Mapsol or agreed in settlement by you (including reasonable attorneys’
                        fees) resulting from such Claim. Mapsol reserves the right, at your expense, to assume the
                        exclusive defense and control of any Claim and you shall cooperate with Mapsol’s defense of such
                        Claim. You may not settle any Claim or admit any liability on behalf of Mapsol without the prior
                        written consent of Mapsol. Mapsol will use reasonable efforts to notify you of any such Claim.
                    </p>
                </div>
            </div>
            <div id="section_10" class="chapter-container">
                <p class="chapter-title">10. WARRANTY; DISCLAIMER.</p>
                <div class="chapter-data-container">
                    <p>THE SITE, SUBSCRIPTION SERVICES, OTHER MAPSOL IP, AND ANYTHING ELSE PROVIDED BY MAPSOL, ITS
                        VENDORS AND LICENSORS, AS APPLICABLE, ARE PROVIDED ON AN “AS IS” BASIS, AND MAPSOL MAKES NO
                        WARRANTIES OR REPRESENTATIONS TO YOU, YOUR AUTHORIZED USERS OR TO ANY OTHER PARTY REGARDING THE
                        SITE, MAPSOL IP, THE SUBSCRIPTION SERVICES OR ANY OTHER SERVICES OR MATERIALS PROVIDED
                        HEREUNDER. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MAPSOL HEREBY DISCLAIMS ALL
                        WARRANTIES AND REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, AND
                        ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. MAPSOL HEREBY DISCLAIMS ANY
                        WARRANTY THAT USE OF THE SITE, SUBSCRIPTION SERVICES OR OTHER MAPSOL IP WILL BE ERROR-FREE,
                        BUG-FREE OR UNINTERRUPTED.</p>
                </div>
            </div>
            <div id="section_11" class="chapter-container">
                <p class="chapter-title">11. LIMITATIONS OF LIABILITY.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li><span class="sub-header">Exclusion of Damages</span>. IN NO EVENT WILL MAPSOL BE LIABLE TO
                            YOU FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR ANY LOSS
                            OF INCOME, DATA, PROFITS, REVENUE OR BUSINESS INTERRUPTION, OR THE COST OF COVER OR
                            SUBSTITUTE SERVICES, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, WHETHER SUCH
                            LIABILITY ARISES FROM ANY CLAIM BASED ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE),
                            STRICT LIABILITY OR OTHERWISE, AND WHETHER OR MAPSOL WAS ADVISED OF THE POSSIBILITY OF SUCH
                            LOSS OR DAMAGE.</li>
                        <li><span class="sub-header">General Liability Cap</span>. IN NO EVENT WILL MAPSOL’S TOTAL
                            LIABILITY TO YOU IN CONNECTION WITH THIS AGREEMENT, EXCEED $100.00, REGARDLESS OF THE LEGAL
                            OR EQUITABLE THEORY ON WHICH THE CLAIM OR LIABILITY IS BASED, AND WHETHER OR NOT MAPSOL WAS
                            ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. THE FOREGOING LIMITATIONS WILL APPLY EVEN
                            IF ANY STATED REMEDY HEREUNDER FAILS OF ITS ESSENTIAL PURPOSE.</li>
                    </ol>
                </div>
            </div>
            <div id="section_12" class="chapter-container">
                <p class="chapter-title">12. TERM AND TERMINATION.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li><span class="sub-header">Term</span>. The initial term of this Agreement begins on the
                            Effective Date and continues until this Agreement is terminated in accordance with this <a
                                href="#section_12">Section ‎12</a>. </li>
                        <li><span class="sub-header">Termination for Convenience</span>. Either party may terminate this
                            Agreement for convenience upon ten (10) days’ written notice to the other party. </li>
                        <li><span class="sub-header">Termination for Breach</span>. Either Party may terminate this
                            Agreement, effective on written notice to the other Party, if the other Party materially
                            breaches this Agreement, and such breach remains uncured five (5) days after the
                            non-breaching Party provides the breaching Party with written notice of such breach.</li>
                        <li><span class="sub-header">Suspension</span>. Mapsol may suspend or otherwise terminate this
                            Agreement or your access to the Subscription Services in the event it determines or believes
                            in its discretion that continuing to provide the Subscription Services may result in harm to
                            Mapsol, or that there is unauthorized access or misuse of the Subscription Services or
                            Mapsol IP, including any breach of the license restrictions set forth in <a
                                href="#section_4_b">Section ‎4(b)</a>. </li>
                        <li><span class="sub-header">Survival</span>. This Section ‎12(e) and Sections <a
                                href="#section_1">‎1</a>, ‎<a href="#section_4_b">4(b)</a>, ‎<a
                                href="#section_4_c">4(c)</a>, <a href="#section_4_f">‎4(f)</a>, <a
                                href="#section_5">‎5</a>, <a href="#section_6">‎6</a>, <a href="#section_7">‎7</a>, <a
                                href="#section_8">‎8</a>, <a href="#section_9">‎9</a>, <a href="#section_10">‎10</a>,
                            ‎<a href="#section_11">11</a>, ‎<a href="#section_12_f">12(f)</a>, ‎and ‎<a
                                href="#section_13">13</a> survive any termination or expiration of this Agreement. </li>
                        <li id="section_12_f"><span class="sub-header">Effect of Termination</span>. Upon expiration or
                            termination of this Agreement: (i) the rights granted pursuant to <a
                                href="#section_4_a">Section ‎4(a)</a> will terminate; and (ii) you will return or
                            destroy, at Mapsol’s sole option, all Mapsol Confidential Information in your possession or
                            control, including permanent removal of such Mapsol Confidential Information (consistent
                            with customary industry practice for data destruction) from any storage devices or other
                            hosting environments that are in your possession or under your control, and at Mapsol’s
                            request, certify in writing to Mapsol that the Mapsol Confidential Information has been
                            returned, destroyed or, in the case of electronic communications, deleted.</li>
                    </ol>
                </div>
            </div>
            <div id="section_13" class="chapter-container">
                <p class="chapter-title">13. GENERAL.</p>
                <div class="chapter-data-container">
                    <ol type="a">
                        <li><span class="sub-header">Entire Agreement</span>. This Agreement is the complete and
                            exclusive agreement between the Parties with respect to its subject matter and supersedes
                            all prior or contemporaneous agreements, communications and understandings, both written and
                            oral, with respect to its subject matter. This Agreement may be amended or modified only by
                            a written document executed by duly authorized representatives of the Parties. </li>
                        <li><span class="sub-header">Notices</span>. All notices required or permitted under this
                            Agreement will be in writing, will reference this Agreement, and will be sent, if to Mapsol,
                            to <a href="mailto:info@mapsol.com" target="_blank">info@mapsol.com</a> or, if to you, to
                            the then-current contact information provided by or on behalf of you to Mapsol. </li>
                        <li><span class="sub-header">Waiver</span>. Either Party’s failure to enforce any provision of
                            this Agreement will not constitute a waiver of future enforcement of that or any other
                            provision. No waiver of any provision of this Agreement will be effective unless it is in
                            writing and signed by the Party granting the waiver.</li>
                        <li><span class="sub-header">Severability</span>. If any provision of is held invalid, illegal
                            or unenforceable, that provision will be enforced to the maximum extent permitted by law,
                            given the fundamental intentions of the Parties, and the remaining provisions of will remain
                            in full force and effect. </li>
                        <li><span class="sub-header">Governing Law; Jurisdiction</span>. This Agreement will be governed
                            by and construed in accordance with the laws of the State of California without giving
                            effect to any principles of conflict of laws that would lead to the application of the laws
                            of another jurisdiction. The Parties expressly agree that the United Nations Convention on
                            Contracts for the International Sale of Goods will not apply. Any legal action or proceeding
                            arising under this Agreement will be brought exclusively in the federal or state courts
                            located in Riverside County, California and the Parties irrevocably consent to the personal
                            jurisdiction and venue therein.</li>
                        <li id="section_13_f"><span class="sub-header">Assignment</span>. You may not assign or transfer
                            this Agreement, by operation of law or otherwise, without Mapsol’s prior written consent.
                            Any attempt to assign or transfer this Agreement without such consent will be void. Mapsol
                            may freely assign or transfer this Agreement, by operation of law or otherwise. Subject to
                            the foregoing, this Agreement is binding upon and will inure to the benefit of each of the
                            Parties and their respective successors and permitted assigns.</li>
                        <li><span class="sub-header">Equitable Relief</span>. You acknowledge and agree that a breach or
                            threatened breach by you of any of your obligations under <a href="#section_6">Section
                                ‎6</a> or <a href="#section_4_b">Section ‎4(b)</a>, would cause Mapsol irreparable harm
                            and significant damages for which there may be no adequate remedy under law and that, in the
                            event of such breach or threatened breach, Mapsol will have the right to seek immediate
                            equitable relief, including a restraining order, an injunction, specific performance and any
                            other relief that may be available from any court. Such remedies are not exclusive and are
                            in addition to all other remedies that may be available at law, in equity or otherwise.</li>
                        <li><span class="sub-header">Force Majeure</span>. Mapsol will not be responsible for any
                            failure or delay in the performance of its obligations under this Agreement (except for any
                            payment obligations) due to causes beyond its reasonable control, which may include labor
                            disputes, strikes, lockouts, shortages of or inability to obtain energy, raw materials or
                            supplies, denial of service or other malicious attacks, telecommunications failure or
                            degradation, pandemics, epidemics, public health emergencies, governmental orders and acts
                            (including government-imposed travel restrictions and quarantines), material changes in law,
                            war, terrorism, riot, or acts of God.</li>
                        <li><span class="sub-header">Export Regulation</span>. You will comply with all applicable
                            federal laws, regulations and rules that prohibit or restrict the export or re-export of the
                            Subscription Services or software, or Your Data, outside the United States (“<b>Export
                                Rules</b>”), and will complete all undertakings required by Export Rules, including
                            obtaining any necessary export license or other governmental approval. </li>
                        <li><span class="sub-header">U.S. Government End Users</span>. The Subscription Services,
                            software and Documentation are “commercial computer software” and “commercial computer
                            software documentation,” respectively, as such terms are used in FAR 12.212 and other
                            relevant government procurement regulations. Any use, duplication, or disclosure of the
                            software or its documentation by or on behalf of the U.S. government is subject to
                            restrictions as set forth in this Agreement.</li>
                        <li><span class="sub-header">Relationship of the Parties</span>. The relationship between the
                            Parties is that of independent contractors. Nothing in this Agreement will be construed to
                            establish any partnership, joint venture or agency relationship between the Parties. Neither
                            Party will have the power or authority to bind the other or incur any obligations on the
                            other’s behalf without the other Party’s prior written consent.</li>
                        <li><span class="sub-header">No Third-Party Beneficiaries</span>. No provision of this Agreement
                            is intended to confer any rights, benefits, remedies, obligations, or liabilities hereunder
                            upon any Person other than the Parties and their respective successors and assigns.</li>
                        <li><span class="sub-header">Trademarks; Publicity</span>. You hereby grant to Mapsol a
                            non-exclusive license to use and display your logos, trademarks and other identifiers for
                            marketing or promotional purposes, including in communications with existing or potential
                            customers, as applicable, and in connection with case studies or other publications or
                            marketing materials publicizing your use of the Subscription Services.</li>
                    </ol>
                </div>
            </div>
            <!-- <div class="chapter-container">
                <p class="chapter-title"></p>
                <div class="chapter-data-container">

                </div>
            </div> -->
        </div>
        <!-- TERMS & CONDITIONS CONTENT END -->

        <!-- PRIVACY POLICY CONTENT START -->
        <div class="privacy-policy-body-container" *ngIf="tcAndPrivacyActivePage=='privacy'">
            <div class="chapter-container">
                <div class="chapter-data-container">
                    <p>Last Updated: [03/15/2021]</p>
                </div>
                <div class="chapter-data-container">
                    <p>fuse.earth is a product of Mapsol Geospatial Solutions, Inc. (“<b>Mapsol</b>,” “<b>we</b>,”
                        “<b>us</b>,” and “<b>our</b>”). Mapsol provides simple and efficient geospatial technologies to
                        allow our customers to readily integrate and transform layers of geospatial data into meaningful
                        information for quick and easy insight-driven decisions. This Privacy Policy is designed to help
                        you understand how we collect, use, and share your personal information and to help you
                        understand and exercise your privacy rights.</p>
                </div>
            </div>
            <div class="chapter-container">
                <div class="chapter-data-container">
                    <ol type="1" class="policy-chapter-link-continer">
                        <li><a href="#psection_1">SCOPE</a></li>
                        <li><a href="#psection_2">PERSONAL INFORMATION WE COLLECT</a></li>
                        <li><a href="#psection_3">HOW WE USE YOUR INFORMATION</a></li>
                        <li><a href="#psection_4">HOW WE DISCLOSE YOUR INFORMATION</a></li>
                        <li><a href="#psection_5">YOUR PRIVACY CHOICES AND RIGHTS</a></li>
                        <li><a href="#psection_6">SECURITY OF YOUR INFORMATION</a></li>
                        <li><a href="#psection_7">INTERNATIONAL DATA TRANSFERS</a></li>
                        <li><a href="#psection_8">RETENTION OF PERSONAL INFORMATION</a></li>
                        <li><a href="#psection_9">SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</a></li>
                        <li><a href="#psection_10">SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</a></li>
                        <li><a href="#psection_11">CHILDREN’S INFORMATION</a></li>
                        <li><a href="#psection_12">OTHER PROVISIONS</a></li>
                        <li><a href="#psection_13">CONTACT US</a></li>
                    </ol>
                </div>
            </div>

            <div id="psection_1" class="chapter-container">
                <p class="chapter-title">1. SCOPE</p>
                <div class="chapter-data-container">
                    <p>This Privacy Policy applies to personal information processed by us, including on our websites,
                        mobile applications, and other online or offline offerings, such as our cloud-based mapping
                        technology, fuse.Earth™. To make this Privacy Policy easier to read, our websites, mobile
                        applications, and other offerings are collectively called “<b>Services</b>.</p>
                    <p><b class="sub-header">An Important Note:</b> This Privacy Policy does not apply to any of the
                        personal information that our customers may collect and process using Mapsol’s Services
                        (“<b>Customer Data</b>”). Our customers’ respective privacy policies govern their collection and
                        use of Customer Data. Our processing of Customer Data is governed by the contracts that we have
                        in place with our customers, not this Privacy Policy. Any questions or requests relating to
                        Customer Data should be directed to our customer.</p>
                </div>
            </div>

            <div id="psection_2" class="chapter-container">
                <p class="chapter-title">2. PERSONAL INFORMATION WE COLLECT</p>
                <div class="chapter-data-container">
                    <p>The categories of personal information we collect depend on how you interact with us, our
                        Services, and the requirements of applicable law. We collect information that you provide to us,
                        information we obtain automatically when you use our Services, and information from other
                        sources such as third-party services and organizations, as described below.</p>

                    <p class="indent"><b>A. Information You Provide to Us Directly</b></p>
                    <p>We may collect the following personal information that you provide to us.</p>
                    <ul>
                        <li><b>Account Creation</b>. We may collect information when you create an account, such as name
                            and email address.</li>
                        <li><b>Platform Information</b>. We may collect information that you upload to the fuse.Earth™
                            platform such as GPS coordinates, addresses, and photos. Please note, we only use this
                            information in order to provide the Services. You retain rights to the information you
                            provide, in accordance with our Terms of Service.</li>
                        <li><b>Purchases</b>. We may collect personal information and details associated with your
                            purchases, including payment information. [Any payments made via our Services are processed
                            by third-party payment processors. We do not directly collect or store any payment card
                            information entered through our Services, but it may receive information associated with
                            your payment card information (e.g., your billing details).]</li>
                        <li><b>Your Communications with Us</b>. We may collect personal information, such as name and
                            email address, phone number, or mailing address when you request information about our
                            Services, request customer or technical support, apply for a job, or otherwise communicate
                            with us.</li>
                        <li><b>Surveys</b>. We may contact you to participate in surveys. If you decide to participate,
                            you may be asked to provide certain information which may include personal information.</li>
                        <li><b>Interactive Features</b>. We and others who use our Services may collect personal
                            information that you submit or make available through our interactive features (e.g.,
                            forums, blogs, and social media pages). Any information you provide using the public sharing
                            features of the Services (referred to herein as “<b>Public Content</b>”) will be considered
                            “public,” unless otherwise required by applicable law, and is not subject to the privacy
                            protections referenced herein. </li>
                        <li><b>Business Development and Strategic Partnerships</b>. We may collect personal information
                            from individuals and third parties to assess and pursue potential business opportunities.
                        </li>
                        <li><b>Job Applications</b>. We may post job openings and opportunities on our Services. If you
                            reply to one of these postings by submitting your application, CV and/or cover letter to us,
                            we will collect and use your information to assess your qualifications.</li>
                    </ul>
                    <p class="indent"><b>B. Information Collected Automatically</b></p>
                    <p>We may collect personal information automatically when you use our Services:</p>

                    <ul>
                        <li><b>Automatic Data Collection</b>. We may collect certain information automatically when you
                            use our Services, such as your Internet protocol (IP) address, user settings, MAC address,
                            cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, browser
                            or device information, location information (including approximate location derived from IP
                            address), and Internet service provider. We may also automatically collect information
                            regarding your use of our Services, such as pages that you visit before, during and after
                            using our Services, information about the links you click, the types of content you interact
                            with, the frequency and duration of your activities, and other information about how you use
                            our Services. </li>
                        <li><b>Location Information</b>. We may collect precise location information, such as GPS
                            coordinates when you use our fuse.Earth™ platform.</li>
                        <li>
                            <b>Cookies, Pixel Tags/Web Beacons, and Other Technologies</b>. We, as well as third parties
                            that provide content, advertising, or other functionality on our Services, may use cookies,
                            pixel tags, local storage, and other technologies (“<b>Technologies</b>”) to automatically
                            collect information through your use of our Services.
                            <ul>
                                <li><b>Cookies</b>. Cookies are small text files placed in device browsers that store
                                    preferences and facilitate and enhance your experience.</li>
                                <li><b>Pixel Tags/Web Beacons</b>. A pixel tag (also known as a web beacon) is a piece
                                    of code embedded in our Services that collects information about engagement on our
                                    Services. The use of a pixel tag allows us to record, for example, that a user has
                                    visited a particular web page or clicked on a particular advertisement. We may also
                                    include web beacons in e-mails to understand whether messages have been opened,
                                    acted on, or forwarded.</li>
                            </ul>
                            <p>Our uses of these Technologies fall into the following general categories: </p>
                            <ul>
                                <li><b>Operationally Necessary</b>. This includes Technologies that allow you access to
                                    our Services, applications, and tools that are required to identify irregular
                                    website behavior, prevent fraudulent activity and improve security or that allow you
                                    to make use of our functionality;</li>
                                <li><b>Performance-Related</b>. We may use Technologies to assess the performance of our
                                    Services, including as part of our analytic practices to help us understand how
                                    individuals use our Services (see Analytics below);</li>
                                <li><b>Functionality-Related</b>. We may use Technologies that allow us to offer you
                                    enhanced functionality when accessing or using our Services. This may include
                                    identifying you when you sign into our Services or keeping track of your specified
                                    preferences, interests, or past items viewed;</li>
                                <li><b>Advertising- or Targeting-Related</b>. We may use first party or third-party
                                    Technologies to deliver content, including ads relevant to your interests, on our
                                    Services or on third-party websites.</li>
                            </ul>
                            <p>See “<a href="#psection_5">Your Privacy Choices and Rights</a>” below to understand your
                                choices regarding these Technologies.</p>
                        </li>

                        <li><b>Analytics</b>. We may use Technologies and other third-party tools to process analytics
                            information on our Services, such as Google Analytics. For more information, please visit <a
                                href="http://www.google.com/policies/privacy/partners/" target="_blank">Google
                                Analytics’ Privacy Policy</a>. To learn more about how to opt-out of Google Analytics’
                            use of your information, please click <a href="http://tools.google.com/dlpage/gaoptout"
                                target="_blank">here</a>.</li>
                        <li><b>Social Media Platforms</b>. Our Services may contain social media buttons such as Twitter
                            and LinkedIn (that might include widgets such as the “share this” button or other
                            interactive mini programs). These features may collect your IP address, which page you are
                            visiting on our Services, and may set a cookie to enable the feature to function properly.
                            Your interactions with these platforms are governed by the privacy policy of the company
                            providing it.</li>
                    </ul>
                    <p class="indent"><b>C. Information Collected from Other Sources</b></p>
                    <p>We may obtain information about you from other sources, including through third-party services
                        and organizations. For example, if you access our Services through a third-party application,
                        such as an app store, a third-party login service, or a social networking site, we may collect
                        information about you from that third-party application that you have made available via your
                        privacy settings. </p>
                </div>
            </div>

            <div id="psection_3" class="chapter-container">
                <p class="chapter-title">3. HOW WE USE YOUR INFORMATION</p>
                <div class="chapter-data-container">
                    <p>We use your information for a variety of business purposes, including to provide our Services,
                        for administrative purposes, and to market our products and Services, as described below.</p>
                    <p class="indent"><b>A. Provide Our Services</b>
                    <p>We use your information to fulfil our contract with you and provide you with our Services, such
                        as:</p>
                    <ul>
                        <li>Managing your information and accounts;</li>
                        <li>Providing access to certain areas, functionalities, and features of our Services;</li>
                        <li>Answering requests for customer or technical support; </li>
                        <li>Communicating with you about your account, activities on our Services, and policy changes;
                        </li>
                        <li>Processing your financial information and other payment methods for products or Services
                            purchased;</li>
                        <li>Processing applications if you apply for a job we post on our Services; and</li>
                        <li>Allowing you to register for events.</li>
                    </ul>

                    <p class="indent"><b>B. Administrative Purposes</b>
                    <p>We use your information for various administrative purposes, such as:</p>
                    <ul>
                        <li>Pursuing our legitimate interests such as direct marketing, research and development
                            (including marketing research), network and information security, and fraud prevention;</li>
                        <li>Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal
                            activity, and prosecuting those responsible for that activity;</li>
                        <li>Measuring interest and engagement in our Services; </li>
                        <li>Improving, upgrading or enhancing our Services; </li>
                        <li>Developing new products and Services;</li>
                        <li>Ensuring internal quality control and safety;</li>
                        <li>Authenticating and verifying individual identities, including requests to exercise your
                            rights under this policy;</li>
                        <li>Debugging to identify and repair errors with our Services;</li>
                        <li>Auditing relating to interactions, transactions and other compliance activities;</li>
                        <li>Enforcing our agreements and policies; and</li>
                        <li>Complying with our legal obligations.</li>
                    </ul>

                    <p class="indent"><b>C. Marketing and Advertising our Products and Services</b>
                    <p>We may use personal information to tailor and provide you with content and advertisements. We may
                        provide you with these materials as permitted by applicable law. </p>
                    <p>Some of the ways we market to you include email campaigns.</p>
                    <p>If you have any questions about our marketing practices or if you would like to opt out of the
                        use of your personal information for marketing purposes, you may contact us at any time as set
                        forth in “<a href="#psection_13">Contact Us</a>” below.</p>

                    <p class="indent"><b>D. Other Purposes</b>
                    <p>We also use your information for other purposes as requested by you or as permitted by applicable
                        law.</p>
                    <ul>
                        <li><b>Consent</b>. We may use personal information for other purposes that are clearly
                            disclosed to you at the time you provide personal information or with your consent.</li>
                        <li><b>De-identified and Aggregated Information</b>. We may use personal information and other
                            information about you to create de-identified and/or aggregated information, such as
                            de-identified demographic information, de-identified location information, information about
                            the device from which you access our Services, or other analyses we create.</li>
                    </ul>
                </div>
            </div>

            <div id="psection_4" class="chapter-container">
                <p class="chapter-title">4. HOW WE DISCLOSE YOUR INFORMATION</p>
                <div class="chapter-data-container">
                    <p>We disclose your information to third parties for a variety of business purposes, including to
                        provide our Services, to protect us or others, or in the event of a major business transaction
                        such as a merger, sale, or asset transfer, as described below. </p>
                    <p class="indent"><b>A. Disclosures to Provide our Services</b></p>
                    <p>The categories of third parties with whom we may share your information are described below. </p>
                    <ul>
                        <li><b>Service Providers</b>. We may share your personal information with our third-party
                            service providers who use that information to help us provide our Services. This includes
                            service providers that provide us with IT support, hosting, payment processing, customer
                            service, and related services.</li>
                        <li><b>Business Partners</b>. We may share your personal information with business partners to
                            provide you with a product or service you have requested. We may also share your personal
                            information to business partners with whom we jointly offer products or services.</li>
                        <li><b>Affiliates</b>. We may share your personal information with our company affiliates. </li>
                        <li><b>Other Users/Website Visitors</b>. As described above in “<a href="#psection_2">Personal
                                Information We Collect</a>,” our Services allow you to share your Public Content with
                            other users/publicly, including to those who do not use our Services. </li>
                        <li><b>APIs/SDKs</b>. We may use third-party Application Program Interfaces (“APIs”) and
                            Software Development Kits (“SDKs”) as part of the functionality of our Services. For more
                            information about our use of APIs and SDKs, please contact us as set forth in “<a
                                href="#psection_13">Contact Us</a>” below.</li>
                    </ul>
                    <p class="indent"><b>B. Disclosures to Protect Us or Others</b></p>
                    <p>We may access, preserve, and disclose any information we store associated with you to external
                        parties if we, in good faith, believe doing so is required or appropriate to: comply with law
                        enforcement or national security requests and legal process, such as a court order or subpoena;
                        protect your, our, or others’ rights, property, or safety; enforce our policies or contracts;
                        collect amounts owed to us; or assist with an investigation or prosecution of suspected or
                        actual illegal activity.</p>
                    <p class="indent"><b>C. Disclosure in the Event of Merger, Sale, or Other Asset Transfers</b></p>
                    <p>If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy,
                        receivership, purchase or sale of assets, or transition of service to another provider, your
                        information may be sold or transferred as part of such a transaction, as permitted by law and/or
                        contract.</p>


                </div>
            </div>

            <div id="psection_5" class="chapter-container">
                <p class="chapter-title">5. YOUR PRIVACY CHOICES AND RIGHTS</p>
                <div class="chapter-data-container">
                    <p><b>Your Privacy Choices</b>. The privacy choices you may have about your personal information are
                        determined by applicable law and are described below. </p>
                    <ul>
                        <li><b>Email and Telephone Communications</b>. If you receive an unwanted email from us, you can
                            use the unsubscribe link found at the bottom of the email to opt out of receiving future
                            emails. Note that you will continue to receive transaction-related emails regarding products
                            or Services you have requested. We may also send you certain non-promotional communications
                            regarding us and our Services, and you will not be able to opt out of those communications
                            (e.g., communications regarding our Services or updates to our Terms or this Privacy
                            Policy). </li>
                        <li><b>Mobile Devices</b>. We may send you push notifications through our mobile application.
                            You may opt out from receiving these push notifications by changing the settings on your
                            mobile device. With your consent, we may also collect precise location-based information via
                            our mobile application. You may opt out of this collection by changing the settings on your
                            mobile device.</li>
                        <li><b>“Do Not Track</b>.” Do Not Track (“DNT”) is a privacy preference that users can set in
                            certain web browsers. Please note that we do not respond to or honor DNT signals or similar
                            mechanisms transmitted by web browsers.</li>
                        <li><b>Cookies and Interest-Based Advertising</b>. You may stop or restrict the placement of
                            Technologies on your device or remove them by adjusting your preferences as your browser or
                            device permits. However, if you adjust your preferences, our Services may not work properly.
                            Please note that cookie-based opt-outs are not effective on mobile applications. However,
                            you may opt-out of personalized advertisements on some mobile applications by following the
                            instructions for <a
                                href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
                                target="_blank">Android</a>, <a href="https://support.apple.com/en-us/HT202074"
                                target="_blank">iOS</a> and <a href="https://www.networkadvertising.org/mobile-choice/"
                                target="_blank">others</a>.</li>
                        <p>The online advertising industry also provides websites from which you may opt out of
                            receiving targeted ads from data partners and other advertising partners that participate in
                            self-regulatory programs. You can access these and learn more about targeted advertising and
                            consumer choice and privacy by visiting the <a
                                href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank">Network
                                Advertising Initiative</a>, <a href="http://www.aboutads.info/choices/"
                                target="_blank">the Digital Advertising Alliance</a>, <a
                                href="https://www.youronlinechoices.eu/" target="_blank">the European Digital
                                Advertising Alliance</a>, and <a href="https://youradchoices.ca/choices/"
                                target="_blank">the Digital Advertising Alliance of Canada</a>. </p>
                        <p>Please note you must separately opt out in each browser and on each device.</p>
                    </ul>

                    <p><b>Your Privacy Rights</b>. In accordance with applicable law, you may have the right to:</p>
                    <ul>
                        <li><b>Access Personal Information</b> about you, including: (i) confirming whether we are
                            processing your personal information; (ii) obtaining access to or a copy of your personal
                            information; and (iii) receiving an electronic copy of personal information that you have
                            provided to us, or asking us to send that information to another company (the “right of data
                            portability”);</li>
                        <li><b>Request Correction</b> of your personal information where it is inaccurate or incomplete.
                            In some cases, we may provide self-service tools that enable you to update your personal
                            information;</li>
                        <li><b>Request Deletion</b> of your personal information; </li>
                        <li><b>Request Restriction of or Object to</b> our processing of your personal information; and
                        </li>
                        <li><b>Withdraw your Consent</b> to our processing of your personal information. </li>
                    </ul>
                    <p>If you would like to exercise any of these rights, please contact us as set forth in “<a
                            href="#psection_13">Contact Us</a>” below. We will process such requests in accordance with
                        applicable laws.</p>

                </div>
            </div>

            <div id="psection_6" class="chapter-container">
                <p class="chapter-title">6. SECURITY OF YOUR INFORMATION</p>
                <div class="chapter-data-container">
                    <p>We take steps to ensure that your information is treated securely and in accordance with this
                        Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the
                        security of any information you provide to us. We have taken appropriate safeguards to require
                        that your personal information will remain protected and require our third-party service
                        providers and partners to have appropriate safeguards as well. To the fullest extent permitted
                        by applicable law, we do not accept liability for unauthorized disclosure.</p>
                    <p>By using our Services or providing personal information to us, you agree that we may communicate
                        with you electronically regarding security, privacy, and administrative issues relating to your
                        use of our Services. If we learn of a security system’s breach, we may attempt to notify you
                        electronically by posting a notice on our Services, by mail or by sending an email to you.</p>
                </div>
            </div>

            <div id="psection_7" class="chapter-container">
                <p class="chapter-title">7. INTERNATIONAL DATA TRANSFERS </p>
                <div class="chapter-data-container">
                    <p>All information processed by us may be transferred, processed, and stored anywhere in the world,
                        including, but not limited to, the United States or other countries, which may have data
                        protection laws that are different from the laws where you live. We endeavor to safeguard your
                        information consistent with the requirements of applicable laws.</p>
                </div>
            </div>

            <div id="psection_8" class="chapter-container">
                <p class="chapter-title">8. RETENTION OF PERSONAL INFORMATION </p>
                <div class="chapter-data-container">
                    <p>We store the personal information we collect as described in this Privacy Policy for as long as
                        you use our Services or as necessary to fulfill the purpose(s) for which it was collected,
                        provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue
                        legitimate business purposes, enforce our agreements, and comply with applicable laws. </p>
                </div>
            </div>

            <div id="psection_9" class="chapter-container">
                <p class="chapter-title">9. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</p>
                <div class="chapter-data-container">
                    <p>This Supplemental California Privacy Notice only applies to our processing of personal
                        information that is subject to the California Consumer Privacy Act of 2018 (“CCPA”). The CCPA
                        provides California residents with the right to know what categories of personal information
                        Mapsol has collected about them and whether Mapsol disclosed that personal information for a
                        business purpose (e.g., to a service provider) in the preceding 12 months. California residents
                        can find this information below:</p>

                    <table>
                        <thead>
                            <th class="table-head">Category of Personal Information Collected by Mapsol</th>
                            <th class="table-head">Category of Third Parties Information is Disclosed to for a Business
                                Purpose</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="personal-info">
                                    <p><b>Identifiers.</b> </p>
                                    <p>A real name, postal address, unique personal identifier, online identifier,
                                        Internet Protocol address, email address, or other similar identifiers.</p>
                                </td>
                                <td class="third-party-info">
                                    <ul>
                                        <li>Service providers</li>
                                        <li>Other Users/Public (name)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="personal-info">
                                    <p><b>Personal information categories listed in the California Customer Records
                                            statute (Cal. Civ. Code § 1798.80(e))</b> </p>
                                    <p>A name, address, bank account number, credit card number, debit card number, or
                                        any other financial information.</p>
                                </td>
                                <td class="third-party-info">
                                    <ul>
                                        <li>Service providers</li>
                                        <li>Other Users/Public (name)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="personal-info">
                                    <p><b>Commercial information</b> </p>
                                    <p>Records of personal property, products or services purchased, obtained, or
                                        considered, or other purchasing or consuming histories or tendencies.</p>
                                </td>
                                <td class="third-party-info">
                                    <ul>
                                        <li>Service providers</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="personal-info">
                                    <p><b>Internet or other electronic network activity</b> </p>
                                    <p>Browsing history, search history, information on a consumer's interaction with an
                                        internet website, application, or advertisement.</p>
                                </td>
                                <td class="third-party-info">
                                    <ul>
                                        <li>Service providers</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="personal-info">
                                    <p><b>Geolocation data</b> </p>
                                    <p>Physical location or movements.</p>
                                </td>
                                <td class="third-party-info">
                                    <ul>
                                        <li>Service providers</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p>The categories of sources from which we collect personal information and our business and
                        commercial purposes for using personal information are set forth in “<a
                            href="#psection_2">Personal Information We Collect</a>” and “<a href="#psection_3">How We
                            Use Your Personal Information</a>” above, respectively. </p>
                    <p><b>“Sales” of Personal Information under the CCPA</b></p>
                    <p>For purposes of the CCPA, Mapsol does not “sell” personal information, nor do we have actual
                        knowledge of any “sale” of personal information of minors under 16 years of age.</p>
                    <p><b>Additional Privacy Rights for California Residents</b></p>
                    <p><b>Non-Discrimination</b>. California residents have the right not to receive discriminatory
                        treatment by us for the exercise of their rights conferred by the CCPA. </p>
                    <p><b>Authorized Agent</b>. Only you, or someone legally authorized to act on your behalf, may make
                        a verifiable consumer request related to your personal information. You may also make a
                        verifiable consumer request on behalf of your minor child. To designate an authorized agent,
                        please contact us as set forth in “<a href="#psection_13">Contact Us</a>” below and provide
                        written authorization signed by you and your designated agent.</p>
                    <p><b>Verification</b>. To protect your privacy, we will take steps the following steps to verify
                        your identity before fulfilling your request. When you make a request, we will ask you to
                        provide sufficient information that allows us to reasonably verify you are the person about whom
                        we collected personal information or an authorized representative, which may include asking you
                        to answer questions regarding your account and use of our Services.</p>
                    <p>If you are a California resident and would like to exercise any of your rights under the CCPA,
                        please contact us as set forth in “<a href="#psection_13">Contact Us</a>” below. We will process
                        such requests in accordance with applicable laws. </p>
                    <p><b>Accessibility</b>. This Privacy Policy uses industry-standard technologies and was developed
                        in line with the World Wide Web Consortium’s Web Content Accessibility Guidelines, version 2.1.
                        If you wish to print this policy, please do so from your web browser or by saving the page as a
                        PDF. </p>

                </div>
            </div>

            <div id="psection_10" class="chapter-container">
                <p class="chapter-title">10. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</p>
                <div class="chapter-data-container">
                    <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal
                        Information to third parties who intend to license or sell that Personal Information. You can
                        exercise this right by contacting us at hello@mapsolgeo.com with the subject line “Nevada Do Not
                        Sell Request” and providing us with your name and the email address associated with your
                        account. Please note that we do not currently sell your Personal Information as sales are
                        defined in Nevada Revised Statutes Chapter 603A. If you have any questions, please contact us as
                        set forth below.</p>
                </div>
            </div>

            <div id="psection_11" class="chapter-container">
                <p class="chapter-title">11. CHILDREN’S INFORMATION</p>
                <div class="chapter-data-container">
                    <p>The Services are not directed to children under 13 (or other age as required by local law), and
                        we do not knowingly collect personal information from children.</p>
                    <p>If you are a parent or guardian and believe your child has uploaded personal information to our
                        site without your consent, you may contact us as described in “<a href="#psection_13">Contact
                            Us</a>” below. If we become aware that a child has provided us with personal information in
                        violation of applicable law, we will delete any personal information we have collected, unless
                        we have a legal obligation to keep it.</p>
                </div>
            </div>

            <div id="psection_12" class="chapter-container">
                <p class="chapter-title">12. OTHER PROVISIONS</p>
                <div class="chapter-data-container">
                    <p><b>Third-Party Websites/Applications</b>. The Services may contain links to other
                        websites/applications and other websites/applications may reference or link to our Services.
                        These third-party services are not controlled by us. We encourage our users to read the privacy
                        policies of each website and application with which they interact. We do not endorse, screen or
                        approve, and are not responsible for, the privacy practices or content of such other websites or
                        applications. Providing personal information to third-party websites or applications is at your
                        own risk. </p>
                    <p><b>Supervisory Authority</b>. If you are located in the European Economic Area, Switzerland, the
                        United Kingdom or Brazil, you have the right to lodge a complaint with a supervisory authority
                        if you believe our processing of your personal information violates applicable law.</p>
                    <p><b>Changes to our Privacy Policy</b>. We may revise this Privacy Policy from time to time in our
                        sole discretion. If there are any material changes to this Privacy Policy, we will notify you as
                        required by applicable law. You understand and agree that you will be deemed to have accepted
                        the updated Privacy Policy if you continue to use our Services after the new Privacy Policy
                        takes effect. </p>
                </div>
            </div>

            <div id="psection_13" class="chapter-container" style="margin-bottom: 50px;">
                <p class="chapter-title">13. CONTACT US </p>
                <div class="chapter-data-container">
                    <p>If you have any questions about our privacy practices or this Privacy Policy, or to exercise your
                        rights as detailed in this Privacy Policy, please contact us at:</p>
                    <div style="text-align: left;">
                        Mapsol Geospatial Solutions, Inc.<br>
                        13081 Wedges Drive<br>
                        Beaumont, CA 92223<br>
                        hello@mapsolgeo.com
                    </div>

                </div>
            </div>

            <!-- <div class="chapter-container">
                <p class="chapter-title"></p>
                <div class="chapter-data-container">

                </div>
            </div> -->

        </div>
        <!-- PRIVACY POLICY CONTENT END -->
    </div>

</ng-template>