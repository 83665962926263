import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MatrixPathService {
  constructor() {}
  path = [
    [0, 0],
    [1, 1],
    [0, 1],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -1],
    [1, -1],
    [1, 0],
    [2, 2],
    [1, 2],
    [0, 2],
    [-1, 2],
    [-2, 2],
    [-2, 1],
    [-2, 0],
    [-2, -1],
    [-2, -2],
    [-1, -2],
    [0, -2],
    [1, -2],
    [2, -2],
    [2, -1],
    [2, 0],
    [2, 1],
    [3, 3],
    [2, 3],
    [1, 3],
    [0, 3],
    [-1, 3],
    [-2, 3],
    [-3, 3],
    [-3, 2],
    [-3, 1],
    [-3, 0],
    [-3, -1],
    [-3, -2],
    [-3, -3],
    [-2, -3],
    [-1, -3],
    [0, -3],
    [1, -3],
    [2, -3],
    [3, -3],
    [3, -2],
    [3, -1],
    [3, 0],
    [3, 1],
    [3, 2],
    [4, 4],
    [3, 4],
    [2, 4],
    [1, 4],
    [0, 4],
    [-1, 4],
    [-2, 4],
    [-3, 4],
    [-4, 4],
    [-4, 3],
    [-4, 2],
    [-4, 1],
    [-4, 0],
    [-4, -1],
    [-4, -2],
    [-4, -3],
    [-4, -4],
    [-3, -4],
    [-2, -4],
    [-1, -4],
    [0, -4],
    [1, -4],
    [2, -4],
    [3, -4],
    [4, -4],
    [4, -3],
    [4, -2],
    [4, -1],
    [4, 0],
    [4, 1],
    [4, 2],
    [4, 3],
    [5, 5],
    [4, 5],
    [3, 5],
    [2, 5],
    [1, 5],
    [0, 5],
    [-1, 5],
    [-2, 5],
    [-3, 5],
    [-4, 5],
    [-5, 5],
    [-5, 4],
    [-5, 3],
    [-5, 2],
    [-5, 1],
    [-5, 0],
    [-5, -1],
    [-5, -2],
    [-5, -3],
    [-5, -4],
    [-5, -5],
    [-4, -5],
    [-3, -5],
    [-2, -5],
    [-1, -5],
    [0, -5],
    [1, -5],
    [2, -5],
    [3, -5],
    [4, -5],
    [5, -5],
    [5, -4],
    [5, -3],
    [5, -2],
    [5, -1],
    [5, 0],
    [5, 1],
    [5, 2],
    [5, 3],
    [5, 4],
  ];
}
