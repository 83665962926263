import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  private isDragging = false;
  private startX = 0;
  private startY = 0;
  private offsetX = 0;
  private offsetY = 0;
  private hasDragged = false; // Tracks if dragging motion has started
  private dragThreshold = 5; // Pixels required to consider it a drag

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
    this.centerModal();
  }

  private centerModal() {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const modalWidth = this.el.nativeElement.offsetWidth;
    const modalHeight = this.el.nativeElement.offsetHeight;

    const left = Math.max((viewportWidth - modalWidth) / 2, 0);
    const top = Math.max((viewportHeight - modalHeight) / 2, 0);

    this.renderer.setStyle(this.el.nativeElement, 'left', `${left}px`);
    this.renderer.setStyle(this.el.nativeElement, 'top', `${top}px`);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isDragging = true; // Enable dragging
    this.hasDragged = false; // Reset drag state
    this.startX = event.clientX;
    this.startY = event.clientY;

    // Calculate initial offset
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.offsetX = this.startX - rect.left;
    this.offsetY = this.startY - rect.top;

    event.preventDefault();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      // Check if movement exceeds drag threshold to activate drag
      if (!this.hasDragged && (Math.abs(event.clientX - this.startX) > this.dragThreshold || Math.abs(event.clientY - this.startY) > this.dragThreshold)) {
        this.hasDragged = true;
      }

      if (this.hasDragged) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const modalWidth = this.el.nativeElement.offsetWidth;
        const modalHeight = this.el.nativeElement.offsetHeight;

        let left = event.clientX - this.offsetX;
        let top = event.clientY - this.offsetY;

        left = Math.max(0, Math.min(viewportWidth - modalWidth, left));
        top = Math.max(0, Math.min(viewportHeight - modalHeight, top));

        this.renderer.setStyle(this.el.nativeElement, 'left', `${left}px`);
        this.renderer.setStyle(this.el.nativeElement, 'top', `${top}px`);
      }
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }
}
