import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService, ElementType } from 'src/app/Services/common.service';
import { CustomSpinner } from 'src/app/Services/SpinnerService';
import { FeAdminDashboardService } from 'src/app/Services/feAdminDashborad.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SocialAuthService } from 'src/app/Services/socialAuthService';


@Component({
  selector: 'app-landing-signup',
  templateUrl: './landing-signup.component.html',
  styleUrls: ['./landing-signup.component.scss']
})
export class LandingSignupComponent implements OnInit {
  showAlert = false;
  alertMessage: string = ''; 
  alertType: string = 'warning';
  
  @Output() signupToLoginEmit: EventEmitter<any> = new EventEmitter<any>();
  signUpUserType: FormControl = new FormControl('individualUser');
  loadingSignupDiv = true;
  loadingOrgDiv = false;
  paymentDiv=false; 
  signupDiv=true;
  serverUrl = environment.serverUrl;
  serverUrl2 = environment.serverUrlV2;
  signupForm: FormGroup;
  paymentPageForm: FormGroup;
  orgSearchObserver: Subject<any> = new Subject<any>();
  fetchStatus = {
    COMPLETED: 'COMPLETED',
    INPROGRESS: 'INPROGRESS',
    UNKNOWN: ''
  };
  orgFetchStatus = this.fetchStatus.UNKNOWN;
  displayTeamsList = false;
  orgsList: any[] = [];
  showTeamList: boolean;
  errMsg = '';

  constructor(private http: HttpClient, private commonService: CommonService, private firebaseAuth: SocialAuthService,
    private spinner: CustomSpinner, private feAdminDashboardService: FeAdminDashboardService) { 
    this.signupForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      organizationName: new FormControl('', [])
    });
    this.paymentPageForm = new FormGroup({
      cardNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      expMonth: new FormControl('', [Validators.required, Validators.minLength(2)]),
      expYear: new FormControl('', [Validators.required, Validators.minLength(2)]),
      cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
      cardHolderName: new FormControl('', [Validators.required, Validators.minLength(4)]),
    });
    this.monitorChangesOrganizationName();
  }

  ngOnInit(): void {
  }

  signupToLoginFun(event): void {
    this.signupToLoginEmit.emit(true);
  }
  signupEvent(): void {
    // let form = document.getElementsByTagName("form")[0];
    // console.log("form data for signup ", form);
    console.log('form data for signup ', this.signupForm.value, this.signUpUserType.value);
    console.log("organization name ", (this.signupForm.value).organizationName);
    if('organizationUser' === this.signUpUserType.value) {
      console.log('its org signup ', (this.signupForm.value).organizationName);
      this.validateOrgSignupForm();
    } else {
      console.log('its indivi signup ', (this.signupForm.value).organizationName);
      this.validateIndvSignupForm();
    }
    
  }
  chargeCreditCard() {
    // let form = document.getElementsByTagName("form")[0];
    // console.log("form data is ", form);
    console.log("form data card data ", this.paymentPageForm.value);
    //window.alert('sending signup form data and payment data is ')
    //window.alert(this.signupForm.value + this.paymentPageForm.value)
    // this.alertMessage = 'Sending signup form data and payment data: ' + JSON.stringify(this.paymentPageForm.value) + ' ' + JSON.stringify(this.paymentPageForm.value);
    // this.alertType = 'success';  // Set the type of alert
    // this.showAlert = true;

    let errorFound = false;
    this.errMsg = '';
    // this.authStatusMsgs = {};
    console.log(this.paymentPageForm.value);

    try {
      if (!this.paymentPageForm.valid) {
        console.log('NOT VALID');
        this.paymentPageForm.get('cardNumber').markAsTouched();
        // this.paymentPageForm.get('expMonth').markAsTouched();
        // this.paymentPageForm.get('expYear').markAsTouched();
        // this.paymentPageForm.get('cvv').markAsTouched();
        // this.paymentPageForm.get('cardHolderName').markAsTouched();
        errorFound = true;
      }
    } catch (e) {
      errorFound = true;
      this.errMsg = e;
      console.log(e)
    }

    if (!errorFound) {
      console.log('All GOOD');
      this.chargeCreditCardOne();
    }

    // Automatically hide the alert after 5 seconds
    setTimeout(() => this.closeAlert(), 5000);
  }

  closeAlert() {
    this.showAlert = false;
    setTimeout(() => {
      this.alertMessage = '';  // Clear the message after the transition ends
    }, 500); // Wait for the transition to finish before clearing the message
  }

  chargeCreditCardOne() {
    let form = this.paymentPageForm.value;
    console.log("Calling token generation and form data is ", form);
    const cardNumber = form.cardNumber;
    const expMonth = form.expMonth;
    const expYear = form.expYear;
    const cvv = form.cvv;
    const cardHolderName = form.cardHolderName;
    const email = (this.signupForm.value).email;
    // const userEmail = form.email.value;
    // const amount = form.amount.value;
    (<any>window).Stripe.card.createToken({
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      cvc: cvv,
      // name: form.holderName.value,
      // email: form.email.value,
    }, (status: number, response: any) => {
      console.log(status, response);
      if (status === 200) {
        let token = response.id;
        console.log(response)
        // here Calling the API for Customer
        const cardDetails = {
          "userId":1,
          "email":email,
          "cardNumber":cardNumber,
          "holderName":cardHolderName,
          "cardType":response.card.brand,
          "expiredDate":expMonth+"/"+expYear,
          "paymnetServiceName":"Stripe",
          "cvvNumber":cvv,
          "status":"Active",
          "custtoken":"",
          "chargetoken":""
      }
        this.chargeCardwithCustomer(token, cardDetails);
        // this.chargeCard(token);
        this.getCustomers(token)
      } else {
        console.log(response.error.message);
        this.alertMessage = 'Error: ' + JSON.stringify(response.error.code) + ' Message: ' + JSON.stringify(response.error.message);
        this.alertType = 'danger';  // Set the type of alert
        this.showAlert = true;
      }
    });
  }
  // Here new code
  chargeCardwithCustomer(token: string, cardDetails: any) {
    const headers = new HttpHeaders();    
    this.http.post(this.serverUrl2 + '/api/guest/v2/payments?token='+token, cardDetails)
      .subscribe(resp => {
        console.log(resp);
        this.alertMessage = 'Card Details Saved Sucessfully';
        this.alertType = 'success';  // Set the type of alert
        this.showAlert = true;
        setTimeout(() => {
          this.signupEvent();          
        }, 3000);
      })
  }
  getCustomers(token: string) {
    const headers = new HttpHeaders({'token': token, 'amount': '1'});
    this.http.post(this.serverUrl2 +'/payment/customer/all', {}, {headers: headers})
      .subscribe(resp => {
        console.log(resp);
      })
  }
  chargeCard(token: string) {
    const headers = new HttpHeaders({'token': token, 'amount': '1'});
    this.http.post('http://localhost:8080/payment/charge', {}, {headers: headers})
      .subscribe(resp => {
        console.log(resp);
      })
  }

  onSiteSearchChanged(event): void {
    console.log('In onSiteSearchChanged');
    console.log('Name', event);
    const searchKey = event.target.value;
    console.log(searchKey);
    this.orgFetchStatus = this.fetchStatus.UNKNOWN;
    this.displayTeamsList = true;
    this.orgsList = [];
    if (this.commonService.isValid(searchKey)) {
      console.log('VALID');
      if (searchKey.length > 1) {
        console.log(searchKey.length);
        console.log(this.orgSearchObserver);
        this.orgSearchObserver.next({ str: searchKey, ts: String(new Date().getTime()) });
      } else if (searchKey.length <= 1) {
        console.log('LESS : ', searchKey.length);
        this.orgSearchObserver.next({ str: '', ts: String(new Date().getTime()) });
      }
    } else {
      this.displayTeamsList = true;
      console.log('NOT VALID');
      this.orgSearchObserver.next({ str: '', ts: String(new Date().getTime()) });
    }
  }

  monitorChangesOrganizationName(): void {
    this.orgSearchObserver.asObservable().pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((change: any) => {
        console.log(change);
        const searchKey = change.str;
        console.log(searchKey);
        if (this.commonService.isValid(searchKey)) {
          this.orgFetchStatus = this.fetchStatus.INPROGRESS;
          return this.firebaseAuth.getOrganizationsWithSearchStr(searchKey);
        } else {
          return [];
        }
      })
    ).subscribe((data: any) => {
      console.log('GOT ORGANIZATIONS');
      console.log(data);
      // this.orgSearchCompleted = true;
      this.orgsList = data;
      this.orgFetchStatus = this.fetchStatus.COMPLETED;
      // this.orgSearchObserver.next('');
    }, error => {
      console.log('ERROR WHILE GETTING ORGANIZATIONS');
      console.log(error);
      // this.orgSearchObserver.next('');
      this.orgsList = [];
      this.orgFetchStatus = this.fetchStatus.COMPLETED;
      if (error.status === 0) {
        // this.errMsg = 'Server down, Please try again after some time.';
        // window.alert('Server down, Please try again after some time.');
        this.alertMessage = 'Server down, Please try again after some time.';
        this.alertType = 'success';  // Set the type of alert
        this.showAlert = true;
      }
      setTimeout(() => this.closeAlert(), 5000);
    });
  }

  onFocusOrgName(event): void {
    console.log('IN FOCUS');
    console.log(event);
    this.displayTeamsList = true;
  }
  onFocusOut(event): void {
    console.log('IN BLUR');
    console.log(event);
    this.displayTeamsList = false;
  }
  mouseInFunc(event): void {
    console.log('In mouseInFunc');
    this.showTeamList = true;
  }
  mouseOutFunc(event): void {
    console.log('In mouseOutFunc');
    this.showTeamList = false;
  }

  selectOrg(selectedOrg): void {
    console.log('In select team');
    console.log(selectedOrg);
    this.displayTeamsList = false;
    this.showTeamList = false;
    this.signupForm.get('organizationName').setValue(selectedOrg.name);
  }

  getErrorMsg(ctrl: FormControl, name: string, type = ElementType.INPUT): string {
    return this.commonService.getFormErrorMsg(ctrl, name, type);
  }

  validateIndvSignupForm(): void {
    console.log('In validateIndvSignupForm');
    let errorFound = false;
    this.errMsg = '';
    // this.authStatusMsgs = {};
    console.log(this.signupForm.value);
    try {
      if (!this.signupForm.valid) {
        console.log('NOT VALID');
        this.signupForm.get('firstName').markAsTouched();
        this.signupForm.get('lastName').markAsTouched();
        this.signupForm.get('email').markAsTouched();
        this.signupForm.get('password').markAsTouched();
        errorFound = true;
      }
    } catch (e) {
      errorFound = true;
      this.errMsg = e;
    }

    if (!errorFound) {      
      console.log('All GOOD');
      console.log(this.signupForm.value);
      const data = this.signupForm.value;
      const credentials = {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        password: data.password
      };
      this.spinner.show();
      this.firebaseAuth.createUserWithEmailAndPassword(credentials.email, credentials.password, credentials.name)
        .then(res => {
          console.log('USER CREATE SUCCESS');
          console.log(res);
          // this.mainPage = this.mainPages.THNX_SIGN_IN_UP;
          this.alertMessage = 'Thank you for signing up. An activation link sent to your email. Please verify your email to login.';
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          // this.paymentDiv=true; 
          // this.signupDiv=false;
          this.spinner.hide();
          setTimeout(() => {
            this.signupToLoginFun(event);
          }, 2000);
        }, error => {
          console.log(error);
          console.log('USER CREATE FAILED');
          if (error.code === 'auth/email-already-in-use') {
            console.log('The email address is already in use by another account.');
            this.errMsg = 'The email address is already in use by another account.';
          } else {
            this.errMsg = 'Unknown Error';
          }
          this.spinner.hide();
          this.alertMessage = this.errMsg;
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
        }).catch(err => {
          console.log('USER CREATE FAILED');
          if (err.code === 'auth/email-already-in-use') {
            console.log('The email address is already in use by another account.');
            this.errMsg = 'The email address is already in use by another account.';
          } else {
            this.errMsg = 'Unknown Error';
          }
          this.spinner.hide();
          console.log(err);
          this.alertMessage = this.errMsg;
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
        });
    }
    setTimeout(() => this.closeAlert(), 5000);
  }

  validateOrgSignupForm(): void {
    console.log('In validateOrgSignupForm');
    let errorFound = false;
    this.errMsg = '';
    // this.authStatusMsgs = {};
    console.log(this.signupForm.value);
    try {
      if (!this.signupForm.valid) {
        console.log('NOT VALID');
        this.signupForm.get('firstName').markAsTouched();
        this.signupForm.get('lastName').markAsTouched();
        this.signupForm.get('email').markAsTouched();
        this.signupForm.get('password').markAsTouched();
        this.signupForm.get('organizationName').markAsTouched();
        errorFound = true;
      } else {
        console.log('VALID');
        if (this.orgFetchStatus === this.fetchStatus.COMPLETED && this.orgsList.length > 0) {
          const index = this.orgsList.findIndex(org => org.name === this.signupForm.get('organizationName').value);
          if (index === -1) {
            console.log('Please select valid organization from list.')
            throw new Error('Please select valid organization from list.');
          }
        } else if (this.orgFetchStatus === this.fetchStatus.COMPLETED && this.orgsList.length === 0) {
          // throw new Error('No organization matches with the search string.');
          console.log('Your org name is not registered with FuseEarth. Please contact your org admin or register a new org below.')
          throw new Error('Your org name is not registered with FuseEarth. Please contact your org admin or register a new org below.');
        } else {
          console.log('Please select organization from list.')
          throw new Error('Please select organization from list.');
        }
      }
    } catch (e) {
      errorFound = true;
      this.errMsg = e;
      console.log(this.signupForm.value);
    }

    if (!errorFound) {
      console.log('All GOOD');
      console.log(this.signupForm.value);
      const data = this.signupForm.value;
      const credentials = {
        name: this.commonService.getFullNameFromFirstAndLastName(data.firstName, data.lastName),
        email: data.email,
        password: data.password
      };
      this.spinner.show();
      this.firebaseAuth.createUserWithEmailAndPassword(credentials.email, credentials.password, credentials.name)
        .then(res => {
          console.log('USER CREATE SUCCESS');
          console.log(res);
          // API CALL TO ADD THIS USER UNDER ORGANIZATION.
          // IF THROWS ANY ERROR. DELETE THE USER FROM FIREBASE.
          const orgUserData = {
            description: data.organizationName,
            email: data.email,
            name: this.commonService.getFullNameFromFirstAndLastName(data.firstName, data.lastName),
            organizationName: data.organizationName,
            organizationRole: null
          };
          console.log(orgUserData);
          this.firebaseAuth.createUserUnderAnOrg(orgUserData)
            .subscribe(result => {
              console.log('ORG USER REGISTRATION SUCCESS');
              console.log(result);
              // this.mainPage = this.mainPages.THNX_SIGN_IN_UP;
              this.alertMessage = 'Thank you for signing up. Your email is under review by org. An email will be sent to you once the account is activated.'
                       + 'Untill then you can walk around the application as a Explorer user.';
              this.alertType = 'success';  // Set the type of alert
              this.showAlert = true;
              // this.paymentDiv=true; 
              // this.signupDiv=false;
              this.spinner.hide();
              setTimeout(() => {
                this.signupToLoginFun(event);
              }, 2000);
            }, error => {
              console.log('ERROR WHILE REGISTERING ORG USER');
              console.log(error);
              // res.user.delete();
              this.spinner.hide();
              let msg = '';
              try {
                if (error.error.message === 'PERSON_ALREADY_REGISTERED_WITH_THIS_EMAIL_ID') {
                  msg = 'This user already member of this/ another organization. Try with another email.';
                } else {
                  msg = error.error.message || 'Unknown error...';
                }
              } catch (e) {
                msg = 'Error while creating user under and org';
              }
              if (error.status === 0) {
                msg = 'Server down, Please try again after some time.';
              }
              this.errMsg = msg;
              this.alertMessage = this.errMsg;
              this.alertType = 'success';  // Set the type of alert
              this.showAlert = true;
            });

        }, error => {
          console.log(error);
          console.log('USER CREATE FAILED');
          if (error.code === 'auth/email-already-in-use') {
            console.log('The email address is already in use by another account.');
            this.errMsg = 'The email address is already in use by another account.';
          } else {
            this.errMsg = error.code || 'Unknown Error';
          }
          this.alertMessage = this.errMsg;
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          this.spinner.hide();
        }).catch(err => {
          console.log('USER CREATE FAILED');
          if (err.code === 'auth/email-already-in-use') {
            console.log('The email address is already in use by another account.');
            this.errMsg = 'The email address is already in use by another account.';
          } else {
            this.errMsg = err.code || 'Unknown Error';
          }
          this.alertMessage = this.errMsg;
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          this.spinner.hide();
          console.log(err);
        });
    }
    setTimeout(() => this.closeAlert(), 5000);
  }

}
