<div class="home-page">
  <div class="home-header d-flex justify-content-between align-items-center">
    <div>
      <img src="../../assets/images/logo-white.png" alt="logo-white" width="200px"style="padding: 5px;">
    </div>
    <div class="d-flex justify-content-around align-items-center" style="z-index: 1;">
      <img src="../../assets/images/notification-bell.png" alt="notification-bell" width="25px" height="25px">
      <img src="../../assets/images/profile.png" alt="profile" width="35px" height="35px" style="margin-left: 15px; margin-right: 15px; border-radius: 50%; border: 3px solid white;" (click)="showProfileDropdown()">
    </div>
    <div class="profile-dropdown" [hidden]="!visibleProfileDropdown">
      <button (click)="onLogout()">logout</button>
    </div>
  </div>

  <div class="d-flex home-body">

    <div class="home-side-menu-container">

      <button class="d-flex   home-side-menu-items" (click)="showDashboard()" [ngClass]="{'active' : dashboardVisible, 'notActive': !dashboardVisible}">
        <img src="../../assets/images/dashboard.png" alt="dashboard">
        <div>Dashboard</div>
      </button>

      <button class="d-flex home-side-menu-items" (click)="showPackageControl()" [ngClass]="{'active' : PackageVisible, 'notActive': !PackageVisible}">
        <img src="../../assets/images/package-control.png" alt="package-control"  >
        <div>Package</div>
      </button>

      <button class="d-flex home-side-menu-items" (click)="showUserPackageControl()" [ngClass]="{'active' : UserPackageVisible, 'notActive': !UserPackageVisible}">
        <svg xmlns="http://www.w3.org/2000/svg"  fill="white" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        <div>User Package Control</div>
      </button>

      <button class="d-flex home-side-menu-items" (click)="showGlobalSettingTab()" [ngClass]="{'active' : globalSettingVisible, 'notActive': !globalSettingVisible}">
        <svg xmlns="http://www.w3.org/2000/svg"  fill="white" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        <div>Global Settings</div>
      </button>



    </div>
    <div class=" home-menu-body">

      <!--START DASHBORAD========================================================== -->
    <div class="home-menu-body-items" [hidden]="!dashboardVisible">

      Dashboard
      <div class="dashboard-items-container">

        <div class="dashboard-one-screen">
          Coming Soon!
        </div>
      </div>
    </div>
      <!--END DASHBORAD========================================================== -->

    <!--START PACKAGE=================================================================== -->
      <div class="home-menu-body-items" [hidden]="!PackageVisible">
        <div class="d-flex justify-content-between align-items-center">
          <div class="home-menu-body-items-title">Package</div>
            <div class="d-flex justify-content-around align-items-center">
          <div class="package-options">
            <img src="../../assets/images/add.png" alt="addUser" style="background: rgb(7,173,255); border-radius:5px; padding: 4px;" (click)="openPackageTable()">
            <img src="../../assets/images/save.png"[hidden]="!showSavePackageIcon" alt="save" style="background: rgb(210,146,0); border-radius:5px; padding: 4px;" (click)="savePackage()" >
            <img src="../../assets/svgs/delete-white.svg"  [hidden]="!showDeletePackageIcon" alt="delete" style="background: rgb(225,87,41); border-radius:5px; padding: 4px;" (click)="showDeletePackagePopup()">
          </div>

          <select  class="select-package-dropdown" [(ngModel)]="selectedPackage" (change)="openPackageDetails($event.target)">
            <option>Select Package</option>
            <option *ngFor="let i of packages; let k = index" id="{{k}}">{{i}}</option>
        </select>


        </div>
        </div>

        <div class="package-items-container"[hidden]="showPackageFirstScreen">

          <div class="package-one-screen" >
            Please select a package from dropdown to edit or add new package using "+" symbol.
          </div>


        </div>
        <div class="package-table-container"  [hidden]="!showPackageTable">
          <form class="package-info-form" #userName="ngForm" [formGroup]="PackageForm" (ngSubmit)="onSubmitAddUser()">
          <div class="d-flex justify-content-around align-items-center mb-5">
            <input formControlName="PackageName" type="text" name="packagename" id="packagename"  placeholder="Package Name" ngModel>
            <input formControlName="PackagePrice"  type="number" name="packageprice" id="packageprice" placeholder="Package Price(in $)" ngModel>
          </div>
        </form>
        <table class="package-table">
          <thead>
            <tr>
              <th >Features</th>

              <th style="float: right; align-items: center;"><img (click)="addModule()" src="../../assets/svgs/plus-circle-fill-white.svg" alt=""> Select All <input type="checkbox" [checked]="selectallcheck" (click)="CheckAllFeatures($event)"> </th>

            </tr>
          </thead>
          <tbody *ngFor="let length of modules; let i=index">

            <tr class="module" style="background: black;" *ngFor="let module of modules[i].value">
              <th >{{module}}</th>
              <th style="float: right; align-items: center;"><img (click)="addSubModule($event)" id={{i}} src="../../assets/svgs/plus-circle-fill-white.svg" alt="">Select Module <input type="checkbox" [checked]="modulecheck" id={{i}} [value]="module" (click)="CheckAllModule($event)" ></th>

            </tr>
            <tr *ngFor="let module of modules[i].data; let k=index">
              <td >{{module.value}}</td>
              <td style="float: right; align-items: center;"><input type="checkbox" id ={{k}} [(ngModel)]="module.checked" [value]="module.value"  (click)="checkSubModule($event)"></td>

            </tr>



          </tbody>
        </table>
        </div>

        <!-- PACKAGE DETAILS==================== -->
        <div class="package-table-container"  [hidden]="!PackageDetailsTableVisible">
          <form class="package-info-form" #userName="ngForm" [formGroup]="PackageForm" (ngSubmit)="onSubmitAddUser()">
          <div class="d-flex justify-content-around align-items-center mb-5">

              <input type="text" name="name" [value]=PackageName>

            <input  type="number" name="packageprice" [value]=PackagePrice>
          </div>
        </form>
        <table class="package-table">
          <thead>
            <tr>
              <th >Features</th>
              <th style="float: right; align-items: center;">Select All <input type="checkbox" [checked]="selectallcheck" (click)="CheckAllFeatures($event)"> </th>

            </tr>
          </thead>
          <tbody *ngFor="let length of modules; let i=index">

            <tr class="module" style="background: black;" *ngFor="let module of modules[i].value">
              <th >{{module}}</th>
              <th style="float: right; align-items: center;"><img (click)="addSubModule($event)" id={{i}} src="../../assets/svgs/plus-circle-fill-white.svg" alt="">Select Module <input type="checkbox" [checked]="modulecheck" id={{i}} [value]="module" (click)="CheckAllModule($event)" ></th>

            </tr>
            <tr *ngFor="let module of modules[i].data; let k=index">
              <td >{{module.value}}</td>
              <td style="float: right; align-items: center;"><input type="checkbox" id ={{k}} [(ngModel)]="module.checked" [value]="module.value"  (click)="checkSubModule($event)"></td>

            </tr>



          </tbody>
        </table>
        </div>

        <!-- END PACKAGE DETAILS -->

        <!-- START DELETE PACKAGE POPUP -->
        <div class="" style=" position:absolute;width:50%; height:20%; background-color:rgb(230,230,230);z-index: 1;top:35%; left:24%; border-radius: 5px; box-shadow: 5px 10px 18px #888888;" [hidden]="!showdeletepackage">
          <div style="width: 100%;height:15%;background-color:#2B2E34; border-radius: 5px 5px 0px 0px;">
              <img src="../../assets/svgs/close-white.svg" (click)="showdeletepackage=false" alt=""  style="float:right ;padding-right: 2px; color: white;" >
          </div>
          <h3 style="padding-top:20px;text-align:center">Are you sure you want to delete the package ?</h3>
          <div style="display:flex ;left:40px ;margin-top:40px; justify-content: center;">
              <div  style=" margin-right: 60px; left:10px; width: 20%; background: red;text-align: center; border-radius: 5px;color: white; " (click)="deletePackage()">Yes</div>
              <div style="    background: rgb(43,46,52); width: 20%; border-radius: 5px; text-align: center; color: white;" (click)="showdeletepackage=false">No</div>

          </div>
          </div>
        <!-- END  DELETE PACKAGE POPUP-->

      <!-- START ADD MODULE POPUP -->
      <div class="addModulePopup" [hidden]="!addmodulepopup">
        <div class="addModulePopupHeader d-flex justify-content-between align-items-center">
          <div class="addModulePopupTitle">
          Add Module
          </div>
          <div> <img src="../../assets/svgs/close-white.svg" alt="close" (click)="addmodulepopup=false"> </div>
        </div>
        <div class="addModulePopupForm">
          <form #userName="ngForm" [formGroup]="AddModuleForm" (ngSubmit)="onSubmitAddModule()">
            <div class="d-flex justify-content-between align-items-center" style="margin-bottom:10px ; padding: 10px;" >
            <input formControlName="ModuleName" type="text" placeholder="Module Name" ngModel>
            <input formControlName="SubModuleName" type="text" placeholder="Sub-Module Name" ngModel>
          </div>

          <div style="margin-bottom:10px ; padding: 10px;">
            <button  class="button" type="submit">Save</button>
          </div>
          </form>
        </div>
      </div>
     <!-- END ADD MODULE POPUP -->
      <!-- START ADD  SUBMODULE POPUP -->
      <div class="addModulePopup" [hidden]="!addsubmodulepopup">
        <div class="addModulePopupHeader d-flex justify-content-between align-items-center">
          <div class="addModulePopupTitle">
          Add Module
           </div>
           <div> <img src="../../assets/svgs/close-white.svg" alt="close" (click)="addsubmodulepopup=false"> </div>
        </div>
        <div class="addModulePopupForm">
          <form #userName="ngForm" [formGroup]="AddSubModuleForm" (ngSubmit)="onSubmitAddSubModule($event)">
            <div class="d-flex justify-content-between align-items-center" style="margin-bottom:10px ; padding: 10px;" >

            <input formControlName="SubModule" type="text" placeholder="Sub-Module Name" ngModel>
          </div>

          <div style="margin-bottom:10px ; padding: 10px;">
            <button  class="button" type="submit">Save</button>
          </div>
          </form>
        </div>
      </div>
       <!-- END ADD SUBMODULE POPUP -->
    </div>
  <!--END PACKAGE=================================================================== -->

    <!--Start User Package Control=============================================================================== -->
    <div class="home-menu-body-items" [hidden]="!UserPackageVisible">
      <div class="d-flex justify-content-between align-items-center">
        <div class="home-menu-body-items-title">User Package Control</div>
          <div class="d-flex justify-content-between align-items-center">
        <div class="userpackageControl-options">
          <span> Select Individual Users</span>
          <input type="checkbox" (click)="isIndividualUser($event)">
          <!-- <img src="../../assets/images/add.png" alt="addUser" style="background: rgb(7,173,255); border-radius:5px; padding: 4px;" (click)="showAddUserPackageControl()"> -->
          <img src="../../assets/images/edit.png" alt="editUser" style="background: rgb(203,153,2); border-radius:5px;  padding: 4px;"(click)="showEditUserPackageControl()">
          <!-- <img src="../../assets/images/delete.png" alt="deleteUser" style="background: rgb(215,89,48); border-radius:5px; padding: 4px;" (click)="showdeleteUserPackageControl()"> -->
        </div>
        <div class="input-box" style="height: 50%;">
          <!-- [value]="fieldvalue" (keyup)="keyup($event.target.value)" -->
          <input type="text"  placeholder="Search"  style="background: white; color:white ; border: 1px solid rgb(230,230,230) ;" class="form-control">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(230,230,230)" class="bi bi-search" viewBox="0 0 16 16" style="position: absolute; right: 63px; top: 54px;">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </div>

      </div>
      </div>



      <div  class="userpackageControl-table-container" >
        <table class="userpackageControl-table" >
            <thead class="userpackageControl-table-head">
                <th  class="name head"> <input type="checkbox" (click)="CheckAllOptions($event)"></th>
                <th class="name head">Email Id</th>
                <th class="name head">Organization</th>
                <th class="name head">Package</th>
                <th class="name head">Start Date</th>
                <th class="name head">End Date</th>
                <th class="name head">Status</th>
            </thead>
            <tr class="userpackageControl-data-container"  *ngFor="let user of users; let i=index">
                <td class="name"> <input type="checkbox" id ={{i}} [(ngModel)]="user.checked" [value]="user.value" (click)="checkid($event, user)"></td>
                <td class="name">{{user.Email}}	</td>
                <td class="name">{{user.Organization}}	</td>
                <td class="name">{{user.Package}}	</td>
                <td class="name">{{user.StartDate}}	</td>
                <td class="name" >{{user.EndDate}}	</td>
                <td class="name" >{{user.Status}}	</td>
            </tr>
        </table>



           <!-- Start ADDUSERPACKAGEPOPUP============ -->
        <div class="UserPopup" *ngIf="AddUserPackageControlVisible">
          <div class="UserPopupHeader d-flex justify-content-between align-items-cemter">
            <div class="UserPopupTitle">
              Package Control
             </div>
             <div> <img src="../../assets/svgs/close-white.svg" alt="close" (click)="closeAddUserPopup()"> </div>
          </div>


          <form class="UserPopupForm" #userName="ngForm" [formGroup]="AddUserForm" (ngSubmit)="onSubmitUpdateOrgSubscription($event)">

            <div class=" d-flex justify-content-around align-items-cemter" style="margin-bottom:10px ;">
              <input disabled formControlName="email" type="email" name="AddUserEmail"  id="AddUserEmail" placeholder="Email Id" >
            <input disabled formControlName="AddUserOrganizationName" type="text"  name="AddUserOrganizationName" id="AddUserOrganizationName" placeholder="Organization Name" >
            </div>
            <div class=" d-flex justify-content-around align-items-cemter" style="margin-bottom:10px ;">
              <select  formControlName="packageNames" [(ngModel)]='packageNameSelected'>
                <option *ngFor="let i of packages">{{i}}</option>
            </select>
            <select  formControlName="status" [(ngModel)]='statusSelected'>
              <option *ngFor="let i of status">{{i.value}}</option>
          </select>
            </div>
            <div class=" d-flex justify-content-around align-items-cemter" style="margin-bottom:10px ;">
              <input formControlName="StartDateSelected" type="date" [(ngModel)]="StartDateSelected">
              <input formControlName="EndDateSelected" type="date" [(ngModel)]="EndDateSelected">
            </div>
            <button  class="button" type="submit">Save</button>

         </form>

        </div>

        <!-- END ADDUSERPACKAGEPOPUP -->



<!-- START EDIT USER PACKAGE POPUP -->


    <div class="UserPopup" *ngIf="edituserpacakagecontrolvisible">
      <div class="UserPopupHeader d-flex justify-content-between align-items-cemter">
        <div class="UserPopupTitle">
         Edit Package Control
         </div>
         <div> <img src="../../assets/svgs/close-white.svg" alt="close" (click)="closeEditUserPopup()"> </div>
      </div>
    </div>
<!-- END EDIT USER PACKAGE POPUP -->






        <!-- START DELETE USER PACKAGE POPUP -->
        <div class="" style=" position:absolute;width:50%; height:20%; background-color:rgb(230,230,230);z-index: 1;top:35%; left:24%; border-radius: 5px; box-shadow: 5px 10px 18px #888888;" *ngIf="showdeleteuser">
          <div style="width: 100%;height:15%;background-color:#2B2E34; border-radius: 5px 5px 0px 0px;">
              <img src="../../assets/svgs/close-white.svg" (click)="showdeleteuser=false" alt=""  style="float:right ;padding-right: 2px; color: white;" >
          </div>
          <h3 style="padding-top:20px;text-align:center">Are you sure you want to delete the user ?</h3>
          <div style="display:flex ;left:40px ;margin-top:40px; justify-content: center;">
              <div  style=" margin-right: 60px; left:10px; width: 20%; background: red;text-align: center; border-radius: 5px;color: white; " (click)="removeSelectedRows()">Yes</div>
              <div style="    background: rgb(43,46,52); width: 20%; border-radius: 5px; text-align: center; color: white;" (click)="showdeleteuser=false">No</div>

          </div>
          </div>

         <!-- END DELETE USER PACKAGE POPUP-->
    </div>


    </div>

   <!--END User Package Control=============================================================================== -->
   <div class="home-menu-body-items" [hidden]="!globalSettingVisible">
    <div class="d-flex justify-content-between align-items-center">
      <div class="home-menu-body-items-title">Global Settings</div>
      <div class="d-flex justify-content-between align-items-center">
        <!-- <div class="userpackageControl-options">
          <span> Add new Global Seeting</span>
          <button>New Setting</button>
        </div> -->
      </div>
    </div>
    <div>
      <form [formGroup]="settingForm">
        <input formControlName="name" type="text" value="" placeholder="Enter setting Name" required/>
        <input formControlName="value" type="text" value="" placeholder="Enter setting Value" required/>
        <button type="submit" (click)="addSetting()">Add</button>
      </form>
      
    </div>
    <div  class="userpackageControl-table-container" >
      <table class="userpackageControl-table" >
          <thead class="userpackageControl-table-head">
              <th class="name head">Name</th>
              <th class="name head">Value</th>
              <th class="name head">Action</th>
          </thead>
          <tr class="userpackageControl-data-container"  *ngFor="let settingsData of globalSettingsData; let i=index">
              <td class="name"><input (change)="onChangeName($event, settingsData)" type="text" value="{{settingsData.name}}"/></td>
              <td class="name"><input (change)="onChangeValue($event, settingsData)" type="text" value="{{settingsData.value}}"/>	</td>
              <td class="name">
                <input (click)="updatesetting(settingsData)" type="button" value="update"/>
                <input (click)="deletesetting(settingsData)" type="button" value="delete"/>	
              </td>
          </tr>
      </table>
    </div>


  </div>
  </div>


  </div>
  <!-- <div class="home-menu-body-items" [hidden]="!globalSettingVisible">
    <div class="d-flex justify-content-between align-items-center">
      <div class="home-menu-body-items-title">Global Settings</div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="userpackageControl-options">
          <span> Add new Global Seeting</span>
          <button>New Setting</button>
        </div>
      </div>
    </div>
  </div> -->
</div>