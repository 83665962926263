<div class="menu-container">
    <button cdkDrag placement="top" [ngbTooltip]="geoTrayCenterIconContent" class="menu-btn"
            [ngStyle]="menuBtnStyle"
            (mouseenter)="onMouseOverMenu()"
            (mouseleave)="onMouseOutMenu()"
            (tap)="toggleMenu()"
            (panstart)="onPanStart($event)"
            (panend)="onPanEnd($event)" [@btnText]="wingsState" (@btnText.done)="animationDone($event)">
        <!-- <span class="btn-cross"
              *ngIf="wingsState;else elseBlock">
            <svg xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink"
                 version="1.1" id="cross" class="img" x="0px" y="0px"
                 viewBox="0 0 212.982 212.982"
                 style="enable-background:new 0 0 212.982 212.982;"
                 xml:space="preserve"
                 [attr.width]="menuConfig.buttonCrossImgSize"
                 [attr.height]="menuConfig.buttonCrossImgSize">
                <g id="Close">
                    <path style="fill-rule:evenodd;clip-rule:evenodd;" [attr.fill]="menuConfig.buttonFontColor" d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312   c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312   l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937   c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"/>
                </g>
            </svg>
        </span> -->
        <i class="material-icons" *ngIf="wingsState;else elseBlock" style="color: black;    font-size: 25px;
        margin-top: 6px;">close</i>
        <ng-template #elseBlock>
            <span class="btn-text">Menu</span>
        </ng-template>
    </button>
    <div class="menu-wings"
         [ngStyle]="menuWingsStyle" #menuWings>
        <div class="wing-wrapper" *ngFor="let wing of wings; let i = index">
            <app-menu-wing *ngIf="wingsState"
                           [position]="positionClass"
                           [wing]="wing"
                           [svgPath]="svgPath"
                           [index]="i"
                           (wingSpinning)="toggleSpinningState($event)"
                           (wingClicked)="clickWing($event)"
                           (wingHovered)="hoverWing($event)"
                           (wingHoveredOut)="hoverOutWing($event)"></app-menu-wing>
        </div>
    </div>
</div>
<ng-template #geoTrayCenterIconContent>Close the GeoTray</ng-template>