import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReturndataService {
 
  file = {
    Key: "fe_public/Geology/California/KML/1674569220274_cageol.kml",
    size: 14636844,
    StorageClass: "STANDARD",
    Type: "File",
    extension: ".kml",
    name: "1674569220274_cageol.kml",
    url: 'https://test-gallery1.s3.ap-southeast-1.amazonaws.com/fe_public/Geology/California/KML/1674569220274_cageol.kml'
    
  };

  constructor() { }
  getFileData() {
    return this.file;
  }

}
