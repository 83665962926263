import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

export class StreetGoogleMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
      return new OlTileLayer({
        visible: false,
        source: new XYZ({
            url: 'https://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}',
            crossOrigin: 'anonymous',
         }),
         name: 'googlestreet',
        });
  }
}

