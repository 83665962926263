import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import Overlay from 'ol/Overlay';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { Text } from "ol/style";
import Feature from 'ol/Feature';
import { Fill, Style, Stroke } from 'ol/style.js';
import {Heatmap as HeatmapLayer} from 'ol/layer';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import Point from 'ol/geom/Point';
import KML from 'ol/format/KML.js';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-labledropdown',
  templateUrl: './labledropdown.component.html',
  styleUrls: ['./labledropdown.component.scss']
})
export class LabledropdownComponent implements OnInit {

  @ViewChild('container') _containerEl: ElementRef;
  @ViewChild('container') containerEl: ElementRef
  @ViewChild('closer') _closerEl: ElementRef;
  @ViewChild('radius') radiusInput: ElementRef;
  @ViewChild('blur') blurInput: ElementRef;

  private _popupOverlay: Overlay;
  tableData = [];
  tableColumns = [];
  columnsList = [];
  layer:any;
  selectedLable = 'NoLable'
  // static _popupOverlay: any;
  // static _containerEl: any;
  // static _closerEl: any;
  // static close: any;
  // static tableColumns: any;
  // static tableData: any[];
  Colors = [
    '#9400D3',
    '#4B0082',
    '#0000FF',
    '#00FF00',
    '#FFFF00',
    '#FF7F00',
    '#FF0000',
  ];
  blur = '5';
  radius = '5';
  heatmapVector;
  rasters:any = '';

  constructor(private basemap: BasemapService, private commonService: CommonService) {      
    // this.blur = document.getElementById('blur');
    // this.radius = document.getElementById('radius');
    // this.blur = '5';
    // this.radius = '5';
  }

  ngOnInit() { }

  private randomRainbowColor(min, max): any {
    return this.Colors[Math.floor(Math.random() * (max - min + 1)) + min];
    this.updateRangeProgress(this.radiusInput.nativeElement);
    this.updateRangeProgress(this.blurInput.nativeElement);
  }

  updateRangeProgress(inputElement: HTMLInputElement) {
    const value = ((+inputElement.value - +inputElement.min) / (+inputElement.max - +inputElement.min)) * 100;
    inputElement.style.background = `linear-gradient(to right, #007bff ${value}%, #e9ecef ${value}%)`;
  }
  
  onChangeRaidus(radiusValue): any {
    const input = event.target as HTMLInputElement;
    console.log(radiusValue)
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === 'tower_heatmap') {
          console.log(layerObj)
          layerObj.setRadius(parseInt(radiusValue, 10));
        }
      }
    });
  }
  onChangeBlur(blurValue): any {
    console.log(blurValue)
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === 'tower_heatmap') {
          console.log(layerObj)
          layerObj.setBlur(parseInt(blurValue, 10));
        }
      }
    });
  }

  onChange(value) {
    console.log('value changed ', value, this.layer)
    const fill = new Fill({
      color: 'rgba(255, 255, 255, 1)',
    });
    // const stroke = new Stroke({
    //   // color: '#319FD3',
    //   color: this.randomRainbowColor(0, 6),
    //   width: 1,
    // });
    this.selectedLable = value;
    if(value !== 'NoLable') {
      this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
        if (layerObj !== undefined) {
          if (layerObj.values_.name === this.layer.name) {
            const fileType = this.layer.fileType
            // console.log('current layer ', layerObj, layerObj.getSource());
            // console.log('features ', layerObj.getSource().getFeatures(), layerObj)
            layerObj.getSource().getFeatures().forEach(feature => {              
              const featureText = (fileType === '.xlsx') ? feature.get('id')[value] + '' : feature.get(value) + '';
              // console.log(feature, feature.getGeometry().getType(), feature.get('id'))
              // console.log(feature.get('id')[value])
              // console.log(feature.getStyle())
              // feature.setStyle(layerObj.getStyle())
              // feature.style = { strokeColor: "red" };
              const color = layerObj.getStyle().getStroke() !== null ? layerObj.getStyle().getStroke().getColor() : '#fff';
              const image_ = layerObj.getStyle().getImage();
              // console.log('color ', color)
              let style;
              if(feature.getGeometry().getType() === 'LineString') {
                  style = new Style({
                    fill: fill,
                    stroke: new Stroke({ color: color }),
                    text: new Text({
                      font: '12px Calibri,sans-serif',
                      fill: new Fill({ color: '#000' }),
                      stroke: new Stroke({
                        color: '#fff', width: 2
                      }),
                      text: featureText,
                      // overflow: 'true',
                      maxAngle: 360,
                      placement: 'line',
                    })
                });
              } else if(feature.getGeometry().getType() === 'Point') {
                  style = new Style({
                    image: image_,
                    fill: fill,
                    stroke: new Stroke({ color: color }),
                    text: new Text({
                      font: '12px Calibri,sans-serif',
                      text: featureText,
                      // overflow: 'true',
                      maxAngle: 45,
                      placement: 'point',
                      textBaseline: 'bottom',
                    })
                });
              } else {
                style = new Style({
                  fill: fill,
                  stroke: new Stroke({ color: color }),
                  text: new Text({
                    font: '12px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    stroke: new Stroke({
                      color: '#fff', width: 2
                    }),
                    text: featureText,
                  })
                });
              }
            //   const style = new Style({
            //     //fill: new ol.style.Fill({ color: '#000' }),
            //     stroke: new Stroke({ color: color }),
            //     text: new Text({
            //       font: '12px Calibri,sans-serif',
            //       fill: new Fill({ color: '#000' }),
            //       stroke: new Stroke({
            //         color: '#fff', width: 2
            //       }),
            //       text: feature.get(value)+ '',
            //       // overflow: 'true',
            //       maxAngle: 360,
            //       placement: 'line',
            //     })
            // });
            feature.setStyle(style);   
            })
          }
        }
      });
    } else {
      this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
        if (layerObj !== undefined) {
          if (layerObj.values_.name === this.layer.name) {
            // remove Lables
            layerObj.getStyle().setText(null);
            // console.log(layerObj.getStyle())
            // console.log('features ', layerObj.getSource().getFeatures())
            layerObj.getSource().getFeatures().forEach(feature => {
              feature.getStyle().setText(null);
              feature.setStyle(null);
            })
          }
        }
      });
    }
  }

  heatmapclicked(): any {
    console.log('clicked on heatmap ', (this.selectedLable).substring, this.layer)
    // const isnumber_ = (this.selectedLable).substring ? window.alert('selected value not numeric') : false;
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === this.layer.name) {
          this.heatmapVector = layerObj;
          if(this.layer.fileType === '.kml' || this.layer.fileType === '.kmz') {
            const vector = new HeatmapLayer({
              source: new VectorSource({
                url: this.layer.firebaseUrl,
                format: new KML({
                  extractStyles: false,
                }),
              }),
              blur: parseInt(this.blur, 10),
              radius: parseInt(this.radius, 10),
              weight: (feature) => {
                // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
                // standards-violating <magnitude> tag in each Placemark.  We extract it from
                // the Placemark's name instead.
                // const name = feature.get('name');
                // const magnitude = parseFloat(name.substr(2));
                // return magnitude - 5;
                console.log('printing lable & VALUE: ', this.selectedLable, feature.get(this.selectedLable))
                return feature.get(this.selectedLable);
              },
            });
            vector.set('name', 'tower_heatmap');
            this.basemap.getCurrentBasemap().addLayer(vector);
          } 
          else {          
            const vector = new HeatmapLayer({
              source: layerObj.getSource(),
              blur: parseInt(this.blur, 10),
              radius: parseInt(this.radius, 10),
              weight: (feature) => {
                // console.log(this.selectedLable, feature.get(this.selectedLable))
              // const name = feature.get(this.selectedLable) + '';
              // const magnitude = parseFloat(name.substr(2));
              console.log('printing lable & VALUE: ', this.selectedLable, feature.get(this.selectedLable))
              return (this.layer.fileType === '.xlsx') ? feature.get('id')[this.selectedLable] + '' : feature.get(this.selectedLable);;
              // return 16;
            }
            // gradient: ['#007700', '#6ce200', '#efef00', '#ffff00', '#ff6600', '#ff0000'],
            });

            // layerObj.getSource().getFeatures().forEach(feature => {
            //   const style = layerObj.getStyle();
            //   if (feature.getGeometry().getType() == 'LineString') {
            //     console.log('LineString, ', style)
            //     style.setGeometry(new Point(feature.getGeometry().getCoordinateAt(0.5)));
            //     vector.getSource().on('addfeature', (event) => {
            //         const name = event.feature.get(this.selectedLable);
            //         event.feature.set('weight', name);
            //       });
            //   }
            // });

            // const defaultStyleFunction = layerObj.getStyleFunction();
            // console.log(defaultStyleFunction)
            // vector.setStyle((feature, resolution) => {
            //   const style = defaultStyleFunction(feature, resolution);
            //   const geom = feature.getGeometry();
            //   console.log(geom)
            //   if (geom.getType() == 'LineString') {
            //     console.log('LineString, ', style[0])
            //     style[0].setGeometry(new Point(geom.getCoordinateAt(0.5)));
            //   }
            //   return style;
            // });

            // vector.getSource().on('addfeature', (event) => {
            //   const name = event.feature.get(this.selectedLable);
            //   event.feature.set('weight', (name - 2500000000000)/200000000000);
            // });

            vector.set('name', 'tower_heatmap');
            this.basemap.getCurrentBasemap().addLayer(vector);
            // vector.redraw()
            // vector.getSource().refresh();
          }
        }
      }
    });
  }

  getlabledropdownPopup(columnsList, layer) {
    this._popupOverlay = new Overlay({
      element: this._containerEl.nativeElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this._closerEl.nativeElement.onclick = () => {
      this.close();
    };
    this.columnsList = columnsList;
    this.layer = layer;
    return this._popupOverlay;
  }

  public close() {
    this._popupOverlay.setPosition(undefined);
    this._closerEl.nativeElement.blur();
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === 'tower_heatmap') {
          this.basemap.getCurrentBasemap().removeLayer(layerObj);
        }
      }
    });
    return false;
  }

  setPropertyValues(tableData, tableColumns) {
    this.tableColumns = tableColumns;
    this.tableData = [];
    Object.entries(tableData).forEach(entry => {
      const dataRow = [];
      Object.entries(entry[1]).forEach( data => {
        dataRow.push(data[1]);
      });
      this.tableData.push(dataRow);
    });
  }
  generatefrf(): any {
    if(this.selectedLable === 'NoLable') {
      window.alert('please select elevation value for generate FRF file');
      return;
    }
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === this.layer.name) {
          layerObj.setZIndex(2);
        }
      }
    });
    const layerObj = this.layer;
    const fileInput: any = layerObj;
    console.log(fileInput.zipfile);
    const formdata = new FormData();
    formdata.append("file", fileInput.zipfile, fileInput.name);
    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    // $('.loader').fadeIn()
    fetch("https://qa.fuse.earth:4433", {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        // this.rasterFileClickEvent()
        console.log(result, this.rasters)
        // debugger
        this.rasters = result;
        this.layerInfoRestAPI(result)
        // this.rasterFileClickEvent()
        // let allLayer = map.getAllLayers()
        // allLayer.forEach(layer => {
        //   if(layer.getProperties().title != undefined){
        //     map.removeLayer(layer)
        //   }
        // });
        if(fileInput.name.includes('.zip') || fileInput.name.includes('.kml') || fileInput.name.includes('.geojson')){
          let vectorLayer = fileInput.name.split('.')[0]
          // this.loadWms(fileInput.name, '')
          // document.getElementById("vector-layer").style.display = "block";
        }
        else{
          // document.getElementById("vector-layer").style.display = "none";
          // this.publish(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  layerInfoRestAPI(fileName):any {
    let url = "https://qa.fuse.earth:4433/info?filename=" + fileName
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        console.log(result.length)
        console.log(result[0], result[1])
        this.loadShpRestAPI(result[0], this.selectedLable)
      })
  }

  loadShpRestAPI(shpFilePath, elevationName): any {
    let url = "https://qa.fuse.earth:4433/loadshp?shp_file_path=" + shpFilePath + "&elev=" + elevationName
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.demRestAPI(result[0], result[1], result[2])
      })

  }
  demRestAPI(fileName, epsg, elevationIndex):any {
    let url = "https://qa.fuse.earth:4433/dem?filename=" + fileName + "&utm_zone=" + epsg + "&elevation_index=" + elevationIndex
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        console.log(result)
        // window.alert('Firebase URL ' + result)
        this.publish(result)
      })
  }

  // rasterFileClickEvent(): any {
  //   console.log(this.rasters)
  //   var requestOptions = {
  //     method: 'GET',
  //     redirect: 'follow'
  //   };
  //   let url = "https://qa.fuse.earth:4433/dem?filename=" + "/root/uploadFile/extract/" + this.rasters + ".shp"
  //   // $('.loader').fadeIn()
  //   fetch(url, {
  //     method: 'GET',
  //     redirect: 'follow'
  //   })
  //     .then(response => response.text())
  //     .then(result => {
  //       console.log(result)
  //       // debugger
  //       this.publish(result)
  //     })
  // }
  publish(filename){  
    console.log(filename)
    var formdata = new FormData(); 
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    let url = "https://qa.fuse.earth:4433/publish?filename=" + filename
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        // debugger
        console.log(result)
        window.alert('Firebase URL ' + result[1])
        // this.commonService.setFirebaseFRFUrl(result[1]);
        this.commonService.firebaseFRFUrl = result[1];
        window.open(result[1], '_blank')
        this.loadWms(result[0], '')
      })
      .catch(error => console.log('error', error));
  }
  
  loadWms(layerName, vector_flag): any {
    const layer = 'giskernel:' + layerName;
    const tiled = new TileLayer({
      visible: true,
      title: layerName,
      source: new TileWMS({
        url: 'http://146.190.140.219:8282/geoserver/giskernel/wms',
        params: {'FORMAT': 'image/png', 
                 'VERSION': '1.1.1',
                 tiled: false,
              "STYLES": '',
              "LAYERS": layer,
              "exceptions": 'application/vnd.ogc.se_inimage',
           //tilesOrigin: -104.92041666666667 + "," + 36.59374999999999
        },
        projection: 'EPSG:3857',
      }),
      zIndex:1
    });
  
    
    // LayerGr.getLayers().push(tiled);
    // document.getElementById("raster-layer").style.display = "block";
    this.basemap.getCurrentBasemap().addLayer(tiled)
    // $('.loader').fadeOut()
  }

}
