import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML.js';
import ImageLayer from 'ol/layer/Image.js';
import Projection from 'ol/proj/Projection.js';
import Static from 'ol/source/ImageStatic.js';
import { Observable, Subject } from 'rxjs';
import { transformExtent } from 'ol/proj';
import { get } from 'ol/proj';
import { BasemapService } from '../../basemap/basemap.service';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import * as xml2js from 'xml2js';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { CommonService } from '../../Services/common.service';
import { AuthObservableService } from '../../Services/authObservableService';
// Here new lib for image rotation
import { addCoordinateTransforms } from 'ol/proj';
import { rotate } from 'ol/coordinate';
import { buffer, getCenter, getWidth } from 'ol/extent';
import { fromLonLat, transform, addProjection, getTransform } from 'ol/proj';
import { isConstructorDeclaration } from 'typescript';
import { fromArrayBuffer } from 'geotiff';
import GeoTIFF from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
// import {PDFExtract, PDFExtractOptions} from 'pdf.js-extract';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Circle } from 'ol/style.js';
import $ from 'jquery';
import { OnChanges, SimpleChanges } from '@angular/core';
import { GeosolComponent } from 'src/app/geosol/geosol.component';
import { OnInit } from '@angular/core';
import { get as getProjection } from 'ol/proj.js';
import { AffineTransformation } from '../../georef/affinetransformation';

export class SetLayerTOMapUtil {
  private basemapProjection;
  private basemap;
  private ZIP_EXTENSION_CONSTANT = '.zip';
  private KML_EXTENSION_CONSTANT = '.kml';
  private KMZ_EXTENSION_CONSTANT = '.kmz';
  private JPG_EXTENSION_CONSTANT = '.jpg';
  private TIF_EXTENSION_CONSTANT = '.tif';
  private PRJ_EXTENSION_CONSTANT = '.prj';
  private DBF_EXTENSION_CONSTANT = '.dbf';
  private GEOJSON_EXTENSION_CONSTANT = '.geojson';
  private JSON_EXTENSION_CONSTANT = '.json';
  private PNG_EXTENSION_CONSTANT = '.png';
  private JPEG_EXTENSION_CONSTANT = '.jpeg';
  private ALERT_CONSTANT = 'alert';
  private TEXT_CONSTANT = 'text';
  private XLSX_EXTENSION_CONSTANT = '.xlsx';
  private PDF_EXTENSION_CONSTANT = '.pdf';
  private CSV_EXTENSION_CONSTANT = '.csv';
  fileName;
  private epsgCode = 'NO-EPSG';
  private alertComponent;
  private geotowerService;
  layerObj;
  TargetGCPs;
  SourceGCPs;
  Colors = [
    '#9400D3',
    '#4B0082',
    '#0000FF',
    '#00FF00',
    '#FFFF00',
    '#FF7F00',
    '#FF0000',
  ];
  /* Colors.names = {
    Violet: '#9400D3',
    Indigo: '#4B0082',
    Blue: '#0000FF',
    Green: '#00FF00',
    Yellow: '#FFFF00',
    Orange: '#FF7F00',
    Red: '#FF0000',
  }; */
  setcolor3 = '#9400D3';

  constructor(
    private basemapService: BasemapService,
    private commonService: CommonService
  ) {
    this.basemap = this.basemapService.getCurrentBasemap();
    // this.basemapProjection = GeosolComponent.mapReference;
    this.basemapProjection = this.basemap.getView().getProjection().code_;
  }

  affinTransParams = {
    matrix : [1, 0, 0, 0, 1, 0],
    hasControlPoints : false,
    similarity : false,
    rotation : undefined,
    scaling : undefined,
    transformation : undefined
  };


  public layerPreview(options): any {
    console.log('client obj is ', options);
    const filetype = options.layerObj.fileType;
    this.layerObj = options.layerObj;
    this.fileName = options.layerObj.name;
    this.alertComponent = options.geotower.alertComponent;
    this.geotowerService = options.geotower.geotowerService;
    if (filetype === this.ZIP_EXTENSION_CONSTANT) {
      this.shpLayerPreview(options.layerObj);
    } else if (
      filetype === this.KML_EXTENSION_CONSTANT ||
      filetype === this.KMZ_EXTENSION_CONSTANT
    ) {
      this.kmlImagePreview(this.layerObj.firebaseUrl);
    } else if (filetype === this.TIF_EXTENSION_CONSTANT) {
      this.tFFImagePreview(this.layerObj.firebaseUrl, this.layerObj.metadata);
    } else if (
      filetype === this.JPG_EXTENSION_CONSTANT ||
      filetype === this.PNG_EXTENSION_CONSTANT ||
      filetype === this.JPEG_EXTENSION_CONSTANT
    ) {
      this.jpgImagePreview(this.layerObj.firebaseUrl, this.layerObj.metadata);
    } else if (
      filetype === this.GEOJSON_EXTENSION_CONSTANT ||
      filetype === this.JSON_EXTENSION_CONSTANT
    ) {
      this.previewGeoJosn_Json_Files_new(
        this.layerObj,
        options.layerObj.fileType
      );
    } else if (filetype === this.PDF_EXTENSION_CONSTANT) {
      this.preview_PDF_File(this.layerObj.firebaseUrl);
    } else if (
      filetype === this.XLSX_EXTENSION_CONSTANT ||
      filetype === this.CSV_EXTENSION_CONSTANT
    ) {
      this.preview_XLSX_CSV_File(this.layerObj);
    } else if (filetype === 'url') {
      console.log('its url type no need to add map, its already added');
      this.addLayerObjectsToTower();
    } else {
      this.shpLayerPreview(options.layerObj);
    }
  }

  // Here new code for shp layer adding to map
  private shpLayerPreview(layerObj): any {
    const geoJsonMapshaper = layerObj.metadata;
    if (geoJsonMapshaper.length > 0) {
      geoJsonMapshaper.forEach((jsonObj) => {
        this.setLayerToMap(jsonObj);
      });
    } else {
      this.setLayerToMap(geoJsonMapshaper);
    }
  }
  private setLayerToMap(geoJson): any {
    const proj3857 = getProjection("EPSG:3857");

    if (this.basemapProjection == undefined) {
      this.basemapProjection = proj3857
    }
    let isVisible = true;
    console.log('file name ', geoJson.fileName);
    if (
      geoJson.fileName === 'City_PopRanks' ||
      geoJson.fileName === 'County_PopRanks' ||
      geoJson.fileName === 'CollegeTowns' ||
      geoJson.fileName === 'StatewideShapes_IHE_Data' ||
      geoJson.fileName === 'CollocateSymbolData' ||
      geoJson.fileName === 'AlabamaCounties' ||
      geoJson.fileName === 'AlabamaStateOnly'
    ) {
      isVisible = false;
    }
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJson, {
        featureProjection: this.basemapProjection,
      }),
    });
    console.log(geoJson, vectorSource, 'veryimportant');
    const fill = new Fill({
      color: 'rgba(255, 255, 255, 1)',
    });
    const stroke = new Stroke({
      // color: '#319FD3',
      color: this.randomRainbowColor(0, 6),
      width: 1,
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      visible: isVisible,
      /* style: this.getShapeStyle() */
      style: new Style({
        image: new CircleStyle({
          fill,
          stroke,
          radius: 5,
        }),
        fill,
        stroke,
      }),
    });
    vectorLayer.set('name', geoJson.fileName);
    // vectorLayer.setStyle(vectorLayer.getStyle());
    vectorLayer.setOpacity(0.7);
    this.basemap.addLayer(vectorLayer);
    this.addLayerObjectsToTower();
    console.log(
      'getting the styles ',
      vectorLayer.getStyle().defaultStyles,
      vectorLayer.getStyleFunction()
    );
    // new code for zoom to extent
    if (
      geoJson.fileName === 'City_PopRanks' ||
      geoJson.fileName === 'County_PopRanks' ||
      geoJson.fileName === 'CollegeTowns' ||
      geoJson.fileName === 'StatewideShapes_IHE_Data' ||
      geoJson.fileName === 'CollocateSymbolData' ||
      geoJson.fileName === 'AlabamaCounties' ||
      geoJson.fileName === 'AlabamaStateOnly'
    ) {
    } else {
      this.basemap.getLayers().forEach((currentLayer) => {
        if (geoJson.fileName === currentLayer.values_.name) {
          const extentValue = currentLayer.values_.source.getExtent();
          this.basemap.getView().fit(extentValue);
          this.basemap.getView().setZoom(this.basemap.getView().getZoom() - 1);
        }
      });
    }
  }

  private setLayerToMap_StaticForPrototype(geoJson): any {
    const fill = new Fill({
      color: 'rgba(255, 255, 255, 1)',
    });
    const stroke = new Stroke({
      // color: '#319FD3',
      color: this.randomRainbowColor(0, 6),
      width: 1,
    });
    const style = new Style({
      image: new CircleStyle({
        fill,
        stroke,
        radius: 5,
      }),
      fill,
      stroke,
    });
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJson, {
        featureProjection: this.basemapProjection,
      }),
    });
    vectorSource.getFeatures().forEach((feature) => {
      const r = 0 + Math.floor(Math.random() * (255 - 0 + 1));
      const g = 0 + Math.floor(Math.random() * (255 - 0 + 1));
      const b = 0 + Math.floor(Math.random() * (255 - 0 + 1));
      const a = 0 + Math.floor(Math.random() * (255 - 0 + 1));
      feature.setStyle(
        new Style({
          image: new CircleStyle({
            fill: new Fill({
              color: 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')',
            }),
            stroke,
            radius: 5,
          }),
          fill: new Fill({
            color: 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')',
          }),
          stroke,
        })
      );
    });
    console.log(vectorSource, vectorSource.getFeatures());
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      /* style: this.getShapeStyle() */
      /* style: new Style({
        image: new CircleStyle({
          fill,
          stroke,
          radius: 5
        }),
        fill,
        stroke,
      }), */
    });
    const vector = new HeatmapLayer({
      source: new VectorSource({
        url: 'https://firebasestorage.googleapis.com/v0/b/geomocus-qa.appspot.com/o/.kml%2F1663110430690_2012_Earthquakes_Mag5.kml?alt=media&token=64c8ba78-0731-4ea8-9af8-88d9d97ef285',
        format: new KML({
          extractStyles: false,
        }),
        /* features: (new GeoJSON()).readFeatures(geoJson, {
          featureProjection: this.basemapProjection
        }) */
      }),
      blur: parseInt('5', 10),
      radius: parseInt('5', 10),
      weight: function (feature) {
        // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
        // standards-violating <magnitude> tag in each Placemark.  We extract it from
        // the Placemark's name instead.
        const name = feature.get('name');
        const magnitude = parseFloat(name.substr(2));
        return magnitude - 5;
      },
    });
    vectorLayer.set('name', geoJson.fileName);
    // vectorLayer.setStyle(vectorLayer.getStyle());
    vectorLayer.setOpacity(0.7);
    this.basemap.addLayer(vectorLayer);
    this.basemap.addLayer(vector);
    this.addLayerObjectsToTower();
    console.log(
      'getting the styles ',
      vectorLayer.getStyle().defaultStyles,
      vectorLayer.getStyleFunction()
    );
    // new code for zoom to extent
    this.basemap.getLayers().forEach((currentLayer) => {
      if (geoJson.fileName === currentLayer.values_.name) {
        const extentValue = currentLayer.values_.source.getExtent();
        this.basemap.getView().fit(extentValue);
        this.basemap.getView().setZoom(this.basemap.getView().getZoom() - 1);
      }
    });
  }
  // ----------------- end shp layer file adding to map
  // new code for adding kml/kmz file to map
  private kmlImagePreview(kmlURL): any {
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        url: kmlURL,
        format: new KML(),
        crossOrigin: 'anonymous',
      }),
    });
    vectorLayer.set('name', this.fileName);
    vectorLayer.setOpacity(0.7);
    this.basemap.addLayer(vectorLayer);
    // this.setUploadStatusInPercentage(80);
    this.addLayerObjectsToTower();
    setTimeout(() => {
      this.basemap.getLayers().forEach((currentLayer) => {
        if (this.fileName === currentLayer.values_.name) {
          const extentValue = currentLayer.values_.source.getExtent();
          console.log(
            'kml/kmz extend value is ',
            extentValue,
            currentLayer.values_.source
          );
          this.basemap.getView().fit(extentValue);
          // this.setUploadStatusInPercentage(100);
          this.basemap.getView().setZoom(this.basemap.getView().getZoom() - 1);
        }
      });
    }, 5000);

    /* const vector = new HeatmapLayer({
      source: new VectorSource({
        url: kmlURL,
        format: new KML({
          extractStyles: false,
        }),
      }),
      blur: parseInt('5', 10),
      radius: parseInt('5', 10),
      weight: function (feature) {
        // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
        // standards-violating <magnitude> tag in each Placemark.  We extract it from
        // the Placemark's name instead.
        const name = feature.get('name');
        const magnitude = parseFloat(name.substr(2));
        return magnitude - 5;
      },
    });
    this.basemap.addLayer(vector); */
  }
  // ----------------- end kml/kmz layer file adding to map
  // new code for adding jpeg/tiff file to map
  private _getImageDimension(image): Observable<any> {
    return new Observable((observer) => {
      const img = new Image();
      img.onload = (event) => {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      };
      img.src = image.url;
    });
  }

  // new method added for xml reding for getting epsg code
  private printNode(xml, key?): any {
    if (xml == null) {
      console.log(`Node is empty`);
      return;
    }

    if (Array.isArray(xml)) {
      return xml.forEach((v) => this.printNode(v, key));
    }

    if (typeof xml === 'object') {
      return Object.entries(xml).forEach(([key, v]) => this.printNode(v, key));
    }

    // console.log(`${key}:${xml}`);
    if (`${key}` === 'LATESTWKID') {
      this.epsgCode = 'EPSG:' + `${xml}`;
      console.log('EPSG Code is ', `${key}`, `${xml}`, this.epsgCode);
      return;
    }
  }

  // new method added for projdef text getting from epsg.io
  private getProjDef(epsgCode): Observable<any> {
    return new Observable((observer) => {
      let projdef =
        '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118' +
        ' +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs';
      fetch('https://epsg.io/?format=json&q=' + epsgCode.split(':')[1]).then(
        (response) => {
          return response.json().then((jsonData) => {
            const results = jsonData.results;
            console.log('gettong proj4 result ', results);
            if (results && results.length > 0) {
              for (let i = 0, ii = results.length; i < ii; i++) {
                const result = results[i];
                if (result) {
                  const code = result.code;
                  const name = result.name;
                  const proj4def = result.proj4;
                  const bbox = result.bbox;
                  if (proj4def && proj4def.length > 0) {
                    console.log('find projedef ', proj4def);
                    projdef = proj4def;
                    observer.next(projdef);
                    observer.complete();
                    return;
                  }
                }
              }
            } else {
              this.epsgCode = 'NO-EPSG';
              console.log('no result for epsg ', this.epsgCode);
            }
            observer.next(projdef);
            observer.complete();
          });
        }
      );
      /* .then((json) => {
      const results = json.results;
      console.log('gettong proj4 result ', results);
      if (results && results.length > 0) {
        for (let i = 0, ii = results.length; i < ii; i++) {
          const result = results[i];
          if (result) {
            const code = result.code;
            const name = result.name;
            const proj4def = result.proj4;
            const bbox = result.bbox;
            if (proj4def && proj4def.length > 0) {
              console.log('find projedef ', proj4def);
              projdef = proj4def;
              observer.next(projdef);
              observer.complete();
              return;
            }
          }
        }
      } else {
        this.epsgCode = 'NO-EPSG';
        console.log('no result for epsg ', this.epsgCode);
      }
      observer.next(projdef);
      observer.complete();
    }); */
    });
  }

  splitIntoPairs(arr):any {
    let pairs = [];
    for (let i = 0; i < arr.length; i += 2) {
        pairs.push([parseFloat(arr[i]), parseFloat(arr[i + 1])]);
    }
    return pairs;
  }

  private xmlParser(xmlData): any {
    // xml prsing code here for getting the epsg code
    const parser = new xml2js.Parser({ strict: false, trim: true });
    parser.parseString(xmlData, (err, result) => {
      const obj: any[] = result;
      console.log(result);
      if (result != null){
        let array = result.PAMDATASET.METADATA;
        let allObjectsWithGeoDataXForm = array.filter(obj => obj.GEODATAXFORM !== undefined);
        this.SourceGCPs = this.splitIntoPairs(allObjectsWithGeoDataXForm[0].GEODATAXFORM[0].SOURCEGCPS[0].DOUBLE)
        this.TargetGCPs = this.splitIntoPairs(allObjectsWithGeoDataXForm[0].GEODATAXFORM[0].TARGETGCPS[0].DOUBLE)
        console.log('sourceGCPS:',this.SourceGCPs)
        console.log('TargetGCPS:',this.TargetGCPs)
      }    
      if (this.commonService.isValid(obj)) {
        this.printNode(obj, 'LATESTWKID');
        console.log('epsgCode checking ', this.epsgCode);
      }
    });
    this.getProjDef(this.epsgCode).subscribe((projdef) => {
      proj4.defs(this.epsgCode, projdef);
      register(proj4);
    })
  }

  private imgProjCoordinateSysCal(
    translationX,
    translationY,
    xScale,
    yScale,
    rotation,
    imageData,
    jpgURL
  ): any {
    /* x1 = Ax + By + C
      y1 = Dx + Ey + F
      image-to-world transformation Calculation */
    this.getProjDef(this.epsgCode).subscribe((projdef) => {
      if (this.epsgCode === 'NO-EPSG') {
        const msg =
          'Sorry! Cannot add the file ' +
          this.fileName +
          ' due to unrecognized coordinate system.';
      }
      console.log(
        'projection def is ',
        projdef,
        jpgURL,
        imageData,
        xScale,
        yScale,
        this.epsgCode
      );
      proj4.defs(this.epsgCode, projdef);
      register(proj4);
      // 
      // let imageExtentProjection = transformExtent(
      //   [
      //     translationX - (xScale * imageData.width / 2),
      //     translationY - (yScale * imageData.height / 2),
      //     translationX + (xScale * imageData.width / 2),
      //     translationY + (yScale * imageData.height / 2)
      //   ],
      //   get(this.epsgCode),
      //   get(this.basemapProjection)
      // );
      let imageExtentProjection = [translationX - (xScale * imageData.width / 2),
            translationY - (yScale * imageData.height / 2),
            translationX + (xScale * imageData.width / 2),
            translationY + (yScale * imageData.height / 2)];
      // let imageExtentProjection = transformExtent(
      //   [
      //     translationX,
      //     translationY + yScale * imageData.height,
      //     translationX + xScale * imageData.width,
      //     translationY,
      //   ],
      //   get(this.epsgCode),
      //   get(this.basemapProjection)
      // );
      let imageExtent = [
        translationX,
        translationY + yScale * imageData.height,
        translationX + xScale * imageData.width,
        translationY,
      ];
      // ------above working fine ------
      /* const imageExtent = transformExtent([translationX, translationY + (yScale * imageData.height),
          translationX + (xScale * imageData.width), translationY],
        get(this.epsgCode), get(this.basemapProjection));
        const imageExtentProjection = [translationX, translationY  + (yScale * imageData.height),
          translationX + (xScale * imageData.width), translationY]; */

      /* const mX1 = (xScale * 0) + (rotateConditionTwo * 0) + translationX;
        const mY1 = (rotateConditionOne * 0) + (yScale * 0) + translationY;
        const mX2 = (xScale * imageData.width) + (rotateConditionTwo * imageData.height) + translationX;
        const mY2 = (rotateConditionOne * imageData.width) + (yScale * imageData.height) + translationY; */
      /* const mX1 = parseFloat(((xScale * 0) + (rotateConditionTwo * 0) + translationX).toFixed(4));
        const mY1 = parseFloat(((rotateConditionOne * imageData.width) + (yScale * imageData.height) + translationY).toFixed(4));
        const mX2 = parseFloat(((xScale * imageData.width) + (rotateConditionTwo * imageData.height) + translationX).toFixed(4));
        const mY2 = parseFloat(((rotateConditionOne * 0) + (yScale * 0) + translationY).toFixed(4));
        const imageExtentProjection = [mX1, mY2, mX2, mY1];
        let imageExtent = transformExtent(imageExtentProjection, 
          get(this.epsgCode), get(this.basemapProjection)); */
      // imageExtentProjection = [-117.19920039368107,33.54801166329121,-117.19650585449564,33.54850946796737];
      let isSingleImage = false;
      if (translationX === '0' && translationY === '0') {
        imageExtent = imageExtent;
        imageExtentProjection = imageExtent;
        isSingleImage = true;
      }

      this.imagePreviewProcess(
        imageExtent,
        imageExtentProjection,
        imageData,
        rotation,
        isSingleImage
      );
    });
  }


  private jpgImagePreview(jpgURL, metadata): any {
    const miniMapSourcePoints = [];
    const image = {
      url: jpgURL,
    };
    console.log(jpgURL);
    console.log(metadata);
    let jgwxData = metadata.geodata;
    const xmlData = metadata.xmldata;
    this.xmlParser(xmlData);
    if(xmlData!= "result") {
      this._getImageDimension(image).subscribe((imageData) => {
        let xScale;
        let yScale;
        let translationX;
        let translationY;
        let rotation; 
        // let rotateConditionOne;
        // let rotateConditionTwo;
        const mapExtent = [0 - (1 * imageData.width / 2), 0 - (1 * imageData.height / 2),
        0 + (1 * imageData.width / 2), 0 + (1 * imageData.height / 2)];
        // let mapExtent = [-771.5, -454, 771.5, 454];
        let imageWidth = imageData.width ; 
        let imageHeight = imageData.height;

        // Calculate centers
        let mapCenterX = mapExtent[0] + (mapExtent[2]-mapExtent[0])/2;
        let mapCenterY = mapExtent[1] + (mapExtent[3]-mapExtent[1])/2;

        let imageCenterX = imageWidth / 2;
        let imageCenterY = imageHeight / 2;

        // Calculate translation
        let translateX = mapCenterX - imageCenterX;
        let translateY = mapCenterY - imageCenterY;

        this.SourceGCPs.forEach((gcp) => {
          let point_0= gcp[0]+translateX; 
          let point_1 = gcp[1]-translateY;
          miniMapSourcePoints.push([point_0,point_1])
        })
        const mainmapSourcePointsProjection = [];
        this.TargetGCPs.forEach((points) => {
        mainmapSourcePointsProjection.push(
          transform(points,this.epsgCode,this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
        );
        })
        const transformation = new AffineTransformation();
        transformation.setControlPoints(miniMapSourcePoints, mainmapSourcePointsProjection);
        this.affinTransParams.matrix = transformation.matrix;
        this.affinTransParams.hasControlPoints = transformation.hasControlPoints;
        this.affinTransParams.rotation = transformation.getRotation();
        this.affinTransParams.scaling = transformation.getScale();
        this.affinTransParams.transformation = transformation.getTranslation();
        this.affinTransParams.similarity = transformation.similarity;
        if (this.commonService.isValid(jgwxData)) {
          console.log('VALID jgwxData : ', jgwxData);
          jgwxData = jgwxData.split('\n');
          // xScale = +jgwxData[0];
          // rotateConditionOne = +jgwxData[1];
          // rotateConditionTwo = +jgwxData[2];
          // yScale = +jgwxData[3];
          // translationX = +jgwxData[4];
          // translationY = +jgwxData[5];
          translationX = transformation.getTranslation()[0];
          translationY = transformation.getTranslation()[1];
          xScale = transformation.getScale()[0];
          yScale = transformation.getScale()[1];
          rotation = transformation.getRotation();
          console.log('tx,ty,sx,sy,r:',translationY,translationX,xScale,yScale,rotation)
        } else {
            console.log('INVALID jgwxData : ', jgwxData);
            xScale = 1;
            yScale = 1;
            translationX = 0;
            translationY = 0;
            rotation = 0;
            // rotateConditionOne = '0';
            // rotateConditionTwo = '0';
            // const msg = 'Cannot add ' + this.fileName + ' file due to unrecognized coordinate system. Please check your data';
            const msg =
              'Sorry! Cannot add the file ' +
              this.fileName +
              ' due to unrecognized coordinate system.';
            // this._getAlertMessage(this.alertComponent, msg);
            // return;
        }

          // ADDING THIS TO SUPPORT ONLY IMAGE TO LOAD ON MAP
        if (this.epsgCode === 'NO-EPSG') {
          console.log('INVALID epsg : ', jgwxData);
          xScale = 1;
          yScale = 1;
          translationX = 0;
          translationY = 0;
          rotation = 0;
          // rotateConditionOne = '0';
          // rotateConditionTwo = '0';
          this.epsgCode = this.basemapProjection;
        }
        const extent = this.imgProjCoordinateSysCal(
          translationX,
          translationY,
          xScale,
          yScale,
          rotation,
          imageData,
          jpgURL
        );
      });
    }
    else{
      this._getImageDimension(image).subscribe((imageData) => {
        let xScale;
        let yScale;
        let translationX;
        let translationY;
        let rotation; 
        if (this.commonService.isValid(jgwxData)) {
          console.log('VALID jgwxData : ', jgwxData);
          jgwxData = jgwxData.split('\n');
          xScale = +jgwxData[0];
          const rotateConditionOne = +jgwxData[1];
          const rotateConditionTwo = +jgwxData[2];
          yScale = +jgwxData[3];
          translationX = +jgwxData[4];
          translationY = +jgwxData[5];
          rotation = rotateConditionOne + rotateConditionTwo;
          console.log('tx,ty,sx,sy,r:',translationY,translationX,xScale,yScale,rotation)
        } else {
            console.log('INVALID jgwxData : ', jgwxData);
            xScale = 1;
            yScale = 1;
            translationX = 0;
            translationY = 0;
            rotation = 0;
            // rotateConditionOne = '0';
            // rotateConditionTwo = '0';
            // const msg = 'Cannot add ' + this.fileName + ' file due to unrecognized coordinate system. Please check your data';
            const msg =
              'Sorry! Cannot add the file ' +
              this.fileName +
              ' due to unrecognized coordinate system.';
            // this._getAlertMessage(this.alertComponent, msg);
            // return;
        }

          // ADDING THIS TO SUPPORT ONLY IMAGE TO LOAD ON MAP
        if (this.epsgCode === 'NO-EPSG') {
          console.log('INVALID epsg : ', jgwxData);
          xScale = 1;
          yScale = 1;
          translationX = 0;
          translationY = 0;
          rotation = 0;
          // rotateConditionOne = '0';
          // rotateConditionTwo = '0';
          this.epsgCode = this.basemapProjection;
        }
        const extent = this.imgProjCoordinateSysCal(
          translationX,
          translationY,
          xScale,
          yScale,
          rotation,
          imageData,
          jpgURL
        );
      })
    }
  }

  private imagePreviewProcess(
    imageExtent,
    imageExtentProjection,
    imageData,
    rotation,
    isSingleImage
  ): any {
    console.log('Data is ', imageExtent, imageExtentProjection);
    // imageExtent = [imageExtent[1], imageExtent[0], imageExtent[3], imageExtent[2]];
    // imageExtentProjection = [imageExtentProjection[1], imageExtentProjection[0], imageExtentProjection[3], imageExtentProjection[2]];


    const basemapProj = this.basemapService.getCurrentBasemap().getView().getProjection().getCode();
    const imageProjection = new Projection({
      code: 'orto-image',
      units: 'pixels',
      extent: buffer(imageExtentProjection, 512),
    });
    const imageProjectionNew = this.returnRotateProjection(
      basemapProj,
      rotation,
      imageExtentProjection
    );
    const imageSource = new Static({
      url: imageData.url,
      projection: imageProjectionNew,
      // Projection: isSingleImage === true ? imageProjection : imageProjectionNew,
      imageExtent: imageExtentProjection,
      imageSize: [imageData.width, imageData.height],
      imageSmoothing: true,
      imageLoadFunction: (image) => {
        image.getImage().src = imageData.url;
        console.log(
          'image resolution is ',
          image.resolution,
          getWidth(image.extent),
          image.image_.width
        );
        if (image.resolution === undefined) {
          // image.resolution = (image.extent[3] - image.extent[1]) / image.image_.height;
          image.resolution = getWidth(image.extent) / image.image_.width;
        }
        console.log(
          'image resolution-2 is ',
          image.resolution,
          imageData.width,
          image.image_
        );
        image.state = 2; // ImageState.LOADED;
        image.unlistenImage_();
        image.changed();
      },
    });
    const imagLayer = new ImageLayer({
      className: 'clipped',
      source: imageSource,
    });
    console.log(imagLayer);
    imagLayer.set('name', this.fileName);
    imagLayer.set('type', 'IMAGE');
    // imagLayer.setZIndex(1);
    imagLayer.setOpacity(0.7);
    this.basemap.addLayer(imagLayer);
    this.addLayerObjectsToTower();
    this.basemap.getLayers().forEach((currentLayer) => {
      if (this.fileName === currentLayer.values_.name) {
        console.log(
          'image layer is ',
          currentLayer,
          imagLayer,
          currentLayer.values_.source
        );
        const extentValue = currentLayer.values_.source.getImageExtent();
        console.log('what is ectent value ', extentValue, imageExtent);
        // this.basemap.values_.view.setCenter(imageExtent);
        this.basemap.getView().fit(imageExtentProjection);
        /* if(extentValue === undefined) {
          this.basemap.getView().fit(extent);
        } */
      }
    });
    // this.setUploadStatusInPercentage(90);

    /* setTimeout(() => {
      // this.setUploadStatusInPercentage(100);
    }, 1000); */
  }
  // ------------------------ ending jpeg/tiff file adding to map
  private _getAlertMessage(alertComponent, alertMessage): any {
    const alert = alertComponent;
    alert.setAlertMessage(alertMessage);
    // this.removeLayerFromClientList();
  }
  private removeLayerFromClientList(): any {
    this.geotowerService.clientObjList.forEach((obj, index) => {
      if (obj.name === this.fileName) {
        this.geotowerService.clientObjList.splice(index, 1);
      }
    });
    this.geotowerService.geotowerLayersList.forEach((obj, index) => {
      if (obj.name === this.fileName) {
        this.geotowerService.geotowerLayersList.splice(index, 1);
      }
    });
  }
  // this code for adding Layer obj after showing the layer on map..
  private addLayerObjectsToTower(): any {
    this.geotowerService.clientObjList.push(this.layerObj);
    this.geotowerService.geotowerLayersList.push(this.layerObj);
  }
  private randomRainbowColor(min, max): any {
    return this.Colors[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  returnRotateProjection(projection, rotation, extent): any {
    const normalProjection = get(this.basemapService.getCurrentBasemap().getView().getProjection().getCode());
    const rotatedProjection = new Projection({
      code: normalProjection.getCode() + ':' + rotation.toString() + ':' + extent.toString(),
      units: normalProjection.getUnits(),
      extent
    });
    addProjection(rotatedProjection);
    addCoordinateTransforms(
      this.basemapService.getCurrentBasemap().getView().getProjection().getCode(),
      rotatedProjection,
      coordinate => {
        // return this.rotateTransform(transform(coordinate, this.baseMapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:3857'), rotation, extent);
        return this.rotateTransform(coordinate, rotation, extent);
      },
      coordinate => {
        return this.normalTransform(coordinate, rotation, extent);
        // return this.normalTransform(transform(coordinate, 'EPSG:3857', this.baseMapService.getCurrentBasemap().getView().getProjection().getCode()), rotation, extent);
      }
    );
    addCoordinateTransforms(
      this.basemapService.getCurrentBasemap().getView().getProjection().getCode(),
      rotatedProjection,
      coordinate => {
        return this.rotateTransform(coordinate, rotation, extent);
        // return this.rotateTransform(transform(coordinate, 'EPSG:3857', this.baseMapService.getCurrentBasemap().getView().getProjection().getCode()), rotation, extent);
      },
      coordinate => {
        return this.normalTransform(coordinate, rotation, extent);
        // return transform(this.normalTransform(coordinate, rotation, extent), this.baseMapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:3857');
      }
    );
    console.log('projection value ', proj4);
    if (typeof proj4 !== 'undefined') {
      
      const projCodes = Object.keys(proj4.defs);
      projCodes.forEach((code) => {
        console.log('what is this code inside ,', projCodes, code, projection);
        const proj4Projection = get(code);
        if (!getTransform(proj4Projection, rotatedProjection)) {
          
          addCoordinateTransforms(
            proj4Projection,
            rotatedProjection,
            (coordinate) => {
              return this.rotateTransform(
                transform(coordinate, proj4Projection, projection), rotation, extent);
            },
            (coordinate) => {
              return transform(
                this.normalTransform(coordinate, rotation, extent), projection, proj4Projection);
            }
          );
        }
      });
    }
    return rotatedProjection;
  }
  rotateTransform(coordinate, rotation, extent): any {
    // console.log('what is inside RT ', coordinate, rotation, getCenter(extent));
    return this.rotateCoordinate(coordinate, rotation, getCenter(extent));
  }
  normalTransform(coordinate, rotation, extent): any {
    /* console.log('what is inside NT ',  coordinate, rotation, getCenter(extent));
    console.log(coordinate); */
    return this.rotateCoordinate(coordinate, -rotation, getCenter(extent));
  }
  rotateCoordinate(coordinate, angle, anchor): any {
    // console.log('what is inside RC ', coordinate, angle, anchor);
    const coord = rotate(
      [coordinate[0] - anchor[0], coordinate[1] - anchor[1]],
      angle
    );
    return [coord[0] + anchor[0], coord[1] + anchor[1]];
  }
  
  // // Here New code for rotation image raster
  // returnRotateProjection(projection, angle, extent): any {
  //   // extent = [extent[1], extent[0], extent[3], extent[2]];
  //   function rotateCoordinate(coordinate, angle, anchor) {
  //     var coord = rotate(
  //       [coordinate[0] - anchor[0], coordinate[1] - anchor[1]],
  //       angle
  //     );
  //     return [coord[0] + anchor[0], coord[1] + anchor[1]];
  //   }
  //   function rotateTransform(coordinate) {
  //     return rotateCoordinate(coordinate, angle, getCenter(extent));
  //   }
  //   function normalTransform(coordinate) {
  //     return rotateCoordinate(coordinate, -angle, getCenter(extent));
  //   }
  //   var normalProjection = get(projection);
  //   var rotatedProjection = new Projection({
  //     code:
  //       normalProjection.getCode() +
  //       ':' +
  //       angle.toString() +
  //       ':' +
  //       extent.toString(),
  //     units: normalProjection.getUnits(),
  //     extent: extent,
  //   });
  //   addProjection(rotatedProjection);
  //   addCoordinateTransforms(
  //     projection,
  //     rotatedProjection,
  //     rotateTransform,
  //     normalTransform
  //   );
  //   addCoordinateTransforms(
  //     'EPSG:4326',
  //     rotatedProjection,
  //     function (coordinate) {
  //       console.log(coordinate, extent);
  //       return rotateTransform(transform(coordinate, 'EPSG:4326', projection));
  //     },
  //     function (coordinate) {
  //       return transform(normalTransform(coordinate), projection, 'EPSG:4326');
  //     }
  //   );
  //   addCoordinateTransforms(
  //     'EPSG:3857',
  //     rotatedProjection,
  //     function (coordinate) {
  //       return rotateTransform(transform(coordinate, 'EPSG:3857', projection));
  //     },
  //     function (coordinate) {
  //       return transform(normalTransform(coordinate), projection, 'EPSG:3857');
  //     }
  //   );
  //   if (typeof proj4 !== 'undefined') {
  //     const projCodes = Object.keys(proj4.defs);
  //     projCodes.forEach((code) => {
  //       const proj4Projection = get(code);
  //       console.log(
  //         'Condition for getTrnas?: ',
  //         code,
  //         getTransform(proj4Projection, rotatedProjection)
  //       );
  //       if (!getTransform(proj4Projection, rotatedProjection)) {
  //         console.log(
  //           'which projection doing trans: ',
  //           proj4Projection,
  //           rotatedProjection
  //         );
  //         addCoordinateTransforms(
  //           proj4Projection,
  //           rotatedProjection,
  //           (coordinate) => {
  //             return rotateTransform(
  //               transform(coordinate, proj4Projection, projection)
  //             );
  //           },
  //           (coordinate) => {
  //             return transform(
  //               normalTransform(coordinate),
  //               projection,
  //               proj4Projection
  //             );
  //           }
  //         );
  //       }
  //     });
  //   }
  //   return rotatedProjection;
  // }

  publishLayers(filename,newExtent){  
    console.log(filename)
    let url = "https://qa.fuse.earth:4433/publishGeotiffLayers?filename=" + filename
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
    .then(response => response.text())
    .then(result => {
      // 
      console.log(result)
      this.loadWms(result,newExtent)
    })
    .catch(error => console.log('error', error));
  }
  
  loadWms(layerName,newExtent): any {
    const layer = 'giskernel:' + layerName;
    const tiled = new TileLayer({
      extent:newExtent,
      visible: true,
      title: layerName,
      name: this.fileName,
      source: new TileWMS({
        url: 'http://146.190.140.219:8282/geoserver/giskernel/wms',
        params: {
          'FORMAT': 'image/png', 
          'VERSION': '1.1.0',
          tiled: true,
          "STYLES": '',
          "LAYERS": layer,
        },
      }),
      zIndex:2
    });
    this.basemapService.getCurrentBasemap().addLayer(tiled);
    this.basemap.getView().fit(newExtent);
    // this.basemap.getCurrentBasemap().getView().setZoom(this.basemap.getCurrentBasemap().getView().getZoom() - 1);
    this.addLayerObjectsToTower();
    // $('.loader').fadeOut()
  }

  tFFImagePreview(jpgURL, metadata): any {
    let extent, epsg, newExtent ;
    fetch(jpgURL)
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        return fromArrayBuffer(arrayBuffer);
      })
      .then((tiff) => {
        return tiff.getImage();
      })
      .then((image) => {
        extent = image.getBoundingBox();
        epsg = this.processGetTiffEpsg(image);
        
        return image;
      })
      .then((image) => {
        let url = "https://qa.fuse.earth:4433/addGeotiff?url=" + encodeURIComponent(jpgURL)
        fetch(url, {
          method: 'GET',
          redirect: 'follow'
        })
        .then(response => response.text())
        .then(result => {
          console.log(result)
          console.log(result.length)
          if(result.length > 0){
            console.log('geopdf')
            newExtent = transformExtent(extent,get(this.epsgCode),get(this.basemapProjection));
            this.publishLayers(result,newExtent)
          }
        })    
      })
    .catch((error) => {
      console.log(error);
      alert(error);
    });
  }



  async processGetTiffEpsg(image) {
    let code = 4326;
    let epsg = `EPSG:${code}`;
    if (image.geoKeys !== null) {
      code =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;
      epsg = `EPSG:${code}`;
      if (!proj4.defs(epsg)) {
        // const response = await fetch(`//epsg.io/${code}.proj4`);
        // proj4.defs(epsg, await response.text());
        this.getProjDef(epsg).subscribe((projdef) => {
        proj4.defs(epsg, projdef);
        register(proj4);
        return epsg;
      })
        // const response = await fetch(`//epsg.io/${code}.proj4`);
        // proj4.defs(epsg, await response.text());
      }
      // this.getProjDef(this.epsgCode).subscribe((projdef) => {
      //   proj4.defs(this.epsgCode, projdef);
      //   register(proj4);
      // })
    }
    this.epsgCode = 'EPSG:' + code;
    return epsg;
  }

  private previewGeoJosn_Json_Files_new(layerObj, fileType): any {
    const tempThis = this;
    console.log(layerObj);
    $.getJSON(layerObj.firebaseUrl, function (data) {
      console.log('state data is ', data);
      tempThis.previewGeoJosn_Json_Files(data, fileType);
    });
  }

  private previewGeoJosn_Json_Files(metadata, fileType): any {
    // let geojson_json_Data = metadata.geodata;
    let geojson_json_Data = metadata;
    if (this.commonService.isValid(geojson_json_Data)) {
      // console.log('VALID geojson_json : ', geojson_json_Data);
      // console.log('parsing ', JSON.parse(geojson_json_Data));
      let featureCollection;
      if (fileType === this.JSON_EXTENSION_CONSTANT) {
        featureCollection = {
          type: 'FeatureCollection',
          // "features": JSON.parse(geojson_json_Data)
          features: geojson_json_Data,
        };
      } else {
        featureCollection = geojson_json_Data;
      }
      console.log('featureCollection: ', featureCollection);
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(featureCollection, {
          featureProjection: this.basemapProjection,
        }),
      });
      const fill = new Fill({
        color: 'rgba(255, 255, 255, 1)',
      });
      const stroke = new Stroke({
        // color: '#319FD3',
        color: this.randomRainbowColor(0, 6),
        width: 1,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        /* style: this.getShapeStyle() */
        style: new Style({
          image: new CircleStyle({
            fill,
            stroke,
            radius: 5,
          }),
          fill,
          stroke,
        }),
      });
      vectorLayer.set('name', this.fileName);
      // vectorLayer.setStyle(vectorLayer.getStyle());
      vectorLayer.setOpacity(0.7);
      this.basemap.addLayer(vectorLayer);
      this.addLayerObjectsToTower();
      console.log(
        'getting the styles ',
        vectorLayer.getStyle().defaultStyles,
        vectorLayer.getStyleFunction()
      );
      // new code for zoom to extent
      this.basemap.getLayers().forEach((currentLayer) => {
        if (this.fileName === currentLayer.values_.name) {
          const extentValue = currentLayer.values_.source.getExtent();
          this.basemap.getView().fit(extentValue);
          this.basemap.getView().setZoom(this.basemap.getView().getZoom() - 1);
        }
      });
    } else {
      console.log('INVALID jgwxData : ', geojson_json_Data);
      // const msg = 'Cannot add ' + this.fileName + ' file due to unrecognized coordinate system. Please check your data';
      const msg =
        'Sorry! Cannot add the file ' +
        this.fileName +
        ' due to geojson not valid.';
      this._getAlertMessage(this.alertComponent, msg);
      // return;
    }
  }

  private preview_XLSX_CSV_File(layerObject): any {
    console.log(
      'preview data ',
      layerObject.metadata,
      JSON.parse(layerObject.metadata)
    );
    const places = JSON.parse(layerObject.metadata).data;
    const name = layerObject.files.name; //.split('.')[0] + layerObject.metadata.id + '.' + layerObject.files.name.split('.')[1];
    let placesFeatures = [];
    places.forEach((p) => {
      let latValue = p.Latitude;
      let longValue = p.Longitude;
      let latlng = [p.Longitude, p.Latitude];
      if ((p.Latitude < -90 || p.Latitude > 90) && (p.Longitude < -180 || p.Longitude > 180)) {
        console.log('its not Latitude OR Longitude ')
        if(this.basemapProjection !== 'EPSG:3857') {
          latlng = transform(latlng, 'EPSG:3857' ,this.basemapProjection);
        }
      } else {
        console.log('its Latitude OR Longitude ')
        if(this.basemapProjection !== 'EPSG:4326') {
          latlng = transform(latlng, 'EPSG:4326' ,this.basemapProjection);
        }
      }
      placesFeatures.push(
        new Feature({
          geometry: new Point(latlng),
          // id: p.Name + ' : ' + p.Address + ' : ' + p.City	+ ' : ' + p.State	+ ' : ' + p.Zip
          id: p,
        })
      );
    });
    var styleGreen = new Style({
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color: 'blue',
        }),
      }),
    });
    setTimeout(() => {
      let placesSource = new VectorSource({ features: placesFeatures });
      const vectorLayer = new VectorLayer({
        source: placesSource,
        name: name,
        style: styleGreen,
      });
      this.basemapService.getCurrentBasemap().addLayer(vectorLayer);
      this.addLayerObjectsToTower();
      // new code for zoom to extent
      this.basemap.getLayers().forEach((currentLayer) => {
        if (name === currentLayer.values_.name) {
          const extentValue = currentLayer.values_.source.getExtent();
          this.basemap.getView().fit(extentValue);
          this.basemap.getView().setZoom(this.basemap.getView().getZoom() - 1);
        }
      });
    }, 500);
  }

  private preview_PDF_File(url): any {
    this.addLayerObjectsToTower()

    // var fs = require('fs');
    /* var PDFExtract = require('../../../../node_modules/pdf.js-extract/lib').PDFExtract;
    var pdfExtract = new PDFExtract();
    pdfExtract.extract('url', {} , function (err, data) {
        if (err) return console.log(err);
        
        console.log(JSON.stringify(data, null, '\t'));
    }); */
  }
  

  // End Main Class
}
