import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Overlay from 'ol/Overlay';
import { BasemapService } from 'src/app/basemap/basemap.service';
import {Fill, RegularShape, Stroke, Style, Circle} from 'ol/style.js';

@Component({
  selector: 'app-geotables',
  templateUrl: './geotables.component.html',
  styleUrls: ['./geotables.component.scss']
})
export class GeotablesComponent implements OnInit {
  @ViewChild('container') _containerEl: ElementRef;
  @ViewChild('closer') _closerEl: ElementRef;
  @ViewChild('selectedColumEle') _selectedColumEle: ElementRef;
  private _popupOverlay: Overlay;
  tableData = [];
  tableColumns = [];
  columnsList = [];
  rowsList = [];
  selectedColumnRowsList = [];
  layer:any;
  selectedColumn;
  activerowSelection = false;
  activeSymbleColorSelection = false;
  symColor = '#000000'
  symColorOnlySelected = '#000000'
  clickedRowIndex = 0;
  symbolNameForMap;
  constructor(private basemap: BasemapService) { }

  ngOnInit(): void {
  }

  changeSymbol(symbolName) {
    console.log(symbolName)
    this.symbolNameForMap = symbolName;
    this.selectedColumnRowsList.forEach( (row, index) => {
      // console.log(index, row, this.clickedRowIndex)
      // console.log(document.getElementById('selectedRowID'+index))
      if('selectedRowID'+this.clickedRowIndex === 'selectedRowID'+index) {
        //console.log('both are matched ', document.getElementById('selectedRowID'+index).className)
        document.getElementById('selectedRowID'+index).className = '';
        document.getElementById('selectedRowID'+index).className = 'fa '+symbolName+' fa-2x';
        // document.getElementById('selectedRowID'+index).className = 'fa fa-square fa-2x';
        // console.log(document.getElementById('selectedRowID'+index).className)
        
        const stroke = new Stroke({color: this.symColor, width: 2});
        const fill = new Fill({color: this.symColor});
        let style_ = new Style({
          image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 5,
            radius: 10,
            radius2: 4,
            angle: 0,
          }),
        });
        if(symbolName == 'fa-star') {
          // star style
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 10,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(symbolName == 'fa-circle') {
          // circle
          style_ = new Style({
            image: new Circle({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 5,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(symbolName == 'fa-square') {
          // square
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              radius: 10 / Math.SQRT2,
              radius2: 10,
              points: 4,
              angle: 0,
              scale: [1, 0.5],
            }),
          });
        } else if(symbolName == 'fa-octagon') {
          // octagon
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 10,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(symbolName == 'fa-plus') {
          // plus
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0,
            }),
          });
        }
        this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
          console.log('layer name ', this.layer, layerObj, row)
          if (layerObj !== undefined) {
            if (layerObj.values_.name === this.layer.name) {
              console.log('selected Layer ', this.layer, layerObj)
              console.log('features ', layerObj.getSource().getFeatures())
              layerObj.getSource().getFeatures().forEach((feature, index) => {
                if(feature.values_.id != undefined) {
                if(feature.values_.id[(this.selectedColumn)] === row) {
                  // console.log("its not undifined & matched")
                  // console.log(feature.values_.id)
                  // console.log(feature.values_.id[(this.selectedColumn)])
                  // console.log(row)
                  feature.setStyle(style_)
                } 
              } else {
                if(feature.values_[(this.selectedColumn)] === row) {
                  // console.log("its not undifined & matched")
                  feature.setStyle(style_)
                }
                this.layer.fexObj.fex.Depiction.style = style_;
              }
                
                // const featureAttr = (layer.fileType === '.xlsx') ? feature.values_.id : feature.values_;
                // if (index === 0) {
                //   for (const [key, value] of Object.entries(featureAttr)) {
                //     console.log(key,value)
                //     featurePropertieHeders.push(key);
                //   }
                // }
                // featureProperties.push(featureAttr);
              });
            }
          }
        });
      }
    })
    
  }

  changeColor(colorCode) {
    console.log('changed color ', colorCode);
    this.symColor = colorCode;
    console.log(this._selectedColumEle.nativeElement)
    console.log(this._selectedColumEle.nativeElement.className)
    // this.selectedColumn = colorCode;
    // this.symColorOnlySelected = colorCode;
    // this._selectedColumEle.nativeElement.style.color = colorCode;
    this.selectedColumnRowsList.forEach( (row, index) => {
      console.log(index, row, this.clickedRowIndex)
      console.log(document.getElementById('selectedRowID'+index))
      if('selectedRowID'+this.clickedRowIndex === 'selectedRowID'+index) {
        console.log('both are matched ', document.getElementById('selectedRowID'+index))
        document.getElementById('selectedRowID'+index).style.color = colorCode;
        const stroke = new Stroke({color: this.symColor, width: 2});
        const fill = new Fill({color: this.symColor});
        let style_ = new Style({
          image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 5,
            radius: 10,
            radius2: 4,
            angle: 0,
          }),
        });
        if(this.symbolNameForMap == 'fa-star') {
          // star style
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 10,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(this.symbolNameForMap == 'fa-circle') {
          // circle
          style_ = new Style({
            image: new Circle({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 5,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(this.symbolNameForMap == 'fa-square') {
          // square
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              radius: 10 / Math.SQRT2,
              radius2: 10,
              points: 4,
              angle: 0,
              scale: [1, 0.5],
            }),
          });
        } else if(this.symbolNameForMap == 'fa-octagon') {
          // octagon
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 5,
              radius: 10,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if(this.symbolNameForMap == 'fa-plus') {
          // plus
          style_ = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0,
            }),
          });
        }
        this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
          console.log('layer name ', this.layer, layerObj, row)
          if (layerObj !== undefined) {
            if (layerObj.values_.name === this.layer.name) {
              console.log('selected Layer ', this.layer, layerObj)
              console.log('features ', layerObj.getSource().getFeatures())
              layerObj.getSource().getFeatures().forEach((feature, index) => {
                if(feature.values_.id != undefined) {
                  if(feature.values_.id[(this.selectedColumn)] === row) {
                    // console.log("its not undifined & matched")
                    // console.log(feature.values_.id)
                    // console.log(feature.values_.id[(this.selectedColumn)])
                    // console.log(row)
                    feature.setStyle(style_)
                  } 
                }
                else if(feature.values_[(this.selectedColumn)] === row) {
                  // console.log("its not undifined & matched")
                  feature.setStyle(style_)
                }
                this.layer.fexObj.fex.Depiction.style = style_;
                // const featureAttr = (layer.fileType === '.xlsx') ? feature.values_.id : feature.values_;
                // if (index === 0) {
                //   for (const [key, value] of Object.entries(featureAttr)) {
                //     console.log(key,value)
                //     featurePropertieHeders.push(key);
                //   }
                // }
                // featureProperties.push(featureAttr);
              });
            }
          }
        });
      }
    })
  }

  uniqByFilter<T>(array: T[]) {
    return array.filter((value, index) => array.indexOf(value) === index);
  }

  onChange(columnName) {
    console.log('Column selected ', columnName);
    this.selectedColumn = columnName;
    this.selectedColumnRowsList = [];
    let result;
    this.rowsList.forEach( row => {
      console.log('rows are Here ', row);
      console.log(' selected column & row ', row[this.selectedColumn])
      this.selectedColumnRowsList.push(row[this.selectedColumn])      
      result = Array.from(this.selectedColumnRowsList.reduce((m, t) => m.set(row[this.selectedColumn], t), new Map()).values());
      console.log(result)
    })
    console.log(this.uniqByFilter(this.selectedColumnRowsList))
    this.selectedColumnRowsList = this.uniqByFilter(this.selectedColumnRowsList);
  }

  changeSymbolColor(index) {
    console.log("changing Symbol & Colors");
    this.activeSymbleColorSelection = true;
    this.clickedRowIndex = index;
  }

  generategeotable() {
    console.log("calling generate geotable columns");
    this.activerowSelection = true;

  }

  getgeotablesPopup(rowsList, columnsList, layer) {
    this._popupOverlay = new Overlay({
      element: this._containerEl.nativeElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this._closerEl.nativeElement.onclick = () => {
      this.close();
    };
    this.columnsList = columnsList;
    this.layer = layer;
    this.rowsList = rowsList;
    return this._popupOverlay;
  }

  public close() {
    this._popupOverlay.setPosition(undefined);
    this._closerEl.nativeElement.blur();
    this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === 'tower_heatmap') {
          this.basemap.getCurrentBasemap().removeLayer(layerObj);
        }
      }
    });
    return false;
  }

}
