import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild ,Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { formatDate } from '@angular/common';
import { FeAdminDashboardService } from '../Services/feAdminDashborad.service';
export interface CardData {
  imageId: string;
  state: "default" | "flipped" | "matched";
}


@Component({
  selector: 'app-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  styleUrls: ['./subscription-billing.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class SubscriptionBillingComponent implements OnInit {
  @Input() userProfile: any = {};
  selectedexploremonth: any;
  selectedexpplanprice: any;
  flipStarter: string = 'inactive';
  flipExplore: string = 'inactive';
  flipEngage: string = 'inactive';
  packages: string[];
  showmenue: string[];
  months: string[];
  exploreplan: any;
  
  engageplan: any;
  selectedengageprice: string;
  subscplanForm: FormGroup;
  paymentdetails: FormGroup;
  showstartertick=false;
  showexploretick=false;
  showengagetick=false;
  selectedmonth: any;
  selectedengagemonth: any;
  
  package: { Free: { index: number; month: string; amount: string; days:number }[];Explore: { index: number; month: string; amount: string; days:number }[]; Engage: { index: number; month: string; amount: string;  days:number}[]; }[];
  packagekeys: string[];
  finalselectedprice: any;
  finalselectedmonth: string;
  tax=20;
  totalprice: any;
  finalselectedprice2: any;
  dayss: Date;
  date: any;
  selectedengagedays: number;
  selectedexploredays: number;
  d: any;
  Difference_In_Time: number;
  Difference_In_Days: number;
  enddate: string;
  showpaymentdetails=false;
  showpackagedetails=true;
  confirmselect=false;
  showgreetings= false;
  orgpackage: { Free: { index: number; month: string; amount: string; days: number; }[]; Explore: { index: number; month: string; amount: string; days: number; }[]; Engage: { index: number; month: string; amount: string; days: number; }[]; }[];
  totalAmount: number;
  isTotalPrice: boolean;
  finalpackageselected: string;
  cardno: any;
  finalpackagename: any;
  detailno: any;
  toggleFlipStarter(e) 
  {
    this.detailno=e
    console.log(this.detailno,"i am detail")
    this.packages[e]["Flipstate"] = (this.packages[e]["Flipstate"] == 'inactive')? 'active' : 'inactive';
    console.log("fliped");
  }


  toggleFlipExplore(){
    this.flipExplore = (this.flipExplore == 'inactive') ? 'active' : 'inactive';
  }

  toggleFlipEngage(){
    this.flipEngage = (this.flipEngage == 'inactive') ? 'active' : 'inactive';
  }
  constructor( private feAdminDashboardService: FeAdminDashboardService) {
    
    this.package=[{
      "Free":[
        {index: 1, month: '1 month' , amount: '$ 0', days: 30 },
        {index: 2, month: '3 months' , amount: '$ 0', days: 90 },
        {index: 3, month: '6 months' , amount: '$ 0', days: 180 },
        {index: 4, month: '9 months' , amount: '$ 0', days: 270 },
        {index: 5, month: '12 months' , amount: '$ 0', days: 360 }
      ], 
      "Explore":[
        {index: 1, month: '1 month' , amount: '$ 15', days: 30  },
        {index: 2, month: '3 months' , amount: '$ 45', days: 90  },
        {index: 3, month: '6 months' , amount: '$ 90', days: 180  },
        {index: 4, month: '9 months' , amount: '$ 135', days: 270  },
        {index: 5, month: '12 months' , amount: '$ 180', days: 360  }
      ], 
      "Engage":[
        {index: 1, month: '1 month' , amount: '$ 35', days: 30  },
        {index: 2, month: '3 months' , amount: '$ 105', days: 90  },
        {index: 3, month: '6 months' , amount: '$ 210', days: 180  },
        {index: 4, month: '9 months' , amount: '$ 315' , days: 270 },
        {index: 5, month: '12 months' , amount: '$ 420', days: 360  }
      ]
   } ]
   this.orgpackage=[{
    "Free":[
      {index: 1, month: '1 month' , amount: '$ 45', days: 30 },
      {index: 2, month: '3 months' , amount: '$ 135', days: 90 },
      {index: 3, month: '6 months' , amount: '$ 270', days: 180 },
      {index: 4, month: '9 months' , amount: '$ 405', days: 270 },
      {index: 5, month: '12 months' , amount: '$ 540', days: 360 }
    ], 
    "Explore":[
      {index: 1, month: '1 month' , amount: '$ 95', days: 30  },
      {index: 2, month: '3 months' , amount: '$ 285', days: 90  },
      {index: 3, month: '6 months' , amount: '$ 570', days: 180  },
      {index: 4, month: '9 months' , amount: '$ 855', days: 270  },
      {index: 5, month: '12 months' , amount: '$ 1140', days: 360  }
    ], 
    "Engage":[
      {index: 1, month: '1 month' , amount: '$ 395', days: 30  },
      {index: 2, month: '3 months' , amount: '$ 1185', days: 90  },
      {index: 3, month: '6 months' , amount: '$ 2370', days: 180  },
      {index: 4, month: '9 months' , amount: '$ 3555' , days: 270 },
      {index: 5, month: '12 months' , amount: '$ 4740', days: 360  }
    ]
 } ]

   this.packagekeys= Object.keys(this.package[0]);
    this.months=['1 month','3 months','6 months','9 months','12 months']
    
    // this.exploreplan=[
    //   {index: 1, month: '1 month' , amount: '$ 200' },
    //   {index: 2, month: '3 months' , amount: '$ 600' },
    //   {index: 3, month: '6 months' , amount: '$ 1200' },
    //   {index: 4, month: '9 months' , amount: '$ 1800' },
    //   {index: 5, month: '12 months' , amount: '$ 2400' }
    // ]; 
    // this.engageplan=[
    //   {index: 1, month: '1 month' , amount: '$ 500' },
    //   {index: 2, month: '3 months' , amount: '$ 1500' },
    //   {index: 3, month: '6 months' , amount: '$ 3000' },
    //   {index: 4, month: '9 months' , amount: '$ 4500' },
    //   {index: 5, month: '12 months' , amount: '$ 6000' }
    // ]; 
    

   }
  
  ngOnInit(): void {
    this.selectedexpplanprice=this.package[0].Explore[0]["amount"];
    this.selectedengageprice=this.package[0].Engage[0]["amount"];
    this.finalselectedprice=0;
    this.finalselectedmonth="none";
    


    this.enddate= '11/11/2023';
    var dateArray = this.enddate.split('/');
    var convertedDate = new Date(dateArray[1]+'-'+dateArray[0]+' '+dateArray[2]);
    var date1 = new Date();
    var date2 = new Date(convertedDate);
      console.log(date1,"start date")
      console.log(date2,"end date")
    // To calculate the time difference of two dates
    this.Difference_In_Time = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    this.Difference_In_Days = Math.floor(this.Difference_In_Time / (1000 * 3600 * 24));
      
    //To display the final no. of days (result)
    console.log("Total number of days between dates  <br>"
               + date1 + "<br> and <br>" 
               + date2 + " is: <br> " 
               + this.Difference_In_Days);
               this.getPackageDetails();
  }
  getPackageDetails(): any {
    this.feAdminDashboardService.getAllPackagesWithFeaturesList().subscribe(response => {
      console.log('response data from all packages with features', response);
      this.packages = response;
      console.log(this.packages[0]["amount"],"i am packagesignup")
      for(let i=0;i<this.packages.length;i++){
        this.packages[i]["Totalamount"]=this.packages[i]["amount"];
        this.packages[i]["Flipstate"]='inactive';
        this.packages[i]["Month selected"]=this.months[0];
      }
      console.log(this.packages[0],"i am package")
    });
  }
//   Selectexploremonth(e){
//     this.selectedexploremonth=e;
//     this.selectedexpplanprice=this.package[0].Explore[0]["amount"]
//     console.log(this.package[0].Explore[0]["month"])
//     console.log( this.selectedexpplanprice)
//     for(let x=0;x<this.package[0].Explore.length;x++){
//       if(this.package[0].Explore[x]["month"]==e){
//         this.selectedexpplanprice=this.package[0].Explore[x]["amount"]
//         this.selectedexploredays=this.package[0].Explore[x]["days"]
//         console.log(this.selectedexploredays,"hhhhhhh")
//       }
//       this.finalselectedprice=this.selectedexpplanprice;
      
//     this.finalselectedprice2=JSON.stringify(this.finalselectedprice)
//     this.finalselectedprice2=this.finalselectedprice2.slice(2,this.finalselectedprice2.length-1)
//     this.finalselectedprice2=Number(this.finalselectedprice2)
//     this.totalprice=this.finalselectedprice2+this.tax
//     console.log(this.totalprice)
//     this.confirmselect=true;
  
     
//     }
//   //   for(let x=0;x<this.exploreplan.length;x++){
//   //   if(this.exploreplan[e][0]==e){
//   //     this.selectedexpplanprice=this.exploreplan[e][1];
//   //     console.log(this.selectedexpplanprice);
//   //   }
//   // }
 
//   this.finalselectedmonth=this.selectedexploremonth;
// }
// Selectengagemonth(e){
//   this.selectedengagemonth=e;
//   this.selectedengageprice=this.package[0].Engage[0]["amount"]
//   console.log(this.package[0].Engage[1]["month"])
//   console.log( this.selectedengageprice)
//   for(let x=0;x<this.package[0].Engage.length;x++){
//     if(this.package[0].Engage[x]["month"]==e){
//       this.selectedengageprice=this.package[0].Engage[x]["amount"]
//       this.selectedengagedays=this.package[0].Engage[x]["days"]
//       console.log(this.selectedengagedays,"hhhhh")
//     }
//     this.finalselectedprice=this.selectedengageprice;
//     this.finalselectedprice2=JSON.stringify(this.finalselectedprice)
//     this.finalselectedprice2=this.finalselectedprice2.slice(2,this.finalselectedprice2.length-1)
//     this.finalselectedprice2=Number(this.finalselectedprice2)
//     this.totalprice=this.finalselectedprice2+this.tax
//     console.log(this.totalprice)
//     this.confirmselect=true;
//     // this.totalprice=this.finalselectedprice2 + this.tax 
//   }
// //   for(let x=0;x<this.exploreplan.length;x++){
// //   if(this.exploreplan[e][0]==e){
// //     this.selectedexpplanprice=this.exploreplan[e][1];
// //     console.log(this.selectedexpplanprice);
// //   }
// // }



// this.finalselectedmonth= this.selectedengagemonth;


// }
// selectstarterpack(){
//   this.showstartertick=true;
//   this.showexploretick=false;
//   this.showengagetick=false;
//   console.log("Selected pack = Starter")
//   console.log("Selected months =", this.selectedmonth);
//   console.log("Price = $ 0",)
//     // $(".subscplan").css({"border" : "5px solid white"})
//     this.confirmselect=true;
// }

// selectexplorepack(){
//   this.showstartertick=false;
//   this.showexploretick=true;
//   this.showengagetick=false;
//   console.log("Selected pack = Explore")
//   console.log("Selected months =", this.selectedexploremonth);
//   console.log("Price = ", this.selectedexpplanprice);
 

// }
// selectengagepack(){
//   this.showstartertick=false;
//   this.showexploretick=false;
//   this.showengagetick=true;
//   console.log("Selected pack = Engage")
//   console.log("Selected months =", this.selectedengagemonth);
//   console.log("Price = ", this.selectedengageprice)
  
  
// }
Selectexploremonth(month, amount,ii) {
  this.selectedexploremonth = month;
  console.log(ii,"li")
  if(ii==1){
    this.selectedexpplanprice=95
  }
  else if(ii==2){
    this.selectedexpplanprice=395
  }
  else if(ii==0){
    this.selectedexpplanprice=45
  }
  else{
    this.selectedexpplanprice = this.packages[ii]["amount"];
  }
  
  console.log(this.selectedexpplanprice)
  /* for (let x = 0; x < this.package[0].Explore.length; x++) {
    if (this.package[0].Explore[x]["month"] == month) {
      this.selectedexpplanprice = this.package[0].Explore[x]["amount"]
    }
    this.finalselectedprice = this.selectedexpplanprice;

    this.finalselectedprice2 = JSON.stringify(this.finalselectedprice)
    this.finalselectedprice2 = this.finalselectedprice2.slice(2, this.finalselectedprice2.length - 1)
    this.finalselectedprice2 = Number(this.finalselectedprice2)
    this.totalprice = this.finalselectedprice2 + this.tax
    console.log(this.totalprice)
  } */
  console.log(this.packages,"unupdated package");

  this.totalprice = (this.selectedexpplanprice) * (this.selectedexploremonth.split(' ')[0]) + this.tax
 
  this.totalAmount = (this.selectedexpplanprice) * (this.selectedexploremonth.split(' ')[0]);
  this.packages[ii]["Totalamount"]=this.totalAmount;
  console.log(this.packages,"updated package");
 this.packages[ii]["Month selected"]=this.selectedexploremonth
  this.isTotalPrice = false;
  this.finalpackageselected=this.packages[ii];
  console.log(this.finalpackageselected,"this is the package selected by user");
}
selectstarterpack(e) {
  console.log(this.packages["packageId"],"asdf");
  this.cardno=e;
  this.finalpackageselected=this.packages[e]
  // for(let i=0;i<this.packages.length;i++){
  //   if(this.cardno==this.packages["packageId"]){
  //     this.showstartertick=!this.showstartertick;
  //   }
  //   else{
  //     this.showstartertick=false;
  //   }
  // }
  this.finalselectedmonth = this.packages[e]["Month selected"];
  this.finalpackagename=this.packages[e]["name"]
  this.showstartertick = !this.showstartertick;
  this.totalprice=this.packages[e]["Totalamount"]+this.tax
  
  this.showstartertick = !this.showstartertick;
  console.log(this.finalpackageselected,"selected package by user")
  
  // $(".subscplan").css({"border" : "5px solid white"})
}
Nextpage(){
  this.showpaymentdetails=true;
  this.showpackagedetails=false;
}
showalert(){
  alert("Please select the Package and Months ")
}
Backpage(){
  this.showpaymentdetails=false;
  this.showpackagedetails=true;

}
Submitpage(){
  this.showgreetings=true
}
}
