import { Injectable } from '@angular/core';
import { BasemapService } from '../basemap/basemap.service';
import { GeometryTool } from './tools/geometry-tool';
import { PositionTool } from './tools/position-tool';
import { PropertyTool } from './tools/property-tool';
import { VicinityTool } from './tools/vicinity-tool';
import { RemarkTool } from './tools/remark-tool';
import { ShareTool } from './tools/share-tool';
import { GeotrayMenuComponent } from './geotray-menu/geotray-menu.component';
import { AnnotationToolService } from '../Services/annotation-tool.service';
import { CommonService } from '../Services/common.service';

@Injectable({
  providedIn: 'root'
})
export class GeotrayService {
  predefinedPopup: boolean;
  entity: any;
  atbWing: any;

  setPopup(data){
    this.predefinedPopup = data;
  }
  getPopup(){
    return this.predefinedPopup;
  }
  private _basemap;
  private _toolOptions;
  private _toolRef: any;
  constructor(public basemapService: BasemapService, private atService: AnnotationToolService, 
    private commonService: CommonService) {
  }

  private _getToolOptions() {
    const basemap = this.basemapService.getCurrentBasemap();
    return {
      // GTB: {
      //   instance: new GeometryTool(basemap,this.basemapService), 
      //   clickEvent: 'drawPolygonCalculatePolygon',
      //   ctrlClickEvent: 'getLayerPropertiesInfo',
      //   destroyEvent: 'destroy',
      //   clickIcon: 'url(/assets/tool-icons/map-icons/GTB2.svg) 25 25, auto',
      //   ctrlClickIcon: 'url(/assets/tool-icons/map-icons/GTB.svg) 10 10, auto'
      // },
      
      GTB: {
        instance: new GeometryTool(basemap,this.basemapService), 
        clickEvent: 'drawLineCalculateLength',
        clickEvent1: 'drawPolygonCalculatePolygon',
        ctrlClickEvent: 'getLayerPropertiesInfo',
        destroyEvent: 'destroy',
        clickIcon: 'url(/assets/tool-icons/map-icons/GTB2.svg) 25 25, auto',
        ctrlClickIcon: 'url(/assets/tool-icons/map-icons/GTB.svg) 10 10, auto'
      },
      PTB: {
        instance: new PositionTool(basemap, this.basemapService),
        clickEvent: 'getPosition',
        ctrlClickEvent: 'rotationMap',
        destroyEvent: 'destroy',
        clickIcon: 'url(/assets/tool-icons/map-icons/PTB2.svg) 25 45, auto',
        ctrlClickIcon: 'url(/assets/tool-icons/map-icons/PTB.svg) 25 25, auto'
      },
      QTB: {
        instance: new PropertyTool(basemap, this.commonService),
        /* clickEvent: 'tempClick',
        ctrlClickEvent: 'getFeatureInfoByPolygone',
        destroyEvent: 'destroy',
        clickIcon: 'auto',
        ctrlClickIcon: 'url(assets/tool-icons/map-icons/QTB.svg) 15 10, auto' */
        clickEvent: 'getFeatureInfoByPolygone',
        ctrlClickEvent: '',
        destroyEvent: 'destroy',
        clickIcon: 'url(assets/tool-icons/map-icons/QTB.svg) 15 10, auto',
        ctrlClickIcon: 'auto'
      },
      // VTB: {
      //   instance: new VicinityTool(basemap),
      //   clickEvent: 'getFeatureInfoByCircleRadius',
      //   ctrlClickEvent: 'getFeatureInfoByCircle',
      //   destroyEvent: 'destroy',
      //   clickIcon: 'url(/assets/tool-icons/map-icons/VTB.svg) 10 10, auto',
      //   ctrlClickIcon: 'auto'
      // },
      VTB: {
        instance: new VicinityTool(basemap, this.basemapService, this.commonService),
        clickEvent: 'getFeatureInfoByCircleRadius',
        ctrlClickEvent: 'getFeatureInfoByCircle',
        destroyEvent: 'destroy',
        clickIcon: 'url(/assets/tool-icons/map-icons/VTB.svg) 10 10, auto',
        ctrlClickIcon: 'auto'
      },
      RTB: {
        instance: new RemarkTool(basemap),
        clickEvent: 'getEditor',
        ctrlClickEvent: '',
        destroyEvent: 'destroy',
        clickIcon: 'auto',
        ctrlClickIcon: 'auto'
      },
      STB: {
        instance: new ShareTool(basemap),
        clickEvent: 'getShare',
        ctrlClickEvent: '',
        destroyEvent: 'destroy',
        clickIcon: 'auto',
        ctrlClickIcon: 'auto'
      }
    };
  }

  activateTool(toolInfo) {
    console.log(toolInfo,"check the passed activated tool info")
    this._toolOptions = this._getToolOptions();
    // Calling deactivate tools here to destroy tools on any other geotray tool click.
    // befor activating any tool
    this.dectivateTools();
    this._toolRef = null;
    console.log('activateTool', this._getToolOptions());
    let toolEvent;
    let toolIcon;
    const toolName = toolInfo.title;
    try{
      if (!toolInfo.isCtrlClicked) {
        console.log("i passed as true")
        console.log(this._toolOptions, "check format of tooloptions", toolInfo.clickEvent, toolInfo.clickEvent1)
        // if(toolInfo.clickEvent==true){
        // toolEvent = this._toolOptions[toolName].clickEvent;
        // }
        toolEvent = this._toolOptions[toolName].clickEvent;
        if(toolInfo.clickEvent1==true){
        toolEvent = this._toolOptions[toolName].clickEvent1;
        }
        toolIcon = this._toolOptions[toolName].clickIcon;
      } else {
        console.log("i passed as false")
        toolEvent = this._toolOptions[toolName].ctrlClickEvent;
        toolIcon = this._toolOptions[toolName].ctrlClickIcon;
      }
    } catch (e) {
      console.log(e);
    }
    this.basemapService.setMouseIcon(toolIcon);
    console.log(this._toolOptions[toolName].instance[toolEvent],"check event ..............")
    this._toolRef = this._toolOptions[toolName].instance[toolEvent](toolInfo);
    this.atService.clearAllAnnotations()

  }

  dectivateTools() {
    console.log('called deactivateTool', this._toolRef);
    this.basemapService.setMouseIcon('auto');
    console.log(this._toolRef, 'toolrefbefore')
    if (this._toolRef) {
      this._toolRef.destroy();
    }
    // this.atService.clearAllAnnotations()
    console.log(this._toolRef, 'toolrefafter')
  }
}
