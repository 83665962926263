<div style="position: relative; display: none; margin-left: -50px; margin-top: -50px"  #compassContainer>
    <i style=" position: absolute; top: -10px; left: -10px; cursor: pointer;" 
      class="material-icons" (click)="refreshData()">autorenew</i>
    <i style=" position: absolute; top: -10px; right: -10px; cursor: pointer;" 
      class="material-icons" (click)="close()">cancel</i>
    <div id="compass" matTooltip="{{locationInfo}}" matTooltipClass="mat-custom-tooltip" style="color: #000;
          width: 100px; height: 100px; border: 1px solid;
          border-radius: 67px; overflow: hidden; background: #fff;">
        <span>N</span>
        <span>E</span>
        <span>S</span>
        <span>W</span>
        <div #pointerContainer>
          <div class="cursor-animation" #comPointer id="pointer"></div>
        </div>
      
    </div>
</div>