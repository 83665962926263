import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';

export class SatelliteBingMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
      return new OlTileLayer({
        visible: false,
        preload: Infinity,
        source: new BingMaps({
          key: 'AsyUGselC-sTUDjUXG2zzNivnjN7bOBiYWL51gaoZUlyhlLUZ7tPcivlA_0lauMR',
          imagerySet: 'AerialWithLabelsOnDemand',
          crossOrigin: 'anonymous',
        }),
        name: 'bingsatellite',
      });
  }
}

