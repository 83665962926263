import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';

export class StreetBingMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
      return new OlTileLayer({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
          key: 'AsyUGselC-sTUDjUXG2zzNivnjN7bOBiYWL51gaoZUlyhlLUZ7tPcivlA_0lauMR',
          imagerySet: 'RoadOnDemand',
          crossOrigin: 'anonymous',
        }),
        name: 'bingstreet',
      });
  }
}

