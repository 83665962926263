<div #container class="popup connection-info-container" ngDraggable>
  <!-- <i #closer class="close-icon connection-info-close"></i> -->
  <button #closer class="connection-info-close">
    <img src="/assets/img/close-icon.svg">
  </button>
  <h3 class="connection-info-title">Connection</h3>
  <div class="connection-info-body-container">
    <!-- <table class="table table-striped specification-info-table"> -->
    <table class="table connection-info-table">
      <thead>
      <tr>
        <th scope="col">S.No.</th>
        <th scope="col">Property Title</th>
        <th scope="col">Property Value</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let connection of connProperties; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{connection.title}} </td>
        <td>{{connection.value}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>