<div style="position: fixed;width: 100vw;height: 100vh;top: 0;left: 0;z-index: 560;
    background-image: url('/assets/svgs/Server-Icons/bg-server-down.jpg');background-position: center;
    background-color: white;background-size: 90%;background-repeat: no-repeat;">
    <div style="text-align: center;height: 70%;font-size: 2rem;">
        <img src="assets/svgs/Server-Icons/browser-1.png" style="height: 40%;margin-top: 10%; margin-bottom: 20px;">
        <p style="margin: 0;"><b>Oops!</b> Your browser went missing from our maps</p>
        <p>We are working on it, will locate you back soon.</p>
    </div>
    <!-- <div style="text-align: center; font-size: 33px;">
        <b>Oops!</b> Your browser went missing from our maps
    </div>
    <div style="text-align: center; font-size: 28px;">
        We are working on it, will locate you back soon.
    </div> -->
    <div style="text-align: center;height: 30%;width:100%;position: absolute;bottom: 0;">
        <img src="assets/svgs/Server-Icons/fe-logo-dark.png" style="width: auto;height: 100%;">
    </div>
</div>