<div #container class="popup specification-info-container" ngDraggable>
  <!-- <i  #closer class="close-icon specification-info-close"></i> -->
  <button #closer class="specification-info-close">
    <img src="/assets/img/close-icon.svg">
  </button>
  <h3 class="specification-info-title">Specification</h3>
  <div class="specification-info-body-container">
    <!-- <table class="table table-striped specification-info-table"> -->
    <table class="table specification-info-table">
      <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Property Title</th>
        <th scope="col">Property Value</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let specification of speProperties; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{specification.title}} </td>
        <td>{{specification.value}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>