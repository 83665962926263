import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService, ElementType } from 'src/app/Services/common.service';

@Component({
  selector: 'app-landing-organization',
  templateUrl: './landing-organization.component.html',
  styleUrls: ['./landing-organization.component.scss']
})
export class LandingOrganizationComponent implements OnInit {
  showAlert = false;
  alertMessage: string = ''; 
  alertType: string = 'warning';
  currentTab: number = 0;
  orgsignupForm: FormGroup;

  constructor(private commonService: CommonService) { 
    this.orgsignupForm = new FormGroup({
      orgname: new FormControl('', [Validators.required, Validators.minLength(1)]),
      orgaddr: new FormControl('', [Validators.required, Validators.minLength(1)]),
      orgtype: new FormControl('', [Validators.required, Validators.minLength(1)]),
      orgcountry: new FormControl('', [Validators.required, Validators.minLength(1)]),
      orgcity: new FormControl('', [Validators.required, Validators.minLength(1)]),
      orgpostal: new FormControl('', [Validators.required, Validators.minLength(1)]),
      firstname: new FormControl('', [Validators.required, Validators.minLength(1)]),
      lastname: new FormControl('', [Validators.required, Validators.minLength(1)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      cardnum: new FormControl('', [Validators.required, Validators.minLength(1)]),
      expmonth: new FormControl('', [Validators.required, Validators.minLength(1)]),
      expyear: new FormControl('', [Validators.required, Validators.email]),
      cvv: new FormControl('', [Validators.required, Validators.minLength(8)]),
      cardholdername: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  ngOnInit(): void {
    this.showTab(this.currentTab);
  }

  showTab(n: number): void {
    const x = document.getElementsByClassName('step');
    (x[n] as HTMLElement).style.display = 'block';
    if (n == 0) {
      (document.getElementById('prevBtn') as HTMLElement).style.display = 'none';
    } else {
      (document.getElementById('prevBtn') as HTMLElement).style.display = 'inline';
    }
    if (n == (x.length - 1)) {
      (document.getElementById('nextBtn') as HTMLElement).innerHTML = 'Submit';
    } else {
      (document.getElementById('nextBtn') as HTMLElement).innerHTML = 'Next';
    }
    this.fixStepIndicator(n);
  }

  nextPrev(n: number): void {
    const x = document.getElementsByClassName('step');
    if (n == 1 && !this.validateForm()) return;
    (x[this.currentTab] as HTMLElement).style.display = 'none';
    this.currentTab = this.currentTab + n;
    if (this.currentTab >= x.length) {
      this.onSubmit();
      return;
    }
    this.showTab(this.currentTab);
  }

  validateForm(): boolean {
    const x = document.getElementsByClassName('step');
    const y = (x[this.currentTab] as HTMLElement).getElementsByTagName('input');
    let valid = true;
    for (let i = 0; i < y.length; i++) {
      if ((y[i] as HTMLInputElement).value == '') {
        (y[i] as HTMLInputElement).classList.add('invalid');
        valid = false;
      }
    }
    if (valid) {
      document.getElementsByClassName('stepIndicator')[this.currentTab].className += ' finish';
    }
    return valid;
  }

  fixStepIndicator(n: number): void {
    const x = document.getElementsByClassName('stepIndicator');
    for (let i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(' active', '');
    }
    x[n].className += ' active';
  }

  onSubmit(): void {
    console.log('Form submitted');
    console.log(this.orgsignupForm.value);
    this.alertMessage = 'sending form data is';
    this.alertType = 'success';  // Set the type of alert
    this.showAlert = true;
    // window.alert('sending form data is ');
    // window.alert( this.orgsignupForm.value);
    // const formData = document.getElementsByTagName('form');
    // console.log('form data is ', formData);
    // let form = document.getElementsByTagName("form")[0];
    // console.log("Calling token generation and form data is ", form);
    // console.log(formData['signUpForm'])
    // console.log(form.signUpForm['name'].value)
    // console.log(form.signUpForm.name.value, form.signUpForm.orgName.value)
    // Handle form submission logic here
    // For example, you might want to send the form data to a backend server
  }
  getErrorMsg(ctrl: FormControl, name: string, type = ElementType.INPUT): string {
    return this.commonService.getFormErrorMsg(ctrl, name, type);
  }
  closeAlert() {
    this.showAlert = false;
    setTimeout(() => {
      this.alertMessage = '';  // Clear the message after the transition ends
    }, 500); // Wait for the transition to finish before clearing the message
  }
}