import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import OlXYZ from 'ol/source/XYZ';

export class SatelliteMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
    const source = new OlXYZ({      
      url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?' +
      'access_token=pk.eyJ1IjoiZ2VvbW9jdXMiLCJhIjoiY2thbm5xaXoxMW8wcDJ4cGVseHhlaWRkbiJ9.GV_1M31DhMjYlFc93unvEg',
       maxZoom: 23,
       crossOrigin: 'anonymous',
    });
    return new OlTileLayer({
      source: source,
      name: 'satellite',
      visible: false
    });
  }
}
