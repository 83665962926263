import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BasemapService } from 'src/app/basemap/basemap.service';
import OlMap from 'ol/Map';
import { DragBox } from 'ol/interaction.js';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import { Select } from 'ol/interaction';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { unByKey } from 'ol/Observable.js';
import { CommonService } from '../../Services/common.service';
import { features } from 'process';
import Overlay from 'ol/Overlay';
import { containsExtent } from 'ol/extent';
import CircleStyle from 'ol/style/Circle';
import { MatExpansionModule } from '@angular/material/expansion';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import Text from 'ol/style/Text';
import { GeotrayMenuComponent } from '../geotray-menu/geotray-menu.component';
import {
  Output,
  EventEmitter,
  SimpleChange,
  AfterViewInit,
} from '@angular/core';
import * as $ from 'jquery';
import { AuthObservableService } from 'src/app/Services/authObservableService';
import { GeotrayService } from '../geotray.service';
import { MiniTowerItemComponent } from 'src/app/geotower/mini-tower-item/mini-tower-item.component';
import { MyService } from 'src/app/my-service.service';
import { Circle } from 'ol/style.js';
import Draw from 'ol/interaction/Draw.js';
import {Modify, Snap} from 'ol/interaction.js';
import Point from 'ol/geom/Point.js';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Tile as TileLayer } from 'ol/layer';
import { emit } from 'process';
import { LineString } from 'ol/geom';
import Pointer from 'ol/interaction/Pointer';
import { SlopeFinderComponent } from '../slope-finder/slope-finder.component';
// import * as turf from 'turf';
import { Geometry } from 'ol/geom';
import * as turf from '@turf/turf';
import { get as getProjection } from 'ol/proj.js';
import { transform } from 'ol/proj';
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import {
  BufferOp
} from 'jsts/org/locationtech/jts/operation/buffer';
import {
  BufferParameters
} from 'jsts/org/locationtech/jts/operation/buffer';
import {
  MultiPoint,
  MultiPolygon,
  MultiLineString
} from 'ol/geom';
import LinearRing from 'ol/geom/LinearRing';



@Component({
  selector: 'app-buffer-tool',
  templateUrl: './buffer-tool.component.html',
  styleUrls: ['./buffer-tool.component.scss'],
})
export class BufferToolComponent implements OnChanges {
  @Input() onbufferClicked;
  private basemap: OlMap;
  private renderer: Renderer2;
  private drawInteraction: Draw | null = null;
  raster: TileLayer;
  vector: VectorLayer;
  source: VectorSource;
  draw: any;
  snap: any;
  receivedslope: any;
  bufferValue: any;
  selectedUnits: turf.Units = 'miles';
  selectedDirection = 'both';
  coordinates: any;
  drawnBuffers = 0;
  bufferLineId: string;
  topRightPosition: any;
  bufferCloseOptionOverlay: Overlay;
  vectorBuffer;
  vectorLayer;
  sourceBuffer;
  listener;

  constructor(
    private commonService: CommonService,
    private basemapService: BasemapService,
    private renderer2: RendererFactory2
  ) {
    this.renderer = this.renderer2.createRenderer(null, null);
    this.basemap = this.basemapService.getCurrentBasemap();
    // const parser = new OL3Parser();
    // parser.inject(
    //     Point,
    //     LineString,
    //     LinearRing,
    //     Polygon,
    //     MultiPoint,
    //     MultiLineString,
    //     MultiPolygon
    // )
    this.sourceBuffer = new VectorSource()
    this.vectorBuffer = new VectorLayer({
      source: this.sourceBuffer,
      style: new Style({
        stroke: new Stroke({
          color: 'green',
          width: 5,
        }),
        image: new Circle({
          radius: 4,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'green',
            width: 2,
          }),
        }),
      }),
      name: 'vectorBuffer'
      // style: {
      //   'fill-color': 'rgba(255, 255, 255, 0.5)',
      //   'stroke-color': 'red',
      //   'stroke-width': 2,
      //   'circle-radius': 7,
      //   'circle-fill-color': '#ffcc33',
      //   'z-index':2,
      // },
    });
    this.basemap.addLayer(this.vectorBuffer)
    this.vectorLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 5,
        }),
        image: new Circle({
          radius: 4,
          fill: new Fill({
            color: 'red',
          }),
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
        }),
      }),
      name: 'bufferLayer'
    });    
    // this.basemap.addLayer(this.vectorLayer);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.onbufferClicked) {
      console.log('MSG: onslope and onbufferClicked ', this.onbufferClicked);
      this.showbuffer(); // Remove the draw interaction
    }
    if (!this.onbufferClicked) {
      console.log('MSG: onslope and onbufferClickedd ', !this.onbufferClicked);
      this.removeDrawInteraction(); // Remove the draw interaction
    }
  }
  showbuffer() {
    
    
    if (!this.commonService.isValid(this.basemap)) {
      this.basemap = this.basemapService.getCurrentBasemap();
    }
    this.basemap.addLayer(this.vectorLayer);
    console.log(this.drawnBuffers++, 'addingtodrawn')
    this.drawInteraction = new Draw({
      source: this.vectorLayer.getSource(),
      type: 'LineString',
    });
    this.basemap.addInteraction(this.drawInteraction);
    const modify = new Modify({source: this.vectorLayer.getSource()});
    this.basemap.addInteraction(modify);
    this.drawInteraction.on('drawend', async (event) => {
      // const temp = event.feature.getGeometry().transform('EPSG:3857','EPSG:4326');
      // event.feature.getGeometry().transform('EPSG:3857','EPSG:4326');
      // this.coordinates = transform(event.feature.getGeometry().getCoordinates(),'EPSG:3857','EPSG:4326');
      this.coordinates = event.feature.getGeometry().getCoordinates();
      const coords_4326 = [];
      this.coordinates.forEach(coords => {
        coords_4326.push(transform(coords,'EPSG:3857','EPSG:4326'))
      })
      // console.log('-- buffer-- ', this.coordinates, coords_4326);
      // var transformedCordArray = [];
      // var transformedCordArray1 = [];
      // const bufferDistance = this.bufferValue;
      // const bufferedLine = turf.buffer(
      //   turf.lineString(coords_4326),
      //   bufferDistance,
      //   { units: this.selectedUnits }
      // );
      // // console.log('buffer-- ', this.coordinates, coords_4326, bufferedLine.geometry.coordinates);
      // // this.coordinates = transform(this.coordinates,'EPSG:4326','EPSG:3857')
      // const corrdsList:any = bufferedLine.geometry.coordinates;
      // corrdsList[0].forEach(coods => {
      //   // console.log(coods)
      //   // console.log(transform([coods[1],coods[0]],'EPSG:4326','EPSG:3857'))
      //   transformedCordArray.push(transform(coods,'EPSG:4326','EPSG:3857'))
      // })
      // transformedCordArray1.push(transformedCordArray);
      // const feature = new Feature({
      //   geometry: new Polygon(transformedCordArray1),
      //   style: new Style({
      //     fill: new Fill({
      //       color: 'rgba(255, 0, 0, 0.5)',
      //     }),
      //     stroke: new Stroke({
      //       color: 'red',
      //       width: 5,
      //     }),
      //   }),
      // });
      // const circleSource = new VectorSource({
      //   features: [feature],
      // });
      // const circleLayer = new VectorLayer({
      //   source: circleSource,
      //   name: 'bufferLayer',
      // });
      // this.drawnBuffers++;
      // console.log(this.drawnBuffers, 'drawnbuffers', this.coordinates, feature, )
      // this.bufferLineId = `${this.drawnBuffers}_buffer`;
      // circleLayer.id = this.bufferLineId;
      // this.basemap.addLayer(circleLayer);
      this.topRightPosition = this.coordinates[0];
      this.bufferCloseOptionOverlay = new Overlay({
        id: `${this.bufferLineId}_delete`,
      });
      console.log(this.bufferCloseOptionOverlay, 'overlayelement');
      this.bufferCloseOptionOverlay.setPosition(this.topRightPosition);

      const cancelButton = document.createElement('div');
      cancelButton.setAttribute('title', 'Cancel');
      cancelButton.style.padding = '0 5px';
      cancelButton.style.margin = '2px';
      cancelButton.style.cursor = 'pointer';

      const cancelImg = document.createElement('img');
      cancelImg.setAttribute('id', `${this.bufferLineId}_delete` )
      cancelImg.style.width = '12px';
      cancelImg.src = 'assets/svgs/geopad/close-icon2.svg';

      cancelButton.appendChild(cancelImg);
      this.bufferCloseOptionOverlay.setElement(cancelButton);
      this.bufferCloseOptionOverlay.getElement().style.display = 'none';
      this.basemap.addOverlay(this.bufferCloseOptionOverlay);
      this.removeBuffer(cancelButton);
      let geomF = this.vectorLayer.getSource().getFeatures()[this.vectorLayer.getSource().getFeatures().length - 1]
      let geom = event.feature.getGeometry(); // geomF.getGeometry();
      if(this.selectedDirection && this.bufferValue && geom){
        console.log(this.calculateSelectedBufferValue())
        let buffer = this.createBuffer(geom,this.calculateSelectedBufferValue(), this.selectedDirection)
        // vector.getSource().removeFeature(geom);
        this.vectorBuffer.getSource().addFeature(buffer);
      }
    });
  }

  removeBuffer(cancelButton) {
    this.listener = this.basemap.on('click', (event) => {
      const isCtrl = platformModifierKeyOnly(event);
      if (isCtrl) {
        const allLayers = this.basemap.getLayers();
        console.log(allLayers, 'vectorlayers');
        this.bufferCloseOptionOverlay.getElement().style.display = 'block';
        cancelButton.addEventListener('click', (e) => {
          
          allLayers.forEach((bufferLayer) => {
            if (bufferLayer.values_.name == 'bufferLayer') {
              this.basemap.removeLayer(bufferLayer);
              // console.log(bufferLayer, e.target['id'],`${bufferLayer.id}_delete`,  this.bufferCloseOptionOverlay, 'allelements' )
              // if (`${bufferLayer.id}_delete` == e.target['id']) {
              //   // this.basemap.removeLayer(bufferLayer);
              //   cancelButton.style.display = 'none'
              //   this.basemap.removeLayer(bufferLayer);
              // }
              // this.basemap.removeLayer(bufferLayer);
            }
            if (bufferLayer.values_.name == 'vectorBuffer') {              
              this.basemap.removeLayer(bufferLayer);
            }
          });
          cancelButton.style.display = 'none';
          this.removeDrawInteraction();
          unByKey(this.listener);
        });
      }
    });
  }
  removeDrawInteraction() {
    if (this.drawInteraction && this.basemap) {
      this.basemap.removeInteraction(this.drawInteraction);
      this.drawInteraction = null;
      console.log('MSG: onslope and onBUFFER REMOVEINT HIT');
    }
  } 

  createBuffer(geom, bufferDistance, side){
    const parser = new OL3Parser();
    parser.inject(
          Point,
          LineString,
          LinearRing,
          Polygon,
          MultiPoint,
          MultiLineString,
          MultiPolygon
      )
    let geomP = parser.read(geom);
    let buffParams = new BufferParameters()
    buffParams.setSingleSided(true);
    buffParams.setQuadrantSegments(18);
    buffParams.setEndCapStyle(3);
    let bufferGeom 
    if(side == 'left'){
        bufferDistance = bufferDistance
        bufferGeom = BufferOp.bufferOp(geomP, bufferDistance, buffParams);
    }
    else if(side== 'right'){
        bufferDistance = -1*bufferDistance
        bufferGeom = BufferOp.bufferOp(geomP, bufferDistance, buffParams);
    }
    else if(side == "both"){
        let buffParams = new BufferParameters()
        
        buffParams.setQuadrantSegments(5);
        buffParams.setEndCapStyle(3);
        bufferGeom = BufferOp.bufferOp(geomP, bufferDistance, buffParams);
    }

    let bufferFeat = new Feature(parser.write(bufferGeom));

    return bufferFeat
  }
  calculateSelectedBufferValue(): any {
    if(this.selectedUnits === 'miles') {
      return Number((Number(this.bufferValue) * 1609).toFixed(3));
    } else if(this.selectedUnits === 'inches') {
      return Number((Number(this.bufferValue) * 0.0254).toFixed(3));
    } else if(this.selectedUnits === 'feet') {
      return Number((Number(this.bufferValue) * 0.3048).toFixed(3));
    } else if(this.selectedUnits === 'yards') {
      return Number(Number(this.bufferValue * 0.9144).toFixed(3));
    } else if(this.selectedUnits === 'centimeters') {
      return Number(Number(this.bufferValue * 0.01).toFixed(3));
    } else if(this.selectedUnits === 'meters') {
      return Number(Number(this.bufferValue * 1).toFixed(3));
    } else if(this.selectedUnits === 'kilometers') {
      return Number((Number(this.bufferValue) * 1000).toFixed(3));
    }
  }
}
