import OlMap from 'ol/Map';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {Draw, Snap, Modify} from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import { Select } from 'ol/interaction.js';
import { Circle } from 'ol/geom';
import Feature from 'ol/Feature.js';
import { unByKey } from 'ol/Observable.js';

import Overlay from 'ol/Overlay.js';
import { LineString, Polygon } from 'ol/geom.js';
import { getArea, getLength,  getDistance } from 'ol/sphere';
import { Fill, Stroke, Style } from 'ol/style.js';
import {transform} from 'ol/proj';
import {getPointResolution} from 'ol/proj';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { CommonService } from 'src/app/Services/common.service';

export class VicinityTool {
  private vectorLayer: any;
  private vectorSource: VectorSource = new VectorSource();
  private dragCircle: any;
  private select: any;
  private overlay: any;
  private listener: any;
  checkCircle : any;
  checkCoordinates : any;
  checkCircleRadius : any;
  p_radius_units : any;
  p_area_units : any;
  area_circle : any;
  perimeter_circle : any;
  p_perimeter_units : any
  modify : any
  snap: any
  // this hilightFeature for removing hilights and showing shilights of features..
  private hilightFeatures: any;
  private _measureTooltipOverlayList = [];

  // ---------------- new code for distance ------------
  private vectorLayerForDistance: any;
  private vectorSourceForDistance: VectorSource = new VectorSource();
  private draw: any;
  private measureTooltip: any;
  private tooltipEl;
  private value_div;
  private geometryChangeListener;
  private selectClick: any;
  private measureTooltipOverlayList = [];
  private basemapProjection;
  dropImage = 'assets/svgs/geo-tray/caret-down.svg'
  circleObj: any;
  dropdown1: HTMLDivElement;
  circularevent: any;
  countradiusdropdown=0;
  countareadropdown=0;
  dropdown2: any;
  countperimeterdropdown=0;
  dropdown3: any;
  // ---------------- end ------------------------------

  constructor(private basemap: OlMap, private basemapService: BasemapService, private commonService: CommonService) { }

  private getVectorLayer(): any {
    if (!this.vectorLayer) {
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource
      });
    }
    this.vectorLayer.set('name', 'vicinity');
    console.log(this.vectorLayer, 'vectorlayerinclick')
    return this.vectorLayer;
  }

  private getDrawCircle(): Draw {
    const drawing = new Draw({
      condition: platformModifierKeyOnly,
      source: this.vectorSource,
      type: 'Circle'
    });
    return drawing;
  }


  private onDrawCircleStart(): any {
    this.dragCircle.on('drawstart', (evt) => {
      this.hilightFeatures.clear();
      this.vectorSource.clear();
      const circle = evt.feature.getGeometry();
      let tooltipCoord;
      var area;
      var perimeter;
      this.geometryChangeListener = circle.on('change', (evts) => {
        console.log(evts,"check in the function")
        console.log(evts.feature,"check feature")
        console.log(this.basemap.getView().getProjection(), "check projections")
        console.log(evt.feature.getGeometry().getRadius(), "check the radius")
        console.log(evt.feature.getGeometry().getCenter(),"check the center ")
         //here circleDistance units are in meters. there after we are converting into respective units
        let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
            evt.feature.getGeometry().getRadius(), evt.feature.getGeometry().getCenter(), 'm');
        console.log(circleDistance,"check the circle distance")
        const lengthUnit = localStorage.getItem('unit');
        if (lengthUnit === 'us') {
          area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) 
          perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 )
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.miles'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'miles'
          circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
        } else if (lengthUnit === 'metric') {
          area = 3.14 * (circleDistance *  0.001 ) * (circleDistance *  0.001 )
          perimeter = 2 * 3.14 * circleDistance *  0.001 
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.kms'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'kms'
          circleDistance = Number(circleDistance *  0.001 ).toFixed(4) + ' ' + 'kms';
        }// else if (lengthUnit === 'METRE') {
        //   area = 3.14 * (((circleDistance / 1000 * 100) / 100) * 1000) * (((circleDistance / 1000 * 100) / 100) * 1000)
        //   perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100) * 1000)
        //   this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.metre'
        //   this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'metre'
        //   circleDistance = Number(((circleDistance / 1000 * 100) / 100) * 1000).toFixed(4) + ' ' + 'metre';
        // } 
        else if(lengthUnit === 'inches'){
          area = 3.14 * circleDistance / 0.0254 * circleDistance / 0.0254
          perimeter = 2 * 3.14 * circleDistance / 0.0254
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.inches'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'inches'
          circleDistance = Number(circleDistance / 0.0254).toFixed(4) + ' ' + 'inches';
        }else if(lengthUnit === 'ft'){
          area = 3.14 * (circleDistance * 3.280839895) * (circleDistance * 3.280839895)
          perimeter = 2 * 3.14 * (circleDistance * 3.280839895)
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.ft'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'ft'
          circleDistance = Number(circleDistance * 3.280839895).toFixed(4) + ' ' + 'ft';
        }else if(lengthUnit === 'yard'){
          area = 3.14 * (circleDistance * 1.0936132983) * (circleDistance * 1.0936132983)
          perimeter = 2 * 3.14 * (circleDistance * 1.0936132983)
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.yard'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'yard'
          circleDistance = Number(circleDistance * 1.0936132983 ).toFixed(4) + ' ' + 'yard';
        }else if(lengthUnit === 'cm'){
          area = 3.14 * (circleDistance * 100) * (circleDistance * 100)
          perimeter = 2 * 3.14 * (circleDistance * 100)
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.cm'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'cm'
          circleDistance = Number(circleDistance * 100).toFixed(4) + ' ' + 'cm';
        }else if(lengthUnit === 'm'){
          area = 3.14 * circleDistance * circleDistance
          perimeter = 2 * 3.14 * circleDistance
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.m'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'm'
          circleDistance = Number(circleDistance).toFixed(4) + ' ' + 'm';
        }else {
          area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609) * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
          perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
          this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.miles'
          this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'miles'
          circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
        }
        this.checkCircle = getPointResolution(this.basemap.getView().getProjection(),
        evt.feature.getGeometry().getRadius(), evt.feature.getGeometry().getCenter(), 'm');
        tooltipCoord = evts.target.getLastCoordinate();
        this.circularevent=evt.feature
        console.log(tooltipCoord,"check coordinates")
        // this.tooltipEl.style.position = 'relative';
        this.p_radius_units.innerHTML = circleDistance;
        this.p_area_units.innerHTML = this.area_circle
        this.p_perimeter_units.innerHTML = this.perimeter_circle
        console.log(this.measureTooltip,"measuretool")
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });
  }

  private getSelectedFeatures(circle): any {
    const selectedFeatures = new Map();
    const basemapLayers = this.basemap.getLayers();
    console.log('after draw list of Layers: ', basemapLayers);
    // As already we have basemap as layer we should get additional layers hence using length > 1.
    basemapLayers.forEach((layer1, index) => {
      const layer:any = layer1;
      console.log('layer.name: ', layer.values_.name, layer.ol_uid);
      /* layer.name working - here avoiding basemap & current vector vicinitymap
       * cause this vicinity have circle feature */
      // if (index !== 0 && layer.values_.name !== 'vicinity') {
      if (index !== 0 && layer.values_.name !== 'vicinity' && layer.type !== 'TILE'
            && layer.type !== 'IMAGE' && layer.values_.type !== 'IMAGE'  
            && layer.values_.type !== 'URL' && layer.values_.visible
            && layer.values_.name !== 'openstreet' && layer.values_.name !== 'toner'
            && layer.values_.name !== 'satellite' && layer.values_.name !== 'terrain'
            && layer.values_.name !== 'bingsatellite' && layer.values_.name !== 'bingstreet'
            && layer.values_.name !== 'googlestreet' && layer.values_.name !== 'googlesatellite') {
        console.log('inside ', layer.getSource(), layer.values_.name, circle.getRadius(),
        circle.getExtent(), circle.getFirstCoordinate(), circle.getLastCoordinate());
        layer.getSource().forEachFeatureIntersectingExtent(circle.getExtent(), (feature) => {
          // if layer.name doesn't exist you should use layer.id
          if (circle.intersectsExtent(feature.getGeometry().getExtent())) {
            const existingFeaturesByLayer = selectedFeatures.get(layer.values_.name)
              ? selectedFeatures.get(layer.values_.name) : [];
            existingFeaturesByLayer.push(feature);
            this.hilightFeatures.push(feature);
            selectedFeatures.set(layer.values_.name, existingFeaturesByLayer);
          }
        });
      }
    });
    return selectedFeatures;
  }

  private onDrawCircleEnd(options): any {
    this.dragCircle.on('drawend', (evt) => {
      const circle = evt.feature.getGeometry();
      // this._popupOperations(options, 'multi-layer-info', selectedFeatures, _circle.getCenter());
      this.tooltipEl.style.position = 'relative';
      this.tooltipEl.className = 'tooltipGTB tooltip-staticGTB';
      console.log(this.checkCircle,"check value")
      // if(this.checkCircle > 823226){
      //   console.log("i am here")
      //   this.measureTooltip.setOffset([-250, 255]);
      // }
      // this.measureTooltip.setOffset([-150,180]);
      evt.sketch = null;

      // setTimeout(() => {
      //   if (this.tooltipEl) {
      //     this.tooltipEl.parentNode.removeChild(this.tooltipEl);
      //   }
      // }, 3000);


      // unset tooltip so that a new one can be created
      // this.tooltipEl = null;
      // this.createMeasureTooltip(false);
      // unByKey(this.geometryChangeListener);
      console.log('selection Toogle in property ', this.select, this.commonService.isSelectionActive, this.select.getFeatures())
      if(!this.commonService.isSelectionActive) {      
        this.basemap.removeInteraction(this.select);      
        console.log('after clear ', this.select.getFeatures(), this.hilightFeatures, this.basemap)
        return;
      } else {
        let isSelectadd = true;
        this.basemap.getInteractions().forEach((interaction) => {
          console.log('interaction are ', interaction)
          if (interaction instanceof Select) {
            isSelectadd = false;
          }
        });
        if(isSelectadd) this.basemap.addInteraction(this.select);
      }     
      const selectedFeatures = this.getSelectedFeatures(circle);
      console.log('multilayer data ', selectedFeatures);
    });
  }
  /** this method used for open popup and add to map */
  private popupOperations(options, contentType, content, coordinates): any {
    const popupComp = options.popupComponent;
    this.overlay = popupComp.getGeoPopup();
    popupComp.setContent(contentType, content);
    this.overlay.setPosition(coordinates);
    this.basemap.addOverlay(this.overlay);
    this.basemap.getOverlays().forEach((overLay, index) => {
      console.log(overLay);
      if ((this.basemap.getOverlays().getLength() - 1) === index) {
        console.log('what is overlay rendered top ', parseInt(overLay.rendered.top_, 1), overLay.getMap());
        if (parseInt(overLay.rendered.top_, 10) < 227) {
          overLay.element.style.zIndex = '1';
          overLay.element.style.transform = 'rotate(180deg)';
          overLay.element.firstChild.firstChild.style.transform = 'rotate(180deg)';
        } else {
          overLay.element.style.zIndex = '1';
          overLay.element.style.transform = 'rotate(0deg)';
          overLay.element.firstChild.firstChild.style.transform = 'rotate(0deg)';
        }
        overLay.element.style.transform = overLay.rendered.transform_;
        /* console.log('View ', overLay, overLay.element.firstChild, overLay.getMap(),
          overLay.getPositioning(), overLay.getOptions().element.clientHeight, window.screen.height); */
      }
    });
    return popupComp;
  }
  // Ctrl + Click on Vicinity tool
  public getFeatureInfoByCircle(options): any {
    console.log('Get Ctrl Vicinity tool activated!');
    this.basemap.addLayer(this.getVectorLayer());
    this.select = new Select();
    this.basemap.addInteraction(this.select);
    this.dragCircle = this.getDrawCircle();
    this.basemap.addInteraction(this.dragCircle);
    this.hilightFeatures = this.select.getFeatures();
    this.createMeasureTooltip();
    // this.temp_selectClick(options);
    this.onDrawCircleStart();
    this.onDrawCircleEnd(options);
    return this;
  }
  private temp_selectClick(options): any {
    const globthis = this;
    this.select.on('select', (e) => {
      console.log(e.target);
      const selectedFeatures = new Map();
      const me = this;
      const features = [];
      const basemapLayers = this.basemap.getLayers();
      // As already we have basemap as layer we should get additional layers hence using length > 1.
      basemapLayers.forEach((layer1, index) => {
        const layer:any = layer1;
        console.log('layer.name: ', layer.values_.name, layer.ol_uid);
        /** layer.name working - here avoiding basemap  */
        if (index !== 0) {
          e.target.getFeatures().forEach((feature) => {
            const existingFeaturesByLayer = selectedFeatures.get(layer.values_.name)
              ? selectedFeatures.get(layer.values_.name) : [];
            existingFeaturesByLayer.push(feature);
            // globthis._hilightFeatures.push(feature);
            selectedFeatures.set(layer.values_.name, existingFeaturesByLayer);
          });
        }
      });
      console.log('what are selected features? ', selectedFeatures);
      if (selectedFeatures.size > 0) {
        const popupComp = options.popupComponent;
        this.overlay = popupComp.getGeoPopup();
        popupComp.setContent('multi-layer-info', selectedFeatures, false);
        this.overlay.setPosition(e.mapBrowserEvent.coordinate);
        this.basemap.addOverlay(this.overlay);
        this.basemap.getOverlays().forEach((overLay, index) => {
          if ((this.basemap.getOverlays().getLength() - 1) === index) {
            console.log('what is overlay rendered top ', parseInt(overLay.rendered.top_, 1), overLay.getMap());
            if (parseInt(overLay.rendered.top_, 10) < 227) {
              overLay.element.style.zIndex = '1';
              overLay.element.style.transform = 'rotate(180deg)';
              overLay.element.firstChild.firstChild.style.transform = 'rotate(180deg)';
            } else {
              overLay.element.style.zIndex = '1';
              overLay.element.style.transform = 'rotate(0deg)';
              overLay.element.firstChild.firstChild.style.transform = 'rotate(0deg)';
            }
            overLay.element.style.transform = overLay.rendered.transform_;
            /* console.log('View ', overLay, overLay.element.firstChild, overLay.getMap(),
              overLay.getPositioning(), overLay.getOptions().element.clientHeight, window.screen.height); */
          }
        });
        return popupComp;
      }
    });
  }
  // Click Event on Vicinity tool
  public getFeatureInfoByCircleRadius(options): any {
    console.log('Get Vicinity tool activated!');
    this.basemap.addLayer(this.getVectorLayer());
    /* this._select = new Select();
    this.basemap.addInteraction(this._select);
    this._hilightFeatures = this._select.getFeatures(); */
    this.onMapClick(options);
    console.log(options, 'options in vicinity')
    return this;
  }
  private onMapClick(options): any {
    this.listener = this.basemap.on('click', (evt) => {
      this.basemap.removeInteraction(this.select);
      const interactionArray = [];
      this.basemap.getInteractions().forEach((interaction) => {
        if (interaction instanceof Select) {
          interactionArray.push(interaction);
        }
      });
      interactionArray.forEach((interation) => {
        console.log('array inside ', interation);
        this.basemap.removeInteraction(interation);
      });
      let returnPopupComp = this.popupOperations(options, 'radius-content-info', '', evt.coordinate);
      console.log(evt.coordinate, returnPopupComp.onRadiusChange,'coordinatesinvicnity')
      returnPopupComp.onRadiusChange.subscribe(readiusValue => {
        
        returnPopupComp.close();
        // after getting value draw a circle on map'
        console.log(readiusValue, 'radiusvalueofvicinity')
        this.createMeasureTooltip2();
        console.log(this.measureTooltip,"measuretool")
        const selectedFeatures = this.drawCircleByRadiusInput(readiusValue, evt);
        
        console.log(selectedFeatures, 'selectedinvicinity')
        console.log('selection Toogle in property ', this.select, this.commonService.isSelectionActive, this.select.getFeatures())
        if(!this.commonService.isSelectionActive) {      
          this.basemap.removeInteraction(this.select);      
          console.log('after clear ', this.select.getFeatures(), this.hilightFeatures, this.basemap)
          return;
        } else {
          let isSelectadd = true;
          this.basemap.getInteractions().forEach((interaction) => {
            console.log('interaction are ', interaction)
            if (interaction instanceof Select) {
              isSelectadd = false;
            }
          });
          if(isSelectadd) this.basemap.addInteraction(this.select);
        }   

        returnPopupComp=null
        return this;
        // this._popupOperations(options, 'multi-layer-info', selectedFeatures, evt.coordinate);
      },);
    });
  }

  // if (lengthUnit === 'us') {
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
  // } else if (lengthUnit === 'metric') {
  //   circleDistance = Number((circleDistance / 1000 * 100) / 100).toFixed(4) + ' ' + 'kms';
  // } else if (lengthUnit === 'METRE') {
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100) * 1000).toFixed(4) + ' ' + 'metre';
  // } else if(lengthUnit === 'inches'){
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 39370.1).toFixed(4) + ' ' + 'inches';
  // }else if(lengthUnit === 'ft'){
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 3280.84).toFixed(4) + ' ' + 'ft';
  // }else if(lengthUnit === 'yard'){
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 0.0009144).toFixed(4) + ' ' + 'yard';
  // }else if(lengthUnit === 'cm'){
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 100000).toFixed(4) + ' ' + 'cm';
  // }else if(lengthUnit === 'm'){
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1000 ).toFixed(4) + ' ' + 'm';
  // }else {
  //   circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
  // }
  private drawCircleByRadiusInput(radiusValue, evt): any {
    console.log(radiusValue);
    console.log(this.vectorSource.getFeatures(),"check layer length")
    if (this.hilightFeatures) {
      this.hilightFeatures.clear();
    }
    if (this.vectorSource.getFeatures().length > 0) {
      this.vectorSource.clear();
    }

    let units = localStorage.getItem('unit');
    units = units === 'mile' ? 'us' : units;
    // units = 'us';
    console.log(units, " units are")
    let conversionFactor;
    if (units === 'us') {
      conversionFactor = 1609; // meters per mile
    } else if (units === 'metric') {
      conversionFactor = 1000; // meters per kilometer
    } else if (units === 'inches') {
      conversionFactor = 0.0254; // meters per inch
    } else if (units === 'ft') {
      conversionFactor = 0.3048; // meters per foot
    } else if (units === 'yard') {
      conversionFactor = 0.9144; // meters per yard
    } else if (units === 'cm') {
      conversionFactor = 0.01; // meters per centimeter
    } else if (units === 'm') {
      conversionFactor = 1; // meters per meter
    }
    // const newCoordinate = transform(evt.coordinate, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326');

// Convert the radius value to the target units
const radiusInMeters = radiusValue * conversionFactor;
const lonDegreeLength = 111319.9 * Math.cos(evt.coordinate[1] * Math.PI / 180);
const radiusDegrees = radiusInMeters / lonDegreeLength;

// Create the circle object using the target units
if(this.basemapService.getCurrentBasemap().getView().getProjection().getCode() == 'EPSG:4326'){
  this.circleObj = new Circle(evt.coordinate, radiusDegrees);

}
else{
  this.circleObj = new Circle(evt.coordinate, radiusInMeters);

}
let lengthUnit = localStorage.getItem('unit');
lengthUnit = lengthUnit === 'mile' ? 'us' : lengthUnit;
// lengthUnit = 'us'
console.log(lengthUnit, radiusInMeters, radiusDegrees, radiusInMeters, lonDegreeLength)
var radius=radiusInMeters
var area;
      var perimeter;
      let circleDistance
      circleDistance=Number(radius)
      console.log(radiusInMeters,"radiusInMeters")
    this.select = new Select();
    this.basemap.addInteraction(this.select);
    this.hilightFeatures = this.select.getFeatures();
    const circleFeature = new Feature(this.circleObj);
    this.vectorSource.addFeature(circleFeature);
    const circle = circleFeature.getGeometry();
    console.log('  ', circle, circleFeature);
    const selectedFeatures = this.getSelectedFeatures(circle);
    console.log('multilayer data radius Vicinity ', selectedFeatures, circleDistance);
    console.log(this.measureTooltip,selectedFeatures,evt.coordinate,"measuretool")
    if (lengthUnit === 'us') {
      area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) 
      perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 )
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.miles'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'miles'
      circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
    } else if (lengthUnit === 'metric') {
      area = 3.14 * (circleDistance *  0.001 ) * (circleDistance *  0.001 )
      perimeter = 2 * 3.14 * circleDistance *  0.001 
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.kms'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'kms'
      circleDistance = Number(circleDistance *  0.001 ).toFixed(4) + ' ' + 'kms';
    }// else if (lengthUnit === 'METRE') {
    //   area = 3.14 * (((circleDistance / 1000 * 100) / 100) * 1000) * (((circleDistance / 1000 * 100) / 100) * 1000)
    //   perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100) * 1000)
    //   this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.metre'
    //   this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'metre'
    //   circleDistance = Number(((circleDistance / 1000 * 100) / 100) * 1000).toFixed(4) + ' ' + 'metre';
    // } 
    else if(lengthUnit === 'inches'){
      area = 3.14 * circleDistance / 0.0254 * circleDistance / 0.0254
      perimeter = 2 * 3.14 * circleDistance / 0.0254
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.inches'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'inches'
      circleDistance = Number(circleDistance / 0.0254).toFixed(4) + ' ' + 'inches';
    }else if(lengthUnit === 'ft'){
      area = 3.14 * (circleDistance * 3.280839895) * (circleDistance * 3.280839895)
      perimeter = 2 * 3.14 * (circleDistance * 3.280839895)
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.ft'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'ft'
      circleDistance = Number(circleDistance * 3.280839895).toFixed(4) + ' ' + 'ft';
    }else if(lengthUnit === 'yard'){
      area = 3.14 * (circleDistance * 1.0936132983) * (circleDistance * 1.0936132983)
      perimeter = 2 * 3.14 * (circleDistance * 1.0936132983)
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.yard'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'yard'
      circleDistance = Number(circleDistance * 1.0936132983 ).toFixed(4) + ' ' + 'yard';
    }else if(lengthUnit === 'cm'){
      area = 3.14 * (circleDistance * 100) * (circleDistance * 100)
      perimeter = 2 * 3.14 * (circleDistance * 100)
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.cm'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'cm'
      circleDistance = Number(circleDistance * 100).toFixed(4) + ' ' + 'cm';
    }else if(lengthUnit === 'm'){
      area = 3.14 * circleDistance * circleDistance
      perimeter = 2 * 3.14 * circleDistance
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.m'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'm'
      circleDistance = Number(circleDistance).toFixed(4) + ' ' + 'm';
    }else {
      area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609) * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
      perimeter = 2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
      this.area_circle = Number(area).toFixed(0) + ' ' + 'sq.miles'
      this.perimeter_circle = Number(perimeter).toFixed(0) + ' ' + 'miles'
      circleDistance = Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
    }
    this.p_radius_units.innerHTML = circleDistance;
        this.p_area_units.innerHTML = this.area_circle
        this.p_perimeter_units.innerHTML = this.perimeter_circle
    this.measureTooltip.setPosition([evt.coordinate[0],evt.coordinate[1]]) 
    this.tooltipEl.style.position = 'relative';
      this.tooltipEl.className = 'tooltipGTB tooltip-staticGTB';
      evt.sketch = null;

    return selectedFeatures;
  }
  // -------------------------- New code for vicinity distance shoing drwing circle ----------
  private createMeasureTooltip(): any {
    if (this.tooltipEl) {
      this.tooltipEl.parentNode.removeChild(this.tooltipEl);
    }
    // this.tooltipEl = document.createElement('div');
    // this.tooltipEl.style.position = 'relative';
    // this.tooltipEl.style.background = 'white'
    // this.tooltipEl.style.border = '1px solid black'
    // var inner_div = document.createElement('div')
    // inner_div.setAttribute('class',"row")
    // inner_div.style.margin = '0'
    // inner_div.style.padding = '0'
    // this.tooltipEl.appendChild(inner_div)
    // this.value_div = document.createElement('div')
    // inner_div.appendChild(this.value_div)
    // var img_div = document.createElement('img')
    // img_div.src = this.dropImage
    // inner_div.append(img_div)
    // img_div.onclick = function(){
    //   console.log("image clicked")
    // }
    // this.tooltipEl.className = 'tooltipGTB tooltip-measureGTB';


    this.tooltipEl = document.createElement('div')
    this.tooltipEl.style.width = '300px'
    this.tooltipEl.style.height = '95px'
    this.tooltipEl.style.background = 'black'
    this.tooltipEl.style.borderRadius = '2px'
    this.tooltipEl.style.position = 'absolute'


    //row one
    var row_one = document.createElement('div')
    row_one.setAttribute('class','row')
    row_one.style.margin = '0'
    row_one.style.padding = '0'
    row_one.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_one)

    var radius_div = document.createElement('div')
    radius_div.style.width = '37%'
    radius_div.style.height = '30px'
    radius_div.style.color = '#ffff'
    row_one.appendChild(radius_div)

    var p_radius = document.createElement('p')
    p_radius.innerHTML = 'Radius'
    radius_div.appendChild(p_radius)

    var radius_div_units = document.createElement('div')
    radius_div_units.style.width = '50%'
    radius_div_units.style.height = '30px'
    radius_div_units.style.textAlign = 'right'
    radius_div_units.style.color = '#ffff'
    row_one.appendChild(radius_div_units)

    this.p_radius_units = document.createElement('p')
    radius_div_units.appendChild(this.p_radius_units)

    var units_one = document.createElement('div')
    
    units_one.style.height = '30px'

    var img_one = document.createElement('img')
    img_one.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    img_one.style.margin = 'auto 0.5rem';
    img_one.style.width = '20px'
    img_one.addEventListener('click', (evt) => {
      if(this.dropdown3){
        this.dropdown3.style.display='none'
        this.countperimeterdropdown=0;
        }
        if(this.dropdown2){
          this.dropdown2.style.display='none'
          this.countareadropdown=0;
          }
      if(this.countradiusdropdown==1){
        this.dropdown1.style.display="none"
        this.countradiusdropdown=0

      }
   else
   {console.log("i am in radius dropdown")
      this.dropdown1 = document.createElement('div');
    
      this.dropdown1.style.position = 'absolute';
      this.dropdown1.style.background = 'black';
      this.dropdown1.style.opacity = '.95';
      this.dropdown1.style.color = 'white';
      this.dropdown1.style.borderRadius = '2px';
      this.dropdown1.style.width = '42px';
      
      this.dropdown1.style.padding = '5px';
      this.dropdown1.style.zIndex = '1';

      // Create the list of measurements
      const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the this.dropdown2
      for (let measurement of measurements) {
console.log(measurement,"measurments")
        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
        this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
        li.addEventListener('click', (evt) => {
          console.log(evt.srcElement['innerHTML'],"in radius")
          var radiusUnits=evt.srcElement['innerHTML']
          if(radiusUnits=='m'){
            
             this.p_radius_units.innerHTML =Number(circleDistance).toFixed(4) + ' ' + 'm';
          }
          else if(radiusUnits=='km'){
             this.p_radius_units.innerHTML =  Number(circleDistance *  0.001 ).toFixed(4) + ' ' + 'kms';
          }
          else if(radiusUnits=='in'){
             this.p_radius_units.innerHTML = Number(circleDistance / 0.0254).toFixed(4) + ' ' + 'in';
          }
          else if(radiusUnits=='ft'){
             this.p_radius_units.innerHTML =Number(circleDistance * 3.280839895).toFixed(4) + ' ' + 'ft';
          }
          else if(radiusUnits=='mile'){
             this.p_radius_units.innerHTML =Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
          }
          else if(radiusUnits=='yard'){
             this.p_radius_units.innerHTML =Number(circleDistance * 1.0936132983 ).toFixed(4) + ' ' + 'yard';
          }
          this.dropdown1.remove();
          this.countradiusdropdown=0
        })
        this.dropdown1.appendChild(li);
        row_one.appendChild(this.dropdown1)
      }
      this.dropdown1.style.top = `${img_one.offsetTop-6}px`;
      this.dropdown1.style.left = `${img_one.offsetLeft+39}px`;
      this.countradiusdropdown++
    }
    })
    units_one.appendChild(img_one)
    row_one.appendChild(units_one)

    //second row

    var row_two = document.createElement('div')
    row_two.setAttribute('class','row')
    row_two.style.margin = '0'
    row_two.style.padding = '0'
    row_two.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_two)

    var area_div = document.createElement('div')
    area_div.style.width = '20%'
    area_div.style.height = '30px'
    area_div.style.color = '#ffff'
    row_two.appendChild(area_div)

    var p_area = document.createElement('p')
    p_area.innerHTML = 'Area'
    area_div.appendChild(p_area)

    var area_div_units = document.createElement('div')
    area_div_units.style.width = '67%'
    area_div_units.style.height = '30px'
    area_div_units.style.textAlign = 'right'
    area_div_units.style.color = '#fff'
    row_two.appendChild(area_div_units)

    this.p_area_units = document.createElement('p')
    area_div_units.appendChild(this.p_area_units)

    var units_two = document.createElement('div')
   
    units_two.style.height = '30px'

    var img_two = document.createElement('img')
    img_two.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    img_two.style.margin = 'auto 0.5rem'
    img_two.style.width = '20px'
    img_two.addEventListener('click', (evt) => {
      if(this.dropdown1){
        this.dropdown1.style.display='none'
        this.countradiusdropdown=0;
        }
        if(this.dropdown3){
          this.dropdown3.style.display='none'
          this.countperimeterdropdown=0
          }
      if(this.countareadropdown==1){
        this.dropdown2.style.display="none"
        this.countareadropdown=0

      }
   else
   {console.log("i am in radius dropdown")
      this.dropdown2 = document.createElement('div');
      this.dropdown2.style.position = 'absolute';
      this.dropdown2.style.background = 'black';
      this.dropdown2.style.opacity = '.95';
      this.dropdown2.style.color = 'white';
      this.dropdown2.style.borderRadius = '2px';
      this.dropdown2.style.width = '54px';
      
      this.dropdown2.style.padding = '5px';
      this.dropdown2.style.zIndex = '1';

      // Create the list of measurements
      const areameasurements = ['sq.m', 'sq.km','sq.mile', 'sq.in', 'sq.ft', 'sq.yd', "acre", "are", "hectare"]
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
      let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
      this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the this.dropdown2
      for (let measurement of areameasurements) {

        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        li.addEventListener('click', (evt) => {
          console.log(evt.srcElement['innerHTML'],"in radius")
          var radiusUnits=evt.srcElement['innerHTML']
          if(radiusUnits=='sq.m'){
            var area = 3.14 * circleDistance * circleDistance
          
          
            this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.m';
        } else if(radiusUnits=='sq.km'){
          area = 3.14 * (circleDistance *  0.001 ) * (circleDistance *  0.001 )

            this.p_area_units.innerHTML = Number(area).toFixed(0) + ' ' + 'sq.km';
        } else if(radiusUnits=='sq.in'){
          area = 3.14 * circleDistance / 0.0254 * circleDistance / 0.0254

  
            this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.in';
        } else if(radiusUnits=='sq.ft'){
          area = 3.14 * (circleDistance * 3.280839895) * (circleDistance * 3.280839895)
            this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.ft';
        } else if(radiusUnits=='sq.mile'){
          area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) 
        
            this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.mi'
        } else if(radiusUnits=='sq.yd'){
          area = 3.14 * (circleDistance * 1.0936132983) * (circleDistance * 1.0936132983)
       
       
            this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.yrd';
        } else if(radiusUnits=='are'){
          area = (3.14 * (circleDistance**2)) / 100;
        
          this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'are';
        } else if(radiusUnits=='acre'){
          area = (3.14 * (circleDistance**2)) / 4046.85642;
        
          this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'acre';
        } else if(radiusUnits=='hectare'){
          area = (3.14 * (circleDistance**2)) / 10000;
        
          this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'hectare';
        }
          this.dropdown2.remove();
          this.countareadropdown=0
        })
        this.dropdown2.appendChild(li);
        row_one.appendChild(this.dropdown2)
      }
      this.dropdown2.style.top = `${img_one.offsetTop-6}px`;
      this.dropdown2.style.left = `${img_one.offsetLeft+39}px`;
      this.countareadropdown++
    }
    })
    units_two.appendChild(img_two)
    row_two.appendChild(units_two)

    //third row

    var row_three = document.createElement('div')
    row_three.setAttribute('class','row')
    row_three.style.margin = '0'
    row_three.style.padding = '0'
    row_three.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_three)

    var perimeter_div = document.createElement('div')
    perimeter_div.style.width = '37%'
    perimeter_div.style.height = '30px'
    perimeter_div.style.color = '#ffff'
    row_three.appendChild(perimeter_div)

    var p_perimeter = document.createElement('p')
    p_perimeter.innerHTML = 'Perimeter'
    perimeter_div.appendChild(p_perimeter)

    var perimeter_div_units = document.createElement('div')
    perimeter_div_units.style.width = '50%'
    perimeter_div_units.style.height = '30px'
    perimeter_div_units.style.textAlign = 'right'
    perimeter_div_units.style.color = '#fff'
    row_three.appendChild(perimeter_div_units)

    this.p_perimeter_units = document.createElement('p')
    perimeter_div_units.appendChild(this.p_perimeter_units)

    var units_three = document.createElement('div')
    
    units_three.style.height = '30px'

    var img_three = document.createElement('img')
    img_three.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    img_three.style.margin = 'auto 0.5rem'
    img_three.style.width = '20px'
    img_three.addEventListener('click', (evt) => {
      if(this.dropdown1){
        this.dropdown1.style.display='none'
        this.countradiusdropdown=0;
        }
        if(this.dropdown2){
          this.dropdown2.style.display='none';
          this.countareadropdown=0;
          }
      if(this.countperimeterdropdown==1){
        this.dropdown3.style.display="none"
        this.countperimeterdropdown=0

      }
   else
   {console.log("i am in radius dropdown")
      this.dropdown3 = document.createElement('div');
      this.dropdown3.style.position = 'absolute';
      this.dropdown3.style.background = 'black';
      this.dropdown3.style.opacity = '.95';
      this.dropdown3.style.color = 'white';
      this.dropdown3.style.borderRadius = '2px';
      this.dropdown3.style.width = '42px';
      
      this.dropdown3.style.padding = '5px';
      this.dropdown3.style.zIndex = '1';

      // Create the list of measurements
      const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the this.dropdown2
      for (let measurement of measurements) {

        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
        this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
        li.addEventListener('click', (evt) => {
          console.log(evt.srcElement['innerHTML'],"in radius")
          var radiusUnits=evt.srcElement['innerHTML']
          if(radiusUnits=='m'){
            var perimeter = 2 * 3.14 * circleDistance
             this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'm';
          }
          else if(radiusUnits=='km'){
            var perimeter = 2 * 3.14 * circleDistance *  0.001
            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'kms';
            
          }
          else if(radiusUnits=='in'){
            var perimeter =  2 * 3.14 * circleDistance / 0.0254
            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'in';
          }
          else if(radiusUnits=='ft'){
            var perimeter =  2 * 3.14 * (circleDistance * 3.280839895)
            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'ft';
          }
          else if(radiusUnits=='mile'){
            var perimeter =2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'mile';
          }
          else if(radiusUnits=='yard'){
            var perimeter = 2 * 3.14 * (circleDistance * 1.0936132983)
            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'yard';
          }
          this.dropdown3.remove();
          this.countperimeterdropdown=0
        })
        this.dropdown3.appendChild(li);
        row_one.appendChild(this.dropdown3)
      }
      this.dropdown3.style.top = `${img_one.offsetTop-6}px`;
      this.dropdown3.style.left = `${img_one.offsetLeft+39}px`;
      this.countperimeterdropdown++
    }
    })
    units_three.appendChild(img_three)
    row_three.appendChild(units_three)


    this.measureTooltip = new Overlay({
      element: this.tooltipEl,
      // offset: [-150,180],
      // positioning: 'bottom-left',
      autoPan : true
    });


    this.modify = new Modify({source : this.vectorSource});
    this.basemap.addInteraction(this.modify)

    this.basemap.addOverlay(this.measureTooltip);

    this.snap = new Snap({source : this.vectorSource})

    this.basemap.addInteraction(this.snap)

    this.measureTooltipOverlayList.push(this.measureTooltip);
    
  }
  private createMeasureTooltip2(): any {
    if (this.tooltipEl) {
      this.tooltipEl.parentNode.removeChild(this.tooltipEl);
    }
    // this.tooltipEl = document.createElement('div');
    // this.tooltipEl.style.position = 'relative';
    // this.tooltipEl.style.background = 'white'
    // this.tooltipEl.style.border = '1px solid black'
    // var inner_div = document.createElement('div')
    // inner_div.setAttribute('class',"row")
    // inner_div.style.margin = '0'
    // inner_div.style.padding = '0'
    // this.tooltipEl.appendChild(inner_div)
    // this.value_div = document.createElement('div')
    // inner_div.appendChild(this.value_div)
    // var img_div = document.createElement('img')
    // img_div.src = this.dropImage
    // inner_div.append(img_div)
    // img_div.onclick = function(){
    //   console.log("image clicked")
    // }
    // this.tooltipEl.className = 'tooltipGTB tooltip-measureGTB';


    this.tooltipEl = document.createElement('div')
    this.tooltipEl.style.width = '300px'
    this.tooltipEl.style.height = '95px'
    this.tooltipEl.style.background = 'black'
    this.tooltipEl.style.borderRadius = '2px'
    this.tooltipEl.style.position = 'absolute'


    //row one
    var row_one = document.createElement('div')
    row_one.setAttribute('class','row')
    row_one.style.margin = '0'
    row_one.style.padding = '0'
    row_one.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_one)

    var radius_div = document.createElement('div')
    radius_div.style.width = '46%'
    radius_div.style.height = '30px'
    radius_div.style.color = '#ffff'
    row_one.appendChild(radius_div)

    var p_radius = document.createElement('p')
    p_radius.innerHTML = 'Radius'
    radius_div.appendChild(p_radius)

    var radius_div_units = document.createElement('div')
    radius_div_units.style.width = '50%'
    radius_div_units.style.height = '30px'
    radius_div_units.style.textAlign = 'right'
    radius_div_units.style.color = '#ffff'
    row_one.appendChild(radius_div_units)

    this.p_radius_units = document.createElement('p')
    radius_div_units.appendChild(this.p_radius_units)

    var units_one = document.createElement('div')
    
    units_one.style.height = '30px'

//     var img_one = document.createElement('img')
//     img_one.src = 'assets/svgs/geo-tray/caret-down-white.svg'
//     img_one.style.margin = 'auto 0.5rem';
//     img_one.style.width = '20px'
//     img_one.addEventListener('click', (evt) => {
//       if(this.dropdown3){
//         this.dropdown3.style.display='none'
//         this.countperimeterdropdown=0;
//         }
//         if(this.dropdown2){
//           this.dropdown2.style.display='none'
//           this.countareadropdown=0;
//           }
//       if(this.countradiusdropdown==1){
//         this.dropdown1.style.display="none"
//         this.countradiusdropdown=0

//       }
//    else
//    {console.log("i am in radius dropdown")
//       this.dropdown1 = document.createElement('div');
    
//       this.dropdown1.style.position = 'absolute';
//       this.dropdown1.style.background = 'black';
//       this.dropdown1.style.opacity = '.95';
//       this.dropdown1.style.color = 'white';
//       this.dropdown1.style.borderRadius = '2px';
//       this.dropdown1.style.width = '42px';
      
//       this.dropdown1.style.padding = '5px';
//       this.dropdown1.style.zIndex = '1';

//       // Create the list of measurements
//       const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
//       // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
//   //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
//   //     var newarea=this.calculateAreaUnits(this.numberss)
//   //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
//   // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
//       // Create the list items and append them to the this.dropdown2
//       for (let measurement of measurements) {
// console.log(measurement,"measurments")
//         const li = document.createElement('li');
//         li.style.fontSize = '12px'
//         li.style.borderBottom = '0.5px solid white'
//         li.innerHTML = measurement;
//         li.style.listStyle = 'none';
//         li.style.padding = '5px';
//         li.style.cursor = 'pointer';
//         li.style.textAlign = 'center';
//         let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
//         this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
//         li.addEventListener('click', (evt) => {
//           console.log(evt.srcElement['innerHTML'],"in radius")
//           var radiusUnits=evt.srcElement['innerHTML']
//           if(radiusUnits=='m'){
            
//              this.p_radius_units.innerHTML =Number(circleDistance).toFixed(4) + ' ' + 'm';
//           }
//           else if(radiusUnits=='km'){
//              this.p_radius_units.innerHTML =  Number(circleDistance *  0.001 ).toFixed(4) + ' ' + 'kms';
//           }
//           else if(radiusUnits=='in'){
//              this.p_radius_units.innerHTML = Number(circleDistance / 0.0254).toFixed(4) + ' ' + 'in';
//           }
//           else if(radiusUnits=='ft'){
//              this.p_radius_units.innerHTML =Number(circleDistance * 3.280839895).toFixed(4) + ' ' + 'ft';
//           }
//           else if(radiusUnits=='mile'){
//              this.p_radius_units.innerHTML =Number(((circleDistance / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
//           }
//           else if(radiusUnits=='yard'){
//              this.p_radius_units.innerHTML =Number(circleDistance * 1.0936132983 ).toFixed(4) + ' ' + 'yard';
//           }
//           this.dropdown1.remove();
//           this.countradiusdropdown=0
//         })
//         this.dropdown1.appendChild(li);
//         row_one.appendChild(this.dropdown1)
//       }
//       this.dropdown1.style.top = `${img_one.offsetTop-6}px`;
//       this.dropdown1.style.left = `${img_one.offsetLeft+39}px`;
//       this.countradiusdropdown++
//     }
//     })
//     units_one.appendChild(img_one)
//     row_one.appendChild(units_one)

    //second row

    var row_two = document.createElement('div')
    row_two.setAttribute('class','row')
    row_two.style.margin = '0'
    row_two.style.padding = '0'
    row_two.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_two)

    var area_div = document.createElement('div')
    area_div.style.width = '29%'
    area_div.style.height = '30px'
    area_div.style.color = '#ffff'
    row_two.appendChild(area_div)

    var p_area = document.createElement('p')
    p_area.innerHTML = 'Area'
    area_div.appendChild(p_area)

    var area_div_units = document.createElement('div')
    area_div_units.style.width = '67%'
    area_div_units.style.height = '30px'
    area_div_units.style.textAlign = 'right'
    area_div_units.style.color = '#fff'
    row_two.appendChild(area_div_units)

    this.p_area_units = document.createElement('p')
    area_div_units.appendChild(this.p_area_units)

    var units_two = document.createElement('div')
   
    units_two.style.height = '30px'

  //   var img_two = document.createElement('img')
  //   img_two.src = 'assets/svgs/geo-tray/caret-down-white.svg'
  //   img_two.style.margin = 'auto 0.5rem'
  //   img_two.style.width = '20px'
  //   img_two.addEventListener('click', (evt) => {
  //     if(this.dropdown1){
  //       this.dropdown1.style.display='none'
  //       this.countradiusdropdown=0;
  //       }
  //       if(this.dropdown3){
  //         this.dropdown3.style.display='none'
  //         this.countperimeterdropdown=0
  //         }
  //     if(this.countareadropdown==1){
  //       this.dropdown2.style.display="none"
  //       this.countareadropdown=0

  //     }
  //  else
  //  {console.log("i am in radius dropdown")
  //     this.dropdown2 = document.createElement('div');
  //     this.dropdown2.style.position = 'absolute';
  //     this.dropdown2.style.background = 'black';
  //     this.dropdown2.style.opacity = '.95';
  //     this.dropdown2.style.color = 'white';
  //     this.dropdown2.style.borderRadius = '2px';
  //     this.dropdown2.style.width = '54px';
      
  //     this.dropdown2.style.padding = '5px';
  //     this.dropdown2.style.zIndex = '1';

  //     // Create the list of measurements
  //     const areameasurements = ['sq.m', 'sq.km','sq.mile', 'sq.in', 'sq.ft', 'sq.yd', "acre", "are", "hectare"]
  //     // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
  //     let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
  //     this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
  // //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  // //     var newarea=this.calculateAreaUnits(this.numberss)
  // //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
  //     // Create the list items and append them to the this.dropdown2
  //     for (let measurement of areameasurements) {

  //       const li = document.createElement('li');
  //       li.style.fontSize = '12px'
  //       li.style.borderBottom = '0.5px solid white'
  //       li.innerHTML = measurement;
  //       li.style.listStyle = 'none';
  //       li.style.padding = '5px';
  //       li.style.cursor = 'pointer';
  //       li.style.textAlign = 'center';
  //       li.addEventListener('click', (evt) => {
  //         console.log(evt.srcElement['innerHTML'],"in radius")
  //         var radiusUnits=evt.srcElement['innerHTML']
  //         if(radiusUnits=='sq.m'){
  //           var area = 3.14 * circleDistance * circleDistance
          
          
  //           this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.m';
  //       } else if(radiusUnits=='sq.km'){
  //         area = 3.14 * (circleDistance *  0.001 ) * (circleDistance *  0.001 )

  //           this.p_area_units.innerHTML = Number(area).toFixed(0) + ' ' + 'sq.km';
  //       } else if(radiusUnits=='sq.in'){
  //         area = 3.14 * circleDistance / 0.0254 * circleDistance / 0.0254

  
  //           this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.in';
  //       } else if(radiusUnits=='sq.ft'){
  //         area = 3.14 * (circleDistance * 3.280839895) * (circleDistance * 3.280839895)
  //           this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.ft';
  //       } else if(radiusUnits=='sq.mile'){
  //         area = 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) * (((circleDistance / 1000 * 100) / 100 ) / 1.609 ) 
        
  //           this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.mi'
  //       } else if(radiusUnits=='sq.yd'){
  //         area = 3.14 * (circleDistance * 1.0936132983) * (circleDistance * 1.0936132983)
       
       
  //           this.p_area_units.innerHTML =Number(area).toFixed(0) + ' ' + 'sq.yrd';
  //       } else if(radiusUnits=='are'){
  //         area = (3.14 * (circleDistance**2)) / 100;
        
  //         this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'are';
  //       } else if(radiusUnits=='acre'){
  //         area = (3.14 * (circleDistance**2)) / 4046.85642;
        
  //         this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'acre';
  //       } else if(radiusUnits=='hectare'){
  //         area = (3.14 * (circleDistance**2)) / 10000;
        
  //         this.p_area_units.innerHTML = String(Math.round(area)) + ' ' + 'hectare';
  //       }
  //         this.dropdown2.remove();
  //         this.countareadropdown=0
  //       })
  //       this.dropdown2.appendChild(li);
  //       row_one.appendChild(this.dropdown2)
  //     }
  //     this.dropdown2.style.top = `${img_one.offsetTop-6}px`;
  //     this.dropdown2.style.left = `${img_one.offsetLeft+39}px`;
  //     this.countareadropdown++
  //   }
  //   })
  //   units_two.appendChild(img_two)
  //   row_two.appendChild(units_two)

    //third row

    var row_three = document.createElement('div')
    row_three.setAttribute('class','row')
    row_three.style.margin = '0'
    row_three.style.padding = '0'
    row_three.style.borderBottom = '0.5px solid black'
    this.tooltipEl.appendChild(row_three)

    var perimeter_div = document.createElement('div')
    perimeter_div.style.width = '46%'
    perimeter_div.style.height = '30px'
    perimeter_div.style.color = '#ffff'
    row_three.appendChild(perimeter_div)

    var p_perimeter = document.createElement('p')
    p_perimeter.innerHTML = 'Perimeter'
    perimeter_div.appendChild(p_perimeter)

    var perimeter_div_units = document.createElement('div')
    perimeter_div_units.style.width = '50%'
    perimeter_div_units.style.height = '30px'
    perimeter_div_units.style.textAlign = 'right'
    perimeter_div_units.style.color = '#fff'
    row_three.appendChild(perimeter_div_units)

    this.p_perimeter_units = document.createElement('p')
    perimeter_div_units.appendChild(this.p_perimeter_units)

    var units_three = document.createElement('div')
    
    units_three.style.height = '30px'

  //   var img_three = document.createElement('img')
  //   img_three.src = 'assets/svgs/geo-tray/caret-down-white.svg'
  //   img_three.style.margin = 'auto 0.5rem'
  //   img_three.style.width = '20px'
  //   img_three.addEventListener('click', (evt) => {
  //     if(this.dropdown1){
  //       this.dropdown1.style.display='none'
  //       this.countradiusdropdown=0;
  //       }
  //       if(this.dropdown2){
  //         this.dropdown2.style.display='none';
  //         this.countareadropdown=0;
  //         }
  //     if(this.countperimeterdropdown==1){
  //       this.dropdown3.style.display="none"
  //       this.countperimeterdropdown=0

  //     }
  //  else
  //  {console.log("i am in radius dropdown")
  //     this.dropdown3 = document.createElement('div');
  //     this.dropdown3.style.position = 'absolute';
  //     this.dropdown3.style.background = 'black';
  //     this.dropdown3.style.opacity = '.95';
  //     this.dropdown3.style.color = 'white';
  //     this.dropdown3.style.borderRadius = '2px';
  //     this.dropdown3.style.width = '42px';
      
  //     this.dropdown3.style.padding = '5px';
  //     this.dropdown3.style.zIndex = '1';

  //     // Create the list of measurements
  //     const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
  //     // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  // //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  // //     var newarea=this.calculateAreaUnits(this.numberss)
  // //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
  //     // Create the list items and append them to the this.dropdown2
  //     for (let measurement of measurements) {

  //       const li = document.createElement('li');
  //       li.style.fontSize = '12px'
  //       li.style.borderBottom = '0.5px solid white'
  //       li.innerHTML = measurement;
  //       li.style.listStyle = 'none';
  //       li.style.padding = '5px';
  //       li.style.cursor = 'pointer';
  //       li.style.textAlign = 'center';
  //       let circleDistance = getPointResolution(this.basemap.getView().getProjection(),
  //       this.circularevent.getGeometry().getRadius(),this.circularevent.getGeometry().getCenter(), 'm');
  //       li.addEventListener('click', (evt) => {
  //         console.log(evt.srcElement['innerHTML'],"in radius")
  //         var radiusUnits=evt.srcElement['innerHTML']
  //         if(radiusUnits=='m'){
  //           var perimeter = 2 * 3.14 * circleDistance
  //            this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'm';
  //         }
  //         else if(radiusUnits=='km'){
  //           var perimeter = 2 * 3.14 * circleDistance *  0.001
  //           this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'kms';
            
  //         }
  //         else if(radiusUnits=='in'){
  //           var perimeter =  2 * 3.14 * circleDistance / 0.0254
  //           this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'in';
  //         }
  //         else if(radiusUnits=='ft'){
  //           var perimeter =  2 * 3.14 * (circleDistance * 3.280839895)
  //           this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'ft';
  //         }
  //         else if(radiusUnits=='mile'){
  //           var perimeter =2 * 3.14 * (((circleDistance / 1000 * 100) / 100 ) / 1.609)
  //           this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'mile';
  //         }
  //         else if(radiusUnits=='yard'){
  //           var perimeter = 2 * 3.14 * (circleDistance * 1.0936132983)
  //           this.p_perimeter_units.innerHTML =Number(perimeter).toFixed(0) + ' ' + 'yard';
  //         }
  //         this.dropdown3.remove();
  //         this.countperimeterdropdown=0
  //       })
  //       this.dropdown3.appendChild(li);
  //       row_one.appendChild(this.dropdown3)
  //     }
  //     this.dropdown3.style.top = `${img_one.offsetTop-6}px`;
  //     this.dropdown3.style.left = `${img_one.offsetLeft+39}px`;
  //     this.countperimeterdropdown++
  //   }
  //   })
  //   units_three.appendChild(img_three)
  //   row_three.appendChild(units_three)


    this.measureTooltip = new Overlay({
      element: this.tooltipEl,
      // offset: [-150,180],
      // positioning: 'bottom-left',
      autoPan : true
    });
    console.log(this.measureTooltip,"ovelaytooltip")

    this.modify = new Modify({source : this.vectorSource});
    this.basemap.addInteraction(this.modify)

    this.basemap.addOverlay(this.measureTooltip);

    this.snap = new Snap({source : this.vectorSource})

    this.basemap.addInteraction(this.snap)

    this.measureTooltipOverlayList.push(this.measureTooltip);
   
  }
  // -------------------------- end this -----------------------------------------------------
  // Deactivated Called this method
  public destroy(): any {
    console.log('Vicinity tool destroyed!');
    this.basemap.removeLayer(this.vectorLayer);
    this.basemap.removeInteraction(this.dragCircle);
    this.basemap.removeInteraction(this.select);
    unByKey(this.listener);
    this.basemap.removeOverlay(this.overlay);
    unByKey(this.geometryChangeListener);
    this.removeOverLaysMeasurments();
    this.basemap.removeLayer(this.vectorLayerForDistance);
    this.basemap.removeInteraction(this.draw);
    const layersToRemove = [];
    console.log(this.basemapService.getCurrentBasemap().getLayers())
    this.basemapService.getCurrentBasemap().getLayers().forEach((layer) => {
      if(layer.values_.name === "bufferLayer") {
        layersToRemove.push(layer);
        // this.basemapService.getCurrentBasemap().removeLayer(layer);
      }
    });
    const len = layersToRemove.length;
    if(len > 0) {
      for(var i = 0; i < len; i++) {
        this.basemapService.getCurrentBasemap().removeLayer(layersToRemove[i]);
      }
    }
  }
  // this function will remove only added overlays not others...
  private removeOverLaysMeasurments(): any {
    
    this.measureTooltipOverlayList.forEach((overLay) => {
      this.basemap.removeOverlay(overLay);
    });
  }

}
