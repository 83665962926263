import OlMap from 'ol/Map';
import { LineString, Polygon } from 'ol/geom.js';
import { unByKey } from 'ol/Observable.js';
import Overlay from 'ol/Overlay.js';
import { getArea, getLength, getDistance } from 'ol/sphere';
import Draw from 'ol/interaction/Draw';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Select } from 'ol/interaction';
import * as condition from 'ol/events/condition';
import {transform} from 'ol/proj';
// import Sphere from 'ol/sphere.js';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { getTopRight } from 'ol/extent';
import { Subject } from 'rxjs';
export class GeometryTool {
  private _draw: any;
  private _measureTooltip: any;
  private _tooltipEl;
  private _vectorLayer: any;
  private _vectorSource: VectorSource = new VectorSource();
  private _geometryChangeListener;
  private _selectClick: any;
  private _overlay: any;
  private _measureTooltipOverlayList = [];
  private _basemapProjection;
  modify: any
  snap: any
  _p_radius_units: any
  _p_area_units: any
  count = 0
  copy_array = []
  measurementss = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
  count2 = 0;
  isCtrl: any;
  ctrlPressed: boolean;
  count3: number;
  coorodinatesofpolygon: any;
  toprightcoord: any;
  polygonlayer: any;
  measurmentcal=this.measurementss[0];
   showpolyoverlay:Subject<any> = new Subject<any>();
   savePerimeter:Subject<any> = new Subject<any>();
  polyoverlaystat=false;
  detectKey: any;
  countpoly=0;
  numberss: number;
  newperimeter: any;
  meterperimeter: string;
  kmeterperimeter: string;
  inchperimeter: string;
  feetperimeter: string;
  mileperimeter: string;
  overallperimeterdata=[];
  sqmeterarea: string;
  sqkilometerarea: string;
  sqincharea: string;
  sqfeetarea: string;
  sqyardarea: string;
  arearea: string;
  hectarearea: string;
  sqacrearea: string;
  overallareadata=[];
  sqmilearea: string;
  dropdown1: HTMLDivElement;
  dropdown: HTMLDivElement;
  _tooltipE2: HTMLDivElement;
  meterlength: string;
  kmeterlength: string;
  inchlength: string;
  feetlength: string;
  milelength: string;
  overalllinedata=[];
  countline=0;
  dropdown2: HTMLDivElement;
  countline1=0;
  yardlength: string;
  yardperimeter: string;
  countperimetercount=0;
  countoflinedropdown=0;
  countofareadropdown=0;
  countofperimeterdropdown=0;
  area=0;
  espgvalue='EPSG:4326';
  


  
  constructor(private basemap: OlMap ,private basemapService:BasemapService) {
    const proj_:any = this.basemap.getView().getProjection();
    this._basemapProjection = proj_.code;
  }

 
  private _getVectorLayer() {
    if (!this._vectorLayer) {
      this._vectorLayer = new VectorLayer({
        source: this._vectorSource,
        // Style for drawing yellow line while measuring.
        style: new Style({
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2
          })
        }),
        name: 'gtb-vector-layer'
      });
    }
    return this._vectorLayer;
  }

  private _formatLength(line) {
    // console.log('am calling multiple time??', line.getCoordinates().length);
    let length = getLength(line);
    let output;
    
    if(localStorage.getItem('unit')==undefined){
      localStorage.setItem('unit', 'mile');
   var length_unit= localStorage.getItem('unit')
    }
    else{
      var length_unit = localStorage.getItem('unit');
    }
    /*  its temp commented code if any projection presentation
    const coordinates = line.getCoordinates();
    const sourceProj = this.basemap.getView().getProjection();
    const c1 = transform(coordinates[0], sourceProj, 'EPSG:4326');
    const c2 = transform(coordinates[1], sourceProj, 'EPSG:4326');
    const distance = getDistance(c1, c2); */
    let distance = 0;
    for (let i = 0; i < line.getCoordinates().length; i++) {
      if ((i + 1) !== line.getCoordinates().length) {
        const c1 = transform(line.getCoordinates()[i], this.basemap.getView().getProjection().getCode(), 'EPSG:4326');
        const c2 = transform(line.getCoordinates()[i + 1], this.basemap.getView().getProjection().getCode(), 'EPSG:4326');
        distance = distance + getDistance(c1, c2);
      }
    }
    // distance = distance + getDistance(line.getCoordinates()[0], line.getCoordinates()[1]);
    // this commented length working fine previously..
    // length = length * 106955.0323336333; // 106969.0323336333; // length * 1000 * 100;
    console.log('what is this tempLength ', distance, length);
    length = distance;
    let conversionDistance;
    if(length_unit === 'm'){
      output =this.numberWithCommas((length).toFixed(2)) + ' ' + 'm';
    }
    else if(length_unit === 'km'){
      output= this.numberWithCommas((length * 0.001).toFixed(2)) + ' ' + 'km'
    }
    else if(length_unit === 'in'){
      output=  this.numberWithCommas((length * 39.3700787402).toFixed(2)) + ' ' + 'in';

    }
    else if(length_unit === 'ft'){
      output=  this.numberWithCommas((length * 3.280839895).toFixed(2)) + ' ' + 'ft';

    }
    else if(length_unit === 'mile'){
      output= this.numberWithCommas((length * 0.000621371192).toFixed(2)) + ' ' + 'mile';

    }
    else if(length_unit === 'yard'){
      output= this.numberWithCommas((length* 1.0936).toFixed(2)) + ' ' + 'yard';

    }
    
    // if (length_unit === 'us') {
    //   output = (Number(((length / 1000 * 100) / 100 ) / 1.609).toFixed(4)) + ' ' + 'miles';
    //   conversionDistance = Number(((length / 1000 * 100) / 100 ) / 1.609).toFixed(4);
    //   if (conversionDistance < 1) {
    //     output = (conversionDistance * 5280).toFixed(4) + ' ' + 'ft';
    //   }
    // } else if (length_unit === 'metric') {
    //   output = Number((length / 1000 * 100) / 100).toFixed(4) + ' ' + 'kms';
    //   conversionDistance = Number((length / 1000 * 100) / 100).toFixed(4);
    //   if (conversionDistance < 1) {
    //     output = (conversionDistance * 1000).toFixed(4) + ' ' + 'm';
    //   }
    // } else if (length_unit === 'METRE') {
    //   output = Number(((length / 1000 * 100) / 100) * 1000).toFixed(4) + ' ' + 'metre';
    // } else {
    //   output = Number(((length / 1000 * 100) / 100 ) / 1.609).toFixed(4) + ' ' + 'miles';
    //   conversionDistance = Number(((length / 1000 * 100) / 100 ) / 1.609).toFixed(4);
    //   if (conversionDistance < 1) {
    //     output = (output * 5280).toFixed(4) + ' ' + 'ft';
    //   }
    // }
    this.meterlength=this.numberWithCommas((length).toFixed(2)) + ' ' + 'm';
    this.kmeterlength=this.numberWithCommas((length * 0.001).toFixed(2)) + ' ' + 'km';
    this.inchlength=this.numberWithCommas((length * 39.3700787402).toFixed(2)) + ' ' + 'in';
    this.feetlength=this.numberWithCommas((length * 3.280839895).toFixed(2)) + ' ' + 'ft';
    this.milelength= this.numberWithCommas((length * 0.000621371192).toFixed(2)) + ' ' + 'mile';
    this.yardlength= this.numberWithCommas((length * 1.0936).toFixed(2)) + ' ' + 'yard';
    return output;
  }

  private _formatArea(polygon) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = (Math.round(area / 1000000 * 100) / 100) +
        ' ' + 'km<sup>2</sup>';
    } else {
      output = (Math.round(area * 100) / 100) +
        ' ' + 'm<sup>2</sup>';
    }
    return output;
  }

  private _getDraw(drawType: string): Draw {
    const drawing = new Draw({
      source: this._vectorSource,
      type: drawType,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2
        })
      })
    });
    return drawing;
  }

  private _onDrawStart() {
    localStorage.setItem('unit', 'mile')
    this._draw.on('drawstart', (evt) => {
      
      
      // set sketch
      const sketch = evt.feature;
      let tooltipCoord = evt.coordinate;
      this._geometryChangeListener = sketch.getGeometry().on('change', (evts) => {
        console.log(evts, "check evts data")
        var length_poly = evts.target.flatCoordinates.length

        console.log(length_poly, "check length")
        const geom = evts.target;
        let distance;
        if (geom instanceof Polygon) {
          console.log(geom, "check gem...")
          distance = this._formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          console.log("i am in length")
          distance = this._formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        console.log(distance, "check distnce")
        this._tooltipEl.style.position = 'relative';
        this._p_radius_units.innerHTML = distance;
        // this._p_area_units.innerHTML = length_poly - 1 *  distance
        this._measureTooltip.setPosition(tooltipCoord);

      });
    });
  }

  private _onDrawEnd() {
    this._draw.on('drawend', (evt) => {
      console.log(this.countline,this.overalllinedata,'count line')
      this._tooltipEl.style.position = 'relative';
      this._tooltipEl.className = 'tooltipGTB tooltip-staticGTB';
      this._measureTooltip.setOffset([0, -5]);
      // unset sketch
      evt.sketch = null;
      // unset tooltip so that a new one can be created
      this._tooltipEl = null;
      
      this._createMeasureTooltip();
      unByKey(this._geometryChangeListener);
      var lengthobject={
        'id': `line${this.countline}`,
        'm': this.meterlength,
        'km':this.kmeterlength,
        'in': this.inchlength,
        'ft': this.feetlength,
        'mile': this.milelength,
        'yard':this.yardlength,
      };
      this.overalllinedata.push(lengthobject);
      this.countline++
      
    });
  }

  private _createMeasureTooltip() {
    console.log("hitted length fucntion")
    if (this._tooltipEl) {
      this._tooltipEl.parentNode.removeChild(this._tooltipEl);
    }

    // this._tooltipEl = document.createElement('div');
    // this._tooltipEl.style.position = 'relative';
    // this._tooltipEl.className = 'tooltipGTB tooltip-measureGTB';


    this._tooltipEl = document.createElement('div')
    this._tooltipEl.setAttribute('id',`line${this.countline1}`)
    this.countline1++
    this._tooltipEl.style.width = '183px'
    this._tooltipEl.style.opacity = '0.75'
    this._tooltipEl.style.height = '30px !important'
    this._tooltipEl.style.background = 'black'
    this._tooltipEl.style.color = 'white'
    this._tooltipEl.style.borderRadius = '8px'
    this._tooltipEl.style.position = 'absolute'
    this._tooltipEl.style.fontSize= '10px';
    this._tooltipEl.style.border= '2px solid white'
    this._tooltipEl.style.paddingTop= '6px';
    // this._tooltipEl.style.width = '183px !important'
    // this._tooltipEl.style.opacity = '0.75 !important'
    // this._tooltipEl.style.height = '40px !important'
    // this._tooltipEl.style.background = 'black !important'
    // this._tooltipEl.style.color = 'white !important'
    // this._tooltipEl.style.borderRadius = '12px !important'
    // this._tooltipEl.style.position = 'absolute !important'
    // this._tooltipEl.style.fontSize= '13px !important';
    // this._tooltipEl.style.border= '2px solid white !important'
    // this._tooltipEl.style.paddingTop= '8px !important';

    
   

    //row one
    var row_one = document.createElement('div')
    row_one.setAttribute('class', 'row')
    row_one.style.margin = '0'
    row_one.style.padding = '0'
    row_one.style.width = '197px'

    this._tooltipEl.appendChild(row_one)

    var radius_div = document.createElement('div')
    radius_div.style.width = '30%'
    radius_div.style.height = '30px'
    row_one.appendChild(radius_div)

    var p_radius = document.createElement('p')
   
    p_radius.innerHTML = 'Length'
   
    
    radius_div.appendChild(p_radius)

    var radius_div_units = document.createElement('div')
    radius_div_units.style.width = '53%'
    radius_div_units.style.height = '30px'
    radius_div_units.style.marginLeft = '-17px'
    radius_div_units.style.marginRight = '4px'
    radius_div_units.style.textAlign = 'right'
   
    row_one.appendChild(radius_div_units)

    this._p_radius_units = document.createElement('p')
    radius_div_units.appendChild(this._p_radius_units)

    var units_one = document.createElement('div')
   
    units_one.style.height = '30px'

    var img_one = document.createElement('img')
    img_one.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    // img_one.style.margin = 'auto 0.5rem'
    img_one.style.width = '25px'
    img_one.style.marginTop= '-1px';
    img_one.style.marginLeft= '-6px';

    img_one.addEventListener('click', (evt) => {
      console.log(this.countoflinedropdown,"countdropdown")
      if(this.dropdown1){
        this.dropdown1.style.display='none'
        }
        if( this.countoflinedropdown==1){
          this.dropdown2.style.display="none"
          this.countoflinedropdown=0

        }
        
        
      else{
      // Create the dropdown menu element
      this.dropdown2 = document.createElement('div');
      this.dropdown2.style.position = 'absolute';
      this.dropdown2.style.background = 'black';
      this.dropdown2.style.opacity = '.95';
      this.dropdown2.style.color = 'white';
      this.dropdown2.style.borderRadius = '2px';
      this.dropdown2.style.width = '42px';
      
      this.dropdown2.style.padding = '5px';
      this.dropdown2.style.zIndex = '1';

      // Create the list of measurements
      const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the this.dropdown2
      for (let measurement of measurements) {

        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        li.addEventListener('click', (evt) => {
          this.countoflinedropdown=0
          
          const li = document.querySelector("li");
           var selectedunit=evt.srcElement['innerHTML']
          var  id=evt.srcElement["parentElement"].offsetParent.id;
           var per=evt.srcElement["parentElement"].offsetParent.firstElementChild.firstElementChild.nextElementSibling.innerText
           
          // var per =evt.srcElement["parentElement"].offsetParent.firstElementChild.nextSibling.firstElementChild.nextElementSibling.innerText
          console.log(evt, selectedunit, id, this.overalllinedata, "checkthepath")
          for(let i = 0; i < this.overalllinedata.length; i++) {
            console.log(id, this.overalllinedata[0].id, per, "checkthepath2")
            if (id == this.overalllinedata[i].id) {
              console.log(evt, selectedunit, id, this.overalllinedata, Object.values(this.overalllinedata[0])[1], "checkthepath2")
              console.log(this.overalllinedata[i], selectedunit, Object.values(this.overalllinedata[i]), "checkoveralldata")
              for (let j = 0; j < Object.keys(this.overalllinedata[i]).length; j++) {
                if (selectedunit == Object.keys(this.overalllinedata[i])[j]) {
                  console.log(Object.values(this.overalllinedata[i])[j], "checkoutputdata")
                  evt.srcElement["parentElement"].offsetParent.firstElementChild.firstElementChild.nextElementSibling.innerText = Object.values(this.overalllinedata[i])[j]
                  // console.log(Object.values(this.overallperimeterdata[i])[j], "check3")
                }
              }
            }
          }
          
          
          //
       
          
       
          
         
          this.dropdown2.remove();
          console.log("showdrop2")

          
        });
        
        this.dropdown2.appendChild(li);
        row_one.appendChild(this.dropdown2)
        
      }

      // Position the this.dropdown2 menu and append it to the document
      this.dropdown2.style.top = `${img_one.offsetTop + img_one.offsetHeight}px`;
      this.dropdown2.style.left = `${img_one.offsetLeft-20}px`;
      this.countoflinedropdown++
    }
     
      console.log(this.countoflinedropdown,"countdropdown")
    });
    units_one.appendChild(img_one)
    row_one.appendChild(units_one)

    //second row

    // var row_two = document.createElement('div')
    // row_two.setAttribute('class','row')
    // row_two.style.margin = '0'
    // row_two.style.padding = '0'
    // row_two.style.borderBottom = '0.5px solid black'
    // this._tooltipEl.appendChild(row_two)

    // var area_div = document.createElement('div')
    // area_div.style.width = '30%'
    // area_div.style.height = '30px'
    // row_two.appendChild(area_div)

    // var p_area = document.createElement('p')
    // p_area.innerHTML = 'Perimeter'
    // area_div.appendChild(p_area)

    // var area_div_units = document.createElement('div')
    // area_div_units.style.width = '50%'
    // area_div_units.style.height = '30px'
    // area_div_units.style.textAlign = 'right'
    // row_two.appendChild(area_div_units)

    // this._p_area_units = document.createElement('p')
    // area_div_units.appendChild(this._p_area_units)

    // var units_two = document.createElement('div')
    // units_two.style.width = '20%'
    // units_two.style.height = '30px'

    // var img_two = document.createElement('img')
    // img_two.src = 'assets/svgs/geo-tray/caret-down.svg'
    // img_two.style.margin = 'auto 0.5rem'
    // units_two.appendChild(img_two)
    // row_two.appendChild(units_two)

    this._measureTooltip = new Overlay({
      element: this._tooltipEl,
      offset: [0, -11],
      positioning: 'bottom-center'
    });
    this.basemap.addOverlay(this._measureTooltip);
    this._measureTooltipOverlayList.push(this._measureTooltip);
  }
  private _createAreaMeasureTooltip() {
    
    
    console.log(this.countofareadropdown,"areadrop")
   
    console.log(this.countpoly,"hitted area fucntion")
    if (this._tooltipEl) {
      this._tooltipEl.parentNode.removeChild(this._tooltipEl);
    }

    // this._tooltipEl = document.createElement('div')
    // this._tooltipEl.style.display='none'
    // this._tooltipEl = document.createElement('div');
    // this._tooltipEl.style.position = 'relative';
    // this._tooltipEl.className = 'tooltipGTB tooltip-measureGTB';


    this._tooltipE2 = document.createElement('div')
    this._tooltipE2.setAttribute('id',`poly${this.countpoly}`)
    this._tooltipE2.style.width = '245px'
    this._tooltipE2.style.opacity = '0.75'
    this._tooltipE2.style.height = '56px'
    this._tooltipE2.style.background = 'black'
    this._tooltipE2.style.color = 'white'
    this._tooltipE2.style.border='3px solid white'
    this._tooltipE2.style.borderRadius = '8px'
    this._tooltipE2.style.position = 'absolute'
    this._tooltipE2.style.fontSize= '13px';
    this._tooltipE2.style.padding= '6px';
    // this._tooltipE2.addEventListener('click',(evt)=>{
    //     // var numberss=parseFloat(evt.srcElement.outerText)
    //         // // localStorage.getItem(localStorage.getItem('unit'))
    //         // console.log(numberss,evt.srcElement.offsetParent.id,numberss,"selectpoly")
    //         // var perimeternumber=evt.srcElement.outerText;
    //   this.numberss=parseFloat(evt.srcElement.outerText)
    //   console.log(this.numberss,"dfghjdfghjfchjg")
    //   this.savePerimeter.next(this.numberss)
    //   console.log(evt.srcElement.offsetParent.id,typeof(this.numberss),"selectpoly")
    // })
    

    //row one
    var row_one = document.createElement('div')
    row_one.setAttribute('class', 'row')
    row_one.style.margin = '0'
    row_one.style.padding = '0'

    this._tooltipE2.appendChild(row_one)
    row_one.style.borderBottom = '0.5px solid #9A9B9F'
    row_one.style.height='23px'
    var radius_div = document.createElement('div')
    radius_div.style.width = '30%'
    radius_div.style.height = '30px'
    row_one.appendChild(radius_div)

    var p_radius = document.createElement('p')
    p_radius.innerHTML = 'Area'
    radius_div.appendChild(p_radius)

    var radius_div_units = document.createElement('div')
    radius_div_units.style.width = '73%'
    radius_div_units.style.height = '30px'
    radius_div_units.style.textAlign = 'right'
    radius_div_units.style.marginRight = '-5px'
    radius_div_units.style.marginLeft = '-25px'
    row_one.appendChild(radius_div_units)

    this._p_area_units = document.createElement('p')
    // this._p_area_units.style.marginTop = '0';
    // this._p_area_units.style.marginBottom = '1rem';
    // this._p_area_units.style.marginLeft = '6px';
    // this._p_area_units.style.marginRight = '-32px';
    
    this._p_area_units.style.marginLeft= '0px';
    this._p_area_units.style.width='164px'
   
    radius_div_units.appendChild(this._p_area_units)

    var units_one = document.createElement('div')
    units_one.style.width = '4%'
    units_one.style.height = '30px'


    var img_one = document.createElement('img')
    img_one.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    
    img_one.style.marginLeft = "5px";
    img_one.style.cursor="pointer"
    img_one.style.width='18px';
    img_one.addEventListener('click', (evt) => {
      if(this.dropdown){
      this.dropdown.style.display='none'
      }
      this.countofperimeterdropdown=0
      if(this.countofareadropdown==1){
        this.dropdown1.style.display = 'none';
        this.countofareadropdown=0
        console.log(this.countofareadropdown,"areadrop")
      }
      else{
      // Create the dropdown menu element
      this.dropdown1 = document.createElement('div');
      this.dropdown1.style.position = 'absolute';
      this.dropdown1.style.background = 'black';
      this.dropdown1.style.opacity = '.95';
      this.dropdown1.style.color = 'white';
      this.dropdown1.style.borderRadius = '2px';
      this.dropdown1.style.width = '55px';
      
      this.dropdown1.style.padding = '5px';
      this.dropdown1.style.zIndex = '1';

      // Create the list of measurements
      const areameasurements = ['sq.m', 'sq.km','sq.mile', 'sq.in', 'sq.ft', 'sq.yd', "acre", "are", "hectare"];
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the dropdown
      for (let measurement of areameasurements) {

        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        li.addEventListener('click', (evt) => {
          this.countofareadropdown=0
          
          const li = document.querySelector("li");
          var selectedunit=evt.srcElement["innerHTML"]
          
          var id=evt.srcElement["parentElement"].offsetParent.id
          
          var are =evt.srcElement['parentElement'].offsetParent.firstElementChild.firstElementChild.nextElementSibling.innerText
          console.log(evt,selectedunit,id,are,"checkthepath2") 
          for(let i =0;i<this.overallareadata.length;i++){
                        if(id==this.overallareadata[i].id){
                          console.log(this.overallareadata[i],selectedunit,Object.keys(this.overallareadata[i]),"checkoveralldata")
                          for(let j=0;j<Object.keys(this.overallareadata[i]).length;j++){
                          if(selectedunit==Object.keys(this.overallareadata[i])[j]){
                            console.log(Object.values(this.overallareadata[i])[j],"checkoutputdata")
                            evt.srcElement['parentElement'].offsetParent.firstElementChild.firstElementChild.nextElementSibling.innerText=Object.values(this.overallareadata[i])[j]
                          }
                        }
                      }
                      }
                      selectedunit='sq.m'
          //           var id= evt['srcElement']['offsetParent'].id
// var per=evt.srcElement['offsetParent'].children[1].firstChild.parentElement.parentElement.lastChild.lastChild.offsetParent.lastChild.parentElement.firstElementChild.nextElementSibling.children[1].innerText
//           console.log(evt,id,"ghghgh3")
//           console.log(per,"ghghgh4")
//           var selectedunit=evt.srcElement.innerHTML
//           for(let i =0;i<this.overallperimeterdata.length;i++){
//             if(id==this.overallperimeterdata[i].id){
//               console.log(this.overallperimeterdata[i],selectedunit,Object.keys(this.overallperimeterdata[i]),"checkoveralldata")
//               for(let j=0;j<Object.keys(this.overallperimeterdata[i]).length;j++){
//               if(selectedunit==Object.keys(this.overallperimeterdata[i])[j]){
//                 console.log(Object.values(this.overallperimeterdata[i])[j],"checkoutputdata")
//                 evt.srcElement['offsetParent'].children[1].firstChild.parentElement.parentElement.lastChild.lastChild.offsetParent.lastChild.parentElement.firstElementChild.nextElementSibling.children[1].innerText=Object.values(this.overallperimeterdata[i])[j]
//               }
//             }
//           }
//           }
       
      
          
         
          
          this.dropdown1.remove();
         
          
        });
        
        this.dropdown1.appendChild(li);
        row_one.append(this.dropdown1)
        
      }

      // Position the dropdown menu and append it to the document
      this.dropdown1.style.top = `${img_two.offsetTop + img_two.offsetHeight}px`;
      this.dropdown1.style.left = `${img_two.offsetLeft-30}px`;
      
      this.countofareadropdown++
      }
    });
    
    units_one.appendChild(img_one)
    row_one.appendChild(units_one)
    
    //second row

    var row_two = document.createElement('div')
    row_two.setAttribute('class', 'row')
    row_two.style.margin = '0'
    row_two.style.padding = '0'

    this._tooltipE2.appendChild(row_two)

    var area_div = document.createElement('div')
    area_div.style.width = '26%'
    area_div.style.height = '30px'
    area_div.style.marginRight = '35px'
    row_two.appendChild(area_div)

    var p_area = document.createElement('p')
    p_area.innerHTML = 'Perimeter'
    area_div.appendChild(p_area)

    var area_div_units = document.createElement('div')
    area_div_units.style.width = '50%'
    area_div_units.style.height = '30px'
    area_div_units.style.textAlign = 'right'
    area_div_units.style.marginLeft='-5px'
    area_div_units.style.marginRight='6px'
    row_two.appendChild(area_div_units)

    this._p_radius_units = document.createElement('p')
    area_div_units.appendChild(this._p_radius_units)

    var units_two = document.createElement('div')

    units_two.style.height = '30px'

    var img_two = document.createElement('img')
    img_two.src = 'assets/svgs/geo-tray/caret-down-white.svg'
    img_two.style.cursor="pointer"
    img_two.style.width='18px';
    
    units_two.appendChild(img_two)
    row_two.appendChild(units_two)
    img_two.addEventListener('click', (evt) => {
      this.countofareadropdown=0
      if(this.dropdown1){
        this.dropdown1.style.display='none'
        }
        if(this.countofperimeterdropdown==1){
          this.dropdown.style.display = 'none';
          this.countofperimeterdropdown=0
        }
        else{
      // Create the dropdown menu element
      this.dropdown = document.createElement('div');
      this.dropdown.style.position = 'absolute';
      this.dropdown.style.background = 'black';
      this.dropdown.style.opacity = '.95';
      this.dropdown.style.color = 'white';
      this.dropdown.style.borderRadius = '2px';
      this.dropdown.style.width = '42px';
      
      this.dropdown.style.padding = '5px';
      this.dropdown.style.zIndex = '1';

      // Create the list of measurements
      const measurements = ['m', 'km', 'in', 'ft',  'mile','yard'];
      // const measurements = ['m', 'km', 'in', 'ft', 'yd', 'mile', 'acre', 'are', 'hectare'];
     
  //     this.numberss=parseFloat(evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText);
  //     var newarea=this.calculateAreaUnits(this.numberss)
  //     evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText=newarea
  // console.log(evt,evt['srcElement']['offsetParent'].firstChild.childNodes[1].innerText,this.numberss,"ghghgh2")
      // Create the list items and append them to the this.dropdown
      for (let measurement of measurements) {

        const li = document.createElement('li');
        li.style.fontSize = '12px'
        li.style.borderBottom = '0.5px solid white'
        li.innerHTML = measurement;
        li.style.listStyle = 'none';
        li.style.padding = '5px';
        li.style.cursor = 'pointer';
        li.style.textAlign = 'center';
        li.addEventListener('click', (evt) => {
         
          this.countofperimeterdropdown=0
          
          const li = document.querySelector("li");
          var selectedunit=evt.srcElement["innerHTML"]
          
          var id=evt.srcElement["parentElement"].offsetParent.id
          
          var per =evt.srcElement["parentElement"].offsetParent.firstElementChild.nextSibling.firstElementChild.nextElementSibling.innerText
          console.log(evt,selectedunit,id,"checkthepath")
          for(let i =0;i<this.overallperimeterdata.length;i++){
                        if(id==this.overallperimeterdata[i].id){
                          console.log(this.overallperimeterdata[i],selectedunit,Object.keys(this.overallperimeterdata[i]),"checkoveralldata")
                          for(let j=0;j<Object.keys(this.overallperimeterdata[i]).length;j++){
                          if(selectedunit==Object.keys(this.overallperimeterdata[i])[j]){
                            console.log(Object.values(this.overallperimeterdata[i])[j],"checkoutputdata")
                            evt.srcElement["parentElement"].offsetParent.firstElementChild.nextSibling.firstElementChild.nextElementSibling.innerText=Object.values(this.overallperimeterdata[i])[j]
                          }
                        }
                      }
                      }
                      selectedunit='m'
          //           var id= evt['srcElement']['offsetParent'].id
// var per=evt.srcElement['offsetParent'].children[1].firstChild.parentElement.parentElement.lastChild.lastChild.offsetParent.lastChild.parentElement.firstElementChild.nextElementSibling.children[1].innerText
//           console.log(evt,id,"ghghgh3")
//           console.log(per,"ghghgh4")
//           var selectedunit=evt.srcElement.innerHTML
//           for(let i =0;i<this.overallperimeterdata.length;i++){
//             if(id==this.overallperimeterdata[i].id){
//               console.log(this.overallperimeterdata[i],selectedunit,Object.keys(this.overallperimeterdata[i]),"checkoveralldata")
//               for(let j=0;j<Object.keys(this.overallperimeterdata[i]).length;j++){
//               if(selectedunit==Object.keys(this.overallperimeterdata[i])[j]){
//                 console.log(Object.values(this.overallperimeterdata[i])[j],"checkoutputdata")
//                 evt.srcElement['offsetParent'].children[1].firstChild.parentElement.parentElement.lastChild.lastChild.offsetParent.lastChild.parentElement.firstElementChild.nextElementSibling.children[1].innerText=Object.values(this.overallperimeterdata[i])[j]
//               }
//             }
//           }
//           }
       
          
       
          
         
          this.dropdown.remove();
          
                    
        });
        this.dropdown.appendChild(li);
        row_two.append(this.dropdown)
        
      }

      // Position the this.dropdown menu and append it to the document
      this.dropdown.style.top = `${img_two.offsetTop + img_two.offsetHeight}px`;
      this.dropdown.style.left = `${img_two.offsetLeft-20}px`;
      this.countofperimeterdropdown++
    }
    });
    this._measureTooltip = new Overlay({
      element: this._tooltipE2,
      offset: [0, 185],
      positioning: 'top-center'
    });

    this.basemap.addOverlay(this._measureTooltip);
    this._measureTooltipOverlayList.push(this._measureTooltip);
    
  }

  // Click Event on Geomotry tool
  public drawLineCalculateLength(options) {
    this.polyoverlaystat=false;
    console.log("i am length cal")
    this.basemap.addLayer(this._getVectorLayer());
    this._draw = this._getDraw('LineString');
    this.basemap.addInteraction(this._draw);
    this._createMeasureTooltip();
    this._onDrawStart();
    this._onDrawEnd();
    return this;
  }
  multiply(a: number, b: number): number {
    console.log(a, b, "check the input numbers passed")
    // Convert numbers to strings with fixed 17 decimal places
    const aStr = a.toFixed(17);
    const bStr = b.toFixed(17);

    console.log(aStr, bStr, "check the a and b after fixed to 17 decimals")

    // Split strings into integer and decimal parts
    const aParts = aStr.split(".");
    const bParts = bStr.split(".");

    console.log(aParts, "check the aparts splitting")
    console.log(bParts, "check the aparts splitting")

    const aInt = parseInt(aParts[0]);
    const aDec = parseInt(aParts[1]);
    const bInt = parseInt(bParts[0]);
    const bDec = parseInt(bParts[1]);

    // Multiply integer and decimal parts separately
    const intProduct = aInt * bInt;
    const decProduct = (aDec / Math.pow(10, 17)) * (bDec / Math.pow(10, 17));

    console.log(intProduct, decProduct, intProduct + decProduct, `${intProduct}.${decProduct}`, "cehck the modified")
    console.log(intProduct + decProduct, "check the decimal ")
    // Add products together and return result
    return intProduct + decProduct;
  }
  private calculateAreaUnits(area) {
    var output;

    const areameasurements = ['m', 'km', 'in', 'ft', 'yd','mile', "acre", "are", "hectare"];
    var units = this.measurmentcal
    // localStorage.getItem('unit')
    if (areameasurements[0] === units) {
      output = this.numberWithCommas((area).toFixed(2)) + ' ' + 'sq.m'
    } else if (areameasurements[1] === units) {
      output = (area * 0.001).toFixed(2) + ' ' + 'km<sup>2</sup>'
    } else if (areameasurements[2] === units) {
      output = (area * 39.3700787402).toFixed(2) + ' ' + 'in<sup>2</sup>'
    } else if (areameasurements[3] === units) {
      output = (area * 3.280839895).toFixed(2) + ' ' + 'ft<sup>2</sup>'
    } else if (areameasurements[4] === units) {
      output = (area * 1.0936132983).toFixed(2) + ' ' + 'yard<sup>2</sup>'
    }  else if (areameasurements[6] === units) {
      output = (area * 0.0002471054).toFixed(2) + ' ' + 'acre'
    } else if (areameasurements[7] === units) {
      output = (area * 0.01).toFixed(2) + ' ' + 'are'
    } else if (areameasurements[8] === units) {
      output = (area * 0.0001).toFixed(2) + ' ' + 'hectare'
    }
    this.sqmeterarea= this.numberWithCommas((area).toFixed(2)) + ' ' + 'sq.m'
this.sqkilometerarea=  this.numberWithCommas((area * 0.001).toFixed(2)) + ' ' + 'sq.km'
this.sqmilearea=  this.numberWithCommas((area *  0.000621371192).toFixed(2)) + ' ' + 'sq.mile';
this.sqincharea=  this.numberWithCommas((area * 39.3700787402).toFixed(2)) + ' ' + 'sq.in'
this.sqfeetarea=  this.numberWithCommas((area * 3.280839895).toFixed(2)) + ' ' + 'sq.feet'
this.sqyardarea=  this.numberWithCommas((area * 1.0936132983).toFixed(2)) + ' ' + 'sq.yard'
this.sqacrearea=  this.numberWithCommas((area * 0.0002471054).toFixed(2)) + ' ' + 'acre'
this.arearea=  this.numberWithCommas((area * 0.01).toFixed(2)) + ' ' + 'are'
this.hectarearea=  this.numberWithCommas((area * 0.0001).toFixed(2)) + ' ' + 'hectare'
    return output
  }
  public numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  private calculatePerimeterUnits(perimeter) {
    console.log(typeof(perimeter),"checkperi")
    var output;
    this.countperimetercount++
    if(this.countperimetercount==1){
      localStorage.setItem('unit', 'mile');
    }
    
    // localStorage.setItem('unit', 'mile');
    if(localStorage.getItem('unit')==undefined){
      localStorage.setItem('unit', 'mile');
   
    }
    
    else{
       var length_unit = localStorage.getItem('unit');
    }
     length_unit= localStorage.getItem('unit');
    const measurements = ['m', 'km', 'in', 'ft',  "mile","yard"];
    
    if(length_unit === 'm'){
      output =this.numberWithCommas((perimeter).toFixed(2)) + ' ' + 'm';
    }
    else if(length_unit === 'km'){
      output= this.numberWithCommas((perimeter * 0.001).toFixed(2)) + ' ' + 'km'
    }
    else if(length_unit === 'in'){
      output=  this.numberWithCommas((perimeter * 39.3700787402).toFixed(2)) + ' ' + 'in';

    }
    else if(length_unit === 'ft'){
      output=  this.numberWithCommas((perimeter * 3.280839895).toFixed(2)) + ' ' + 'ft';

    }
    else if(length_unit === 'mile'){
      output= this.numberWithCommas((perimeter * 0.000621371192).toFixed(2)) + ' ' + 'mile';

    }
    else if(length_unit === 'yard'){
      output= this.numberWithCommas((perimeter* 1.0936).toFixed(2)) + ' ' + 'yard';

    }
    this.meterperimeter=this.numberWithCommas((perimeter).toFixed(2)) + ' ' + 'm';
    this.kmeterperimeter=this.numberWithCommas((perimeter * 0.001).toFixed(2)) + ' ' + 'km';
    this.inchperimeter=this.numberWithCommas((perimeter * 39.3700787402).toFixed(2)) + ' ' + 'in';
    this.feetperimeter=this.numberWithCommas((perimeter * 3.280839895).toFixed(2)) + ' ' + 'ft';
    this.mileperimeter=this.numberWithCommas((perimeter * 0.000621371192).toFixed(2)) + ' ' + 'mile';
    this.yardperimeter=this.numberWithCommas((perimeter *  1.0936).toFixed(2)) + ' ' + 'yard';
   
    return output
  }
  private _onDrawAreaStart() {
   
    var transform_Array = []
    console.log( this.area,"check area")
    // if(!this.polyoverlaystat){
    // this._tooltipE2 = document.createElement('div')
    //   this._tooltipE2.style.display='none'
    // }
    if(this.polyoverlaystat){
    
    this.polygonlayer=this._draw.on('drawstart', (evt) => {
      this.count2++
      
      console.log(this.count2,"i am poly")
     
          console.log("i am poly")
          // this._draw = this._getDraw('Polygon');
          
         this._createAreaMeasureTooltip();
         
       
      // if (this.showpolyoverlay) {
      //   console.log("i am poly")
      //   this._draw = this._getDraw('Polygon');
      //   this._createAreaMeasureTooltip();

      // }
      // else {
      //   console.log("i am length in poly")
      //   this._createMeasureTooltip();
      // }
      console.log(evt, "check events in onDrawStart")
      // set sketch
      const sketch = evt.feature;
      let tooltipCoord = evt.coordinate;
      console.log(tooltipCoord, "check coordinates of draw")
      this._geometryChangeListener = sketch.getGeometry().on('change', (evts) => {
        console.log(evts, "check evts data")
        var length_poly = evts.target.flatCoordinates.length

        console.log(length_poly, "check length")
        const geom = evts.target;
        console.log(geom.getCoordinates(), "check...........")
        ////
        this.coorodinatesofpolygon=geom.getExtent();
        this.toprightcoord=getTopRight(this.coorodinatesofpolygon)
        console.log(this.toprightcoord, "checkextent")

        const coordinates_Polygon = geom.getCoordinates()
        var array_coordinates = coordinates_Polygon[0]
        var output_coord_area = []
        var output_coord_perimeter = []
        var w_array = []
        var y_array = []
        var loop_perimeter = []
        var w_sum = 0
        var y_sum = 0
        var area
        var perimeter = 0
        this.espgvalue=this.basemapService.getCurrentBasemap().getView().getProjection().getCode();
        console.log( this.espgvalue,"check epsg in geometry")
        // var sourceprj=getProjection("")
        console.log(array_coordinates, "check the coordinates of polygon")
        for (let i = 0; i < array_coordinates.length; i++) {
          for (let j = 0; j < 1; j++) {
            const transformed_coord_area = this.basemapService.getTransformedCoordinates([array_coordinates[i][j], array_coordinates[i][j + 1]], this.basemap.getView().getProjection(), this.basemapService.getDestinationProjectionEquArea())
            const transformed_coord_perimeter = this.basemapService.getTransformedCoordinates([array_coordinates[i][j], array_coordinates[i][j + 1]], this.basemap.getView().getProjection(), this.basemapService.getDestinationProjectionEquDistance())
            console.log(this.basemapService.getDestinationProjectionEquArea(), "check destination of area")
            console.log(this.basemapService.getDestinationProjectionEquDistance(), "check destination of perimter")
            output_coord_area.push(transformed_coord_area)
            output_coord_perimeter.push(transformed_coord_perimeter)
          }
        }


        console.log(output_coord_area, "check output coordinates of area")
        console.log(output_coord_perimeter, "check output coordinates of perimeter")
        console.log(output_coord_area, "check test_arry coordinatessss")
        if (output_coord_area.length === array_coordinates.length) {
          for (let i = 0; i < output_coord_area.length - 1; i++) {
            console.log(output_coord_area[i][0], output_coord_area[i + 1][1], ((output_coord_area[i][0]) * (output_coord_area[i + 1][1])), `${i}th array coordinates`)
            w_array.push(this.multiply(output_coord_area[i][0], output_coord_area[i + 1][1]))
            y_array.push(this.multiply(output_coord_area[i][1], output_coord_area[i + 1][0]))
            // w_array.push(this.multiply((test_array[i][0]) ,(test_array[i+1][1])))
            // y_array.push(this.multiply((test_array[i][1]) ,(test_array[i+1][0])))
            console.log(w_array, "check after every loop")
          }


          //  console.log(perimeter,"check the perimeter")

          w_array.map((x) => {
            w_sum = w_sum + x
          })

          y_array.map((x) => {
            y_sum = y_sum + x
          })

          area = (w_sum - y_sum) / 2


          console.log(w_array, "check all the w combination")
          console.log(y_array, "check all the y combinations")

          console.log(w_sum, "check sum of x")
          console.log(y_sum, "check sum of y")
          console.log(area, "check the area......")

        }

        if (output_coord_perimeter.length === array_coordinates.length) {
          for (let i = 0; i < output_coord_perimeter.length - 1; i++) {
            var x_point = output_coord_perimeter[i + 1][0] - output_coord_perimeter[i][0]
            var y_point = output_coord_perimeter[i + 1][1] - output_coord_perimeter[i][1]
            console.log(output_coord_perimeter[i + 1][0], output_coord_perimeter[i][0], `${i}th points of x`)
            console.log(output_coord_perimeter[i + 1][1], output_coord_perimeter[i][1], `${i}th points of y`)
            console.log(x_point, y_point, "check x and y")
            console.log(((x_point * x_point) + (y_point * y_point)), "check the sum of x^2 + y^2")
            var perimter_xy = Math.sqrt(this.multiply(x_point, x_point) + this.multiply(y_point, y_point))
            console.log(Math.sqrt(this.multiply(x_point, x_point)), "check x point")
            console.log(perimter_xy, "check the perimeter")

            loop_perimeter.push(perimter_xy)

          }
          loop_perimeter.map((x) => {
            console.log(x, "check the each x..in perimeter")
            perimeter = perimeter + x
          })

          console.log(perimeter, "check perimeter")
          console.log(loop_perimeter, "check the perimter array")
        }

        let area_calc;
        let perimter_calc;
        this.area=area
        area = Math.abs(area)
        area_calc = this.calculateAreaUnits(area)
        perimter_calc = this.calculatePerimeterUnits(perimeter)
        if (geom instanceof Polygon) {
          console.log(geom, "check gem...")
          // distance = this._formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          console.log("i am in length")
          area = this._formatLength(geom);

          tooltipCoord = geom.getLastCoordinate();
        }
        console.log(Math.abs(area), "check distnce")
        this._tooltipE2.style.position = 'relative';
        this._p_radius_units.innerHTML = perimter_calc;
        this._p_area_units.innerHTML = area_calc;
        this._measureTooltip.setPosition(tooltipCoord);

      });
    });
  }
  }
  private _onDrawAreaEnd() {
    
    this._draw.on('drawend', (evt) => {
      
      console.log("draw start end")
      this.count2 = 0;
      this._tooltipE2.style.position = 'relative';
      this._tooltipE2.className = 'tooltipGTB tooltip-staticGTB';
      this.toprightcoord=getTopRight(this.coorodinatesofpolygon[0])
      console.log(this.toprightcoord,'toprightcoord')
      this._measureTooltip.setOffset([0, -5]);
      // unset sketch
      evt.sketch = null;
      console.log("")
      // unset tooltip so that a new one can be created
      this._tooltipE2 = null;
      this._createAreaMeasureTooltip()
      unByKey(this._geometryChangeListener);
      unByKey(this.detectKey);
      // const areameasurements = ['sq.m', 'sq.km', 'sq.in', 'sq.ft', 'sq.yd', "acre", "are", "hectare"]
      var areaobject={
        'id': `poly${this.countpoly}`,
        'sq.m': this.sqmeterarea,
        'sq.km':this.sqkilometerarea,
        'sq.mile':this.sqmilearea,
        'sq.in': this.sqincharea,
        'sq.ft': this.sqfeetarea,
        'sq.yd':this.sqyardarea,
        "acre":this.sqacrearea,
        "are":this.arearea,
        "hectare":this.hectarearea,

      }
      this.overallareadata.push(areaobject);
      var perimeterObject = {
        'id': `poly${this.countpoly}`,
        'm': this.meterperimeter,
        'km':this.kmeterperimeter,
        'in': this.inchperimeter,
        'ft': this.feetperimeter,
        'mile': this.mileperimeter,
        'yard':this.yardperimeter,
      };
      console.log(perimeterObject,"check perimeter data")
      this.overallperimeterdata.push(perimeterObject);
      console.log( this.overallperimeterdata,this.overallareadata,"check perimeter data")

    });
  }
  public drawPolygonCalculatePolygon(){
   
    console.log("i am length poly")
    this.polyoverlaystat=true;
      this.basemap.addLayer(this._getVectorLayer());
      this._draw = this._getDraw('Polygon');
      this.basemap.addInteraction(this._draw);
      
      
      this._onDrawAreaStart()
      // this._createAreaMeasureTooltip()
      this._onDrawAreaEnd()
      
      return this;
      
  }
  // Ctrl + Click on Geometry tool
  public getLayerPropertiesInfo(options) {
    
    this.polyoverlaystat=false;
    console.log('ctrl click Geometry');
    this._selectClick = new Select({
      condition: condition.click
    });
    this.basemap.addInteraction(this._selectClick);
    this._mapSelectClickEvent(options);
    return this;
  }

  private _mapSelectClickEvent(options) {
    const propertiesMap = new Map();
    this._selectClick.on('select', (evt) => {
      let area = 0;
      const bounds = [];
      let length = 0;
      if (evt.target.getFeatures().getLength() > 0 ) {
        evt.target.getFeatures().forEach((feature) => {
          console.log(feature.getGeometry().getType());
          if (feature.getGeometry().getType() === 'Polygon') {
            area = feature.getGeometry().getArea() * 1000 * 1000;
          } else if (feature.getGeometry().getType() === 'LineString') {
            length = feature.getGeometry().getLength() * 100;
          }
          console.log(' geometry - Bounds ', feature.getGeometry().getExtent(), area, length);
          feature.getGeometry().getExtent().forEach(bound => {
            bounds.push(bound.toFixed(2));
          });
        });
        propertiesMap.set('Area', area.toFixed(2) + ' - Units');
        propertiesMap.set('BBox', bounds);
        propertiesMap.set('Length', length.toFixed(2));
        propertiesMap.set('Perimeter', '');
        propertiesMap.set('Coord System', this._basemapProjection);
        const coordinates = evt.mapBrowserEvent.coordinate;
        this._popupOperations(options, 'layer-feature-info', propertiesMap, coordinates);
      }
    });
  }

  /** this method used for open popup and add to map */
  private _popupOperations(options, contentType, content, coordinates) {
    const popupComp = options.popupComponent;
    this._overlay = popupComp.getGeoPopup();
    popupComp.setContent(contentType, content);
    this._overlay.setPosition(coordinates);
    // this.basemap.getView().setCenter(coordinates);
    this.basemap.addOverlay(this._overlay);
    this.basemap.getOverlays().forEach((overLay, index) => {
      if ((this.basemap.getOverlays().getLength() - 1) === index) {
        console.log('what is overlay rendered top ', parseInt(overLay.rendered.top_, 1), overLay.getMap());
        if (parseInt(overLay.rendered.top_, 10) < 227) {
          overLay.element.style.zIndex = '1';
          overLay.element.style.transform = 'rotate(180deg)';
          overLay.element.firstChild.firstChild.style.transform = 'rotate(180deg)';
        } else {
          overLay.element.style.zIndex = '1';
          overLay.element.style.transform = 'rotate(0deg)';
          overLay.element.firstChild.firstChild.style.transform = 'rotate(0deg)';
        }
        overLay.element.style.transform = overLay.rendered.transform_;
        /* console.log('View ', overLay, overLay.element.firstChild, overLay.getMap(),
          overLay.getPositioning(), overLay.getOptions().element.clientHeight, window.screen.height); */
      }
    });
    return popupComp;
  }

  public destroy() {
    console.log('Destroying Geometry tool');
    this.basemap.removeLayer(this._vectorLayer);
    this.basemap.removeInteraction(this._draw);
    this.basemap.removeInteraction(this._selectClick);
    unByKey(this._geometryChangeListener);
    this._removeOverLaysMeasurments();
    this.basemap.removeOverlay(this._overlay);
  }

  // this function will remove only added overlays not others...
  private _removeOverLaysMeasurments() {
    this._measureTooltipOverlayList.forEach((overLay) => {
      this.basemap.removeOverlay(overLay);
    });
  }
}