import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import OlXYZ from 'ol/source/XYZ';


export class StamenMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
    return new OlTileLayer({
      source: new OlXYZ({
        url: 'https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}@2x.png',
        attributions: [
          '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a>',
          '&copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a>',
          '&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a>',
          '&copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>'
        ],
        tilePixelRatio: 2,
        maxZoom: 20
      }),
      name: 'terrain',
      visible: false
    });
  }
}

