import { IBasemap } from './IBasemap';
import OlTileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

export class SatelliteGoogleMap implements IBasemap {
  getMapTileLayer(): OlTileLayer {
      return new OlTileLayer({
        visible: false,
        source: new XYZ({
            crossOrigin: 'anonymous',
            url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
         }),
         name: 'googlesatellite',
        });
  }
}

