<div class="org-creation-container">
    <div class="org-creation-steps-container">
        <p class="page-title">Organization Registration Process</p>
        <div class="org-creation-steps">
            <div [ngClass]="{'org-creation-step': true, 'active-row': item.active}"
                *ngFor="let item of menus ; let i=index">
                <div class="step-index-container">
                    <span *ngIf='!item.valid'>{{item.index}}</span>
                    <span *ngIf="item.valid" class="material-icons"> done </span>
                </div>
                <div class="step-name-container">
                    <p id={{i+1}} (click)="process($event.target.id)">{{item.name}}</p>
                </div>
            </div>
        </div>
        <div class="seleted-org-creation-step">
            <p>{{activeWizard.name}}</p>
        </div>
    </div>
    <div class="org-creation-data-container">
        <div class="org-creation-header">
            <img src="assets/images/login/fuse-earth-white.png">
        </div>
        <div class="org-creation-body">
            <div *ngIf="activeWizard.id=='ORG_DETAILS'">
                <form [formGroup]="orgDetailsForm" id="orgDetailsForm" [hidden]="true">
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container">
                            <input class="full-width" type="text" formControlName="orgName"
                                placeholder="Organization Name*"
                                (change)="getOrganizationsWithStr($event.target.value)">
                            <p class="field-status-msg invalid" *ngIf="validOrg === orgStatus.INVALID">Organization
                                present with this name. Choose another.</p>
                            <p class="field-status-msg valid" *ngIf="validOrg === orgStatus.VALID">Organization
                                available</p>
                            <p class="field-status-msg" *ngIf="validOrg === orgStatus.INPROGRESS">Checking organization
                                existence...</p>
                            <span *ngIf="!orgDetailsForm.get('orgName').valid && orgDetailsForm.get('orgName').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgName'), 'Organization Name')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container">
                            <input class="full-width" type="text" formControlName="orgAddress"
                                placeholder="Organization Address*">
                            <span
                                *ngIf="!orgDetailsForm.get('orgAddress').valid && orgDetailsForm.get('orgAddress').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgAddress'), 'Organization Address')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <!-- <input class="full-width" type="text" formControlName="orgType" placeholder="Organization Type*"> -->
                            <select style="width: 100%; height: 100%; max-height: 30px;" formControlName="orgType">
                                <option value="" selected>Select Org Type*</option>
                                <option *ngFor="let type of orgTypesList" value="{{type.value}}">{{type.name}}</option>
                            </select>
                            <span *ngIf="!orgDetailsForm.get('orgType').valid && orgDetailsForm.get('orgType').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgType'), 'Organization Type', 'SELECT')}}
                            </span>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="city" placeholder="City*">
                            <span *ngIf="!orgDetailsForm.get('city').valid && orgDetailsForm.get('city').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('city'), 'City')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <!-- <input class="full-width" type="text" formControlName="country" placeholder="Country*"> -->
                            <select style="width: 100%; height: 100%; max-height: 30px;" placeholder="Country*"
                                formControlName="country">
                                <option value="" selected>Select Country*</option>
                                <option *ngFor="let cntry of countryList" value="{{cntry}}">{{cntry}}</option>
                            </select>
                            <span *ngIf="!orgDetailsForm.get('country').valid && orgDetailsForm.get('country').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('country'), 'Country', 'SELECT')}}
                            </span>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="postalCode"
                                placeholder="Postal Code*">
                            <span
                                *ngIf="!orgDetailsForm.get('postalCode').valid && orgDetailsForm.get('postalCode').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('postalCode'), 'Postal Code')}}
                            </span>
                        </div>
                    </div>

                    <br>
                </form>
                <!--//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
                <form [formGroup]="orgDetailsForm" id="orgDetailsForm" [hidden]="false">
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container">
                            <input class="full-width" type="text" formControlName="orgName"
                                placeholder="Organization Name*"
                                (change)="getOrganizationsWithStr($event.target.value)">
                            <p class="field-status-msg invalid" *ngIf="validOrg === orgStatus.INVALID">Organization
                                present with this name. Choose another.</p>
                            <p class="field-status-msg valid" *ngIf="validOrg === orgStatus.VALID">Organization
                                available</p>
                            <p class="field-status-msg" *ngIf="validOrg === orgStatus.INPROGRESS">Checking organization
                                existence...</p>
                            <span *ngIf="!orgDetailsForm.get('orgName').valid && orgDetailsForm.get('orgName').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgName'), 'Organization Name')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container">
                            <input class="full-width" type="text" formControlName="orgAddress"
                                placeholder="Organization Address*">
                            <span
                                *ngIf="!orgDetailsForm.get('orgAddress').valid && orgDetailsForm.get('orgAddress').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgAddress'), 'Organization Address')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <!-- <input class="full-width" type="text" formControlName="orgType" placeholder="Organization Type*"> -->
                            <select style="width: 100%; height: 100%; max-height: 30px;" formControlName="orgType">
                                <option value="" selected>Select Org Type*</option>
                                <option *ngFor="let type of orgTypesList" value="{{type.value}}">{{type.name}}</option>
                            </select>
                            <span *ngIf="!orgDetailsForm.get('orgType').valid && orgDetailsForm.get('orgType').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('orgType'), 'Organization Type', 'SELECT')}}
                            </span>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="city" placeholder="City*">
                            <span *ngIf="!orgDetailsForm.get('city').valid && orgDetailsForm.get('city').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('city'), 'City')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <!-- <input class="full-width" type="text" formControlName="country" placeholder="Country*"> -->
                            <select style="width: 100%; height: 100%; max-height: 30px;" placeholder="Country*"
                                formControlName="country">
                                <option value="" selected>Select Country*</option>
                                <option *ngFor="let cntry of countryList" value="{{cntry}}">{{cntry}}</option>
                            </select>
                            <span *ngIf="!orgDetailsForm.get('country').valid && orgDetailsForm.get('country').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('country'), 'Country', 'SELECT')}}
                            </span>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="postalCode"
                                placeholder="Postal Code*">
                            <span
                                *ngIf="!orgDetailsForm.get('postalCode').valid && orgDetailsForm.get('postalCode').touched"
                                class="error">
                                {{getErrorMsg(orgDetailsForm.get('postalCode'), 'Postal Code')}}
                            </span>
                        </div>
                    </div>

                    <br>
                </form>


                <!--//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
            </div>



            <div *ngIf="activeWizard.id=='ADMIN_DETAILS'">
                <form [formGroup]="adminDetailsForm" id="adminDetailsForm">
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="firstName" placeholder="First Name*">
                            <span
                                *ngIf="!adminDetailsForm.get('firstName').valid && adminDetailsForm.get('firstName').touched"
                                class="error">
                                {{getErrorMsg(adminDetailsForm.get('firstName'), 'First Name')}}
                            </span>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="text" formControlName="lastName" placeholder="Last Name*">
                            <span
                                *ngIf="!adminDetailsForm.get('lastName').valid && adminDetailsForm.get('lastName').touched"
                                class="error">
                                {{getErrorMsg(adminDetailsForm.get('lastName'), 'Last Name')}}
                            </span>
                        </div>
                    </div>

                    <div class="fields-row-container col-md-12">
                        <div class="fields-container">
                            <input class="full-width" type="text" formControlName="email" placeholder="Email Id*">
                            <span *ngIf="!adminDetailsForm.get('email').valid && adminDetailsForm.get('email').touched"
                                class="error">
                                {{getErrorMsg(adminDetailsForm.get('email'), 'Email Id')}}
                            </span>
                        </div>
                    </div>
                    <div class="fields-row-container col-md-12">
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="password" formControlName="password"
                                placeholder="Password*">
                            <span
                                *ngIf="!adminDetailsForm.get('password').valid && adminDetailsForm.get('password').touched"
                                class="error">
                                {{getErrorMsg(adminDetailsForm.get('password'), 'Password')}}
                            </span>
                            <p class="hint">Password should be combination of alpha numeric & special characters,
                                minimum of 8 characters.</p>
                        </div>
                        <div class="fields-container col-md-6">
                            <input class="full-width" type="password" formControlName="confirmPassword"
                                placeholder="Confirm Password*">
                            <span
                                *ngIf="!adminDetailsForm.get('confirmPassword').valid && adminDetailsForm.get('confirmPassword').touched"
                                class="error">
                                {{getErrorMsg(adminDetailsForm.get('confirmPassword'), 'Confirm Password')}}
                            </span>
                        </div>
                    </div>

                    <div class="fields-row-container col-md-12">
                        <p class="desclamair">By clicking Sign Up, you agree to our
                            <span class="links" (click)="openPage('terms')">Terms & Conditions</span> and that you have
                            read all our
                            <span class="links" (click)="openPage('policy')">Policies,</span> including our <span>Cookie
                                Use.</span>
                        </p>
                    </div>

                    <br>
                </form>
            </div>


            <div *ngIf="activeWizard.id=='EMAIL_VER'">
                <p *ngIf="operationStatus === ''">Creating account...</p>
                <p *ngIf="operationStatus === 'success'">Organization creation success. Verification mail has been sent.
                    Please verfiy and continue for login.</p>
            </div>
            <div *ngIf="activeWizard.id=='SUBSC_PLAN'">

                <div style="display: flex; margin-top: 3%; margin-bottom: 26% ">

                    <div class="tp-wrapper " *ngFor="let pkg of packages ; let i=index">
                        <div  class="tp-box" (click)="selectstarterpack(i)" [class.selected]="i === cardno" [@flipState]="pkg.Flipstate">
                            <div class="tp-box__side tp-box__front">
                                <div style="padding-top: 5px;">
                                    <img *ngIf="i === cardno" src="../../../assets/images/tick.png" alt=""
                                        width="30px" height="30px"
                                        style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                                </div>
                                <div
                                    style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  font-size: 14px;">
                                    {{pkg.name}}</div>
                                <div [hidden]="!isTotalPrice" style="color: black; font-size: 16px;"><b>${{pkg.amount}}</b></div>
                                <div [hidden]="isTotalPrice" style="color: black; font-size: 16px;"><b>${{pkg.Totalamount}}</b></div>
                                <div style="color: black; margin-top: 8%;">{{pkg.user}}</div>
                                <div style="color: black;margin-bottom: 9%;font-size: 9px;"><b>{{pkg.usersbt}}</b></div>
                                <div>
                                    <select class="month" name="attribute" id="attribute" placeholder="none"
                                        (change)="Selectexploremonth($event.target.value, pkg.amount,i)"
                                        style="    margin-bottom: 5%;">

                                        <option value="attribute" [value]="item"
                                            *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                                    </select>
                                </div>

                                <div><a (click)="toggleFlipStarter(i)"
                                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                                </div>
                            </div>
                            <div class="tp-box__side tp-box__back">

                                <p>Features</p>
                                <ul style="text-align:left;font-size: 10px;margin-left: -17px">
                                    <li *ngFor="let feature of pkg.packageItems ; let j=index">{{feature.name}}</li>
                                </ul>
                                <a (click)="toggleFlipStarter(i)"
                                    style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px;text-decoration: underline;">Back</a>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="tp-wrapper">
                        <div class="tp-box" (click)="selectexplorepack()" [@flipState]="flipExplore">
                            <div class="tp-box__side tp-box__front">
                                <div style="    padding-top: 5px;">
                                    <img [hidden]="!showexploretick" src="../../../assets/images/tick.png" alt=""
                                        width="30px" height="30px"
                                        style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                                </div>
                                <div
                                    style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  font-size: 22px;">
                                    Explore</div>
                                <div style="color: black; font-size: 16px;"><b>{{selectedexpplanprice}}</b></div>
                                <div style="color: black;">10 User</div>
                                <div>
                                    <select class="month" name="attribute" id="attribute" placeholder="none"
                                        (change)="Selectexploremonth($event.target.value)"
                                        style="    margin-bottom: 5%;">

                                        <option value="attribute" [value]="item"
                                            *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                                    </select>
                                </div>
                                <a (click)="toggleFlipExplore()"
                                    style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                            </div>
                            <div class="tp-box__side tp-box__back">

                                <p>Features</p>
                                <ul style="text-align:left;font-size: 10px;margin-left: -17px;overflow: auto;">
                                    <li>Look up locations</li>
                                    <li>Change basemaps</li>
                                    <li>Add GIS data</li>
                                    <li>Measure distances and areas</li>
                                    <li>Create your own GIS data</li>
                                    <li>Share your work</li>
                                    <li>Georeference</li>
                                    <li>Add project data</li>


                                </ul>
                                <a (click)="toggleFlipExplore()"
                                    style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px; text-decoration: underline;">Back</a>

                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="tp-wrapper">
                        <div class="tp-box" (click)="selectengagepack()" [@flipState]="flipEngage">
                            <div class="tp-box__side tp-box__front">
                                <div style="    padding-top: 5px;">
                                    <img [hidden]="!showengagetick" src="../../../assets/images/tick.png" alt=""
                                        width="30px" height="30px"
                                        style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                                </div>
                                <div
                                    style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  font-size: 22px;">
                                    Engage</div>
                                <div style="color: black; font-size: 16px;"><b>{{selectedengageprice}}</b></div>
                                <div style="color: black;">50 User</div>
                                <div>
                                    <select class="month" name="attribute" id="attribute" placeholder="none"
                                        (change)="Selectengagemonth($event.target.value)"
                                        style="    margin-bottom: 6%;">

                                        <option value="attribute" [value]="item"
                                            *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                                    </select>
                                </div>
                                <div>
                                    <a (click)="toggleFlipEngage()"
                                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                                </div>
                            </div>
                            <div class="tp-box__side tp-box__back">

                                <p>Features</p>
                                <ul style="text-align:left;font-size: 10px;margin-left: -17px;overflow: auto;">
                                    <li>Look up locations</li>
                                    <li>Change basemaps</li>
                                    <li>Add GIS data</li>
                                    <li>Measure distances and areas</li>
                                    <li>Create your own GIS data</li>
                                    <li>Share your work</li>
                                    <li>Georeference</li>
                                    <li>Add project data</li>
                                    <li>Custom query GIS data</li>
                                    <li>Symbolize your work</li>
                                    <li>Project manage your work</li>
                                    <li>Make printable maps</li>
                                    <li>Create your teams</li>


                                </ul>
                                <a (click)="toggleFlipEngage()"
                                    style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px;text-decoration: underline;">Back</a>

                            </div>
                        </div>
                    </div> -->

                </div>
                <div style="bottom: 120px;
                position: relative;
                font-size: 11px;
                width: 85%;
                margin-left: 5%;
            ">*There may be additional data costs, customization, and configuration costs for specific cases. Please reach out to discuss your organization's specific needs that are not listed here.</div>
            </div>
            <div *ngIf="activeWizard.id=='PAYMENT_DETAILS'">
                <div class="d-flex" >

                    <div style="text-align:left;width: 40%;">
                        <div
                            style="margin-left:1%;font-size:18px;text-decoration: underline;margin-bottom: 2px;margin-right: 10px;">
                            Billing Address</div>
                        <div style="margin-left:1%;font-size:14px;
                        margin-top: 3%;">
                            {{orgname}}
                        </div>
                        <div style="margin-left:1%;font-size:14px;">
                            {{orgaddress}}
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div>
                            <div
                                style="font-size:18px;text-decoration: underline;text-align: left;margin-left: 10px;margin-top: 2px;">
                                Order Summary
                            </div>
                            <div class="d-flex"
                                style="text-align: left; width: 100%;border-bottom: 2px solid lightgray;margin-top: 2%;">
                                <div style="font-size: 18px; margin-left:2%;">
                                    {{finalpackagename}} X ({{finalselectedmonth}})
                                </div>

                                <div style=" margin-left:26%; margin-bottom: 2%;">
                                    {{finalselectedprice}}

                                </div>
                                <br>

                            </div>
                            <div class="d-flex"
                                style="text-align: left;width: 100%;border-bottom: 2px solid lightgray;margin-top: 2%;">
                                <div style="font-size: 14px; margin-left:18%;">
                                    Tax
                                </div>

                                <div style=" margin-left: 65%; margin-bottom: 1%;margin-top: 0;">
                                    $ 20

                                </div>
                                <br>

                            </div>
                            <div class="d-flex" style="text-align: left;width:100%;">
                                <div style="font-size: 18px; margin-left:17%;">
                                    Total
                                </div>

                                <div style=" margin-left: 58%; margin-bottom: 1%; margin-top: 1%;">
                                    $ {{totalprice}}

                                </div>
                                <br>

                            </div>


                        </div>

                    </div>

                </div>
                

            </div>
        </div>
        <div class="org-creation-footer">
            <div class="col-md-12" style="margin: 0; font-size: 12px;" *ngIf="errMsg!==''">
                <p style="margin: 0; text-align: center;" class="err-msg">{{errMsg}}</p>
            </div>
            <div class="wizard-ops">
                <div class="back-button-container">
                    <button type="button" class="btn btn-outline-secondary" *ngIf="!activeWizard.first"
                        [disabled]="activeWizard.disabled" (click)="showPreviousPage()">Back</button>
                </div>
                <div class="org-logo-container">
                    <img src="assets/images/login/mapsol-white.png">
                </div>
                <div class="next-button-container">
                    <button type="button" class="btn btn-outline-secondary" *ngIf="!activeWizard.last"
                        (click)="onSubmit(activeWizard.form)"
                        [disabled]="!activeWizard.form.valid || activeWizard.disabled">Next</button>
                    <button type="button" class="btn btn-outline-secondary" *ngIf="activeWizard.last"
                        [disabled]="activeWizard.disabled" (click)="showNextPage()">
                        <!-- Submit -->
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>