import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import Overlay from 'ol/Overlay';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { CommonService } from 'src/app/Services/common.service';
import { Select } from 'ol/interaction';
import Feature from 'ol/Feature';

@Component({
  selector: 'app-dbftable',
  templateUrl: './dbftable.component.html',
  styleUrls: ['./dbftable.component.scss']
})
export class DbfTableComponent implements OnInit {
  @ViewChild('container') _containerEl: ElementRef;
  @ViewChild('closer') _closerEl: ElementRef;
  private _popupOverlay: Overlay;
  tableData = [];
  tableColumns = [];
  layer:any;
  private select: any;
  features
  // static _popupOverlay: any;
  // static _containerEl: any;
  // static _closerEl: any;
  // static close: any;
  // static tableColumns: any;
  // static tableData: any[];


  constructor(private basemap: BasemapService, private commonService: CommonService) { }

  ngOnInit() { 
    this.select = new Select();
    this.features = this.select.getFeatures();
    
  }

  getdbfTablePopup() {
    this._popupOverlay = new Overlay({
      element: this._containerEl.nativeElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this._closerEl.nativeElement.onclick = () => {
      this.close();
    };
    return this._popupOverlay;
  }

  public close() {
    this._popupOverlay.setPosition(undefined);
    this._closerEl.nativeElement.blur();
    return false;
  }

  setPropertyValues(tableData, tableColumns, layerObj) {
    this.tableColumns = tableColumns;
    this.tableData = [];
    this.layer = layerObj;
    Object.entries(tableData).forEach(entry => {
      const dataRow = [];
      Object.entries(entry[1]).forEach( data => {
        dataRow.push(data[1]);
      });
      this.tableData.push(dataRow);
    });
  }
  checkboxToggle(event):any {
    console.log('checkbox event triggered ', event, this.tableColumns, event.target['checked'])
    console.log('print row & column ', event.target['defaultValue'].split(','), this.layer)
    
    this.basemap.getCurrentBasemap().getLayers().forEach((layerObj) => {
      if (layerObj.values_.name === this.layer.name) {
        console.log(layerObj)
        console.log('features ', layerObj.getSource().getFeatures())
        layerObj.getSource().getFeatures().forEach(feature => {
          console.log(feature.values_, this.tableColumns[0], event.target['defaultValue'].split(',')[0])
          if(feature.values_[this.tableColumns[0]] === event.target['defaultValue'].split(',')[0]) {
            console.log('matched ', feature);
            if(event.target['checked']) {
              this.features.push(feature);
            } else {
              this.features.remove(feature);
            }
          }
        });
      }
    });
    if(!this.commonService.isSelectionActive) {      
      this.basemap.getCurrentBasemap().removeInteraction(this.select);      
      console.log('after clear ', this.select.getFeatures())
      return;
    } else {
      let isSelectadd = true;
      this.basemap.getCurrentBasemap().getInteractions().forEach((interaction) => {
        console.log('interaction are ', interaction)
        if (interaction instanceof Select) {
          isSelectadd = false;
        }
      });
      if(isSelectadd) this.basemap.getCurrentBasemap().addInteraction(this.select);
    }
    console.log('selection Toogle in dbftable ', this.select, this.commonService.isSelectionActive, this.select.getFeatures())
    
  }
}
