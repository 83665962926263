
<div
style="position: absolute; top: 70%; left: 30%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.5); width: 100vw; height: 100vh; display: flex; align-items: center; justify-content: center; z-index: 1050;"
>
<!-- Popup Content -->
<div
  #container
  class="popup interaction-info-container"
  ngDraggable
  style="width: 450px; border-radius: 8px; background-color: white;"
>
  <div
    class="position-relative d-flex align-items-center p-3"
    style="background-color: #703A96; color: white; border-top-left-radius: 8px; border-top-right-radius: 8px; font-size: 1.1rem; font-weight: bold;"
  >
    <span class="mx-auto">Select The Label</span>
    <i
      #closer
      class="material-icons position-absolute"
      style="top: 17px; right: 12px; cursor: pointer; font-size: 1rem;"
      (click)="close()"
      >close</i
    >
  </div>
  <div class="interaction-info-body-container p-4">
    <div class="form-group mb-3">
      <div class="input-group">
        <span class="input-group-text" id="options-label">Label</span>
        <select
          id="labelDropdown"
          class="form-select"
          aria-label="Options"
          (change)="onChange($event.target.value)"
        >
          <option value="NoLabel">Choose...</option>
          <option *ngFor="let column of columnsList; let i = index" [value]="column">
            {{ column }}
          </option>
        </select>
        <button class="btn btn-primary ms-2" name="heatmap" (click)="heatmapclicked()">Heat Map</button>
      </div>
    </div>
    <form>
      <div class="form-group">
        <label for="radius" style="font-size: 1rem;">Radius Size:</label>
        <input
          id="radius"
          type="range"
          class="form-range"
          min="1"
          max="50"
          step="1"
          value="5"
          (change)="onChangeRaidus($event.target.value)"
        />
      </div>
      <div class="form-group">
        <label for="blur" style="font-size: 1rem;">Blur Size:</label>
        <input
          id="blur"
          type="range"
          class="form-range"
          min="1"
          max="50"
          step="1"
          value="15"
          (change)="onChangeBlur($event.target.value)"
        />
      </div>
    </form>
  </div>
</div>
</div> 