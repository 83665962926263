<!-- <li [ngClass]="{'hide-layer': layer.previewLayer}" class="layer-main" id="{{layer.name}}"
  *ngFor="let layer of layersList; let i = index;" [ngStyle]="{'z-index': layersList.length-i }"> -->
  <!-- <app-tower-item-options *ngIf="layer.selected" [layerItemOptActive]="layerItemOptActive" [isGroup]="false" [layerData]="layer" -->
    <!-- <div #draggingTower [ngStyle]="{
      'width': towerWidth+'px', 
      'height': layerOptionsHeight+'px',
      'margin-top': '0px' 
    }" 
    class="tower-before-noscroll" [ngStyle]="{'z-index': layerOrder*3}"></div> -->
    <!-- class="tower-before-noscroll" [ngStyle]="{'z-index': showLayerCapFun()  ? layerOrder*3+1 : layerOrder*3}"></div> -->
    <app-tower-item-options *ngIf="layer.selected" [isGroup]="false" [layerData]="layer"
    [ngStyle]="{'left': towerWidth+'px', 'width': towerOptionsWidth+'px' }"
    [towerWidth]="towerWidth"
    [towerOptionsWidth]="towerOptionsWidth"
    (saveTowerLayer)="saveTowerLayerFun($event)"
    (previewIsActiveEmit)="previewIsActiveEmit($event)"
    (collapseRibbon)="_layerOptionActivateEvent($event, layer, false)"
    [towerId]="towerId" 
    [isDeleteDisable]="isDeleteDisable"
    (sliderVal)="sliderValue=$event">
  </app-tower-item-options>

  <!--circular///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->


  <!-- <div [ngClass]="{'hide-layer': layer.previewLayer}" [ngStyle]="{'width': towerWidth+'px' }" class="tower-item-div"> -->
    <ul class="circle" >
      <!-- <span [ngStyle]="{'height': layerOptionsHeight+'px', 'background': !isValid(layer.proj) ? 'gray' : '' }" matTooltip="Connection" container="body" id="{{layer.name}}" (click) = "showItemPropertyWindow($event, layer)" style="cursor: pointer;">c</span> -->
      <li>
        <div class="text" style="color: black;cursor: pointer;" title="Preview" (click)="showPreviewFun(layer)" longPress (mouseLongPress)="previewORgrouplayer()" *ngIf="searchorhand">
         
          <img src="../../../assets/svgs/geotower/preview.svg" alt=""  style="width: 12px;transform: skewY(174deg) rotate(256deg);margin-top: -5px;margin-left: -1px;">
        </div>
        <div class="text" style="color: black;cursor: pointer;"  title="Group Layer" (click)="showLayer(layer)" longPress (mouseLongPress)="previewORgrouplayer()" *ngIf="!searchorhand"><img src="../../../assets/svgs/geotower/group_layer.svg" alt=""  style="width: 12px;transform: skewY(174deg) rotate(256deg);margin-top: -5px;margin-left: -1px;"></div>
      </li>
      <li>
        <div class="text" style="color: black;cursor: pointer;"  (click)="tables($event)" title="Table" longPress (mouseLongPress)="tableORdatabase()" *ngIf="tableordatabase"><img src="../../../assets/svgs/geotower/table.svg" alt=""  style="width: 12px;transform: skewY(353deg) rotate(214deg);padding-top: 5px;margin-left: -3px;"></div>
        <div class="text" style="color: black;cursor: pointer;" (click)="savelayer($event)"  title="Save To Database" longPress (mouseLongPress)="tableORdatabase()" *ngIf="!tableordatabase"><img src="../../../assets/svgs/geotower/save_to_db.svg" alt=""  style="width: 12px;transform: skewY(353deg) rotate(214deg);padding-top: 5px;margin-left: -3px;"></div>
      </li>
      <li>
        <div class="text" style="color: black;cursor: pointer;" 
        title="Active / Inactive Toggle"  [ngClass]="{'showlayer' :'showlayervisibility' , 'hidelayer': !showlayervisibility}" (click)="showLayer(layer)" title="Show Layer" longPress (mouseLongPress)="showlayerORdeletelayer()" *ngIf="showlayerordeletelayer"><img *ngIf="!showlayervisibility" src="../../../assets/svgs/geotower/raster_vector_toggle_disable.svg" alt=""  style="width: 12px;transform: skewY(353deg) rotate(185deg);" >
        <img *ngIf="showlayervisibility" src="../../../assets/svgs/geotower/toggle_active.svg" alt=""  style="width: 12px;transform: skewY(353deg) rotate(185deg);" ></div>
        <div class="text" style="color: black;cursor: pointer;" 
        title="Active / Inactive Toggle" (click)="deletelayer()" title="Delete Layer"  longPress (mouseLongPress)="showlayerORdeletelayer()"  *ngIf="!showlayerordeletelayer"><img src="../../../assets/svgs/geotower/delete_layer.svg" alt=""   style="width:13px;margin-top: -3px;margin-left: -3px;transform: skewY(353deg) rotate(179deg);"></div>
      </li>
      <li>
        <!-- <div class="text" style="color: black;cursor: pointer;" title="Selection" longPress (mouseLongPress)="selectionORlabel()" *ngIf="selectionorlabel"><img src="../../../assets/svgs/geotower/selection.svg" alt="" style="width: 12px;transform: skewY(353deg) rotate(56deg);"></div> -->
        <div class="text" style="color: black;cursor: pointer;" title="Label" longPress (mouseLongPress)="selectionORlabel()" (click)="displayingLables(layer, false)" *ngIf="selectionorlabel"><img src="../../../assets/svgs/geotower/label.svg" alt="" style="width: 12px;transform: skewY(353deg) rotate(145deg);"></div>
        <div class="text" style="color: black;cursor: pointer;" title="Selection" longPress (mouseLongPress)="selectionORlabel()" (click)="selectionToggle()" *ngIf="!selectionorlabel"><img src="../../../assets/svgs/geotower/selection.svg" alt="" style="width: 12px;transform: skewY(353deg) rotate(56deg);"></div>
        <!-- (click)="pythonScriptAPI(layer)" -->
      </li>
      <li>
        <div class="text"  title="Zoom to Layer" (click)="zoomToLayer(layer)" style="color: black;cursor: pointer;"   longPress (mouseLongPress)="zoomORpan()" *ngIf="zoomorpan"><img src="../../../assets/svgs/geotower/zoom_to_layer.svg" alt="" style="width: 12px;transform: skewY(353deg) rotate(95deg);"></div>
        <div class="text"  title="Pan To Layer" (click)="pantolayer()" style="color: black;cursor: pointer;"   longPress (mouseLongPress)="zoomORpan()" *ngIf="!zoomorpan"><img src="../../../assets/svgs/geotower/pan_to_layer.svg" alt="" style="width: 12px;transform: skewY(353deg) rotate(115deg);"></div>
      </li>
     <!--<li>
        <div class="text" [ngStyle]="!isConnectionDataAvailable(layer) ? {'background': '#bbb9b9', 'color':'rgba(0,0,0,0.5)'} : {'cursor': 'pointer'}" 
        title="Connection" id="{{layer.name}}" (click) = "showItemPropertyWindow($event, layer, CONNECTION_HEADER)" style="cursor: pointer;">C</div>
      </li>
      <li>
        <div class="text" title="Specification" id="{{layer.name}}" (click)="showItemPropertyWindow($event, layer, SPECIFICATION_HEADER)" style="cursor: pointer;">S</div>
      </li>
      <li>
        <div class="text" title="Function" container="body" id="{{layer.name}}" (click) = "showItemPropertyWindow($event, layer, FUNCTION_HEADER)" style="cursor: pointer;" >F</div>
      </li>
      <li>
        <div class="text" title="Depicition" id="{{layer.name}}" (click)="showItemPropertyWindow($event, layer, DEPICTION_HEADER)" style="cursor: pointer;">D</div>
      </li>
      <li>
        <div class="text" title="Interaction" id="{{layer.name}}" (click)="showItemPropertyWindow($event, layer, INTERACTION_HEADER)" style="cursor: pointer;">I</div>
      </li>-->
      <li>
        <!-- Connection Tab -->
        <div class="text" 
         [ngStyle]="!isConnectionDataAvailable(layer) ? {'background': '#bbb9b9', 'color':'rgba(0,0,0,0.5)'} : {'cursor': 'pointer'}" 
         title="Connection" 
         id="{{layer.name}}" 
         (click)="showItemPropertyWindow($event, layer, CONNECTION_HEADER, 'C')" 
         [ngClass]="{'active-tab': activeTab === 'C'}">
      C
    </div>
      </li>
      <li>
        <!-- Specification Tab -->
        <div class="text" 
             title="Specification" 
             id="{{layer.name}}" 
             (click)="showItemPropertyWindow($event, layer, SPECIFICATION_HEADER, 'S')" 
             style="cursor: pointer;">S
        </div>
      </li>
      <li>
        <!-- Function Tab -->
        <div class="text" 
             title="Function" 
             container="body" 
             id="{{layer.name}}" 
             (click)="showItemPropertyWindow($event, layer, FUNCTION_HEADER, 'F')" 
             style="cursor: pointer;">F
        </div>
      </li>
      <li>
        <!-- Depiction Tab -->
        <div class="text" 
             title="Depiction" 
             id="{{layer.name}}" 
             (click)="showItemPropertyWindow($event, layer, DEPICTION_HEADER, 'D')" 
             style="cursor: pointer;">D
        </div>
      </li>
      <li>
        <!-- Interaction Tab -->
        <div class="text" 
             title="Interaction" 
             id="{{layer.name}}" 
             (click)="showItemPropertyWindow($event, layer, INTERACTION_HEADER, 'I')" 
             style="cursor: pointer;">I
        </div>
      </li>
      
     
    </ul>
  <!-- </div> -->
  <div class="inner-coin" #container>
   
   <div class="layer-opacity"  [ngStyle]="{'background': 'linear-gradient(to right, ' + firstColor + sliderValue + '%,' + secondColor + ' ' + sliderValue + '%' }">
    <span *ngIf="showslidervalue" style="align-items: center;
    position: absolute;
 top: 25%;
 left: 20%;
 font-size: 22px;z-index: 1;cursor: pointer;transform: rotate(90deg);
 -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */" title="{{layer.name}}" matTooltipClass="mat-custom-tooltip" ><b>{{sliderValue}}%</b></span>
  <span *ngIf="!showslidervalue" style="align-items: center;
  position: absolute;
top: 13%;
left: 38%;
font-size: 34px;z-index: 1;cursor: pointer;transform: rotate(90deg);
-webkit-user-select: none; /* Safari */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Standard */" title="{{layer.name}}" matTooltipClass="mat-custom-tooltip" ><b>{{layer.name.toUpperCase( )  | slice:0:1}}</b></span>
    <input matTooltipPosition="left" matTooltip="{{layer.name}}" matTooltipClass="mat-custom-tooltip" (change)="layerSlider($event)" type="range" min="1" max="100" value="75" class="slider"  [(ngModel)]="sliderValue" [value]="sliderValue" (mousedown)="dragfeature(false)" (mouseup)="dragfeature(true)">
  </div>
  </div>

<!-- CSFDI modal -->
<div *ngIf="isModalOpen" class="custom-modal-container" appDraggable cdkDrag>

    <div class="custom-modal-sidebar">
      <button (click)="setActiveTab('C')" [ngClass]="{ 'active': activeTab === 'C' }" class="custom-modal-button c-button">C</button>
      <button (click)="setActiveTab('S')" [ngClass]="{ 'active': activeTab === 'S' }" class="custom-modal-button s-button">S</button>
      <button (click)="setActiveTab('F')" [ngClass]="{ 'active': activeTab === 'F' }" class="custom-modal-button f-button">F</button>
      <button (click)="setActiveTab('D')" [ngClass]="{ 'active': activeTab === 'D' }" class="custom-modal-button d-button">D</button>
      <button (click)="setActiveTab('I')" [ngClass]="{ 'active': activeTab === 'I' }" class="custom-modal-button i-button">I</button>
    </div>


    <div class="custom-modal-content">
 
      <div class="modal-header draggable-handle">
        <span [ngStyle]="{ 'color': getTabColor() }">{{ getTabName() }}</span>
        <button mat-icon-button (click)="closeModal()" aria-label="Close" class="custom-modal-close">
          <mat-icon>close</mat-icon>
        </button>
      </div>

     
      <ng-container *ngIf="activeTab === 'C'">
        <div class="connection-info-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Property Title</th>
                <th>Property Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let connection of connProperties; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ connection.title }}</td>
                <td>{{ connection.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>


      <ng-container *ngIf="activeTab === 'S'">
        <div class="specification-info-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Property Title</th>
                <th>Property Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let specification of specProperties; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ specification.title }}</td>
                <td>{{ specification.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      
      <ng-container *ngIf="activeTab === 'F'">
        <div class="function-info-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Property Title</th>
                <th>Property Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let func of funProperties; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ func.title }}</td>
                <td>{{ func.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

    
      <ng-container *ngIf="activeTab === 'D'">
<script src="https://cdn.rawgit.com/openlayers/openlayers.github.io/master/en/v6.1.1/build/ol.js"></script>  
   
    <div *ngIf="depictionforlayer =='Notgeometric'" class="depiction-info-body-container">
          <table class="table depiction-info-table table-striped">
            <thead style="background-color: white;">
            <tr style="background-color: white;">
              <th scope="col">S.No</th>
              <th scope="col">Property Title</th>
              <th scope="col">Property Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let depiction of depProperties; index as i">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{depiction.title}} </td>
              <td>{{depiction.value}}</td>
            </tr>
            </tbody>
          </table>
      
    </div>
    <div *ngIf="depictionforlayer == 'MultiPolygon' || depictionforlayer == 'Polygon' || depictionforlayer == 'PolygonZ' || depictionforlayer == 'PolygonM' || depictionforlayer == 'MultiPatch'">
      <div class="class=depiction-info-body-container">
        <table class="table depiction-info-table">
          <thead style="background-color: white;">
            <tr style="background-color: white;">
              <th scope="col" ><h6 style="text-align: left; background-color: white;">Property title</h6></th>
              <th scope="col"><h6 style="text-align: left;padding-left: 18px; background-color: white;" > 
                Values
              </h6></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="property-label" style="text-align: left; ">Outline Color</th>
              <td>
                <div class="property-value-container">
                  <input type="color" class="form-control form-control-color" id="colorpicker" [(ngModel)]="selectedColor" (change)="selectOutlineColorFun(selectedColor)" title="Choose your color">
                </div>
              </td>
            </tr>

            <tr>
              <th class="property-label" style="text-align: left;">Outline Thickness</th>
              <td>
                <div class="property-value-container" (click)="toggleDropdown('thickness')" style="position: relative;">
                  <div>________</div>
                  <i *ngIf="showoutlinethicknesspopup; else downArrow" class="fa fa-caret-up dropdown-icon"></i>
                  <ng-template #downArrow>
                    <i class="fa fa-caret-down dropdown-icon"></i>
                  </ng-template>
            
                  <div *ngIf="showoutlinethicknesspopup" class="dropdown-menu-custom bg-white p-2 shadow rounded">
                    <a class="dropdown-item" (click)="setlinethickness(1)">
                      <div style="height: 1px; background-color: black; width: 100%;"></div>
                    </a>
                    <a class="dropdown-item" (click)="setlinethickness(2)">
                      <div style="height: 2px; background-color: black; width: 100%;"></div>
                    </a>
                    <a class="dropdown-item" (click)="setlinethickness(3)">
                      <div style="height: 3px; background-color: black; width: 100%;"></div>
                    </a>
                    <a class="dropdown-item" (click)="setlinethickness(5)">
                      <div style="height: 5px; background-color: black; width: 100%;"></div>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            
              <tr>
                <th class="property-label" style="text-align: left;">Outline Style</th>
                <td>
                  <div class="property-value-container" (click)="toggleDropdown('style')" style="position: relative; cursor: pointer;">
                    <div>.............</div>
                    <i *ngIf="showoutlinestylepopup; else downArrowStyle" class="fa fa-caret-up dropdown-icon"></i>
                    <ng-template #downArrowStyle>
                      <i class="fa fa-caret-down dropdown-icon"></i>
                    </ng-template>
              
                    <div *ngIf="showoutlinestylepopup" class="dropdown-menu-custom bg-white p-2 shadow rounded">
                      <a class="dropdown-item text-center" (click)="setlinestyle('dashed')" style="font-size: 24px; color: black; cursor: pointer;">--------</a>
                      <a class="dropdown-item text-center" (click)="setlinestyle('dotted')" style="font-size: 24px; color: black; cursor: pointer;">........</a>
                      <a class="dropdown-item text-center" (click)="setlinestyle('solid')" style="font-size: 24px; color: black; cursor: pointer;">_____</a>
                    </div>
                  </div>
                </td>
              </tr>

            
            

            <tr>
              <th class="property-label" style="text-align: left; ">Fill Color</th>
              <td>
                <div class="property-value-container" >
                  <input type="color" class="form-control form-control-color" id="colorpicker" [(ngModel)]="selectedColor2" (change)="selectfillColorFun(selectedColor2)" title="Choose your color">
                </div>
              </td>
            </tr>

          </tbody>
          
        </table>
      </div>
    <div *ngIf="depictionforlayer == 'Point' || depictionforlayer == 'MultiPoint' || depictionforlayer == 'PointM' || depictionforlayer == 'PointZ'">
      
      <div class="depiction-info-body-container" style="border: 2px solid rgb(196, 47, 104);">

        <table class="table depiction-info-table">
          <thead style="background-color: white;">
            <tr style="background-color:white;">
              <th scope="col" ><h3 style="text-align: left; background-color: white; ">Property title</h3></th>
              <th scope="col"><h3 style="text-align: left;padding-left: 18px; background-color: white;" > 
                Values
              </h3></th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <th style="margin-right: 5px;text-align: left;    margin-left: 7px;font-weight: 500;">Fill Color</th>
              <th scope="row"><div class="col-md-5">
                <input type="color" id="colorpicker" [(ngModel)]="selectedColor3" (change)="selectsymbolColorFun(selectedColor3)" style="border: none;">
              </div></th>
            </tr><tr >
              <th style="text-align: left;    margin-left: 7px;font-weight: 500;">Symbol</th>
              <th  scope="row"><div class="col-md-5 d-flex" style="padding-left: 2px;margin-left: 15px;
                 cursor: pointer;" (click)="showsymbolpopup=!showsymbolpopup">
                <div style="margin-bottom:5px"><img [src]="firstsymbol"  class="image" alt="" style="margin-left: 9px;"> </div></div></th>
              
              <div  *ngIf="showsymbolpopup" style="position: absolute;left: 101%;top: 9px;background-color: white; border-radius: 12px; width: 154px;">
                <div class="grid-container" style="padding:16px;">
                  <div *ngFor="let image of symbols; let i = index;" class="grid-item">
                    <img [src]="image" class="image" (click)="selectsymbol(i)">
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="depictionforlayer == 'LineString' || depictionforlayer == 'MultiLineString' || depictionforlayer == 'LineStringZ' || depictionforlayer == 'LineStringM' || depictionforlayer == 'CompoundCurve' || depictionforlayer == 'CircularString'">
      
      <div class="depiction-info-body-container" style="border: 2px solid rgb(196, 47, 104);">

        <table class="table depiction-info-table">
          <thead style="background-color: white;">
            <tr style="background-color: white;">
              <th scope="col" ><h3 style="text-align: left; background-color: white;">Property title</h3></th>
              <th scope="col"><h3 style="text-align: left;padding-left: 18px; background-color: white;" > 
                Values
              </h3></th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <th style="margin-right: 5px;text-align: left;    margin-left: 7px;font-weight: 500;">Outline Color</th>
              <th scope="row"><div class="col-md-5">
                <input type="color" id="colorpicker" [(ngModel)]="selectedColor4" (change)="selectOutlineColorFun4(selectedColor4)" style="border: none;">
              </div></th>
            </tr><tr >
              <th style="text-align: left;    margin-left: 7px;font-weight: 500;">Outline thickness</th>
              <th  scope="row"><div class="col-md-5 d-flex" style="padding-left: 2px;margin-left: 15px;
                border: 1px solid black; cursor: pointer;" (click)="showoutlinethicknesspopup1=!showoutlinethicknesspopup1 ;showoutlinestylepopup=false"><div style="margin-bottom:5px">________ </div></div></th>
            </tr>
            <div *ngIf="showoutlinethicknesspopup1" style="position: absolute;left: 101%;top: 15px;background-color: white; border-radius: 12px;">
              <ul style="list-style: none">
                <li style="display: flex;margin-top: 19px;"><div style="width: 93px;background-color: black;border: .5px solid black;margin-top: 24px;margin-bottom: 18px;cursor: pointer;margin-right: 40px;" matTooltip="1px" (click)="setlinethickness1(1)"></div></li>
                <li style="display: flex;"> <div style="width: 93px;background-color: black;border: 2px solid black; margin-bottom: 18px;cursor: pointer;" matTooltip="2px"  (click)="setlinethickness1(2)"></div></li>
                <li style="display: flex;"><div style="width: 93px;background-color: black;border: 3px solid black; margin-bottom: 18px;cursor: pointer;" matTooltip="3px"  (click)="setlinethickness1(3)"></div></li>
                <li style="display: flex;"><div style="width: 93px;background-color: black;border: 5px solid black; margin-bottom: 18px;cursor: pointer;" matTooltip="5px"  (click)="setlinethickness1(5)"></div></li>
              </ul>
            </div>
            <tr >
              <th style="text-align: left;    margin-left: 7px;font-weight: 500;">Outline style</th>
              <th  scope="row"><div class="col-md-5 d-flex" style="padding-left: 2px;margin-left: 15px;
                border: 1px solid black; cursor: pointer;" (click)="showoutlinestylepopup1=!showoutlinestylepopup1;showoutlinethicknesspopup1=false"><div style="margin-bottom:5px">............. </div></div></th>
              <div  *ngIf="showoutlinestylepopup1"  style="position: absolute;left: 101%;top: 15px;background-color: white; border-radius: 12px; width: 154px;">
                <ul style="list-style: none">
                  <li style="display: flex; font-size: 31px; cursor: pointer;" matTooltip="Dashed"  (click)="setlinestyle1('dashed')">--------</li>
                  <li style="display: flex;  font-size: 31px;cursor: pointer;"  matTooltip="Dotted"  (click)="setlinestyle1('dotted')">........</li>
                  <li style="display: flex;  font-size: 31px; cursor: pointer;"  matTooltip="Line"  (click)="setlinestyle1('line')">_____</li>
                  
                </ul>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
      </ng-container>

      <ng-container *ngIf="activeTab === 'I'">
        <div class="interaction-info-container">
          <table class="table table-striped">
            <thead style="background-color: white;">
              <tr>
                <th>S.No.</th>
                <th>Property Title</th>
                <th>Property Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let interaction of intProperties; index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ interaction.title }}</td>
                <td>{{ interaction.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

    </div>
</div>
  <!--circular///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- </li> -->
<app-connection style="display:none"></app-connection>
<app-depiction style="display:none"></app-depiction>
<app-function style="display:none"></app-function>
<app-interaction style="display:none"></app-interaction>
<app-specification style="display:none"></app-specification>
<app-dbftable style="display:none"></app-dbftable>
<app-labledropdown style="display:none"></app-labledropdown>
<app-geotables style="display:none"></app-geotables>
<ng-template #geotowerItemConnectionContent>Connection</ng-template>
<ng-template #geotowerItemSpecificationContent>Specification</ng-template>
<ng-template #geotowerItemFunctionContent>Function</ng-template>
<ng-template #geotowerItemDepictionContent>Depiction</ng-template>
<ng-template #geotowerItemInteractionContent>Interaction</ng-template>

<ng-template #geotowerItemZoomToLayer>Zoom to Layer</ng-template>
<ng-template #geotowerItemSelection>Selection</ng-template>
<ng-template #geotowerItemRasterVectorToggle>Raster / vector Toggle</ng-template>
<ng-template #geotowerItemActiveInactiveToggle>Active / Inactive Toggle</ng-template>
<ng-template #geotowerItemPreview>Preview</ng-template>
