import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService, ElementType } from 'src/app/Services/common.service';
import { SocialAuthService } from 'src/app/Services/socialAuthService';
import { CustomSpinner } from 'src/app/Services/SpinnerService';

@Component({
  selector: 'app-landing-login',
  templateUrl: './landing-login.component.html',
  styleUrls: ['./landing-login.component.scss']
})
export class LandingLoginComponent implements OnInit {
  showAlert = false;
  alertMessage: string = ''; 
  alertType: string = 'warning';
  loadingLoginDiv = true;
  loadingSignupDiv = false;
  loginLoginDiv = true;
  loginForgotDiv = false;
  @Output() triggerToShowFeSpalsh: EventEmitter<any> = new EventEmitter<any>();
  errMsg = '';
  loginForm: FormGroup;
  sentActivationLink = '';
  emailVerified = '';

  constructor(private firebaseAuth: SocialAuthService, private commonService: CommonService,
    private spinner: CustomSpinner) { 
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
    this.loginForm.valueChanges.subscribe(res => {
      // console.log(this.loginForm.valid);
      if (!this.loginForm.valid) {
        this.errMsg = '';
      }
    });
    this.loginForm.get('email').valueChanges.subscribe(res => {
      // console.log(this.loginForm.valid);
      if (!this.commonService.isValid(res)) {
        this.loginForm.get('password').reset();
      }
    });
  }

  ngOnInit(): void {
  }
  getErrorMsg(ctrl: FormControl, name: string, type = ElementType.INPUT): string {
    return this.commonService.getFormErrorMsg(ctrl, name, type);
  }
  resetData(): void {
    this.errMsg = '';
    this.loginForm.reset();
    // this.indvSignUpForm.reset();
    // this.orgSignUpForm.reset();
    // this.sentActivationLink = '';
    this.emailVerified = '';
  }
  showBlog(): void {   
    this.triggerToShowFeSpalsh.emit(true);
  }
  signupToLoginFun(event): void {
    this.loadingLoginDiv = true;
    this.loadingSignupDiv = false;
  }
  setForgotPwdPage(): void {
    this.loginLoginDiv = false;
  }
  loginWithGoogle(op: string): void {
    console.log('IN loginWithGoogle');
    console.log(op);
    if (op === 'signIn') {
      this.firebaseAuth.doFirebaseGoogleLogin(op);
      // this.closeLogin.emit('close');
    } else if (op === 'signUp') {
      window.alert('Will come soon...');
    }
  }

  validateLogin(): void {
    console.log('In validateLogin');
    let errorFound = false;
    this.errMsg = '';
    console.log(this.loginForm.value);
    console.log(this.loginForm);
    try {
      if (!this.loginForm.valid) {
        console.log('NOT VALID');
        this.loginForm.get('email').markAsTouched();
        this.loginForm.get('password').markAsTouched();
        errorFound = true;
      }
    } catch (e) {
      errorFound = true;
      this.errMsg = e;
      console.log(e);
      this.alertMessage = this.errMsg;
      this.alertType = 'success';  // Set the type of alert
      this.showAlert = true;
    }

    if (!errorFound) {
      console.log('All GOOD');
      console.log(this.loginForm.value);
      const data = this.loginForm.value;
      const credentials = {
        email: data.email,
        password: data.password
      };
      this.spinner.show();
      this.firebaseAuth.signInWithEmailAndPassword(credentials.email, credentials.password)
        // this.linkFbWithEmail, this.linkCredentials, this.isOrgPage)
        .then(res => {
          console.log('SIGNIN WITH EMAIL PWD RES');
          console.log(res);
          // this.emailVerified = String(res.user.emailVerified);
          if (!res.user.emailVerified) {
            // this.spinner.hide();
          }
          else {
            this.resetData();
            // this.closeLogin.emit('close');
          }
        }, error => {
          console.error('SIGN IN WITH EMAIL PWD ERR');
          console.error(error);
          if (error.code === 'auth/wrong-password') {
            this.firebaseAuth.getProviderOfAnEmail(credentials.email)
              .then(providersRes => {
                console.log('PROVIDERS RES');
                console.log(providersRes);
                const index = providersRes.findIndex(val => val === 'password');
                if (index !== -1) {
                  /// E-MAIL ID REGISTERED WITH PROVIDER 'PASSWORD'
                  this.errMsg = 'Incorrect password...';
                } else {
                  /// E-MAIL ID NOT REGISTERED YET WITH PROVIDER 'PASSWORD'
                  this.errMsg = 'This email already registered with provider GOOGLE/ FACEBOOK';
                }
                this.spinner.hide();
                this.alertMessage = this.errMsg;
                this.alertType = 'success';  // Set the type of alert
                this.showAlert = true;
              }, err => {
                this.errMsg = 'Incorrect password...';
                this.spinner.hide();
                this.alertMessage = this.errMsg;
                this.alertType = 'success';  // Set the type of alert
                this.showAlert = true;
              }).catch(err => {
                this.errMsg = 'Incorrect password...';
                this.spinner.hide();
                this.alertMessage = this.errMsg;
                this.alertType = 'success';  // Set the type of alert
                this.showAlert = true;
              });
          } else if (error.code === 'auth/user-not-found') {
            this.errMsg = 'User not found, please Sign-Up';
            this.spinner.hide();
            this.alertMessage = this.errMsg;
            this.alertType = 'success';  // Set the type of alert
            this.showAlert = true;
          } else if (error.code === 'auth/internal-error') {
            this.errMsg = 'INVALID_LOGIN_CREDENTIALS';
            this.spinner.hide();
            this.alertMessage = this.errMsg;
            this.alertType = 'success';  // Set the type of alert
            this.showAlert = true;
          }
          this.emailVerified = '';
          this.sentActivationLink = '';
        })
        .catch(err => {
          this.spinner.hide();
          console.error('SIGN IN WITH EMAIL PWD ERR');
          console.error(err);
          if (err.code === 'auth/wrong-password') {
            this.errMsg = 'Incorrect password...';
          } else if (err.code === 'auth/user-not-found') {
            this.errMsg = 'User not found, please Sign-Up';
          }
          this.emailVerified = '';
          this.sentActivationLink = '';
          this.alertMessage = this.errMsg;
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          
        });
    }
  }

  closeAlert() {
    this.showAlert = false;
    setTimeout(() => {
      this.alertMessage = '';  // Clear the message after the transition ends
    }, 500); // Wait for the transition to finish before clearing the message
  }

}
