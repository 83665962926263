import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  OnChanges,
  ElementRef,
  Renderer2,
  ViewChild,
  OnDestroy,
  ComponentFactoryResolver,
} from '@angular/core';
import { BasemapService } from '../basemap/basemap.service';
import {
  NgbModalConfig,
  NgbModal,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AuthObservableService } from '../Services/authObservableService';
import { BasemapFactory } from '../basemap/BasemapFactory.js';
import { Subject } from 'rxjs';
import { get } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import OlView from 'ol/View';
import Stamen from 'ol/source/Stamen.js';
import OlTileLayer from 'ol/layer/Tile';
import OlXYZ from 'ol/source/XYZ';
import { CommonService } from '../Services/common.service';
import { GeotowerService } from '../geotower/geotower.service';
import { environment } from 'src/environments/environment';
// import * as jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { getPointResolution } from 'ol/proj';
import { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Observable } from 'rxjs';
import { PrintToolService } from '../Services/print-tool.service.js';
import Map from 'ol/Map';
import View from 'ol/View';
import OlMap from 'ol/Map';
import { defaults as defaultControls, ScaleLine, Zoom } from 'ol/control.js';
import {
  buffer,
  getCenter,
  getTopLeft,
  getTopRight,
  getBottomLeft,
  getHeight,
  getWidth,
} from 'ol/extent';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import { Draggable } from '../../assets/js/Draggable.js';
import { TweenLite } from '../../assets/js/TweenLite.js';
import ResizeObserver from 'resize-observer-polyfill';
declare var $: any;
import { Buffer } from 'buffer';
import { DomSanitizer } from '@angular/platform-browser';
import { GeoNotePadService } from '../Services/geo-notepad.service';
import { Draw, Modify, Snap } from 'ol/interaction';
import { proj } from 'ol';
import { createBox, createRegularPolygon } from 'ol/interaction/Draw.js';
import { setInterval } from 'timers';
import { style } from '@angular/animations';

@Component({
  selector: 'app-print-tool',
  templateUrl: './print-tool.component.html',
  styleUrls: ['./print-tool.component.scss'],
})
export class PrintToolComponent implements OnInit, OnDestroy {
  showProjectionUnits = true;
  showNavigationArrow = true;
  print_view: any;
  logo_view: any;
  compass_view: any;
  showPresentaiton = true;
  isBaseMapOptActive = false;
  selecteMapType: any = { name: 'Street (Bing)', value: 'bingstreet' };
  showtexteditmenue = false;
  showshapeeditmenue = false;
  showshapeeditmenue2 = false;
  shapemenutoggle = true;
  showdropdown = false;
  checkCredit = false;
  uploadedImage: any;
  checkUpload = false;
  creditmove: any;
  uploadmove: any;
  showLegend = false;
  showSiteMarkerMenu = false;
  showShapeMarkerMenu = false;
  countShape = 0;
  currentContextInfo: any = {};
  vector: VectorLayer;
  source: VectorSource;
  draw: any;
  snap: any;
  locationData: any[] = [];
  tempCreateSiteId: string = String(new Date().getTime());
  currentFeature: any;
  goingToMarkPosition = false;
  getCoOrdsForNewNote = false;
  showNotesAndFilePicker = false;
  activatedLogoChange = false;
  logoChange: any = {};
  changeDetection = [];
  navigatePosition = new Subject<string>();
  logoPosition = new Subject<string>();
  printMapPosition = new Subject<string>();
  legendPosition = new Subject<string>();
  trianglePosition = new Subject<string>();
  squarePosition = new Subject<string>();
  textAreaPosition = new Subject<string>();
  circlePosition = new Subject<string>();
  uploadImagePosition = new Subject<string>();
  divSize: any;
  unitConversion: any;
  boldChanging = false;
  italicChanging = false;
  underlineChanging = false;
  addPresentationFormGroup: FormGroup;

  activesites = [
    { color: 'blue', text: 'boundary' },
    { color: 'red', text: 'bount' },
    { color: 'yellow', text: 'borrow' },
    { color: 'green', text: 'bean' },
  ];

  presentationPageSizes: any[] = [
    { name: 'Letter', value: [215.9, 279.4] },
    // { name: 'Letter Wide', value: [279,216] },
    // { name: 'Legal', value: [216, 356] },
    // { name: 'Legal Wide', value: [356,216] },
    { name: 'A3', value: [297, 420] },
    { name: 'A4', value: [215.9, 279.4] },
    // { name: 'A5', value: [148, 210] },
    // { name: 'B4', value: [257, 364] },
    // { name: 'B5', value: [182, 257] },
    // { name: 'Euro Fanfold', value: [250, 340] },
    // { name: 'Executive 1', value: [178, 267] },
    // { name: 'Executive 2', value: [184, 267] },
    // { name: 'Executive 3', value: [190, 267] },

    // { name: 'A4 Wide', value: [297, 210] },
    { name: 'ARCH A', value: [228.6, 304.8] },
    { name: 'ARCH B', value: [304.8, 457.2] },
    { name: 'ARCH C', value: [457.2, 609.6] },
    { name: 'ARCH D', value: [609.6, 914.4] },
    { name: 'ARCH E', value: [914.4, 1219.2] },
    { name: 'ARCH E1', value: [762, 1066.8] },
    { name: 'ARCH E2', value: [660.4, 965.2] },
    { name: 'ARCH E3', value: [685.8, 990.6] },
  ];
  presentationDpiList: any[] = [
    { name: '100', value: '100' },
    { name: '200', value: '200' },
    { name: '300', value: '300' },
    { name: '400', value: '400' },
  ];

  mapTypesList: any[] = [
    { name: 'Satellite (Mapbox)', value: 'satellite', view: '../../assets/svgs/geosol/geosole-framework/map-icons/satellite-mapbox.png' },
    { name: 'Satellite (Bing)', value: 'bingsatellite', view: '../../assets/svgs/geosol/geosole-framework/map-icons/satellite-bing.png' },
    { name: 'Street (Bing)', value: 'bingstreet', view: '../../assets/svgs/geosol/geosole-framework/map-icons/street-bing.png' },
    { name: 'Street (Google)', value: 'googlestreet', view: '../../assets/svgs/geosol/geosole-framework/map-icons/street-google.png' },
    { name: 'Satellite (Google)', value: 'googlesatellite', view: '../../assets/svgs/geosol/geosole-framework/map-icons/satellite-google.png' },
    { name: 'Toner (Stamen)', value: 'toner', view: '../../assets/svgs/geosol/geosole-framework/map-icons/toner-stamen.png' },
    { name: 'Terrain (Stamen)', value: 'terrain', view: '../../assets/svgs/geosol/geosole-framework/map-icons/terrain-stamen.png' },
    { name: 'Open Street', value: 'openstreet', view: '../../assets/svgs/geosol/geosole-framework/map-icons/open-street.png' },
  ];

  unitsList: any = [
    { value: 'us', view: 'Miles', scaleValue: 'us' },
    { value: 'metric', view: 'Kms', scaleValue: 'metric' },
  ];

  @ViewChild('dpiInput') dpiInput: ElementRef<HTMLInputElement>;
  dpiSelect: FormControl = new FormControl('');
  selectedOutputFormat: any;
  selectedPageSize: any;
  selectedOrientation: any;
  selectedDPI: any;
  rotationValue: any = 0;
  angleRotate: any = 0;
  rotateReturn: any;
  storeNavigateArrayPosition = [];
  storeLogoArrayPosition = [];
  storePrintMapPosition = [];
  storeLegendPosition = [];
  storeTrianglePosition = [];
  storeSquarePosition = [];
  storeTextAreaPosition = [];
  storeCirclePosition = [];
  storeUploadImagePosition = [];
  storeSquareArrayPosition = []
  @ViewChild('scaleContainerPopup')
  scaleContainerPopup: ElementRef<HTMLDivElement>;
  @ViewChild('ptool', { static: true }) ptool: ElementRef<HTMLDivElement>;
  @ViewChild('imageScaleDivID') imageScaleDivID: ElementRef<HTMLDivElement>;
  @ViewChild('imgContainerPopup') imgContainerPopup: ElementRef<HTMLDivElement>;
  @ViewChild('legendContainerPopup')
  legendContainerPopup: ElementRef<HTMLDivElement>;
  @ViewChild('presentationIMGContainer', { static: true })
  presentationIMGContainer: ElementRef<HTMLDivElement>;
  @ViewChild('ptbc') ptbc!: ElementRef;
  @ViewChild('childDiv') public childDiv!: ElementRef;
  @ViewChild('mapDiv', { static: true }) mapDiv: ElementRef<HTMLDivElement>;
  @ViewChild('rotateN', { static: true }) rotateN: ElementRef<HTMLDivElement>;
  @ViewChild('mapLogo', { static: true }) mapLogo: ElementRef<HTMLDivElement>;
  @ViewChild('navigateN', { static: true })
  navigateN: ElementRef<HTMLDivElement>;
  @ViewChild('imageupload', { static: true })
  imageupload: ElementRef<HTMLDivElement>;
  @ViewChild('rotationField', { static: true })
  rotationField: ElementRef<HTMLDivElement>;
  @ViewChild('legendDiv', { static: false }) legendDiv: ElementRef;
  @ViewChild('imageDiv', { static: false }) imageDiv: ElementRef;

  private printMap: Map;
  activeSitesForPresentation: any[] = [];
  mapProjectionUnitsPopup = '';
  scaleLineWidth = 0;
  popupContent: any;
  basemap: any;
  mapX = 0;
  mapY = 0;
  mapWidth = 0;
  mapHeight = 0;
  logoX = 0;
  logoY = 0;
  symbolX = 0;
  symbolY = 0;
  isResizing = false;
  countMiles = 0;
  showdropdownMiles = false;
  mapURL: any;
  creditData: any;
  legend_move: any;
  upload_move: any;
  textarea_move: any;
  positionMarkObserver: Subject<any> = new Subject<any>();
  circle_move: any;
  square_move: any;
  triangle_move: any;
  globalObject: any;
  checkEvent: any;
  countValue = 0;
  currentViewFactory = '';
  inMetricSystem: any;
  inMilesSystem: any;
  reverseScaleCalculation: any;
  addtextfieldbuttonactive = false;
  shapemenueactive = false;
  sitemenueactive = false;
  fontsize: Number;
  fontcolor = '#000000';
  backcolor = 'transparent';
  counttext = 0;
  textareacontent: Element;
  showdropdownPages = false;
  countPages = 0;
  hoverEvent: Element;
  showdropdownDpi = false;
  countDpi = 0;
  countOrientation = 0;
  showdropdownOrientation = false;
  showCanvasSquare = false;
  countSquares = 0;
  countCircles = 0;
  shapeFillColor: '#ffffff';
  strokeFillColor: any;
  countTriangles = 0;
  squareId: any;
  circleId: any;
  squareContent: HTMLElement;
  circleContent: HTMLElement;
  addSquare = false;
  addCircle = false;
  changestcolor = false;
  path: SVGRectElement;
  countlegendbutton = 0;
  legendvalue = 'Hide';
  strokecolor4 = '#000000';
  legendMove: any;
  northArrow: HTMLElement;
  logoView: any;
  countlinedashed = 0;
  strokesizewithpx = '4px';
  strokesize: number;
  fontsizewithpx = '10px';
  legenddiv: HTMLElement;
  legendtools = false;
  strokecoloroflegend: any;
  squareAdded: any;
  circleAdded: any;
  triangleAdded: any;
  textboxAdded: any;
  square_print = false;
  trainglestrokeformat = '12,0';
  triangleId: any;
  triangleContent: HTMLElement;
  boldCountlegend = 0;
  legendheading: boolean;
  selectlegendnames: boolean;
  legendnameselected: HTMLElement;
  legenddivheading: HTMLElement;
  squareToPrint = false;
  circleToPrint = false;
  triangleToPrint = false
  textboxToPrint = false
  showtextsizediv: boolean;
  addtextfieldtool: boolean;
  selectedpagesize = 'A4';
  squareIntialPosition: any;
  circleIntialPosition: any;
  triangleIntialPosition: any;
  circledivclicked: boolean;
  squaredivclicked: boolean;
  triangledivclicked: boolean;
  zindexvalue = 11;
  squareshapeFillColor: any;
  circleshapeFillColor: any;
  triangleshapeFillColor: any;
  selectedtextfield: boolean;
  squarestrokeformat: any;
  circlestrokeformat: any;
  squarewidthvalue = '2px';
  circlewidthvalue = '2px';
  trainglewidthvalue = '2px';
  squarestrokecolor = '#000000';
  circlestrokecolor = '#000000';
  trianglestrokecolor = '#000000';
  textboxIntialPosition: any;
  geosolAngle: any;
  orientation = 'Portrait';
  circlearrayforprinting = [];
  presentcircle: any;
  showcirclecode: HTMLElement;
  showcircularcode: HTMLElement;
  initialObj1: { nav_x: any; nav_y: any; };
  circlearraytemp: { id: string; position: { nav_x: any; nav_y: any; }; zindex: number };
  circlearray = [];
  circleprintcode: HTMLElement;
  presentSquare: any;
  initialObj2: { nav_x: any; nav_y: any; };
  showSquareCode: HTMLElement;
  squareArrayTemp: { id: string; position: { nav_x: any; nav_y: any; }; zindex: number };
  squareArray = [];
  squarePrintCode: HTMLElement;
  presentTriangle: any;
  initialObj3: { nav_x: any; nav_y: any; };
  showTriangleCode: HTMLElement;
  triangleArrayTemp: { id: string; position: { nav_x: any; nav_y: any; }; zindex: number };
  triangleArray = [];
  trianglePrintCode: HTMLElement;
  presentTextField: any;
  initialObj4: { nav_x: any; nav_y: any; };
  showTextCode: HTMLElement;
  textArrayTemp: { id: string; position: { nav_x: any; nav_y: any; }; zindex: number };
  textArray = [];
  textPrintCode: HTMLElement;
  latestCircleArray = [];
  islandscape = false;
  textBoxOnce: boolean;
  basemapactive = false;
  northarrowactive = true;
  scalebaractive = true;
  legendcoloractive = false;
  pagedimensions = "[ 8.50 in X 11.00 in ]"
    ;
  istextboxselected: boolean;
  legendfontcolor: any;
  legendheadingfontcolor: any;
  textboxfontcolor: any;
  strokeoftxtbox: any;
  textbackgcolor: any;
  finalscaling: any;
  value2: number;
  viewfactorchange: boolean;
  viescalefactor: any;
  changeviewfactor: boolean;
  updatedratio: any;
  countiteration = 0;
  OverallArray = [];
  printcode: HTMLElement;
  textid: any;
  path2: SVGSVGElement;
  initialObj5: { nav_x: any; nav_y: any; };
  legendArrayTemp: { id: string; position: { nav_x: any; nav_y: any; }; zindex: number; };
  navigateClass: any;
  navigateId: HTMLElement;
  initialObj7: { nav_x: number; nav_y: number; };
  navigateArrayTemp: { id: string; position: { nav_x: number; nav_y: number; }; zindex: number; };
  printMapId: HTMLElement;
  initialObj8: { nav_x: number; nav_y: number; };
  mapArrayTemp: { id: string; position: { nav_x: number; nav_y: number; }; zindex: number; };
  squarehtmlcode: HTMLElement;
  circlehtmlcode: HTMLElement;
  path3: SVGEllipseElement;
  path4: SVGPolygonElement;
  trianglehtmlcode: HTMLElement;
  checkresolution: any;
  preview: HTMLCollectionOf<Element>;
  PreviewToPhysicalRatio: number;
  scalebarwidth: string;
  scalebarwidthmetric: string;
  testingDPI = 91;
  value: number;
  viewPort: any;
  scalevalue: number;
  viewfactorforui: any;
  squareaddcount = 0;
  circleaddcount = 0;
  triangleaddcount = 0;
  textboxaddcount = 0;
  static printCount = 0;
  orientationvalue = "Portrait";
  finallogoposition: any[];
  canvas;
  ctx;

  canvasOffset;
  offsetX;
  offsetY;

  startX;
  startY;
  isDown = false;

  pi2 = Math.PI * 2;
  resizerRadius = 8;
  rr = this.resizerRadius * this.resizerRadius;
  draggingResizer;
  imageX = 50;
  imageY = 50;
  imageWidth;
  imageHeight;
  imageRight;
  imageBottom;

  draggingImage = false;

  img;

  ratio;
  imagecode: Element;
  imagedetailsforprint: { id: string; position: { nav_x: number; nav_y: number; }; printcode: Element; zindex: number; };
  htmlcode: any;


  constructor(
    private basemapService: BasemapService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private geotowerService: GeotowerService,
    private renderer: Renderer2,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private observ: AuthObservableService,
    private printToolService: PrintToolService,
    private authObsr: AuthObservableService,
    private sanitizer: DomSanitizer,
    private notePadService: GeoNotePadService
  ) {
    this.selectedPageSize = this.presentationPageSizes[0].value;
    this.dpiInput = this.presentationDpiList[0].value;
    this.selectedOrientation = 'Portrait';
    this.selectedOutputFormat = 'PDF';
    this.selectedDPI = this.presentationDpiList[1].value;
    this.popupContent = this;
    this.basemap = this.basemapService.getCurrentBasemap();

    this.positionMarkObserver.subscribe((res) => {
      console.log('POSITION MARKED');
      console.log(res);
      let coords;
      const coordsList = [];
      if (
        res.from === this.notePadService.shapeDrawType.POLYGON /*'polygon'*/
      ) {
        // coords = res['co-ordinates'];
        res['co-ordinates'].forEach((latLngList) => {
          console.log(' ???? ', latLngList);
          latLngList.forEach((element) => {
            console.log(element);
            // CO-ORDINATES `[78.534344232, 17.534435435]` <=> `[LONGITUDE, LATITUDE]`
            coordsList.push(element[0]);
            coordsList.push(element[1]);
          });
        });
        console.log(coordsList);
        coords = coordsList;
      } else if (res.from === this.notePadService.shapeDrawType.LINE_STRING) {
        res['co-ordinates'].forEach((latLngList) => {
          console.log(latLngList);
          // CO-ORDINATES `[78.534344232, 17.534435435]` <=> `[LONGITUDE, LATITUDE]`
          coordsList.push(latLngList[0]);
          coordsList.push(latLngList[1]);
        });
        console.log(coordsList);
        coords = coordsList;
      } else if (
        res.from === this.notePadService.shapeDrawType.POINT /*'position'*/
      ) {
        // coords = [res['co-ordinates']];
        res['co-ordinates'].forEach((latLngList) => {
          console.log(latLngList);
          // CO-ORDINATES `[78.534344232, 17.534435435]` <=> `[LONGITUDE, LATITUDE]`
          coordsList.push(latLngList);
        });
        console.log(coordsList);
        coords = coordsList;
      }

      this.locationData = coords;
      this.tempCreateSiteId = String(new Date().getTime());
      // [latitude] = "locationData[0]"
      // [longitude] = "locationData[1]"
      this.currentFeature = res;
      this.goingToMarkPosition = false;
      console.log(this);
      // this.locationCollected=true;
      // this.saveLatLong(coords);
      if (this.getCoOrdsForNewNote) {
        // this.maximizeNotePage();
        this.showNotesAndFilePicker = true;
      }
    });
  }
  ngOnDestroy(): void {
    console.log('destroyed.....');
  }

  ngOnInit(): void {
    console.log( this.geotowerService.geotowerLayersList,"geotowerlist")
    let _this = this;
    
    // this.ctx = this.canvas.getContext("2d");

    // // const ele = <HTMLElement>document.getElementById("parent");

    // this.canvasOffset = this.canvas;
    // this.offsetX = this.canvas.offsetLeft;
    // this.offsetY = this.canvas.offsetTop;
    this.finallogoposition = [{
      position: {
        nav_x: 0,
        nav_y: 0
      }
    }]
    this.logoView = document.querySelector('.logo-move');
    // this.logoView.style.resize="both"
    var showloading = document.getElementById("loading")
    showloading.style.display = "none"
    console.log(
      this.notePadService.getGlobalObject(),
      'global object in map canvas'
    );
    this.globalObject = this.notePadService.getGlobalObject();
    this.createPresentationFormGroup();
    console.log('im on init');
    // this.draggableMapView()
    this.embeddingPrintMap();
    console.log('im on init 2');
    this.printMap.on('moveend', (e) => {
      // console.log(e);

      this.getScaleLineWidth();
      this.getMapProjectionUnits();
      console.log('im on init 2');
      // this.basemapService.setLoadScaleLine();
    });
    console.log('im on init 2');
    // this._fieldDraggableObj();
    console.log('im on init 2');

    //   this.addPresentationFormGroup.controls.addMapscale.valueChanges.subscribe(
    //     (val) => {
    //       console.log(
    //         'Unit value changed',
    //         this.addPresentationFormGroup.controls.addMapscale.value
    //       );
    //       console.log(val, 'check me');
    //       this.submit();
    //     }
    //   );

    this.addPresentationFormGroup.controls.addCredit.valueChanges.subscribe(
      (val) => {
        this.checkCredit = true;
        this.creditData = val;
        setTimeout(() => {
          this.creditmove = document.querySelector('.credit-move');
          console.log(this.creditmove, 'chekc credit move');
          this.creditmove.addEventListener('mousedown', () => {
            this.creditmove.addEventListener('mousemove', () => {
              $('.credit-move').draggable({ containment: 'parent' });
            });

            this.creditmove.addEventListener('mousedown', () => {
              this.creditmove.removeEventListener('mousemove', () => {
                $('.credit-move').draggable({ containment: 'parent' });
              });
            });
          });

          this.creditmove.addEventListener('mouseenter', () => {
            var a_tag = document.createElement('a');
            a_tag.setAttribute('class', 'ol-popup-closer');
            a_tag.setAttribute('id', 'checkImage');
            a_tag.style.position = 'absolute';
            a_tag.style.marginTop = '-0.5rem';
            a_tag.style.marginRight = '-1rem';
            // a_tag.style.right = "0%"
            a_tag.addEventListener('click', () => {
              console.log('i got clieckd');
              this.creditData = '';
              this.addPresentationFormGroup.controls['addCredit'].setValue('');
            });
            this.creditmove.appendChild(a_tag);
          });

          this.creditmove.addEventListener('mouseleave', () => {
            this.creditmove.removeChild(document.getElementById('checkImage'));
          });
        }, 1000);
      }
    );
    console.log('came till here 1')
    setTimeout(() => {
      this.printMapId = document.getElementById('map-mini')
      console.log(this.printMapId, 'printmapinitialized')
      // const positionMap = this.printMapId.get
      this.initialObj8 = {
        nav_x: 0,
        nav_y: 0
      };
      this.mapArrayTemp = {
        id: this.printMapId.id,
        position: this.initialObj8,
        zindex: parseInt(this.printMapId.style.zIndex)
      }
      this.OverallArray.push(this.mapArrayTemp)
      console.log(this.OverallArray, 'overallafteraddingprint')
      console.log('came till here 2')
      // if (this.showNavigationArrow == true) {
      //   this.navigateClass = document.querySelector('.compass-move')
      //   console.log(this.navigateClass, 'navigateclass')
      //   this.navigateClass.setAttribute('id', 'navigateDiv')
      //   this.navigateId = document.getElementById('navigateDiv')
      //   const position = this.navigateId.getBoundingClientRect();
      //   var team = this;

      //   this.initialObj7 = {
      //     nav_x: 0,
      //     nav_y: 0
      //   };
      //   console.log(this.initialObj7, "checkcircleforprinting")
      //   this.navigateArrayTemp = {
      //     id: this.navigateId.id,
      //     position: this.initialObj7,
      //     zindex: parseInt(this.navigateId.style.zIndex)
      //   }
      //   this.OverallArray.push(this.navigateArrayTemp)


      // }


    }, 1000);


    // this.logoMoveFunc();
    this.loadAllBaseMaps({});
  }

  ngAfterViewInit() {
    this.getScaleLineWidth();
    // console.log(this.legendDiv.nativeElement,"check me here for legend")
    this.showSitesInfoFun();
    this._updateDraggableObj();

    this.geosolAngle = this.basemap.getView().getRotation()
    console.log(this.basemap.getView().getRotation(), "checkgeosolangle")
    if (this.geosolAngle) {
      console.log(this.geosolAngle, "angle inside")
      this.printMap.getView().setRotation(this.geosolAngle);
      TweenLite.set('#rotationN', { rotation: this.geosolAngle });

    } else {
      TweenLite.set('#rotationN', { rotation: 0 });

    }
    //calculating new width and height for the div with aspect ratio fo page sizes
    console.log('im after init');
    for (let x of this.presentationPageSizes) {
      if (this.addPresentationFormGroup.get('addPageSize').value === x.name) {
        const pageWidth = x.value[0] / 25.4;
        const pageHeight = x.value[1] / 25.4;

        const divWidth = this.childDiv.nativeElement.offsetWidth * 0.0104166667;
        const divHeight =
          this.childDiv.nativeElement.offsetHeight * 0.0104166667;

        const newWidth = (divHeight * pageWidth) / pageHeight;

        console.log(this.addPresentationFormGroup.get('addOrientation').value, 'inital orientation')
        if (
          this.addPresentationFormGroup.get('addOrientation').value ===
          'Portrait'
        ) {
          this.islandscape = false;
          console.log('in the potrait', newWidth, divHeight);
          $('.print-style').css({
            width: `${Math.round(newWidth * 96)}px`,
            height: `${Math.round(divHeight * 96)}px`,
            margin: '33px auto auto',
          });
          $('.print-map').css({
            resize: 'both',
            'max-width': `${Math.round(newWidth * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(divHeight * 96)}px`,
          });
          // $('.print-style').css({
          //   width: `${Math.round(newWidth * 96)}px`,
          //   height: `${Math.round(divHeight * 96)}px`,
          //   margin: '33px auto auto',
          // });
          // $('.print-map').css({
          //   resize: 'both',
          //   'max-width': `${Math.round(newWidth * 96)}px`,
          //   overflow: 'hidden',
          //   'max-height': `${Math.round(divHeight * 96)}px`,
          // });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([newWidth * 96, divHeight * 96]);
          this.printMap.getView().fit(popupExtent);

          this.printMap.setSize([newWidth * 96, divHeight * 96]);
          //this.printMap.setSize([816, 1056]);
          this.PreviewToPhysicalRatio = Math.round(newWidth * 96) / (pageWidth * 96);
          // this.PreviewToPhysicalRatio=1
          // this.scalebarwidth=Math.round(newWidth*96)/pageWidth + 'px'
          this.scalebarwidth = Math.round(newWidth * this.testingDPI) / pageWidth + 'px'
          this.scalebarwidthmetric = (Math.round(newWidth * this.testingDPI) / (pageWidth * 2.54)) * 2 + 'px'
          console.log(pageWidth * 96, Math.round(newWidth * 96), this.PreviewToPhysicalRatio, "checkwidth")

        } else {
          this.islandscape = false;
          console.log('in the lanscape', divHeight, newWidth);
          $('.print-style').css({
            width: `${Math.round(divHeight * 96)}px`,
            height: `${Math.round(newWidth * 96)}px`,
            margin: '8rem auto',
          });
          $('.print-map').css({
            resize: 'both',
            'max-width': `${Math.round(divHeight * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(newWidth * 96)}px`,
          });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([divHeight * 96, newWidth * 96]);
          this.printMap.getView().fit(popupExtent);
          this.printMap.setSize([divHeight * 96, newWidth * 96]);
          this.PreviewToPhysicalRatio = Math.round(newWidth * 96) / (pageWidth * 96);
          // this.PreviewToPhysicalRatio=1
          this.scalebarwidth = Math.round(newWidth * this.testingDPI) / pageWidth + 'px'
          this.scalebarwidthmetric = (Math.round(newWidth * this.testingDPI) / (pageWidth * 2.54)) * 2 + 'px'
          console.log(pageWidth * 96, Math.round(newWidth * 96), this.PreviewToPhysicalRatio, "checkwidth")
        }
      }
    }
    // this.loadAllBaseMaps({});
    // setTimeout(() => {
    //   const canvaselement=  document.getElementsByTagName('canvas')[1]



    // console.log(canvaselement,"checkcanvas")
    // canvaselement.style.width="495px"
    //  }, 2000);

    this.submit()
  }

  createPresentationFormGroup() {
    this.addPresentationFormGroup = this.formBuilder.group({
      addOrientation: new FormControl('Portrait'),
      addPageSize: new FormControl('A4'),
      addOutputFormat: new FormControl('PDF'),
      addDpi: new FormControl('200'),
      addMaptype: new FormControl('Street (Bing)'),
      addMapscale: new FormControl('us'),
      addLegend: new FormControl('Hide'),
      addImageUpload: new FormControl(''),
      addMapOrientation: new FormControl('0'),
      addCredit: new FormControl(''),
    });
  }

  onPageOptionSelected(pagesize) {
    this.selectedpagesize = pagesize
    var pageWidth: any;
    var pageHeight: any;
    var divWidth: any;
    var divHeight: any;
    var newWidth: any;

    this.presentationPageSizes.map((x) => {
      const dpi = this.addPresentationFormGroup.get('addDpi').value;
      console.log(dpi, 'dpi main')

      if (x.name === pagesize) {
        console.log(dpi, 'check dpi');
        pageWidth = x.value[0] / 25.4;
        pageHeight = x.value[1] / 25.4;

        console.log(pageWidth, pageHeight, 'paper width and height in inch');
        console.log(x.name, 'name of map before print')

        console.log(
          this.childDiv.nativeElement.offsetWidth,
          this.childDiv.nativeElement.offsetHeight,
          'Divwidth and Divheight in px'
        );

        divWidth = this.childDiv.nativeElement.offsetWidth * 0.0104166667;
        divHeight = this.childDiv.nativeElement.offsetHeight * 0.0104166667;

        // divWidth = 564 * 0.0104166667
        // divHeight = 640 * 0.0104166667

        console.log(divWidth, divHeight, 'Divwidth and Divheight in inch');

        newWidth = (divHeight * pageWidth) / pageHeight;

        const resolution_width = dpi * newWidth;

        const resolution_height = dpi * divHeight;

        console.log(
          resolution_width,
          resolution_height,
          'after added dpi width and height in px'
        );

        const _width = resolution_width / dpi;

        const _height = resolution_height / dpi;

        console.log(_width, _height, 'check width and height in inh');
        var logo = document.getElementsByClassName("logo-move")[0]
        
        if (
          this.orientation ===
          'Portrait'
        ) {
          logo["style"].inset =`${Math.round(_height * 96)-36}px auto auto ${Math.round(_width * 96)-150}px`
          console.log('in protrait');
          console.log(_width, _height, 'updated width and height in inch');
          $('.print-style').css({
            width: `${Math.round(_width * 96)}px`,
            height: `${Math.round(_height * 96)}px`,
            margin: '33px auto auto',
          });
          $('.print-map').css({
            resize: 'both',
            'max-width': `${Math.round(_width * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(_height * 96)}px`,
          });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([_width * 96, _height * 96]);
          this.printMap.getView().fit(popupExtent);
          this.printMap.setSize([_width * 96, _height * 96]);
          this.printMap.updateSize();
          this.PreviewToPhysicalRatio = Math.round(newWidth * this.testingDPI) / (pageWidth * this.testingDPI);
          // this.PreviewToPhysicalRatio=1
          this.scalebarwidth = Math.round(newWidth * this.testingDPI) / pageWidth + 'px'
          this.scalebarwidthmetric = (Math.round(newWidth * this.testingDPI) / (pageWidth * 2.54)) * 2 + 'px'

        } else {
          
          const divLWidth = 564 * 0.0104166667;
          const divLHeight = 640 * 0.0104166667;

          const newLWidth = (divLHeight * pageWidth) / pageHeight;

          const resolution_width = dpi * newLWidth;

          const resolution_height = dpi * divLHeight;

          console.log(
            resolution_width,
            resolution_height,
            'after added dpi width and height in px'
          );

          const _width = resolution_width / dpi;

          const _height = resolution_height / dpi;

          console.log(_width, _height, 'check width and height in inh');

          console.log(_height, _width, 'updated width and height in inch');
          logo["style"].inset =`${Math.round(_width * 96)-36}px auto auto ${Math.round(_height * 96)-150}px`
          $('.print-style').css({
            width: `${Math.round(_height * 96)}px`,
            height: `${Math.round(_width * 96)}px`,
            margin: '8rem auto',
          });
          $('.print-map').css({
            resize: 'both',
            width: `${Math.round(_height * 96)}px`,
            height: `${Math.round(_width * 96)}px`,
            'max-width': `${Math.round(_height * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(_width * 96)}px`,
          });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([_height * 96, _width * 96]);
          this.printMap.getView().fit(popupExtent);
          this.printMap.setSize([_height * 96, _width * 96]);
          this.printMap.updateSize();
          this.PreviewToPhysicalRatio = Math.round(newWidth * this.testingDPI) / (pageWidth * 96);
          // this.PreviewToPhysicalRatio=1
          this.scalebarwidth = Math.round(newWidth * this.testingDPI) / pageWidth + 'px'
          this.scalebarwidthmetric = (Math.round(newWidth * this.testingDPI) / (pageWidth * 2.54)) * 2 + 'px'
        }
      }
    });
    this.pagedimensions = "[" + " " + pageWidth.toFixed(2) + " " + "in" + " " + "X" + " " + pageHeight.toFixed(2) + " " + "in" + " " + ']'
  }

  onOrientOptionSelected(selectedOrientation: string) {
    console.log(selectedOrientation, this.orientationvalue, "check orient")

    this.orientation = selectedOrientation;
    console.log(this.orientation, this.addPresentationFormGroup.get('addPageSize').value, ' check orient');
    console.log(selectedOrientation, this.presentationPageSizes[2].name == this.addPresentationFormGroup.get('addPageSize').value, 'selected one')
    const dpi = this.addPresentationFormGroup.get('addDpi').value;
    this.presentationPageSizes.map((x) => {
      if (this.addPresentationFormGroup.get('addPageSize').value === x.name) {
        console.log(this.printMap.getView(), "mapsizes")
        const pageWidth = x.value[0] / 25.4;
        const pageHeight = x.value[1] / 25.4;

        const divWidth = 564 * 0.0104166667;
        const divHeight = 640 * 0.0104166667;

        const newWidth = (divHeight * pageWidth) / pageHeight;

        const resolution_width = dpi * newWidth;

        const resolution_height = dpi * divHeight;

        console.log(
          resolution_width,
          resolution_height,
          'after added dpi width and height in px'
        );

        const _width = resolution_width / dpi;

        const _height = resolution_height / dpi;

        console.log(_width, _height, 'check width and height in inh');
        if (this.orientationvalue == this.orientation && this.orientation == "Portrait") {
          this.orientationvalue = "Portrait"
        }
        if (this.orientationvalue == this.orientation && this.orientation == "Landscape") {
          this.orientationvalue = "Landscape"
        }
        else if (this.orientationvalue != this.orientation && this.orientation == "Landscape") {
          this.orientationvalue = "Landscape";
          console.log(this.finallogoposition['nav_y'] * (495 / 640), "this.orientationvalue")
          var logo = document.getElementsByClassName("logo-move")[0]
          // if(this.finallogoposition && this.finallogoposition!=this.finallogoposition){console.log("i am in logo");logo["style"].inset=`${this.finallogoposition['nav_y']*(495/640)}px auto auto ${this.finallogoposition['nav_x']*(495/640)}px`}

          // htmlcode.style.height=width
          for (let i = 0; i < this.OverallArray.length; i++) {
            // const temp = this.OverallArray[i].position.nav_x;
            // this.OverallArray[i].position.nav_x = this.OverallArray[i].position.nav_y;
            // this.OverallArray[i].position.nav_y = temp;
            
            if (this.OverallArray[i].id == "upload-move") {
             
              
                this.htmlcode = document.querySelector('.upload-move');
                this.htmlcode['style'].setProperty('inset', `${this.OverallArray[i].position.nav_x}px auto auto ${this.OverallArray[i].position.nav_y}px`);
           

            }
            else {
              this.htmlcode = document.getElementById(this.OverallArray[i].id)
              ; this.htmlcode['style'].setProperty('inset', `${this.OverallArray[i].position.nav_x}px auto auto ${this.OverallArray[i].position.nav_y}px`);
            }

            console.log(this.htmlcode, "this.htmlcode")
            // var width=this.htmlcode.style.width
            // var height=this.htmlcode.style.height
            // finallogoposition
            console.log(logo["style"], "logo")
            // this.htmlcode['style'].setProperty('inset', `${this.OverallArray[i].position.nav_x}px auto auto ${this.OverallArray[i].position.nav_y}px`);


            // this.htmlcode.style.width=height
          }
        }
        else if (this.orientationvalue != this.orientation && this.orientation == "Portrait") {
          this.orientationvalue = "Portrait";
          console.log(this.finallogoposition, "this.finallogoposition")
          var logo = document.getElementsByClassName("logo-move")[0]
          //if(this.finallogoposition && this.finallogoposition!=this.finallogoposition ){logo["style"].inset=`${this.finallogoposition['nav_y']*(495/640)}px auto auto ${this.finallogoposition['nav_y']*(495/640)}px`}

          console.log(this.orientationvalue, "this.orientationvalue")
          for (let i = 0; i < this.OverallArray.length; i++) {
            // const temp = this.OverallArray[i].position.nav_x;
            // this.OverallArray[i].position.nav_x = this.OverallArray[i].position.nav_y;
            // this.OverallArray[i].position.nav_y = temp;
            if (this.OverallArray[i].id == "upload-move") {
             
                this.htmlcode = document.querySelector('.upload-move');
                this.htmlcode['style'].setProperty('inset', `${this.OverallArray[i].position.nav_y}px auto auto ${this.OverallArray[i].position.nav_x}px`);
             

            }
            else{  this.htmlcode = document.getElementById(this.OverallArray[i].id)

              this.htmlcode['style'].setProperty('inset', `${this.OverallArray[i].position.nav_y}px auto auto ${this.OverallArray[i].position.nav_x}px`);}
          

          }

        }
        var logo = document.getElementsByClassName("logo-move")[0]
        
        if (this.orientation === 'Portrait') {
          logo["style"].inset =`${Math.round(_height * 96)-36}px auto auto ${Math.round(_width * 96)-150}px`
          console.log('in the potrait', _width, _height);
          $('.print-style').css({
            width: `${Math.round(_width * 96)}px`,
            height: `${Math.round(_height * 96)}px`,
            margin: '33px auto auto',
          });
          $('.print-map').css({
            resize: 'both',
            width: `${Math.round(_width * 96)}px`,
            height: `${Math.round(_height * 96)}px`,
            'max-width': `${Math.round(_width * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(_height * 96)}px`,
          });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([_width * 96, _height * 96]);
          this.printMap.getView().fit(popupExtent);
          this.printMap.setSize([_width * 96, _height * 96]);
          this.printMap.updateSize();
          console.log(this.printMap.updateSize(), 'portrait size')
        } else {
          logo["style"].inset =`${Math.round(_width * 96)-36}px auto auto ${Math.round(_height * 96)-150}px`
          console.log('in the lanscape', _height, _width);
          $('.print-style').css({
            width: `${Math.round(_height * 96)}px`,
            height: `${Math.round(_width * 96)}px`,
            margin: '8rem auto',
          });
          $('.print-map').css({
            resize: 'both',
            width: `${Math.round(_height * 96)}px`,
            height: `${Math.round(_width * 96)}px`,
            'max-width': `${Math.round(_height * 96)}px`,
            overflow: 'hidden',
            'max-height': `${Math.round(_width * 96)}px`,
          });
          const popupExtent = this.basemap
            .getView()
            .calculateExtent([_height * 96, _width * 96]);
          this.printMap.getView().fit(popupExtent);
          this.printMap.setSize([_height * 96, _width * 96]);
          this.printMap.updateSize();
          console.log(this.printMap.updateSize(), 'landscape size')
        }
        // if (this.orientation === 'Portrait') {
        //   var logo = document.getElementsByClassName("logo-move")[0]
        //   var printhtml=document.getElementById('map-mini')
        //   console.log(parseInt(printhtml['style'].maxHeight),parseInt(printhtml['style'].maxWidth),"printmapheight")
        //   logo["style"].inset = "604.01px auto auto 345px"
        //   console.log('in the potrait', _width, _height);
        //   $('.print-style').css({
        //     width: `${Math.round(_width * 96)}px`,
        //     height: `${Math.round(_height * 96)}px`,
        //     margin: '33px auto auto',
        //   });
        //   $('.print-map').css({
        //     resize: 'both',
        //     'max-width': `${Math.round(_width * 96)}px`,
        //     overflow: 'hidden',
        //     'max-height': `${Math.round(_height * 96)}px`,
        //   });
        //   // $('.print-map').css({
        //   //    resize: 'both',
        //   //   'max-width': `${parseInt(printhtml['style'].maxWidth)}px`,
        //   //   overflow: 'hidden',
        //   //   'max-height': `${parseInt(printhtml['style'].maxHeight)}px`,
        //   //   width:`${parseInt(printhtml['style'].maxWidth)}px`,
        //   //   height: `${parseInt(printhtml['style'].maxHeight)}px`,
        //   //   // resize: 'both',
        //   //   // 'max-width': `${parseInt(printhtml['style'].maxWidth)}px`,
        //   //   // overflow: 'hidden',
        //   //   // 'max-height': `${parseInt(printhtml['style'].maxHeight)}px`,
        //   //   // width:`${parseInt(printhtml['style'].maxWidth)}px`,
        //   //   // height: `${parseInt(printhtml['style'].maxHeight)}px`,
        //   // });
        //   const popupExtent = this.basemap
        //     .getView()
        //     .calculateExtent([_width * 96, _height * 96]);
        //   this.printMap.getView().fit(popupExtent);
        //   this.printMap.setSize([parseInt(printhtml['style'].maxWidth),parseInt(printhtml['style'].maxHeight)]);
        //   this.printMap.updateSize();
        //   console.log(this.printMap.updateSize(), 'portrait size')
        // } else {
        //   var logo = document.getElementsByClassName("logo-move")[0]
        //   var printhtml=document.getElementById('map-mini')
        //   console.log(Math.round(_height * 96)+'px',Math.round(_height * 96)+"px","printmapheight")
        //   logo["style"].inset = "459.01px auto auto 490px"
        //   console.log('in the lanscape', _height, _width);
        //   $('.print-style').css({
        //     width: `${Math.round(_height * 96)}px`,
        //     height: `${Math.round(_width * 96)}px`,
        //     margin: '8rem auto',
        //   });
        //   $('.print-map').css({
        //     resize: 'both',
        //     'max-width': `${Math.round(_width * 96)}px`,
        //     overflow: 'hidden',
        //     'max-height': `${Math.round(_height * 96)}px`,
        //   });
        //   // $('.print-map').css({
        //   //   resize: 'both',
        //   //   'max-width': `${parseInt(printhtml['style'].maxHeight)}px`,
        //   //   overflow: 'hidden',
        //   //   'max-height': `${parseInt(printhtml['style'].maxWidth)}px`,
        //   //   width:`${parseInt(printhtml['style'].maxHeight)}px`,
        //   //   height: `${parseInt(printhtml['style'].maxWidth)}px`,
        //   // });
        //   const popupExtent = this.basemap
        //     .getView()
        //     .calculateExtent([_height * 96, _width * 96]);
        //   this.printMap.getView().fit(popupExtent);
        //   this.printMap.setSize(parseInt(printhtml['style'].maxHeight),parseInt(printhtml['style'].maxWidth));
        //   this.printMap.updateSize();
        //   console.log(this.printMap.updateSize(), 'landscape size')
        // }
      }
    });

  }

  // onDPIOptionSelected(dpi) {
  //   console.log(dpi, ' check dpi');
  //   const orientation =
  //     this.addPresentationFormGroup.get('addOrientation').value;
  //   this.presentationPageSizes.map((x) => {
  //     if (this.addPresentationFormGroup.get('addPageSize').value === x.name) {
  //       const pageWidth = x.value[0] / 25.4;
  //       const pageHeight = x.value[1] / 25.4;

  //       const divWidth = 564 * 0.0104166667;
  //       const divHeight = 640 * 0.0104166667;

  //       console.log(pageWidth, pageHeight, 'in the dpi option');

  //       const newWidth = (divHeight * pageWidth) / pageHeight;

  //       console.log(newWidth, 'new width in dpi selected');

  //       const resolution_width = dpi * newWidth;

  //       const resolution_height = dpi * divHeight;

  //       console.log(
  //         resolution_width,
  //         resolution_height,
  //         'after added dpi width and height in px'
  //       );

  //       const _width = resolution_width / dpi;

  //       const _height = resolution_height / dpi;

  //       console.log(_width, _height, 'check width and height in inh');

  //       if (orientation === 'Portrait') {
  //         console.log('in the potrait', newWidth, divHeight);

  //         console.log(
  //           resolution_width,
  //           resolution_height,
  //           'after adding dpi to width and height'
  //         );

  //         console.log(_width, _height, 'original width and height in inches');

  //         $('.print-style').css({
  //           width: `${_width}in`,
  //           height: `${_height}in`,
  //           margin: '33px auto auto',
  //         });
  //         $('.print-map').css({
  //           resize: 'both',
  //           'max-width': `${_width}in`,
  //           overflow: 'hidden',
  //           'max-height': `${_height}in`,
  //         });
  //         const popupExtent = this.basemap
  //           .getView()
  //           .calculateExtent([_width * 96, _height * 96]);
  //         this.printMap.getView().fit(popupExtent);
  //         this.printMap.setSize([_width * 96, _height * 96]);
  //       } else {
  //         console.log('in the lanscape', _height, _width);

  //         console.log(
  //           resolution_height,
  //           resolution_width,
  //           'after adding dpi to width and height'
  //         );

  //         console.log(_height, _width, 'original width and height in inches');

  //         $('.print-style').css({
  //           width: `${_height}in`,
  //           height: `${_width}in`,
  //           margin: '8rem auto',
  //         });
  //         $('.print-map').css({
  //           resize: 'both',
  //           'max-width': `${_height}in`,
  //           overflow: 'auto',
  //           'max-height': `${_width}in`,
  //         });
  //         const popupExtent = this.basemap
  //           .getView()
  //           .calculateExtent([_height * 96, _width * 96]);
  //         this.printMap.getView().fit(popupExtent);
  //         this.printMap.setSize([_height * 96, _width * 96]);
  //       }
  //     }
  //   });
  // }

  public loadAllBaseMaps(options) {
    let _basemapFactory = new BasemapFactory('openstreet');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    _basemapFactory = new BasemapFactory('satellite');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    _basemapFactory = new BasemapFactory('terrain');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    _basemapFactory = new BasemapFactory('bingsatellite');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    /* _basemapFactory = new BasemapFactory('bingstreet');
  this._currentMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer()); */
    _basemapFactory = new BasemapFactory('googlestreet');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    _basemapFactory = new BasemapFactory('googleSatellite');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    _basemapFactory = new BasemapFactory('toner');
    this.printMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
    /* if (options.pageType === 'DEFAULT') {
    _basemapFactory = new BasemapFactory('bingsatellite');
    this._currentMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
  } else {
    _basemapFactory = new BasemapFactory('bingstreet');
    this._currentMap.addLayer(_basemapFactory.getBaseMap().getMapTileLayer());
  } */
  }

  getMaptypeSelected(mapType) {
    console.log('hitted');

    console.log(mapType, 'check');

    this.printMap.getLayers().forEach((layer) => {
      console.log('check - ', layer.values_);
      if (mapType === 'openstreet') {
        if (
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'openstreet') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'satellite') {
        if (
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'satellite') {
          layer.setVisible(true);
        }
      }
      if (mapType === 'terrain') {
        if (
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'terrain') {
        }
      }

      if (mapType === 'toner') {
        if (
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'toner') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'bingsatellite') {
        if (
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingsatellite') {
          layer.setVisible(true);
        }
      }
      // New code for street view of bing
      if (mapType === 'bingstreet') {
        if (
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'googlestreet' ||
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingstreet') {
          layer.setVisible(true);
        }
      }

      // New code for street view of bing
      if (mapType === 'googlestreet') {
        if (
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'googlesatellite'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlestreet') {
          layer.setVisible(true);
        }
      }

      // New code for satellite view of google
      if (mapType === 'googlesatellite') {
        if (
          layer.values_.name === 'openstreet' ||
          layer.values_.name === 'terrain' ||
          layer.values_.name === 'toner' ||
          layer.values_.name === 'bingsatellite' ||
          layer.values_.name === 'bingstreet' ||
          layer.values_.name === 'satellite' ||
          layer.values_.name === 'googlestreet'
        ) {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlesatellite') {
          layer.setVisible(true);
        }
      }
    });

    // #TODO - later this code need to be simply & easly...
  }

  submit(): any {

    //         const scaleLine = new ScaleLine({ className: 'custom-scale-line',units:'us',bar: true, text: true, minWidth: 125});
    // this.printMap.addControl(scaleLine);




    // for(let i =8;i<scalbarui2.length;i++){
    //   console.log(scalbarui2[i],"scalbarui2")
    //   scalbarui2[i]['style'].height='10px'
    // }
    // this.printMap.controls.forEach((control) => {
    //   console.log('what is control here ', control);
    //   if (control.values_ !== null && control.values_.units !== undefined) {
    //     this.printMap.removeControl(control);
    //     control.setUnits(
    //       localStorage.getItem('unit')
    //     );
    //     // this.mapProjectionUnits = this.setMapProjectionUnits(control.renderedHTML_);
    //     this.mapProjectionUnitsPopup = this.setMapProjectionUnits(
    //       control.element.innerText, this.updatedratio
    //     );
    //     console.log(control.element.innerText, "checkscale")
    //     // this.printMap.addControl(control);
    //       }
    //     });
    // this.observ.updateUnits(this.addPresentationFormGroup.controls.addMapscale.value);
    // this.modalService.dismissAll('close');
    // localStorage.setItem('unit', this.addPresentationFormGroup.controls.addMapscale.value);
  }

  onOrientOptionsSelected(selectedValue): any {
    console.log('selected value ', selectedValue);
    this.selectedOrientation = selectedValue;
    /* setTimeout(() => {
      this.calcuating_PolygoneFromPDFSize();
    }, 500); */
    // this.calculatePrintFrame_FitMap(true);
    // this.embeddingPrintMap();
  }
  // onPdfOptionsSelected(selectedValue): any {
  //   console.log('selected value ', selectedValue);
  //   this.selectedPageSize = selectedValue;
  //   // this.calculatePrintFrame_FitMap(true);
  //   // this.embeddingPrintMap();
  // }
  isValid(str): any {
    if (
      str == null ||
      str === undefined ||
      str === ' ' ||
      str === '' ||
      str === 'null' ||
      str === 'undefined'
    ) {
      return false;
    } else {
      return true;
    }
  }
  getScaleLineWidth(): any {
    setTimeout(() => {
      try {
        const mapControlCollection: any[] = this.printMap.getControls().array_;
        console.log(mapControlCollection, 'check map control collection');
        mapControlCollection.forEach((element) => {
          console.log(element, 'check all mapcontrol elements');
          if (this.isValid(element.renderedWidth_)) {
            this.scaleLineWidth = element.renderedWidth_;
          }
        });
        console.log('SCALE LINE WIDTH : ', this.scaleLineWidth);
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }
  getMapProjectionUnits(): any {
    console.log(this.printMap.controls, "checkmapcontrols")
    console.log(this.printMap.controls, "checkmapcontrols")
    this.printMap.controls.forEach((control) => {
      console.log(this.printMap, control, 'vales....');
      if (this.commonService.isValid(control.values_)) {
        if (control.values_.units !== undefined) {
          console.log(control, 'check all the controls....');
          setTimeout(() => {
            // console.log('Here scal line ', control, control.renderedHTML_, control.element.innerText);
            this.mapProjectionUnitsPopup = this.setMapProjectionUnits(
              control.element.innerText, this.updatedratio
            );
            console.log(control.element.innerText, "checkscalebar")
          }, 1000);
        }
      }
    });
  }
  setMapProjectionUnits(val, updatedvalueinviewFactor): any {

    this.checkresolution = val
    this.viewPort = this.printMap.getViewport()
    console.log(this.printMap.getViewport(), "getviewport")
    // Set the conversion factor based on the desired units (e.g., meters, kilometers, miles, etc.)

    // Display the view factor
    console.log(this.basemap.getView().getResolution(), this.basemap.controls, val, this.printMap.getView().getResolution(), "checkResolutions")
    console.log(val, 'vales.....gcfnj');
    const tempArr = val.split(' ');
    console.log(tempArr, "checktemparr")
    if (tempArr.length > 1) {
      // Here adding new code for view factory related
      let scaleLine;
      if(tempArr[3].match(/\d+/g) !== undefined) {
        scaleLine = tempArr[3].match(/\d+/g);
      }
      scaleLine = tempArr[3].match(/\d+/g);
      const scalByHalf = Number(scaleLine) / 2;
      const value = tempArr[2]
        .slice(0, tempArr[2].length - scalByHalf.toString().length)
        .slice(0, -1); // .replace(/\,/g, '');
      // console.log(tempArr, ' :: ', scaleLine, ' : ', value);
      const viewFactory = tempArr[0] + tempArr[1] + value;
      console.log(viewFactory, 'fxgchjk');
      this.viewfactorchange = true
      this.currentViewFactory = viewFactory;
      this.value = Number(value.replace(/,/g, ''))
      console.log(this.printMap.getView().getResolution(), "cexfgchvjk")
      console.log(this.value2, value, tempArr, this.scaleLineWidth, "value2fromscale")
      console.log(tempArr[0] + tempArr[1] + value, 'cejbhjh');
      console.log(updatedvalueinviewFactor, tempArr[4], scaleLine, "updatedvalueinviewFactor")
      if (this.changeviewfactor) {
        var convert_Number = updatedvalueinviewFactor // e*816/495
        console.log("in true")
      }
      if (!this.changeviewfactor) {
        var convert_Number = value.replace(/,/g, '');
        console.log("in false")
        this.countiteration = 0;
      }
      this.viewfactorforui = value
      // var convert_Number = value.replace(/,/g, '');
      // this.value2 = Math.round(Number(parseInt(convert_Number)*this.PreviewToPhysicalRatio));
      this.value2 = Math.round(Number(parseInt(convert_Number)));
      var showviewfactor = document.getElementById('changeviewfactor');
      console.log(showviewfactor, "showviewfactor")
      // this.value2 = parseInt(convert_Number);
      var viewFactorValue = Number(convert_Number);
      this.scalevalue = Number(scaleLine[0]) + tempArr[4]


      this.testingDPI
      // this.testingFactor
      var inMetric
      var inMiles
      var updatedFontSize = 12
      var scalebarui = document.getElementById('scalebar')
      var scalebarui1 = document.getElementById('scalebar1')
      var navigateui = document.getElementsByClassName('compass-move')[0]
      var navigate = navigateui.getElementsByClassName("navigate")[0]
      console.log(navigateui, scalebarui, navigate, "checknavigateui")
      scalebarui.style.fontSize = `${16 * this.PreviewToPhysicalRatio}px`
      scalebarui1.style.fontSize = `${16 * this.PreviewToPhysicalRatio}px`
      if (tempArr[4] == "km" || tempArr[4] == "m" || tempArr[4] == "mm") {
        inMetric = true;
        inMiles = false;
        console.log(this.PreviewToPhysicalRatio, this.scalebarwidthmetric, "checkwidth")
        scalebarui.style.width = `${this.testingDPI}px`
        scalebarui1.classList.add('animated-width');
        scalebarui1.style.width = `${this.scaleLineWidth}px`
        scalebarui.style.height = parseInt(this.scalebarwidthmetric) / 3.3 + "px"
        navigate["style"].width = this.scalebarwidthmetric
        navigate["style"].height = this.scalebarwidthmetric

      }
      else if (tempArr[4] == "mi" || tempArr[4] == "ft" || tempArr[4] == "in") {
        inMiles = true;
        inMetric = false;
        console.log(this.PreviewToPhysicalRatio, this.scalebarwidth, Number(scaleLine[0]), (Number(scaleLine[0]) / Number(this.scaleLineWidth)) * this.testingDPI, "checkwidth")
        scalebarui.style.width = `${this.testingDPI}px`
        scalebarui1.classList.add('animated-width');
        scalebarui1.style.width = `${this.scaleLineWidth}px`
        // scalebarui.style.width=this.scalebarwidth
        scalebarui.style.height = parseInt(this.scalebarwidth) / 3.3 + "px"
        navigate["style"].width = this.scalebarwidth

        navigate["style"].height = this.scalebarwidth
        console.log(scalebarui, "scalebarui")
      }

      else {
        inMiles = false;
        inMetric = false;
      }


      console.log(this.printMap.getView().getZoom(), "checkzoomlevel")
      if (inMiles) {
        var mileviewingfactor = Math.round(((Number(scaleLine[0]) / Number(this.scaleLineWidth)) * this.testingDPI)) + tempArr[4]

        // var mileviewingfactor = Math.round(((Number(scaleLine[0]) / Number(this.scaleLineWidth)) * this.testingDPI)) + tempArr[4]
        // var mileviewingfactor=Math.round(((Number(scaleLine[0])/Number(this.scaleLineWidth))*parseInt(this.scalebarwidth))) + tempArr[4]
        // var mileviewingfactor=Number(convert_Number)*this.PreviewToPhysicalRatio;
        // var mileviewingfactor=Number(convert_Number)
        // if (viewFactorValue > 63360) {
        //   this.inMilesSystem = `${Math.round(mileviewingfactor / 63360)} mi  `;
        // } else if (viewFactorValue <= 63360 && viewFactorValue > 12) {
        //   this.inMilesSystem = `${Math.round(mileviewingfactor / 12)} ft`;
        // } else if (viewFactorValue <= 12) {
        //   this.inMilesSystem = `${Math.round(mileviewingfactor) / 1} in`;
        //   console.log(this.inMilesSystem, "inmilesystem")
        // }
        this.finalscaling = mileviewingfactor;
      }

      else if (inMetric) {

        var metricviewingfactor = Math.round(((Number(scaleLine[0]) / Number(this.scaleLineWidth)) * 91)) + tempArr[4]
        // var metricviewingfactor=Number(convert_Number)
        // if (viewFactorValue > 100000) {
        //   this.inMetricSystem = ` ${Math.round((metricviewingfactor)/ 100000)} km`;
        //   this.unitConversion = 100000;
        // } else if (viewFactorValue <= 100000 && viewFactorValue > 100) {
        //   this.inMetricSystem = `${Math.round((metricviewingfactor) / 100)} m`;
        //   this.unitConversion = 100000;
        // } else if (viewFactorValue <= 100) {
        //   this.inMetricSystem = `${Math.round((metricviewingfactor) / 1)} cm`;
        //   this.unitConversion = 1;
        // }
        this.finalscaling = metricviewingfactor;
      }
      else {
        this.finalscaling = "invalif";
      }
      console.log(this.inMetricSystem, this.inMilesSystem, "chekthevalues")
      // if (tempArr[4] == "km" || tempArr[4] == "m" || tempArr[4] == "in") {
      //   this.finalscaling = this.inMetricSystem
      // }
      // if (tempArr[4] == "mi" || tempArr[4] == "ft" || tempArr[4] == "in") {
      //   this.finalscaling =   this.inMilesSystem 
      // }
      this.countiteration++
      if (this.countiteration == 3) { this.changeviewfactor = false; this.countiteration = 1 }

      console.log(inMiles, 'check bcvhgj');
      // this.observ.updateViewFactory(viewFactory);
      if (scaleLine.includes('.')) {
        let fixedNum = scaleLine.substr(scaleLine.indexOf('.') + 1).length;
        // console.log(fixedNum);
        if (fixedNum > 5) {
          // console.log('MORE THAN 5. SETTING TO 5');
          fixedNum = 5;
        } console.log(val, "checkscalingg")
        return String(Number(scaleLine).toFixed(fixedNum)) + ' ' + tempArr[4];
      } else {
        console.log(scaleLine + ' ' + tempArr[4], "checkscalingg")
        return this.finalscaling;
      }
    } else {
      console.log(val, "checkscalingg")
      return val;

    }

  }

  // getLayerStyle() {
  //   return new Style({
  //     width: 495,
  //     height: 640
  //   });
  // }

  initializingMiniMap(mapLayer, projection) {
    console.log(mapLayer, 'check layer');

    this.printMap = new Map({
      layers: [mapLayer],
      target: 'map-mini',
      controls: defaultControls().extend([
        new ScaleLine({

          className: 'ol-scale-text-mini',
          target: this.scaleContainerPopup,
          units: 'us',
          bar: true,
          text: true,


        }),
      ]),
      view: new View({
        center: [-119.417931, 36.778259],
        zoom: 4,
        projection: projection,
        constrainRotation: false,
      }),

    })

    const scaleLine = new ScaleLine({ className: 'custom-scale-line', units: 'us', bar: true, text: true, minWidth: 145 });
    this.printMap.addControl(scaleLine);
    var scalevalues = document.getElementsByClassName('ol-scale-step-text');
    console.log(scalevalues, "scalevalues")
  }

  embeddingPrintMap(): any {
    const projection = this.basemap.getView().getProjection().code_;
    this.basemap.getLayers().forEach((basemapLayer) => {
      const layerName = basemapLayer.values_.name;
      if (
        layerName === 'terrain' ||
        layerName === 'openstreet' ||
        layerName === 'satellite' ||
        layerName === 'toner' ||
        layerName === 'bingsatellite' ||
        layerName === 'bingstreet' ||
        layerName === 'googlestreet' ||
        layerName === 'googlesatellite'
      ) {
        if (basemapLayer.values_.visible) {
          const basemapFactory = new BasemapFactory(layerName);
          const mapLayer: OlTileLayer = basemapFactory
            .getBaseMap()
            .getMapTileLayer();
          mapLayer.setVisible(true);
          this.initializingMiniMap(mapLayer, projection);
          // const scaleLine = new ScaleLine({units:'us',bar: true, text: true, minWidth: 125});
          // this.printMap.addControl(scaleLine);
          // this.calculatePrintFrame_FitMap(false);
          //           const scaleLine = new ScaleLine({ className: 'custom-scale-line',units:'us',bar: true, text: true, minWidth: 125});
          // this.printMap.addControl(scaleLine);
        }
      }
    });
    /* this.calcuating_PolygoneFromPDFSize();
    this.showSitesInfoFun(); */
  }


  showOrCloseUnits() {
    this.scalebaractive = !this.scalebaractive
    this.showProjectionUnits = !this.showProjectionUnits;
  }


  // calculatePrintFrame_FitMap(isSelectionChange) {
  //   this.printMap.getView().setRotation(this.basemap.getView().getRotation());
  //   this.rotationValue = this.basemap.getView().getRotation() * 180 / Math.PI;
  // /* try{
  //   const globeIconDraggable = Draggable.get('#rotationN');
  //   TweenLite.set('#rotationN', { rotation: this.rotationValue });
  //   globeIconDraggable.update();
  // } catch (e){
  //   console.log(e);
  // } */
  //   // globeIconDraggable.update();
  //   /* 1 inch = 25.4 mm
  //   dpi = 96 px / in
  //   96 px / 25.4 mm
  //   Therefore one pixel is equal to
  //   1 px = 25.4 mm / 96
  //   1 px = 0.26458333 mm */
  //   let pdfSize = this.selectedPageSize;
  //   if (this.selectedPageSize.length > 2) {
  //     pdfSize = [];
  //     pdfSize.push(this.selectedPageSize.split(',')[0], this.selectedPageSize.split(',')[1]);
  //   }
  //   // mm = ( pixcel * 25.4 ) / DPI(default 96)
  //   // pixcel = mm * 96(for DPI) / 25.4
  //   // ratio calculation = width/height & 1 / result(w/h)
  //   const pdfWidthHeightRatio = pdfSize[0] / pdfSize[1];
  //   const pdfHeightWidthRatio = 1 / pdfWidthHeightRatio;
  //   // main whitespace Div Height & Width to mm
  //   const divWidth = this.presentationIMGContainer.nativeElement.offsetWidth * 25.4 / 96;
  //   const divHeight = this.presentationIMGContainer.nativeElement.offsetHeight * 25.4 / 96;
  //   // calculting mapPopup height & width
  //   const mapWindowHeight = divWidth / pdfHeightWidthRatio;
  //   const mapWindoWidth = divHeight / pdfHeightWidthRatio;
  //   const printFrameSize = [];
  //   let printFrameFitMapSize = [210, 297];
  //   const mapDivSize = [this.mapDiv.nativeElement.offsetWidth, this.mapDiv.nativeElement.offsetHeight];
  //   if (this.selectedOrientation !== 'portrait') {
  //     printFrameSize.push(pdfSize[1], pdfSize[0]);
  //     this.ptool.nativeElement.style.width = mapWindowHeight + 'mm';
  //     this.ptool.nativeElement.style.height = (mapWindoWidth - 0) + 'mm';
  //     printFrameFitMapSize = [297, 210];
  //   } else {
  //     printFrameSize.push(pdfSize[0], pdfSize[1]);
  //     this.ptool.nativeElement.style.width = mapWindoWidth + 'mm';
  //     this.ptool.nativeElement.style.height = (mapWindowHeight - 0) + 'mm';
  //     printFrameFitMapSize = [210, 297];
  //   }
  //   this.mapDiv.nativeElement.style.width = this.ptool.nativeElement.offsetWidth + 'px';
  //   this.mapDiv.nativeElement.style.height = this.ptool.nativeElement.offsetHeight + 'px';
  //   this.mapDiv.nativeElement.style.maxWidth = this.ptool.nativeElement.offsetWidth + 'px';
  //   this.mapDiv.nativeElement.style.maxHeight = this.ptool.nativeElement.offsetHeight + 'px';
  //   this.ptool.nativeElement.style.maxHeight = this.presentationIMGContainer.nativeElement.offsetHeight + 'px';

  //   console.log('calculated data - pdfWidthHeightRatio: ', pdfWidthHeightRatio, ' pdfHeightWidthRatio: ', pdfHeightWidthRatio,
  //   ' divWidth: ', divWidth, ' divHeight: ', divHeight,
  //   'mapWindowHeight: ', mapWindowHeight, ' mapWindoWidth: ', mapWindoWidth,
  //   '--> mapWindowHeight: ', mapWindowHeight * 96 / 25.4, ' mapWindoWidth: ', mapWindoWidth * 96 / 25.4);

  //   if(!isSelectionChange) {
  //     const popupExtent = this.basemap.getView().calculateExtent(printFrameFitMapSize);
  //     this.printMap.getView().fit(popupExtent);
  //     console.log(pdfSize, printFrameSize, printFrameFitMapSize, popupExtent, this.ptool, getCenter(popupExtent),
  //     this.basemap.getView().getCenter(), this.printMap.getView().getCenter(),
  //     this.basemap.getView().getResolution(), this.printMap.getView().getResolution(),
  //     Math.round(pdfSize[0] * this.selectedDPI / 25.4), Math.round(pdfSize[1] * this.selectedDPI / 25.4),
  //     this.presentationIMGContainer.nativeElement.offsetWidth,
  //     this.presentationIMGContainer.nativeElement.offsetHeight,
  //     Math.round((printFrameSize[0] * this.selectedDPI / 25.4) / this.presentationIMGContainer.nativeElement.offsetWidth * 100),
  //     Math.round((printFrameSize[1] * this.selectedDPI / 25.4) / this.presentationIMGContainer.nativeElement.offsetHeight * 100));
  //     
  //     this.getMapProjectionUnits();
  //   } else {
  //     this.printMap.updateSize();
  //   }
  //   this.showSitesInfoFun();
  // }
  // removePolygon(): any {
  //   if (this.printMap.getLayers().values_.length > 0) {
  //     this.printMap.getLayers().forEach((layer) => {
  //       console.log(layer);
  //       if (layer !== undefined) {
  //         if (layer.values_.name === 'orientationPrint') {
  //           this.printMap.removeLayer(layer);
  //         }
  //       }
  //     });
  //   }
  // }

  // drawPolygon(popupExtent): any {
  //   console.log(this.printMap.getLayers());
  //   if (this.printMap.getLayers().values_.length > 0) {
  //     this.printMap.getLayers().forEach((layer) => {
  //       console.log(layer);
  //       if (layer !== undefined) {
  //         if (layer.values_.name === 'orientationPrint') {
  //           this.printMap.removeLayer(layer);
  //         }
  //       }
  //     });
  //   }
  //   this.printMap.addLayer(
  //     new VectorLayer({
  //       name: 'orientationPrint',
  //       source: new VectorSource({
  //         features: [new Feature(fromExtent(popupExtent))],
  //       }),
  //       style: new Style({
  //         fill: new Fill({
  //           color: 'rgba(255, 255, 255, 0.7)', // 'rgba(255, 255, 255, 0.2)'
  //         }),
  //         stroke: new Stroke({
  //           color: 'blue',
  //           width: 2,
  //         }),
  //       }),
  //     })
  //   );
  // }

  showSitesInfoFun(): void {
    console.log('check sites whether actve');
    console.log(
      this.notePadService.getStoredNotesObject(),
      'check the object....'
    );
    this.activeSitesForPresentation = [];
    // this.showPresentaiton = true;
    const activeSites = [];
    const activegeotowerSites = [];
    this.basemapService
      .getCurrentBasemap()
      .getLayers()
      .forEach((layer) => {
        console.log(layer.values_.custom_name, 'check layer data');
        console.log(layer,"sdsdsdsdsdasdad")
        if (layer.values_.visible && layer.constructor.name!=="TileLayer" && !this.commonService.isValid(layer.values_.geopadCustomData)){
          var towerlayer=layer
          activegeotowerSites.push(towerlayer)
        }
        if (this.commonService.isValid(layer)) {
          // TO IDENTIFY SITE LAYERS ONLY
          if (this.commonService.isValid(layer.values_.geopadCustomData)) {
            activeSites.push(layer);
          } else if (layer.values_.custom_name) {
            this.printMap.addLayer(layer);
          }
          
        }
      });
      console.log(this.activeSitesForPresentation,"checklayers")
    activeSites.forEach((element) => {
      this.printMap.addLayer(element);
      const site = element.values_.geopadCustomData.site;
      if (site.siteType == "Polygon") {
        var iconurl = "../../assets/svgs/geopad/new/polygon_icon.svg"
      }
      else if (site.siteType == "Point") {
        var iconurl = "../../assets/svgs/geopad/new/point_icon.svg"
      }
      else if (site.siteType == "LineString") {
        var iconurl = "../../assets/svgs/geopad/new/line_icon.svg"
      }
      this.activeSitesForPresentation.push({
        name: site.locationName,
        type: site.siteType,
        fillColour: site.fillColour,
        iconColour: site.iconColour,
        icon: iconurl
      });
      
      console.log(this.activeSitesForPresentation, site, 'find meee');
    });

    //Adding geotowerlayer
    
    if(this.geotowerService.geotowerLayersList.length>0){
      
      activegeotowerSites.forEach((layer) => {
        console.log(layer, ' activegeotowerSites ')
          this.printMap.addLayer(layer)
      });
      this.geotowerService.geotowerLayersList.forEach((layer) => {
        
      console.log(layer.metadata, 'metadata', layer)
        // this.printMap.addLayer(layer)
        
        if(layer.previewLayer){
          var iconurl = "../../assets/svgs/geopad/new/polygon_icon.svg"
          // if (layer.metadata[0].features[0].geometry.type == "Polygon") {
          //   var iconurl = "../../assets/svgs/geopad/new/polygon_icon.svg"
          // }
          // else if (layer.metadata[0].features[0].geometry.type == "Point") {
          //   var iconurl = "../../assets/svgs/geopad/new/point_icon.svg"
          // }
          // else if (layer.metadata[0].features[0].geometry.type == "LineString") {
          //   var iconurl = "../../assets/svgs/geopad/new/line_icon.svg"
          // }
          this.activeSitesForPresentation.push({
            name: layer.name,
            type: 'Polygon', // layer.metadata[0].type,
            icon: iconurl
            
          });
          console.log(layer,"layername")
        }
      });
    }
    
  }

  // showPresentaitonFun_old(): void {
  //   this.activeSitesForPresentation = [];
  //   this.showPresentaiton = true;
  //   const activeSites = [];
  //   this.basemapService
  //     .getCurrentBasemap()
  //     .getLayers()
  //     .forEach((layer) => {
  //       if (this.commonService.isValid(layer)) {
  //         // TO IDENTIFY SITE LAYERS ONLY
  //         if (this.commonService.isValid(layer.values_.geopadCustomData)) {
  //           activeSites.push(layer);
  //         }
  //       }
  //     });
  //   activeSites.forEach((element) => {
  //     const site = element.values_.geopadCustomData.site;
  //     this.activeSitesForPresentation.push({
  //       name: site.locationName,
  //       type: site.siteType,
  //       fillColour: site.fillColour,
  //       iconColour: site.iconColour,
  //     });
  //   });
  //   console.log(this.activeSitesForPresentation);
  // }

  // setValueForInputFromSelect(event, inputFrom: string, listItems: any[]): void {
  //   console.log(event);
  //   const val = event.target.value;
  //   let nameToSet = '';
  //   const index = listItems.findIndex(
  //     (item) => String(item.value) === String(val)
  //   );
  //   if (index !== -1) {
  //     nameToSet = listItems[index].name;
  //   }
  //   try {
  //     if (inputFrom === 'dpi') {
  //       this.dpiInput.nativeElement.value = nameToSet;
  //     }
  //   } catch (e) { }
  // }
  // createHeaders(keys) {
  //   var result = [];
  //   for (var i = 0; i < keys.length; i += 1) {
  //     result.push({
  //       id: keys[i],
  //       name: keys[i],
  //       prompt: keys[i],
  //       width: 70,
  //       height: 5,
  //       align: 'center',
  //       padding: 0,
  //     });
  //   }
  //   return result;
  // }



  private _updateDraggableObj(): any {
    var instanceMap = this
    const rotationN = document.querySelector('#rotationN')
    if (rotationN) {
      Draggable.create(rotationN, {
        type: 'rotation',
        throwProps: true,
        onDrag: function () {
          let angle;
          console.log(this.target._gsTransform.rotation, "check the transfroemd code");
          angle = this.target._gsTransform.rotation
          console.log(angle, "check the angle....")
          console.log(instanceMap.printMap, "check the printmap inisde")
          instanceMap.printMap.getView().setRotation(Math.PI / 180 * angle);
        },
        onDragEnd: function () {
          let angle;
          console.log(this.target._gsTransform.rotation, "check the transfroemd code");
          angle = this.target._gsTransform.rotation
          console.log(angle, "check the angle....")
          console.log(instanceMap.printMap, "check the printmap inisde")
          instanceMap.printMap.getView().setRotation(Math.PI / 180 * angle);
        }
      });
      const globeIconDraggable = Draggable.get('#rotationN');
      // this.basemapService.updateAngle.subscribe(x =>{
      //   console.log(x,"check the x")
      //   TweenLite.set('#rotationN', { rotation: x });

      // })
      TweenLite.set('#rotationN', { rotation: 0 });

      globeIconDraggable.update();
    }
  }

  private _fieldDraggableObj(): any {
    Draggable.create('#rotationField', {
      type: 'rotation',
      throwProps: true,
      // bounds: { minRotation: -23, maxRotation: 337 },
      onDrag: (e) => {
        console.log('drag start ', e);
        let target = null;
        if (e.target.tagName === 'IMG') {
          console.log('img matched');
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'rotationField') {
          console.log('rotationId MATCHED');
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
          target = this.rotationField.nativeElement;
          // target = e.target;
        }
        if (this.commonService.isValid(target)) {
          console.log(target, 'in the ondrag');
          // console.log('VALID TARGET...');
          const element = target; // e.target;
          // console.log(element);
          // console.log(element._gsTransform);
          let angle = element._gsTransform.rotation;
          // console.log(e, angle, element);
          // Here code call for setting the angle to base map
          angle = angle + 23;
          // this._setRotation(angle);
          this.printMap.getView().setRotation((angle * Math.PI) / 180);
        } else {
          console.log('INVALID TARGET...');
        }
      },
      onDragEnd: (e) => {
        console.log('drag end ', e);
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'rotationField') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
          target = this.rotationField.nativeElement;
        }
        if (this.commonService.isValid(target)) {
          // console.log('VALID TARGET...');
          const element = target; // e.target;
          let angle = element._gsTransform.rotation;
          console.log(angle, element);
          // Here code call for setting the angle to base map
          angle = angle + 23;
          this.angleRotate = `${parseInt(angle)}°`;
          // this._setRotation(angle);
          console.log(
            this.mapDiv.nativeElement.offsetWidth,
            this.mapDiv.nativeElement.offsetHeight,
            'check width and height naveen'
          );

          this.printMap.getView().setRotation((angle * Math.PI) / 180);
          console.log(angle, 'check angle');
        } else {
          console.log('INVALID TARGET...');
        }
      },
    });
    const globeIconDraggable = Draggable.get('#rotationField');
    TweenLite.set('#rotationField', { rotation: 0 });
    globeIconDraggable.update();
  }
  setDefaultPosition(event) {
    this._updateDraggableObj();
    this.rotationValue = 0;
    this.printMap.getView().setRotation((0 * Math.PI) / 180);
  }



  // draggableMapView(){
  //    this.print_view = document.querySelector(".print-map")

  //    //map move function
  //     this.print_view.addEventListener("mousedown", (event)=>{
  //       if(event.altKey === true){
  //           this.print_view.addEventListener("mousemove", ()=>{
  //             $(".print-map").css({"cursor": "move"})
  //             $(".print-map").draggable({containment: "parent" })
  //           });
  //       }

  //         this.print_view.addEventListener("mouseup", ()=>{
  //         this.print_view.removeEventListener("mousemove", ()=>{
  //           $(".print-map").draggable({containment: "parent" })
  //         })

  //       })
  //      })

  //     //  this.compass_view = document.querySelector(".compass-move")

  //      //compass move function
  //     //  this.logo_view.addEventListener("mousedown", ()=>{

  //     //      this.logo_view.addEventListener("mousemove", ()=>{
  //     //        $(".compass-move").draggable({containment: "parent" })
  //     //      })

  //     //    this.logo_view.addEventListener("mousedown", ()=>{
  //     //      this.logo_view.removeEventListener("mousemove", ()=>{
  //     //        $(".compass-move").draggable({containment: "parent" })
  //     //      })
  //     //    })
  //     //  })

  //     }
  Printingmanager(Maplogo, Navigate, LegendDiv, ImageDiv) {
    PrintToolComponent.printCount++
    if (PrintToolComponent.printCount == 1) {
      this.checkPdfDownload(Maplogo,
        Navigate,
        LegendDiv,
        ImageDiv)
    }
    else if (PrintToolComponent.printCount > 1) {
      alert("Print already initiated")
    }
  }
  checkPdfDownload(
    mapLogo,
    navigate,
    legend: ElementRef,
    imageDiv: ElementRef
  ) {
    var showloading = document.getElementById("loading")
    showloading.style.display = "block"
    setTimeout(() => {
      console.log(this.OverallArray, "Checkid")
      for (let i = 0; i < this.OverallArray.length; i++) {
        this.printcode = document.getElementById(this.OverallArray[i].id)
        this.OverallArray[i].printcode = this.printcode
        console.log(this.OverallArray[i].printcode, this.OverallArray.length, 'printcode 1')
        if (this.OverallArray[i].position == undefined) {
          var initialposition = {
            nav_x: 0,
            nav_y: 0,
          };
          this.OverallArray[i].position = initialposition
        }
        if (this.OverallArray[i].id == "upload-move") {
          this.OverallArray[i].printcode = this.imagecode
        }
      }

      this.OverallArray.sort((a, b) => a.zindex - b.zindex);
    }, 1000)
    console.log(this.OverallArray, 'circle in before3')
    console.log(this.printcode, 'printcode 2')



    console.log(this.storeSquarePosition, this.storeSquarePosition.pop(), this.storeSquarePosition.length, 'popped in 1st')
    console.log(this.storeTextAreaPosition, this.storeTextAreaPosition.pop(), 'text stored in pdf')
    var navigateArrayPosition = this.storeNavigateArrayPosition.length === 0 ? { nav_x: 0, nav_y: 0 } : this.storeNavigateArrayPosition.pop()
    // var logoArrayPosition = this.storeLogoArrayPosition.length === 0 ? { nav_x: 0, nav_y: 0 } : this.storeLogoArrayPosition.pop()
    var logoArrayPosition = this.storeLogoArrayPosition.pop()
    var printMapArrayPosition = this.storePrintMapPosition.length === 0 ? { nav_x: 0, nav_y: 0 } : this.storePrintMapPosition.pop()
    var legendArrayPosition = this.storeLegendPosition.length === 0 ? { nav_x: 0, nav_y: 0 } : this.storeLegendPosition.pop()
    var uploadImageArrayPosition = this.storeUploadImagePosition.pop()
    if (this.squareToPrint) {
      var squareArrayPosition = this.storeSquarePosition.length === 0 ? { nav_x: this.squareIntialPosition.nav_x, nav_y: this.squareIntialPosition.nav_y } : this.storeSquarePosition.pop()

    }
    if (this.circleToPrint) {
      var circleArrayPosition = this.storeCirclePosition.length === 0 ? { nav_x: this.circleIntialPosition.nav_x, nav_y: this.circleIntialPosition.nav_y } : this.storeCirclePosition.pop()

    }
    if (this.triangleToPrint) {
      var triangleArrayPosition = this.storeTrianglePosition.length === 0 ? { nav_x: this.triangleIntialPosition.nav_x, nav_y: this.triangleIntialPosition.nav_y } : this.storeTrianglePosition.pop()

    }
    if (this.textboxToPrint) {
      var textboxArrayPosition = this.storeTextAreaPosition.length === 0 ? { nav_x: this.textboxIntialPosition.nav_x, nav_y: this.textboxIntialPosition.nav_y } : this.storeTextAreaPosition.pop()
      console.log(this.storeTextAreaPosition, this.storeTextAreaPosition.pop(), 'text stored in pdf2')

    }
    console.log(this.storeTextAreaPosition, this.storeTextAreaPosition.pop(), 'text stored in pdf3')


    console.log("naviagtionArray", navigateArrayPosition, "logoArray", logoArrayPosition, "printMapArray", printMapArrayPosition, "legendArray", legendArrayPosition, "uploadImageArray", uploadImageArrayPosition, "squareArray", squareArrayPosition, "circleArray", circleArrayPosition, "triangleArray", triangleArrayPosition, textboxArrayPosition, 'texts', "check all stored array")


    const mapmini = this.mapDiv.nativeElement;
    var pdfwidthinMM
    var pdfheightinMM
    var pdfheight
    var pdfwidth
    var orientation = 'Portrait';
    var pdf: any;
    var filename;
    var Mapwidth
    var Mapheight
      ;
    let dpi = this.addPresentationFormGroup.get('addDpi').value;
    console.log(this.orientation, 'before orie')
    this.presentationPageSizes.map((x) => {
      if (x.name === this.addPresentationFormGroup.get('addPageSize').value) {
        //  pdfheight =  orientation === 'Portrait'
        //   ? (x.value[1] / 25.4)
        //   : (x.value[0] / 25.4);
        // pdfwidth = orientation === 'Portrait'
        //   ? (x.value[0] / 25.4)
        //   : (x.value[1] / 25.4);

        if (this.orientation === 'Portrait') {
          pdfwidth = (x.value[0] / 25.4)

          pdfheight = (x.value[1] / 25.4)
          console.log('in portrait')
        }
        if (this.orientation === 'Landscape') {
          pdfwidth = (x.value[1] / 25.4)
          pdfheight = (x.value[0] / 25.4)
          console.log('in land')
        }
        orientation = this.orientation;
        console.log(this.orientation, x, 'orientation 1st')
        console.log(pdfwidth, pdfheight, dpi, orientation, x, orientation === 'Portrait', 'check values');
        console.log(x.name, 'paper to print')
      }
      console.log(x.value[0], x.value[1], 'pdf heights check')

    });
    console.log(this.orientation, 'orientation 2nd')

    console.log(pdfwidth, pdfheight, dpi, 'check values');

    const width = Math.round(pdfwidth * Number(dpi));
    const height = Math.round(pdfheight * Number(dpi));

    const c_width = width / 25.4;
    const c_height = height / 25.4;

    console.log(width, height, 'after dpi added width and height');

    const exportOptions = {
      useCORS: true,
      ignoreElements: function (element) {
        const className = element.className || '';
        return (
          className.includes('ol-control') &&
          !className.includes('ol-scale') &&
          (!className.includes('ol-attribution') ||
            !className.includes('ol-uncollapsible'))
        );
      },
    };


    console.log(this.viewPort, "checkdpivalue")
    let captureScale = this.value / 1000
    const width1 = Math.round((pdfwidth * Number(dpi)));
    const height1 = Math.round((pdfheight * Number(dpi)))
    const viewResolution1 = this.printMap.getView().getResolution();

    const scaleResolution =
      captureScale /
      getPointResolution(
        this.printMap.getView().getProjection(),
        Number(dpi) / 25.4,
        this.printMap.getView().getCenter()
      );
    console.log(Number(dpi), captureScale, width1, height1, viewResolution1, scaleResolution, "checkdatafrom map")


    console.log(c_width, c_height, 'after c added width and height');
    var printstyle = document.getElementsByClassName('print-map')[0];




    if (Number.isNaN(parseInt(printstyle['style'].width))) {
      Mapwidth = parseInt(printstyle['style'].maxWidth);
      Mapheight = parseInt(printstyle['style'].maxHeight);
    }
    else {
      Mapwidth = parseInt(printstyle['style'].width);
      Mapheight = parseInt(printstyle['style'].height);
      // const printSize = [1200, 800];
    }

    const size = [Mapwidth, Mapheight];
    console.log(this.divSize, 'check me...');
    if (this.divSize === undefined) {
      console.log('i am indise ...');
      this.divSize = [Mapwidth, Mapheight];
    }
    console.log(this.divSize, 'check divsize constant');
    const viewResolution = this.printMap.getView().getResolution();

    console.log(size, viewResolution, 'check size and resolution of map');
    console.log(this.printMap, 'check olmap');

    // this.printMap.once('rendercomplete', function () {
    console.log('in the loop of render');
    console.log(this.activatedLogoChange, 'check status of logo chnage');
    console.log(this.logoChange, 'check the obj of logochange');
    var checkLogoChange = this.activatedLogoChange;
    var navigateElement = navigate;
    var mapDivElement = mapmini;
    // console.log(this.legendDiv.nativeElement,"check me here")

    var showLegend = this.showLegend;
    var upload_image = this.checkUpload;
    console.log(upload_image, 'check the upload image condition');
    var logoChangeObj = this.logoChange;
    var divSiZE = this.divSize;
    var square_print = this.squareToPrint
    var circle_print = this.circleToPrint
    var triangle_print = this.triangleToPrint
    var textbox_print = this.textboxToPrint

    if (showLegend === true) {
      var legendDivElement = legend.nativeElement;
    }
    if (upload_image === true) {
      var imageDivElement = imageDiv.nativeElement;
    }
    if (this.OverallArray.length > 0) {
      var overall_pdf = this.OverallArray

    }
    if (circle_print === true) {
      var circle_pdf = this.circlearray
    }
    if (triangle_print === true) {
      var triangle_pdf = this.triangleArray
    }
    if (textbox_print === true) {
      var textbox_pdf = this.textArray
    }

    console.log(overall_pdf, "overall_pdf1")
    // console.log(square_pdf, 'square added in pdf 1st step')
    this.printMap.once('rendercomplete', function () {
      console.log(overall_pdf, "overall_pdf2")
      console.log(navigateArrayPosition, 'check inside the render complete');
      console.log(navigateElement, 'checking inner loop value of navigate');
      printstyle = document.getElementsByClassName('print-map')[0];
      if (Number.isNaN(parseInt(printstyle['style'].width))) {
        Mapwidth = parseInt(printstyle['style'].maxWidth);
        Mapheight = parseInt(printstyle['style'].maxHeight);
      }
      else {
        Mapwidth = parseInt(printstyle['style'].width);
        Mapheight = parseInt(printstyle['style'].height);
        // const printSize = [1200, 800];
      }


      const mapCanvas = document.createElement('canvas');
       mapCanvas.width = Mapwidth; // apply DPI to width
      mapCanvas.height = Mapheight; // apply DPI to height
      const mapContext = mapCanvas.getContext('2d');
      console.log(mapCanvas.width, mapCanvas.height, "mapcanvaswidthheight")

      console.log(mapCanvas.width, mapCanvas.height, printstyle['style'].maxHeight, mapCanvas.width, mapCanvas.height, "mapcanvaswh")
      Array.prototype.forEach.call(
        document.querySelectorAll(
          '.print-map .ol-viewport .ol-layers .ol-layer canvas'
        ),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity = parseFloat(canvas.parentNode.style.opacity || 1);
            mapContext.globalAlpha = opacity;
            const transform = canvas.style.transform;
            const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
            mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
            mapContext.drawImage(canvas, 0, 0);

          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      // if(orientation === 'Portrait'){
      html2canvas(navigateElement, { backgroundColor: null })
        .then((canvasEle) => {
          console.log(divSiZE, orientation, 'check div sie inside');

          //minimized width and height

          const new_width =
            orientation === 'Portrait'
              ? divSiZE[0] * 0.0104166667
              : divSiZE[1] * 0.0104166667;
          const new_height =
            orientation === 'Portrait'
              ? divSiZE[1] * 0.0104166667
              : divSiZE[0] * 0.0104166667;
          var mainmapwidthinches = Mapwidth * 0.0104166667 * 1.649;//1.649 is the pdf width by map width ratio
          var mainmapheightinches = Mapheight * 0.0104166667 * 1.649;//1.649 is the pdf width by map width ratio
          console.log(new_width, new_height, 'check new width and new height ');
          console.log(pdfwidth, pdfheight, 'old width and old height');

          //convert px to inches for navigation element width and height
          const map_width_inches = mapDivElement.offsetWidth * 0.0104166667;
          const map_height_inches = mapDivElement.offsetHeight * 0.0104166667;

          console.log(
            mapDivElement.offsetWidth,
            mapDivElement.offsetHeight,
            map_width_inches,
            map_height_inches,
            'mapdiv width and height'
          );

          //calculate original width and height for navigation element
          const original_width_mapElement =
            mainmapwidthinches;
          const original_height_mapElement =
            mainmapheightinches;

          console.log(
            original_width_mapElement,
            original_height_mapElement,
            'original width and height of print map'
          );

          //convert px to inch for position_x and position_y
          const map_position_x_inches =
            printMapArrayPosition.nav_x * 0.0104166667;
          const map_position_y_inches =
            printMapArrayPosition.nav_y * 0.0104166667;

          console.log(
            map_position_x_inches,
            map_position_y_inches,
            'positions width and height'
          );

          //calculate original position_x and position_y width and heights
          const map_original_position_x =
            (pdfheight * map_position_x_inches) / new_height;
          const map_original_position_y =
            (map_position_y_inches * pdfwidth) / new_width;

          console.log(
            map_original_position_x,
            map_original_position_y,
            original_width_mapElement,
            original_height_mapElement,
            'check printmap coordinates'
          );



          if (orientation === 'Portrait') {
            console.log(orientation, 'orientation in portrait'),
              pdf = new jsPDF('p', 'in', [pdfwidth, pdfheight]);
            // pdf.addImage(
            //   mapCanvas.toDataURL('image/jpeg', 2.0),
            //   'JPEG',
            //   map_original_position_x,
            //   map_original_position_y,
            //   original_width_mapElement,
            //   original_height_mapElement

            // ); console.log(map_original_position_x,
            //   map_original_position_y,
            //   original_width_mapElement,
            //   original_height_mapElement, 'map canvas element ')

            // const dpi = 800; // set the desired DPI
            // const scaleFactor = dpi / 72; // calculate the scale factor based on the desired DPI and default DPI (72)

            // // scale up the canvas by the scale factor
            // pdf.scale(scaleFactor, scaleFactor);
          } else {
            console.log(orientation, 'orientation in landscape'),
              pdf = new jsPDF('l', 'in', [pdfwidth, pdfheight]);
            // pdf.addImage(
            //   mapCanvas.toDataURL('image/jpeg', 2.0),
            //   'JPEG',
            //   map_original_position_x,
            //   map_original_position_y,
            //   original_width_mapElement,
            //   original_height_mapElement
            // );
            // // pdf.addImage(
            // //   canvasEle.toDataURL('image/png',2.0),
            // //   'JPEG',
            // //   original_position_x,
            // //   original_position_y,
            // //   original_width_navElement,
            // //   original_height_navElement
            // // );
            // console.log(map_original_position_x,
            //   map_original_position_y,
            //   original_width_mapElement,
            //   original_height_mapElement, 'map canvas element in landscape ')
          }
          if (overall_pdf) {
            console.log(overall_pdf, 'circle in before1')
            // const mapObj = { printcode: mapCanvas };
            // // push the new object to the overall_pdf array
            // overall_pdf.push(mapObj);
            var results = []; // create an array to store the results
            this.count = 0;
            const promises = overall_pdf.map(overallshapes => {
              console.log(overallshapes, "overallshapes")
              this.count++


              return html2canvas(overallshapes.printcode, { backgroundColor: null });
            });
           
            // 
            console.log(promises, 'promises get')
            return Promise.all(promises)
            
          }
        })


        .then(squareCanvas => {
         
          console.log('entering square canvas')
          if (overall_pdf) {
            //  this.circleCanvas1 =html2canvas(circle_pdf[0].circlecode, { backgroundColor: null })
            // circleCanvas= this.circleCanvas1
            console.log('entering square canvas')
            //   for(let i=0; i<overall_pdf.length; i++){
            //     if(overall_pdf[i].id == 'map-mini'){
            //       var inchWFactor = (pdfwidth)/ (overall_pdf[i].printcode.offsetWidth * 0.0104166667)
            // var inchHFactor = (pdfheight)/ (overall_pdf[i].printcode.offsetHeight * 0.0104166667)
            //     }
            //   }
            var printstyle1 = document.getElementsByClassName('print-map')[0];

            var Mapwidth1 = parseInt(printstyle1['style'].maxWidth);
            var Mapheight1 = parseInt(printstyle1['style'].maxHeight);
            //  else
            //  {  Mapwidth = parseInt(printstyle1['style'].width);
            //   Mapheight = parseInt(printstyle1['style'].height);
            //    // const printSize = [1200, 800];
            //  }
            var inchWFactor = (pdfwidth) / (Mapwidth1 * 0.0104166667)
            var inchHFactor = (pdfheight) / (Mapheight1 * 0.0104166667)

            for (let i = 0; i < overall_pdf.length; i++) {
              console.log(this.squareAdded, 'square in 2nd step')
              // return html2canvas( circle_pdf[i].circlecode, { backgroundColor: null });
              //minimized width and height

              const new_width = Mapwidth1 / 96

              const new_height = Mapheight1 / 96


              //convert px to inches for navigation element width and height
              const square_width_inches =
                // overall_pdf[i].printcode.offsetWidth * 0.0104166667 * inchWFactor;
                overall_pdf[i].printcode.offsetWidth * 0.0104166667 * inchWFactor;

              const square_height_inches =
                overall_pdf[i].printcode.offsetHeight * 0.0104166667 * inchHFactor;


              console.log(square_width_inches, square_height_inches, inchWFactor, inchHFactor, 'for all inches')

              //calculate original width and height for navigation element
              const original_width_squareElement =
                square_width_inches;
              const original_height_squareElement =
                square_height_inches;

              console.log(original_width_squareElement, original_height_squareElement, 'original properties')

              if (overall_pdf[i].position !== undefined) {
                //convert px to inch for position_x and position_y
                const square_position_x_inches =
                  overall_pdf[i].position.nav_x * 0.0104166667;
                const square_position_y_inches =
                  overall_pdf[i].position.nav_y * 0.0104166667;
                console.log(square_position_x_inches, square_position_y_inches, 'new x and y of circle')


                //calculate original position_x and position_y width and heights
                const square_original_position_x =
                  (pdfheight * square_position_x_inches) / new_height;
                const square_original_position_y =
                  (square_position_y_inches * pdfwidth) / new_width;

                if (orientation === 'Portrait') {
                  console.log(pdfwidth, square_position_y_inches, new_width, "checkid")
                  var countSquares = 0
                  // pdf = new jsPDF('p','in', [pdfwidth , pdfheight  ]);
                  {
                    pdf.addImage(


                      squareCanvas[i].toDataURL('image/png'),
                      'JPEG',
                      square_original_position_x,
                      square_original_position_y,
                      original_width_squareElement,
                      original_height_squareElement
                    );
                  }
                  console.log(pdf, "checkpdf")

                } else {

                  // pdf = new jsPDF('l','in', [pdfwidth , pdfheight  ]);
                  pdf.addImage(
                    squareCanvas[i].toDataURL('image/png'),
                    'JPEG',
                    square_original_position_x,
                    square_original_position_y,
                    original_width_squareElement,
                    original_height_squareElement
                  );

                }
                var showloading = document.getElementById("loading")
                showloading.style.display = "none"
                var enableprint = true
                console.log("printcompleted")
                PrintToolComponent.printCount = 0;
                if (this.orientation === 'Portrait') {
                  var printhtml=document.getElementById('map-mini')
                  $('.print-map').css({
                    resize: 'both',
                   'max-width': `${495}px`,
                   overflow: 'hidden',
                   'max-height': `${640}px`,
                   width:`${495}px`,
                   height: `${640}px`,
                   // resize: 'both',
                   // 'max-width': `${parseInt(printhtml['style'].maxWidth)}px`,
                   // overflow: 'hidden',
                   // 'max-height': `${parseInt(printhtml['style'].maxHeight)}px`,
                   // width:`${parseInt(printhtml['style'].maxWidth)}px`,
                   // height: `${parseInt(printhtml['style'].maxHeight)}px`,
                 });
                 this.printMap.setSize([parseInt(printhtml['style'].maxWidth),parseInt(printhtml['style'].maxHeight)]);
                }
                if  (this.orientation === 'Landscape') {
                  var printhtml=document.getElementById('map-mini')
                  $('.print-map').css({
                    resize: 'both',
                    'max-width': `${640}px`,
                    overflow: 'hidden',
                    'max-height': `${495}px`,
                    width:`${640}px`,
                    height: `${495}px`,
                  });
                  this.printMap.setSize(parseInt(printhtml['style'].maxHeight),parseInt(printhtml['style'].maxWidth));
                }
                console.log(
                  square_original_position_x,
                  square_original_position_y,
                  original_width_squareElement,
                  original_height_squareElement,
                  'image upload coordinates'
                );
              }


            }

          }
          mapCanvas.width = 495; // apply DPI to width
          mapCanvas.height = 640;
        }
        )

        .then(() => {
          if (orientation === 'Portrait') {
            // pdf = new jsPDF('p','in', [pdfwidth , pdfheight  ]);
            const obj = {
              pdf: pdf,
              pdf_dimensions: [pdfwidth, pdfheight],
              size: divSiZE,
              dpi: dpi,
              positionArray: logoArrayPosition,
              orientation: orientation,
              status: checkLogoChange,
              coordinates: logoChangeObj,

            };
            // PrintToolComponent.legendGenerate(legendDiv)
            PrintToolComponent.logoGenerate(obj, mapLogo);
          } else {
            // pdf = new jsPDF('l','in', [pdfwidth , pdfheight  ]);
            const obj = {
              pdf: pdf,
              pdf_dimensions: [pdfwidth, pdfheight],
              size: [divSiZE[1], divSiZE[0]],
              dpi: dpi,
              positionArray: logoArrayPosition,
              orientation: orientation,
              status: checkLogoChange,
              coordinates: logoChangeObj,

            };
            // PrintToolComponent.legendGenerate(legendDiv)
            PrintToolComponent.logoGenerate(obj, mapLogo);
          }
        });
      // }


      console.log(this.printMap, ' controles')
      let scaleLineControl;
      if(this.printMap !== undefined) {
        scaleLineControl = this.printMap.getControls().getArray().find(control => control instanceof ScaleLine);
      }

      // Set the DPI value of the ScaleLine control
      if (scaleLineControl) {
        scaleLineControl.setDpi();
      }
      ScaleLine.setDpi();
      this.printMap.getTargetElement().style.width = '';
      this.printMap.getTargetElement().style.height = '';
      this.printMap.updateSize();
      this.printMap.getView().setResolution(scaleResolution);
      
    });

    setTimeout(() => {
      var widthM;
      var heightM
      const printstyle = document.getElementsByClassName('print-map')[0]
      if (Number.isNaN(parseInt(printstyle['style'].width))) {
        if (Number.isNaN(parseInt(printstyle['style'].width))) {
          widthM = parseInt(printstyle['style'].maxWidth);
          heightM = parseInt(printstyle['style'].maxHeight);
        }
      }
      else {
        widthM = parseInt(printstyle['style'].width)
        heightM = parseInt(printstyle['style'].height)


        // const printSize = [1200, 800];
      }
      const printSize = [widthM, heightM];
      console.log(printSize, printstyle, "checkprintsize");

      this.printMap.setSize(printSize);

      const size = this.printMap.getSize();
      const viewResolution = this.printMap.getView().getResolution();
      const scaling = Math.min((widthM) / size[0], (heightM) / size[1]);

      console.log(size[0], "checkscaling");
      this.printMap.getView().setResolution(viewResolution / scaling);

      var extent = this.printMap.getView().calculateExtent();
      this.printMap.getView().fit(extent);
      
    },);


  }

  // recalculate_preview(timer, size) {
  //   console.log(timer, 'milliseconds allocated')
  //   setTimeout(() => {
  //     const popupExtent = this.basemap
  //       .getView()
  //       .calculateExtent([size[0], size[1]]);
  //     this.printMap.getView().fit(popupExtent);
  //   }, timer);
  // }

  static logoGenerate(obj, mapLogo) {
    var img = new Image();
    img.src = 'assets/svgs/properties_icon/new/logo_bg.png';
    const pdf = obj.pdf;
    //here we are getting the logoElement width & height of the minimized version page sizes now we should transform into original width & heights

    //formula -- in inches
    // original_width : 8.26, original_height : 11.69
    // minimized_width : 4.71, minimized_height : 6.66

    //old_width = (old_height * new_width) / new_height   example: (11.69 * 4.71) / 6.66 = 8.26 ->here always new_width would be current div width
    //old_height = (new_height * old_width) / new_width   example: (6.66 * 8.26) / 4.71 = 11.69 -> here always new_height would be current div height
    //minimized width and height
    var printstyle = document.getElementsByClassName('print-map')[0];

    const new_width = parseInt(printstyle['style'].maxWidth) * 0.0104166667;
    const new_height = parseInt(printstyle['style'].maxHeight) * 0.0104166667;

    //convert px to inches for logo element width and height
    const width_inches = mapLogo.offsetWidth * 0.0104166667;
    const height_inches = mapLogo.offsetHeight * 0.0104166667;

    const pdfwidth = obj.pdf_dimensions[0];
    const pdfheight = obj.pdf_dimensions[1];

    //calculate original width and height for logo element
    const original_width_logoElement = (pdfheight * width_inches) / new_height;
    const original_height_logoElement = (height_inches * pdfwidth) / new_width;

    console.log(obj.positionArray, 'check inside it');
    console.log(pdfwidth, original_width_logoElement, (pdfheight * width_inches), obj.size[1], "checkwidths")
    if (obj.positionArray === undefined) {
      if (obj.orientation === 'Portrait') {
        pdf.addImage(
          img.src,
          'png',
          pdfwidth - original_width_logoElement,
          pdfheight - original_height_logoElement,
          original_width_logoElement,
          original_height_logoElement
        );
      } else {
        pdf.addImage(
          img.src,
          'png',
          pdfheight - original_width_logoElement,
          pdfwidth - original_height_logoElement,
          original_width_logoElement,
          original_height_logoElement
        );
      }
      var timestamp = Math.floor(Date.now() / 1000);
      console.log(timestamp, 'check time stamp...');
      var filename = `${timestamp}` + '.pdf';
      pdf.save(filename);
    }

    if (obj.positionArray != undefined) {
      //convert px to inch for position_x and position_y
      const position_x_inches = obj.positionArray.nav_x * 0.0104166667;
      const position_y_inches = obj.positionArray.nav_y * 0.0104166667;

      //calculate original position_x and position_y width and heights
      const original_position_x = (pdfheight * position_x_inches) / new_height;
      const original_position_y = (position_y_inches * pdfwidth) / new_width;
      console.log(original_position_x,
        original_position_y,
        original_width_logoElement,
        original_height_logoElement, "checkwidths")
      if (obj.orientation === 'Portrait') {
        pdf.addImage(
          img.src,
          'png',
          original_position_x,
          original_position_y,
          original_width_logoElement,
          original_height_logoElement
        );
      } else {
        pdf.addImage(
          img.src,
          'png',
          original_position_x,
          original_position_y,
          original_width_logoElement,
          original_height_logoElement
        );
      }

      var timestamp = Math.floor(Date.now() / 1000);
      console.log(timestamp, 'check time stamp...');
      var filename = `${timestamp}` + '.pdf';
      pdf.save(filename);
    }
    console.log('came into logo generate')
    // }
    // else{
    //   // pdf.addImage(img.src, 'png',  )
    //   var timestamp = Math.floor(Date.now() / 1000)
    //   console.log(timestamp,"check time stamp...")
    //   var filename = `${timestamp}` + '.pdf';
    //   pdf.save(filename);
    // }
  }

  static legendGenerate(legendDiv) {
    console.log(legendDiv, 'check the div element');
  }

  // static rotateNGenerate(obj, navigateN) {
  //   const pdf = obj.pdf;
  //   console.log(obj, 'check obj');

  //   // var calculated_width;
  //   // var calculated_height;
  //   var element = document.getElementById('navigateArrow');
  //   var element2 = element
  //   element2.style.width = "60px"
  //   html2canvas(element2).then((canvas) => {
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     console.log(contentDataURL, 'check url');
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 1, 1);
  //   });

  //   // html2canvas(navigateN).then(canvas =>{
  //   //   const imageURL = canvas.toDataURL('image/png')
  //   //   console.log(imageURL,"check image")
  //   //   return imageURL
  //   // }).then(value=>{
  //   //   calculated_width = navigateN.offsetWidth *  0.0104166667 * obj.dpi / 96
  //   //   calculated_height = navigateN.offsetHeight *  0.0104166667 * obj.dpi / 96

  //   //  console.log(calculated_width,calculated_height, "check the width and height")

  //   //   console.log(value,"check output")
  //   //   pdf.addImage(value, 'PNG' , 0 , 0, calculated_width, calculated_height)
  //   // })
  // }

  // checkButtonFunction(e){
  //   var blob = URL.createObjectURL(e.target.files[0])
  //   this.uploadedImage = this.sanitizer.bypassSecurityTrustUrl(blob)
  //   this.checkUpload = true
  //   setTimeout(() => {
  //     this.upload_move = document.querySelector(".upload-move")
  //          //image move function
  //          this.upload_move.addEventListener("mousedown", ()=>{

  //            this.upload_move.addEventListener("mousemove", ()=>{
  //              $(".upload-move").draggable({containment: "parent" })
  //            })

  //          this.upload_move.addEventListener("mousedown", ()=>{
  //            this.upload_move.removeEventListener("mousemove", ()=>{
  //              $(".upload-move").draggable({containment: "parent" })
  //            })
  //          })
  //        })

  //       this.upload_move.addEventListener("mouseenter", ()=>{
  //         console.log("i am hiting")
  //             var a_tag = document.createElement('a')
  //             a_tag.setAttribute("class","ol-popup-closer")
  //             a_tag.setAttribute("id","checkUpload")
  //             a_tag.style.position = "relative"
  //             // a_tag.style.marginTop = "-0.5rem"
  //             // a_tag.style.marginRight = '-1rem'
  //             a_tag.style.top = "0%"
  //             a_tag.style.right = "0%"
  //             a_tag.addEventListener("click", ()=>{
  //              console.log("i got clieckd")
  //              this.checkUpload = false
  //             //  this.addPresentationFormGroup.controls['addCredit'].setValue('')
  //           });
  //             this.upload_move.appendChild(a_tag)
  //         });

  //         this.upload_move.addEventListener("mouseleave", ()=>{
  //           this.upload_move.removeChild(document.getElementById("checkUpload"));
  //       });

  //   }, 1000);
  // }

  // checkButtonFunction(e){
  //   const file = e.target.files[0];
  //   const blob = URL.createObjectURL(file);
  //   this.uploadedImage = this.sanitizer.bypassSecurityTrustUrl(blob);
  //   this.checkUpload = true;
  //   setTimeout(() => {
  //       const uploadMove = document.querySelector(".upload-move");
  //       uploadMove.addEventListener("mousedown", () => {
  //           uploadMove.addEventListener("mousemove", () => {
  //               $(".upload-move").draggable({ containment: "parent" });
  //           });
  //           uploadMove.addEventListener("mouseup", () => {
  //               uploadMove.removeEventListener("mousemove", () => {
  //                   $(".upload-move").draggable({ containment: "parent" });
  //               });
  //           });
  //       });

  //       uploadMove.addEventListener("mouseenter", () => {
  //           const aTag = document.createElement("a");
  //           aTag.classList.add("ol-popup-closer");
  //           aTag.id = "checkUpload";
  //           aTag.style.position = "relative";
  //           aTag.style.top = "0%";
  //           aTag.style.right = "0%";

  //           // aTag.style.marginTop = '-5rem'
  //           aTag.addEventListener("click", () => {
  //               this.checkUpload = false;
  //           });
  //           uploadMove.appendChild(aTag);
  //       });

  //       // uploadMove.addEventListener("mouseleave", () => {
  //       //     uploadMove.removeChild(document.getElementById("checkUpload"));
  //       // });
  //   }, 1000);
  // }

  checkButtonFunction(e) {
    const file = e.target.files[0];
    const timestamp = Date.now(); // generate a timestamp
    const blob = URL.createObjectURL(file);
    this.uploadedImage = this.sanitizer.bypassSecurityTrustUrl(blob);
    this.checkUpload = true;
    setTimeout(() => {
      this.imagecode = document.querySelector('.upload-move')
      this.imagecode['style'].display = 'block'
for(let i=0;i<this.OverallArray.length;i++){
  if(this.OverallArray[i].id=="upload-move"){
    console.log("i am in danger")
    this.OverallArray.splice(i,1)
  }
}
      console.log(this.imagecode['style'], "display")

      this.imagedetailsforprint = {
        id: "upload-move",
        position: { nav_x: 345, nav_y: 0 },
        printcode: this.imagecode,
        zindex: 11

      }
      this.OverallArray.push(this.imagedetailsforprint)

    }, 1000);
  }
  imageDeleteButton() {
    // create cross button element
    const imageElement = document.querySelector('.image-div');
    // var printEle = document.getElementById('print-style');
    // imageElement.addEventListener(
    //   'click',
    //   (e) => {
    //     console.log(this.mapDiv.nativeElement.offsetWidth ,e,"this.imagecode")
    //     imageElement['style'].maxWidth = `${this.mapDiv.nativeElement.offsetWidth - e.['offsetX']
    //     }px`;
    //     imageElement['style'].maxHeight = `${this.mapDiv.nativeElement.offsetHeight -e['offsety']
    //     }px`;
    //   })

    // imageElement['style'].resize = 'both'
    var removeImage = document.createElement('img');
    removeImage.setAttribute('class', `cross-button`);
    removeImage.setAttribute(
      'src',
      '../../assets/svgs/new/cross_icon.svg'
    );

    removeImage.classList.add('.cross-button');
    // crossBtn.innerText = 'Close';
    removeImage.style.position = 'absolute';
    removeImage.style.top = '0';
    removeImage.style.right = '0';
    removeImage.style.zIndex = `${this.zindexvalue + 1}`;
    imageElement.appendChild(removeImage);

    console.log(imageElement, "imageElement")
    // removeImage.addEventListener('click', (e) => {
    //   const squareelement=document.getElementById(e.target['id'])
    //   console.log(e,squareelement,"i am in remove")
    //   for (let i = 0; i < this.OverallArray.length; i++) {
    //     console.log(e.target['id'], this.OverallArray[i].id, e, 'checktriangleaftersplice')
    //     if ((e.target['id']) == this.OverallArray[i].id) {
    //       this.OverallArray.splice(i, 1)
    //     }

    //   }
    //   setTimeout(() => {
    //     this.showshapeeditmenue = false;
    //     this.showshapeeditmenue2 = false;
    //   }, 10);
    //   // console.log(divEle,"divEle")
    //   // // // Remove the div element from the DOM
    //   // squareelement.parentNode.removeChild(imageTag);
    //   squareelement.style.display="none"

    // });

    removeImage.style.display = 'block';


    imageElement.addEventListener('mouseleave', () => {
      removeImage['style'].display = 'none';
    });
    // console.log(imageElement, 'imagggeee')
    // var removeImage = document.createElement('img');
    // removeImage.setAttribute('class', `cross-button`);
    // removeImage.setAttribute(
    //   'src',
    //   '../../assets/svgs/new/cross_icon.svg'
    // );
    // console.log('mouse entered');
    // removeImage.classList.add('.cross-button');
    // // crossBtn.innerText = 'Close';
    // removeImage.style.position = 'absolute';
    // removeImage.style.top = '0';
    // removeImage.style.right = '0';
    // imageElement.appendChild(removeImage);

    removeImage.addEventListener('click', () => {
      // Remove the div element from the DOM
      imageElement['style'].display = 'none';
      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == "upload-move") {
          this.OverallArray.splice(i, 1);
        }
      }
    });

    // removeImage.style.display = 'block';
    // ;

    // imageElement.addEventListener('mouseleave', () => {
    //   removeImage['style'].display = 'none';
    // });
  }

  onLegendOptionSelected(legend) {
    var team = this;
    this.legendcoloractive = !this.legendcoloractive
    this.countlegendbutton++;
    if (this.countlegendbutton == 1) {
      this.legendvalue = 'Show';
    }
    if (this.countlegendbutton == 2) {
      this.legendvalue = 'Hide';
      this.countlegendbutton = 0;
    }
    console.log(legend, 'check legend');
    if (this.legendvalue === 'Show') {
      this.showLegend = true;
    } else {
      this.showLegend = false;
    }
    setTimeout(() => {
      var legenddiv = document.getElementsByClassName('legendBox')[0];
      this.legendMove = document.querySelector('.legendBox');
      this.legenddiv = document.getElementById('legendboxdiv');
      const position = this.legenddiv.getBoundingClientRect();
      // const positionLegend = this.legenddiv.getBoundingClientRect();
      console.log(this.legenddiv, this.legendMove, legenddiv, 'legend div')
      if (this.countlegendbutton == 1) {
        console.log('hi in legend')
        console.log(this.legenddiv, this.legendMove, legenddiv, 'legend div')
        this.initialObj5 = {
          nav_x: position.top,
          nav_y: position.left,
        };
        this.legendArrayTemp = {
          id: this.legenddiv.id,
          position: this.initialObj5,
          zindex: parseInt(this.legenddiv.style.zIndex)
        }
        this.OverallArray.push(this.legendArrayTemp);
        console.log(this.OverallArray, 'overall legend 1')
        console.log(this.legendArrayTemp, "legendarray 1")
        this.legenddiv.addEventListener('mousemove', (e) => {
          var Allarray = this.OverallArray
          // console.log(positionLegend, 'boundaries 1' )

          if (e['altKey'] === true) {
            $('.legendBox').draggable({
              containment: 'parent',
              stop: function (event, ui) {
                console.log(ui.helper[0].id, Allarray, 'get the ui elements inside legend');
                const x = ui.position.left;
                const y = ui.position.top;
                const obj = {
                  nav_x: x,
                  nav_y: y,
                };
                for (let i = 0; i < Allarray.length; i++) {
                  if (ui.helper[0].id == Allarray[i].id) {
                    Allarray[i].position = obj
                  }
                }
                team.legendPosition.next(`${JSON.stringify(obj)}`);
                const printEle = document.getElementById('print-style');
                const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
                const divBounds = legenddiv.getBoundingClientRect(); // get the bounding rectangle of the divEle element
                const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
                const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
                console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
                legenddiv['style'].maxWidth = ` ${maxAllowedWidth}px`; // set the maximum allowed width
                legenddiv['style'].maxHeight = ` ${maxAllowedHeight}px`; // set the maximum allowed height
                console.log(divBounds, e, e.srcElement['id'], "checkdivbound")

              },
            });
            this.OverallArray = Allarray
          } else if (e['altKey'] === false) {
            $('.legendBox').draggable('destroy');
          }
          team.legendPosition.subscribe((val) => {
            console.log(JSON.parse(val), 'check if the values exist in legend');
            this.storeLegendPosition.push(JSON.parse(val));
          });
          setTimeout(() => {
            for (let i = 0; i < this.OverallArray.length; i++) {
              console.log(e.srcElement['id'] == this.OverallArray[i].id, this.OverallArray[i].id, this.storeLegendPosition, this.OverallArray[i].position, 'torf')
              if (e.srcElement['id'] == this.OverallArray[i].id) {
                console.log("i amin legend")
                this.OverallArray[i].position = this.storeLegendPosition[this.storeLegendPosition.length - 1]
                console.log(this.OverallArray[i].position, "overall")
              }
            }
          }, 1000);



          console.log(this.OverallArray, "overall")
        })
        // var removeImage = document.createElement('img');
        // removeImage.setAttribute('class', `cross-button`);
        // removeImage.setAttribute('src', '../../assets/svgs/new/cross_icon.svg');
        // this.legendMove.addEventListener('mouseenter', () => {
        //   console.log('mouse entered');
        //   removeImage.classList.add('.cross-button');
        //   // crossBtn.innerText = 'Close';
        //   removeImage.style.position = 'absolute';
        //   removeImage.style.top = '0';
        //   removeImage.style.right = '0';
        //   this.legendMove.appendChild(removeImage);

        //   removeImage.addEventListener('click', (e) => {
        //     // Remove the div element from the DOM
        //     for(let i=0;i<this.OverallArray.length;i++){
        //       if((e.target['id'])==this.OverallArray[i].id){
        //         this.OverallArray.splice(i,1)
        //       }
        //     }
        //     this.legendMove.parentNode.removeChild(this.legendMove);
        //   });

        //   removeImage.style.display = 'block';
        // });

        // this.legendMove.addEventListener('mouseleave', () => {
        //   removeImage['style'].display = 'none';
        // });
        // console.log(this.OverallArray,"overall")

      }
    }, 1000);
  }
  //onMoveEvent
  legendMoveEvent(e) {
    var legenddiv = document.getElementsByClassName('legendBox')[0];
    var team = this;
    this.legendMove = document.querySelector('.legendBox');
    const printEle = document.getElementById('print-style');
    this.legenddiv = document.getElementById('legendboxdiv');
    const positionLegend = this.legenddiv.getBoundingClientRect();
    const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
    const divBounds = legenddiv.getBoundingClientRect(); // get the bounding rectangle of the divEle element
    const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
    const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
    console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
    legenddiv['style'].maxWidth = ` ${maxAllowedWidth}px`; // set the maximum allowed width
    legenddiv['style'].maxHeight = ` ${maxAllowedHeight}px`; // set the maximum allowed height
    console.log(positionLegend, printBounds, divBounds, 'boundaries')

    console.log(this.OverallArray, 'overall legend')
    console.log(this.legendArrayTemp, "legendarray")
    // if (e['altKey'] === true) {
    //   $('.legendBox').draggable({
    //     containment: 'parent',
    //     stop: function (event, ui) {
    //       console.log(ui, 'get the ui elements inside legend');
    //       const x = ui.position.left;
    //       const y = ui.position.top;
    //       const obj = {
    //         nav_x: x,
    //         nav_y: y,
    //       };

    //       team.legendPosition.next(`${JSON.stringify(obj)}`);
    //       const printEle = document.getElementById('print-style');
    //              const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
    //              const divBounds = legenddiv.getBoundingClientRect(); // get the bounding rectangle of the divEle element
    //              const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
    //              const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
    //              console.log(maxAllowedWidth,maxAllowedHeight, 'max height and width' )
    //              legenddiv['style'].maxWidth =` ${maxAllowedWidth}px`; // set the maximum allowed width
    //              legenddiv['style'].maxHeight =` ${maxAllowedHeight}px`; // set the maximum allowed height
    //              console.log(divBounds,"checkdivbound")

    //     },
    //   });
    // } else if (e['altKey'] === false) {
    //   $('.legendBox').draggable('destroy');
    // }
    // team.legendPosition.subscribe((val) => {
    //   console.log(JSON.parse(val), 'check if the values exist in legend');
    //   this.storeLegendPosition.push(JSON.parse(val));
    // });
  }

  logoMoveEvent(e) {
    var team = this;
    // this.initialObj6 = {
    //   nav_x: position.top,
    //   nav_y: position.left,
    // };
    // this.legendArrayTemp={
    //   id: this.legenddiv.id,
    //   position: this.initialObj5,
    //   zindex: parseInt(this.legenddiv.style.zIndex )
    // } 
    // this.OverallArray.push(this.legendArrayTemp);
    var logoposition = this.finallogoposition
    console.log(e, 'check the event for navigation');
    if (e['altKey'] === true) {
      $('.logo-move').draggable({
        containment: 'parent',
        stop: function (event, ui) {
          console.log(ui, 'get the ui elements inside logo');
          const x = ui.position.left;
          const y = ui.position.top;
          const obj = {
            nav_x: x,
            nav_y: y,
          };
          logoposition[0].position = obj
          console.log(ui, logoposition, 'get the ui elements inside logo');

          team.logoPosition.next(`${JSON.stringify(obj)}`);
        },

      });
      this.finallogoposition = logoposition
      // this.finallogoposition=logoposition
      console.log(this.finallogoposition, logoposition, "this.finallogoposition")
    } else if (e['altKey'] === false) {
      $('.logo-move').draggable('destroy');
    }

    team.logoPosition.subscribe((val) => {
      console.log(JSON.parse(val), 'check if the values exist in logo');
      this.storeLogoArrayPosition.push(JSON.parse(val));
    });
    // this.finallogoposition=this.storeLogoArrayPosition[this.storeLogoArrayPosition.length-1]
    // for(let i=0;i<this.OverallArray.length;i++){
    //   console.log(e.srcElement['id']==this.OverallArray[i].id,this.OverallArray[i].id,this.storeLogoArrayPosition,this.OverallArray[i].position, 'torf')
    //   if(e.srcElement['id']==this.OverallArray[i].id){
    //     console.log("i amin legend")
    //     this.OverallArray[i].position=this.storeLogoArrayPosition[this.storeLogoArrayPosition.length-1]
    //     console.log(this.OverallArray[i].position,"overall")
    //   }
    // }
    this.logoView = document.querySelector('.logo-move');
    console.log(this.logoView, 'logoview')
    // var removeImage = document.createElement('img');
    // removeImage.setAttribute('class', `cross-button`);
    // removeImage.setAttribute('src', '../../assets/svgs/new/cross_icon.svg');
    // this.logoView.addEventListener('mouseenter', () => {
    //   console.log('mouse entered');
    //   removeImage.classList.add('.cross-button');
    //   // crossBtn.innerText = 'Close';

    //   this.logoView.appendChild(removeImage);
    //   console.log(this.logoView, 'logoss');

    //   removeImage.addEventListener('click', () => {
    //     // Remove the div element from the DOM
    //     this.logoView.parentNode.removeChild(this.logoView);
    //   });

    //   removeImage.style.display = 'block';
    // });

    // this.logoView.addEventListener('mouseleave', () => {
    //   removeImage['style'].display = 'none';
    // });
  }


  navigateMoveEvent(e) {
    this.navigateClass = document.querySelector('.compass-move')
    this.navigateClass.setAttribute('id', 'navigateDiv')
    this.navigateId = document.getElementById('navigateDiv')
    const position = this.navigateId.getBoundingClientRect();
    var team = this;

    if (e['altKey'] === true) {
      $('.compass-move').draggable({
        containment: 'parent',
        stop: function (event, ui) {
          console.log(ui, 'get the ui elements');
          const x = ui.position.left;
          const y = ui.position.top;
          const obj = {
            nav_x: x,
            nav_y: y,
          };
          team.navigatePosition.next(`${JSON.stringify(obj)}`);
        },
      });
    } else if (e['altKey'] === false) {
      $('.compass-move').draggable('destroy');
    }

    team.navigatePosition.subscribe((val) => {
      console.log(JSON.parse(val), 'check if the values exist');
      this.storeNavigateArrayPosition.push(JSON.parse(val));
    });
    setTimeout(() => {
      console.log(this.OverallArray.length, this.OverallArray, 'entered overall array')
      for (let i = 0; i < this.OverallArray.length; i++) {
        console.log(this.navigateId.id == this.OverallArray[i].id, this.OverallArray[i].id, this.storeNavigateArrayPosition, this.OverallArray[i].position, 'torf')
        if (this.navigateId.id == this.OverallArray[i].id) {
          console.log("i amin legend")
          this.OverallArray[i].position = this.storeNavigateArrayPosition[this.storeNavigateArrayPosition.length - 1]
          console.log(this.OverallArray[i].position, "overall")
        }
      }
    }, 1000);


    // console.log(e, 'check the event for navigation');
    // if (e['altKey'] === true) {
    //   $('.compass-move').draggable({
    //     containment: 'parent',
    //     stop: function (event, ui) {
    //       console.log(ui, 'get the ui elements');
    //       const x = ui.position.left;
    //       const y = ui.position.top;
    //       const obj = {
    //         nav_x: x,
    //         nav_y: y,
    //       };
    //       team.navigatePosition.next(`${JSON.stringify(obj)}`);
    //     },
    //   });
    // } else if (e['altKey'] === false) {
    //   $('.compass-move').draggable('destroy');
    // }

    // team.navigatePosition.subscribe((val) => {
    //   console.log(JSON.parse(val), 'check if the values exist');
    //   this.storeNavigateArrayPosition.push(JSON.parse(val));
    // });

  }
  showOrCloseArrow() {
    this.getMapProjectionUnits();
    this.showNavigationArrow = !this.showNavigationArrow;
    this.northarrowactive = !this.northarrowactive


  }

  printMapMoveEvent(e) {
    var team = this;
    var Allarray = this.OverallArray
    this.printMapId = document.getElementById('map-mini')
    console.log(this.printMapId, 'printmapinitialized')
    console.log(e, 'check the event for printmap');
    if (e['altKey'] === true) {
      $('#map-mini').draggable({
        containment: 'parent',
        stop: function (event, ui) {
          console.log(ui.helper[0].id, 'get the ui elements inside the print');
          const x = ui.position.left;
          const y = ui.position.top;
          const obj = {
            nav_x: x,
            nav_y: y,
          };

          for (let i = 0; i < Allarray.length; i++) {
            if (ui.helper[0].id == Allarray[i].id) {
              Allarray[i].position = obj
            }
          }
          console.log(Allarray, ui.helper[0].id, ui.position, "Checkid")
          team.printMapPosition.next(`${JSON.stringify(obj)}`);
        },
      });
      this.OverallArray = Allarray
      console.log(this.OverallArray, "Checkid")
    } else if (e['altKey'] === false) {
      // console.log($('.print-map').draggable('destroy'))
      //$('.print-map').draggable('destroy');
    }

    team.printMapPosition.subscribe((val) => {
      console.log(JSON.parse(val), 'check if the values exist');
      this.storePrintMapPosition.push(JSON.parse(val));
    });
    // setTimeout(() => {
    //   console.log(this.OverallArray.length, this.OverallArray, 'entered overall array')
    //   for (let i = 0; i < this.OverallArray.length; i++) {
    //     console.log(this.printMapId.id == this.OverallArray[i].id, this.OverallArray[i].id,this.printMapId.id, this.storePrintMapPosition, this.OverallArray[i].position, 'torf')
    //     if (this.printMapId.id == this.OverallArray[i].id) {
    //       console.log("i amin legend")
    //       this.OverallArray[i].position = this.storePrintMapPosition[this.storePrintMapPosition.length - 1]
    //       console.log(this.OverallArray[i].position, "overall")
    //     }
    //   }
    // }, 1000);
  }

  imageMoveEvent(e) {
    var team = this;
    var Allarray = this.OverallArray;
    console.log(e, 'check the event for image');
    if (e['altKey'] === true) {
      $('.upload-move').draggable({
        containment: 'parent',
        stop: function (event, ui) {
          console.log(ui, 'get the ui elements inside the print');
          const x = ui.position.left;
          const y = ui.position.top;
          const obj = {
            nav_x: x,
            nav_y: y,
          };
          for (let i = 0; i < Allarray.length; i++) {
            if (Allarray[i].id == "upload-move") {
              Allarray[i].position = obj
            }
          }
          team.uploadImagePosition.next(`${JSON.stringify(obj)}`);
        },
      });
      this.OverallArray = Allarray
    } else if (e['altKey'] === false) {
      $('.upload-move').draggable('destroy');
    }
    var divEle = document.querySelector('.image-div')
    const printEle = document.getElementById('print-style');
    const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
    const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
    const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
    const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
    console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
    divEle['style'].maxWidth = `${maxAllowedWidth}px`; // set the maximum allowed width
    divEle['style'].maxHeight = `${maxAllowedHeight}px`; // set the maximum allowed height  
    team.uploadImagePosition.subscribe((val) => {
      console.log(JSON.parse(val), 'check if the values exist');
      this.storeUploadImagePosition.push(JSON.parse(val));
    });
  }
  //when ever you would like to chnage the location of textarea after first time

  //follow the steps

  //click on alykey and press anyhwere to update the textarea new location

  boldCount = 0;
  updateBoldFontlegend() {
    // this.boldChanging = !this.boldChanging;
    // this.boldCountlegend++;
    // console.log(this.boldCountlegend,'clicked bold')
    // console.log('clicked bold');
    // this.legendnameselected['style'].fontWeight = 'bold';

    if (this.boldCountlegend > 0) {
      console.log(this.boldCountlegend, 'clicked bold 1')
      if (this.legendtools) {
        this.legendnameselected['style'].fontWeight = 'normal';
      }
      else {
        this.textareacontent['style'].fontWeight = 'normal';
      }
      this.boldCountlegend = 0;
    }
    else {
      this.boldCountlegend++;
      console.log(this.boldCountlegend, 'clicked bold')
      console.log('clicked bold');
      if (this.legendtools) {
        this.legendnameselected['style'].fontWeight = 'bold';
      }
      else {
        this.textareacontent['style'].fontWeight = 'bold';
      }
    }
  }
  italicCount = 0;
  updateItalicFont() {
    // this.italicChanging = !this.italicChanging;
    // this.italicCount++;
    // this.legendnameselected.style.fontStyle = 'italic';
    // this.textareacontent['style'].fontStyle = 'italic';
    if (this.italicCount > 0) {
      if (this.legendtools) {
        this.legendnameselected.style.fontStyle = 'normal';
      }
      else {
        this.textareacontent['style'].fontStyle = 'normal';
      }
      this.italicCount = 0;
    }
    else {
      this.italicChanging = !this.italicChanging;
      this.italicCount++;
      if (this.legendtools) {
        this.legendnameselected.style.fontStyle = 'italic';
      }

      else {
        this.textareacontent['style'].fontStyle = 'italic';
      }
    }
  }
  underlineCount = 0;
  updateUnderline() {

    if (this.underlineCount > 0) {
      if (this.legendtools) {
        this.legendnameselected.style.textDecoration = 'none';
      }
      else {
        this.textareacontent['style'].textDecoration = 'none';
      }
      this.underlineCount = 0;
    }
    else {
      if (this.legendtools) {
        this.legendnameselected.style.textDecoration = 'underline';
      }
      else {
        this.underlineChanging = !this.underlineChanging;

        this.textareacontent['style'].textDecoration = 'underline';
      }
      this.underlineCount++;
    }
  }
  updateColor1(event) {

    console.log(this.backcolor, "selectedlegenddiv");
    if (this.legendtools) {
      this.backcolor = event;
      this.legenddiv.style.backgroundColor = this.backcolor
    }
    if (this.istextboxselected == true) {
      this.textbackgcolor = event
      this.textareacontent['style'].backgroundColor = this.textbackgcolor;
    }

  }

  updateColor(event) {

    this.textboxfontcolor = event
    console.log(this.fontcolor, "selectedtextoflegend")
    if (this.legendheading) { this.legendheadingfontcolor = event; this.legenddivheading.style.color = this.legendheadingfontcolor; }
    if (this.selectlegendnames) { this.legendfontcolor = event; this.legendnameselected.style.color = this.legendfontcolor; }
    if (this.istextboxselected == true) {

      this.textboxfontcolor = event
      console.log(event, this.textareacontent['style'].color, "check color of text");

      this.textareacontent['style'].color = this.textboxfontcolor;

    }

  }
  selectedtextoflegend(e) {
    this.legendtools = true;


    this.showtexteditmenue = !this.showtexteditmenue;
    this.showtextsizediv = false
    this.showshapeeditmenue = false
    this.showshapeeditmenue2 = this.showtexteditmenue

    this.showdropdown = false;

    this.shapemenutoggle = false;
    this.legendheading = false;
    this.istextboxselected = false
    this.selectlegendnames = true
    this.legendnameselected = document.getElementById(e.target.id);
    console.log(this.legendnameselected.style.color, "selectedtextoflegend")

  }
  selectelegendheading(e) {
    this.legendtools = true;


    this.showtexteditmenue = !this.showtexteditmenue;
    this.showtextsizediv = false
    this.showshapeeditmenue = false
    this.showshapeeditmenue2 = this.showtexteditmenue

    this.showdropdown = false;

    this.shapemenutoggle = false;
    this.istextboxselected = false
    this.selectlegendnames = false;
    this.legendheading = true;
    this.legenddivheading = document.getElementById(e);

    console.log(e, this.legenddivheading, "selectedtextoflegend")
  }
  changeFontSize(size: string) {
    console.log(size);
    this.fontsize = Number(size);
    this.fontsizewithpx = this.fontsize + 'px';
    console.log('check fontsizee', this.fontsizewithpx);
    this.textareacontent['style'].fontSize = this.fontsize + 'px';
  }
  strokewidthvalue(e) {
    console.log('check fontsizee', e);
    this.strokesize = Number(e);
    this.strokesizewithpx = this.strokesize + 'px';
    console.log('check fontsizee', this.strokesizewithpx);
    this.textareacontent['style'].fontSize = this.fontsize + 'px';
    if (this.squaredivclicked) {
      this.squarewidthvalue = this.strokesizewithpx;
    }
    else if (this.circledivclicked) {
      this.circlewidthvalue = this.strokesizewithpx;
    }
    else if (this.triangledivclicked) {
      this.trainglewidthvalue = this.strokesizewithpx;
    }
  }
  updateStrokeColor(e) {

    if (this.legendtools) {
      this.strokecoloroflegend = e;
      this.legenddiv.style.border = '1px solid' + ' ' + this.strokecoloroflegend;
    }
    if (this.istextboxselected == true) {
      this.strokeoftxtbox = e
      this.textareacontent['style'].border = '1px solid' + ' ' + this.strokeoftxtbox
    }
  }
  strokeformatvalue(e) {
    this.trainglestrokeformat = e;
    console.log(this.trainglestrokeformat, "fontsize check")
    if (this.squaredivclicked) {
      this.squarestrokeformat = e;
    }
    else if (this.circledivclicked) {
      this.circlestrokeformat = e;
    }
    else if (this.triangledivclicked) {
      this.trainglestrokeformat = e;
    }
  }
  selectboc(e) {
    console.log(e.srcElement.className);
    const myElement = document.getElementById(e.target.id);
    console.log(myElement, 'myelement11');
    this.selectedtextfield = true;
    // this.squaredivclicked=false;
    // this.circledivclicked=false;
    // this.triangledivclicked=false;
    // console.log(myElement[0], 'myelement');
    this.textareacontent = myElement;
    this.textid = e.target.id;
    console.log('in select boc', this.showtexteditmenue);
    // if (this.addtextfieldbuttonactive == true) {
    //   this.showtexteditmenue = true;
    //   this.showshapeeditmenue2 = this.showtexteditmenue
    //   console.log('in select boc event', this.showtexteditmenue);
    // }
  }

  addTextField() {
    this.addtextfieldbuttonactive = !this.addtextfieldbuttonactive;
    if (this.shapemenueactive == true) { this.shapemenueactive = true; }
    this.shapemenutoggle = false
    this.sitemenueactive = false;
    this.basemapactive = false
    console.log(this.textBoxOnce, 'previous text')
    console.log(this.showtexteditmenue, 'testing edited menue');

    this.showdropdown = false;
    this.showshapeeditmenue = false;
    this.showshapeeditmenue2 = false;


    this.showSiteMarkerMenu = false
    var printEle = document.getElementById('print-style');
    if (this.addtextfieldbuttonactive == true) {
      printEle.addEventListener(
        'click',
        (e) => {
          this.textboxaddcount = 0
          console.log(this.fontsize);
          this.textboxToPrint = true;
          var textField = document.createElement('div');
          var textNode = document.createTextNode('edit me for customisation');
          textField.setAttribute('class', `textarea-move`);
          textField.setAttribute('id', `textarea-move${this.counttext}`);
          console.log(textField, 'added textfield');
          textField.setAttribute('contenteditable', 'true');
          this.presentTextField = `textarea-move${this.counttext}`

          console.log(this.textboxAdded, 'added textbox2')
          textField.style.background = 'transparent';
          // textField.style.backgroundColor = this.backcolor;
          textField.style.border = '1px solid black';
          textField.style.position = 'absolute';
          textField.style.paddingLeft = '2rem';
          textField.style.paddingRight = '2rem';
          textField.style.top = `${e.offsetY}px`;
          textField.style.left = `${e.offsetX}px`;
          textField.style.overflow = 'hidden';
          textField.style.resize = 'both';
          textField.style.color = this.fontcolor;
          textField.style.zIndex = `${this.zindexvalue + 1}`
          this.zindexvalue++

          setTimeout(() => {
            this.circleAdded = document.querySelector('.circle-move')
            console.log(this.circleAdded, 'added circle')
            this.initialObj4 = {
              nav_x: $('#' + this.presentTextField).position().left,
              nav_y: $('#' + this.presentTextField).position().top
            };
            console.log(this.initialObj1, "checkcircleforprinting")
            this.textArrayTemp = {
              id: `${this.presentTextField}`,
              position: this.initialObj4,
              zindex: parseInt(textField.style.zIndex)
            }
            console.log(this.circlearraytemp, "checkcircleforprinting")
            this.OverallArray.push(this.textArrayTemp)
            // this.circlearrayforprinting.push((this.presentcircle))
            // this.showcircularcode=document.getElementById(this.presentcircle)

            //  this.circlearrayforprinting.push(( this.showcircularcode))
            console.log(this.OverallArray, "overall")
          }, 1000);
          // this.showshapeeditmenue2 = this.showtexteditmenue
          // this.showtexteditmenue = false;
          // this.showshapeeditmenue2 = this.showtexteditmenue
          // this.showtextsizediv=false;
          console.log('in text field', this.showtexteditmenue);

          textField.style.maxWidth = `${this.mapDiv.nativeElement.offsetWidth - e.offsetX
            }px`;
          textField.style.maxHeight = `${this.mapDiv.nativeElement.offsetHeight - e.offsetY
            }px`;
          textField.style.fontSize = this.fontsize + 'px';
          this.addtextfieldbuttonactive = true;
          textField.addEventListener('click', (e) => {
            console.log(e, 'in textfield e');
            this.istextboxselected = true;
            this.showtexteditmenue = !this.showtexteditmenue;
            this.showtextsizediv = true
            this.showshapeeditmenue = false
            this.showshapeeditmenue2 = this.showtexteditmenue

            this.showdropdown = false;

            this.shapemenutoggle = false;

            this.showSiteMarkerMenu = false;


            ;

            if (this.legendtools) {
              this.legendtools = false
            }

            this.addtextfieldtool = this.showtexteditmenue
            console.log('in text field listener', this.showtexteditmenue);
          });
          textField.appendChild(textNode);
          printEle.appendChild(textField);
          document.body.style.cursor = 'default';


          setTimeout(() => {
            const initialObj = {
              nav_x: $('#' + this.presentTextField).position().left,
              nav_y: $('#' + this.presentTextField).position().top
            };
            this.textboxIntialPosition = initialObj

            console.log(this.textboxIntialPosition.nav_x, 'before changing values in circle')
            this.storeTextAreaPosition.push(initialObj)
            textField.addEventListener('mousemove', (e) => {
              var team = this
              var Allarray = this.OverallArray
              if (e['altKey'] === true) {
                console.log(e.target["id"], "check textbox")
                $('#' + e.target["id"]).draggable({
                  containment: "parent",
                  stop: function (event, ui) {
                    console.log(ui, "get the ui elements inside legend")
                    const x = ui.position.left;
                    const y = ui.position.top;
                    this.texteobj = {
                      nav_x: x,
                      nav_y: y
                    }
                    for (let i = 0; i < Allarray.length; i++) {
                      if (ui.helper[0].id == Allarray[i].id) {
                        Allarray[i].position = this.texteobj
                      }
                    }
                    team.textAreaPosition.next(`${JSON.stringify(this.texteobj)}`)
                    const printEle = document.getElementById('print-style');
                    const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
                    const divBounds = textField.getBoundingClientRect(); // get the bounding rectangle of the divEle element
                    const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
                    const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
                    console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
                    textField.style.maxWidth = ` ${maxAllowedWidth}px`; // set the maximum allowed width
                    textField.style.maxHeight = ` ${maxAllowedHeight}px`; // set the maximum allowed height
                    console.log(divBounds, "checkdivbound")
                  }
                })
                this.OverallArray = Allarray
              } else if (e['altKey'] === false) {
                $('#' + e.target["id"]).draggable("destroy");
              }
              team.textAreaPosition.subscribe((val) => {
                console.log(JSON.parse(val), "check if the values exist in circle")
                this.storeTextAreaPosition.push(JSON.parse(val))
                // for(let i =0;i<this.textArray.length;i++){
                //   console.log(e,this.textArray[i].id,"checkidfortext")
                //   if(e.srcElement['offsetParent'].id==this.textArray[i].id){
                //      this.textArray[i].position=JSON.parse(val)
                //      console.log( this.textArray,"checkidfortext")
                //   }
                // }

              })
              console.log(this.storeTextAreaPosition[this.storeTextAreaPosition.length - 1], "  out")
              console.log(e, e.srcElement['id'], "check square id outside")
              // for (let i = 0; i < this.OverallArray.length; i++) {
              //   if (e.srcElement['id'] == this.OverallArray[i].id) {
              //     this.OverallArray[i].position = this.storeTextAreaPosition[this.storeTextAreaPosition.length - 1]
              //   }
              // }
              console.log(this.OverallArray, "overall")


            })
            var removeImage = document.createElement('img');
            removeImage.setAttribute('id', `textarea-move${this.counttext - 1}`);
            removeImage.setAttribute('class', `cross-button`);
            removeImage.setAttribute(
              'src',
              '../../assets/svgs/new/cross_icon.svg'
            );
            textField.addEventListener('mouseenter', () => {
              console.log('mouse entered');
              removeImage.classList.add('.cross-button');
              // crossBtn.innerText = 'Close';
              removeImage.style.position = 'absolute';
              removeImage.style.top = '0';
              removeImage.style.right = '0';
              removeImage.style.zIndex = `${this.zindexvalue + 1}`;

              textField.appendChild(removeImage);

              removeImage.addEventListener('click', (e) => {
                var texthtmlelement = document.getElementById(e.target['id'])
                console.log(e, (e.target['id']), '')
                for (let i = 0; i < this.OverallArray.length; i++) {
                  console.log((e.target['id']), this.OverallArray[i].id, (e.target['id']) == this.OverallArray[i].id, 'text main')
                  if ((e.target['id']) == this.OverallArray[i].id) {
                    this.OverallArray.splice(i, 1)
                  }

                }
                setTimeout(() => {
                  this.showtexteditmenue = false;
                  this.showtextsizediv = false

                  this.showshapeeditmenue2 = this.showtexteditmenue
                }, 10);


                // Remove the div element from the DOM
                // textField.parentNode.removeChild(textField);
                texthtmlelement.style.display = "none"
              });

              removeImage.style.display = 'block';
            });

            textField.addEventListener('mouseleave', () => {
              removeImage['style'].display = 'none';
            });
            //   divEle.addEventListener('mousemove', ()=>{
            //   const crossBtn = document.querySelector(`.cross-button`);
            //   crossBtn.addEventListener('click', () => {
            //     // Remove the square element from the DOM
            //     const circle = document.getElementById(this.circleId);
            //     circle.parentNode.removeChild(circle);
            //   });
            // })
          });
          this.counttext++;

          //   divEle.addEventListener('mousemove', ()=>{
          //   const crossBtn = document.querySelector(`.cross-button`);
          //   crossBtn.addEventListener('click', () => {
          //     // Remove the square element from the DOM
          //     const square = document.getElementById(this.squareId);
          //     square.parentNode.removeChild(square);
          //   });
          // })
        },
        { once: true }
      );
      this.textBoxOnce = false;
      console.log(this.textBoxOnce, 'after 1 text')

    }
    console.log(this.textBoxOnce, 'after 2 text')

  }
  showSiteMenu() {
    this.showdropdown = false;
    this.showshapeeditmenue = false;
    this.showshapeeditmenue2 = false;
    this.shapemenutoggle = false;
    this.showtexteditmenue = false
    this.basemapactive = false
    this.sitemenueactive = !this.sitemenueactive;

    this.addtextfieldbuttonactive = false;
    this.shapemenueactive = false;
    $('.shapeMenu').css({ display: 'none' });
    this.showSiteMarkerMenu = !this.showSiteMarkerMenu;

    console.log('in show site', this.showtexteditmenue);
  }
  count = 0;
  opendropdown(): any {
    console.log('dropdown act');
    this.showdropdown = !this.showdropdown;
    this.basemapactive = !this.basemapactive

    this.showSiteMarkerMenu = false;
    this.shapemenueactive = false
    this.showdropdown = !this.showdropdown;
    this.showshapeeditmenue = false;
    this.showshapeeditmenue2 = false;
    this.shapemenutoggle = false;
    this.showtexteditmenue = false
    this.count++;
    this.showdropdown = true;
    if (this.count > 1) {
      this.showdropdown = false;
      this.count = 0;
    }
  }

  // opendropdownMiles() {
  //   this.showdropdownMiles = !this.showdropdownMiles;
  //   this.countMiles++;
  //   this.showdropdownMiles = true;
  //   if (this.countMiles > 1) {
  //     this.showdropdownMiles = false;
  //     this.countMiles = 0;
  //   }
  // }

  opendropdownPages() {
    this.showdropdownPages = !this.showdropdownPages;
    this.countPages++;
    this.showdropdownPages = true;
    if (this.countPages > 1) {
      this.showdropdownPages = false;
      this.countPages = 0;
    }
  }

  opendropdownOrientation() {
    this.showdropdownOrientation = !this.showdropdownOrientation;
    this.countOrientation++;
    this.showdropdownOrientation = true;
    if (this.countOrientation > 1) {
      this.showdropdownOrientation = false;
      this.countOrientation = 0;
    }
  }
  opendropdownDpi() {
    this.showdropdownDpi = !this.showdropdownDpi;
    this.countDpi++;
    this.showdropdownDpi = true;
    if (this.countDpi > 1) {
      this.showdropdownDpi = false;
      this.countDpi = 0;
    }
  }
  showShapeMenu() {
    this.shapemenueactive = !this.shapemenueactive;
    this.sitemenueactive = false;
    this.addtextfieldbuttonactive = false;
    this.showtexteditmenue = false;
    this.showshapeeditmenue = false;
    this.showshapeeditmenue2 = false;
    this.shapemenutoggle = true;
    this.showdropdown = false;
    this.basemapactive = false

    this.showSiteMarkerMenu = false
    // this.showshapeeditmenue this.showtexteditmenue
    console.log('in shape menu', this.showtexteditmenue);

    console.log(this.shapemenueactive, 'shaaa');
    console.log(this.shapemenutoggle, 'shaaa2');

    this.countShape++;
    console.log(this.countShape);
    if (this.countShape > 1) {
      this.showSiteMarkerMenu = false;
      $('.shapeMenu').css({ display: 'none' });
      this.countShape = 0;
    } else {
      this.showSiteMarkerMenu = false;
      $('.shapeMenu').css({
        display: 'grid',
        'margin-top': '15.2rem',
        position: 'absolute',
      });
    }
  }

  // pointerFuncClicked() {
  //   console.log('pointer clicked');
  //   this.notePadService.locatePointOrPolygonOnMap(
  //     this.notePadService.shapeDrawType.POINT,
  //     this.positionMarkObserver,
  //     this.currentContextInfo
  //   );
  // }

  // lineFuncClicked() {
  //   console.log('line clicked');
  // }

  // polygonFuncClicked() {
  //   console.log('polygon clicked');
  // }
  // drawCircle() {
  //   this.ctx.beginPath();
  //   this.ctx.arc(100, 75, 50, 0, 2 * Math.PI);
  //   this.ctx.stroke();
  //   console.log(this.ctx.stroke(), 'stroke')
  // }

  updateShapeFillColor(event) {
    this.addSquare = false;
    console.log('square at shapefill', this.addSquare);
    this.shapeFillColor = event;

    console.log(this.shapeFillColor, this.squaredivclicked, 'shaped');
    // this.legenddiv.style.backgroundColor = this.shapeFillColor;	
    // this.triangleFuncClicked(this.shapeFillColor, this.strokeFillColor)	
    // this.squareFuncClicked(this.shapeFillColor, this.strokeFillColor)	
    if (this.squaredivclicked === true) {
      console.log('square shaped')
      this.squareshapeFillColor = this.shapeFillColor;
      this.squareContent.style.backgroundColor = this.squareshapeFillColor;
    }
    // console.log(this.shapeFillColor, 'shaped');	
    else if (this.circledivclicked) {
      this.circleshapeFillColor = this.shapeFillColor;

    }
    else if (this.triangledivclicked) {
      this.triangleshapeFillColor = this.shapeFillColor;
    }
  }
  updateShapeStrokeColor(event) {
    this.changestcolor = true;
    this.addSquare = false;
    this.strokecolor4 = event;
    if (this.squaredivclicked === true) {
      console.log('square shaped')
      this.squarestrokecolor = this.strokecolor4;

    }
    // console.log(this.shapeFillColor, 'shaped');	
    else if (this.circledivclicked) {
      this.circlestrokecolor = this.strokecolor4;

    }
    else if (this.triangledivclicked) {
      this.trianglestrokecolor = this.strokecolor4;

    }
    this.strokeFillColor = '3px solid' + ' ' + event;
    console.log(this.strokeFillColor, 'shaped');
    console.log(this.path, 'in event listnener2');
    // console.log( this.path,"after addeventlist1")	
    //               console.log( this.path,"after addeventlist")	
    // this.triangleFuncClicked(this.shapeFillColor, this.strokeFillColor)	
    // this.squareContent.style.border= this.strokeFillColor	
    console.log('stroked', this.squareContent.style.fill);
    // var col = document.getElementsByClassName('iTag')	
    // col[0].removeAttribute()	
    // console.log(col, 'col')
  }

  circlFunClicked() {
    console.log('circle clicked');
    this.addCircle = true;
    var printEle = document.getElementById('print-style');
    console.log('print-style 1', printEle);
    console.log('circle at shapefill 2', this.addCircle)

    // document.body.style.cursor = 'text'	
    if (this.addCircle = true) {
      printEle.addEventListener(
        'click',
        (e) => {
          this.circleaddcount = 0
          console.log(this.addCircle, 'addsssss')
          console.log('circle at shapefill 3', this.addCircle)
          this.circleToPrint = true;
          var divEle = document.createElement('div');
          divEle.setAttribute('class', `circle-move`);
          divEle.setAttribute('id', `circle-movediv${this.countCircles}`);
          divEle.style.position = 'absolute';
          // divEle.style.resize = 'both';
          divEle.style.overflow = 'hidden';
          divEle.style.top = `${e.offsetY}px`;
          divEle.style.left = `${e.offsetX}px`;
          divEle.style.maxWidth = `${this.mapDiv.nativeElement.offsetWidth}px`;
          divEle.style.maxHeight = `${this.mapDiv.nativeElement.offsetHeight}px`;
          divEle.style.width = '100px';
          divEle.style.height = '100px'
          console.log(divEle.style.width, divEle.style.height, "checkdiveledimensions")
          this.presentcircle = `circle-movediv${this.countCircles}`



          console.log(this.circleAdded, 'added circle')
          var imageTag = document.createElement('img');
          imageTag.setAttribute('class', 'iTag');
          imageTag.setAttribute('src', '../../assets/svgs/new/circle_icon.svg');
          imageTag.style.width = '100%';
          imageTag.style.height = '100%';
          imageTag.style.position = "relative"
          imageTag.style.zIndex = `${this.zindexvalue + 1}`
          this.zindexvalue++
          imageTag.setAttribute('id', `circle-move${this.countCircles}`);

          console.log(imageTag, 'imagetag')
          var removeImage = document.createElement('img');
          removeImage.setAttribute('class', `cross-button`)
          removeImage.setAttribute('src', '../../assets/svgs/new/cross_icon.svg');
          // divEle.appendChild(removeImage)	
          divEle.appendChild(imageTag);
          printEle.appendChild(divEle);
          console.log('divEle-1', divEle);
          console.log('imageTag', imageTag);
          console.log('divEle.innerhtml1', divEle.innerHTML);


          imageTag.addEventListener('click', (e) => {
            console.log('divEle 2', divEle);
            console.log('fg', e.target['id']);
            this.circleId = e.target['offsetParent'].id
            this.circleContent = document.getElementById(this.circleId)
            // this.squareContent.style.backgroundColor=shapeFillColor	
            this.circledivclicked = true;
            this.squaredivclicked = false;
            this.triangledivclicked = false
            // console.log(strokeFillColor, 'strokess')	
            console.log('circleId', this.circleContent)
            this.showshapeeditmenue = !this.showshapeeditmenue;
            this.showshapeeditmenue2 = this.showshapeeditmenue;
            this.shapemenutoggle = false;
            this.showtexteditmenue = false





            this.showdropdown = false;

            console.log(divEle.style.width, divEle.style.height, "checkdiveledimensions")
            this.showSiteMarkerMenu = false;

            fetch('../../assets/svgs/new/circle_icon.svg')
              .then((response) => response.text())
              .then((svgText) => {
                var parser = new DOMParser();
                var svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
                this.circlehtmlcode = document.getElementById(e.srcElement['nextElementSibling'].id)
                this.path2 = svgDoc.querySelector('svg');
                this.path3 = svgDoc.querySelector('ellipse');
                console.log(this.path, this.circlehtmlcode.style.width, "in event listnener")
                var a = ((parseInt(this.circlehtmlcode.style.width) - 20) / 2);
                var b = ((parseInt(this.circlehtmlcode.style.height) - 20) / 2);

                console.log('path-before', svgDoc, this.path3, this.path2.getAttribute('cx'));
                this.path2.setAttribute('width', `${this.circlehtmlcode.style.width}`)
                this.path2.setAttribute('height', `${this.circlehtmlcode.style.height}`)
                this.path3.setAttribute('cx', `${(parseInt(this.circlehtmlcode.style.width) / 2)}`);
                this.path3.setAttribute('cy', `${(parseInt(this.circlehtmlcode.style.height) / 2)}`);
                this.path3.setAttribute('rx', `${a}`);
                this.path3.setAttribute('ry', `${b}`);
                console.log('path-after', this.path3);
                // if(this.changestcolor==true){	
                //  this.path3.removeAttribute('stroke');	
                // this.changestcolor=false;	
                // }	
                this.path3.setAttribute('stroke-width', this.circlewidthvalue);
                this.path3.setAttribute('stroke', this.circlestrokecolor);
                console.log(this.strokecolor4, "checkcirclecolor")
                if (this.circleshapeFillColor != null) {
                  this.path3.setAttribute('fill', this.circleshapeFillColor);

                }

                this.path3.setAttribute('stroke-dasharray', this.circlestrokeformat);
                // path.setAttribute('fill', shapeFillColor);
                // console.log('color-value', strokeColorInput.value);
                // path.setAttribute("fill", strokeColorInput.value);

                // Update the SVG data
                var svgCode = svgDoc.documentElement.outerHTML;
                // $(".iTag").attr("src","data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgCode));
                imageTag.src =
                  'data:image/svg+xml;charset=utf-8,' +
                  encodeURIComponent(svgCode);
                console.log('ccccc', imageTag);
                console.log('print-style 2', printEle);

              });
          });
          setTimeout(() => {
            this.circleAdded = document.querySelector('.circle-move')
            console.log(this.circleAdded, 'added circle')
            this.initialObj1 = {
              nav_x: $('#' + this.presentcircle).position().left,
              nav_y: $('#' + this.presentcircle).position().top
            };
            console.log(this.initialObj1, "checkcircleforprinting")
            this.circlearraytemp = {
              id: `${this.presentcircle}`,
              position: this.initialObj1,
              zindex: parseInt(imageTag.style.zIndex)
            }
            console.log(this.circlearraytemp, "checkcircleforprinting")
            this.OverallArray.push(this.circlearraytemp)
            // this.circlearrayforprinting.push((this.presentcircle))
            this.showcircularcode = document.getElementById(this.presentcircle)

            this.circlearrayforprinting.push((this.showcircularcode))
            console.log(this.OverallArray, "overall")
          }, 1000);
          setTimeout(() => {
            const printEle = document.getElementById('print-style');
            const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
            const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
            const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
            const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
            console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
            divEle.style.maxWidth = ` ${maxAllowedWidth}px`; // set the maximum allowed width
            divEle.style.maxHeight = ` ${maxAllowedHeight}px`; // set the maximum allowed height
            console.log(divBounds, "checkdivbound")
            const initialObj = {
              nav_x: $('#' + this.presentcircle).position().left,
              nav_y: $('#' + this.presentcircle).position().top
            };
            this.circleIntialPosition = initialObj

            console.log(this.circleIntialPosition.nav_x, 'before changing values in circle')
            this.storeCirclePosition.push(initialObj)
            console.log(this.storeCirclePosition, this.storeCirclePosition.length, 'added values set in circle')
            divEle.addEventListener('mousemove', (e) => {
              var team = this
              var Allarray = this.OverallArray
              if (e['altKey'] === true) {
                $('#' + e.target['nextSibling'].id).draggable({
                  containment: "parent",
                  stop: function (event, ui) {
                    console.log(ui, "get the ui elements inside legend")
                    const x = ui.position.left;
                    const y = ui.position.top;
                    this.circleobj = {
                      nav_x: x,
                      nav_y: y
                    }
                    for (let i = 0; i < Allarray.length; i++) {
                      if (ui.helper[0].id == Allarray[i].id) {
                        Allarray[i].position = this.circleobj
                      }
                    }
                    team.circlePosition.next(`${JSON.stringify(this.circleobj)}`)
                    const printEle = document.getElementById('print-style');
                    const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
                    const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
                    const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
                    const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
                    console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
                    divEle.style.maxWidth = `${maxAllowedWidth}px`; // set the maximum allowed width
                    divEle.style.maxHeight = `${maxAllowedHeight}px`; // set the maximum allowed height
                    console.log(divBounds, "checkdivbound")
                  }
                })
                this.OverallArray = Allarray
              } else if (e['altKey'] === false) {
                $('#' + e.target['nextSibling'].id).draggable("destroy");
              }
              team.circlePosition.subscribe((val) => {
                this.latestCircleArray.push(e.srcElement['offsetParent'].id)
                console.log(JSON.parse(val), this.latestCircleArray[this.latestCircleArray.length - 1], "check if the values exist in circle")
                this.storeCirclePosition.push(JSON.parse(val))
                // for(let i =0;i<this.circlearray.length;i++){
                //   console.log(e,this.circlearray[i].id,"checkidforcircle")
                //   if(this.latestarray[this.latestarray.length-1]==this.circlearray[i].id){
                //      this.circlearray[i].position=JSON.parse(val)
                //      console.log( this.circlearray,"checkidforcircle")
                //   }
                // }

                console.log(team.circlePosition[0], "checkidforcircle outside")
              })
              console.log(this.storeCirclePosition[this.storeCirclePosition.length - 1], "checkidforcircle out")
              // console.log(e.srcElement['offsetParent'].id,e.srcElement['offsetParent'].id==this.circlearray[0].id,"check circle id outside")
              // for (let i = 0; i < this.OverallArray.length; i++) {
              //   if (e.srcElement['offsetParent'].id == this.OverallArray[i].id) {
              //     this.OverallArray[i].position = this.storeCirclePosition[this.storeCirclePosition.length - 1]
              //   }
              // }
              // console.log(this.OverallArray, "overall")
            })
            var removeImage = document.createElement('img');
            removeImage.setAttribute('id', `circle-movediv${this.countCircles - 1}`);
            removeImage.setAttribute('class', `cross-button`);
            removeImage.setAttribute(
              'src',
              '../../assets/svgs/new/cross_icon.svg'
            );
            divEle.addEventListener('mouseenter', () => {
              console.log('mouse entered');
              removeImage.classList.add('.cross-button');
              // crossBtn.innerText = 'Close';
              removeImage.style.position = 'absolute';
              removeImage.style.top = '0';
              removeImage.style.right = '0';
              removeImage.style.zIndex = `${this.zindexvalue + 1}`;

              divEle.appendChild(removeImage);

              removeImage.addEventListener('click', (e) => {
                var circleelement = document.getElementById(e.target['id'])
                for (let i = 0; i < this.OverallArray.length; i++) {
                  if ((e.target['id']) == this.OverallArray[i].id) {
                    this.OverallArray.splice(i, 1)
                  }
                }

                setTimeout(() => {
                  this.showshapeeditmenue = false;
                  this.showshapeeditmenue2 = false;
                }, 100);

                // Remove the div element from the DOM
                // divEle.parentNode.removeChild(divEle);
                circleelement.style.display = "none"
              });

              removeImage.style.display = 'block';
            });

            divEle.addEventListener('mouseleave', () => {
              removeImage['style'].display = 'none';
            });
            //   divEle.addEventListener('mousemove', ()=>{
            //   const crossBtn = document.querySelector(`.cross-button`);
            //   crossBtn.addEventListener('click', () => {
            //     // Remove the square element from the DOM
            //     const circle = document.getElementById(this.circleId);
            //     circle.parentNode.removeChild(circle);
            //   });
            // })
          });
          this.countCircles++
        },
        { once: true }
      )
    }

  }
  squareCount() {
    this.squareaddcount++
    if (this.squareaddcount == 1) {
      this.squareFuncClicked()
    }
    else if (this.squareaddcount > 1) {
      alert("Square already selected")

    }
  }
  circleCount() {
    this.circleaddcount++
    if (this.circleaddcount == 1) {
      this.circlFunClicked()
    }
    else if (this.circleaddcount > 1) {
      alert("Circle already selected")

    }
  }
  TriangleCount() {
    this.triangleaddcount++
    if (this.triangleaddcount == 1) {
      this.triangleFuncClicked({}, {})
    }
    else if (this.triangleaddcount > 1) {
      alert("Triangle already selected")

    }
  }
  TextboxCount() {
    this.textboxaddcount++
    if (this.textboxaddcount == 1) {
      this.addTextField()
    }
    else if (this.textboxaddcount > 1) {
      alert("TextBox already selected")

    }
  }

  squareFuncClicked() {

    console.log('square clicked');
    this.addSquare = true;
    var printEle = document.getElementById('print-style');
    console.log('print-style 1', printEle);
    console.log('square at shapefill 2', this.addSquare)

    // document.body.style.cursor = 'text'
    if (this.addSquare = true) {
      printEle.addEventListener(
        'click',
        (e) => {
          this.squareaddcount = 0
          console.log(this.addSquare, 'addsssss')
          console.log('square at shapefill 3', this.addSquare)
          this.squareToPrint = true;
          var divEle = document.createElement('div');
          divEle.setAttribute('class', `square-move`);
          divEle.setAttribute('id', `square-movediv${this.countSquares}`);
          this.presentSquare = `square-movediv${this.countSquares}`
          divEle.style.position = 'absolute';
          divEle.style.resize = 'both';
          divEle.style.overflow = 'hidden';
          divEle.style.top = `${e.offsetY}px`;
          divEle.style.left = `${e.offsetX}px`;
          divEle.style.width = '100px';
          divEle.style.height = '100px'
          const resizeObserver = new ResizeObserver(() => {
            // Call your function here
          });

          resizeObserver.observe(divEle);

          setTimeout(() => {
            const printEle = document.getElementById('print-style');
            const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
            const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
            const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
            const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
            console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
            divEle.style.maxWidth = `${maxAllowedWidth}px`; // set the maximum allowed width
            divEle.style.maxHeight = `${maxAllowedHeight}px`; // set the maximum allowed height  
            console.log(divBounds, "checkdivbound")
            this.squareAdded = document.querySelector('.square-move')
            console.log(this.squareAdded, 'added square')
            this.initialObj2 = {
              nav_x: $('#' + this.presentSquare).position().left,
              nav_y: $('#' + this.presentSquare).position().top
            };
            console.log(this.initialObj2, "checksquareforprinting")

          }, 1000);

          console.log(this.squareAdded, 'added square')

          // imageTag.style.maxWidth='453px'      
          console.log(this.childDiv.nativeElement, this.childDiv.nativeElement.offsetWidth, this.childDiv.nativeElement.offsetHeight, 'width of rectangle svg')
          var imageTag = document.createElement('img');
          imageTag.setAttribute('class', 'iTag');
          imageTag.setAttribute('src', '../../assets/svgs/new/square_icon.svg');
          imageTag.style.width = '100%';
          imageTag.style.zIndex = `${this.zindexvalue + 1}`
          this.zindexvalue++
          imageTag.style.height = '100%';
          imageTag.style.position = "relative"

          setTimeout(() => {
            this.squareArrayTemp = {
              id: `${this.presentSquare}`,
              position: this.initialObj2,
              zindex: parseInt(imageTag.style.zIndex)
            }
            console.log(this.squareArrayTemp, "checksquareforprinting")
            this.OverallArray.push(this.squareArrayTemp)
            // this.circlearrayforprinting.push((this.presentcircle))
            // this.showcircularcode=document.getElementById(this.presentSquare)

            //  this.squareArrayForPrinting.push(( this.showSquarecode))
            console.log(this.OverallArray, "overall")
          }, 1000);
          imageTag.setAttribute('id', `square-move${this.countSquares}`);
          var removeImage = document.createElement('img');
          removeImage.setAttribute('class', `cross-button`)
          removeImage.setAttribute('src', '../../assets/svgs/new/cross_icon.svg');
          // divEle.appendChild(removeImage)
          divEle.appendChild(imageTag);
          printEle.appendChild(divEle);
          console.log('divEle-1', divEle);
          console.log('imageTag', imageTag);
          console.log('divEle.innerhtml1', divEle.innerHTML);

          imageTag.addEventListener('click', (e) => {
            console.log('divEle 2', divEle);
            console.log('fg', e);
            this.squareId = e.target['offsetParent'].id
            this.squareContent = document.getElementById(e.srcElement['id'])
            // this.squareContent.style.backgroundColor=shapeFillColor
            this.circledivclicked = false;
            this.squaredivclicked = true;
            this.triangledivclicked = false;
            this.selectedtextfield = false;
            console.log(this.shapeFillColor, this.squaredivclicked, 'shaped');
            console.log(this.strokecolor4, 'strokess')
            console.log('squareid', this.squareContent)
            this.showshapeeditmenue = !this.showshapeeditmenue;
            this.showshapeeditmenue2 = this.showshapeeditmenue;
            this.shapemenutoggle = false;
            this.showtexteditmenue = false





            this.showdropdown = false;


            this.showSiteMarkerMenu = false;
            this.squareContent.addEventListener('click', () => {
              fetch('../../assets/svgs/new/square_icon.svg')
                .then((response) => response.text())
                .then((svgText) => {
                  var parser = new DOMParser();
                  var svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
                  console.log('svgDoc', svgDoc);
                  this.squarehtmlcode = document.getElementById(e.srcElement['nextElementSibling'].id)
                  this.path2 = svgDoc.querySelector('svg');
                  this.path = svgDoc.querySelector('rect');
                  console.log(this.path, this.squarehtmlcode.style.width, "in event listnener")
                  console.log('path-before', svgDoc, this.path, this.path2.getAttribute('width'));
                  this.path2.setAttribute('width', `${this.squarehtmlcode.style.width}`)
                  this.path2.setAttribute('height', `${this.squarehtmlcode.style.height}`)
                  this.path.setAttribute('width', `${parseInt(this.squarehtmlcode.style.width)}`);
                  this.path.setAttribute('height', `${parseInt(this.squarehtmlcode.style.height)}`);
                  // this.path.removeAttribute('stroke');
                  // this.changestcolor=false;
                  // }
                  // if(this.changestcolor==true){
                  this.path.setAttribute('stroke-width', this.squarewidthvalue);
                  this.path.setAttribute('stroke-dasharray', this.squarestrokeformat);
                  this.path.setAttribute('stroke', this.squarestrokecolor);
                  //   if( this.squareshapeFillColor != null)


                  this.changestcolor = false;
                  //  }
                  //  else{

                  //  }
                  // path.setAttribute('fill', shapeFillColor);
                  // console.log('color-value', strokeColorInput.value);
                  // path.setAttribute("fill", strokeColorInput.value);
                  console.log('path-after', this.path);
                  // Update the SVG data
                  var svgCode = svgDoc.documentElement.outerHTML;
                  // $(".iTag").attr("src","data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgCode));
                  imageTag.src =
                    'data:image/svg+xml;charset=utf-8,' +
                    encodeURIComponent(svgCode);
                  console.log('ccccc', imageTag);
                  console.log('print-style 2', printEle);
                })
            }, { once: true });
          });
          setTimeout(() => {
            const initialObj = {
              nav_x: $('#' + this.presentSquare).position().left,
              nav_y: $('#' + this.presentSquare).position().top
            };
            this.squareIntialPosition = initialObj

            console.log(this.squareIntialPosition.nav_x, 'before changing values in square')
            this.storeSquarePosition.push(initialObj)
            const ArrayAll = this.OverallArray;
            console.log(this.storeSquarePosition, this.storeSquarePosition.length, 'added values set in square')
            divEle.addEventListener('mousemove', (e) => {
              console.log('#' + e.target['nextSibling'].id, "checkthesquaredragcomponent")
              var team = this
              var Allarray = this.OverallArray
              if (e['altKey'] === true) {
                $('#' + e.target['nextSibling'].id).draggable({
                  containment: "parent",

                  stop: function (event, ui) {
                    console.log(ui, Allarray, "get the ui elements inside square")

                    const x = ui.position.left;
                    const y = ui.position.top;
                    this.squareobj = {
                      nav_x: x,
                      nav_y: y
                    }
                    for (let i = 0; i < Allarray.length; i++) {
                      if (ui.helper[0].id == Allarray[i].id) {
                        Allarray[i].position = this.squareobj
                      }
                    }
                    team.squarePosition.next(`${JSON.stringify(this.squareobj)}`)
                    const printEle = document.getElementById('print-style');
                    const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
                    const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
                    const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
                    const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
                    console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
                    divEle.style.maxWidth = `${maxAllowedWidth}px`; // set the maximum allowed width
                    divEle.style.maxHeight = `${maxAllowedHeight}px`; // set the maximum allowed height  
                    console.log(divBounds, "checkdivbound")

                  }
                })
                this.OverallArray = Allarray
              } else if (e['altKey'] === false) {
                $('#' + e.target['nextSibling'].id).draggable("destroy");
              }
              team.squarePosition.subscribe((val) => {
                // this.latestSquareArray.push(e.srcElement['offsetParent'].id)
                // console.log(JSON.parse(val),this.latestCircleArray[this.latestCircleArray.length-1],"check if the values exist in circle")
                this.storeSquarePosition.push(JSON.parse(val))
                // for(let i =0;i<this.circlearray.length;i++){
                //   console.log(e,this.circlearray[i].id,"checkidforcircle")
                //   if(this.latestarray[this.latestarray.length-1]==this.circlearray[i].id){
                //      this.circlearray[i].position=JSON.parse(val)
                //      console.log( this.circlearray,"checkidforcircle")
                //   }
                // }

                console.log(team.squarePosition[0], "checkidforsquare outside")
              })
              console.log(this.storeSquarePosition[this.storeSquarePosition.length - 1], "checkidforsquare out")
              // console.log(e,e.srcElement['offsetParent'].id,this.squareArray[0].id,"check square id outside")
              // for (let i = 0; i < this.OverallArray.length; i++) {
              //   if (e.srcElement['offsetParent'].id == this.OverallArray[i].id) {
              //     console.log(this.OverallArray[i].id,"i amin square")
              //     this.OverallArray[i].position = this.storeSquarePosition[this.storeSquarePosition.length - 1]
              //     console.log(this.OverallArray[i].position,i, "overallPositions")

              //   }
              // }
              console.log(this.OverallArray, "overall")
            })
            var removeImage = document.createElement('img');
            removeImage.setAttribute('id', `square-movediv${this.countSquares - 1}`);
            console.log(`square-movediv${this.countSquares - 1}`, this.countSquares, 'squarestrpper');
            removeImage.setAttribute('class', `cross-button`);
            removeImage.setAttribute(
              'src',
              '../../assets/svgs/new/cross_icon.svg'
            );
            divEle.addEventListener('mouseenter', () => {
              console.log('mouse entered');
              removeImage.classList.add('.cross-button');
              // crossBtn.innerText = 'Close';
              removeImage.style.position = 'absolute';
              removeImage.style.top = '0';
              removeImage.style.right = '0';
              removeImage.style.zIndex = `${this.zindexvalue + 1}`;
              divEle.appendChild(removeImage);


              removeImage.addEventListener('click', (e) => {
                const squareelement = document.getElementById(e.target['id'])
                console.log(e, squareelement, "i am in remove")
                for (let i = 0; i < this.OverallArray.length; i++) {
                  console.log(e.target['id'], this.OverallArray[i].id, e, 'checktriangleaftersplice')
                  if ((e.target['id']) == this.OverallArray[i].id) {
                    this.OverallArray.splice(i, 1)
                  }

                }
                setTimeout(() => {
                  this.showshapeeditmenue = false;
                  this.showshapeeditmenue2 = false;
                }, 10);
                // console.log(divEle,"divEle")
                // // // Remove the div element from the DOM
                // squareelement.parentNode.removeChild(imageTag);
                squareelement.style.display = "none"

              });

              removeImage.style.display = 'block';
            });

            divEle.addEventListener('mouseleave', () => {
              removeImage['style'].display = 'none';
            });
            //   divEle.addEventListener('mousemove', ()=>{
            //   const crossBtn = document.querySelector(`.cross-button`);
            //   crossBtn.addEventListener('click', () => {
            //     // Remove the square element from the DOM
            //     const circle = document.getElementById(this.circleId);
            //     circle.parentNode.removeChild(circle);
            //   });
            // })
          });
          this.countSquares++
        },
        { once: true }
      )
    }

  }

  triangleFuncClicked(shapeFillColor, strokeFillColor) {
    console.log('triangle clicked');
    var printEle = document.getElementById('print-style');
    console.log('print-style 1', printEle);
    // document.body.style.cursor = 'text'
    printEle.addEventListener(
      'click',

      (e) => {
        this.triangleaddcount = 0
        this.triangleToPrint = true;
        var divEle = document.createElement('div');
        divEle.setAttribute('class', 'triangle-move');
        divEle.setAttribute('id', `triangle-movediv${this.countTriangles}`);
        this.presentTriangle = `triangle-movediv${this.countTriangles}`

        divEle.style.position = 'absolute';
        divEle.style.resize = 'both';
        divEle.style.overflow = 'auto';
        divEle.style.top = `${e.offsetY}px`;
        divEle.style.left = `${e.offsetX}px`;
        divEle.style.maxWidth = `${this.mapDiv.nativeElement.offsetWidth}px`;
        divEle.style.maxHeight = `${this.mapDiv.nativeElement.offsetHeight}px`;
        divEle.style.width = '100px';
        divEle.style.height = '100px'
        setTimeout(() => {
          const printEle = document.getElementById('print-style');
          const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
          const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
          const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
          const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
          console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
          divEle.style.maxWidth = `${maxAllowedWidth}px`; // set the maximum allowed width
          divEle.style.maxHeight = ` ${maxAllowedHeight}px`; // set the maximum allowed height
          console.log(divBounds, "checkdivbound")
          this.triangleAdded = document.querySelector('.triangle-move')
          console.log(this.circleAdded, 'added circle')
          this.initialObj3 = {
            nav_x: $('#' + this.presentTriangle).position().left,
            nav_y: $('#' + this.presentTriangle).position().top
          };
          console.log(this.initialObj1, "checkcircleforprinting")

        }, 1000);


        console.log(this.triangleAdded, 'added square')
        var imageTag = document.createElement('img');
        imageTag.setAttribute('id', `triangle${this.countTriangles}`)
        imageTag.setAttribute('class', 'iTag');
        imageTag.setAttribute('src', '../../assets/svgs/new/triangle_icon.svg');
        imageTag.style.width = '100%';
        imageTag.style.height = '100%';
        imageTag.style.position = "relative"
        imageTag.style.zIndex = `${this.zindexvalue + 1}`
        this.zindexvalue++
        divEle.appendChild(imageTag);
        printEle.appendChild(divEle);
        console.log('divEle-1', divEle);
        console.log('imageTag', imageTag);
        console.log('divEle.innerhtml1', divEle.innerHTML);
        setTimeout(() => {
          this.triangleArrayTemp = {
            id: `${this.presentTriangle}`,
            position: this.initialObj3,
            zindex: parseInt(imageTag.style.zIndex)
          }
          console.log(this.triangleArrayTemp, "checkcircleforprinting")
          this.OverallArray.push(this.triangleArrayTemp)
          // // this.circlearrayforprinting.push((this.presentcircle))
          // this.showcircularcode=document.getElementById(this.presentTriangle)

          //  this.circlearrayforprinting.push(( this.showcircularcode))
          console.log(this.OverallArray, "overall")
        }, 1000);
        imageTag.addEventListener('click', (e) => {
          console.log('divEleTraingle', e, divEle);
          this.triangleId = e.target['offsetParent'].id
          this.triangleContent = document.getElementById(this.triangleId)
          console.log(this.triangleContent, "check triangle")
          this.circledivclicked = false;
          this.squaredivclicked = false;
          this.triangledivclicked = true;
          this.selectedtextfield = false;
          // var toolbar = document.createElement('div');
          // toolbar.setAttribute('class', 'toolbar');
          this.showshapeeditmenue = !this.showshapeeditmenue;
          this.showshapeeditmenue2 = this.showshapeeditmenue;
          this.shapemenutoggle = false;
          this.showtexteditmenue = false





          this.showdropdown = false;


          this.showSiteMarkerMenu = false;
          // Create the stroke color input
          // var strokeColorLabel = document.createElement('label');
          // strokeColorLabel.innerHTML = 'Stroke Color: ';
          // var strokeColorInput = document.createElement('input');
          // strokeColorInput.setAttribute('type', 'color');
          // strokeColorInput.setAttribute('id', 'stroke-color');
          // strokeColorInput.addEventListener('change', () => {
          fetch('../../assets/svgs/new/triangle_icon.svg')
            .then((response) => response.text())
            .then((svgText) => {
              this.countlinedashed++
              var parser = new DOMParser();
              var svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
              console.log('svgDoc', svgDoc);
              this.trianglehtmlcode = document.getElementById(e.srcElement['nextElementSibling'].id)
              this.path2 = svgDoc.querySelector('svg');
              this.path4 = svgDoc.querySelector('polygon');
              const width = parseInt(this.trianglehtmlcode.style.width);; // replace with the actual width of your SVG
              const height = parseInt(this.trianglehtmlcode.style.height); // replace with the actual height of your SVG

              const x1 = 1;
              const y1 = height - 1;

              const x2 = width / 2;
              const y2 = 0;

              const x3 = width;
              const y3 = height - 1;
              console.log(this.path4, this.trianglehtmlcode.style.width, "in event listnener")
              console.log('path-before', svgDoc, this.path4, this.path2.getAttribute('width'));
              this.path2.setAttribute('width', `${this.trianglehtmlcode.style.width}`)
              this.path2.setAttribute('height', `${this.trianglehtmlcode.style.height}`)
              this.path4.setAttribute('points', `${x1},${y1} ${x2},${y2} ${x3},${y3}`);

              // path.setAttribute('stroke', strokeFillColor);
              // path.setAttribute('fill', shapeFillColor);
              console.log(this.shapeFillColor, "fontsize check")
              this.path4.setAttribute('stroke-width', this.trainglewidthvalue);
              this.path4.setAttribute('stroke', this.trianglestrokecolor);
              if (this.triangleshapeFillColor != null) {
                this.path4.setAttribute('fill', this.triangleshapeFillColor);

              }

              this.path4.setAttribute('stroke-dasharray', this.trainglestrokeformat);



              // console.log('color-value', strokeColorInput.value);
              // this.path4.setAttribute("fill", strokeColorInput.value);
              console.log('this.path4-after', this.path4);
              // Update the SVG data
              var svgCode = svgDoc.documentElement.outerHTML;
              // $(".iTag").attr("src","data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgCode));
              imageTag.src =
                'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(svgCode);
              console.log('ccccc', imageTag);
              console.log('print-style 2', printEle);

            });
          // });
          // toolbar.appendChild(strokeColorLabel);
          // toolbar.appendChild(strokeColorInput);

          // Create the fill color input
          // var fillColorLabel = document.createElement('label');
          // fillColorLabel.innerHTML = 'Fill Color: ';
          // var fillColorInput = document.createElement('input');
          // fillColorInput.setAttribute('type', 'color');
          // fillColorInput.setAttribute('id', 'fill-color');
          // fillColorInput.addEventListener('change', () => {
          //   fetch('../../assets/svgs/new/triangle_icon.svg')
          //     .then((response) => response.text())
          //     .then((svgText) => {
          //       var parser = new DOMParser();
          //       var svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
          //       console.log('svgDoc', svgDoc);
          //       var path = svgDoc.querySelector('polygon');
          //       console.log('path-before', path);
          //       path.setAttribute('fill', fillColorInput.value);
          //       console.log('color-value', fillColorInput.value);
          //       // path.setAttribute("fill", strokeColorInput.value);
          //       console.log('path-after', path);
          //       // Update the SVG data
          //       var svgCode = svgDoc.documentElement.outerHTML;
          //       // $(".iTag").attr("src","data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgCode));
          //       imageTag.src =
          //         'data:image/svg+xml;charset=utf-8,' +
          //         encodeURIComponent(svgCode);
          //       console.log('ccccc', imageTag);
          //       console.log('print-style 2', printEle);
          //     });
          // });
          // toolbar.appendChild(fillColorLabel);
          // toolbar.appendChild(fillColorInput);

          // divEle.appendChild(toolbar);

          // Create the border radius input
          // var borderRadiusLabel = document.createElement('label');
          // borderRadiusLabel.innerHTML = 'Border Radius: ';
          // var borderRadiusInput = document.createElement('input');
          // borderRadiusInput.setAttribute('type', 'number');
          // borderRadiusInput.setAttribute('id', 'border-radius');
          // borderRadiusInput.addEventListener('change', () => {
          //   imageTag.style.borderRadius = borderRadiusInput.value + 'px';
          // });
          // toolbar.appendChild(borderRadiusLabel);
          // toolbar.appendChild(borderRadiusInput);

          // // Append the toolbar to the print element
          // // printEle.appendChild(toolbar);
          // // printEle.appendChild(divEle)
          // divEle.appendChild(toolbar);
        });

        setTimeout(() => {
          const initialObj = {
            nav_x: $('#' + this.presentTriangle).position().left,
            nav_y: $('#' + this.presentTriangle).position().top
          };
          this.triangleIntialPosition = initialObj

          console.log(this.triangleIntialPosition.nav_x, 'before changing values in circle')
          this.storeTrianglePosition.push(initialObj)
          console.log(this.storeTrianglePosition, this.storeTrianglePosition.length, 'added values set in circle')
          divEle.addEventListener('mousemove', (e) => {
            var team = this
            var Allarray = this.OverallArray
            if (e['altKey'] === true) {
              $('#' + e.target['nextSibling'].id).draggable({
                containment: "parent",
                stop: function (event, ui) {
                  console.log(ui, "get the ui elements inside legend")
                  const x = ui.position.left;
                  const y = ui.position.top;
                  this.triangleobj = {
                    nav_x: x,
                    nav_y: y
                  }
                  for (let i = 0; i < Allarray.length; i++) {
                    if (ui.helper[0].id == Allarray[i].id) {
                      Allarray[i].position = this.triangleobj
                    }
                  }
                  team.trianglePosition.next(`${JSON.stringify(this.triangleobj)}`)
                  const printEle = document.getElementById('print-style');
                  const printBounds = printEle.getBoundingClientRect(); // get the bounding rectangle of the print-style element
                  const divBounds = divEle.getBoundingClientRect(); // get the bounding rectangle of the divEle element
                  const maxAllowedWidth = Math.min(printBounds.width - (divBounds.left - printBounds.left)); // calculate the maximum allowed width of the divEle
                  const maxAllowedHeight = Math.min(printBounds.height - (divBounds.top - printBounds.top)); // calculate the maximum allowed height of the divEle
                  console.log(maxAllowedWidth, maxAllowedHeight, 'max height and width')
                  divEle.style.maxWidth = ` ${maxAllowedWidth}px`; // set the maximum allowed width
                  divEle.style.maxHeight = `${maxAllowedHeight}px`; // set the maximum allowed height
                  console.log(divBounds, "checkdivbound")
                }
              })
              this.OverallArray = Allarray
            } else if (e['altKey'] === false) {
              $('#' + e.target['nextSibling'].id).draggable("destroy");
            }
            team.trianglePosition.subscribe((val) => {
              console.log(JSON.parse(val), "check if the values exist in Triangle")
              this.storeTrianglePosition.push(JSON.parse(val))
              // for(let i =0;i<this.triangleArray.length;i++){
              //   console.log(e,this.triangleArray[i].id,"checkidforTriangle")
              //   if(e.srcElement['offsetParent'].id==this.triangleArray[i].id){
              //      this.triangleArray[i].position=JSON.parse(val)
              //      console.log( this.triangleArray,"checkidforTriangle")
              //   }
              // }

            })
            // console.log(e.srcElement['id'],"checkidforcircle")

            console.log(this.storeTrianglePosition[this.storeTrianglePosition.length - 1], "checkidfortriangle out")
            // console.log(e.srcElement['offsetParent'].id,e.srcElement['offsetParent'].id==this.triangleArray[0].id,"check triangle id outside")
            // for (let i = 0; i < this.OverallArray.length; i++) {
            //   if (e.srcElement['offsetParent'].id == this.OverallArray[i].id) {
            //     this.OverallArray[i].position = this.storeTrianglePosition[this.storeTrianglePosition.length - 1]
            //   }
            // }
            console.log(this.OverallArray, "overall")
          })
          var removeImage = document.createElement('img');
          removeImage.setAttribute('id', `triangle-movediv${this.countTriangles - 1}`);
          removeImage.setAttribute('class', `cross-button`);
          removeImage.setAttribute(
            'src',
            '../../assets/svgs/new/cross_icon.svg'
          );
          divEle.addEventListener('mouseenter', () => {
            console.log('mouse entered');
            removeImage.classList.add('.cross-button');
            // crossBtn.innerText = 'Close';
            removeImage.style.position = 'absolute';
            removeImage.style.top = '0';
            removeImage.style.right = '0';
            removeImage.style.zIndex = `${this.zindexvalue + 1}`;
            divEle.appendChild(removeImage);

            removeImage.addEventListener('click', (e) => {
              var triangleelement = document.getElementById(e.target['id'])
              for (let i = 0; i < this.OverallArray.length; i++) {
                console.log(e.target['id'], this.OverallArray[i].id, e, 'checktriangleaftersplice')
                if ((e.target['id']) == this.OverallArray[i].id) {
                  this.OverallArray.splice(i, 1)
                }

              }
              setTimeout(() => {
                this.showshapeeditmenue = false;
                this.showshapeeditmenue2 = false;
              }, 10);
              // Remove the div element from the DOM
              // divEle.parentNode.removeChild(divEle);
              triangleelement.style.display = "none"
            });

            removeImage.style.display = 'block';
          });

          divEle.addEventListener('mouseleave', () => {
            removeImage['style'].display = 'none';
          });
          //   divEle.addEventListener('mousemove', ()=>{
          //   const crossBtn = document.querySelector(`.cross-button`);
          //   crossBtn.addEventListener('click', () => {
          //     // Remove the square element from the DOM
          //     const circle = document.getElementById(this.circleId);
          //     circle.parentNode.removeChild(circle);
          //   });
          // })
        });
        this.countTriangles++
      },
      { once: true }
    );
  }
  // logoMoveFunc() {
  //   var obj = {};
  //   console.log('logomove is moving ');
  //   var printEle = document.getElementById('print-style');
  //   printEle.addEventListener('mouseover', (event) => {
  //     this.logo_view = document.querySelector('.logo-move');
  //     console.log(event, 'print event function.............');

  //     //image move function
  //     this.logo_view.addEventListener('mousedown', () => {
  //       this.logo_view.addEventListener('mousemove', (x) => {
  //         this.activatedLogoChange = true;

  //         console.log(this.logoChange, 'check change in logo x and y');
  //         $('.logo-move').draggable({
  //           containment: 'parent',
  //           drag: function (event, ui) {
  //             obj = {
  //               left: ui.position.left,
  //               top: ui.position.top,
  //             };
  //           },
  //         });
  //         this.logoChange = obj;
  //         console.log(this.logoChange, 'check updated logo change');
  //       });

  //       this.logo_view.addEventListener('mousedown', () => {
  //         this.logo_view.removeEventListener('mousemove', () => {
  //           $('.logo-move').draggable({ containment: 'parent' });
  //         });
  //       });
  //     });
  //   });
  // }
  increaseZIndex() {
    // this.circledivclicked=false;
    // this.squaredivclicked=false;
    // this.triangledivclicked=true;
    // this.squareContent.style.zIndex
    console.log(this.circledivclicked, "checkzindexshape");
    console.log(this.squaredivclicked, this.squareId, "checkzindexshape")
    console.log(this.triangledivclicked, this.circleId, "checkzindexshape")
    if (this.squaredivclicked) {
      console.log(this.squareContent.style.zIndex, "checkZIndex")
      console.log(this.squareContent.style, "checkZIndex")
      console.log(this.OverallArray[0].id, this.squareId, this.OverallArray[0].id == this.squareId, "checkZIndex")

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.squareId) {
          console.log("inside square index")
          this.squareContent.style.zIndex = `${this.OverallArray[i].zindex + 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex + 1)
        }
      }
      console.log("checkzindexofsquare", this.OverallArray)
      // `${Number(this.squareContent.style.zIndex)+1}`
    }
    else if (this.circledivclicked) {

      console.log(this.OverallArray[0].id, this.circleId, this.OverallArray[0].id == this.circleId, "checkZIndex")
      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.circleId) {
          this.circleContent.style.zIndex = `${this.OverallArray[i].zindex + 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex + 1)
        }
      }
    }
    else if (this.triangledivclicked) {
      console.log(this.triangleContent.style.zIndex, "checkzindexshape")

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.triangleId) {
          this.triangleContent.style.zIndex = `${this.OverallArray[i].zindex + 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex + 1)
        }
      }
    }
    else if (this.selectedtextfield) {

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.textid) {
          this.textareacontent['style'].zIndex = `${this.OverallArray[i].zindex + 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex + 1)
        }
      }
    }

  }
  decreaseZIndex() {
    if (this.squaredivclicked && Number(this.squareContent.style.zIndex) > -1) {
      console.log(this.squareContent.style.zIndex, "checkZIndex")
      console.log(this.squareContent.style, "checkZIndex")

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.squareId) {
          this.squareContent.style.zIndex = `${parseInt(this.OverallArray[i].zindex) - 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex) - 1
        }
      }
      // `${Number(this.squareContent.style.zIndex)+1}`
    }
    else if (this.circledivclicked && Number(this.circleContent.style.zIndex) > -1) {

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.circleId) {
          this.circleContent.style.zIndex = `${parseInt(this.OverallArray[i].zindex) - 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex) - 1
        }
      }
    }
    else if (this.triangledivclicked && Number(this.triangleContent.style.zIndex) > -1) {

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.triangleId) {
          this.triangleContent.style.zIndex = `${parseInt(this.OverallArray[i].zindex) - 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex) - 1
        }
      }
    }
    else if (this.selectedtextfield && Number(this.textareacontent['style'].zIndex) > -1) {

      for (let i = 0; i < this.OverallArray.length; i++) {
        if (this.OverallArray[i].id == this.textid) {
          this.textareacontent['style'].zIndex = `${parseInt(this.OverallArray[i].zindex) - 1}`
          this.OverallArray[i].zindex = parseInt(this.OverallArray[i].zindex) - 1
        }
      }
    }
  }
  // updateMetricSystem(event: any) {
  //   console.log(event, "checkupdatescale")
  //   console.log(this.printMap.getViewport(), "size of viewport")
  //   this.inMetricSystem = event.target.innerText;
  //   console.log(this.inMetricSystem, "finding the valuee")
  //   var scale = parseFloat(this.inMetricSystem.replace(/[^0-9.]/g, ''));

  //   if (this.inMetricSystem.includes("km")) {
  //     console.log(" i am in km")
  //     this.reverseScaleCalculation = scale * 100000
  //   } else if (this.inMetricSystem.includes("m")) {
  //     console.log(" i am in m")
  //     this.reverseScaleCalculation = scale * 100
  //   }
  //   // else if(this.inMetricSystem.includes("cm")){
  //   //   console.log(" i am in cm")
  //   //   this.reverseScaleCalculation = scale * 1
  //   // }

  //   console.log(this.reverseScaleCalculation, "check the reverse calculated scale")

  //   var view = this.printMap.getView();
  //   console.log(view, "check view of the printmap")
  //   var dpi = 25.4 / 0.28;

  //   var resolution = (this.reverseScaleCalculation / (100 * this.unitConversion / 0.0254));

  //   //this is the formulae we are working on   // var resolution = this.reverseScaleCalculation * 0.0254 / 90.71 // which works different in 3857 and 4326 projection..


  //   // var resolution = this.reverseScaleCalculation * 0.0254 / 90.71
  //   // var resolution = 139700 / 1100
  //   console.log(resolution, "cexfgchvjk")
  //   // var resolution = 0.254 * (this.reverseScaleCalculation / 100)
  //   // if(resolution < view.getMinResolution()){
  //   //   resolution = view.getMinResolution()
  //   // }else if(resolution >view.getMaxResolution()){
  //   // resolution = view.getMaxResolution()
  //   // }
  //   // console.log("scale:", this.reverseScaleCalculation, "dpi:", dpi, "unitConversion:", this.unitConversion, "check inputs before conversion");
  //   console.log("resolution:", resolution, "check the resolution value....");
  //   console.log("maxResolution", view.getMaxResolution(), "minResolution", view.getMinResolution())
  //   // check if resolution is valid before setting it on the view
  //   if (resolution > 0 && resolution <= view.getMaxResolution() && resolution >= view.getMinResolution()) {
  //     view.setResolution(resolution);
  //   } else {
  //     console.error("Invalid resolution:", resolution);
  //   }

  // }

  // updateMilesSystem(event: any) {
  //   this.inMilesSystem = event.target.innerText;
  //   console.log(this.inMilesSystem, "check the updated value in miles")
  // }
  // editableMetricField() {
  //   $(".inMetric").attr("contenteditable", "true");
  //   $(".inMetric").focus()

  //   $(".inMetric").keypress(function (e) {
  //     if (e.which === 13) {
  //       $(this).attr("contenteditable", "false");
  //     }
  //   })

  // }

  // editableMilesField() {
  //   $(".inMiles").attr("contenteditable", "true");
  //   $(".inMiles").focus()

  //   $(".inMiles").keypress(function (e) {
  //     if (e.which === 13) {
  //       $(this).attr("contenteditable", "false");
  //     }
  //   })

  // }
  closePrintTool() {
    this.showPresentaiton = false;
    console.log('need to clear')
    this.printMap.getLayers().forEach((layer) => {
      this.printMap.removeLayer(layer);
    });
  }
  updatescaleviewfactor(e) {
    // e=e/this.PreviewToPhysicalRatio
    // e=e*0.8476312239
    e = e * 1.0031767
    this.updatedratio = e;

    var view = this.printMap.getView();
    console.log(e, view, "checktheupdaterscaleafterenter")

    var projection = view.getProjection();
    var metersPerUnit = projection.getMetersPerUnit();
    var units = projection.getUnits();
    var Zoom = view.getZoom()
    var Resolution = view.getResolution()
    console.log(Zoom, Resolution, "checkzoomResolutionInitial")
    var is3857 = projection.getCode() === 'EPSG:3857';


    // var resolution;
    // if (is3857) {
    //   resolution = (e * (97834.2 / 349425722.0250));
    // } else {
    //   var center = view.getCenter();
    //   var lat = center[1];
    //   if (units === 'degrees') {
    //     // resolution = e / (dpi * 39.37 * Math.cos(lat * Math.PI / 180) * metersPerUnit);
    //     resolution = e * (0.087890625 / 34942572.0250)
    //   } else {
    //     resolution = e * (0.087890625 / 34942572.0250)
    //     // resolution = e / (dpi * 39.37 * metersPerUnit);
    //   }
    // }
    var resolution =
      (e / 1000) /
      getPointResolution(
        this.printMap.getView().getProjection(),
        this.testingDPI / 25.4,
        this.printMap.getView().getCenter()
      );

    if (resolution > 0 && resolution <= view.getMaxResolution() && resolution >= view.getMinResolution()) {
      view.setResolution(resolution);
    } else {
      console.error("Invalid resolution:", resolution);
    }
    this.changeviewfactor = true;
    console.log(resolution, this.unitConversion, this.printMap.getView().getZoom(), metersPerUnit, projection, units, "lat:", "resolution:", resolution, "cexfgchvjk")
    this.printMap.controls.forEach((control) => {
      console.log(this.printMap, control, 'vales....');
      if (this.commonService.isValid(control.values_)) {
        if (control.values_.units !== undefined) {
          console.log(control, 'check all the controls....');
          setTimeout(() => {
            // console.log('Here scal line ', control, control.renderedHTML_, control.element.innerText);
            this.mapProjectionUnitsPopup = this.setMapProjectionUnits(
              control.element.innerText, this.updatedratio
            );
            console.log(control.element.innerText, "checkscalebar")
          }, 1000);
        }
      }
    });


  }

}
// enoughhhhhhhhhhhhhhhhhhhhhhhhhhhhhh