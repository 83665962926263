<div [ngClass]="{'fixed-alert': true, 'show': showAlert}">
    <div class="fixed-alert-container">
        <ngb-alert [type]="alertType" (close)="closeAlert()" [dismissible]="true">
            {{ alertMessage }}
        </ngb-alert>
    </div>
</div>
<div [ngClass]="{'login-and-app-info-container': true, 'move-left': globalObject.pageType === pageTypes.COVID19}">
    <div class="login-container">
        <app-user-login
        [pageType]="globalObject.pageType"
        [userProfile]="loggedInUserProfile"
        [userInfo]="userInfo"
        [pageClickEvent]="showOrHideUserLoginMenu"
        (openPage)="openPageFun($event)"></app-user-login>
    </div>
    <!-- <div class="app-info-container">
        <button mat-raised-button (click)="openAppInfo($event, appInfoContent)">App Info</button>
    </div> -->
</div>
<!-- <div class="hbcu-EW-logo">
    <div class="hbcu-EW-logo-container">
        <a class="hbcu-EW-logo-link" target="_blank" href="https://www.hbcueweek.com/">
          <img matTooltip="HBCU ENTREPRENEUR'S WEEK" matTooltipClass="mat-custom-tooltip" src="assets/HBCU-EW-LOGO.png"/>
        </a>
      </div>
</div> -->
<app-basemap (click)="pageClickEventOnMap($event)"
    [pageType]="globalObject.pageType === pageTypes.COVID19 ? 'COVID19' : 'DEFAULT'"
    [basemapType]="globalObject.pageType === pageTypes.COVID19 ? 'googlestreet' : 'bingstreet'"></app-basemap>
<app-geotray *ngIf="globalObject.pageType !== pageTypes.COVID19" (click)="pageClickEventfun($event)"
    [closeNotepad]="closeNotepad"
    [isGuest]="isGuest"
    (showGeopadWindow)="showGeopadWindowFun($event);"
    [closeGeoSession]="closeGeoSession"
    (showGeoSessionWindow)="showGeoSessionWindowFun($event);"
    [packageEvent]="packageEventsSubject.asObservable()"
    (showAnalyDropdown)="showAnalyDropdownEvent($event)" (showGeoDropdown)="showGeoDropdownEvent($event)"
    ></app-geotray>
<app-annotation-tool 
    [triggerAnnotation]="triggerAnnotation" (saveAnnotation)="saveAnnotationFun($event)"></app-annotation-tool>
<app-geobar (click)="pageClickEventfun($event)"
    [globalObject] = "globalObject"
    [userInfo]="userInfo"
    [userClickOnMap]="clickedOnMap"
    [packageModulesData]="packageModulesData"
    [currentSession]="currentSession"
    [packageEvent]="packageEventsSubject.asObservable()">

</app-geobar>
<app-geotower *ngIf="globalObject.pageType !== pageTypes.COVID19" (click)="pageClickEventfun($event)"
    [sessionId]="sessionId"
    [globalObject] = "globalObject"
    [currentSession]="currentSession"
    [isGuest]="isGuest"
    [userClickOnMap]="clickedOnMap">
</app-geotower>

<div *ngIf="showGeodropdowninfo" style="position: absolute;width: 47px;height: 104px;background:#2B2E34 !important;margin-bottom: 114px;right: 0%;bottom: 0%;z-index: 3;border-radius: 0.1rem;opacity: 0.95; border-radius: 9px;">
    <div class="row" *ngFor="let item of units" style="margin: 0;padding:0;cursor: pointer;">
        <div style="color: white;font-size: 11px;width: 100%;height: 100%;border-bottom: 1px solid white;text-align: center;" (click)="checkGeoDropdownClickFunc(item.name)">{{item.name}}</div>
    </div>
</div>
<div *ngIf="showAnalydropdowninfo"  style="position: absolute;width: 50px;height: 104px;background:#2B2E34 !important;margin: 1.7rem 3.5rem;right: 0%;bottom: 0%;z-index: 3;border-radius: 0.1rem;">
    <div class="row" *ngFor="let item of units" style="margin: 0;padding:0">
        <div style="color: white;font-size: 11px;width: 100%;height: 100%;border-bottom: 1px solid white;text-align: center;" (click)="checkAnalyDropdownClickFunc(item.name)" >{{item.name}}</div>
    </div>
</div>
<app-geosol (click)="pageClickEventfun($event)"
    [globalObject] = "globalObject"
    [isGuest]="isGuest"
    (triggerToShowFeSpalsh) = "showAsModalFun($event)"
    [packageEvent]="packageEventsSubject.asObservable()"
    (toggleAwareness)="toggleAwareness($event)" [awarenessCurrentMode]="viewMode">
</app-geosol>
<app-capture *ngIf="show" 
    [userInfo]="userInfo"
    [currentSession]="currentSession"
    [isGuest]="isGuest"
    [showCapture]="showCapture"
    [showExpandedView]="showCaptureExpandedView"
    [sessionId]="sessionId" [globalObject] = "globalObject"
    [markLocation]="markGeopadLocation" (closeCapture)="closeCaptureWindow()"
    [viewMode]="viewMode"
    [userProfileData]="loggedInUserProfile"
    (loadSession)="loadSessionFun($event)"
    [packageEvent]="packageEventsSubject.asObservable()"
    (storeNotesObject)="loadStoreNotesObject($event)">
</app-capture>
<!-- <app-geo-notepad *ngIf="show" [showNotePad]="showNotePad" (close)="showNotePad=false" ></app-geo-notepad> -->
<app-geo-session *ngIf="globalObject.pageType !== pageTypes.COVID19"
    [userInfo]="userInfo"
    [sessionId]="sessionId"
    [currentSession]="currentSession"
    [sessionUuid]="uuid"
    [globalObject] = "globalObject"
    [showExpandedView]="showSessionExpandedView"
    [showGeoSession]="showGeoSession" (closeGeoSession)="closeGeoSessionWindow()">
</app-geo-session>
<app-notification-bar (click)="pageClickEventfun($event)"></app-notification-bar>

<app-home (click)="feDashboardClick($event)" [hidden]="showDashboard"></app-home>

<div class="zoomin" *ngIf="globalObject.pageType === pageTypes.COVID19" (click)="zoomIn()" matTooltip="Zoom In" matTooltipClass="mat-custom-tooltip">
    <img src="assets/svgs/geosol/plus_white.svg">
</div>
<div class="zoomout" *ngIf="globalObject.pageType === pageTypes.COVID19" (click)="zoomOut()" matTooltip="Zoom Out" matTooltipClass="mat-custom-tooltip">
    <img src="assets/svgs/geosol/minus_white.svg">
</div>
<div class="scale-container-for-covid" *ngIf="globalObject.pageType === pageTypes.COVID19" [ngStyle]="{'width': scaleLineWidth+'px'}">
    <!-- <div class="scale-container"> -->
    <span>{{mapProjectionUnits}}</span>
</div>
<!-- <button class="covid-app-info-btn" *ngIf="globalObject.pageType === pageTypes.COVID19"
    (click)="showCovidInfo = true">
    <span class="material-icons"> info </span>
</button> -->

<!-- <div class="covid-info-container">
    <button class="covid-info-close">x</button>
    <img class="covid-info" src="assets/covid/covid-info.png">
</div> -->
<div class="login-prompt-container" *ngIf="globalObject.pageType === pageTypes.COVID19 && showCovidInfo">
    <div class="login-prompt-area">
        <div class="screens-btns-container">
            <button class="close-btn" (click)="showCovidInfo=false">
            &#10006;
            </button>
            <div class="screens-image-container">
            <img src="assets/covid/covid_info.jpg">
            </div>
        </div>
    </div>
</div>

<button class="covid-info-legend-trigger-btn" cdkDrag *ngIf="globalObject.pageType === pageTypes.COVID19 && !showCovidInfoLegend"
    (click)="showCovidInfoLegend = true">
    <!-- <span class="material-icons"> info </span> -->
    L
</button>

<div class="covid-info-legend-container" cdkDrag *ngIf="globalObject.pageType === pageTypes.COVID19 && showCovidInfoLegend">
    <button class="close-btn" (click)="showCovidInfoLegend=false">
        <!-- &#10006; -->
        &#8722;
    </button>
    <div class="covid-info-legend">
        <div class="info-type-container">
            <div class="info-symbol" style="border-radius: 10px;"></div>
            <div class="info-name">Need</div>
        </div>
        <div class="info-type-container">
            <div class="info-symbol"></div>
            <div class="info-name">Availability</div>
        </div>
        <hr>
        <div class="info-type-container" *ngFor="let topic of infoNameAndColors">
            <div class="info-symbol" [ngStyle]="{'background': topic.color}"></div>
            <div class="info-name">{{topic.name}}</div>
        </div>
    </div>
</div>


<div class="login-prompt-container" *ngIf="globalObject.pageType === pageTypes.COVID19 && playCovidInfoVideo">
    <div class="login-prompt-area">
        <div class="screens-btns-container">
            <!-- <button class="close-btn" (click)="playCovidInfoVideo=false"> &#10006; </button>
            <div class="screens-image-container" style="width: 100%; height: auto">
                <iframe style="width: 100%; height: 100%;" width="100%" height="auto"
                    [src]="getYoutubeEmbedUrl('https://www.youtube.com/watch?v=won79zIVe5Q', true) | safe" 
                    allow='autoplay; encrypted-media' frameborder="0" allowfullscreen>
                </iframe>
            </div> -->
        </div>
    </div>
</div>



<!-- MANAGEMENT PAGES -->
<div class="mgmt-modal-container" *ngIf="currentPage !== ''">
    <div class="mgmt-modal-area">
  
      <!-- LOGIN PAGE -->
        <div class="mgmt-page-container">
            <div class="mgmt-header-container">
                <div class="title">
                    <img src="{{pageIcon}}">
                    <p>{{pageTitle}}</p>
                </div>
                <button class="close-btn" (click)="closePage()">
                    &#10006;
                </button>
            </div>
            <div class="mgmt-body-container">
                <app-user-profile style="width: 100%;" *ngIf="currentPage === 'profile'"
                    [userProfile]="loggedInUserProfile">

                </app-user-profile>
                <app-user-mgmt style="width: 100%;" *ngIf="currentPage === 'user-mgmt'"
                    [userProfile]="loggedInUserProfile">

                </app-user-mgmt>
                <app-project-mgmt style="width: 100%;" *ngIf="currentPage === 'proj-mgmt'"
                    [userProfile]="loggedInUserProfile">
                </app-project-mgmt>
                <app-team-mgmt style="width: 100%;" *ngIf="currentPage === 'team-mgmt'"
                    [userProfile]="loggedInUserProfile">
                </app-team-mgmt>
                <app-subscription-billing style="width: 100%;position: relative;" *ngIf="currentPage === 'subscription-billing'"
                    [userProfile]="loggedInUserProfile">
                </app-subscription-billing> 
            </div>
            <!-- <div class="mgmt-footer-container">

            </div> -->
        </div>
    </div>
</div>

<div id="subscriptionModal" class="modal fade show" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered slide-top" style="max-width: 600px;">
      <div class="modal-content rounded-3" style="background-color: rgba(252, 252, 252, 0.695); min-height: 400px;">
        <div class="modal-header bg-white border-bottom-0 rounded-top p-1">
          <h5 class="modal-title d-flex align-items-center" id="modalTitle" style="font-size: 1rem;">
            <span class="material-icons me-2">person</span>
            Subscription and Billing
          </h5>
          <button type="button" class="btn" aria-label="Close" data-bs-dismiss="modal" style="border: none; background: transparent;">
            <i class="bi bi-x" style="font-size: 1.2rem; color: black;"></i>
          </button>
        </div>
        <div class="modal-body d-flex flex-column justify-content-center align-items-center text-center p-2" style="background-color: rgba(252, 252, 252, 0.2); font-family: 'Noto Sans', sans-serif;">
          <img src="assets/svgs/coming_soon_icon.svg" alt="Coming Soon" class="mb-3" style="width: 100px; height: auto;">
          <p class="mb-0">
            On this Subscription page, you can manage your billing, select packages, and check the details of your current plan. Easily view your package validity and make changes to suit your needs.
          </p>
        </div>
      </div>
    </div>
  </div>

<!-- FE SPLASH SCREEN -->
<!-- <div [ngClass]="{'fe-splash-modal-container': true, 'fe-splash-popup-modal': showAsModal}" *ngIf="showFeSplashScreen">
    <div class="fe-splash-modal-area">
        <div class="fe-splash-page-container">
            <button class="close-btn" *ngIf="showAsModal" (click)="showFeSplashScreen=false; showAsModal=false;">
                &#10006;
            </button>
            <div class="fe-splash-body-container">
                <div class="row">
                    <div class="col landingheader col-md-12">
                        <h3>Welcome to</h3>
                        <img class="felogolight" src="assets/svgs/splash-screen/fe_logo.png ">
                        <p> GIS isn't an expensive specialty anymore. </p>
                        <div matRipple class="landingpagebutton desktopview" (click)="showFeSplashScreen=false" matTooltip="Start Exploring" matTooltipClass="mat-custom-tooltip">
                            <button class="btn btn-lg btn-outline-secondary">
                                <img src="assets/svgs/splash-screen/explore_light.svg" width="70" /> Start Exploring
                            </button>
                        </div>
                    </div>
                  </div>  

                  <div class="row mobileview aligncenter">
                    <div class="col-md-12">
                        <div matRipple class="social-link" matTooltip="Playstore" matTooltipClass="mat-custom-tooltip"
                        (click)="openTourLink('playstore')">
                        <img class="appstore" src="assets/svgs/splash-screen/play-store.png">
                        </div>
                    </div>
                    </div>
               
              <div class="row">
                <div class="col landingfeature col-md-12">
                   <p class="features">Features</p>
                </div>
              </div>
                <div class="row bigscreenpadding">
                    <div class="col col-md-5 splash-box">
                        <div class="landinglinks" matRipple (click)="openTourLink('createSite')">
                            <img src="assets/svgs/splash-screen/create-sites-light.svg">
                        <p>Locate sites and create 
                        new site information</p></div></div>
                    <div class="col col-md-5 splash-box">
                        <div class="landinglinks" matRipple (click)="openTourLink('storeProject')">
                            <img src="assets/svgs/splash-screen/store-data-light.svg">
                        <p>Store project info
                        in secure cloud space</p></div></div>
                    <div class="col col-md-5 splash-box">
                        <div class="landinglinks" matRipple (click)="openTourLink('editManage')">
                            <img src="assets/svgs/splash-screen/edit-manage-project-light.svg">
                        <p>Edit and manage
                        project infromation</p></div></div>
                    <div class="col col-md-5 splash-box">
                        <div class="landinglinks" matRipple (click)="openTourLink('addGeodata')">
                            <img src="assets/svgs/splash-screen/geodata-analyze-light.svg">
                        <p>Add geodata and 
                        analyze your site</p></div></div>
                    <div class="col col-md-5 splash-box">
                        <div class="landinglinks" matRipple (click)="openTourLink('collaborate')">
                            <img src="assets/svgs/splash-screen/collaborate_light.svg">
                        <p>Collaborate and 
                        present your work</p></div></div>
                    </div>

                    <div class="row align-items-end">
                        <div class="col-md-3 landingFooter">
                            <div class="fe-tour-link-container" *ngIf="!showAsModal">
                                <div class="row-flex">
                                    <div class="column-flex">
                                        <div matRipple class="fe-tour-link" (click)="openTourLink('tour')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/tour_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="fe-tour-link" (click)="openTourLink('learn')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/learn_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="fe-tour-link" (click)="openTourLink('watch')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/watch_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="fe-tour-link" (click)="openTourLink('read')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/read_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="fe-tour-link" (click)="openTourLink('support')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/support_light.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 landingFooter">
                            <a href="https://www.mapsolgeo.com" target="_blank"><img class="footer-img" src="assets/image.png"></a>
                        </div>
                        <div class="col-md-3 landingFooter">
                            <div class="row-flex">
                                    <div class="column-flex">
                                        <div matRipple class="social-link" matTooltip="Facebook" matTooltipClass="mat-custom-tooltip"
                                        (click)="openTourLink('fb')">
                                        <img class="footersocialmedia" src="assets/svgs/splash-screen/fb_light.svg">
                                    </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="social-link" matTooltip="Linkedin" matTooltipClass="mat-custom-tooltip"
                                            (click)="openTourLink('linkedin')">
                                            <img class="footersocialmedia" src="assets/svgs/splash-screen/linkedin_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="social-link" matTooltip="Twitter" matTooltipClass="mat-custom-tooltip"
                                        (click)="openTourLink('twitter')">
                                        <img class="footersocialmedia" src="assets/svgs/splash-screen/twitter_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="social-link" matTooltip="Slack" matTooltipClass="mat-custom-tooltip"
                                    (click)="openTourLink('slack')">
                                    <img class="footersocialmedia" src="assets/svgs/splash-screen/slack_light.svg">
                                        </div>
                                    </div>
                                    <div class="column-flex">
                                        <div matRipple class="social-link" matTooltip="Instagram" matTooltipClass="mat-custom-tooltip"
                                        (click)="openTourLink('instagram')">
                                        <img class="footersocialmedia" src="assets/svgs/splash-screen/instagram.svg">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>                
            </div>           
    </div>
</div> -->
<div class="main-body" [ngClass]="{'fe-splash-modal-container': true, 'fe-splash-popup-modal': showAsModal}" *ngIf="showFeSplashScreen">
    <div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100" *ngIf="landingpageDiv">
        <div class="position-absolute top-0 start-0 mt-3 ms-3 icon-link">
            <nav class="landingheader">
                <a href="https://mapsolgeo.com/contact-us/" target="_blank"><img src="../../assets/img/support_icon.svg" alt="support" class="social-link"></a>
                <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/blog_icon.svg" alt="blog" class="social-link"></a>
                <a href="https://mapsolgeo.com/tour/" target="_blank"><img src="../../assets/img/tour_icon.svg" alt="tour" class="social-link"></a>
                <a href="https://mapsolgeo.com/tutorials/" target="_blank"><img src="../../assets/img/tutorials_icon.svg" alt="tutorials" class="social-link"></a>
                <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/video_icon.svg" alt="video" class="social-link"></a>
            </nav>
        </div>
        <div class="position-absolute top-0 end-0 mt-3 me-3">
            <p>Already an user? <a  class="btn btn-secondary" (click)="landingpageDiv=false; userLoginDiv=true;">Login</a></p>
            
        </div>
        <div class="text-center mb-4 logo">
            <img src="../../assets/img/fe-logo.svg" alt="fuse.Earth Logo" width="300" class="logo mb-2">
        </div>
        <form [formGroup]="sessionLoginForm" id="emailForm" class="text-center">
            <div class="mb-3">
                <input formControlName="email" type="email" class="form-control" #emailInput id="emailInput" placeholder="Enter Email" required>
                <span *ngIf="!sessionLoginForm.get('email').valid && sessionLoginForm.get('email').touched" class="error">
                    {{getErrorMsg(sessionLoginForm.get('email'), 'Email')}}
                </span>
            </div>
            <div class="d-grid">
            <button (click)="userSessionNonLogin(emailInput.value)" type="submit" class="btn btn-secondary">Let's Go</button>
            </div>
        </form>
        <footer class="position-absolute bottom-0 start-0 m-3 d-flex justify-content-between">
            <p class="text-muted mb-0"><a href="https://mapsolgeo.com" target="_blank"><img src="../../assets/img/mapsol-logo.svg" alt="fuse.Earth Logo" width="250" class="mb-2"></a></p>
            <div class="icon-link align-self-end">
                <nav>
                    <a href="https://www.facebook.com/fuse.earth" target="_blank"><img src="../../assets/img/facebook_icon.svg" alt="Facebook" class="social-link"></a>
                    <a href="https://www.linkedin.com/showcase/fuse-earth/" target="_blank"><img src="../../assets/img/linkedin_icon.svg" alt="LinkedIn" class="social-link"></a>
                    <a href="https://www.instagram.com/fuse_earth/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank"><img src="../../assets/img/instagram_icon.svg" alt="Instagram" class="social-link"></a>
                    <a href="https://x.com/fuse_earth" target="_blank"><img src="../../assets/img/twitter_icon.svg" alt="Twitter" class="social-link"></a>
                </nav>
            </div>
        </footer>
    </div>
    <div *ngIf="userLoginDiv">
            <app-landing-login
                [pageType]="globalObject.pageType"
                [userProfile]="loggedInUserProfile"
                [userInfo]="userInfo"
                [pageClickEvent]="showOrHideUserLoginMenu"
                (triggerToShowFeSpalsh) = "showAsModalFun($event)"></app-landing-login>
    </div>
    <div *ngIf="userSignupDiv">
        <app-landing-signup  [userProfile]="loggedInUserProfile" [userInfo]="userInfo"
            [pageClickEvent]="showOrHideUserLoginMenu" (signupToLoginEmit) = "signupToLoginFun($event)"></app-landing-signup>
    </div>
    </div>
<!--Here for popup code-->
<div class="confirm-delete-container" *ngIf="showUserLoginAlert">
    <div class="confirm-delete" *ngIf="showUserLoginAlert">
        <div class="header">
          <!-- <i class="fa fa-times" (click)="showUserLoginAlert=false"></i> -->
        </div>
        <div class="confirm-body">
          <p class="confirm-text">{{inputText}}</p>
          <div class="btns-container">
            <div class="yes-btn-container">
                <button (click)="defaultFEURL()">Okay</button>
            </div>
            <!-- <div class="no-btn-container">
              <button (click)="showUserLoginAlert=false">Cancel</button>
            </div> -->
          </div>
        </div>
    </div>
  </div>