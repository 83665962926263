
<div #container class="popup interaction-info-container" ngDraggable style="min-width: 1000px; min-height: 200px;">
    <i  #closer class="close-icon interaction-info-close"></i>
    <h3 class="interaction-info-title">GeoTables</h3>
    <div class="interaction-info-body-container">
        <div style="width: 100%; overflow: hidden;">
            <div style="float: left;"> 
                <select class="select" (change)="onChange($event.target.value)">
                    <option value="NoLable">Select Column</option>
                    <option *ngFor="let column of columnsList; let i = index" [value]="column">{{column}}</option>
                </select>
                <!-- <button name="generategeotable" (click)="generategeotable()">+</button> -->
                <i class="fa fa-plus-square fa-2x"  (click)="generategeotable()"></i>
            </div>
            <div style="display: flex;float: left;"> 
                <div *ngIf="activerowSelection">            
                    <span><i class="fa fa-star fa-2x" [style.color]="symColor"></i></span>
                    <span class="text">{{selectedColumn}}</span>
                    <!-- <select>
                        <option *ngFor="let row of rowsList; let i = index" [value]="row">*</option>
                    </select> -->
                    <!-- <div *ngFor="let row of rowsList; let i = index" >
                        <span (click)="changeSymbolColor()">
                            <i class="fa fa-star fa-2x" [style.color]="symColor"></i>
                        </span>
                        <span class="text">{{row.State}}</span>
                    </div> -->
                    <div *ngFor="let row of selectedColumnRowsList; let i = index" >
                        <span (click)="changeSymbolColor(i)">
                            <i #selectedColumEle [attr.id]="'selectedRowID' + i" class="fa fa-star fa-2x" style="color: black;"></i>
                        </span>
                        <span class="text">{{row}}</span>
                    </div>
                </div> 
            </div>
            <div>
                <div *ngIf="activeSymbleColorSelection">
                    <div>
                        <span><i class="fa fa-star fa-2x" [style.color]="symColor"></i></span>
                        <span class="text">Value</span>
                    </div>
                    <div>
                        <input type="color" (change)="changeColor($event.target.value)">
                        <input type="search" placeholder="Search">
                    </div>
                    <div>
                        <span class="text"><i (click)="changeSymbol('fa-star')" class="fa fa-star fa-2x" [style.color]="symColor"></i></span>
                        <span class="text"><i (click)="changeSymbol('fa-circle')" class="fa fa-circle fa-2x" [style.color]="symColor"></i></span>
                        <span class="text"><i (click)="changeSymbol('fa-square')" class="fa fa-square fa-2x" [style.color]="symColor"></i></span>
                        <span class="text"><i (click)="changeSymbol('fa-octagon')" class="fa fa-octagon fa-2x" [style.color]="symColor"></i></span>
                        <span class="text"><i (click)="changeSymbol('fa-plus')" class="fa fa-plus fa-2x" [style.color]="symColor"></i></span>
                    </div>
                </div>                
            </div>
       </div>        
    </div>
  </div>