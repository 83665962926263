import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsfdiServiceService {
  private depictionData = new BehaviorSubject<any>(null);
  private connPropertiesSource = new BehaviorSubject<any>(null);
  currentConnProperties = this.connPropertiesSource.asObservable();

  setConnectionProperties(properties: any) {
    console.log('Setting Connection Properties in Service:', properties);
    this.connPropertiesSource.next(properties);
  }
  getSpecificationData(properties: any) {
    console.log('Setting Connection Properties in Service:', properties);
    this.connPropertiesSource.next(properties);
  }

  setDepictionData(data: any) {
    console.log('Data set in service:', data);  
    this.depictionData.next(data);
  }

  
  getDepictionData() {
    return this.depictionData.asObservable();
  }

  constructor() { }
}
