<div [hidden]="!showpackagedetails" >
    <div style="    display: flex;
    margin-top: 12%;
    margin-bottom: 7%; ">

        <div class="tp-wrapper " *ngFor="let pkg of packages ; let i=index">
            <div  class="tp-box" (click)="selectstarterpack(i)" [class.selected]="i === cardno" [@flipState]="pkg.Flipstate">
                <div class="tp-box__side tp-box__front">
                    <div style="padding-top: 5px;">
                        <img *ngIf="i === cardno" src="../../../assets/images/tick.png" alt=""
                            width="30px" height="30px"
                            style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                    </div>
                    <div
                        style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  
                        font-size: 14px;
                    ">
                        {{pkg.name}}</div>
                    <div [hidden]="!isTotalPrice" style="color: black; font-size: 16px;"><b>${{pkg.amount}}</b></div>
                    <div [hidden]="isTotalPrice" style="color: black; font-size: 16px;"><b>${{pkg.Totalamount}}</b></div>
                    <div style="color: black; margin-top: 8%;margin-bottom: 9%;font-size: 12px;">Per User</div>
                    <div>
                        <select class="month" name="attribute" id="attribute" placeholder="none"
                            (change)="Selectexploremonth($event.target.value, pkg.amount,i)"
                            style="    margin-bottom: 5%;">

                            <option value="attribute" [value]="item"
                                *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                        </select>
                    </div>

                    <div><a (click)="toggleFlipStarter(i)"
                            style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                    </div>
                </div>
                <div class="tp-box__side tp-box__back">

                    <p>Features</p>
                    <ul style="text-align:left;font-size: 10px;margin-left: -17px">
                        <li *ngFor="let feature of pkg.packageItems ; let j=index">{{feature.name}}</li>
                    </ul>
                    <a (click)="toggleFlipStarter(i)"
                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px;text-decoration: underline;">Back</a>

                </div>
            </div>
        </div>

        <!-- <div class="tp-wrapper">
            <div class="tp-box" (click)="selectexplorepack()" [@flipState]="flipExplore">
                <div class="tp-box__side tp-box__front">
                    <div style="    padding-top: 5px;">
                        <img [hidden]="!showexploretick" src="../../../assets/images/tick.png" alt=""
                            width="30px" height="30px"
                            style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                    </div>
                    <div
                        style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  font-size: 22px;">
                        Explore</div>
                    <div style="color: black; font-size: 16px;"><b>{{selectedexpplanprice}}</b></div>
                    <div style="color: black;">10 User</div>
                    <div>
                        <select class="month" name="attribute" id="attribute" placeholder="none"
                            (change)="Selectexploremonth($event.target.value)"
                            style="    margin-bottom: 5%;">

                            <option value="attribute" [value]="item"
                                *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                        </select>
                    </div>
                    <a (click)="toggleFlipExplore()"
                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                </div>
                <div class="tp-box__side tp-box__back">

                    <p>Features</p>
                    <ul style="text-align:left;font-size: 10px;margin-left: -17px;overflow: auto;">
                        <li>Look up locations</li>
                        <li>Change basemaps</li>
                        <li>Add GIS data</li>
                        <li>Measure distances and areas</li>
                        <li>Create your own GIS data</li>
                        <li>Share your work</li>
                        <li>Georeference</li>
                        <li>Add project data</li>


                    </ul>
                    <a (click)="toggleFlipExplore()"
                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px; text-decoration: underline;">Back</a>

                </div>
            </div>
        </div> -->

        <!-- <div class="tp-wrapper">
            <div class="tp-box" (click)="selectengagepack()" [@flipState]="flipEngage">
                <div class="tp-box__side tp-box__front">
                    <div style="    padding-top: 5px;">
                        <img [hidden]="!showengagetick" src="../../../assets/images/tick.png" alt=""
                            width="30px" height="30px"
                            style="position: absolute; top: -11px; right: -9px; background-color: white;border-radius: 50%;">
                    </div>
                    <div
                        style="background-color:#32373D;color:white;margin-bottom: 10px;margin-top: 20px;  font-size: 22px;">
                        Engage</div>
                    <div style="color: black; font-size: 16px;"><b>{{selectedengageprice}}</b></div>
                    <div style="color: black;">50 User</div>
                    <div>
                        <select class="month" name="attribute" id="attribute" placeholder="none"
                            (change)="Selectengagemonth($event.target.value)"
                            style="    margin-bottom: 6%;">

                            <option value="attribute" [value]="item"
                                *ngFor="let item of months; let i=index" id="{{i}}">{{item}}</option>

                        </select>
                    </div>
                    <div>
                        <a (click)="toggleFlipEngage()"
                            style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;text-decoration: underline;font-size: 12px;">Details</a>
                    </div>
                </div>
                <div class="tp-box__side tp-box__back">

                    <p>Features</p>
                    <ul style="text-align:left;font-size: 10px;margin-left: -17px;overflow: auto;">
                        <li>Look up locations</li>
                        <li>Change basemaps</li>
                        <li>Add GIS data</li>
                        <li>Measure distances and areas</li>
                        <li>Create your own GIS data</li>
                        <li>Share your work</li>
                        <li>Georeference</li>
                        <li>Add project data</li>
                        <li>Custom query GIS data</li>
                        <li>Symbolize your work</li>
                        <li>Project manage your work</li>
                        <li>Make printable maps</li>
                        <li>Create your teams</li>


                    </ul>
                    <a (click)="toggleFlipEngage()"
                        style="    margin-bottom: 20px;  margin-top: 10px;color: #32373D;font-size: 12px;text-decoration: underline;">Back</a>

                </div>
            </div>
        </div> -->

    </div>
        <div style="font-size: 20px;color: aliceblue;">
            <h4 style="text-align: center;">Expires on {{ enddate }}</h4>
            <h4 style="text-align: center;margin-bottom: 0.3rem;">({{Difference_In_Days}} days)</h4>
            <button *ngIf="!confirmselect" disabled class="renew-button" (click)="showalert()" >Renew / Change Plan</button>
            <button *ngIf="confirmselect" class="renew-button" (click)="Nextpage()">Renew / Change Plan</button>
            <div>
            <!-- <button>Renew / Change Plan</button> -->
            </div>
        
        </div>
</div>
<div [hidden]="!showpaymentdetails">
<div class="d-flex" style="color:#F0F0F0;    margin: 3%; position: relative;" >
                    
    <div style="text-align:left;width: 40%;">
        <div style="    margin-left: 1%;
        font-size: 25px;
        text-decoration: underline;
        margin-bottom: 2px;
        margin-right: 10px;">Billing Address</div>
        <div style="    margin-left: 1%;
        font-size: 22px;
        margin-top: 2%;">
        Company Name
        </div>
        <div style="margin-left: 1%;
        font-size: 22px;">
        Company Address 
        </div>
    </div>
    <div style="width: 100%;">
        <div>
            <div style="font-size:24px;text-decoration: underline;text-align: left;margin-left: 10px;margin-top: 2px;">
            Order Summary
            </div>
            <div class="d-flex" style="text-align: left;width:100%; width: 100%;border-bottom: 2px solid lightgray;margin-top: 2%;" >
                <div style="font-size: 22px; margin-left:2%;">
                    Premiuim X ({{finalselectedmonth}})
                </div>
                
                <div style="margin-left: 58%; margin-bottom: 2%;">
                    {{finalselectedprice}}
                    
                </div>
                <br>
            
            </div>
            <div class="d-flex" style="text-align: left; width: 100%;border-bottom: 2px solid lightgray;margin-top: 2%;" >
                <div style="font-size: 22px; margin-left:18%;">
                Tax
                </div>
                
                <div style="margin-left: 71%;">
                    $ 20
                    
                </div>
                <br>
                
            </div>
            <div class="d-flex" style="text-align: left; width: 100%;" >
                <div style="font-size: 22px; margin-left:17%;">
                    Total
                </div>
                    
                <div style="margin-left: 68%; margin-top: 1%;">
                    $ {{totalprice}}
                        
                </div>
                <br>
                    
            </div>
                
        
        </div>

    </div>
    

</div>
<p *ngIf="showgreetings"style="    font-size: 21px;
color: white;
text-align: center;
margin-top: 20%;">Thankyou! An Email is been sent to the admin. Pay the Subscription to activate your account.</p>

<div style="position: absolute;bottom: 0;width: 100%;">
    <div style="display: flex;justify-content: space-between;">
        <button *ngIf="!showgreetings" class="back-button" (click)="Backpage()">Back</button>
        <button  *ngIf="!showgreetings" class="back-button" (click)="Submitpage()" >Submit</button>
    </div>

</div>
</div>
