<!-- PRESENTATION TOOL SCREEN -->
<div class="presentation-tool-modal-container" [hidden]="!showPresentaiton">
    
    <div class="presentation-tool-modal-area" >
        
        <div class="presentation-tool-page-container">
            <div class="button">
                <span class="close-btn" style="position: relative;
                z-index: 11;
           "
                                    (click)="closePrintTool()">
                    <img src="../../assets/svgs/new/cross_icon.svg" style="float: right;
                    width: 28px;
                    filter: contrast(.7);" />
                </span>
            </div>


            <div class="presentation-tool-body-container" style="
            height:94%;
            position: relative;
            top: -0.5rem;
        ">
                <div class="col-xl-12">
                    <div class="d-flex">
                        <div id="main-div" class="col-xl-7" style="height: 45rem;
                        position: relative;
                        top: -28px;
                        background-color: #ededed;
                        z-index: 2;
                        border-radius: 16px;
                    " #ptbc>
                                
                               

                           
                                
                                <div class="print-style" (click)="selectboc($event)" id="print-style" #childDiv
                                    style="    box-shadow: grey 3px 3px 3px; width: 453px; height: 640px; margin: 33px auto auto;    margin-left: -10px;">
                                    <!-- <canvas id="c"  width="453px" position="absolute" height="640px" ></canvas> -->
                                    <div class="print-map" style="z-index: 10;" id="map-mini" #mapDiv
                                        (mousemove)="printMapMoveEvent($event)">
                                        <div  style="position: absolute;top: 0%;left: 0%;z-index: 10;" id="navigateArrow" class="compass-move"
                                        #navigateN (mousemove)="navigateMoveEvent($event)">
                                        <img draggable class="navigate compass-move" src="../../assets/svgs/properties_icon/new/true-north.svg" *ngIf='showNavigationArrow'
                                            style="width: 58.2353px;
                                            height: 58.2353px;" id="rotationN" #rotateN
                                            (dblclick)="setDefaultPosition($event)" (mousemove)="navigateMoveEvent($event)">
                                        <!-- <div id="scalebar" *ngIf="showProjectionUnits"
                                            style="background: #e2e2e2;color:#2B2E34;border:0.5px solid black;text-align:center;height: 17px;">
                                            {{mapProjectionUnitsPopup}}</div>
                                            <div id="scalebar1" *ngIf="showProjectionUnits"
                                            style="background: #e2e2e2;color:#2B2E34;border:0.5px solid black;text-align:center;height: 17px;">
                                            {{scalevalue}}</div> -->
                                           
                                            
                                    </div>
                                        <!-- <div class="viewfactorvalue">1 : {{viewfactorforui}}</div> -->
                                    </div>
                                    <div class="logo-move" style="bottom: 0%;position:absolute;right: 0%;z-index: 10;width: 150px;height:36px;resize: both;"  (mousemove)="logoMoveEvent($event)" #mapLogo>
                                   <div 
                                   style="width: 100%;height:100%;" 
                                   >
                                   <img src="../../assets/svgs/properties_icon/new/logo-bg.svg" style="width: 100%;height: 100%;" ></div>
                                </div>
                                   
                                    <div style="position: absolute;bottom: 0%;margin: 0 1rem;" *ngIf="checkCredit" class="credit-move">
                                        {{creditData}}</div>
                                    <div class="image-div upload-move" [src]=uploadedImage *ngIf="checkUpload"
                                    style="width: 150px;height:150px;position: absolute;right: 0%;overflow: auto;"
                                     (mouseenter)="imageDeleteButton()" (mousemove)="imageMoveEvent($event)"
                                    #imageDiv>
                                    <img [src]=uploadedImage *ngIf="checkUpload"
                                    style="width: 100%;height:100%;"
                                        >
                                    </div>
                                    <div *ngIf="showLegend" id="legendboxdiv" (click)="selectlegend($event)"
                                        style="border:1px solid black;z-index: 11;opacity:0.95;border:1px soild grey;width: auto;height:auto;background: #E0E0E0;position: absolute;resize: both;overflow: hidden;min-width: 197px;min-height: 60px;"
                                        class="legendBox" #legendDiv (mousemove)="legendMoveEvent($event)">
                                        <p id="legendheading" (click)="selectelegendheading($event.target.id)"
                                            style="font-size:14px;font-weight: bold;margin: 0 1rem;text-decoration: underline;">
                                            Legend</p>
                                        <div class="row" *ngFor="let active of activeSitesForPresentation;let i = index;">
                                            <div style="width:83%;height: 20px;background: none;margin: 0.2rem 1.5rem;">
                                                <div class="row"
                                                    style="background: none;width: 100%;height: 100%;margin: 0 0.2rem;border: 0.5px solid black;">
                                                    <div 
                                                       ><img style="width: 30px;
                                                        height: 14px;
                                                        margin-bottom: 7px; "src={{active.icon}} alt=""></div>
                                                    <div
                                                        style="width: 75%;height: 100%;background: none;border-left: 0.1px solid black;">
                                                        <p [attr.id]="'legendtextdiv' + i" (click)="selectedtextoflegend($event)"
                                                            style="width: 100%;height:100%;font-size:12px;margin: 0 0.5rem;"
                                                            spellcheck="false" contenteditable>{{active.name}}</p>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                    
                                    </div>
                                </div>
                                <!-- <ul class="arrows" style="left:10px" *ngIf="showshapeeditmenue2" >
                                    <li style="list-style: none;margin-bottom: 1.5rem;">
                                        <div style="width: 50px;
                                        height: 50px;
                                        background: #f4f4f4;
                                        margin: -4px;
                                        margin-left: -110px;
                                        border-radius: 7px;
                                        box-shadow: 3px 3px 3px grey;
                                        padding: 6px;
                                        max-width: 4.5rem;" (click)="increaseZIndex()">
                                            <a>
                                                <img src="../../assets/svgs/new/up-arrow.svg" style="width: 113%;
                                                    height: 109%;
                                                    margin: -2px;
                                                    background-color: gainsboro;
                                                    padding: 9px;
                                                    border-radius: 7px;
                                                    margin-left: -2.5px;
                                                    border: 5px;" />
                                            </a>
                                        </div>
                                    </li>
                                    <li style="list-style: none;margin-bottom: 3rem;">
                                        <div style="width: 50px;
                                        height: 50px;
                                        background: #f4f4f4;
                                        margin: -4px;
                                        margin-left: -110px;
                                        border-radius: 7px;
                                        box-shadow: 3px 3px 3px grey;
                                        padding: 6px;
                                        max-width: 4.5rem;" (click)="decreaseZIndex()">
                                            <a>
                                                <img src="../../assets/svgs/new/down-arrow.svg" style="width: 113%;
                                                    height: 109%;
                                                    margin: -2px;
                                                    background-color: gainsboro;
                                                    padding: 9px;
                                                    border-radius: 7px;
                                                    margin-left: -2.5px;
                                                    border: 5px;" />
                                            </a>
                                        </div>
                                    </li>
                                  
                                </ul> -->
                            
                    
                        </div>
                        <div class="col-xl-1" style="position: relative;
                       ">
                            <ul class="shapeMenu" *ngIf="shapemenutoggle">
                                <li style="list-style: none;margin-bottom: 3rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="circleCount()">
                                        <a>
                                            <img src="../../assets/svgs/new/circle_icon_demo.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 3rem" >
                                    <!-- <canvas id="c" *ngIf="showCanvasSquare" width="564px" position="absolute" height="310px" ></canvas> -->
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;"  (click)="squareCount()">
                                        <!-- <input type="color" id="colorpicker"   style="border: none;"> -->

                                        <a>
                                            <img src="../../assets/svgs/new/square_icon_demo.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;">
                                    <div class="list-classs" style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    border: none;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="TriangleCount()">
                                        <a>
                                            <img src="../../assets/svgs/new/triangle_icon_demo.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                        <!-- <div class="svg-container-box d-flex">
                                            <img src="../../assets/svgs/new/triangle_icon.svg" style="width: 20%;height: 20%;margin: 0 0.5rem;"/>
                                            <img src="../../assets/svgs/new/triangle_icon.svg" style="width: 20%;height: 20%;margin: 0 0.5rem;"/>
                                            <img src="../../assets/svgs/new/triangle_icon.svg" style="width: 20%;height: 20%;margin: 0 0.5rem;"/>
                                        </div> -->
                                    </div>

                                </li>
                            </ul>
                            <ul class="geometry-bar" *ngIf="showSiteMarkerMenu">
                                <li style="list-style: none;margin-bottom: 3rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="pointerFuncClicked()">
                                        <a>
                                            <img src="../../assets/svgs/new/point_icon.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 3rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="lineFuncClicked()">
                                        <a>
                                            <img src="../../assets/svgs/new/line_icon.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;">
                                    <div class="list-classs" style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="polygonFuncClicked()">
                                        <a>
                                            <img src="../../assets/svgs/new/polygon_icon.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>

                                </li>
                            </ul>
                            <ul class="shapeMenu1" *ngIf="showtexteditmenue">
                                
                                <li  mat-raised-button
                                matTooltip="Bring it forward"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" [hidden]="!showshapeeditmenue2"  style="list-style: none;margin-bottom: 1.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;"(click)="increaseZIndex()">
                                        <a>
                                            <img src="../../assets/svgs/new/up-arrow.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>
                                </li>
                                <li mat-raised-button
                                matTooltip="Stroke color"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" style="list-style: none;margin-bottom: 1.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <input style="width: 46px;
                                        height: 48px;
                                        border: none;
                                        border-radius: 13px;
                                        margin-left: -4px;
                                        margin-top: -5px;" type="color" id="color-picker"
                                            (change)="updateStrokeColor($event.target.value)">
                                    </div>


                                </li>

                                <li *ngIf="showtextsizediv" style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Font size"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 65px;
                                    height: 53px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <select style="height: 40px;
                                        width: 55px;
                                        border: none;
                                        background-color: gainsboro;
                                       
                                        border-radius: 6px;" [(ngModel)]="selectedSize"
                                            (change)="changeFontSize($event.target.value)">
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="10">10px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="12">12px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="14">14px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="16">16px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="18">18px</option>
                                                <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="20">20px</option>
                                                <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="24">24px</option>
                                                <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="28">28px</option>
                                        </select>
                                    </div>

                                </li>
                                <li *ngIf="legendtools" style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Font size"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 65px;
                                    height: 53px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <select style="height: 40px;
                                        width: 55px;
                                        border: none;
                                        background-color: gainsboro;
                                       
                                        border-radius: 6px;" [(ngModel)]="selectedSize"
                                            (change)="changeFontSize($event.target.value)">
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="1">1px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="2">2px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="3">3px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="4">4px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="5">5px</option>
                                                
                                        </select>
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Font color"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <input style="width: 46px;
                                        height: 48px;
                                        border: none;
                                        border-radius: 13px;
                                        margin-left: -4px;
                                        margin-top: -5px;" type="color" id="color-picker"
                                            (change)="updateColor($event.target.value)">
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Background color"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <input style="width: 46px;
                                        height: 48px;
                                        border: none;
                                        border-radius: 13px;
                                        margin-left: -4px;
                                        margin-top: -5px;" type="color" id="color-picker"
                                            (change)="updateColor1($event.target.value)">
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Bold"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="updateBoldFontlegend()">
                                        <!-- <input type="color" id="colorpicker"   style="border: none;"> -->

                                        <a>

                                            <div style="    position: absolute;
                                                right: 1.6rem;
                                               
                                                font-size: 2rem;">B</div>

                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Italic"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="updateItalicFont()">
                                        <!-- <input type="color" id="colorpicker"   style="border: none;"> -->

                                        <a>

                                            <div style="    position: absolute;
                                                right: 2rem;
                                               
                                                font-size: 2rem;">i</div>

                                        </a>
                                    </div>

                                </li>
                                <li style="list-style: none;margin-bottom: 1.5rem;"  mat-raised-button
                                matTooltip="Underline"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;"  (click)="updateUnderline()">
                                        <!-- <input type="color" id="colorpicker"   style="border: none;"> -->

                                        <a>

                                            <div style="    position: absolute;
                                                right: 1.6rem;
                                               
                                                font-size: 2rem;">U</div>

                                        </a>
                                    </div>

                                </li>
                                <li  mat-raised-button
                                matTooltip="Push it backward"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip"  [hidden]="!showshapeeditmenue2" style="list-style: none;margin-bottom: 1.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="decreaseZIndex()">
                                        <a>
                                            <img src="../../assets/svgs/new/down-arrow.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                            <ul class="shapeMenu2"  *ngIf="showshapeeditmenue">
                                <li  mat-raised-button
                                matTooltip="Bring it forward"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" *ngIf="showshapeeditmenue2"  style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;"(click)="increaseZIndex()">
                                        <a>
                                            <img src="../../assets/svgs/new/up-arrow.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>
                                </li>
                                <li  mat-raised-button
                                matTooltip="Stroke style"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 65px;
                                    height: 53px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <select  (change)="strokeformatvalue($event.target.value)" style="height: 40px;
                                        width: 55px;
                                        border: none;
                                        background-color: gainsboro;
                                        font-size: 13px;
                                        border-radius: 6px;" placeholder="None">
                                        <option
                                        style="background-color: #707175;opacity: 0.25;color: white;"
                                        value='0,0'>None</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value='4,4'>_ _ _ _</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;margin-bottom: 35px;"
                                                value='12,0'>______</option>
                                            
                                                
                                        </select>
                                    </div>

                                </li>
                                <li  mat-raised-button
                                matTooltip="Stroke Thickness"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 65px;
                                    height: 53px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <select  (change)="strokewidthvalue($event.target.value)" style="height: 40px;
                                        width: 55px;
                                        border: none;
                                        background-color: gainsboro;
                                        font-size: 13px;
                                        border-radius: 6px;">
                                        <option
                                        style="background-color: #707175;opacity: 0.25;color: white;"
                                        value="4">None</option>
                                    
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="2">4px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="3">6px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="4">8px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="5">12px</option>
                                            <option
                                                style="background-color: #707175;opacity: 0.25;color: white;"
                                                value="6">14px</option>
                                                
                                        </select>
                                    </div>

                                </li>
                                <li  mat-raised-button
                                matTooltip="Fill color"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <input style="width: 46px;
                                        height: 48px;
                                        border: none;
                                        border-radius: 13px;
                                        margin-left: -4px;
                                        margin-top: -5px;" type="color" id="color-picker"
                                            (change)="updateShapeFillColor($event.target.value)">
                                    </div>

                                </li>
                                <li  mat-raised-button
                                matTooltip="Stroke color"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip" style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;">
                                        <input style="width: 46px;
                                        height: 48px;
                                        border: none;
                                        border-radius: 13px;
                                        margin-left: -4px;
                                        margin-top: -5px;" type="color" id="color-picker"
                                            (change)="updateShapeStrokeColor($event.target.value)">
                                    </div>

                                </li>
                               
                                <li  mat-raised-button
                                matTooltip="Push it backward"
                                matTooltipClass="my-tooltip-class"
                                aria-label="Button with tooltip"  *ngIf="showshapeeditmenue2" style="list-style: none;margin-bottom: 2.5rem;">
                                    <div style="width: 50px;
                                    height: 50px;
                                    background: #f4f4f4;
                                    margin: -4px;
                                    margin-left: 2px;
                                    border-radius: 7px;
                                    box-shadow: 3px 3px 3px grey;
                                    padding: 6px;
                                    max-width: 4.5rem;" (click)="decreaseZIndex()">
                                        <a>
                                            <img src="../../assets/svgs/new/down-arrow.svg" style="width: 113%;
                                                height: 109%;
                                                margin: -2px;
                                                background-color: gainsboro;
                                                padding: 9px;
                                                border-radius: 7px;
                                                margin-left: -2.5px;
                                                border: 5px;" />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-4" style="position: relative;padding:0 ;
                       ">
                            <form [formGroup]="addPresentationFormGroup">

                                <div class="row" style="margin: 1rem -0.5rem;">
                                    <!-- <p
                                        style="margin: 0.1rem 1rem;font-size: 21px;font-weight: bold;letter-spacing: 0.1rem;">
                                        Map Details</p> -->
                                    <!-- <div class="row" style="height: 30px;width: 130px;margin-left: 6.5rem;"> -->
                                    <!-- <div
                                            style="width: 40px;height:100%;background:white;margin-right: 0.3rem;border-radius: 0.2rem;">
                                            <a (click)="addTextField()">
                                                <img src="../../assets/svgs/new/textbox_icon.svg"
                                                    style="width: 70%;height: 70%;margin: 0.2rem 0.3rem;" />
                                            </a>

                                        </div>
                                        <div
                                            style="width: 40px;height:100%;background:white;margin-right: 0.3rem;border-radius: 0.2rem;">
                                            <a (click)="showSiteMenu()">
                                                <img src="../../assets/svgs/new/geometry_icon.svg"
                                                    style="width: 70%;height: 70%;margin: 0.2rem 0.3rem;" />
                                            </a>

                                        </div> -->
                                    <!-- <div style="width: 40px;height:100%;background:white;border-radius: 0 0.3rem;">
                                            <a >
                                                <img src="../../assets/svgs/new/shapes_icon.svg"
                                                    style="width: 70%;height: 70%;margin: 0.2rem 0.3rem;" />
                                            </a>

                                        </div> -->






                                    <!-- </div> -->
                                    <!-- <div style="height: 0.1rem;background:#2B2E34;margin: 0 1rem;width: 100%"></div> -->
                                </div>

                                <!-- <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Map Type</p>
                                        <select class="form-control" style="margin: 0;padding:0" name="addMaptype" formControlName="addMaptype" #mapType (change)="getMaptypeSelected(mapType)">
                                            <option value={{maptype.name}}  *ngFor="let maptype of mapTypesList"> {{maptype.name}} </option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Map Orientation</p>
                                    <div class="row" style="background-color: white;margin: 0;width: 176px;">
                                        <input type="text" class="form-control" value="{{angleRotate}}" style="text-align: center;width: 80%;border: 0;" formControlName="addMapOrientation" name="addMapOrientation" >
                                        <img src="../../assets/svgs/rotationknob_icon.svg" style="width: 30px;height:30px;float: right;" id="rotationField" #rotationField>

                                    </div>
                                </div>
                                </div> -->

                                <div class="container" style="margin-top: 1rem;">
                                    <div class="d-flex justify-content-center">
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;padding: 6px;box-shadow: 3px 3px 3px grey;max-width: 4.5rem;">
                                            <a (click)="addTextField()">
                                                <img 
                                                mat-raised-button
                                                matTooltip="Text Box"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"
                                                matTooltipPosition="left"
                                                src="../../assets/svgs/new/textbox_icon.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                   
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;"
                                                    [ngStyle]="{'background-color': addtextfieldbuttonactive ? 'black' : 'gainsboro'}" />
                                            </a>
                                        </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="showShapeMenu()">
                                                <img mat-raised-button
                                                matTooltip="Shapes"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip" matTooltipPosition="below"
                                                 src="../../assets/svgs/new/shapes_icon.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;"
                                                    [ngStyle]="{'background-color': shapemenueactive ? 'black' : 'gainsboro'}" />
                                            </a>
                                        </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="showSiteMenu()">
                                                <img
                                                mat-raised-button
                                                matTooltip="Geometry"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"  matTooltipPosition="right"
                                                 src="../../assets/svgs/new/geometry_icon.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;"
                                                    [ngStyle]="{'background-color': sitemenueactive ? 'black' : 'gainsboro'}" />
                                            </a>
                                        </div>
                                        

                                       

                                    </div>

                                </div>
                                <!-- 
                                <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Map Scale (units)</p>
                                        <select class="form-control" style="margin: 0;padding:0" name="addMapscale" formControlName="addMapscale" >
                                            <option *ngFor="let unit of unitsList" [value]="unit.value">
                                                {{unit.view}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Legend</p>
                                        <select class="form-control" style="margin: 0;padding:0" formControlName="addLegend" #legend (change)="onLegendOptionSelected(legend.value)">
                                            <option value="Show">Show</option>
                                            <option value="Hide">Hide</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div class="col-xl-6">
                                    <p style="margin: 0;font-size:14px">Map Type</p>
                                    <select class="form-control" style="margin: 0;padding:0" name="addMaptype" formControlName="addMaptype" #mapType (change)="getMaptypeSelected(mapType)">
                                        <option value={{maptype.name}}  *ngFor="let maptype of mapTypesList"> {{maptype.name}} </option>
                                    </select>
                                </div> -->
                                <div class="container">
                                    <div class="d-flex justify-content-center">
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="opendropdown()">
                                                <img mat-raised-button
                                                matTooltip="Basemap"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"  matTooltipPosition="left"
                                                src="../../assets/svgs/new/basemap-black.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;" 
                                                    [ngStyle]="{'background-color': basemapactive ? 'black' : 'gainsboro'}"  />

                                            </a>
                                        </div>
                                        <div *ngIf="showdropdown" style="width: 15%;
                                        position: absolute;
                                        z-index: 1;
                                        left: -5.8rem;
                                        top: -8.5rem;">
                                            <div *ngFor="let maptype of mapTypesList" class="form-control"  style="margin-bottom: 20px; box-shadow: 2px 2px 2px grey;"
                                               #maptypes value="{maptype.name}" (click)="getMaptypeSelected(maptype.value)">
                                               <img [src]="maptype.view" style="width: 45px; height: 45px; position: relative; top: 0; left: -8px; background: gainsboro;
                                               border-radius: 5px;">
                                            </div>
                                        </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="showOrCloseUnits()">
                                                <img 
                                                mat-raised-button
                                                matTooltip="Scale Bar"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip" matTooltipPosition="below"
                                                src="../../assets/svgs/new/scale-black.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: black;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;"
                                                      
                                                      />
                                                <div *ngIf="showdropdownMiles" style=" width: 80%;
                                                      position: absolute;
                                                      z-index: 1;
                                                      top: 4.4rem;
                                                      left: 0.6rem;">
                                                    <div class="form-control" *ngFor="let unit of unitsList"
                                                        style="margin: 0;padding:0" name="addMapscale"
                                                        [ngClass]="{'selected': unit.view.selected}"
                                                        formControlName="addMapscale" [value]="unit.value">
                                                        <div
                                                            style=" font-size: 12px; height: 100%; margin: 5px;z-index:1">
                                                            {{unit.view}}</div>
                                                    </div>
                                                </div>

                                            </a>
                                        </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click) = 'showOrCloseArrow()' >
                                                <img 
                                                mat-raised-button
                                                matTooltip="North Arrow"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip" matTooltipPosition="right"
                                                 src="../../assets/svgs/new/north-arrow-black.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;"
                                                      [ngStyle]="{'background-color': northarrowactive ? 'black' : 'gainsboro'}" />
                                                <!-- <input type="text" class="form-control" value="{{angleRotate}}" style="text-align: center;width: 80%;border: 0;" 
                                                    formControlName="addMapOrientation" name="addMapOrientation" > -->
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="container">
                                    <div class="d-flex justify-content-center">
                                        <div class="col"  (click) = "onLegendOptionSelected($event.target.value)"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a>
                                                <img 
                                                mat-raised-button
                                                matTooltip="Legend"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"
                                                matTooltipPosition="left" 
                                                src="../../assets/svgs/new/legend-black.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;" 
                                                      [ngStyle]="{'background-color': legendcoloractive ? 'black' : 'gainsboro'}" />
                                            </a>
                                        </div>
                                        
                                        <div class="col"
                                        style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                        <a>
                                            <label for="checkBtn" style="margin-bottom: 0rem">
                                            <img
                                            mat-raised-button
                                            matTooltip="Image Upload"
                                            matTooltipClass="my-tooltip-class"
                                            aria-label="Button with tooltip"
                                             src="../../assets/svgs/new/upload_icon.svg"  style=" width: 100%;
                                                height: 60%;
                                                margin: 0px;
                                                background-color: gainsboro;
                                                padding: 11px;
                                                border-radius: 7px;
                                                margin-left: 0px;
                                                border: 5px;" />
                                            </label>
                                        </a>
                                        <input type="file" id="checkBtn" style="display: none;" name="addImageUpload" formControlName="addImageUpload" (change)="checkButtonFunction($event)">
                                    </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="opendropdownOrientation()">
                                                <img 
                                                mat-raised-button
                                                matTooltip="Page Orientation"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"  matTooltipPosition="right"
                                                src="../../assets/svgs/new/page-orientation-vertical-black.svg"
                                                    style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;" />
                                                <!-- <select class="form-control" style="margin: 0;padding:0" name="addOrientation"
                                                     formControlName="addOrientation" #orientation
                                                     (change)="onOrientOptionSelected(orientation.value)">
                                                     <option value="Portrait">Portrait</option>
                                                     <option value="Landscape">Landscape</option>
                                                 </select> -->
                                                 <!-- <div *ngIf="showdropdownOrientation" style=" width: 100%;
                                                 position: absolute;
                                                 z-index: 1;
                                                 top: 4.4rem;
                                                 left: 0rem;">
                                                   
                                                     <div class="form-control" style="margin: 0;padding:0"
                                                         name="addOrientation"
                                                         [ngClass]="{'selected': pages.name.selected}"
                                                         formControlName="addOrientation" #orientation>
                                                         <div (click)="onOrientOptionSelected('Portrait')"
                                                             style="font-size: 12px; height: 100%; margin: 5px; z-index: 1 "
                                                             selected>Portrait</div>
                                                         <div (click)="onOrientOptionSelected('Landscape')"
                                                             style="font-size: 12px; height: 100%; margin: 5px; z-index: 1">
                                                             Landscape</div>
                                                     </div>
                                                    
                                                 </div> -->
                                            </a>
                                            <div class="col-xl-6">
                                                <select class="form-control" style="margin: 0;padding:0;
                                                top: 2px;
                                               position: relative;
                                               height: 20px;
                                               width: 37px;
                                               right: 3px;
                                               font-weight: 800;
                                               border: none;
                                               font-size: 9px" formControlName="addOrientation" #orientation (change)="onOrientOptionSelected($event.target.value)">
                                                    <option value='Portrait' style =" background-color: #707175; color: #FFFFFF">Portrait</option>
                                                    <option value='Landscape' style =" background-color: #707175; color: #FFFFFF">Landscape</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="container">
                                    <div class="row align-items-center">
                                      <div class="col"  style="width: 40px;height:100%;background:#f4f4f4;margin-right: 0.3rem;border-radius: 0.2rem;border: 0.5px solid black;">
                                    <a>
                                        <img src="../../assets/svgs/rotationknob_icon.svg" style="width: 60%;height: 60%;margin: 0 0.5rem;" />
                                        </a>
                                      </div>
                                      </div>
                                </div> -->

                                <!-- <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-12">
                                        <p style="margin: 0;font-size:14px">Image Upload</p>
                                        <div class="form-control" style="height: 37.99px;">
                                            <label for="checkBtn" style="margin-left: 0;opacity: 0.5;">
                                                Image size should be 150pxs X 60pxs
                                                <span style="margin-left: 4rem;">
                                                    <img src="../../assets/svgs/upload.svg"
                                                        style="width: 20px;height:20px;">
                                                </span>

                                            </label>
                                            <input type="file" id="checkBtn" style="display: none;"
                                                name="addImageUpload" formControlName="addImageUpload"
                                                (change)="checkButtonFunction($event)">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">

                                    </div>
                                </div> -->
                                <div class="container">
                                    <div class="d-flex justify-content-center">
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem;">
                                            <a (click)="opendropdownPages()">
                                                <img 
                                                mat-raised-button
                                                matTooltip="Page Size"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"
                                                src="../../assets/svgs/new/page-size-black.svg" style="    width: 100%;
                                                    height: 60%;
                                                    margin: 0px;
                                                    background-color: gainsboro;
                                                    padding: 11px;
                                                    border-radius: 7px;
                                                    margin-left: 0px;
                                                    border: 5px;" />
                                                <!-- <select class="form-control" 
                                                    formControlName="addPageSize" #pagesize
                                                    (change)="onPageOptionSelected(pagesize.value)">
                                                    <option value={{pages.name}} *ngFor="let pages of presentationPageSizes ">
                                                        {{pages.name}}</option>
                                                </select> -->
                                                <!-- <div *ngIf="showdropdownPages" style=" width: 80%;
                                                position: absolute;
                                                z-index: 1;
                                                top: 0.4rem;
                                                left: 0.6rem;">
                                                    <div class="form-control"
                                                        *ngFor="let pages of presentationPageSizes"
                                                        style="margin: 0;padding:0" formControlName="addPageSize"
                                                        #pagesize value={{pages.name}} (click)="onPageOptionSelected(pagesize.value)">
                                                        <div
                                                            style=" font-size: 12px; height: 100%; margin: 5px;z-index:1">
                                                            {{pages.name}}</div>
                                                    </div>
                                                </div> -->
                                            </a>
                                            <div class="col-xl-6">
                                                <select class="form-control" style="margin: 0;padding:0;
                                                top: 2px;
                                               position: relative;
                                               height: 20px;
                                               width: 37px;
                                               right: 3px;
                                               font-weight: 800;
                                               border: none;
                                               font-size: 9px" formControlName="addPageSize" #pagesize (change)="onPageOptionSelected(pagesize.value)">
                                                    <option value={{pages.name}} *ngFor="let pages of presentationPageSizes " style =" background-color: #707175; color: #FFFFFF">{{pages.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col"
                                            style="width: 40px;height:100%;background:#f4f4f4;margin: 20px;border-radius: 7px;box-shadow: 3px 3px 3px grey;padding: 6px;max-width: 4.5rem; margin-left: 4.5rem;">
                                            <a (click)="opendropdownDpi()">
                                                <img 
                                                mat-raised-button
                                                matTooltip="Print"
                                                matTooltipClass="my-tooltip-class"
                                                aria-label="Button with tooltip"
                                                matTooltipPosition="below"
                                                src="../../assets/svgs/new/print-black.svg" style="    width: 100%;
                                                height: 60%;
                                                margin: 0px;
                                                background-color: gainsboro;
                                                padding: 11px;
                                                border-radius: 7px;
                                                margin-left: 0px;
                                                border: 5px;" (click)="Printingmanager(mapLogo,navigateN,legendDiv,imageDiv )"/>                                                         
                                              
                                            </a>
                                            <!-- <div class="col-xl-6" >
                                                <select class="form-control" style="margin: 0;padding:0;
                                                 top: 2px;
                                                position: relative;
                                                height: 20px;
                                                width: 59px;
                                                right: 14px;
                                                font-weight: 800;
                                                border: none;
                                                font-size: 9px;" formControlName="addDpi" #dpi >
                                                    <option style =" background-color: #707175; color: #FFFFFF" value="100">100 DPI</option>
                                                    <option style =" background-color: #707175; color: #FFFFFF" value="200">200 DPI</option>
                                                    <option style =" background-color: #707175; color: #FFFFFF" value="300">300 DPI</option>
                                                    <option style =" background-color: #707175; color: #FFFFFF" value="400">400 DPI</option>
                                                </select>
                                            </div> -->
                                        </div>
                                    </div>
                                   
                                </div>
                                <!-- <div style="z-index:10;;bottom: 0%;font-size: 13px;font-weight: bold;    margin-top: 22px;
                                    margin-left: 78px;">Viewing Factor = <input type="text" value="1" style="text-align: center;width: 27px;margin-right: 2px;"disabled>: <input id="changeviewfactor" value="{{value}}" type="number" placeholder="{{value2}}" style="    width: 92px;"  (keyup.enter)="updatescaleviewfactor($event.target.value)"></div> -->
                                <div style="z-index:10;;bottom: 0%;font-size: 13px;font-weight: bold;    margin-top: 22px;
                                margin-left: 101px;">Page Size = {{pagedimensions}}</div>
                                <div  id="loading" style="z-index:10;;bottom: 0%;font-size: 15px;font-weight: bold;    margin-top: 70px;
                                margin-left: 273px;">Printing <img src="../../assets/images/loadingdots.gif" alt="" style="width: 20px;"> </div>
                               <!-- <div style="z-index:10;;bottom: 0%;font-size: 13px;font-weight: bold;">Viewing Factor : <span class="value"> {{currentViewFactory}} </span></div> -->
                                <!-- <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-12">
                                        <p style="margin: 0;font-size:14px">Credits</p>
                                        <textarea class="form-control" rows="4"
                                            style="margin: 0;padding: 0;width: 100%;"
                                            formControlName="addCredit"></textarea>
                                    </div>
                                </div>

                                <div class="row" style="margin: 1rem -0.5rem;">
                                    <p
                                        style="margin: 0.1rem 1rem;font-size: 21px;font-weight: bold;letter-spacing: 0.1rem;">
                                        Print Details</p>
                                    <div style="height: 0.1rem;background:#2B2E34;margin: 0 1rem;width: 100%"></div>
                                </div>

                                <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Orientation</p>
                                        <select class="form-control" style="margin: 0;padding:0" name="addOrientation"
                                            formControlName="addOrientation" #orientation
                                            (change)="onOrientOptionSelected(orientation.value)">
                                            <option value="Portrait">Portrait</option>
                                            <option value="Landscape">Landscape</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Page Size</p>
                                        <select class="form-control" style="margin: 0;padding:0"
                                            formControlName="addPageSize" #pagesize
                                            (change)="onPageOptionSelected(pagesize.value)">
                                            <option value={{pages.name}} *ngFor="let pages of presentationPageSizes ">
                                                {{pages.name}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <!-- <div class="row" style="margin: 1rem -0.5rem;">
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">DPI</p>
                                        <select class="form-control" style="margin: 0;padding:0"
                                            formControlName="addDpi" #dpi>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="300">300</option>
                                            <option value="400">400</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6">
                                        <p style="margin: 0;font-size:14px">Output Format</p>
                                        <select class="form-control" style="margin: 0;padding:0"
                                            formControlName="addOutputFormat">
                                            <option value="PDF">PDF</option>
                                            <option value="TIFF">TIFF</option>
                                            <option value="JPG">JPG</option>
                                        </select>
                                    </div>
                                </div> -->
                              
                            </form>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>