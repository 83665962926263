<div class="login-page">
  <div class="login-card">
<img src="../../assets/images/fe-logo-blue.png" width="120px" height="100px" alt="fe-blue-logo" style="margin-right: auto; margin-left: auto; display: block;">
<form class="login-form" action="" [formGroup]="loginForm">
  <div>
    <input #email type="email" name="email" id="email" placeholder="Email Address">
  </div>
  <div><input #password type="password" name="password" id="password" placeholder="Password"></div>



   <button (click)="onLogin(email.value, password.value)">Login</button>

</form>
</div>
</div>
<app-home [hidden]="showDashboard"></app-home>
