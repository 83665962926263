import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, Pipe, PipeTransform,
          AfterViewInit, SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from 'src/app/Services/common.service';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { GeoNotePadService } from 'src/app/Services/geo-notepad.service';
import { FormControl } from '@angular/forms';
import { TopicsService } from 'src/app/Services/topics.service';
import OlMap from 'ol/Map';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { Draggable } from '../../../assets/js/Draggable.js';
import { TweenLite } from '../../../assets/js/TweenLite.js';
import { FirebaseUtil } from '../../geobar/util/firebaseUtil';
import { AngularFireStorage } from '@angular/fire/storage';
import { Fill, Stroke, Style, Text } from "ol/style";
import Point from 'ol/geom/Point.js';
import { getCenter } from 'ol/extent';
import Feature from 'ol/Feature';

const EXIF: any = (window as any).EXIF;
export enum SiteType{
  ALL = 'ALL',
  POINT = 'Point',
  LINE = 'LineString',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  UNKNOWN = ''
}
enum FileFormats {
  URL = 'url',
  FILE = 'file',
  YOUTUBE = 'youtube'
}
export enum FileTypes {
  IMAGES = 'images',
  VIDEOS = 'videos',
  AUDIOS = 'audios',
  NOTES = 'notes',
  SCHETCHES = 'schetches',
  DESCRIPTORS = 'descriptors'
}
export class FileObject {
  name: string;
  format: FileFormats;
  url: string;
  file: File;
  fileExtention: string;
  date: string;
  type: FileTypes;
  caption: string;
  base64Data: any;
  dimension: any;
  id: string;
  gpsAvailable: boolean;
  latitude: string;
  longitude: string;
  observationItemId = 0;
  observationInstanceId = 0;
  size: any;
  constructor(name: string, format: FileFormats, url: string, type: FileTypes, file: File, fileExtention: string, base64Data: any,
              caption: string, dimension: any, date,
              gpsAvailable = false, latitude = '', longitude = '') {
    this.file = file;
    this.fileExtention = fileExtention;
    this.name = name;
    this.format = format;
    this.url = url;
    this.date = date;
    this.type = type;
    this.base64Data = base64Data;
    this.caption = caption;
    this.dimension = dimension;
    this.gpsAvailable = gpsAvailable;
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class SiteParams {
  icon: any;
  color: string;
  symbolSize: string;
  rotationAngle: number;
  arrowType: string;
  fillColor: string;
  siteType: SiteType;
  constructor(icon: any, color: string, symbolSize: string, rotationAngle: number,
              arrowType: string, fillColor: string, siteType: SiteType){
    this.icon = icon;
    this.color = color;
    this.symbolSize = symbolSize;
    this.rotationAngle = rotationAngle;
    this.arrowType = arrowType;
    this.fillColor = fillColor;
    this.siteType = siteType;
  }
}

export class FuseEarthSite {
  id: string | number;
  observationInstanceId = null;
  createdDate: string;
  description: string;
  filesList: Array<FileObject>;
  geopadId: number;
  geopadNotes: Array<FileObject>;
  latitudeLongitude: any[];
  latitudeLongitudeToShow: any[];
  multiGeometryLatitudeLongitude: any[];
  locationName: string;
  status: string;
  tags: any[] = [];
  uiTimestamp: string;
  updatedDate: string;
  siteType: SiteType;
  imageFilesList: Array<FileObject>;
  videoFilesList: Array<FileObject>;
  audioFilesList: Array<FileObject>;
  descriptorsFilesList: Array<FileObject>;
  siteParams: SiteParams;
  verticalData: { headers: any[], body: any[]};
  observationTagsList: any[] = [];

  constructor(
    id: string|number, createdDate: string, description: string, filesList: Array<FileObject>, geopadId: number,
    geopadNotes: Array<FileObject>,
    latitudeLongitude: any[], multiGeometryLatitudeLongitude: any[],locationName: string, status: string, tags: any[], uiTimestamp: string, updatedDate: string,
    siteType: SiteType, siteParams: SiteParams, verticalData: { headers: any[], body: any[]}, observationTagsList: any[],
    imageFilesList: Array<FileObject> = [], videoFilesList: Array<FileObject> = [], audioFilesList: Array<FileObject> = [],
    descriptorsFilesList: Array<FileObject> = []) {
      this.id = id;
      this.createdDate = createdDate;
      this.description = description;
      this.filesList = filesList;
      this.geopadId = geopadId;
      this.geopadNotes = geopadNotes;
      this.latitudeLongitude = latitudeLongitude;
      this.multiGeometryLatitudeLongitude = multiGeometryLatitudeLongitude;
      this.locationName = locationName;
      this.status = status;
      this.tags = tags || [];
      this.uiTimestamp = uiTimestamp;
      this.updatedDate = updatedDate;
      this.siteType = siteType;
      this.imageFilesList = imageFilesList;
      this.videoFilesList = videoFilesList;
      this.audioFilesList = audioFilesList;
      this.descriptorsFilesList = descriptorsFilesList;
      this.siteParams = siteParams;
      this.verticalData = verticalData;
      this.observationTagsList = observationTagsList;
  }
}

@Pipe({ name: 'safe' })
export class UrlSafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url): SafeResourceUrl {
    if (url !== '' && url !== undefined && url !== null) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return '';
    }
  }
}

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];

@Pipe({ name: 'formatFileSize' })
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes: number): string {
    const units = FILE_SIZE_UNITS;
    if (sizeInBytes === 0){
      return '';
    }
    let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);
    const size = sizeInBytes / Math.pow(1024, power); // size in new units
    const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
    const unit = units[power];
    return formattedSize + '' + unit;
  }
}

@Component({
  selector: 'app-capture-notes',
  templateUrl: './capture-notes.component.html',
  styleUrls: ['./capture-notes.component.scss']
})
export class CaptureNotesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {  
  @Input() selectedProjectId = 0;
  @Input() selectedPlaceId = 0;
  @Input() selectedTopicId = 0;
  @Input() currentContextInfo: any = {};
  @Input() userInfo: any = {};
  @Input() globalObject;
  @Input() locationData: any[] = [];
  @Input() site_type;
  @Input() operation = 'add';
  @Input() selectedNote: any = {};
  @Input() isGuest = true;
  @Input() currentSession: any = {};
  @Input() type: FileTypes = FileTypes.IMAGES;
  @Output() closeNotesPicker: EventEmitter<any> = new EventEmitter<any>();
  @Output() capturedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() savingNotes: EventEmitter<any> = new EventEmitter<any>();
  selectedTab: FileTypes;
  allFileTypes = FileTypes;
  allFileFormats = FileFormats;
  imageFilesList: Array<FileObject> = [];
  videoFilesList: Array<FileObject> = [];
  audioFilesList: Array<FileObject> = [];
  notesList: Array<FileObject> = [];
  schetchFilesList: Array<FileObject> = [];
  descriptorsFilesList: Array<FileObject> = [];
  filesList: Array<FileObject> = [];
  showFileUrlCollector = false;
  @ViewChild('siteNameInput') siteNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('fileUrlInput') fileUrlInput: ElementRef<HTMLInputElement>;
  @ViewChild('uploadFileSelector') uploadFileSelector: ElementRef<HTMLInputElement>;
  @ViewChild('notesDescription') notesDescription: ElementRef<HTMLTextAreaElement>;
  @ViewChild('notesProjectName') notesProjectName: ElementRef<HTMLSelectElement>;
  @ViewChild('projectInput') projectInput: ElementRef<HTMLInputElement>;
  @ViewChild('placeInput') placeInput: ElementRef<HTMLInputElement>;
  @ViewChild('topicInput') topicInput: ElementRef<HTMLInputElement>;
  urlValidationError = '';
  sites: Array<FuseEarthSite> = [];
  currentSite: FuseEarthSite;
  showSitesTray = true;
  siteNameCtrl: FormControl = new FormControl('');
  descriptionCtrl: FormControl = new FormControl('');
  siteNotesCtrl: FormControl = new FormControl('');
  acceptFileTypes: any = {
    images: 'image/*',
    videos: 'video/mp4,video/x-m4v,video/*',
    audios: '.mp3,audio/*',
    descriptors: '.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf'
  };
  showConfirmClose = false;
  showTagsContainer = false;
  showLatLongContainer = true;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('spanTag_') spanTag_: ElementRef<HTMLInputElement>;
  notesValidationError = '';
  showFileViewer = false;
  selectedFileToView: any;
  private imageUploadAbservable = new Subject<any>();
  private imageUploadAbservableStream$ = this.imageUploadAbservable.asObservable();
  dataToSave: any = {};
  generatedUrlsCount = 0;
  filesToSave: Array<FileObject> = [];
  projects: any[] = [];
  projectSelect: FormControl = new FormControl('');
  places: any[] = [];
  placeSelect: FormControl = new FormControl('');
  topics: any[] = [];
  topicSelect: FormControl = new FormControl('');
  projectId = 0;
  placeId = 0;
  topicId = 0;
  selectedProject: any = {};
  selectedPlace: any = {};
  selectedTopic: any = {};
  siteRequestReceived: number;
  siteRequestSent: number;
  showLocationTaggedImages: boolean;
  locationTaggedImages: Array<FileObject> = [];
  siteNoteOperation = 'add';
  currentSiteNote: FileObject;
  private basemap: OlMap;
  @Input() confirmCurrentNotesClosing: any = {};
  @Output() responseOfCurrentNoteCloseRequest: EventEmitter<any> = new EventEmitter<any>();
  watchOnPolygonChangesSubs: Subscription;
  @Input() tempCreateSiteId: string = String(new Date().getTime());
  @Input() userProfileData: any = {};
  @Input() viewMode: any = {};
  userRole = '';
  siteTypeEnum = SiteType;
  generalSiteIconsList: any[] = [];
  specialSiteIconsList: any[] = [];
  customSiteIconsList: any[] = [];
  generalSiteSelectedIcon: any = {};
  specialSiteSelectedIcon: any = {};
  customSiteSelectedIcon: any = {};
  rotationAngle: FormControl = new FormControl(0);
  lineSiteThicknessIconsList: any[] = [];
  lineSiteSelectedThicknessIcon: any = {};
  currentSiteIcon: any = {};
  sitePointOrLineColor: FormControl = new FormControl('#000000');
  sitePolygonFillColor: FormControl = new FormControl('#ffffff');
  sitePointSymbolSize: FormControl = new FormControl('4');
  progress: Subject<any> = new Subject<any>();
  showVerticalInfo = false;
  showSessionShareSiteEditAlert = false;
  initialSiteData: any;
  tagsToSaveList = []
  constructor(public commonService: CommonService, private firebaseService: FirebaseService,
              private basemapService: BasemapService,
              private firestorage: AngularFireStorage,
              private notepadService: GeoNotePadService, private topicsService: TopicsService) {
    this.imageUploadAbservableStream$.subscribe(data => this.uploadStatusEvent(data));
    this.progress.subscribe(prog => {
    });
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}): any {
    if (this.commonService.isValid(changes.confirmCurrentNotesClosing)) {
      if (this.commonService.isValid(changes.confirmCurrentNotesClosing.currentValue)) {
        if (this.confirmCurrentNotesClosing.type === 'confirm-close') {
          document.getElementById('capture-notes-close-btn').click();
        }
      }
    }
  }
  ngOnInit(): any {
    try{
      this.userRole = this.userProfileData.userOrgRolesInfo.roleName;
    } catch (e){
      console.log(e);
    }
    if (this.operation === 'update' || this.operation === 'view') {
      // UPDATE OR VIEW SITE. HERE SELECTED NOTE CAN HAVE THE SITE INFO
      try{
        this.selectedProjectId = this.selectedNote.project.topicId;
      } catch (e){
        console.log(e);
      }
      try{
        this.selectedPlaceId = this.selectedNote.place.topicId;
      } catch (e){
        console.log(e);
      }
      try{
        this.selectedTopicId = this.selectedNote.topic.topicId;
      } catch (e){
        console.log(e);
      }
    } else {
      // ADDING SITE CASE. HERE CONTEXT INFO CONTAINS VALID PROJECT, PLACE, TOPIC IDS
      try{
        this.selectedProjectId = this.currentContextInfo.project.topicId;
        this.selectedPlaceId = this.currentContextInfo.place.topicId;
        this.selectedTopicId = this.currentContextInfo.topic.topicId;
      } catch (e){
        console.log(e);
      }
    }
    this.basemap = this.basemapService.getCurrentBasemap();
    this.currentSession = this.globalObject.geobase;
    let type: SiteType;
    if(!Array.isArray(this.locationData[0])){
      if (this.locationData.length === 2) {
        type = SiteType.POINT;
      }
      else{        
        if(this.locationData.length > 2){
          let isPolygon = false;
          if (this.locationData.length > 4) {
            if (this.locationData[0] === this.locationData[this.locationData.length - 2] &&
                this.locationData[1] === this.locationData[this.locationData.length - 1]) {
                isPolygon = true;
            }
          }
          type = isPolygon ? SiteType.POLYGON : SiteType.LINE;
        }
      } 
    } else if (Array.isArray(this.locationData[0])) {  
        if(this.locationData[0].length == 2 && !Array.isArray(this.locationData[0][0])){
          type = SiteType.MULTI_POINT;
        }
      else{
          let isMultiPolygon = false;
          if(this.locationData[0].length >=2){            
            if (this.locationData[0].length > 4) {              
              if (this.locationData[0][0] === this.locationData[0][this.locationData[0].length -2] &&
                  this.locationData[0][1] === this.locationData[0][this.locationData[0].length - 1]) {
                  isMultiPolygon = true;
              }
            }
            type = isMultiPolygon ? SiteType.MULTI_POLYGON : SiteType.MULTI_LINE;
          }      
      }     
    }
    this.currentSite = new FuseEarthSite(1, '', '', [], this.currentSession.geopadId, [], this.locationData,this.selectedNote.multiGeometryLatitudeLongitude, '',
      'Active', [], '', '',  type, null, this.initiateVerticalData(), []);    
    this.sites = [this.currentSite];
    this.currentSite.latitudeLongitude = this.locationData;
    this.currentSite.latitudeLongitudeToShow = this.getLatLongsToShow(this.locationData);
    this.siteNameCtrl.valueChanges.subscribe(res => {
      this.currentSite.locationName = res;
    });
    this.descriptionCtrl.valueChanges.subscribe(res => {
      this.currentSite.description = res;
    });
    this.siteNotesCtrl.valueChanges.subscribe(res => {
      if (!this.commonService.isValid(res)) {
        this.siteNoteOperation = 'add';
      }
    });
    this.selectedTab = this.type;
    this.getProjectsList();
    this.projectSelect.valueChanges.subscribe(res => {
      if (this.commonService.isValid(res)) {
      }
    });
    this.placeSelect.valueChanges.subscribe(res => {
      if (this.commonService.isValid(res)) {
      }
    });
    Draggable.create('#siteIconRotationAngNeedle', {
      type: 'rotation',
      throwProps: true,
      // bounds: { minRotation: -23, maxRotation: 337 },
      onDrag: (e) => {
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'siteIconRotationAngNeedle') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.commonService.isValid(target)) {
          const element = target; 
          let angle = element._gsTransform.rotation;
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          console.log('INVALID TARGET...');
        }
      },
      onDragEnd: (e) => {
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'siteIconRotationAngNeedle') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.commonService.isValid(target)) {
          const element = target;
          let angle = element._gsTransform.rotation;
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          console.log('INVALID TARGET...');
        }
      }
    });
    try{
      this._updateDraggableObj();
    } catch (e){
      console.log(e);
    }
    this.rotationAngle.valueChanges.subscribe(val => {
      try{
        const globeIconDraggable = Draggable.get('#siteIconRotationAngNeedle');
        TweenLite.set('#siteIconRotationAngNeedle', { rotation: val });
      } catch (e){
        console.log(e);
      }
      this.redrawSiteOnMap();
    });
    this.sitePointOrLineColor.valueChanges.subscribe(val => {
      this.redrawSiteOnMap();
    });
    this.sitePolygonFillColor.valueChanges.subscribe(val => {
      this.redrawSiteOnMap();
    });
    this.sitePointSymbolSize.valueChanges.subscribe(val => {
      this.redrawSiteOnMap();
    });
  }
  private _setRotation(rotationValue): any {
    this.rotationAngle.setValue(rotationValue);
  }
  uploadCustomIcon(): any {
    document.getElementById('selectedIcon').click();
  }
  selectSiteIcon(iconType, siteType: SiteType, siteIcon): void{
    if (iconType === 'general'){
      this.generalSiteSelectedIcon = siteIcon;
    } else if (iconType === 'special'){
      this.specialSiteSelectedIcon = siteIcon;
    } else if (iconType === 'custom'){
      this.customSiteSelectedIcon = siteIcon;
    } else if (iconType === 'thickness'){
      this.lineSiteSelectedThicknessIcon = siteIcon;
      this.sitePointSymbolSize.setValue(siteIcon.value);
    }
    if (iconType !== 'thickness'){
      this.currentSiteIcon = siteIcon;
    }
    this.redrawSiteOnMap();
  }
  redrawSiteOnMap(): void{
    this.sites.forEach(site => {
      site.siteParams = this.getCurrentSiteParams();
    });
    this.updateSiteStyleOnMap(this.currentSite);
  }
  getCurrentSiteParams(): SiteParams{
    const icon = this.currentSiteIcon;
    const color = this.sitePointOrLineColor.value;
    let symbolSize = '';
    let rotationAngle: number;
    let arrowType = 'NONE';
    let fillColor = '';
    if (this.currentSite.siteType === SiteType.POINT || this.currentSite.siteType === SiteType.MULTI_POINT){
      symbolSize = this.sitePointSymbolSize.value;
      rotationAngle = this.rotationAngle.value;
    } else{
      arrowType = 'NONE';
      symbolSize = this.sitePointSymbolSize.value;
    }
    if (this.currentSite.siteType === SiteType.POLYGON || this.currentSite.siteType === SiteType.MULTI_POLYGON){
      fillColor = this.sitePolygonFillColor.value;
    }
    const siteParams = new SiteParams(icon, color, symbolSize, rotationAngle,
      arrowType, fillColor, this.currentSite.siteType);
    return siteParams;
  }
  ngOnDestroy(): void{
    this.clearPolygonDrawingTools();
    if (this.commonService.isValid(this.watchOnPolygonChangesSubs)) {
      this.watchOnPolygonChangesSubs.unsubscribe();
    }
  }
  clearPolygonDrawingTools(): void{
    this.notepadService.clearPolygonDrawingTools();
  }
  updatePolygonOnChanges(watchOnPolygonChanges: Subject<any>): void{    
    this.watchOnPolygonChangesSubs = watchOnPolygonChanges.subscribe(polygonChanged => {      
      let coords;
      const coordsList = [];
      let siteType: SiteType;
      if (polygonChanged.from === this.notepadService.shapeDrawType.POLYGON /*'polygon'*/){
        polygonChanged['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(element => {
            coordsList.push(element[0]);
            coordsList.push(element[1]);
          });
        });
        siteType = SiteType.POLYGON;
        console.log(coordsList);
        coords = coordsList;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = this.locationData;
      } else if ( polygonChanged.from === this.notepadService.shapeDrawType.LINE_STRING ){
        polygonChanged['co-ordinates'].forEach(latLngList => {
          coordsList.push(latLngList[0]);
          coordsList.push(latLngList[1]);
        });
        console.log(coordsList);
        coords = coordsList;
        siteType = SiteType.LINE;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = this.locationData;
      } else if (polygonChanged.from === this.notepadService.shapeDrawType.POINT /*'position'*/){
        polygonChanged['co-ordinates'].forEach(latLngList => {
          coordsList.push(latLngList);
        });
        coords = coordsList;
        siteType = SiteType.POINT;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = this.locationData;
      } else if (polygonChanged.from === this.notepadService.shapeDrawType.MULTI_POINT /*'multipoint'*/) {
        polygonChanged['co-ordinates'].forEach(latLngList => {
          if( Array.isArray(latLngList[0])){
            coordsList.push([latLngList[0][0],latLngList[0][1]]);
          }         
        });
        coords = coordsList;
        siteType = SiteType.MULTI_POINT;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = [0,0];
        this.currentSite.multiGeometryLatitudeLongitude = [];
        this.currentSite.multiGeometryLatitudeLongitude = coords;
        this.currentSite.latitudeLongitudeToShow = this.getLatLongsToShow(this.locationData);
      } else if (polygonChanged.from === this.notepadService.shapeDrawType.MULTI_LINE /*'multiline*/) {
        polygonChanged['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(elements => {
            let multiLineCoords=[];
            elements.forEach(element => {
              multiLineCoords.push(element[0])              
              multiLineCoords.push(element[1])
            });
            coordsList.push(multiLineCoords)
          })       
        });
        coords = coordsList;
        siteType = SiteType.MULTI_LINE;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = [0,0];
        this.currentSite.multiGeometryLatitudeLongitude = [];
        this.currentSite.multiGeometryLatitudeLongitude = coords;
        this.currentSite.latitudeLongitudeToShow = this.getLatLongsToShow(this.locationData);
      }
      else if (polygonChanged.from === this.notepadService.shapeDrawType.MULTI_POLYGON /*'multipolygon'*/) {
        polygonChanged['co-ordinates'].forEach(latLngList => {
          latLngList.forEach(elements => {
            let multiPolygonCoords=[];
            elements.forEach(element => {
              element.forEach(e =>{
                multiPolygonCoords.push(e[0])              
                multiPolygonCoords.push(e[1]) 
              })
            });
            coordsList.push(multiPolygonCoords)
          });  
        });
        coords = coordsList;
        siteType = SiteType.MULTI_POLYGON;
        this.locationData = coords;
        this.currentSite.latitudeLongitude = [0,0];
        this.currentSite.multiGeometryLatitudeLongitude = [];
        this.currentSite.multiGeometryLatitudeLongitude = coords;
        this.currentSite.latitudeLongitudeToShow = this.getLatLongsToShow(this.locationData);
      }
      this.locationData = coords;
      this.currentSite.siteType = siteType;
      this.currentSite.latitudeLongitude = this.locationData;
      this.currentSite.latitudeLongitudeToShow = this.getLatLongsToShow(this.locationData);
      polygonChanged.features.features.forEach(feature => {
        this.tagsToSaveList.forEach(tags => {
          if(feature.properties !== null) {
          if(tags.tagName === feature.properties.name) {
            tags.latitude = feature.geometry.coordinates[0];
            tags.longitude = feature.geometry.coordinates[1];
          }
        }
        })
      })
    });
  }
  getLatLongsToShow(locationData: Array<string>): Array<string> {
    const tempData: Array<string> = [];
    if(Array.isArray(locationData[0]) && !Array.isArray(locationData[0][0])){
        for (let index = 0; index < locationData.length; index = index + 1) {
          for (let j = 0; j < locationData[index].length; j = j + 1) {
            try{
              tempData.push(Number(locationData[index][j]).toFixed(2).toString());
            } catch (e) { console.log(e); }
          }
        }
    }
    else{
      for (let index = 0; index < locationData.length; index = index + 2 ) {
        try{
          tempData.push(Number(locationData[index + 1]).toFixed(2).toString());
        } catch (e) { console.log(e); }
        try{
          tempData.push(Number(locationData[index]).toFixed(2).toString());
        } catch (e) { console.log(e); }
      }
    }    
    return tempData;
  }
  initiateVerticalData(): any{
    return { headers: [{name: '', id: new Date().getTime(), order: 0}], body: []};
  }
  ngAfterViewInit(): any {
    if (this.operation === 'update' || this.operation === 'view') {
      const existSiteParams: SiteParams = this.selectedNote.siteParams;
      this.currentSiteIcon = existSiteParams.icon;
      this.sitePointOrLineColor.setValue(existSiteParams.color);
      if (this.commonService.isValid(existSiteParams.icon)){
        if (existSiteParams.icon.iconCategory === 'General') {
          this.generalSiteSelectedIcon = existSiteParams.icon;
        } else if (existSiteParams.icon.iconCategory === 'Special') {
          this.specialSiteSelectedIcon = existSiteParams.icon;
        }  else if (existSiteParams.icon.iconCategory === 'Custom') {
          this.customSiteIconsList = existSiteParams.icon;
        }
      }
      this.sitePointSymbolSize.setValue(existSiteParams.symbolSize);
      if (this.selectedNote.siteType === SiteType.POINT){
        this.rotationAngle.setValue(existSiteParams.rotationAngle);
      } else{
      }
      if (this.selectedNote.siteType === SiteType.POLYGON){
        this.sitePolygonFillColor.setValue(existSiteParams.fillColor);
      }
      this.currentSite =  new FuseEarthSite(this.selectedNote.observationInstanceId, this.selectedNote.createdDate,
                          this.selectedNote.description, this.selectedNote.filesList, this.selectedNote.geopadId,
                          /*this.selectedNote.geopadNotes*/ [], this.selectedNote.latitudeLongitude,this.selectedNote.multiGeometryLatitudeLongitude, this.selectedNote.locationName,
                          this.selectedNote.status, this.selectedNote.tags, this.selectedNote.uiTimestamp, this.selectedNote.updatedDate,
                          this.selectedNote.siteType, null, this.initiateVerticalData(), this.selectedNote.observationTagsList);

      this.currentSite.siteParams = this.getCurrentSiteParams();
      this.currentSite.observationInstanceId = this.selectedNote.observationInstanceId;
      const imagesList: any[] = this.selectedNote.filesList.filter(val => val.type === FileTypes.IMAGES);
      this.currentSite.observationTagsList = this.selectedNote.observationTagsList;
      imagesList.forEach(element => {
        const url = element.url;
        const urlWithFileName = url.substr(0, url.lastIndexOf('?'));
        const extension = urlWithFileName.substring(urlWithFileName.lastIndexOf('.'));
        const img = new Image();
        img.onload = (imgLoadEvent) => {
          const loadedImage: any = imgLoadEvent.currentTarget;
          const width = loadedImage.width;
          const height = loadedImage.height;
          const dimension = { width, height };
          const fileObj: FileObject = new FileObject('', FileFormats.URL, url, FileTypes.IMAGES,
                                      null, extension.substring(extension.lastIndexOf('.') + 1), null, element.caption,
                                      dimension, element.updatedDate);
          fileObj.observationItemId = element.observationItemId;
          fileObj.observationInstanceId = element.observationInstanceId;
          fileObj.size = element.size;
          this.currentSite.imageFilesList.push(fileObj);
        };
        img.src = url;
      });
      this.currentSite.videoFilesList = this.getFilesFromList(this.selectedNote.filesList, FileTypes.VIDEOS);
      this.currentSite.audioFilesList = this.getFilesFromList(this.selectedNote.filesList, FileTypes.AUDIOS);
      this.currentSite.descriptorsFilesList = this.getFilesFromList(this.selectedNote.filesList, FileTypes.DESCRIPTORS);
      this.currentSite.geopadNotes = this.getFilesFromList(this.selectedNote.filesList, FileTypes.NOTES);
      this.siteNameCtrl.setValue(this.currentSite.locationName);
      this.descriptionCtrl.setValue(this.currentSite.description);
      if(this.selectedNote.ismultigeometry) {
        this.locationData = this.selectedNote.multiGeometryLatitudeLongitude;
        const coords = this.locationData;
        let coordsTrans;
        let coords_trans;
        let temp_coords_trans = [];
        if(coords.length >= 2 && Array.isArray(coords[0])) {
          if(Array.isArray(coords[0]) && coords[0].length > 2) {            
            let i=0;
            while ( i < coords.length ) {
              const coOrds = [];
              let j = 0;
              while ( j < coords[i].length ) {
                try{
                  const tempArray = [Number(coords[i][j]), Number(coords[i][j + 1])];
                  coords_trans = this.basemapService.getTransformedCoordinates(tempArray,'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
                  coOrds.push(coords_trans[0]);
                  coOrds.push(coords_trans[1]);
                } catch (e) {
                  console.log(e);
                }
                j = j + 2;
              }
              temp_coords_trans.push(coOrds)
              i = i + 1;
            }
          }else{
            if(Array.isArray(coords[0]) && coords[0].length == 2){
              coords.forEach(latlngs => {              
                  coords_trans = this.basemapService.getTransformedCoordinates(latlngs,'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
                  temp_coords_trans.push(coords_trans)
                })
           }
          }
          coordsTrans = temp_coords_trans
        }
        this.currentSite.latitudeLongitudeToShow = coordsTrans;
        this.currentSite.multiGeometryLatitudeLongitude = [];
        this.currentSite.multiGeometryLatitudeLongitude = coordsTrans;
      }
      else{
        this.locationData = this.selectedNote.latitudeLongitude;
        const coords = this.locationData;
        let coordsTrans;
        let temp_coordsTrans = [];
        if(coords.length > 2) {
          let latlngs = [];
          coords.forEach(lats => {
            latlngs.push(lats)
            if(latlngs.length === 2 ) {                               
              coordsTrans = this.basemapService.getTransformedCoordinates(latlngs, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
              temp_coordsTrans.push(coordsTrans[0])
              temp_coordsTrans.push(coordsTrans[1])
              latlngs = [] 
            }
          });
          coordsTrans = temp_coordsTrans
          } else {
          coordsTrans = this.basemapService.getTransformedCoordinates(coords, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
        }
        this.currentSite.latitudeLongitudeToShow = coordsTrans;
      }
      this.currentSite.verticalData = this.commonService.isValid(this.selectedNote.verticalData) ?
                                JSON.parse(this.selectedNote.verticalData) : { headers: [], body: []};
      this.sites = [this.currentSite];
    }
    if (this.currentSite.siteType === SiteType.POINT){
      this.getSiteIconsDropDown('General', SiteType.POINT);
      this.getSiteIconsDropDown('Special', SiteType.POINT);
      this.getSiteIconsDropDown('Custom', SiteType.POINT);
    } else if (this.currentSite.siteType === SiteType.LINE || this.currentSite.siteType === SiteType.MULTI_LINE){
      this.getSiteIconsDropDown('General', SiteType.LINE);
      this.getSiteIconsDropDown('Special', SiteType.LINE);
    } else if (this.currentSite.siteType === SiteType.POLYGON || this.currentSite.siteType === SiteType.MULTI_POLYGON ){
      this.getSiteIconsDropDown('General', SiteType.POLYGON);
    }else if (this.currentSite.siteType === SiteType.MULTI_POINT){
      this.getSiteIconsDropDown('General', SiteType.POINT);
      this.getSiteIconsDropDown('Special', SiteType.POINT);
      this.getSiteIconsDropDown('Custom', SiteType.POINT);
    }
    if (this.currentSite.siteType === SiteType.LINE || this.currentSite.siteType === SiteType.POLYGON || this.currentSite.siteType === SiteType.MULTI_LINE || this.currentSite.siteType === SiteType.MULTI_POLYGON){
      this.lineSiteThicknessIconsList = [
        { name: '1 px', imgUrl: '/assets/svgs/geopad/siteinfo/one_px.svg', value: '1'},
        { name: '2 px', imgUrl: '/assets/svgs/geopad/siteinfo/two_px.svg', value: '2'},
        { name: '4 px', imgUrl: '/assets/svgs/geopad/siteinfo/four_px.svg', value: '4'},
        { name: '8 px', imgUrl: '/assets/svgs/geopad/siteinfo/eight_px.svg', value: '8'},
        { name: '10 px', imgUrl: '/assets/svgs/geopad/siteinfo/ten_px.svg', value: '10'}
      ];
      if (this.operation === 'add'){
        this.lineSiteSelectedThicknessIcon = this.lineSiteThicknessIconsList[0];
      } else {
        const index = this.lineSiteThicknessIconsList.findIndex(val => val.value === this.currentSite.siteParams.symbolSize);
        if (index !== -1){
          this.lineSiteSelectedThicknessIcon = this.lineSiteThicknessIconsList[index];
        } else {
          this.lineSiteSelectedThicknessIcon = this.lineSiteThicknessIconsList[0];
        }
      }
    }
    if (this.operation === 'view') {
      this.showOrCloseLocationOnMap(this.currentSite, 'zoom');
    } else{      
      const watchOnPolygonChanges: Subject<any> = new Subject<any>();
      this.updatePolygonOnChanges(watchOnPolygonChanges);
      setTimeout(() => {
        this.showOrCloseLocationOnMap(this.currentSite, 'zoom', watchOnPolygonChanges);
      }, 500);
    }
  }
  getFilesFromList(filesList: any[], type: string): Array<FileObject> {
    const tempList: any[] = filesList.filter(val => val.type === type);
    const retList: Array<FileObject> = [];
    tempList.forEach(element => {
      const url = element.url;
      const urlWithFileName = url.substr(0, url.lastIndexOf('?'));
      const extension = urlWithFileName.substring(urlWithFileName.lastIndexOf('.'));
      const fileObj: FileObject = new FileObject('', FileFormats.URL, url, type as FileTypes,
                                    null, extension.substring(extension.lastIndexOf('.') + 1), null, element.caption,
                                    // {});
                                    {}, element.updatedDate);
      fileObj.observationItemId = element.observationItemId;
      fileObj.observationInstanceId = element.observationInstanceId;
      fileObj.size = element.size;
      retList.push(fileObj);
    });
    return retList;
  }

  pickFiles(type: FileTypes): any {
    this.selectedTab = type;
    this.showFileUrlCollector = false;
    this.urlValidationError = '';
    this.showFileViewer = false;
  }
  addUrl(): any {
    this.urlValidationError = '';
    this.showFileUrlCollector = !this.showFileUrlCollector;
  }
  uploadFile(): any {
    this.uploadFileSelector.nativeElement.click();
  }
  toggleTagsContainer(): any {
    this.showTagsContainer = !this.showTagsContainer ;
    if (this.showTagsContainer) {
      setTimeout(() => {
        this.tagInput.nativeElement.focus();
      }, 500);
    }
  }
  addNewTag(): any {
    const tag = this.tagInput.nativeElement.value;
    if (this.commonService.isValid(tag)) {
      this.currentSite.tags.push(tag);
    }
    this.tagInput.nativeElement.value = '';
  }
  removeTag(i): any {
    this.removeTagFromMap(i);
    setTimeout(() => {
      this.currentSite.tags.splice(i, 1);      
    }, 500);
  }
  tagClicked(i, op, tagObj): any {
    let elements: any = this.spanTag_.nativeElement.children;
    let length: any = this.spanTag_.nativeElement.children.length;
    for(let element of elements){
    }
    for (let index = 0; index < elements.length; index++) {
      const input = elements.item(index);
      if(input.id === this.currentSite.tags[i]) {
        if(input.style.background === "rgb(10, 16, 221)") {
          input.style.background = "";
          this.removeTagFromMap(i);
        } else {
          input.style.background = "#0a10dd";
          // add it to map
          this.addTagToMap(i, op, tagObj);
        }
      }
    }
  }
  addTagToMap(i, op, tagObj) {
    this.basemap.getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if(layerObj.values_.name.split('_')[0] === "observationInstanceId") {          
          this.currentSite.tags.forEach(tag  => {
            if(this.currentSite.tags[i] === tag) {
              const labelStyle = new Style({
                text: new Text({
                  font: '13px Calibri,sans-serif',
                  scale: 1.5,
                  fill: new Fill({
                    color: 'rgba(0, 0, 0, 1)'
                  }),
                  padding: [2, 2, 2, 2],
                  stroke: new Stroke({
                        color: "#000f",
                        width: 1
                    })
                })
              });              
            labelStyle.getText().setText(tag)
            let point = new Point(getCenter(layerObj.getSource().getExtent()));
            if(op) point = new Point([tagObj.latitude, tagObj.longitude]);
            const pointFeature = new Feature({
              geometry: point,
              name: tag,
            });
            pointFeature.setStyle(labelStyle)
            layerObj.getSource().addFeature(pointFeature)
            const tagsToSave = {
              tagName: tag,
              latitude: getCenter(layerObj.getSource().getExtent())[0],
              longitude: getCenter(layerObj.getSource().getExtent())[1],
              observationInstanceId: this.currentSite.observationInstanceId,
              visable: true,
              options: ''
            };
            this.tagsToSaveList.push(tagsToSave);
            }
          });
        }
      }
    });

  }
  removeTagFromMap(i) {
    this.basemap.getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if(layerObj.values_.name.split('_')[0] === "observationInstanceId") {
          this.currentSite.tags.forEach(tag  => {
            console.log(this.currentSite.tags[i], tag)
            if(this.currentSite.tags[i] === tag) {
              layerObj.getSource().getFeatures().forEach(feature => {
                console.log(feature, feature.values_.name)
                if(feature.values_.name === tag) {
                  console.log('removing feature ', feature)
                  layerObj.getSource().removeFeature(feature);
                  this.tagsToSaveList.forEach((tagSave, index) => {
                    if(tagSave.tagName === tag) {
                      this.tagsToSaveList.splice(index,1);
                    }
                  })
                }
              });
            }
          });
        }
      }
    });
    
  }
  getYoutubeEmbedUrl(url): any {
    let retUrl = '';
    if (url.includes('embed')) {
      retUrl = url.trim();
    } else {
      retUrl = 'https://www.youtube.com/embed/' + this.getYoutubeVideoId(url);
    }
    return retUrl;
  }
  getYoutubeVideoId(url): any {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  closeNotesPickerFun(decision): any {
    let errorFound = false;
    if (decision === 'yes') {
      if (this.operation !== 'view') {
        errorFound = this.isErrorsFoundWhileValidatingSiteInfo();
        if (!errorFound) {
          this.saveNotes();
          setTimeout(() => {
            this.sites.forEach(site => {
              this.showOrCloseLocationOnMap(site, 'close');
            });
            this.closeNotesPicker.emit();
          }, 2000);
        } else {
          this.showConfirmClose = false;
        }
      } else {
        this.sites.forEach(site => {
          this.showOrCloseLocationOnMap(site, 'close');
        });
        this.closeNotesPicker.emit();
      }
    } else if (decision === 'no') {
      this.sites.forEach(site => {
        this.showOrCloseLocationOnMap(site, 'close');
      });
      this.closeNotesPicker.emit('without-save');
    }
    if (this.confirmCurrentNotesClosing.type === 'confirm-close' && !errorFound) {
      this.responseOfCurrentNoteCloseRequest.emit(decision);
      this.confirmCurrentNotesClosing = {};
    } else {
      console.log('Errors found. Staying in same page..');
    }
  }

  checkChangesAndClose(): void{
    if ( JSON.stringify(this.initialSiteData) === JSON.stringify(this.currentSite)){
      this.closeNotesPickerFun('no');
    } else {
      this.showConfirmClose = true;
    }
  }

  showCurrentSiteLocation(site): void{
    this.showOrCloseLocationOnMap(this.currentSite, 'close');
    this.currentSite = site;
    const watchOnPolygonChanges: Subject<any> = new Subject<any>();
    this.watchOnPolygonChangesSubs.unsubscribe();    
    this.updatePolygonOnChanges(watchOnPolygonChanges);
    this.showOrCloseLocationOnMap(site, 'zoom', watchOnPolygonChanges);
  }
  viewFileInViewer(file, index, totalLength): any {
    this.selectedFileToView = file;
    let leftAvailable = false;
    let rightAvailable = false;
    if (index > 0) {
      leftAvailable = true;
    }
    if (index === 0) {
      leftAvailable = false;
    }
    if ((totalLength - 1) > index) {
      rightAvailable = true;
    }
    if ((totalLength - 1) === index) {
      rightAvailable = false;
    }
    this.selectedFileToView.leftAvailable = leftAvailable;
    this.selectedFileToView.rightAvailable = rightAvailable;
    this.selectedFileToView.index = index;
    this.showFileViewer = true;
  }
  getPreviousFile(): any {
    if (this.selectedTab === FileTypes.IMAGES) {
      const file = this.currentSite.imageFilesList[this.selectedFileToView.index - 1];
      this.viewFileInViewer(file, this.selectedFileToView.index - 1, this.currentSite.imageFilesList.length);
    } else if (this.selectedTab === FileTypes.VIDEOS) {
      const file = this.currentSite.videoFilesList[this.selectedFileToView.index - 1];
      this.viewFileInViewer(file, this.selectedFileToView.index - 1, this.currentSite.videoFilesList.length);
    } else if (this.selectedTab === FileTypes.AUDIOS) {
      const file = this.currentSite.audioFilesList[this.selectedFileToView.index - 1];
      this.viewFileInViewer(file, this.selectedFileToView.index - 1, this.currentSite.audioFilesList.length);
    }
  }
  getNextFile(): any {
    if (this.selectedTab === FileTypes.IMAGES) {
      const file = this.currentSite.imageFilesList[this.selectedFileToView.index + 1];
      this.viewFileInViewer(file, this.selectedFileToView.index + 1, this.currentSite.imageFilesList.length);
    } else if (this.selectedTab === FileTypes.VIDEOS) {
      const file = this.currentSite.videoFilesList[this.selectedFileToView.index + 1];
      this.viewFileInViewer(file, this.selectedFileToView.index + 1, this.currentSite.videoFilesList.length);
    } else if (this.selectedTab === FileTypes.AUDIOS) {
      const file = this.currentSite.audioFilesList[this.selectedFileToView.index + 1];
      this.viewFileInViewer(file, this.selectedFileToView.index + 1, this.currentSite.audioFilesList.length);
    }
  }

  saveSiteNote(): any {
    const siteNote = this.siteNotesCtrl.value;
    let fileObj: FileObject;
    if (this.siteNoteOperation === 'add') {
      fileObj = new FileObject('', FileFormats.URL, '', this.selectedTab, null, '', null, siteNote, {}, new Date().toISOString);
      this.storeFileObjectInList(fileObj);
    } else if (this.siteNoteOperation === 'update') {
      // fileObj = new FileObject('', FileFormats.URL, '', this.selectedTab, null, '', null, siteNote, {});
      const notesIndex = this.currentSite.geopadNotes.findIndex(val => val.id === this.currentSiteNote.id);
      if (notesIndex !== -1) {
        const tempObj: FileObject = this.currentSite.geopadNotes[notesIndex];
        tempObj.caption = siteNote;
      }
    }
    this.siteNotesCtrl.reset();
    this.siteNoteOperation = 'add';
  }
  editSiteNote(fileObj: FileObject): void {
    this.siteNotesCtrl.setValue(fileObj.caption);
    this.currentSiteNote = fileObj;
    this.siteNoteOperation = 'update';
  }
  removeSiteNote(index): void {
    this.currentSite.geopadNotes.splice(index, 1);
  }
  fileSelected(event): any {
    const selectedFiles: FileList = event.target.files;
    this.locationTaggedImages = [];
    const tempList: Array<File> = [];
    for (let index = 0; index < selectedFiles.length; index++) {
      tempList.push(selectedFiles[index]);
    }
    event.target.value = '';
    let filesListCount = 0;
    let loadedFilesCount = 0;
    tempList.forEach( (file: File) => {
        const base64Data: any = URL.createObjectURL(file);
        const extension = file.name.substring(file.name.lastIndexOf('.'));
        if (this.selectedTab === FileTypes.IMAGES) {
            const fr   = new FileReader(); // to read file contents
            fr.onloadend = (e) => {
              const exif = EXIF.readFromBinaryFile(e.target.result);
              let gpsAvailable = false;
              let latitude; let longitude;
              if (exif && this.operation === 'add'){
                const gpsLatitude = this.getIfExist(exif, 'GPSLatitude');
                const gpsLatitudeRef = this.getIfExist(exif, 'GPSLatitudeRef');
                const gpsLongitude = this.getIfExist(exif, 'GPSLongitude');
                const gpsLongitudeRef = this.getIfExist(exif, 'GPSLongitudeRef');
                if (gpsLatitude && gpsLatitudeRef && gpsLongitude && gpsLongitudeRef ) {
                  gpsAvailable = true;
                  latitude = this.convertToDegress(gpsLatitude); // exif.latitude;
                  longitude = this.convertToDegress(gpsLongitude); // exif.longitude;
                  console.log(latitude);
                  console.log(longitude);
                  if (gpsLatitudeRef !== 'N') {
                    latitude = 0 - latitude;
                  }
                  if (gpsLongitudeRef !== 'E'){
                      longitude = 0 - longitude;
                  }
                }
              }
              const img = new Image();
              img.onload = (imgLoadEvent) => {
                const loadedImage: any = imgLoadEvent.currentTarget;
                const width = loadedImage.width;
                const height = loadedImage.height;
                const dimension = { width, height };
                const fileObj: FileObject = new FileObject(file.name, FileFormats.FILE, '', this.selectedTab,
                file, extension.substring(extension.lastIndexOf('.') + 1), base64Data, file.name, dimension, new Date().toISOString(),
                gpsAvailable, latitude, longitude);
                fileObj.size = file.size;
                gpsAvailable ? this.locationTaggedImages.push(fileObj) : this.storeFileObjectInList(fileObj);

                loadedFilesCount++;
                if (filesListCount === loadedFilesCount) {
                  let locationTagFound = false;
                  this.locationTaggedImages.forEach((element: FileObject) => {
                    if (element.gpsAvailable) {
                      locationTagFound = true;
                    }
                  });
                  if (locationTagFound) {
                    this.showLocationTaggedImages = true;
                  } else {
                    this.locationTaggedImages.forEach((element: FileObject) => {
                      this.storeFileObjectInList(element);
                    });
                  }
                } else {
                  console.log('LOADING IMAGES OF STIE');
                }
              };
              img.src = base64Data;
            };
            fr.readAsArrayBuffer(file);
            filesListCount++;
        } else {
            const fileObj: FileObject = new FileObject(file.name, FileFormats.FILE, '', this.selectedTab, file,
                                                extension.substring(extension.lastIndexOf('.') + 1), base64Data,
                                                file.name, {}, new Date().toISOString);
            fileObj.size = file.size;
            this.storeFileObjectInList(fileObj);
        }
    });
  }

  getIfExist(data, key): any {
    if (this.commonService.isValid(data[key])){
      return data[key];
    } else{
      return undefined;
    }
  }
  convertToDegress(value): number {
    const d0 = value[0].numerator; // [0];
    const d1 = value[0].denominator; // [1];
    const d = d0 / d1;
    const m0 = value[1].numerator; // [0];
    const m1 = value[1].denominator; // [1];
    const m = m0 / m1;
    const s0 = value[2].numerator; // [0];
    const s1 = value[2].denominator; // [1];
    const s = s0 / s1;    
    return d + (m / 60.0) + (s / 3600.0);
  }
  addUrlToNotes(): any {
    const url = this.fileUrlInput.nativeElement.value;
    this.urlValidationError = '';
    let errorFound = false;
    let extension = '';
    try {
      if (!this.commonService.isValidURL(url)) {
        throw new Error('Invalid URL.');
      }
      const urlWithFileName = url.substr(0, url.lastIndexOf('?'));
      if (url.lastIndexOf('?') === -1) {
        extension = url.substring(url.lastIndexOf('.'));
      } else {
        extension = urlWithFileName.substring(urlWithFileName.lastIndexOf('.'));
      }
      let validExtensions = [];
      if (this.selectedTab === FileTypes.IMAGES) {
        validExtensions = ['.jpg', '.png'];
      } else if (this.selectedTab === FileTypes.VIDEOS) {
        validExtensions = ['.mp4', '.avi', '.mkv'];
      } else if (this.selectedTab === FileTypes.AUDIOS) {
        validExtensions = ['.mp3'];
      } else if (this.selectedTab === FileTypes.DESCRIPTORS) {
        validExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.pdf'];
      }
      const index = validExtensions.findIndex(val => val === extension);
      if (this.selectedTab === FileTypes.VIDEOS && !url.includes('youtube') && !url.includes('youtu') && index === -1) {
          throw new Error('Url invalid. Valid format are <b>' + validExtensions.toString() + '</b>');
      } else if (this.selectedTab !== FileTypes.VIDEOS && index === -1) {
          throw new Error('Url invalid. Valid format are <b>' + validExtensions.toString() + '</b>');
      }
    } catch (e) {
      errorFound = true;
      this.urlValidationError = e;
      setTimeout(() => {
        this.urlValidationError = '';
      }, 5000);
    }
    if (!errorFound) {
      const fileName = '';
      if (this.selectedTab === FileTypes.IMAGES) {
        const img = new Image();
        img.onload = (imgLoadEvent) => {
          const loadedImage: any = imgLoadEvent.currentTarget;
          const width = loadedImage.width;
          const height = loadedImage.height;
          const dimension = { width, height };
          const fileObj: FileObject = new FileObject(fileName, FileFormats.URL, url, this.selectedTab,
                                                      null, extension.substring(extension.lastIndexOf('.') + 1), null, '', dimension, '');
          this.storeFileObjectInList(fileObj);
        };
        img.src = url;
      } else if (this.selectedTab === FileTypes.VIDEOS) {
        let format = FileFormats.URL;
        if (this.selectedTab === FileTypes.VIDEOS && (url.includes('youtube') || url.includes('youtu')) ) {
            format = FileFormats.YOUTUBE;
        }
        const fileObj: FileObject = new FileObject(fileName, format, url, this.selectedTab, null,
                                                extension.substring(extension.lastIndexOf('.') + 1), null, '', {}, '');
        this.storeFileObjectInList(fileObj);
      } else {
        const fileObj: FileObject = new FileObject(fileName, FileFormats.URL, url, this.selectedTab, null,
                                                extension.substring(extension.lastIndexOf('.') + 1), null, '', {}, '');
        this.storeFileObjectInList(fileObj);
      }
      this.showFileUrlCollector = false;
    }
  }
  removeSite(site, i): any {
    this.sites.splice(i, 1);
    if (site.id === this.currentSite.id){
      this.showOrCloseLocationOnMap(site, 'close');
      this.currentSite = this.sites[0];
      this.siteNameCtrl.setValue(this.currentSite.locationName);
      this.descriptionCtrl.setValue(this.currentSite.description);
      this.showOrCloseLocationOnMap(this.currentSite, 'zoom');
    }
  }
  createNewSiteWithTaggedImg(tagImg: FileObject, i): any {
    const coords = this.basemapService.getTransformedCoordinates([tagImg.latitude, tagImg.longitude], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode());
    tagImg.latitude = coords[0];
    tagImg.longitude = coords[1];
    const index = this.sites.findIndex(element => {
                    console.log(`${element.latitudeLongitude.toString()} === ${[Number(tagImg.longitude), Number(tagImg.latitude)].toString()}`);
                    if ( element.latitudeLongitude.toString() === [Number(tagImg.longitude), Number(tagImg.latitude)].toString() ){
                      return true;
                    } else {
                      return false;
                    }
                  });
    if (index === -1) {
      const tempSite: FuseEarthSite =
          new FuseEarthSite(this.sites.length + 1, new Date().toISOString(), '', [], this.currentSession.geopadId,
            [], [Number(tagImg.longitude), Number(tagImg.latitude)],[], `Geotag location ${this.sites.length}`, 'Active', [],
            new Date().toISOString(), new Date().toISOString(), SiteType.POINT, this.getCurrentSiteParams(),
            this.initiateVerticalData(), []);
      tempSite.imageFilesList.push(tagImg);
      tempSite.latitudeLongitudeToShow = this.getLatLongsToShow([String(tagImg.longitude), String(tagImg.latitude)]);
      this.sites.push(tempSite);
    } else {
      this.sites[index].imageFilesList.push(tagImg);
    }
    for ( let siteIndex = 0; siteIndex < this.sites.length; siteIndex++){
      if (siteIndex === 0) {
        if (!this.commonService.isValid(this.sites[siteIndex].locationName)){
          this.sites[siteIndex].locationName = `User Location`;
        }
      } else {
        if (!this.commonService.isValid(this.sites[siteIndex].locationName)){
          this.sites[siteIndex].locationName = `Geotag location ${this.sites[siteIndex].id}`;
        }
      }
    }
    if (!this.commonService.isValid(this.siteNameCtrl.value)){
      this.siteNameCtrl.setValue(this.currentSite.locationName);
    }
    this.locationTaggedImages.splice(i, 1);
    if (this.locationTaggedImages.length === 0) {
      this.showLocationTaggedImages = false;
    }
    this.sites.forEach(site => {
      site.siteParams = this.getCurrentSiteParams();
    });
  }
  addTaggedImgToCurrentSite(tagImg, i): any {
    this.currentSite.imageFilesList.push(tagImg);
    this.locationTaggedImages.splice(i, 1);
    if (this.locationTaggedImages.length === 0) {
      this.showLocationTaggedImages = false;
    }
  }
  storeFileObjectInList(fileObj: FileObject): any {
    if (this.selectedTab === FileTypes.IMAGES) {
      this.currentSite.imageFilesList.push(fileObj);
      let gpsAvailable = false;
      this.currentSite.imageFilesList.forEach((tempObj: FileObject) => {
        if (tempObj.gpsAvailable) {
          gpsAvailable = true;
        }
      });

    } else if (this.selectedTab === FileTypes.VIDEOS) {
      this.currentSite.videoFilesList.push(fileObj);
    } else if (this.selectedTab === FileTypes.AUDIOS) {
      this.currentSite.audioFilesList.push(fileObj);
    } else if (this.selectedTab === FileTypes.NOTES) {
      this.currentSite.geopadNotes.push(fileObj);
    } else if (this.selectedTab === FileTypes.DESCRIPTORS) {
      this.currentSite.descriptorsFilesList.push(fileObj);
    }
  }
  removeFile(i): any {
    if (this.selectedTab === FileTypes.IMAGES) {
      this.currentSite.imageFilesList.splice(i, 1);
      let gpsAvailable = false;
      this.currentSite.imageFilesList.forEach((tempObj: FileObject) => {
        if (tempObj.gpsAvailable) {
          gpsAvailable = true;
        }
      });
    } else if (this.selectedTab === FileTypes.VIDEOS) {
      this.currentSite.videoFilesList.splice(i, 1);
    } else if (this.selectedTab === FileTypes.AUDIOS) {
      this.currentSite.audioFilesList.splice(i, 1);
    } else if (this.selectedTab === FileTypes.NOTES) {
      this.currentSite.geopadNotes.splice(i, 1);
    } else if (this.selectedTab === FileTypes.DESCRIPTORS) {
      this.currentSite.descriptorsFilesList.splice(i, 1);
    }
  }

  isErrorsFoundWhileValidatingSiteInfo(): boolean{
    let errorFound = false;
    this.notesValidationError = '';
    let project = '';
    if (this.userInfo.type === 'INDEPENDENT' || (this.userInfo.type !== 'INDEPENDENT' && this.userRole === 'USER_ADMIN')) {
      project = (this.projectInput.nativeElement.value).trim();
    } else {
      project = this.projectSelect.value;
    }
    let place = '';
    if (this.userInfo.type === 'INDEPENDENT' || (this.userInfo.type !== 'INDEPENDENT' && this.userRole === 'USER_ADMIN')) {
      place = (this.placeInput.nativeElement.value).trim();
    } else {
      place = this.placeSelect.value;
    }
    let topic = '';
    if (this.userInfo.type === 'INDEPENDENT' || (this.userInfo.type !== 'INDEPENDENT' && this.userRole === 'USER_ADMIN')) {
      topic = (this.topicInput.nativeElement.value).trim();
    } else {
      topic = this.topicSelect.value;
    }
    const sites: any[] = this.sites;
    try {
      if (!this.commonService.isValid(this.locationData)) {
        throw new Error('Please select a location');
      }
      for (let key = 0; key < sites.length; key++) {
        if (!this.commonService.isValid(sites[key].locationName)){
          const title: any = sites.length > 1 ? key + 1 : '';
          throw new Error(`Please enter name of site ${title}`);
        } else {
        }
        if (!this.commonService.isValid(sites[key].description)){
          const title: any = sites.length > 1 ? key + 1 : '';
          throw new Error(`Please enter description for site ${title}`);
        } else {
        }
      }
      if (!this.commonService.isValid(project)) {
        throw new Error('Please select project');
      } else if (!this.commonService.isValid(place)) {
        throw new Error('Please select place');
      } else if (!this.commonService.isValid(topic)) {
        throw new Error('Please select topic');
      }

    } catch (e) {
      errorFound = true;
      this.notesValidationError = e;
      setTimeout(() => {
        this.notesValidationError = '';
      }, 5000);
    }
    return errorFound;
  }
  saveNotes(): any {
    if (this.isGuest) {
      window.alert('Please login to do this...');
      return ;
    }
    if (this.globalObject.pageType === 'share') {
      this.showSessionShareSiteEditAlert = true;
      return ;
    }
    const errorFound = this.isErrorsFoundWhileValidatingSiteInfo();
    const sites: any[] = this.sites;

    if (!errorFound){
      if (!this.commonService.isValid(this.currentSession.geopadId)){
        this.currentSession.geopadId = 0;
      }
      this.siteRequestSent = 0;
      this.siteRequestReceived = 0;
      sites.forEach(site => {
        console.log('Saving site :', site);
        this.siteRequestSent++;
        this.saveNotesOfaSite(site);
      });
    }
  }
  saveNotesOfaSite(currentSite): any {
    currentSite.dataToSave = {};
    const notesDescription = currentSite.description; // this.notesDescription.nativeElement.value;
    let projectId = '';
    let placeId = '';
    let topicId = '';
    if (this.userInfo.type === 'INDEPENDENT' || (this.userInfo.type !== 'INDEPENDENT' && this.userRole === 'USER_ADMIN')) {
      const project = (this.projectInput.nativeElement.value).trim();
      const projectIndex = this.projects.findIndex(val => val.name === project);
      if (projectIndex !== -1){
        projectId = this.projects[projectIndex].topicId;
      }

      const place = (this.placeInput.nativeElement.value).trim();
      const placeIndex = this.places.findIndex(val => val.name === place);
      if (placeIndex !== -1){
        placeId = this.places[placeIndex].topicId;
      }
      const topic = (this.topicInput.nativeElement.value).trim();
      const topicIndex = this.topics.findIndex(val => val.name === topic);
      if (topicIndex !== -1){
        topicId = this.topics[topicIndex].topicId;
      }
    } else {
      projectId =  this.projectSelect.value;
      placeId = this.placeSelect.value;
      topicId = this.topicSelect.value;
    }
    const siteNameInput = currentSite.locationName;
    currentSite.filesList = [];
    currentSite.filesList = currentSite.filesList.concat(
      currentSite.imageFilesList, currentSite.videoFilesList, currentSite.audioFilesList,
      currentSite.descriptorsFilesList, currentSite.geopadNotes
    );
    currentSite.filesList = currentSite.filesList; // .filter(val => val.observationItemId === 0);
    if(currentSite.multiGeometryLatitudeLongitude == undefined ){
      if(currentSite.latitudeLongitude.length > 0) {
        const coords = currentSite.latitudeLongitude;
        let coords_trans;
        let temp_coords_trans = [];        
        if(coords.length >= 2 && Array.isArray(coords[0])) {
          if(Array.isArray(coords[0]) && coords[0].length > 2) {            
            let i=0;
            while ( i < coords.length ) {
              const coOrds = [];
              let j = 0;
              while ( j < coords[i].length ) {
                try{
                  const tempArray = [Number(coords[i][j]), Number(coords[i][j + 1])];
                  coords_trans = this.basemapService.getTransformedCoordinates(tempArray, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
                  coOrds.push(coords_trans[0]);
                  coOrds.push(coords_trans[1]);
                } catch (e) {
                  console.log(e);
                }
                j = j + 2;
              }
              temp_coords_trans.push(coOrds)
              i = i + 1;
            }
          }else{
            if(Array.isArray(coords[0]) && coords[0].length == 2){
              coords.forEach(latlngs => {              
                  coords_trans = this.basemapService.getTransformedCoordinates(latlngs, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
                  temp_coords_trans.push(coords_trans)
                })
           }
          }
          coords_trans = temp_coords_trans
          currentSite.multiGeometryLatitudeLongitude = coords_trans
          currentSite.ismultigeometry = true
          currentSite.latitudeLongitude = [0,0]
        }          
        else {
          if(coords.length==2 && !Array.isArray(coords[0])){
            coords_trans = this.basemapService.getTransformedCoordinates(coords, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
          } 
          else if(coords.length > 2 && !Array.isArray(coords[0])) {
            let temp_coordsTrans = [];
            let latlngs = [];
            coords.forEach(lats => {
              latlngs.push(lats)
              if(latlngs.length === 2 ) {                               
                let coordsTrans = this.basemapService.getTransformedCoordinates(latlngs, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(),'EPSG:4326')
                temp_coordsTrans.push(coordsTrans[0])
                temp_coordsTrans.push(coordsTrans[1])
                latlngs = [] 
              }
            });
            coords_trans = temp_coordsTrans 
          }                  
          console.log(coords, coords_trans)
          currentSite.latitudeLongitude = []
          currentSite.latitudeLongitude = coords_trans
        }        
      }
    }else{
      if(currentSite.multiGeometryLatitudeLongitude.length > 0) {
        const coords = currentSite.multiGeometryLatitudeLongitude;
        let coords_trans;
        let temp_coords_trans = [];
        if(coords.length >= 2 && Array.isArray(coords[0])) {
          if(Array.isArray(coords[0]) && coords[0].length > 2) {            
            let i=0;
            while ( i < coords.length ) {
              const coOrds = [];
              let j = 0;
              while ( j < coords[i].length ) {
                try{
                  const tempArray = [Number(coords[i][j]), Number(coords[i][j + 1])];
                  coords_trans = this.basemapService.getTransformedCoordinates(tempArray, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
                  coOrds.push(coords_trans[0]);
                  coOrds.push(coords_trans[1]);
                } catch (e) {
                  console.log(e);
                }
                j = j + 2;
              }
              temp_coords_trans.push(coOrds)
              i = i + 1;
            }
          }else{
            if(Array.isArray(coords[0]) && coords[0].length == 2){
              coords.forEach(latlngs => {              
                  coords_trans = this.basemapService.getTransformedCoordinates(latlngs, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
                  temp_coords_trans.push(coords_trans)
                })
            }
          }
          coords_trans = temp_coords_trans
          currentSite.multiGeometryLatitudeLongitude = coords_trans
          currentSite.ismultigeometry = true
          currentSite.latitudeLongitude = [0,0]
        } else {
          coords_trans = this.basemapService.getTransformedCoordinates(coords, this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326')
          console.log(coords, coords_trans)
          currentSite.latitudeLongitude = []
          currentSite.latitudeLongitude = coords_trans
        }

      }
    }
    
    const data: any = {
      observationInstanceId: currentSite.observationInstanceId,
      locationName: siteNameInput,
      description: notesDescription,
      geopadId: this.currentSession.geopadId,
      geopadNotes: '',
      uiTimestamp: new Date().toISOString(),
      tags: currentSite.tags, // this.locationTags,
      status: 'Active',
      projectId, // project, // 1,
      topicId, // topic, // 3,
      placeId, // place, // 7,
      latitudeLongitude: currentSite.latitudeLongitude, // [0,0] this should be ismultigeometry
      // 2 new colums added
      multiGeometryLatitudeLongitude: currentSite.multiGeometryLatitudeLongitude,
      ismultigeometry: currentSite.ismultigeometry,
      siteType: currentSite.siteType,
      iconColour: '',
      fillColour: '',
      iconLineSize: '',
      iconRotation: 0,
      fillColourOpacity: 4,
      obseravationInstanceIconsId: null,
      verticalData: this.commonService.isValid(currentSite.verticalData) ? JSON.stringify(currentSite.verticalData) : null
    };
    const siteParams: SiteParams = currentSite.siteParams;
    if (this.commonService.isValid(siteParams)){
      if (currentSite.siteType === SiteType.POINT){
        data.iconColour = siteParams.color;
        data.iconLineSize = siteParams.symbolSize;
        data.iconRotation = siteParams.rotationAngle;
        data.fillColourOpacity = 4;
        if (this.commonService.isValid(siteParams.icon)){
          data.obseravationInstanceIconsId = siteParams.icon.id;
        }
      } else {
        data.iconColour = siteParams.color;
        data.fillColour = siteParams.fillColor;
        data.iconLineSize = siteParams.symbolSize;
        data.iconRotation = siteParams.rotationAngle;
        data.fillColourOpacity = 4;
        if (this.commonService.isValid(siteParams.icon)){
          data.obseravationInstanceIconsId = siteParams.icon.id;
        }
      }
    }
    this.tagsToSaveList.forEach(tagsSave => {
      const coords = this.basemapService.getTransformedCoordinates([tagsSave.latitude, tagsSave.longitude], this.basemapService.getCurrentBasemap().getView().getProjection().getCode(), 'EPSG:4326');
      tagsSave.latitude = coords[0];
      tagsSave.longitude = coords[1];
    })
    this.processProjectSaving(currentSite, data);
  }

  processProjectSaving(currentSite, data): void{
    if (!this.commonService.isValid(data.projectId)){
      this.savingNotes.emit(true);
      const projectData: any = this.prepareObjectOfTopics((this.projectInput.nativeElement.value).trim(), 'project');
      this.topicsService.saveTopics(projectData)
            .subscribe(projectRes => {
              data.projectId = projectRes.body;
              this.processPlaceSaving(currentSite, data);
              this.getProjectsList();
            },
            error => {
              console.log(error);
              let msg = 'New project creation failed. Please try again.';
              if (this.commonService.isValid(error)){
                if (this.commonService.isValid(error.error)){
                  msg = error.error.message || msg;
                }
              }
              this.notesValidationError = msg;
              setTimeout(() => {
                this.notesValidationError = '';
              }, 5000);
              this.savingNotes.emit(false);
            });
    } else {
      this.processPlaceSaving(currentSite, data);
    }
  }
  processPlaceSaving(currentSite, data): void{
    if (!this.commonService.isValid(data.placeId)){
      const placeData: any = this.prepareObjectOfTopics((this.placeInput.nativeElement.value).trim(), 'place');
      placeData.parentTopicId = data.projectId;
      this.topicsService.saveTopics(placeData)
        .subscribe(placeRes => {
          data.placeId = placeRes.body;
          this.processTopicSaving(currentSite, data);
      }, error => {
        console.log(error);
        let msg = 'New place creation failed. Please try again.';
        if (this.commonService.isValid(error)){
          if (this.commonService.isValid(error.error)){
            msg = error.error.message || msg;
          }
        }
        this.notesValidationError = msg;
        setTimeout(() => {
          this.notesValidationError = '';
        }, 5000);
        this.savingNotes.emit(false);
      });
    } else {
      this.processTopicSaving(currentSite, data);
    }
  }

  processTopicSaving(currentSite, data): void{
    if (!this.commonService.isValid(data.topicId)){
      const topicData: any = this.prepareObjectOfTopics((this.topicInput.nativeElement.value).trim(), 'topic');
      topicData.parentTopicId = data.placeId;
      this.topicsService.saveTopics(topicData)
        .subscribe(topicRes => {
          data.topicId = topicRes.body;
          this.saveNoteWithProject(currentSite, data);
      }, error => {
        console.log(error);
        let msg = 'New Topic creation failed. Please try again.';
        if (this.commonService.isValid(error)){
          if (this.commonService.isValid(error.error)){
            msg = error.error.message || msg;
          }
        }
        this.notesValidationError = msg;
        setTimeout(() => {
          this.notesValidationError = '';
        }, 5000);
        this.savingNotes.emit(false);
      });
    } else {
      this.saveNoteWithProject(currentSite, data);
    }
  }

  prepareObjectOfTopics(topicName, topicType): any{
    return {
      organizationId: this.currentSession.organizationId,
      name: topicName,
      description: '',
      parentTopicId: 0,
      topicUsage: 1,
      status: 'Active',
      userId: this.userProfileData.user.userId,
      topicType
    };
  }

  saveNoteWithProject(currentSite, data): void{
    currentSite.dataToSave = data;
    let index = 0;
    currentSite.filesToSave = [];
    currentSite.filesList.forEach((element: FileObject) => {
      element.id = `${String(new Date().getTime())}_${index++}`;
      if (element.format === FileFormats.URL){
      } else if (element.format === FileFormats.FILE){
        currentSite.filesToSave.push(element);
      }
    });
    // GENERATE URLS AND SAVE SITES
    currentSite.generatedUrlsCount = 0;
    this.savingNotes.emit(true);
    if (currentSite.filesToSave.length > 0) {
      currentSite.filesToSave.forEach((element: FileObject) => {
        this.firebaseService.uploadFileAndGetURL(element.file, element.type, element.id, currentSite.id,
          'geopad', this.imageUploadAbservable);
      });
    } else {
      currentSite.dataToSave.observationItemInfoList = currentSite.filesList;
      currentSite.dataToSave.observationTagsInfoList = this.tagsToSaveList;
      if (this.operation === 'add') {
        this.callSaveNotes(currentSite);
      } else if (this.operation === 'update') {
        this.callUpdateNotes(currentSite);
      }
    }

  }

  uploadStatusEvent(data: any): any {
    const siteIndex = this.sites.findIndex(val => val.id === data.siteId);
    let currentSite: any = {};
    if (siteIndex !== -1) {
      currentSite = this.sites[siteIndex];
    }
    if (data.status === 'completed' || data.status === 'error') {
      currentSite.generatedUrlsCount++;
      const index = currentSite.filesToSave.findIndex((val: FileObject) => val.id === data.id);
      if (index !== -1) {
        currentSite.filesToSave[index].url = data.url;
      }
    }
    // CHECKING IF ALL URLs GENERATED
    if (currentSite.generatedUrlsCount === currentSite.filesToSave.length) {
      currentSite.filesToSave.forEach(element => {
        const index = currentSite.filesList.findIndex((val: FileObject) => val.id === element.id);
        if (index !== -1) {
          currentSite.filesList[index] = element;
        }
      });
      currentSite.filesList.forEach((element: FileObject) => {
        element.format  = FileFormats.URL;
        element.file = null;
        element.base64Data = null;
      });
      currentSite.dataToSave.observationItemInfoList = currentSite.filesList;      
      currentSite.dataToSave.observationTagsInfoList = this.tagsToSaveList;
      if (this.operation === 'add') {
        this.callSaveNotes(currentSite);
      } else if (this.operation === 'update') {
        this.callUpdateNotes(currentSite);
      }

    } else {
    }
  }
  callSaveNotes(currentSite): any {
    if (currentSite.dataToSave.obseravationInstanceIconsId === null) {
      currentSite.dataToSave.obseravationInstanceIconsId = 0;
      const msg = `Please Select at lest one drop down from Type of Site`;
      this.notesValidationError = msg;
      setTimeout(() => {
        this.notesValidationError = '';
      }, 5000);
      this.savingNotes.emit(false);
      this.siteRequestReceived++;
      if (this.siteRequestSent === this.siteRequestReceived){
        this.savingNotes.emit(false);
      }
      return;
    }
    this.notepadService.saveSingleSite(currentSite.dataToSave, this.currentSession.geopadId)
        .subscribe(result => {
          this.siteRequestReceived++;
          if (this.siteRequestSent === this.siteRequestReceived){
            this.capturedData.emit(currentSite);
            this.savingNotes.emit(false);
            this.sites.forEach(site => {              
              this.showOrCloseLocationOnMap(site, 'close');
            });
          }
        },
        error => {
          console.log('Error while saving notes');
          console.log(error);
          let msg = `Error while saving site ${currentSite.dataToSave.locationName}`;
          if (this.commonService.isValid(error)){
            if (this.commonService.isValid(error.error)){
              msg = error.error.message || msg;
            }
          }
          this.notesValidationError = msg;
          setTimeout(() => {
            this.notesValidationError = '';
          }, 5000);
          this.savingNotes.emit(false);
          this.siteRequestReceived++;
          if (this.siteRequestSent === this.siteRequestReceived){
            this.savingNotes.emit(false);
          }
        });
  }
  callUpdateNotes(currentSite): any {
    this.notepadService.updateSingleSite(currentSite.dataToSave, this.currentSession.geopadId, this.currentSite.id)
        .subscribe(result => {
          this.siteRequestReceived++;
          console.log(`Sent: ${this.siteRequestSent}, Recv: ${this.siteRequestReceived}`);
          if (this.siteRequestSent === this.siteRequestReceived) {
            this.capturedData.emit(currentSite);
            this.savingNotes.emit(false);
            this.sites.forEach(site => {
              this.showOrCloseLocationOnMap(site, 'close');
            });
          }
        },
        error => {
          console.log('Error while saving notes');
          console.log(error);
          let msg = `Error while saving site ${currentSite.dataToSave.locationName}`;
          if (this.commonService.isValid(error)){
            if (this.commonService.isValid(error.error)){
              msg = error.error.message || msg;
            }
          }
          this.notesValidationError = msg;
          setTimeout(() => {
            this.notesValidationError = '';
          }, 5000);
          this.savingNotes.emit(false);
          this.siteRequestReceived++;
          if (this.siteRequestSent === this.siteRequestReceived){
            this.savingNotes.emit(false);
          }
        });
  }

  getProjectsList(): any {
    this.projectSelect.disable();
    this.placeSelect.disable();
    this.topicSelect.disable();
    this.getPlacesListByProjectId(1);
    this.getTopicsListByPlaceId(1);
    this.topicsService.getProjectsList(this.globalObject.pageType === 'COVID19' ? 'COVID19' : this.userInfo.type,
                                      this.globalObject.geobase.organizationId)
    .subscribe(projectInfo => {
      if (this.operation !== 'view') {
        this.projectSelect.enable();
      }
      if (!this.commonService.isValid(projectInfo)) {
      } else {
        this.projects = [{name: '', topicId: ''}].concat(projectInfo);
        if (this.commonService.isValid(this.selectedProjectId) && this.selectedProjectId > 0) {
          const index = this.projects.findIndex(val => Number(val.topicId) === Number(this.selectedProjectId));
          if (index !== -1){
            this.selectedProject = this.projects[index];
          }
        }
        setTimeout(() => {
          this.setDataToFormControl(this.projectSelect, this.projectInput, this.selectedProject, this.projects);
        }, 500);
      }
    }, error => {
      console.log(error);
      if (error.errorCode === 500) {
      }
      if (this.operation !== 'view') {
        this.projectSelect.enable();
      }
    });
  }

  getPlacesListByProjectId(projectId): any {
    this.placeSelect.disable();
    this.topicSelect.disable();
    if (this.globalObject.pageType !== 'COVID19'){
      this.topicsService.getTopicsListByTopicType('place')
      .subscribe(placesInfo => {
        if (this.operation !== 'view') {
          this.placeSelect.enable();
        }
        if (!this.commonService.isValid(placesInfo)) {
        } else {
        this.places = [{name: '', topicId: ''}].concat(placesInfo);
        if (this.commonService.isValid(this.selectedPlaceId) && this.selectedPlaceId > 0) {
        const index = this.places.findIndex(val => Number(val.topicId) === Number(this.selectedPlaceId));
        if (index !== -1){
          this.selectedPlace = this.places[index];
        }
      }
        setTimeout(() => {
        this.setDataToFormControl(this.placeSelect, this.placeInput, this.selectedPlace, this.places);
      }, 500);
      }
      }, error => {
        console.log(error);
        if (error.errorCode === 500) {
        }
        if (this.operation !== 'view') {
          this.placeSelect.enable();
         }
       });
    } else {
      this.places = [{
        createdDate: null,
        description: 'Place is world wide',
        name: 'world-wide',
        organizationId: 5,
        parentTopicId: 994,
        status: 'Active',
        topicId: 1010,
        topicUsage: 1,
        updatedDate: null,
      }];
      if (this.commonService.isValid(this.selectedPlaceId) && this.selectedPlaceId > 0) {
        const index = this.places.findIndex(val => Number(val.topicId) === Number(this.selectedPlaceId));
        if (index !== -1){
          this.selectedPlace = this.places[index];
        }
      }
      setTimeout(() => {
        this.setDataToFormControl(this.placeSelect, this.placeInput, this.selectedPlace, this.places);
      }, 500);
    }
  }

  getTopicsListByPlaceId(placeId): any {
    this.topicSelect.disable();
    if (this.globalObject.pageType !== 'COVID19'){
       this.topicsService.getTopicsListByTopicType('topic')
       .subscribe(topicsInfo => {
         if (this.operation !== 'view') {
           this.topicSelect.enable();
         }
         if (!this.commonService.isValid(topicsInfo)) {
         } else {
           this.topics = [{name: '', topicId: ''}].concat(topicsInfo);
           if (this.commonService.isValid(this.selectedTopicId) && this.selectedTopicId > 0) {
        const index = this.topics.findIndex(val => Number(val.topicId) === Number(this.selectedTopicId));
        if (index !== -1){
          this.selectedTopic = this.topics[index];
        }
      }
           setTimeout(() => {
        this.setDataToFormControl(this.topicSelect, this.topicInput, this.selectedTopic, this.topics);
      }, 500);
         }
       }, error => {
         console.log('Error while getting topicsInfo');
         console.log(error);
         if (error.errorCode === 500) {
         }
         if (this.operation !== 'view') {
           this.topicSelect.enable();
         }
       });
    } else {
      this.topicsService.getTopicsListByPlaceId(placeId)
            .subscribe(topicsInfo => {
              if (this.operation !== 'view') {
                this.topicSelect.enable();
              }
              if (!this.commonService.isValid(topicsInfo)) {
              } else {
                this.topics = [{name: '', topicId: ''}].concat(topicsInfo);
                if (this.commonService.isValid(this.selectedTopicId) && this.selectedTopicId > 0) {
                  const index = this.topics.findIndex(val => Number(val.topicId) === Number(this.selectedTopicId));
                  if (index !== -1){
                    this.selectedTopic = this.topics[index];
                  }
                }
                setTimeout(() => {
                  this.setDataToFormControl(this.topicSelect, this.topicInput, this.selectedTopic, this.topics);
                }, 500);
              }
            }, error => {
              console.log(error);
              if (error.errorCode === 500) {
              }
              if (this.operation !== 'view') {
                this.topicSelect.enable();
              }
            });
    }

  }

  setDataToFormControl(formCtrl: FormControl, inputEle: ElementRef<HTMLInputElement>, selectedObj: any, allListArr: any[]): any {
    let topicId;
    let topicName;
    try{
      if (this.commonService.isValid(selectedObj)) {
        if (this.commonService.isValid(selectedObj.topicId)) {
          topicId = selectedObj.topicId;
          topicName = selectedObj.name;
        } else {
          topicId = allListArr[0].topicId;
          topicName = allListArr[0].name;
        }
      } else{
        topicId = allListArr[0].topicId;
        topicName = allListArr[0].name;
      }
    } catch (e){
      topicId = '';
      topicName = '';
    }
    formCtrl.setValue(topicId);
    if (inputEle) {
      inputEle.nativeElement.value = topicName;
    }
  }

  updateSiteStyleOnMap(note: FuseEarthSite): any{
    const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
               this.tempCreateSiteId; // note.latitudeLongitude.toString();
    const name = `observationInstanceId_${id}`;

    let layerFound = false;
    let addedLayerObj: any;
    this.basemap.getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === name) {
          layerFound = true;
          addedLayerObj = layerObj;
        }
      }
    });
    if (layerFound) {
      this.notepadService.updateSiteStyle(addedLayerObj, note.siteParams);
    }
  }
  showOrCloseLocationOnMap(note: FuseEarthSite, operation = '', watchOnPolygonChanges = null): any {
    let geometryData: any;
    let isPolygon = false;
    let isMultiPolygon = false;
    const siteParams = note.siteParams;
    const currentContextInfo: any = {};
    for (const key in this.currentContextInfo) {
      if (Object.hasOwnProperty.call(this.currentContextInfo, key)) {
        currentContextInfo[key] = this.currentContextInfo[key];
      }
    }
    currentContextInfo.site = note;
    var data: any={};    
    if(note.multiGeometryLatitudeLongitude != undefined){
      this.locationData= note.multiGeometryLatitudeLongitude;
      if(Array.isArray(this.locationData[0])) {
        if(this.locationData[0].length == 2){
          const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
          this.tempCreateSiteId; // note.latitudeLongitude.toString();
          data = {
              features: {
              type: 'FeatureCollection',
              features: []
            },
            name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
          };
          let i=0;
          while ( i < note.multiGeometryLatitudeLongitude.length ) {
            var featureData = {
              type:'Feature',
              geometry:{
                type: this.notepadService.shapeDrawType.MULTI_POINT, // 'multiPoint',
                // coordinates: ptCoords
                coordinates: [note.multiGeometryLatitudeLongitude[i]]
              },
              properties: null
            }
            data.features.features.push(featureData)
            i = i + 1;
          };
          // 
        }
        else{
          if(this.locationData[0].length > 2){            
            if (this.locationData[0].length > 4) {              
              if (this.locationData[0][0] === this.locationData[0][this.locationData[0].length - 2] &&
                  this.locationData[0][1] === this.locationData[0][this.locationData[0].length - 1]) {
                  isMultiPolygon = true;
              }
            }
          }
          const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
          this.tempCreateSiteId; // note.latitudeLongitude.toString();
          data = {
              features: {
              type: 'FeatureCollection',
              features: []
            },
            name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
          };
          let i=0;
          // let j = 0;
          while ( i < note.multiGeometryLatitudeLongitude.length ) {
            const coOrds = [];
            let j = 0;
            while ( j < note.multiGeometryLatitudeLongitude[i].length ) {
              try{
                const tempArray = [Number(note.multiGeometryLatitudeLongitude[i][j]), Number(note.multiGeometryLatitudeLongitude[i][j + 1])];
                coOrds.push(tempArray);
              } catch (e) {
                console.log(e);
              }
              j = j + 2;
            }
            if(isMultiPolygon){
              featureData = {
                type:'Feature',
                geometry:{
                  type: this.notepadService.shapeDrawType.MULTI_POLYGON,
                  // coordinates: ptCoords
                  coordinates: [[coOrds]]
                },
                properties: null
              }
            }else{
              featureData = {
                type:'Feature',
                geometry:{
                  type: this.notepadService.shapeDrawType.MULTI_LINE,
                  // coordinates: ptCoords
                  coordinates: [coOrds]
                },
                properties: null
              }
            }            
            data.features.features.push(featureData)
            i = i + 1;
          };
        } 
      }
    }
    else{  
      if (note.latitudeLongitude.length === 2 && !Array.isArray(note.latitudeLongitude[0])) {
        let ptCoords;
        if(note.locationName == ''){
          ptCoords = [Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])];
        }else{
        ptCoords = this.basemapService.getTransformedCoordinates([Number(note.latitudeLongitude[0]), Number(note.latitudeLongitude[1])], 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode())
        }
        geometryData = {
          type: this.notepadService.shapeDrawType.POINT, // 'Point',
          coordinates: ptCoords
        };
        const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
        this.tempCreateSiteId; // note.latitudeLongitude.toString();
        data = {
          features: {
          type: 'FeatureCollection',
          features: [{
          type: 'Feature',
          geometry: geometryData,
          properties: null
          }]
        },
        name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
        };
      } 
      else{
        if (note.latitudeLongitude.length >= 2) {
          if(!Array.isArray(this.locationData[0])){
            if (note.latitudeLongitude.length > 4 ) {
              if (note.latitudeLongitude[0] === note.latitudeLongitude[note.latitudeLongitude.length - 2] &&
                  note.latitudeLongitude[1] === note.latitudeLongitude[note.latitudeLongitude.length - 1]) {
                  isPolygon = true;
              }
            }
            geometryData = {
              type: isPolygon ? this.notepadService.shapeDrawType.POLYGON : this.notepadService.shapeDrawType.LINE_STRING,
              coordinates: []
            };
            let i = 0;
            const coOrds = [];
            while ( i < note.latitudeLongitude.length ) {
              try{
                const tempArray = [Number(note.latitudeLongitude[i]), Number(note.latitudeLongitude[i + 1])];
                coOrds.push(tempArray);
              } catch (e) {
                console.log(e);
              }
              i = i + 2;
            }
            if(note.locationName == ''){
              geometryData.coordinates = isPolygon ? [coOrds] : coOrds;
            }else{
              let coords_trans = coOrds.map(coord =>this.basemapService.getTransformedCoordinates(coord, 'EPSG:4326', this.basemapService.getCurrentBasemap().getView().getProjection().getCode()))
              geometryData.coordinates = isPolygon ? [coords_trans] : coords_trans;
            }   
            const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
              this.tempCreateSiteId; // note.latitudeLongitude.toString();
            data = {
              features: {
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                  geometry: geometryData,
                  properties: null
                }]
              },
              name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
            };         
          }
          else if(Array.isArray(this.locationData[0])) {
            if(this.locationData[0].length == 2 && !Array.isArray(this.locationData[0][0])){
              const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
              this.tempCreateSiteId; // note.latitudeLongitude.toString();
              data = {
                  features: {
                  type: 'FeatureCollection',
                  features: []
                },
                name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
              };
              let i=0;
              while ( i < note.latitudeLongitude.length ) {
                var featureData = {
                  type:'Feature',
                  geometry:{
                    type: this.notepadService.shapeDrawType.MULTI_POINT, // 'multiPoint',
                    // coordinates: ptCoords
                    coordinates: [note.latitudeLongitude[i]]
                  },
                  properties: null
                }
                data.features.features.push(featureData)
                i = i + 1;
              };
              // 
            }
            else{
              if(this.locationData[0].length >= 2 && !Array.isArray(this.locationData[0][0])){                         
                if (this.locationData[0].length > 4) {                  
                  if (note.latitudeLongitude[0][0] === note.latitudeLongitude[0][note.latitudeLongitude[0].length - 2] &&
                      note.latitudeLongitude[0][1] === note.latitudeLongitude[0][note.latitudeLongitude[0].length - 1]) {
                      isMultiPolygon = true;
                  }
                }
                const id = this.commonService.isValid(note.observationInstanceId) ? note.observationInstanceId :
                this.tempCreateSiteId; // note.latitudeLongitude.toString();
                data = {
                    features: {
                    type: 'FeatureCollection',
                    features: []
                  },
                  name: /*`${note.locationName}_${id}` /*/ `observationInstanceId_${id}`
                };
                let i=0;
                // let j = 0;
                while ( i < note.latitudeLongitude.length ) {
                  const coOrds = [];
                  let j = 0;
                  while ( j < note.latitudeLongitude[i].length ) {
                    try{
                      const tempArray = [Number(note.latitudeLongitude[i][j]), Number(note.latitudeLongitude[i][j + 1])];
                      coOrds.push(tempArray);
                    } catch (e) {
                      console.log(e);
                    }
                    j = j + 2;
                  }
                  if(isMultiPolygon){
                    featureData = {
                      type:'Feature',
                      geometry:{
                        type: this.notepadService.shapeDrawType.MULTI_POLYGON,
                        // coordinates: ptCoords
                        coordinates: [[coOrds]]
                      },
                      properties: null
                    }
                  }else{
                    featureData = {
                      type:'Feature',
                      geometry:{
                        type: this.notepadService.shapeDrawType.MULTI_LINE,
                        // coordinates: ptCoords
                        coordinates: [coOrds]
                      },
                      properties: null
                    }
                  }                  
                  data.features.features.push(featureData)
                  i = i + 1;
                };
              }
            } 
          } 
        }  
      } 
    }   
  
    let layerFound = false;
    let addedLayerObj: any;
    this.basemap.getLayers().forEach(layerObj => {
      if (layerObj !== undefined) {
        if (layerObj.values_.name === data.name) {
          layerFound = true;
          addedLayerObj = layerObj;
        }
      }
    });
    if (layerFound) {
      this.notepadService.removeLayerFromMap(this.basemap, data.name);
    } else if (operation === '' || operation === 'zoom') {
      if(note.multiGeometryLatitudeLongitude!= undefined){
        
        if(Array.isArray(this.locationData[0]) && this.locationData[0].length == 2 ){
          this.notepadService.reDrawPointOrPolygonOnMap(
            this.notepadService.shapeDrawType.MULTI_POINT,
            data, operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
        }else{
          this.notepadService.reDrawPointOrPolygonOnMap(
            isMultiPolygon ? this.notepadService.shapeDrawType.MULTI_POLYGON : this.notepadService.shapeDrawType.MULTI_LINE,
            data, operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
        }
      }
      else{
        // ONLY OF OPERATION is '', IT SHOULD WORK IN TOGGLE MODE
        if (note.latitudeLongitude.length === 2 && !Array.isArray(note.latitudeLongitude[0])) {
          this.notepadService.reDrawPointOrPolygonOnMap(this.notepadService.shapeDrawType.POINT, data,
                  operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
  
        } else if (note.latitudeLongitude.length >= 2 && !Array.isArray(this.locationData[0][0])) {
          console.log('ADDING POLYGON');
          if(Array.isArray(this.locationData[0])){
            if(this.locationData[0].length == 2 && !Array.isArray(this.locationData[0])){
              this.notepadService.reDrawPointOrPolygonOnMap(
                this.notepadService.shapeDrawType.MULTI_POINT,
                data, operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
            }
            else{
              this.notepadService.reDrawPointOrPolygonOnMap(
                isMultiPolygon ? this.notepadService.shapeDrawType.MULTI_POLYGON : this.notepadService.shapeDrawType.MULTI_LINE,
                data, operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
            }
            
          }
          // LINE or PLOYGON is ok here.
          else{
            this.notepadService.reDrawPointOrPolygonOnMap(
                  isPolygon ? this.notepadService.shapeDrawType.POLYGON : this.notepadService.shapeDrawType.LINE_STRING,
                  data, operation === 'zoom', watchOnPolygonChanges, currentContextInfo, siteParams);
          }
        }
      }      
      // Here adding the tagsList
      note.observationTagsList.forEach(tag => {
        const index = this.currentSite.tags.findIndex(x => x === tag.tagName);
        this.tagClicked(index, true, tag);
      })
    }
  }

  setValueForInputFromSelect(event, inputFrom: string, listItems: any[]): void{
      const val = event.target.value;
      let nameToSet = '';
      const index = listItems.findIndex(item => String(item.topicId) === String(val));
      if (index !== -1){
        nameToSet = listItems[index].name;
      }
      try{
        if (inputFrom === 'project') {
          this.projectInput.nativeElement.value = nameToSet;
        } else if (inputFrom === 'place') {
          this.placeInput.nativeElement.value = nameToSet;
        } else if (inputFrom === 'topic') {
          this.topicInput.nativeElement.value = nameToSet;
        }
      } catch (e){
      }
  }

  downloadFile(file: FileObject): void{
    window.open(file.url, '_blank');
  }
  getSiteIconsDropDown(iconCategory, iconSubCategory): any {
    this.notepadService.getSiteIconInfo(iconCategory, iconSubCategory)
    .subscribe (generalSiteIcons => {
      if (!this.commonService.isValid(generalSiteIcons)) {
      } else {
        if (generalSiteIcons.length > 0) {
          generalSiteIcons.forEach(iconInfo => {
            const rowData = {name: iconInfo.name, imgUrl: iconInfo.url, value: iconInfo.iconType,
              id: iconInfo.observationInstanceIconsId,
              iconCategory: iconInfo.iconCategory, iconSubCategory: iconInfo.iconSubCategory, iconType: iconInfo.iconType };
            if (iconCategory === 'General') {
              this.generalSiteIconsList.push(rowData);
            } else if (iconCategory === 'Special') {
              this.specialSiteIconsList.push({ name: iconInfo.name, imgUrl: iconInfo.url,
                      value: iconInfo.iconType, id: iconInfo.observationInstanceIconsId,
                      iconCategory: iconInfo.iconCategory, iconSubCategory: iconInfo.iconSubCategory, iconType: iconInfo.iconType});
            }  else if (iconCategory === 'Custom') {
              this.customSiteIconsList.push({ name: iconInfo.name, imgUrl: iconInfo.url,
                      value: iconInfo.iconType, id: iconInfo.observationInstanceIconsId,
                      iconCategory: iconInfo.iconCategory, iconSubCategory: iconInfo.iconSubCategory, iconType: iconInfo.iconType});
            }
          });
          if (this.operation === 'add' || !this.commonService.isValid(this.generalSiteSelectedIcon) ||
            (this.operation === 'update' && !this.commonService.isValid(this.currentSite.siteParams.icon.id)) ) {
            this.generalSiteSelectedIcon = this.generalSiteIconsList[0];
            this.currentSiteIcon = this.generalSiteIconsList[0];
            this.selectSiteIcon('general', this.currentSite.siteType === this.siteTypeEnum.MULTI_POINT ?
              this.siteTypeEnum.POINT : this.siteTypeEnum.LINE, this.generalSiteSelectedIcon);
            }
          if (this.customSiteIconsList.length > 0 &&
              (this.operation === 'add' || !this.commonService.isValid(this.customSiteSelectedIcon.imgUrl)) ) {
            this.customSiteSelectedIcon = this.customSiteIconsList[0];
          }
          if (this.specialSiteIconsList.length > 0 &&
              (this.operation === 'add' || !this.commonService.isValid(this.specialSiteSelectedIcon.imgUrl)) ) {
            this.specialSiteSelectedIcon = this.specialSiteIconsList[0];
          }
        }
      }
      this.initialSiteData = this.commonService.getObjectClone(this.currentSite);
    }, error => {
      console.log('Error while getting generalSiteIcons');
      console.log(error);
      if (error.errorCode === 500) {
      }
    });
  }

  onCustomPointIconUpload(event): any {
    if (event.target.files.length > 0) {
      // Here need to add validation for uploading icon
      const convertedSize = (event.target.files[0].size / (1024 * 1024)).toFixed(1);
      if (Number(convertedSize) > 1) {
      const msg = `Icon size should be less than 1 MB`;
      this.notesValidationError = msg;
      setTimeout(() => {
        this.notesValidationError = '';
      }, 5000);
      return;
      }
      const returnData = {
        inputFiles: event.target.files[0],
        filetype: 'site_icons/custom',
        fileName: event.target.files[0].name,
        firebaseUrl: '',
        metadata: ''
      };
      const firebaseUtil = new FirebaseUtil(this.firestorage);
      firebaseUtil.firebaseUtilCallback = (firebaseFileURL) => {
        returnData.firebaseUrl = firebaseFileURL;
        const customIconInfo = {
          iconCategory: 'Custom',
          iconSubCategory: 'Point',
          iconType: 'Custom',
          name: returnData.fileName,
          url: firebaseFileURL,
          organizationId: this.currentSession.organizationId,
          userId: this.userProfileData.user.userId,
          status: 'ACTIVE'
        };
        this.notepadService.saveCustomSiteIconInfo(customIconInfo)
        .subscribe(result => {
          this.getSiteIconsDropDown('Custom', SiteType.POINT);
        },
        error => {
          console.log(error);
          let msg = `Error while saving custom site icon`;
          if (this.commonService.isValid(error)){
            if (this.commonService.isValid(error.error)){
              msg = error.error.message || msg;
            }
          }
          this.notesValidationError = msg;
          setTimeout(() => {
            this.notesValidationError = '';
          }, 5000);
        });
      };
      firebaseUtil.getFirebaseFileURL(returnData.inputFiles, returnData.filetype, returnData.metadata, this.progress);

    }
  }
  setDefaultPosition(event): any {
    this._updateDraggableObj();
    this._setRotation(0);
    this.redrawSiteOnMap();
  }
  private _updateDraggableObj(): any {
    Draggable.create('#siteIconRotationAngNeedle', {
      type: 'rotation',
      throwProps: true,
      // bounds: { minRotation: -23, maxRotation: 337 },
      onDrag: (e) => {
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'siteIconRotationAngNeedle') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.commonService.isValid(target)) {
          const element = target; // e.target;
          let angle = element._gsTransform.rotation;
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          console.log('INVALID TARGET...');
        }
      },
      onDragEnd: (e) => {
        let target = null;
        if (e.target.tagName === 'SPAN') {
          target = e.target.parentNode || e.target.parentElement;
        } else if (e.target.id === 'siteIconRotationAngNeedle') {
          target = e.target;
        } else {
          console.log('OTHER ELEMENT');
        }
        if (this.commonService.isValid(target)) {
          const element = target; // e.target;
          let angle = element._gsTransform.rotation;
          angle = angle + 23;
          this._setRotation(angle);
        } else {
          console.log('INVALID TARGET...');
        }
      }
    });
    const globeIconDraggable = Draggable.get('#siteIconRotationAngNeedle');
    TweenLite.set('#siteIconRotationAngNeedle', { rotation: 0 });
    globeIconDraggable.update();
  }

  addNewColumn(direction): void{
    const id = new Date().getTime();
    if (direction === 'left'){
      this.currentSite.verticalData.headers = [{name: '', id, order: null}].concat(this.currentSite.verticalData.headers);
    } else if (direction === 'right'){
      this.currentSite.verticalData.headers.push({name: '', id, order: null});
    }
    this.setOrderForVerticalInfoHeaders();
  }
  removeColumn(index): void{
    let fieldName = '';
    if (this.currentSite.verticalData.headers.length > 0){
      fieldName = this.currentSite.verticalData.headers[index].id; // .name;
      this.currentSite.verticalData.headers.splice(index, 1);
    }
    this.setOrderForVerticalInfoHeaders();
    this.currentSite.verticalData.body.forEach(element => {
      try{
        delete element[fieldName];
      } catch (e){
        console.log(e);
      }
    });
  }
  setOrderForVerticalInfoHeaders(): void{
    this.currentSite.verticalData.headers.forEach((element, index) => {
      element.order = index;
    });
  }
  addRow(): void{
    const row: any = {};
    this.currentSite.verticalData.headers.forEach((element, index) => {
      row[element.id] = '';
    });
    this.currentSite.verticalData.body.push(row);
  }
  removeRow(index): void{
    if (this.currentSite.verticalData.body.length > 0){
      this.currentSite.verticalData.body.splice(index, 1);
    }
  }  
}
