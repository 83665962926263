import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialAuthService } from 'src/app/Services/socialAuthService';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showDashboard = true;

  constructor(private router: Router, private firebaseAuth: SocialAuthService) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup(
      {
      email : new FormControl('',[Validators.required, Validators.email]),
      password : new FormControl('',[Validators.required,Validators.minLength(6)])
      }
      );

  }
  onLogin(email, password){
    this.firebaseAuth.signInWithEmailAndPassword(email, password)
            .then(res => {
                console.log(res);
                if (res.user.email){
                  this.showDashboard = false;
                }
                else{
                }
              }, error => {
                console.error('SIGN IN WITH EMAIL PWD ERR');
                console.error(error);                
            })
  }
}


