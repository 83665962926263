import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { AnnotationPolygon, AnnotationToolComponent } from 'src/app/annotation-tool/annotation-tool.component';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { FileUtil } from 'src/app/geobar/util/fileUtil';
import { KMLGroundOverlayParsing } from 'src/app/geobar/util/kmlGroundOverlayParsing';
import { GeoPopupComponent } from 'src/app/geopopup/geopopup.component';
import { AnnotationToolService } from 'src/app/Services/annotation-tool.service';
import { AuthObservableService } from 'src/app/Services/authObservableService';
import { CommonService } from 'src/app/Services/common.service';
import { GeotrayComponent } from '../geotray.component';
import { GeotrayService } from '../geotray.service';
import {GeometryTool} from '../tools/geometry-tool';
import { package_features_control as packagefeatures } from '../../map-canvas/map-canvas.component'

import { MiniTowerItemComponent } from 'src/app/geotower/mini-tower-item/mini-tower-item.component';
import { MyService } from 'src/app/my-service.service';
import { Circle, Fill, Stroke, Style } from 'ol/style.js';
import Draw from 'ol/interaction/Draw.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorSource from 'ol/source/Vector.js';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import OlMap from 'ol/Map';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { emit } from 'process';
import { LineString } from 'ol/geom';
import Pointer from 'ol/interaction/Pointer';
import { SlopeFinderComponent } from '../slope-finder/slope-finder.component';
// import * as turf from 'turf';
import { Geometry } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import * as turf from '@turf/turf';
import { VicinityTool } from '../tools/vicinity-tool';
import { Select } from 'ol/interaction';
import {Snap, Modify} from 'ol/interaction';
import { Subscription } from 'rxjs';


declare var $: any;


@Component({
  selector: 'app-geotray-menu',
  templateUrl: './geotray-menu.component.html',
  styleUrls: ['./geotray-menu.component.scss']
})
export class GeotrayMenuComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() state: boolean;
  @Output() parentFunction:EventEmitter<any> = new EventEmitter()
  propertiesWindowVisible: any;
  predefinedVisibility : any ;
  classifiedVisibility : any ;
  blendedVisibility: any;
  collocatedVisibility: any;
  extendedVisibility: any;
  slopefinderVisibility: any;
  slopefinderVisibilityAEDS: any;
  buffertoolVisibility: any;
  vicinitytoolVisibility: any;
  private basemap: OlMap;
  private renderer: Renderer2;
  raster: any;
  vector: any;
  source: VectorSource;
  draw: any;
  snap: any;
  receivedslope: any;
  //map: OlMap.Map;
  @Input() layernames;
  @Input() layersListForFarming;
  @Input() layer: any;
  count=-1
  @ViewChild(GeoPopupComponent) popupComponent1: GeoPopupComponent;
  @Input() wings: any = [];
  @Input() resetAllWings: String = '';
  @Input() isGuest = true;
  @Output() onPropertiesClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onslopeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAEDSClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onbufferClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onPredefinedClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClassifiedClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBlendedClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onCollocatedClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onExtendedClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onWingHovered: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWingHoveredOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWingSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMenuBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() showGeopadWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() showPropertyWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() showGeoSessionWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() nameEmitter:EventEmitter < string > = new EventEmitter<any>();  
  @Output() slopetoolcall: EventEmitter<boolean> = new EventEmitter<any>();
  @Output() showAnalyDropdown:EventEmitter < string > = new EventEmitter<any>();  
  @Output() showGeoDropdown:EventEmitter < string > = new EventEmitter<any>(); 

  setActive = false;
  currentTooltipContent = '';
  width = 50;
  height = 50;
  showSubOptions = false;  
  showGeometryProperties = false;
  showProperties = false;
  showbuffer = false;
  showvicinity = false;
  showslope = false;
  showslopeAEDS = false;
  selectedSubOption: string;
  selectedProperty: string;
  afterLoginOperations: any[] = [];
  fileNames: string[];
  slopefindercursor =
    'url(/assets/svgs/Farming/slope-finder-icon.svg) 31 68, auto';
  buffertoolcursor =
    'url(/assets/svgs/Farming/buffer-tool-icon.svg) 33.99999 68, auto';
  cursorService: any;
  percentage: any;
  
  showannotatetool: boolean;
  showgeometrytool: boolean;
  showgeopadtool: boolean;
  showpropeetiestool: boolean;
  showanalysis: boolean;
  showpropertiestool: boolean;
  packagecontrolgeotray: string[];
  disable = false;
  geometrydisable = false;
  annotatedisable = false;
  geopaddisable = false;
  propertiesdisable = false;
  analysisdisable = false;
  
  temp: any;
  static calcareaperimeter=false;
  count2=-1;
  checkProperty:any;
  countVariable: any;
  previewCount: boolean;
  showlinedropdown=false;
  annotatePolygons: Array<AnnotationPolygon> = [];
  wingAtb= false;
  wingStorageArray= [];
  event_element:any;
  // packageEventsFortrayMenuSubject: Subscription;
  // @Input() packageEventFortrayMenu; // Observable<any>;
  packageItems;
  
  
  constructor(private atService: AnnotationToolService,
    private geotrayService: GeotrayService, private authObsr: AuthObservableService,
    private commonService: CommonService, private predefined1: GeotrayService, private basemapService: BasemapService,
    private myService: MyService,
    private renderer2: RendererFactory2) {
      this.renderer = this.renderer2.createRenderer(null, null);
      this.basemap = this.basemapService.getCurrentBasemap();
     }
   
    predef : any;
  // public fileUtilCallback: (returnData: any) => any;
  // kmlParsingProcess: KMLGroundOverlayParsing;
  // baseService: BasemapService;
  // zipWriter: any;
  // validationUploadedFile(inputFiles: any, options: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // validationAwsUrl(options: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // checkOtherFormatsForAws(inputFiles: any, options: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // _createZipFile(inputFiles: any, callback: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // getZipFileMethod1(files: any, callback: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // addNextFileToZip(currentCount: any, files: any, onSuccess: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // getZipFileMethod2(inputFiles: any, finalCallback: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // saveZipBlob(blob: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // pFileReaderAsText(file: any): Promise<unknown> {
  //   throw new Error('Method not implemented.');
  // }
  // pFileReaderAsArrayBuffer(file: any): Promise<unknown> {
  //   throw new Error('Method not implemented.');
  // }
  // pBufferReaderAsText(buffer: any): Promise<unknown> {
  //   throw new Error('Method not implemented.');
  // }
  // public _processXLSXFilesList(inputFile: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // public _processCSVFilesList(inputFile: any): void {
  //   throw new Error('Method not implemented.');
  // }
  // getFile(files: any, fileType: string): unknown {
  //   throw new Error('Method not implemented.');
  // }
 

  ngOnInit(): void {
    
    // this.parentFunction.emit("sushma")
    // $(document).ready(function(){
    //   $('.close').click(function(){
    //     // $('ul').toggleClass('active');
    //     $('.circle-menu-card-container').toggleClass('active');
    //   })
    this.packagecontrolgeotray = ["GeotrayGeometrytool", "GeotrayAnnotatetool", "GeotrayGeopadtool", "GeotrayPropertiestool", "GeotrayAnalysis",]

    // })
    console.log(this.wings,"check wings data")
    // this.packageEventsFortrayMenuSubject = this.packageEventFortrayMenu.subscribe((data) => {
    //   console.log('************ on geotraymenu emit inside emit package data is ', data, data.package_features_control.default.package.features)
    //   const packageItems = JSON.parse(localStorage.getItem('packageItems'));
    //   // const packageItems = data.package_features_control.default.package.features;
    //   console.log(packageItems)
    //   packageItems.forEach((key, value) => {
    //     if (key.name == "GeotrayGeometrytool" && !this.showgeometrytool) {
    //       this.showgeometrytool = key.isAccess;
    //     }
    //     if (key.name == "GeotrayAnnotatetool" && !this.annotatedisable) {
    //       this.annotatedisable = key.isAccess;
    //     }
    //     if (key.name == "GeotrayGeopadtool" && !this.showgeopadtool) {
    //       this.showgeopadtool = key.isAccess;
    //     }
    //     if (key.name == "GeotrayPropertiestool" && !this.propertiesdisable) {
    //       this.propertiesdisable = key.isAccess;
    //     }
    //     if (key.name == "GeotrayAnalysis" && !this.analysisdisable) {
    //       this.analysisdisable = key.isAccess;
    //     }
    //   })
    // });
    setTimeout(() => {
      for (let i = 0; i < packagefeatures["default"]["package"]["features"].length; i++) {
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeotrayGeometrytool") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeometrytool = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
          if (this.showgeometrytool == false) {
            this.geometrydisable = true
          }

        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeotrayAnnotatetool") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showannotatetool = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
          if (this.showannotatetool == false) {
            this.annotatedisable = true
          }
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeotrayGeopadtool") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showgeopadtool = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
          if (this.showgeopadtool == false) {
            this.geopaddisable = true
          }
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeotrayPropertiestool") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showpropertiestool = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
          if (this.showpropertiestool == false) {
            this.propertiesdisable = true
          }
        }
        if (packagefeatures["default"]["package"]["features"][i]["name"] == "GeotrayAnalysis") {
          console.log(packagefeatures["default"]["package"]["features"][i]["isAccess"],)
          this.showanalysis = (packagefeatures["default"]["package"]["features"][i]["isAccess"])
          if (this.showanalysis == false) {
            this.analysisdisable = true
          }
        }

      }
    }, 500);
  }
  ngAfterViewInit() {
   
    setTimeout(() => {
      this.setActive = true;
    }, 300);

    console.log(this.wings, "check wings on loaded")
    this.wings.map((x)=>{
      
      switch (x.className) {
        case 'main-imgVTB':
          $(`.main-img${x.title}`).css({"margin-bottom":"-0.5rem"})
          $(`.dropdown-img${x.title}`).css({"display":"block","width":"10px","height":"10px","margin-bottom":"1.6rem","margin-left":"0.9rem"})
          break;
        case 'main-imgATB':
          $(`.dropdown-img${x.title}`).css({"display":"none"})
          break;
        case 'main-imgGTB':
          $(`.main-img${x.title}`).css({"margin-bottom":"-0.6rem","margin-left":"-0.4rem","width": "42px"})
          $(`.dropdown-img${x.title}`).css({"display":"block","width":"10px","height":"10px","margin-bottom":"-1rem","margin-left":"1.2rem"})
         

          break;
        case 'main-imgQTB':
          $(`.dropdown-img${x.title}`).css({"display":"none"})
          break;
        case 'main-imgGPTB':
          $(`.dropdown-img${x.title}`).css({"display":"none"})
          break;        
      
        default:
          break;
      }
      console.log(x.title, "check wings for condirmation")
      // if(x.title === 'VTB'){
      //   console.log("in the VTB")
      
      // }
      // if(x.title === 'ATB'){

      // }
      // if((x.title === 'GTB') || (x.title === 'QTB') || (x.title === 'GPTB')){
      //   $('.main-img').css({"margin-bottom":""})
      // }
    })
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.predefined1.setPopup(this.predef)
    console.log(this.predefined1,"qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
    console.log(changes);
    if (this.commonService.isValid(changes.resetAllWings)){
      if (!changes.resetAllWings.firstChange) {
        this.resetWingSelection();
        this.showSubOptions = false;
        this.showProperties = false;
        this.showGeometryProperties=false;
        this.showbuffer = false;
        this.showvicinity = false;
        this.showslope = false;
        this.showslopeAEDS = false;
        this.selectedSubOption = '';
        this.selectedProperty = '';
      }
    }
    if (this.commonService.isValid(changes.isGuest)){
      if (!changes.isGuest.firstChange) {
        if (!this.isGuest) {
          this.runAllWaitingTasks();
        } else {
          this.afterLoginOperations = [];
        }
      }
    }
    // const packageItems = JSON.parse(localStorage.getItem('packageItems'));
    //   // const packageItems = data.package_features_control.default.package.features;
    //   console.log(packageItems)
    //   packageItems.forEach((key, value) => {
    //     if (key.name == "GeotrayGeometrytool" && !this.showgeometrytool) {
    //       this.showgeometrytool = key.isAccess;
    //     }
    //     if (key.name == "GeotrayAnnotatetool" && !this.annotatedisable) {
    //       this.annotatedisable = key.isAccess;
    //     }
    //     if (key.name == "GeotrayGeopadtool" && !this.showgeopadtool) {
    //       this.showgeopadtool = key.isAccess;
    //     }
    //     if (key.name == "GeotrayPropertiestool" && !this.propertiesdisable) {
    //       this.propertiesdisable = key.isAccess;
    //     }
    //     if (key.name == "GeotrayAnalysis" && !this.analysisdisable) {
    //       this.analysisdisable = key.isAccess;
    //     }
    //   })
    if(localStorage.getItem('email') !== null) {
      if(this.packageItems === null || this.packageItems === undefined) {
        this.packageItems = this.commonService.packageStringToJsonArray();
        this.activatePackageUIElements();    
      }
    }
  }
  activatePackageUIElements(): any {
    if(this.packageItems !== null) {
      if(!this.showgeometrytool || !this.annotatedisable || !this.showgeopadtool
        || !this.propertiesdisable || !this.analysisdisable) {
          this.packageItems.forEach((key, value) => {
            if (key.name == "GeotrayGeometrytool" && !this.showgeometrytool) {
              this.showgeometrytool = key.isAccess;
            }
            if (key.name == "GeotrayAnnotatetool" && !this.annotatedisable) {
              this.annotatedisable = key.isAccess;
            }
            if (key.name == "GeotrayGeopadtool" && !this.showgeopadtool) {
              this.showgeopadtool = key.isAccess;
            }
            if (key.name == "GeotrayPropertiestool" && !this.propertiesdisable) {
              this.propertiesdisable = key.isAccess;
            }
            if (key.name == "GeotrayAnalysis" && !this.analysisdisable) {
              this.analysisdisable = key.isAccess;
            }
          })
      }    
    }
  }
  resetWingSelection() {
    this.wings.forEach(element => {
      element.selected = false;
      this.setHoveredOutIcon(element);
    });
  }
  mouseOver(e, wing) {
   
    this.currentTooltipContent = wing.tooltip;
    if (!wing.selected) {
      this.onWingHovered.emit(wing);
    }
  }
  mouseOut(e, wing) {
   
    if (!wing.selected) {
      this.onWingHoveredOut.emit(wing);
    }
  }

  runAllWaitingTasks(){
    this.afterLoginOperations.forEach(operation => {
      if ( operation.type === 'showSaveShare') {
        console.log('CALLING SHOW SAVE SHARE SCREEN AFTER LOGIN');
        this.openSaveShareScreen(operation.data);
        const index = this.afterLoginOperations.findIndex(op => op.type === 'showSaveShare');
        if (index !== -1) {
          this.afterLoginOperations.splice(index, 1);
        }
      }
    });
  }

  openSaveShareScreen(data){
    this.showGeoSessionWindow.emit(data);
    this.closeMenu('');
    setTimeout(() => {
      this.resetWingSelection();
    }, 1000);
    this.geotrayService.dectivateTools();
  }
  selectedWing(e: PointerEvent, wing) {
    this.deleteBufferToolCacheFromMap();
    console.log(e,wing,"selected wing data ......");
    console.log(e.ctrlKey);
    this.wingStorageArray.push(wing.title)
    console.log(this.wingStorageArray, 'wingstoredarray')
    var atbThere = false
    if (wing.title === 'GPTB') {
      this.showGeometryProperties = false;
      if (this.showgeopadtool == true) {
      if (e.ctrlKey && !(wing.title == 'GTB')){
        this.showSubOptions = true;
        this.resetWingSelection();
        this.geotrayService.dectivateTools();
        this.showslope = false;
        this.showslopeAEDS = false;
        this.showbuffer = false;
        this.showvicinity = false;
      } else {
        this.showGeopad(e);
        this.resetWingSelection();
      }
    }
      else {
        alert("Susbcribe for higher package to use this feature")
      }
    } else if(wing.title === 'QTB'){
      if (this.showpropertiestool == true){
        this.showProperties = false;
        this.showbuffer = false;
        this.showvicinity = false;
        this.showslope = false;
        this.showslopeAEDS = false;
        this.showGeometryProperties = false;
      }
      else {
        alert("Susbcribe for higher package to use this feature")
        return;
      }
    } else if (wing.title === 'VTB') {
      if(e.ctrlKey) {
        this.showbuffer = false;
        this.showvicinity = false;
      } else {
        this.showbuffer = !this.showbuffer;
        this.showvicinity = !this.showvicinity;
      }      
      this.slopefinderVisibility = false;
      this.slopefinderVisibilityAEDS = false;
      this.onslopeClicked.emit(false);
      this.onAEDSClicked.emit(false);
      this.showslope = false;
      this.showslopeAEDS = false;
      this.showProperties = false;
    }
    
    else if (wing.title === 'STB') {
      this.showGeometryProperties = false;
      const data = {e, data: 'geosession'};
      if (!this.isGuest) {
        this.openSaveShareScreen(data);
      } else {
        // SAVING OPERATION TO PERFORM AFTER LOGIN
        const index = this.afterLoginOperations.findIndex(op => op.type === 'showSaveShare');
        if (index === -1) {
          // IF NO TOWER LAYER SAVE REQUEST PRESENT
          this.afterLoginOperations.push({ type: 'showSaveShare', data });
        } else {
          // IF TOWER LAYER SAVE REQUEST PRESENT, SAVING RECENT REQUEST ONLY
          this.afterLoginOperations[index] = { type: 'showSaveShare', data };
        }
        this.authObsr.initiateAuthenticationRequest({from: 'geotray-save-share'});
      }
    } else if (wing.title === 'ATB') {
      if (e.ctrlKey) {
        if (this.showannotatetool == true) {
                    this.showAnnotation(e);
            this.resetWingSelection();
            this.showGeometryProperties = false;
          }
          else {
            alert("Susbcribe for higher package to use this feature")
            this.disable = true;
            return;
          }
      } else {
        this.showslope = !this.showslope;
        this.showslopeAEDS = !this.showslopeAEDS;
        this.buffertoolVisibility = false;
        this.onbufferClicked.emit(false);
        this.showProperties = false;
        this.showbuffer = false;
        this.showvicinity = false;
      }
      // this.wingAtbCount++;
      // console.log( this.wingAtbCount, 'countinatb1')
    //   if (this.showannotatetool == true) {
    //           this.showAnnotation(e);
    //   this.resetWingSelection();
    //   this.showGeometryProperties = false;
    // }
    // else {
    //   alert("Susbcribe for higher package to use this feature")
    //   this.disable = true;
    //   return;
    // }
  } 
    else if (wing.title === 'GTB') {
      console.log(this.showgeometrytool)
      if (this.showgeometrytool == true) { }
      else {
        alert("Susbcribe for higher package to use this feature")
        return;
      }
    }
    else {
      this.showSubOptions = false;
      this.showProperties = false;
      this.showGeometryProperties = false;
      
    }
    console.log(wing.title, 'wing here')
    this.resetWingSelection();
    console.log(wing.title, 'wing here2')
    if (wing.title === 'GPTB' && !e.ctrlKey) {
      this.showGeometryProperties = false;
      this.count = 0

    } else if (wing.title === 'QTB') {
      this.showGeometryProperties = false;
      this.count = 0
    } 
    else if (wing.title === 'ATB') {
      this.showGeometryProperties = false;
      this.count = 0
      this.wingAtb = true
      atbThere = true
      console.log( this.wingAtb, 'countinatb2')
    } 
    else if (wing.title === 'GTB') {
      // console.log(AnnotationToolComponent.shareannotate,"checkpolygon")
      // AnnotationToolComponent.shareannotate.forEach(obj => {
      //   this.atService.removeLayerFromMap(obj.features.name, obj.features.id);
      // });
      
      // AnnotationToolComponent.shareannotate;
      //   this.atService.removePolygonMarkTools();
    //  console.log(this.wingStorageArray,this.showannotatetool, 'wingstoredarray2')
    //   console.log( atbThere, 'countinatb2')
      // this.basemapService.iconChanger.subscribe(icon => {
      //   console.log(icon, 'icon in gtb');
      // });
    
      // console.log(this.atService.clearAllAnnotations(), 'clearingannotate')
      // this.resetWingSelection();
              // this.atService.clearAllAnnotations();

     
      // console.log(this.geotrayService.multipleToolsActivate(), 'toolshere')
      if(e.ctrlKey){
        console.log("insidegeocontrol")
        GeotrayMenuComponent.calcareaperimeter=true
        // this.geometrycal.drawLineCalculateLength();
        const toolOptions = {
          title: this.temp.title,
          clickEvent:false,
          clickEvent1:false,
          isCtrlClicked:true,
          popupComponent: GeotrayComponent.toolOptions2.popupComponent
        };
        // this.geotrayService.activateTool(toolOptions);
        console.log(toolOptions,"ingeomeue")
        this.geotrayService.activateTool(toolOptions);
      }
      console.log(wing,e,"geometryclicked")
     this.count++;
      if(this.count % 2 === 0 ){
       this.showGeometryProperties=true;
      }else{
        this.showGeometryProperties=false;
      }
      if(!this.checkProperty){
        this.checkProperty = this.showGeometryProperties
        console.log(this.checkProperty,"after assign")
      }
      // if(this.geotrayService.multipleToolsActivate()== true){
      //   this.atService.clearAllAnnotations();
      // }
      // console.log(this.showGeometryProperties,this.count%2,"check the geopad value everytrime")

    } 
    // else if (wing.title === 'VTB') {
    //   const toolOptions = {
    //     title: this.temp.title,
    //     clickEvent:false,
    //     clickEvent1:false,
    //     isCtrlClicked:false,
    //     popupComponent: GeotrayComponent.toolOptions2.popupComponent
    //   };
    //   // this.geotrayService.activateTool(toolOptions);
    //   console.log(toolOptions,"ingeomeue")
    //   this.geotrayService.activateTool(toolOptions);
    // }

    else {
      this.count = 0
      console.log("i am here, in geometry")
      this.wings.forEach(element => {
        if (element.title === wing.title) {
          element.selected = true;
          console.log(element.title, wing.title, 'in true')
          this.setHoveredIcon(element);
          // this.onWingHovered.emit(wing);
        }      else {
          element.selected = false;
          console.log(element.title, wing.title, 'in false')
          this.setHoveredOutIcon(element);
          // this.onWingHoveredOut.emit(wing);
        }
      });
    }
    if(wing.title === 'VTB' && !e.ctrlKey) {
      this.temp = wing;
      this.event_element = e;
    } else {  
      this.temp = wing;
      this.temp.srcEvent = e;
      this.onWingSelected.emit(this.temp);
    }
    // }
  }
  showUploadPhotosVideos() {
    // this.showGeopadWindow.emit('video-image');
    // this.selectedSubOption='video-image';
  }
  showUploadAudio() {
    // this.showGeopadWindow.emit('audio');
    // this.selectedSubOption='audio';
  }
  showGeopad(event) {
    this.showGeopadWindow.emit({event, data: 'geopad'});
    this.selectedSubOption = 'geopad';
    this.closeMenu('');
  }
  showAnnotation(event): void{
    this.showGeopadWindow.emit({event, data: 'annotate'});
    this.selectedSubOption = 'annotate';
    this.closeMenu('');
  }
  showViewOtherLocation() {
    // this.showGeopadWindow.emit('view-location');
    // this.selectedSubOption='view-location';
  }
  showPredefined(){
    this.selectedProperty = 'predefined';
    this.predefinedVisibility = !this.predefinedVisibility;
    // this.propertiesWindowVisible = !this.propertiesWindowVisible;
    // this.onPropertiesClicked.emit(this.propertiesWindowVisible);
    this.classifiedVisibility = false;
    this.blendedVisibility = false;
    this.collocatedVisibility = false;
    this.extendedVisibility = false;
    this.onPredefinedClicked.emit(this.predefinedVisibility);
  }
  showClassified(){
    this.selectedProperty = 'classified';
    this.classifiedVisibility = !this.classifiedVisibility;
    this.blendedVisibility = false;
    this.collocatedVisibility = false;
    this.extendedVisibility = false;
    this.predefinedVisibility = false;
    this.propertiesWindowVisible = !this.propertiesWindowVisible;
    this.onClassifiedClicked.emit(this.predefinedVisibility);
    this.onClassifiedClicked.emit(this.classifiedVisibility);
  }
  showBlended(){
    this.selectedProperty = 'blended';
    this.blendedVisibility = !this.blendedVisibility;
    this.classifiedVisibility = false;
    this.collocatedVisibility = false;
    this.extendedVisibility = false;
    this.predefinedVisibility = false;
    this.propertiesWindowVisible = !this.propertiesWindowVisible;
    this.onBlendedClicked.emit(this.predefinedVisibility);
    this.onBlendedClicked.emit(this.blendedVisibility);
  }
  showCollocated(){
    this.selectedProperty = 'collocated';
    this.extendedVisibility = false;
    this.predefinedVisibility = false;
    this.blendedVisibility = false;
    this.classifiedVisibility = false;
    this.collocatedVisibility = !this.collocatedVisibility;
    this.propertiesWindowVisible = !this.propertiesWindowVisible;
    this.onCollocatedClicked.emit(this.predefinedVisibility);
    this.onCollocatedClicked.emit(this.collocatedVisibility);
  }
  showExtended(){
    this.selectedProperty = 'extended';
    this.predefinedVisibility = false;
    this.blendedVisibility = false;
    this.classifiedVisibility = false;
    this.collocatedVisibility = false;
    this.extendedVisibility = !this.extendedVisibility;
    this.propertiesWindowVisible = !this.propertiesWindowVisible;
    this.onExtendedClicked.emit(this.predefinedVisibility);
    this.onExtendedClicked.emit(this.extendedVisibility);
  }
  handleSlopePctValue(e) {
    this.receivedslope = e;
    console.log(this.receivedslope, 'checkreceivedslopeval');
  }

  showAEDS() {
    this.selectedProperty = 'slopefinderADES';
    this.slopefinderVisibilityAEDS = !this.slopefinderVisibilityAEDS;
    this.onAEDSClicked.emit(this.slopefinderVisibilityAEDS);
    this.slopefinderVisibility = false;
    this.showslopedropdown = false;
    this.showbufferdropdown = false;
    this.onslopeClicked.emit(this.slopefinderVisibility);
    this.myService.trigger();
    this.basemapService.setMouseIcon(this.slopefindercursor);
    console.log(
      'checkproperty',
      this.selectedProperty,
      this.slopefinderVisibilityAEDS
    );
  }
  showslopefinder() {
    this.selectedProperty = 'slopefinder';
    this.slopefinderVisibility = !this.slopefinderVisibility;
    this.onslopeClicked.emit(this.slopefinderVisibility);
    this.slopefinderVisibilityAEDS = false;
    this.onAEDSClicked.emit(this.slopefinderVisibilityAEDS);
    this.showslopedropdownAEDS = false;
    this.showbufferdropdown = false;
    this.myService.trigger();
    this.basemapService.setMouseIcon(this.slopefindercursor);
  }

  showslopedropdown: boolean = false;

  openslopedropdown() {
    this.showslopedropdown = !this.showslopedropdown;
    this.showslopedropdownAEDS = false;
  }
  showslopedropdownAEDS: boolean = false;

  openslopedropdownAEDS() {
    this.showslopedropdownAEDS = !this.showslopedropdownAEDS;
    this.showslopedropdown = false;
  }
  showbuffertool() {  
    console.log(this.basemapService.getCurrentBasemap().getLayers())  
    this.basemapService.getCurrentBasemap().getLayers().forEach((layer) => {
      if(layer.values_.name === "vicinity") {
        this.basemapService.getCurrentBasemap().removeLayer(layer);
      }
    });
    this.basemapService.getCurrentBasemap().getInteractions().forEach((interaction) => {
      console.log(interaction)
      if (interaction instanceof Draw || interaction instanceof Modify || interaction instanceof Snap) {
        this.basemapService.getCurrentBasemap().removeInteraction(interaction);
      }
    });
    this.selectedProperty = 'buffertool';
    this.buffertoolVisibility = !this.buffertoolVisibility;
    this.showslopedropdown = false;
    this.showslopedropdownAEDS = false;
    this.onbufferClicked.emit(this.buffertoolVisibility);
    this.basemapService.setMouseIcon(this.buffertoolcursor);
    console.log('checkhit', this.onbufferClicked);
  }

  showbufferdropdown: boolean = false;

  openbufferdropdown() {
    this.showbufferdropdown = !this.showbufferdropdown;
  }

  deleteBufferToolCacheFromMap():any {
    const layersToRemove = [];
    console.log(this.basemapService.getCurrentBasemap().getLayers())
    this.basemapService.getCurrentBasemap().getLayers().forEach((layer) => {
      if(layer.values_.name === "bufferLayer") {
        layersToRemove.push(layer);
        // this.basemapService.getCurrentBasemap().removeLayer(layer);
      }
    });
    const len = layersToRemove.length;
    if(len > 0) {
      for(var i = 0; i < len; i++) {
        this.basemapService.getCurrentBasemap().removeLayer(layersToRemove[i]);
      }
    }
  }

  showvicinitytool(wing) {
    this.vicinitytoolVisibility = !this.vicinitytoolVisibility;
    this.basemapService.getCurrentBasemap().getInteractions().forEach((interaction) => {
      console.log(interaction)
      if (interaction instanceof Draw || interaction instanceof Modify || interaction instanceof Snap) {
        this.basemapService.getCurrentBasemap().removeInteraction(interaction);
      }
    });
    this.deleteBufferToolCacheFromMap();
    // wing.title === 'vicinity'
    //     clickEvent: 'getFeatureInfoByCircleRadius',
    // ctrlClickEvent: 'getFeatureInfoByCircle',
    // const toolOptions = {
    //   title: 'VTB',
    //   clickEvent:true,
    //   isCtrlClicked:false,
    //   popupComponent: GeotrayComponent.toolOptions2.popupComponent
    // };
    // // this.geotrayService.activateTool(toolOptions);
    // console.log(toolOptions, " clicked on vicinity tool ")
    // this.geotrayService.activateTool(toolOptions);
    // const vicinityInstance = new VicinityTool(this.basemap, this.basemapService);
    // const options = '';
    // vicinityInstance.getFeatureInfoByCircle(options);
    console.log(' --------------------------------- ', wing, this.temp, this.event_element)
    // this.temp = wing;
    this.temp.srcEvent = this.event_element;
    console.log(' ********************************* ', wing, this.temp, this.event_element)
    this.onWingSelected.emit(this.temp);
    this.buffertoolVisibility = !this.buffertoolVisibility;
  }
  closeMenu(event) {
    this.onMenuBtnClicked.emit(false);
  }
  public setHoveredIcon(wing) {
    console.log(wing,"check")
    wing.icon.dropname = 'assets/svgs/geo-tray/caret-down-white.svg'
    wing.color = '#667BBC';
    wing.icon.name = 'assets/right-white-svg/'
      + wing.title + '.svg';

    // console.log('onWingHovered', selectedToolWing);
  }

  public setHoveredOutIcon(wing) {
    wing.icon.dropname = 'assets/svgs/geo-tray/caret-down.svg'
    wing.color = '#FFFFFF';
    wing.icon.name = 'assets/right-colored-svg/'
      + wing.title + '.svg';

    // console.log('onWingHoveredOUt', selectedToolWing);
  }

  dropClicked(wing){
    
    console.log("clicked the drop",wing)
    if(wing.title === 'VTB'){
      this.showGeometryProperties = false;
      this.count++;
      this.showAnalyDropdown.emit('true')
      console.log(this.count, 'count')
      if(this.count > 1 ){
       console.log('greater than 1')
       this.showAnalyDropdown.emit('false')
       this.count = 0
      }
    }


    if(wing === 'GTB'){
      console.log('in line drop')
this.showlinedropdown=!this.showlinedropdown
      // this.showGeometryProperties =! this.showGeometryProperties
      // console.log(this.checkProperty,this.showGeometryProperties,"check geovghdvghdsvgh")
      if(this.showlinedropdown){
        this.showGeoDropdown.emit('true')
      }else{
        this.showGeoDropdown.emit('false')
      }

      // console.log("geometryclicked2")
      // console.log("gtb clicked")
      // this.count2++;
      // console.log(this.count2,this.count2%2,"check clicks")
      // if(this.count2%2==0  ){
      //   this.showGeometryProperties = true
      // this.showGeoDropdown.emit('true')
      
      // }
      // else
      // {
      //   this.showGeometryProperties = true
      //  console.log('greater than 1')
      //  this.showGeoDropdown.emit('false')
       
      
      // }
      
    }

   

  }
  distancecal(temp){
    GeotrayMenuComponent.calcareaperimeter=true
    
    // this.geometrycal.drawLineCalculateLength();
    const toolOptions = {
      title: this.temp.title,
      clickEvent:true,
      clickEvent1:false,
      isCtrlClicked:false,
      popupComponent: GeotrayComponent.toolOptions2.popupComponent
    };
    // this.geotrayService.activateTool(toolOptions);
    console.log(toolOptions,"ingeomeue")
    this.geotrayService.activateTool(toolOptions);
  }
  areacal(temp){
    GeotrayMenuComponent.calcareaperimeter=true
    // this.geometrycal.drawLineCalculateLength();
    console.log(temp.ctrlKey,'ctrlclickedcheck')
    if(temp.ctrlKey){
      var toolOptions = {
        title: this.temp.title,
        clickEvent:false,
        clickEvent1:false,
        isCtrlClicked:true,
        popupComponent: GeotrayComponent.toolOptions2.popupComponent
      };
      
    }
    else{
    var toolOptions = {
      title: this.temp.title,
      clickEvent:false,
      clickEvent1:true,
      isCtrlClicked:false,
      popupComponent: GeotrayComponent.toolOptions2.popupComponent
    };
  }
    // this.geotrayService.activateTool(toolOptions);
    console.log(toolOptions,"ingeomeue")
    this.geotrayService.activateTool(toolOptions);
  }

  onChangeLayerSelection(selectedType, eventValue): any {
    console.log('event triggered in geotray menu from SlopAED ', selectedType, eventValue);
    this.commonService.triggerFarmingLayerMenu(selectedType);
  }

}