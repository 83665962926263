import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BasemapService } from 'src/app/basemap/basemap.service';
import { CommonService, fexJSON } from 'src/app/Services/common.service';

@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.scss']
})
export class LegendsComponent implements OnInit {

  @Input() layersList: any;
  color: any;
  downloadUrl;
  filename = "";
  @ViewChild("circleEle") circleEle: ElementRef;
  layerviewname: any;

  constructor(private commonService: CommonService, private sanitizer: DomSanitizer, private basemapService: BasemapService) { }

  ngOnInit(): void {
    console.log("Layers List in Legends", this.layersList)
  }

  ngOnChanges(): void {
    console.log("Layers List in Legends on changes", this.layersList)
    this.layersList.forEach(layer => {
      console.log('layer-data ', layer)
      if(layer.isServer) {
        if(layer.type === 'jpg') {
          this.color = 'red'
        } else {
          this.color = 'green'
        }
      } else {
        if(layer.fileType === 'jpg') {
          this.color = 'red'
        } else {
          this.color = 'green'
        }
      }
    });
  }

  generateFEX(layer): any {
    console.log('generating & download FEX file ', layer);
    console.log('json is ', fexJSON)
    const fexJSON_new = layer.fexObj;
    // Connection setting
    // const connJosn = this._setConnectionPropertyJson(layer);
    // fexJSON_new.geobar.Connection.Context.Geoframe = 'OpenLayers';
    // fexJSON_new.geobar.Connection.Context['Bounding Box'] = connJosn.BoundingBox;
    // fexJSON_new.geobar.Connection.Reference['Coordinate System'] = connJosn.CoordinateSystem;
    // fexJSON_new.geobar.Connection.Reference.Datum = connJosn.Datum;
    // fexJSON_new.geobar.Connection.Reference.Projection = connJosn.Projection;
    // // Specification setting
    // const specJson = this._setSpecificationPropertyJson(layer);
    // fexJSON_new.geobar.Specification['Data Type'] = specJson.Storage;
    // fexJSON_new.geobar.Specification.File = specJson['File Name'];
    // fexJSON_new.geobar.Specification.Geometry = specJson.Storage;

    // // Depection
    // const depJson = this._setDepictionPropertyJson();
    fexJSON_new.fex.Depiction.Layer.Label = this.layerviewname;

    console.log('json is after setting', fexJSON_new, fexJSON, layer.fexObj);
    const c = JSON.stringify(fexJSON_new);
    const file = new Blob([c], {type: 'text/json'});
    this.download(file,layer.name+".json");

  }
  download(blob, filename) {   
    var a = document.createElement("a"),
                url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0);          
  }
  // _setConnectionPropertyJson(layerObj): any {
  //   let projection = '-';
  //   let datum = '-';
  //   let prjCoodSys = '-';
  //   if (layerObj.proj.split('PROJCS')[1] === undefined) {
  //     datum = ((layerObj.proj.split('PROJCS')[0]).split('GEOGCS')[1]).split('DATUM')[1].split('"')[1];
  //   } else {
  //     projection = (((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1]).split('PROJECTION')[1].split('"')[1];
  //     datum = ((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1].split('"')[1];
  //     prjCoodSys = layerObj.proj.split('PROJCS')[1].split('"')[1];
  //   }
  //   return {
  //     ReferenceType: '',
  //     Datum: datum,
  //     Projection: projection,
  //     CoordinateSystem: prjCoodSys,
  //     Transformation: '-',
  //     GeoFrame: 'OpenLayer',
  //     BoundingBox: this.basemapService.getCurrentBasemap().getView().calculateExtent(this.basemapService.getCurrentBasemap().getSize())
  //   };
  // }

  // _setSpecificationPropertyJson(layerObj): any {
  //   let storage = '';
  //   if (this.commonService.isValid(layerObj.metadata)) {
  //     if (this.commonService.isValid(layerObj.metadata[0])) {
  //       storage = layerObj.metadata[0].features[0].geometry.type;
  //     }
  //   }
  //   return {
  //     Represntation: 'Vector',
  //     Source: 'User',
  //     'File Name': layerObj.name,
  //     'File Location': '',
  //     Storage: storage,
  //     Specifics: ''
  //   };
  // }
  // _setDepictionPropertyJson(): any {
  //   return {
  //     Symbols: '',
  //     Display: '',
  //     Label: this.layerviewname
  //   };
  // }

  // _setFunctionPropertyJson(): any {
  //   return {
  //     'Attributes/Bands': '',
  //     DataFilter: '-'
  //   };
  // }

  // _setInteractionPropertyJson(layerObj): any {
  //   return {
  //     'Date/Time Added': layerObj.timestamp,
  //     'User Initials': '',
  //     Gpad: ''
  //   };
  // }

}
