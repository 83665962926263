import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CommonService, ElementType } from 'src/app/Services/common.service';
import { SocialAuthService } from 'src/app/Services/socialAuthService';
import { CustomSpinner } from 'src/app/Services/SpinnerService';
import { FeAdminDashboardService } from '../../Services/feAdminDashborad.service';
declare var $: any;
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { __values } from 'tslib';
export interface CardData {
  imageId: string;
  state: "default" | "flipped" | "matched";
}

export class WizardPage {
  index: number;
  id: string;
  name: string;
  active: boolean;
  disabled: boolean;
  valid: boolean;
  first: boolean;
  last: boolean;
  form: FormGroup;
}
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class OrganizationComponent implements OnInit {
  flipStarter: string = 'inactive';
  packages: string[];
  cardno: any;
  detailno: any;
  finalpackageselected: any;
  finalpackagename: any;
  orgname: any;
  orgaddress: any;
  toggleFlipStarter(e) 
  {
    this.detailno=e
    console.log(this.detailno,"i am detail")
    this.packages[e]["Flipstate"] = (this.packages[e]["Flipstate"] == 'inactive')? 'active' : 'inactive';
    console.log("fliped");
  }
  @Output() closeLogin: EventEmitter<any> = new EventEmitter<any>();
  @Output() showPage: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('subscborder', { static: true }) subscborder: ElementRef<HTMLDivElement>;
  orgDetailsForm = new FormGroup({
    orgName: new FormControl('', [Validators.required, Validators.minLength(4)]), // Validators.pattern("[A-Za-z0-9]")]),
    orgAddress: new FormControl('', [Validators.required, Validators.minLength(4)]), // Validators.pattern("[A-Za-z0-9]")]),
    orgType: new FormControl('', [Validators.required, Validators.minLength(4)]),
    city: new FormControl('', [Validators.required, Validators.minLength(2)]),
    country: new FormControl('', [Validators.required, Validators.minLength(2)]),
    postalCode: new FormControl('', [Validators.required, Validators.minLength(5)])
  });

  // var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  adminDetailsForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(1)]), // Validators.pattern("[A-Za-z0-9]")]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(1)]), // Validators.pattern("[A-Za-z0-9]")]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
  });

  emailVerifyForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.minLength(4)])
  });
  menus: Array<WizardPage> = [];
  activeWizard: WizardPage;

  orgSearchObserver: Subject<any> = new Subject<any>();
  orgStatus = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    INPROGRESS: 'INPROGRESS',
    UNKNOWN: ''
  };
  validOrg = this.orgStatus.UNKNOWN;
  orgSearchCompleted: boolean;
  errMsg = '';
  operationStatus = '';
  countryList: Array<string> = this.commonService.getCountryList();
  orgTypesList: Array<any> = [
    { name: 'Default', value: 'DEFAULT' },
    // { name: 'Type1', value: 'TYPE1'},
    // { name: 'Type2', value: 'TYPE2'},
    // { name: 'Type3', value: 'TYPE3'}
  ];
  showmenue: string[];
  months: string[];
  selectedexpplanprice: any;
  subscplanForm: FormGroup;
  paymentdetails: FormGroup;
  showstartertick = false;
  selectedmonth: any;
  selectedengagemonth: any;
  selectedexploremonth: any;
  package: { Free: { index: number; month: string; amount: string; }[]; Explore: { index: number; month: string; amount: string; }[]; Engage: { index: number; month: string; amount: string; }[]; }[];
  packagekeys: string[];
  finalselectedprice: any;
  finalselectedmonth: string;
  tax = 20;
  totalprice: any;
  finalselectedprice2: any;
  totalAmount = 0;
  isTotalPrice = true;


  constructor(private commonService: CommonService, private socialAuth: SocialAuthService,
    private spinner: CustomSpinner, private feAdminDashboardService: FeAdminDashboardService) {

    /* this.package = [{
      "Free": [
        { index: 1, month: '1 month', amount: '$ 200' },
        { index: 2, month: '3 months', amount: '$ 600' },
        { index: 3, month: '6 months', amount: '$ 1200' },
        { index: 4, month: '9 months', amount: '$ 1800' },
        { index: 5, month: '12 months', amount: '$ 2400' }
      ],
      "Explore": [
        { index: 1, month: '1 month', amount: '$ 200' },
        { index: 2, month: '3 months', amount: '$ 600' },
        { index: 3, month: '6 months', amount: '$ 1200' },
        { index: 4, month: '9 months', amount: '$ 1800' },
        { index: 5, month: '12 months', amount: '$ 2400' }
      ],
      "Engage": [
        { index: 1, month: '1 month', amount: '$ 500' },
        { index: 2, month: '3 months', amount: '$ 1500' },
        { index: 3, month: '6 months', amount: '$ 3000' },
        { index: 4, month: '9 months', amount: '$ 4500' },
        { index: 5, month: '12 months', amount: '$ 6000' }
      ]
    }] */

    // this.packagekeys = Object.keys(this.package[0]);
    this.months = ['1 month', '3 months', '6 months', '9 months', '12 months']

    // this.exploreplan=[
    //   {index: 1, month: '1 month' , amount: '$ 200' },
    //   {index: 2, month: '3 months' , amount: '$ 600' },
    //   {index: 3, month: '6 months' , amount: '$ 1200' },
    //   {index: 4, month: '9 months' , amount: '$ 1800' },
    //   {index: 5, month: '12 months' , amount: '$ 2400' }
    // ]; 
    // this.engageplan=[
    //   {index: 1, month: '1 month' , amount: '$ 500' },
    //   {index: 2, month: '3 months' , amount: '$ 1500' },
    //   {index: 3, month: '6 months' , amount: '$ 3000' },
    //   {index: 4, month: '9 months' , amount: '$ 4500' },
    //   {index: 5, month: '12 months' , amount: '$ 6000' }
    // ]; 

    this.menus = [
      {
        index: 1, id: 'ORG_DETAILS', name: 'Organization Details', form: this.orgDetailsForm,
        disabled: false, active: true, valid: false, first: true, last: false
      },
      {
        index: 2, id: 'ADMIN_DETAILS', name: 'Administrator Details', form: this.adminDetailsForm,
        disabled: false, active: false, valid: false, first: false, last: false
      },
      

      {
        index: 3, id: 'SUBSC_PLAN', name: 'Subscription Plan Details', form: this.subscplanForm,
        disabled: false, active: false, valid: false, first: false, last: true
      },
      {
        index: 4, id: 'PAYMENT_DETAILS', name: 'Payment Details', form: this.paymentdetails,
        disabled: false, active: false, valid: false, first: false, last: true
      },
      {
        index: 5, id: 'EMAIL_VER', name: 'Email Verification', form: this.emailVerifyForm,
        disabled: false, active: false, valid: false, first: false, last: true
      },
      // { index: 4, id: 'SUBS_PLAN', name: 'Subscrition Plan Details', active: false, valid: false, first: false, last: false },
      // { index: 5, id: 'PAYMENT_DETAILS', name: 'Payment Details', active: false, valid: false, first: false, last: true }
    ];
    this.showmenue = ['ORG_DETAILS', 'ADMIN_DETAILS', 'SUBSC_PLAN', 'PAYMENT_DETAILS', 'EMAIL_VER'];
    this.activeWizard = this.menus[0];
    this.orgDetailsForm.get('orgType').setValue('DEFAULT');
    this.orgDetailsForm.valueChanges.subscribe(res => {
      // console.log(this.orgDetailsForm.valid);
      if (!this.orgDetailsForm.valid) {
        this.errMsg = '';
      }
    });
    this.orgDetailsForm.get('orgName').valueChanges.subscribe(res => {
      this.validOrg = this.orgStatus.UNKNOWN;
    });
    this.adminDetailsForm.valueChanges.subscribe(res => {
      // console.log(this.adminDetailsForm.valid);
      if (!this.adminDetailsForm.valid) {
        this.errMsg = '';
      }
    });
    this.orgDetailsForm.get('orgAddress').valueChanges.subscribe(res => {
      this.orgaddress=res
      console.log(res,"i am the address")
    });;
   // console.log(this.orgaddress,"i am the address")
  }

  ngOnInit(): void {
    /* console.log(Object.keys(this.package[0]))
    this.selectedexpplanprice = this.package[0].Explore[0]["amount"];
    this.selectedengageprice = this.package[0].Engage[0]["amount"];
    this.finalselectedprice = 0;
    this.finalselectedmonth = "none"; */
    this.finalselectedmonth="1 month"
    this.getPackageDetails();
    console.log()
  }
  // get the Package details
  getPackageDetails(): any {
    this.feAdminDashboardService.getAllPackagesWithFeaturesList().subscribe(response => {
      console.log('response data from all packages with features', response);
      this.packages = response;
      this.packages[0]["amount"] = 45;
      this.packages[1]["amount"] = 95;
      this.packages[2]["amount"] = 395;
      this.packages[0]["user"] = "Unlimited free Users";
      this.packages[1]["user"] = "5 free Users";
      this.packages[2]["user"] = "10 free Users";
      this.packages[0]["usersbt"] = ".";
      this.packages[1]["usersbt"] = "($10 per additional User)";
      this.packages[2]["usersbt"] = "($25 per additional User)";
      console.log(this.packages[0]["amount"],"i am package")
      for(let i=0;i<this.packages.length;i++){
        this.packages[i]["Totalamount"]=this.packages[i]["amount"];
        this.packages[i]["Flipstate"]='inactive';
        this.packages[i]["Month selected"]=this.months[0];
      }
      console.log(this.packages[0],"i am package")
    });
  }

  setAdminPage(): void {
    const index = this.menus.findIndex(val => val.id === this.activeWizard.id);
    if (index !== -1) {
      this.activeWizard.active = false;
      this.menus[index] = this.activeWizard;
    }

    const index1 = this.menus.findIndex(val => val.id === 'ADMIN_DETAILS');
    if (index1 !== -1) {
      // this.menus[index1] = this.activeWizard;
      this.activeWizard = this.menus[index1];
      this.activeWizard.active = true;
    }
  }
  showNextPage(): void {
    const index = this.menus.findIndex(val => val.id === this.activeWizard.id);
    if (index !== -1) {
      this.activeWizard.active = false;
      this.menus[index] = this.activeWizard;
      this.activeWizard = this.menus[index + 1];
      this.activeWizard.active = true;
    }
    if (this.activeWizard.id === 'EMAIL_VER') {
      
      console.log(this.orgDetailsForm.value);
      console.log(this.adminDetailsForm.value);
      console.log(this.finalpackageselected);
      console.log('EMAIL VER PAGE...');
      this.createOrganizationWithAdmin();
    }
  }
  showPreviousPage(): void {
    const index = this.menus.findIndex(val => val.id === this.activeWizard.id);
    if (index !== -1) {
      this.activeWizard.active = false;
      this.menus[index] = this.activeWizard;
      this.activeWizard = this.menus[index - 1];
      this.activeWizard.active = true;
    }
  }

  // monitorChangesOrganizationName(): void{
  //   this.orgSearchObserver.asObservable().pipe(
  //     debounceTime(1000),
  //     distinctUntilChanged(),
  //     switchMap((change: any) => {
  //       console.log(change);
  //       const orgNameMatchKey = change.str;
  //       console.log(orgNameMatchKey);
  //       if (this.commonService.isValid(orgNameMatchKey)) {
  //         this.orgSearchCompleted = false;
  //         this.validOrg = this.orgStatus.INPROGRESS;
  //         return this.socialAuth.getOrganizationsWithSearchStr(orgNameMatchKey);
  //       } else {
  //         return [];
  //       }
  //     })
  //   ).subscribe((data: any) => {
  //       console.log('GOT ORGANIZATIONS');
  //       console.log(data);
  //       this.orgSearchCompleted = true;
  //       if (data.length > 0){
  //         this.validOrg = this.orgStatus.INVALID;
  //       } else {
  //         this.validOrg = this.orgStatus.VALID;
  //         if (this.activeWizard.form.valid) {
  //           this.activeWizard.valid = true;
  //         } else{
  //           this.activeWizard.valid = false;
  //         }
  //       }
  //       // this.orgSearchObserver.next('');
  //   }, error => {
  //     console.log('ERROR WHILE GETTING ORGANIZATIONS');
  //     console.log(error);
  //     // this.orgSearchObserver.next('');
  //     this.orgSearchCompleted = true;
  //     this.validOrg = this.orgStatus.INVALID;
  //   });
  // }

  getOrganizationsWithStr(orgNameMatchKey): void {
    if (this.commonService.isValid(orgNameMatchKey) && orgNameMatchKey.length > 2) {
      this.orgname=orgNameMatchKey;
      this.orgSearchCompleted = false;
      this.validOrg = this.orgStatus.INPROGRESS;
      this.errMsg = '';
      this.socialAuth.getOrganizationsWithName(orgNameMatchKey)// getOrganizationsWithSearchStr(orgNameMatchKey)
        .subscribe((data: any) => {
          console.log('GOT ORGANIZATIONS');
          console.log(data);
          this.orgSearchCompleted = true;
          if (this.commonService.isValid(data)) {
            this.validOrg = this.orgStatus.INVALID;
          } else {
            this.validOrg = this.orgStatus.VALID;
            if (this.activeWizard.form.valid) {
              this.activeWizard.valid = true;
            } else {
              this.activeWizard.valid = false;
            }
          }
          this.errMsg = '';
          // if (data.length > 0){
          //   this.validOrg = this.orgStatus.INVALID;
          // } else {
          //   this.validOrg = this.orgStatus.VALID;
          //   if (this.activeWizard.form.valid) {
          //     this.activeWizard.valid = true;
          //   } else{
          //     this.activeWizard.valid = false;
          //   }
          // }
        }, error => {
          console.log('ERROR WHILE GETTING ORGANIZATIONS');
          console.log(error);
          // this.orgSearchObserver.next('');
          this.orgSearchCompleted = true;
          this.validOrg = this.orgStatus.UNKNOWN; // INVALID;
          if (error.status === 0) {
            this.errMsg = 'Server down, Please try again after some time.';
          }
        });
    }
  }

  onSubmit(form: FormGroup): void {
    // TODO: Use EventEmitter with form value
    
    this.errMsg = '';
    if (form.valid) {

      if (this.activeWizard.id === 'ORG_DETAILS') {
        console.log('ORG DETAILS WIZARD...');
        if (this.validOrg === this.orgStatus.VALID) {
          this.activeWizard.valid = true;
        } else if (this.validOrg === this.orgStatus.INPROGRESS) {
          this.activeWizard.valid = false;
          this.errMsg = 'Please wait. Checking for organization name.';
        } else {
          this.activeWizard.valid = false;
          this.errMsg = 'Please enter valid organization name.';
        }
      } else if (this.activeWizard.id === 'ADMIN_DETAILS') {
        console.log('ADMIN DETAILS WIZARD...');
        if (form.get('password').value !== form.get('confirmPassword').value) {
          this.errMsg = 'Password not matching.';
          this.activeWizard.valid = false;
        } else {
          this.activeWizard.valid = true;
        }
      } else {
        console.log('OTHER WIZARD...');
        console.log(this.orgDetailsForm.value);
        console.log(this.adminDetailsForm.value);
        this.activeWizard.valid = true;
      }

      // MOVE TO NEXT STEP
      if (this.activeWizard.valid && !this.activeWizard.last) {
        this.showNextPage();
      } else {
        // THIS IS FINAL STEP
      }
      console.log(this.activeWizard);
      console.log(this);
    } else {
      // STAY THERE
    }

  }
  createUserAccountInFirebase(): void {
    const data = this.adminDetailsForm.value;
    const credentials = {
      name: this.commonService.getFullNameFromFirstAndLastName(data.firstName, data.lastName),
      email: data.email,
      password: data.password
    };
    const orgFormData = this.orgDetailsForm.value;
    console.log(data);
    console.log(credentials);
    console.log((this.finalpackageselected.Totalamount / this.finalpackageselected.amount) * 30);
    const totalDaysDuration = (this.finalpackageselected.Totalamount / this.finalpackageselected.amount) * 30;
    const orgData = {
      organizationInfo: {
        organizationId: null,
        description: `${orgFormData.orgName} Description`,
        name: orgFormData.orgName,
        url: null,
        status: 'Active',
        address: orgFormData.orgAddress,
        type: orgFormData.orgType,
        city: orgFormData.city,
        country: orgFormData.country,
        postalCode: orgFormData.postalCode
      },
      userSignUpInfo: {
        description: '',
        email: data.email,
        name: this.commonService.getFullNameFromFirstAndLastName(data.firstName, data.lastName),
        organizationName: orgFormData.orgName,
        organizationRole: null
      },
      packageReqInfo: {
        packageId: this.finalpackageselected.packageId,
        name: this.finalpackageselected.name,
        duration: totalDaysDuration,
        amount: this.finalpackageselected.amount,
        ToalBillingAmount: this.finalpackageselected.Totalamount
      }
    };
    console.log(orgData);
    this.spinner.show();
    this.socialAuth.createUserWithEmailAndPassword(credentials.email, credentials.password, credentials.name)
      .then(res => {
        console.log('USER CREATE SUCCESS');
        console.log(res);
        this.callOrganizationWithAdminAPI(orgData);
      }, error => {
        console.log(error);
        console.log('USER CREATE FAILED');
        if (error.code === 'auth/email-already-in-use') {
          console.log('The email address is already in use by another account.');
          this.errMsg = 'The email address is already in use by another account.';
        } else {
          this.errMsg = error.code || 'Unknown Error';
        }
        this.spinner.hide();

        this.setAdminPage();
        this.activeWizard.valid = false;
        this.activeWizard.disabled = false;
        this.operationStatus = '';

      }).catch(err => {
        console.log('USER CREATE FAILED');
        if (err.code === 'auth/email-already-in-use') {
          console.log('The email address is already in use by another account.');
          this.errMsg = 'The email address is already in use by another account.';
        } else {
          this.errMsg = err.code || 'Unknown Error';
        }
        this.spinner.hide();

        this.setAdminPage();
        this.activeWizard.valid = false;
        this.activeWizard.disabled = false;
        this.operationStatus = '';

        console.log(err);
      });

    // // FOR ERROR..
    // const msg = 'User already exist with the email. Try with another email for admin.';
    // this.operationStatus = 'failed';
    // this.activeWizard.valid = false;
    // this.setAdminPage();
    // this.adminDetailsForm.get('email').reset();
    // this.errMsg = msg;
    // this.operationStatus = '';

    // // ALL SUCCESS
    // this.operationStatus = 'success';
    // this.activeWizard.disabled = false;
    // this.activeWizard.valid = true;
    // this.spinner.hide();
  }

  callOrganizationWithAdminAPI(orgData): void {
    console.log('callOrganizationWithAdminAPI');
    console.log(orgData);
    this.socialAuth.createOrganizationAndAdmin(orgData)
      .subscribe((data: any) => {
        console.log('ORGANIZATION WITH ADMIN CREATED...');
        console.log(data);
        this.operationStatus = 'success';
        this.activeWizard.disabled = false;
        this.activeWizard.valid = true;
        this.spinner.hide();
      }, error => {
        console.log('ERROR WHILE CREATING ORGANIZATION WITH ADMIN...');
        console.log(error);
        let msg = '';
        if (error.error.message === 'PERSON_ALREADY_REGISTERED_WITH_THIS_EMAIL_ID') {
          msg = 'User already exist with the email. Try with another email for admin.';
        } else {
          msg = error.error.message || 'Unknown error...';
        }
        if (error.status === 0) {
          msg = 'Server down, Please try again after some time.';
        }
        this.activeWizard.valid = false;
        this.activeWizard.disabled = false;
        this.setAdminPage();
        this.adminDetailsForm.get('email').reset();
        this.errMsg = msg;
        this.operationStatus = '';
        this.spinner.hide();
      });

  }
  createOrganizationWithAdmin(): void {
    console.log('WILL CALL APIS...');
    if (this.operationStatus === '') {
      console.log('CREATING ACCOUNT');
      this.activeWizard.disabled = true;
      // setTimeout(() => {
      // this.activeWizard.disabled = false;
      this.createUserAccountInFirebase();
      // }, 5000);
    } else {
      console.log('ALREADY SUBMITTED');
    }
  }

  closeWindow(): void {
    console.log('Close window...');
    this.closeLogin.emit('close');
  }

  changePage(e): void {
    console.log(e);
    // this.page = any;
    // console.log(any.value);
    // console.log(any);
  }

  getErrorMsg(ctrl: FormControl, name: string, type = ElementType.INPUT): string {
    return this.commonService.getFormErrorMsg(ctrl, name, type);
  }

  openPage(page: string): void {
    this.showPage.emit(page);
  }
  process(e) {
    console.log(e)

    this.menus[0].active = false;
    console.log(this.menus[e - 1].name)
    for (let i = 1; i < this.menus.length + 1; i++) {
      if (e == i) {
        this.menus[i - 1].active = true;
        console.log(this.showmenue[e])
        this.activeWizard.id = this.showmenue[e - 1];

      }
      else {
        this.menus[i - 1].active = false;
      }
    }


  }

  Selectexploremonth(month, amount,ii) {
    this.selectedexploremonth = month;
    console.log(ii,"li")
    if(ii==1){
      this.selectedexpplanprice=95
    }
    else if(ii==2){
      this.selectedexpplanprice=395
    }
    else if(ii==0){
      this.selectedexpplanprice=45
    }
    else{
      this.selectedexpplanprice = this.packages[ii]["amount"];
    }
    
    console.log(this.selectedexpplanprice)
    /* for (let x = 0; x < this.package[0].Explore.length; x++) {
      if (this.package[0].Explore[x]["month"] == month) {
        this.selectedexpplanprice = this.package[0].Explore[x]["amount"]
      }
      this.finalselectedprice = this.selectedexpplanprice;

      this.finalselectedprice2 = JSON.stringify(this.finalselectedprice)
      this.finalselectedprice2 = this.finalselectedprice2.slice(2, this.finalselectedprice2.length - 1)
      this.finalselectedprice2 = Number(this.finalselectedprice2)
      this.totalprice = this.finalselectedprice2 + this.tax
      console.log(this.totalprice)
    } */
    console.log(this.packages,"unupdated package");
  
    this.totalprice = (this.selectedexpplanprice) * (this.selectedexploremonth.split(' ')[0]) + this.tax
   
    this.totalAmount = (this.selectedexpplanprice) * (this.selectedexploremonth.split(' ')[0]);
    this.packages[ii]["Totalamount"]=this.totalAmount;
    console.log(this.packages,"updated package");
   this.packages[ii]["Month selected"]=this.selectedexploremonth
    this.isTotalPrice = false;
    this.finalpackageselected=this.packages[ii];
    console.log(this.finalpackageselected,"this is the package selected by user");
  }
  selectstarterpack(e) {
    console.log(this.packages["packageId"],"asdf");
    this.cardno=e;
    this.finalpackageselected=this.packages[e]
    // for(let i=0;i<this.packages.length;i++){
    //   if(this.cardno==this.packages["packageId"]){
    //     this.showstartertick=!this.showstartertick;
    //   }
    //   else{
    //     this.showstartertick=false;
    //   }
    // }
    this.finalselectedmonth = this.packages[e]["Month selected"];
    this.finalpackagename=this.packages[e]["name"]
    this.showstartertick = !this.showstartertick;
    this.totalprice=this.packages[e]["Totalamount"]+this.tax
    
    this.showstartertick = !this.showstartertick;
    console.log(this.finalpackageselected,"selected package by user")
    
    // $(".subscplan").css({"border" : "5px solid white"})
  }
  /* selectexplorepack() {
    this.showstartertick = false;
    this.showexploretick = true;
    this.showengagetick = false;
    console.log("Selected pack = Explore")
    console.log("Selected months =", this.selectedexploremonth);
    console.log("Price = ", this.selectedexpplanprice)
  }
  selectengagepack() {
    this.showstartertick = false;
    this.showexploretick = false;
    this.showengagetick = true;
    console.log("Selected pack = Engage")
    console.log("Selected months =", this.selectedengagemonth);
    console.log("Price = ", this.selectedengageprice)
  } */
}
