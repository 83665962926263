<div [ngClass]="{'fixed-alert': true, 'show': showAlert}">
    <div class="fixed-alert-container">
        <ngb-alert [type]="alertType" (close)="closeAlert()" [dismissible]="true">
            {{ alertMessage }}
        </ngb-alert>
    </div>
  </div>
<div *ngIf="loadingLoginDiv">
    <div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
        <div class="position-absolute top-0 start-0 mt-3 ms-3">
            <button (click)="showBlog()" class="btn btn-secondary text-center">Back</button>
        </div>
        <div class="container-fluid d-flex justify-content-center">
            <div class="row w-100 d-flex justify-content-center align-items-center">
                <!-- Logo and Icons Column -->
                <div class="col-md-3 d-flex flex-column align-items-center justify-content-center text-center mb-4">
                    <img src="../../assets/img/fe-logo.svg" alt="fuse.Earth Logo" width="400" class="logo mb-2">
                    <div class="icon-link">
                        <a class="d-inline" href="https://mapsolgeo.com/contact-us/" target="_blank"><img
                                src="../../assets/img/support_icon.svg" alt="support" class="social-link"></a>
                        <a class="d-inline mx-4" href="https://mapsolgeo.com/videos/" target="_blank"><img
                                src="../../assets/img/blog_icon.svg" alt="blog" class="social-link"></a>
                        <a class="d-inline" href="https://mapsolgeo.com/tour/" target="_blank"><img
                                src="../../assets/img/tour_icon.svg" alt="tour" class="social-link"></a>
                        <a class="d-inline mx-4" href="https://mapsolgeo.com/tutorials/" target="_blank"><img
                                src="../../assets/img/tutorials_icon.svg" alt="tutorials" class="social-link"></a>
                        <a href="https://mapsolgeo.com/videos/" target="_blank"><img
                                src="../../assets/img/video_icon.svg" alt="video" class="social-link"></a>
                    </div>
                </div>
                <!-- Vertical Line Column -->
                <div class="col-md-1 d-flex align-items-center justify-content-center">
                    <div class="vertical-line"></div>
                </div>
                <!-- Form Column -->
                <div class="col-md-3" *ngIf="loginForgotDiv">
                    <h6 class="text-start text-dark fw-bold">Forgot Password</h6>
                    <div class="d-flex flex-column justify-content-center">
                        <form id="emailForm" class="text-center">
                            <div class="mb-3">
                                <input type="email" class="form-control" id="emailInput" placeholder="Email ID"
                                    required>
                            </div>                            
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <button (click)="loginForgotDiv=false;loginLoginDiv=true;" type="submit" class="btn btn-secondary text-end">BACK</button>
                                <button type="submit" class="btn btn-secondary text-end">SEND</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="loginLoginDiv">
                    <h6 class="text-start text-dark fw-bold">Login</h6>
                    <div class="d-flex flex-column justify-content-center">
                        <form [formGroup]="loginForm" id="emailForm" class="text-center">
                            <div class="mb-3">
                                <input formControlName="email" type="email" class="form-control" id="emailInput" placeholder="Email ID"
                                    required>
                                    <span *ngIf="!loginForm.get('email').valid && loginForm.get('email').touched" class="error">
                                        {{getErrorMsg(loginForm.get('email'), 'Email')}}
                                    </span>
                            </div>
                            <div class="mb-3">
                                <input formControlName="password" type="password" class="form-control" id="passwordInput" placeholder="Password"
                                    required>
                                    <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched" class="error">
                                        {{getErrorMsg(loginForm.get('password'), 'Password')}}
                                    </span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <!-- <a class="text-start text-dark notextdec">Forgot Password?</a> -->
                                <span class="text-start text-dark notextdec" (click)="setForgotPwdPage(); loginForgotDiv=true;">Forgot Password?</span>
                                <button (click)="validateLogin()" type="submit" class="btn btn-secondary text-end">Login</button>
                                <!-- <p class="err-msg">{{errMsg}}</p> -->
                            </div>
                        </form>
                        <h6 class="text-center">or</h6>
                        <button (click)="loginWithGoogle('signIn')" type="submit" class="btn btn-secondary text-center my-3">Login via Gmail</button>
                        <button type="submit" class="btn btn-outline-secondary text-center"
                            (click)="loadingLoginDiv=false;loadingSignupDiv=true;">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
        <footer class="position-absolute bottom-0 start-0 m-3 d-flex justify-content-between">
            <p class="text-muted mb-0"><a href="https://mapsolgeo.com" target="_blank"><img
                        src="../../assets/img/mapsol-logo.svg" alt="fuse.Earth Logo" width="250" class="mb-2"></a></p>
            <div class="icon-link align-self-end">
                <nav>
                    <a href="https://www.facebook.com/fuse.earth" target="_blank"><img
                            src="../../assets/img/facebook_icon.svg" alt="Facebook" class="social-link"></a>
                    <a href="https://www.linkedin.com/showcase/fuse-earth/" target="_blank"><img
                            src="../../assets/img/linkedin_icon.svg" alt="LinkedIn" class="social-link"></a>
                    <a href="https://www.instagram.com/fuse_earth/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank"><img
                            src="../../assets/img/instagram_icon.svg" alt="Instagram" class="social-link"></a>
                    <a href="https://x.com/fuse_earth" target="_blank"><img src="../../assets/img/twitter_icon.svg"
                            alt="Twitter" class="social-link"></a>
                </nav>
            </div>
        </footer>
    </div>
</div>
<!-- [pageType]="globalObject.pageType" -->
<div *ngIf="loadingSignupDiv">
    <app-landing-signup  [userProfile]="loggedInUserProfile" [userInfo]="userInfo"
        [pageClickEvent]="showOrHideUserLoginMenu" (signupToLoginEmit) = "signupToLoginFun($event)"></app-landing-signup>
</div>