<div [ngClass]="{'fixed-alert': true, 'show': showAlert}">
  <div class="fixed-alert-container">
      <ngb-alert [type]="alertType" (close)="closeAlert()" [dismissible]="true">
          {{ alertMessage }}
      </ngb-alert>
  </div>
</div>
<div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    <div class="position-absolute top-0 start-0 mt-3 ms-3 icon-link">
        <nav class="landingheader">
            <a href="https://mapsolgeo.com/contact-us/" target="_blank"><img src="../../assets/img/support_icon.svg" alt="support" class="social-link"></a>
            <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/blog_icon.svg" alt="blog" class="social-link"></a>
            <a href="https://mapsolgeo.com/tour/" target="_blank"><img src="../../assets/img/tour_icon.svg" alt="tour" class="social-link"></a>
            <a href="https://mapsolgeo.com/tutorials/" target="_blank"><img src="../../assets/img/tutorials_icon.svg" alt="tutorials" class="social-link"></a>
            <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/video_icon.svg" alt="video" class="social-link"></a>
        </nav>
    </div>
    <div class="position-absolute top-0 end-0 mt-3 me-3">
      <p>Already an user? <a href="#" class="btn btn-secondary">Login</a></p>
    </div>
    <h1 class="text-center fs-4">Organization Registration Process</h1>
    <form [formGroup]="orgsignupForm" id="signUpForm" (submit)="onSubmit()">
      <div class="form-header d-flex mb-4">
        <span class="stepIndicator">Organization Details</span>
        <span class="stepIndicator">Administrator Details</span>
        <span class="stepIndicator">Subscription Plan Details</span>
        <span class="stepIndicator">Payment Details</span>
        <span class="stepIndicator">Email Verification</span>
      </div>
      
      <div class="step">
        <p class="text-center mb-4">Organization Details</p>
        <div class="mb-3">
          <input formControlName="orgname" type="text" class="form-control" placeholder="Organization Name" name="org-name">
          <span *ngIf="!orgsignupForm.get('orgname').valid && orgsignupForm.get('orgname').touched" class="error">
            {{getErrorMsg(orgsignupForm.get('orgname'), 'Organization Name')}}
        </span>
        </div>
        <div class="mb-3">
          <input formControlName="orgaddr" type="text" class="form-control" placeholder="Organization Address" name="org-address">
          <span *ngIf="!orgsignupForm.get('orgaddr').valid && orgsignupForm.get('orgaddr').touched" class="error">
            {{getErrorMsg(orgsignupForm.get('orgaddr'), 'Organization Address')}}
        </span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <select formControlName="orgtype" class="form-select" id="org-type">
                <option selected>Organization Type</option>
                <option value="default">Default</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="orgcity" type="text" class="form-control" placeholder="City" name="org-city">
              <span *ngIf="!orgsignupForm.get('orgcity').valid && orgsignupForm.get('orgcity').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('orgcity'), 'City')}}
            </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="orgcountry" type="text" class="form-control" placeholder="Country" name="org-country">
              <span *ngIf="!orgsignupForm.get('orgcountry').valid && orgsignupForm.get('orgcountry').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('orgcountry'), 'Country')}}
            </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="orgpostal" type="text" class="form-control" placeholder="Postal Code" name="org-postalcode">
              <span *ngIf="!orgsignupForm.get('orgpostal').valid && orgsignupForm.get('orgpostal').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('orgpostal'), 'Postal Code')}}
            </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="step">
        <p class="text-center mb-4">Administrator Details</p>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="firstname" type="text" class="form-control" placeholder="First Name" name="first-name">
              <span *ngIf="!orgsignupForm.get('firstname').valid && orgsignupForm.get('firstname').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('firstname'), 'First Name')}}
            </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="lastname" type="text" class="form-control" placeholder="Last Name" name="last-name">
              <span *ngIf="!orgsignupForm.get('lastname').valid && orgsignupForm.get('lastname').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('lastname'), 'Last Name')}}
            </span>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <input formControlName="email" type="text" class="form-control" placeholder="Email ID" name="email-id">
          <span *ngIf="!orgsignupForm.get('email').valid && orgsignupForm.get('email').touched" class="error">
            {{getErrorMsg(orgsignupForm.get('email'), 'Email ID')}}
        </span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <input formControlName="password" type="password" class="form-control" placeholder="Password" name="password">
              <span *ngIf="!orgsignupForm.get('password').valid && orgsignupForm.get('password').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('password'), 'Password')}}
            </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <input type="password" class="form-control" placeholder="Confirm Password" name="confirm-password">
              <span *ngIf="!orgsignupForm.get('password').valid && orgsignupForm.get('password').touched" class="error">
                {{getErrorMsg(orgsignupForm.get('password'), 'confirm-password')}}
            </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="step">
        <p class="text-center mb-4">Subscription Plan Details</p>
        <div class="mb-3">
          <select class="form-select" id="basicSelect">
            <option selected>Select a Package</option>
            <option value="engage">Engage</option>
          </select>
        </div>
      </div>
      
      <div class="step">
        <p class="text-center mb-4">Payment Details</p>
        <div class="row mb-3">
          <div class="col-md-6 mb-3 mb-md-0">
            <input formControlName="cardnum" type="text" class="form-control" placeholder="Card Number">
            <span *ngIf="!orgsignupForm.get('cardnum').valid && orgsignupForm.get('cardnum').touched" class="error">
              {{getErrorMsg(orgsignupForm.get('cardnum'), 'Card Number')}}
          </span>
          </div>
          <div class="col-md-3">
            <input formControlName="expmonth" type="text" class="form-control" placeholder="Expiry Month">
            <span *ngIf="!orgsignupForm.get('expmonth').valid && orgsignupForm.get('expmonth').touched" class="error">
              {{getErrorMsg(orgsignupForm.get('expmonth'), 'Expiry Month')}}
          </span>
          </div>
          <div class="col-md-3 mb-3 mb-md-0">
            <input formControlName="expyear" type="text" class="form-control" placeholder="Expiry Year">
            <span *ngIf="!orgsignupForm.get('expyear').valid && orgsignupForm.get('expyear').touched" class="error">
              {{getErrorMsg(orgsignupForm.get('expyear'), 'Expiry Year')}}
          </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <input formControlName="cvv" type="text" class="form-control" placeholder="CVV">
            <span *ngIf="!orgsignupForm.get('cvv').valid && orgsignupForm.get('cvv').touched" class="error">
              {{getErrorMsg(orgsignupForm.get('cvv'), 'CVV')}}
          </span>
          </div>
          <div class="col-md-6 mb-3 mb-md-0">
            <input formControlName="cardholdername" type="text" class="form-control" placeholder="Card Holder Name">
            <span *ngIf="!orgsignupForm.get('cardholdername').valid && orgsignupForm.get('cardholdername').touched" class="error">
              {{getErrorMsg(orgsignupForm.get('cardholdername'), 'Card Holder Name')}}
          </span>
          </div>
        </div>
      </div>
      
      <div class="step">
        <p class="text-center mb-4">Email Verification</p>
        <div class="col-md-12">
          <p class="text-center mb-4">Account Creation in progress...</p>
          <p class="text-center mb-4">Please check your email to verify & activate your Account</p>
        </div>
      </div>
      
      <div class="form-footer d-flex">
        <button type="button" class="btn btn-dark" id="prevBtn" (click)="nextPrev(-1)">Previous</button>
        <button type="button" class="btn btn-dark" id="nextBtn" (click)="nextPrev(1)">Next</button>
      </div>
    </form>
    <footer class="position-absolute bottom-0 start-0 m-3 d-flex justify-content-between">
        <p class="text-muted mb-0"><a href="https://mapsolgeo.com" target="_blank"><img src="../../assets/img/mapsol-logo.svg" alt="fuse.Earth Logo" width="250" class="mb-2"></a></p>
        <div class="icon-link align-self-end">
            <nav>
                <a href="https://www.facebook.com/fuse.earth" target="_blank"><img src="../../assets/img/facebook_icon.svg" alt="Facebook" class="social-link"></a>
                <a href="https://www.linkedin.com/showcase/fuse-earth/" target="_blank"><img src="../../assets/img/linkedin_icon.svg" alt="LinkedIn" class="social-link"></a>
                <a href="https://www.instagram.com/fuse_earth/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank"><img src="../../assets/img/instagram_icon.svg" alt="Instagram" class="social-link"></a>
                <a href="https://x.com/fuse_earth" target="_blank"><img src="../../assets/img/twitter_icon.svg" alt="Twitter" class="social-link"></a>
            </nav>
        </div>
    </footer>
  </div>  