import {
  Component, OnInit, Input, ViewChild, ElementRef, Output, VERSION, EventEmitter,
  SimpleChange, HostListener, OnChanges, AfterViewInit, ChangeDetectionStrategy,
  forwardRef, Renderer2
} from '@angular/core';
import { ConfigServices } from 'src/app/config.service';
import { ChangeDetectorRef } from '@angular/core';
import { GeotowerService } from '../geotower.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigDataKeys } from '../../config.enum';
import { ActivatedRoute, Event } from '@angular/router';
import { BasemapService } from '../../basemap/basemap.service';
import { ConnectionComponent } from '../tower-item-headers/connection/connection.component';
import { DepictionComponent } from '../tower-item-headers/depiction/depiction.component';
import { FunctionComponent } from '../tower-item-headers/function/function.component';
import { InteractionComponent } from '../tower-item-headers/interaction/interaction.component';
import { SpecificationComponent } from '../tower-item-headers/specification/specification.component';
import { CommonService } from '../../Services/common.service';
import { LayersService } from 'src/app/Services/layers.service';
import { GeobaseService } from 'src/app/Services/geobase.service';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import { getCenter } from 'ol/extent';
import { Style, Fill, Stroke, Icon } from 'ol/style'; 
import { findIndex } from 'rxjs-compat/operator/findIndex';
import { TowerItemOptionsComponent } from '../tower-item-options/tower-item-options.component';
import { GeotowerComponent } from '../geotower.component';
import { Vector} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector.js';
import OlView from 'ol/View';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as $ from "jquery";
import { DbfTableComponent } from '../tower-item-headers/dbf-table/dbftable.component';
import { Text } from "ol/style";
import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { LabledropdownComponent } from '../tower-item-headers/labledropdown/labledropdown.component';
import { GeotablesComponent } from '../geotables/geotables.component';
import { CsfdiServiceService } from '../csfdi-service.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import Overlay from 'ol/Overlay';


import VectorLayer from 'ol/layer/Vector.js';
import * as ol from 'ol';
import Map from 'ol/Map.js';
import View from 'ol/View.js';




@Component({
  selector: 'app-tower-item',
  templateUrl: './tower-item.component.html',
  styles: [':host{ display:block; }'],
  styleUrls: ['./tower-item.component.scss']
})
export class TowerItemComponent implements OnInit, OnChanges {
  isModalOpen: boolean = false;
  activeTab: string = 'C';  

  modalData: any;

  @Input() layerOrder: number;
  @Input() layerIndex: number;
  @Input() layersList: any[];
  @Input() layerObj: any; 
    // layersList: any[] = [];
  @Input() refresh = '';
  @Output() towerReloaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() showPanToLayer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() layersCount: EventEmitter<number> = new EventEmitter<number>();
  @Input() isGroup: boolean;
  @Output() isAnyLayerOptionsActive: EventEmitter<boolean> = new EventEmitter();
  @Output() saveTowerLayer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('towerItemOption') towerItemOptionEle: ElementRef;
  authentication: any;
  layersURL: any;
  // layerItemOptActive: EventEmitter<any> = new EventEmitter();
  // previewIsActive: boolean;
  previewLayer: any;
  @ViewChild(DbfTableComponent) dbftableComp: DbfTableComponent;
  @ViewChild(ConnectionComponent) connectionComp: ConnectionComponent;
  @ViewChild(DepictionComponent) depictionComp: DepictionComponent;
  @ViewChild(FunctionComponent) functionComp: FunctionComponent;
  @ViewChild(InteractionComponent) interactionComp: InteractionComponent;
  @ViewChild(SpecificationComponent) specificationComp: SpecificationComponent;
  @ViewChild(LabledropdownComponent) labledropdownComp: LabledropdownComponent;
  @ViewChild(GeotablesComponent) geotablesComp: GeotablesComponent;
  public CONNECTION_HEADER = 'connection';
  public DEPICTION_HEADER = 'depiction';
  public FUNCTION_HEADER = 'function';
  public INTERACTION_HEADER = 'interaction';
  public SPECIFICATION_HEADER = 'specification';

  @ViewChild('modalContainer', { static: false }) modalContainer: ElementRef;
  @ViewChild('modalHeader') modalHeader!: ElementRef;
  isDragging = false;
  initialX = 0;
  initialY = 0;
  offsetX = 0;
  offsetY = 0;

  
  //depiction
  color1;
  pickerStatus = "none";
  @ViewChild('container') _containerEl: ElementRef;
  @ViewChild('closer') _closerEl: ElementRef;
  private _popupOverlay: Overlay;
  depictionforlayer: string = 'Polygon'; 
  static setoutlinecolorforMP= "#000000";
  layerobj: any;
  showoutlinethicknesspopup=false
  symbols=['../../../../assets/images/geo-alt-fill.svg','../../../../assets/images/square.svg','../../../../assets/images/star.svg','../../../../assets/images/circle.svg','../../../../assets/images/triangle.svg','../../../../assets/images/pentagon.svg'];
  
  lineWidths = [
    { name: '1px', value: 1, icon: 'fas fa-minus' },
    { name: '2px', value: 2, icon: 'fas fa-minus' },
    { name: '3px', value: 3, icon: 'fas fa-minus' }
  ];

  // Color properties
  selectedColor: string = '#0000ff';
  selectedColor2: string = '#ffffff';
  selectedColor3: string = '#000000';


  selectedStrokeWidth = 3;
  selectedthickness: any;
  selectedlinestyle=[1];
  showoutlinestylepopup=false;
  setfillcolor='rgba(255, 255, 255, 1)';
  selectedsymbolurl: string;
  outlinecolor: string;
  setfillcolor3: any;
  svg: string;
  firstsymbol: string;
  showsymbolpopup=false
  static setoutlinecolorforMP1: string;
  outlinecolor1: string;
  selectedthickness1=false;
  selectedlinestyle1: number[];
  showoutlinestylepopup1=false;
  showoutlinethicknesspopup1=false;
  

  @Input() towerWidth: number;
  @Input() towerOptionsWidth: number;
  @Input() layerOptionsHeight: number;
  @Input() isGuest: boolean;
  @Input() sessionId;
  @Input() towerId;
  @Input() layer: any;
  private JPG_EXTENSION_CONSTANT = '.jpg';
  private JPEG_EXTENSION_CONSTANT = '.jpeg';
  private TIF_EXTENSION_CONSTANT = '.tif';
  private PDF_EXTENSION_CONSTANT = '.pdf';
    
  sliderValue = 75;
  private mapLayersList = new Map();
  @Input() isDeleteDisable;
  @Input() globalObject;
  @Output() showPreview: EventEmitter<any> = new EventEmitter<any>();
  @Output() sliderVal: EventEmitter<any> = new EventEmitter<any>();
  @Input() geoRefOrPreviewClosed = '';
  selectedLayerForPreview = '';
  @Input() isGeotowerActive;
  @Input() geoRefLayerDataToShow: any = {};


  @Input() connProperties: any[] = [];
  @Input() specProperties: any[] = [];
  @Input() funProperties: any[] = [];
  depProperties: any[] = [];
  @Input() intProperties: any[] = [];

  searchorhand = true;
  showlayerordeletelayer = true;
  tableordatabase = true;
  selectionorlabel = true;
  zoomorpan = true;
  showpreview: boolean;
  finallayername: any;
  showlayervisibility = false;
  
  static isdrag;
  @Output() DragvalueChanged = new EventEmitter<any>();
  @ViewChild('button') button: ElementRef;
  @ViewChild('container') container: ElementRef;
  value: any;
  dragen: any;
  backgroundcolorvalue: any;
  backgroundcolorwhitevalue: number;
  showslidervalue = false;
  firstColor: string;
  secondColor: string;
  static selectedLayergeometrictype = "Notgeometric";
  setcolor: string;
  static towertodepiction: any;
  rasters:any = '';
  selectionToogle = false;
  funcProperties: any;
  interProperties: any;
  
  tabDetails = {
    'C': { name: 'Connection', color: '#8B572A' },
    'S': { name: 'Specification', color: '#D49A5E' },
    'F': { name: 'Function', color: '#50A1E3' },
    'D': { name: 'Depiction', color: '#C94C63' },
    'I': { name: 'Interaction', color: '#7E57C2' }
  };

  toggleDropdown(type: string) {
    if (type === 'thickness') {
      this.showoutlinethicknesspopup = !this.showoutlinethicknesspopup;
      this.showoutlinestylepopup = false;
    } else if (type === 'style') {
      this.showoutlinestylepopup = !this.showoutlinestylepopup;
      this.showoutlinethicknesspopup = false; 
    }
  }
  

  openModal(tab: string): void {
    this.activeTab = tab;
    this.isModalOpen = true;
  }
  onDragEnded(event) {
    // Calculate the height of the button relative to the div
    const height = this.button.nativeElement.offsetTop - this.container.nativeElement.offsetTop;
    console.log(`Button height: ${height}px`, "lkjjkl");
  }



  constructor(private dialog: MatDialog, private configService: ConfigServices, private commonService: CommonService,
    private cdr: ChangeDetectorRef, private renderer: Renderer2,
    private geotowerService: GeotowerService, private route: ActivatedRoute,
    private basemap: BasemapService, private layersService: LayersService, 
    private csfdiService: CsfdiServiceService,
    private geobaseService: GeobaseService, private http: HttpClient) {
    this.firstColor = 'RGB(226, 226, 226, 1)';
    this.secondColor = 'RGB(255, 255, 255)';
  }

  ngOnChanges(change: { [key: string]: SimpleChange }): any {

    if (change.layerObj) {
      this.setDepictionLayerType();
      this.depProperties = this._setDepictionPropertyJson(this.layerObj);
      this.cdr.detectChanges();
  }
    this.cdr.detectChanges();
    this.setcolor = DepictionComponent.setoutlinecolorforMP;

    if (change.depProperties) {
        this.depProperties = this._setDepictionPropertyJson(this.layer);
    }

    if (this.commonService.isValid(change.geoRefOrPreviewClosed)) {
        if (this.commonService.isValid(change.geoRefOrPreviewClosed.currentValue)) {
            this.selectedLayerForPreview = '';
        }
    }

    this.cdr.detectChanges(); 
    this.setcolor = DepictionComponent.setoutlinecolorforMP

    // this.isDeleteDisable = change.isDeleteDisable.currentValue;
    console.log(' is guest ', this.isGuest, change, this.isDeleteDisable);
    if (this.commonService.isValid(change.refresh)) {
      if (this.commonService.isValid(change.refresh.currentValue)) {
        console.log('REFRESH TOWER DATA');
        // this._getTowerLayerList(this.towerId);
      }
    }
    if (this.commonService.isValid(change.geoRefOrPreviewClosed)) {
      if (this.commonService.isValid(change.geoRefOrPreviewClosed.currentValue)) {
        console.log('REFRESH TOWER DATA');
        this.selectedLayerForPreview = '';
      }
    }

    if (this.commonService.isValid(change.layerList)) {
      if (this.commonService.isValid(change.layerList.currentValue)) {
        console.log('REFRESH TOWER DATA');
        this.layer = this.layersList[this.layerIndex];
      }
    }
  }

  get sliderContainerStyles() {
    return {
      'width': '111%',
      'height': '10px',
      'background': 'none',
      'outline': 'none',
      'transform': 'rotate(0deg)',
      'margin-top': '28px',
      'position': 'absolute',
      'z-index': '1'
    };
  }
  
  get sliderThumbStyles() {
    return {
      'width': '10px',
      'height': '10px',
      'background-image': 'url("/assets/img/scroller.png")',
      'color': '#353535',
      'background-repeat': 'no-repeat',
      'cursor': 'pointer',
      'background-size': 'auto 100%',
      'margin-top': '-4px',
      'margin-left': '-2px'
    };
  }

  
  connectionData: any = {};

  ngOnInit(): any {

    DepictionComponent.setoutlinecolorforMP='';
    this.firstsymbol=this.symbols[3];
    this.setDepictionLayerType();
   
    console.log(this);
   
    // this._getTowerLayerList();
    // this.getGeobaseInfo();
    // this._getTowerLayerList(this.towerId);
    this.depProperties = this._setDepictionPropertyJson(this.layer);
    console.log('Depiction properties in parent (TowerItemComponent):', this.depProperties);

  
    if (this.commonService.isValid(this.layersList)) {
      this.layer = this.layersList[this.layerIndex];
    }
    if (this.layer.fileType === '.jpg') {
      if (this.commonService.isValid(this.layer.metadata.geodata)) {
        this.layer.showconnection = true;
        // return true;
      } else {
        this.layer.showconnection = false;
        // return false;
      }
    } else {
      this.layer.showconnection = true;
      // return true;
    }
    console.log(this.layer, this.layer.showconnection = true, "connections")
    // const layerName = 'USGS_24KBoundaries';
    // const layer = 'tiger:' + layerName;
    // const tiled = new TileLayer({
    //   visible: true,
    //   title: layerName,
    //   source: new TileWMS({
    //     url: 'http://146.190.140.219:8282/geoserver/tiger/wms',
    //     params: {'FORMAT': 'image/png', 
    //              'VERSION': '1.1.1',
    //              tiled: true,
    //           "STYLES": '',
    //           "LAYERS": layer,
    //           "exceptions": 'application/vnd.ogc.se_inimage',
    //        tilesOrigin: -104.92041666666667 + "," + 36.59374999999999
    //     },
    //     // projection: 'EPSG:4326',
    //   }),
    //   zIndex:2
    // });
    // // this.basemap.getCurrentBasemap().addLayer(tiled);
    // var vectorSource = new VectorSource({
    //   format: new GeoJSON(),
    //   url: 'http://146.190.140.219:8282/geoserver/tiger/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=tiger%3AUSGS_24KBoundaries&outputFormat=application%2Fjson',
    //   // params: {
    //   //   service:"WFS",
    //   //   version:"1.0.0",
    //   //   request:"GetFeature",
    //   //   typeName:"tiger:USGS_24KBoundaries",
    //   //   outputFormat:"application/json"
    //   // }
    //   // strategy: ol.loadingstrategy.bbox
    // });

    // var vector = new VectorLayer({
    //   source: vectorSource,
    //   style: new Style({
    //     stroke: new Stroke({
    //       color: 'rgba(0, 0, 255, 1.0)',
    //       width: 2
    //     })
    //   }),
    //   name:'USGS_24KBoundaries',
    //   zIndex:5
    // });
    // this.basemap.getCurrentBasemap().addLayer(vector);

  }

  //depiction

  selectStrokeWidth(width: number) {
    this.selectedStrokeWidth = width;
  }
  /*getDepctionPopup() {
    console.log(TowerItemComponent.selectedLayergeometrictype)
    
    this.depictionforlayer=TowerItemComponent.selectedLayergeometrictype;
    console.log(TowerItemComponent.towertodepiction,this.depictionforlayer,"Layerindepiction")
    console.log(this.depictionforlayer == 'MultiPolygon')
    this.layerobj=TowerItemComponent.towertodepiction;
    this._popupOverlay = new Overlay({
      element: this._containerEl.nativeElement,
      offset: [-600, 250],
      autoPan: true,
      autoPanAnimation: {
          duration: 250
      }
  });
    this._closerEl.nativeElement.onclick = () => {
      this.close();
    };
    return this._popupOverlay;
  }
  */
  colorList = ['#ff0000', '#00ff00', '#0000ff'];

  setDepictionLayerType(): void {
    console.log(this.layerObj)
    if (this.layerObj.geometryType) {
      const geometryType = this.layerObj.geometryType;
      this.depictionforlayer = ['MultiPolygon', 'Polygon', 'PolygonZ', 'PolygonM', 'MultiPatch'].includes(geometryType)
          ? 'Polygon'
          : ['Point', 'MultiPoint', 'PointM', 'PointZ'].includes(geometryType)
          ? 'Point'
          : ['LineString', 'MultiLineString', 'LineStringZ', 'LineStringM', 'CompoundCurve', 'CircularString'].includes(geometryType)
          ? 'LineString'
          : 'Notgeometric';
    } else {
      this.depictionforlayer = 'Notgeometric';
    }
  }


  selectColor2='rgba(255, 255, 255, 1)';

  selectOutlineColorFun(color: string) {
    DepictionComponent.setoutlinecolorforMP = color;
    this.outlinecolor = color;
    this.applyStyleToLayer({
        stroke: new Stroke({
            color: color,
            width: this.selectedthickness || 3,
            lineDash: this.selectedlinestyle || []
        }),
        fill: new Fill({
            color: this.setfillcolor || '#FFFFFF'
        })
    });
}


selectOutlineColorFun4(color: string) {
  console.log(color,"checking color");
  console.log(this.layerobj,"layerinsetcolor")
  DepictionComponent.setoutlinecolorforMP1=color;
  this.outlinecolor1=color;
  // logic to update color value
  console.log( DepictionComponent.setoutlinecolorforMP1,"checking color");
    const polystyle=new Style({
      stroke: new Stroke({
        color: color,
        width: this.selectedthickness1,
        lineDash: this.selectedlinestyle1,
      }),
      fill: new Fill({
        color:  this.setfillcolor,
      }),
    })
    
  
  this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
    if (this.layer.name === currentLayer.values_.name) {
      console.log(currentLayer,"inbasemapservice")
      currentLayer.setStyle(polystyle)
    }
  });
  
}
selectfillColorFun(fillColor: string) {
  this.setfillcolor = fillColor;
  this.applyStyleToLayer({
      stroke: new Stroke({
          color: this.outlinecolor || DepictionComponent.setoutlinecolorforMP || '#000000',
          width: this.selectedthickness || 3,
          lineDash: this.selectedlinestyle || []
      }),
      fill: new Fill({
          color: fillColor
      })
  });
}


setlinethickness(thickness){
  this.selectedthickness=thickness;
  console.log(this.selectedthickness,"selected thickness")

  this.showoutlinethicknesspopup = false;

  //this.showoutlinethicknesspopup=!this.showoutlinethicknesspopup
  if(this.showoutlinestylepopup==true){
    this.showoutlinestylepopup=!this.showoutlinestylepopup
  }
  const polystyle=new Style({
    stroke: new Stroke({
      color: DepictionComponent.setoutlinecolorforMP,
      width: this.selectedthickness,
      lineDash: this.selectedlinestyle,
    }),
    fill: new Fill({
      color: this.setfillcolor,
    }),
  });
  this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
  if (this.layer.name === currentLayer.values_.name) {
    console.log(currentLayer,"inbasemapservice")
    currentLayer.setStyle(polystyle)
  }
  });
  }


  setlinethickness1(thickness){
    this.showoutlinestylepopup1=false
    this.selectedthickness1=thickness;
    console.log(this.selectedthickness1,"selected thickness")
    this.showoutlinethicknesspopup=!this.showoutlinethicknesspopup
    if(this.showoutlinestylepopup==true){
      this.showoutlinestylepopup=!this.showoutlinestylepopup
    }
    const polystyle=new Style({
      stroke: new Stroke({
        color: DepictionComponent.setoutlinecolorforMP1,
        width: this.selectedthickness1,
        lineDash: this.selectedlinestyle1,
      }),
      fill: new Fill({
        color: this.setfillcolor,
      }),
    })
    
    this.showoutlinethicknesspopup1=!this.showoutlinethicknesspopup1;
    this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
    if (this.layer.name === currentLayer.values_.name) {
      console.log(currentLayer,"inbasemapservice")
      currentLayer.setStyle(polystyle)
    }
    });
    }

    setlinestyle(linestyle){
      console.log(linestyle)
      if(linestyle=='dashed'){
        this.selectedlinestyle=[12,11]
        console.log(linestyle)
      }
      else if(linestyle=='dotted'){
        this.selectedlinestyle=[1,6]
        console.log(linestyle)
      }
      else if(linestyle=='line'){
        this.selectedlinestyle=[0];
        console.log(linestyle)
      }

      console.log(this.selectedlinestyle, "selected linestyle");
      this.showoutlinestylepopup = false;

      const polystyle=new Style({
        stroke: new Stroke({
          color: DepictionComponent.setoutlinecolorforMP,
          width: this.selectedthickness,
          lineDash: this.selectedlinestyle,
        }),
        fill: new Fill({
          color: this.setfillcolor,
        }),
      })
      this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
      if (this.layer.name === currentLayer.values_.name) {
        console.log(currentLayer,"inbasemapservice")
        currentLayer.setStyle(polystyle)
      }
      });
      console.log(this.selectedlinestyle,"selected thickness")
      this.showoutlinestylepopup=!this.showoutlinestylepopup
      if(this.showoutlinethicknesspopup==true){
        this.showoutlinethicknesspopup=!this.showoutlinethicknesspopup
      }
      this.showoutlinestylepopup = false;
    
    }

    
setlinestyle1(linestyle){
  console.log(linestyle)
  if(linestyle=='dashed'){
    this.selectedlinestyle1=[12,11]
    console.log(linestyle)
  }
  else if(linestyle=='dotted'){
    this.selectedlinestyle1=[1,6]
    console.log(linestyle)
  }
  else if(linestyle=='line'){
    this.selectedlinestyle1=[0];
    console.log(linestyle)
  }
  const polystyle=new Style({
    stroke: new Stroke({
      color: DepictionComponent.setoutlinecolorforMP1,
      width: this.selectedthickness1,
      lineDash: this.selectedlinestyle1,
    }),
    fill: new Fill({
      color: this.setfillcolor,
    }),
  })
  
  
  this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
  if (this.layer.name === currentLayer.values_.name) {
    console.log(currentLayer,"inbasemapservice")
    currentLayer.setStyle(polystyle)
  }
  });
  console.log(this.selectedlinestyle,"selected thickness")
  this.showoutlinestylepopup1=!this.showoutlinestylepopup1
  if(this.showoutlinethicknesspopup1==true){
    this.showoutlinethicknesspopup1=!this.showoutlinethicknesspopup1
  }

}

selectsymbol(id){
  console.log(id,"selectedimageid")
  this.selectedsymbolurl=this.symbols[id]
  this.firstsymbol=this.selectedsymbolurl
  this.http.get( this.selectedsymbolurl, {responseType: 'text'}).subscribe(svg => {   
    svg = svg.replace(/fill="[^"]*"/, `fill="${this.setfillcolor3}"`);
      this.svg=svg
      const polystyle=new Style({ 
        image: new Icon({         
          src: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.svg),
          scale: 0.7
        })
      })
      this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
        if (this.layer.name === currentLayer.values_.name) {
          console.log(currentLayer,"inbasemapservice")
          currentLayer.setStyle(polystyle)
        }
        });
  });
this.showsymbolpopup=!this.showsymbolpopup;
}

selectsymbolColorFun(fillColor: string) {
  this.setfillcolor3 = fillColor;
  const symbol = this.selectedsymbolurl || '../../../../assets/images/circle.svg';
  this.updateSymbolStyle(symbol, fillColor);
}


updateSymbolStyle(symbolUrl: string, fillColor: string) {
  this.http.get(symbolUrl, { responseType: 'text' }).subscribe(svg => {
      svg = svg.replace(/fill="[^"]*"/, `fill="${fillColor}"`);
      this.svg = svg;
      this.applyStyleToLayer({
          image: new Icon({
              src: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
              scale: 0.7
          })
      });
  });
}
applyStyleToLayer(styleConfig: any) {
  const polystyle = new Style(styleConfig);
  this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
      if (this.layer.name === currentLayer.values_.name) {
          currentLayer.setStyle(polystyle);
      }
  });
}


  /*openModal(tab: string) {
    this.isModalOpen = true;
    this.setActiveTab(tab, this.layer);
    this.initializeAllTabs(this.layer);
    this.setDepictionLayerType(); 
    this.initializeAllTabs(this.layer); 
  }*/

  setDepictionValues(depictionProperties: any): void {
    this.depProperties = [];
    Object.entries(depictionProperties).forEach(([key, value]) => {
        const jsonObj = { title: key, value };
        this.depProperties.push(jsonObj);
    });
}

  initializeAllTabs(layerObj: any) {
    if (!this.connProperties.length) {
        this.modalData = this._setConnectionPropertyJson(layerObj);
        this.setPropertyValues(this.modalData);
    }

    if (!this.specProperties.length) {
        this.modalData = this._setSpecificationPropertyJson(layerObj);
        this.setSpecificationValues(this.modalData);
    }

    if (!this.funProperties.length) {
        this.modalData = this._setFunctionPropertyJson(layerObj);
        this.setFunctionValues(this.modalData);
    }

    if (!this.depProperties.length) {
        this.modalData = this._setDepictionPropertyJson(layerObj);
        this.setDepictionValues(this.modalData);
    }

    if (!this.intProperties.length) {
        this.modalData = this._setInteractionPropertyJson(layerObj);
        this.setInteractionValues(this.modalData);
    }
  }
  getTabName(): string {
    return this.tabDetails[this.activeTab]?.name || '';
  }

  getTabColor(): string {
    return this.tabDetails[this.activeTab]?.color || '#000';
  }

  getHeaderClass(): string {
    return this.tabDetails[this.activeTab]?.headerClass || '';
  }



  setActiveTab(tab: string, layerObj: any) {
    this.activeTab = tab;
    console.log('Active Tab set to:', tab);
    
    switch (tab) {
      case 'C':
        this.modalData = this._setConnectionPropertyJson(layerObj);
        this.setPropertyValues(this.modalData);
        break;
      case 'S':
        this.modalData = this._setSpecificationPropertyJson(layerObj);
        this.setSpecificationValues(this.modalData);
        break;
      case 'F':
        this.modalData = this._setFunctionPropertyJson(layerObj);
        this.setFunctionValues(this.modalData);
        break;
      case 'D':
        this.modalData = this._setDepictionPropertyJson(layerObj);
        this.setDepictionValues(this.modalData);
        this.cdr.detectChanges();  
        break;
        
      case 'I':
        this.modalData = this._setInteractionPropertyJson(layerObj);
        this.setInteractionValues(this.modalData);
        break;
      default:
        break;
    }
  }
  
  loadTabData(tab: string) {
    switch (tab) {
      case 'C':
        this.setPropertyValues(this.connProperties);
        break;
      case 'S':
        this.setSpecificationValues(this.specProperties);
        break;
      case 'F':
        this.setFunctionValues(this.funProperties);
        break;
      case 'D':
        this.setDepictionValues(this.depProperties);
        break;
      case 'I':
        this.setInteractionValues(this.intProperties);
        break;
    }
  }
  
  

  @HostListener('window:keyup.esc', ['$event'])
  keyEvent(event: KeyboardEvent): any {
    console.log('esc clicked!! geotower item component ', event);
    this.layersList.forEach(element => {
      console.log(element);
      console.log('IF');
      element.selected = false;
    });
  }
  /* getGeobaseInfo(): any {
  this.geotowerService.clientObjList.forEach((clientObj) => {
  this.layersList.push(clientObj);
  });
  if (!this.isGuest) {
  // here default geobase getting.. how to find is default or selected geobase session??
  console.log('checking the default session or not condition', this.sessionId);
  let geobaseId = 0;
  let isDefault = true;
  if (this.sessionId > 0) {
    geobaseId = this.sessionId;
    isDefault = false;
  }
  console.log('is default session or opened new session?? ', this.sessionId, geobaseId, isDefault);
  this.geobaseService.getGeobase(geobaseId, isDefault)
  .subscribe(geobaseInfo => {
    console.log('Got default geobaseInfo info in tower item');
    console.log(geobaseInfo);
    if (!this.commonService.isValid(geobaseInfo)) {
      console.log('No geobaseInfo present');
      // no default geobase for this user..
    } else {
      console.log('geobaseInfo present', geobaseInfo, geobaseInfo.sessionId, geobaseInfo.towerId);
      this._getTowerLayerList(geobaseInfo.towerId);
    }
  }, error => {
    console.log('Error while getting geobaseInfo');
    console.log(error);
    if (error.errorCode === 500) {
    }
    this._getTowerLayerList('');
  });
  }
  } */

  _getTowerLayerList(towerId): any {
    const tempList = [];
    if (!this.isGuest) {
      this.geotowerService.clientObjList.forEach((clientObj) => {
        // this.layersList.push(clientObj);
        tempList.push(clientObj);
      });
    }
    this.layersList = tempList;
    // Here will getAllLayers call API
    console.log('Here calling tower-item-option layers list ', this.isGuest, towerId, this.globalObject);
    if (!this.isGuest || this.globalObject.pageType === 'share') {
      console.log('In getUserWorkSpace');
      const email = sessionStorage.getItem('email');
      if (this.globalObject.pageType === 'share') {
        towerId = this.globalObject.geobase.towerId;
      }
      if (this.commonService.isValid(towerId)) {
        this.layersService.getTowerIncludeLayers(towerId, this.isGuest)
          .subscribe(workspaceRes => {
            console.log('Got workspace info');
            console.log(workspaceRes);
            if (!this.commonService.isValid(workspaceRes)) {
              console.log('No workspace present');
            } else {
              console.log('Workspace present');
              let layerList = workspaceRes[0].listOfLayers;
              if (layerList.length > 0) {
                layerList.forEach(layerObj => {
                  layerObj.active = false;
                  layerObj.selected = false;
                  layerObj.previewLayer = false;
                  layerObj.isServer = true;
                  const index: number = this.geotowerService.prevActiveServerLayersList.
                    findIndex(layerName => layerName === layerObj.name);

                  if (index !== -1) {
                    console.log('prev active layer ', layerObj.name, this.geotowerService.prevActiveServerLayersList);
                    layerObj.previewLayer = true;
                  }
                  layerObj.firebaseUrl = layerObj.url;
                  // this.layersList.push(layerObj);
                });
                layerList = this.commonService.sortByDesc(layerList);

                layerList.forEach(layerObj => {
                  this.layersList.push(layerObj);
                });
                // if (this.isGeotowerActive){
                if (this.commonService.isValid(this.geoRefLayerDataToShow)) {
                  if (this.commonService.isValid(this.geoRefLayerDataToShow.layerObj)) {
                    if (this.commonService.isValid(this.geoRefLayerDataToShow.layerObj.name)) {
                      const layerIndex = this.layersList.findIndex(layer => layer.name === this.geoRefLayerDataToShow.layerObj.name);
                      console.log(layerIndex);
                      console.log(this.layersList[layerIndex]);
                      if (layerIndex !== -1) {
                        this.showLayer(this.layersList[layerIndex], true);
                        this.geoRefLayerDataToShow = {};
                      }
                    }
                  }
                }
              }
              this.layersCount.emit(this.layersList.length);
            }
            this.towerReloaded.emit(String(new Date().getTime()));
          }, error => {
            console.log('Error while getting workspace');
            console.log(error);
            if (error.errorCode === 500) {
            }
            this.towerReloaded.emit(String(new Date().getTime()));
          });
      } else {
        this.towerReloaded.emit(String(new Date().getTime()));
      }
      this.geotowerService.geotowerLayersList = this.layersList;
    } else {
      this.towerReloaded.emit(String(new Date().getTime()));
    }
    this.layersCount.emit(this.layersList.length);
  }


  public _layerOptionActivateEvent(event, layerGroupObj, maximizeLayerOptions: boolean): any {
    console.log('....', layerGroupObj, layerGroupObj.zipfile);
    console.log(this);
    console.log(this.layersList);
    // this.layerItemOptActive.emit(layerGroupObj);
    console.log(layerGroupObj.selected);
    layerGroupObj.selected = maximizeLayerOptions;
    const index = this.layersList.findIndex(layer => layer.layerId === layerGroupObj.layerId);
    if (index !== -1) {
      this.layersList[index].selected = layerGroupObj.selected;
    }
    console.log(layerGroupObj.selected);
    let val = false;
    for (const layer of this.layersList) {
      if (layer.selected) {
        console.log("LAYER SELECTED");
        val = true;
        break;
      }
      else {
        console.log("LAYER NOT SELECTED");
      }
    }
    console.log(val);
    this.isAnyLayerOptionsActive.emit(val);
  }

  previewIsActiveEmit(e): any {
    console.log('IN previewIsActiveEmit');
    console.log(e);
    const previewIsActive = e.previewIsActive;
    this.previewLayer = e.layerData.name;
    this.layersList.forEach(element => {
      console.log(element);
      if (previewIsActive && this.previewLayer === element.name) {
        console.log('IF');
        element.previewLayer = true;
      } else if (!previewIsActive && this.previewLayer === element.name) {
        console.log('ELSE');
        element.previewLayer = false;
      }
    });
  }

/*  showItemPropertyWindow(event, layerObj, from): any {
    console.log(event);
    console.log(layerObj, from);
    alert("Connection Here!!")
    if (this.basemap.getCurrentBasemap().getOverlays().array_.length > 0) {
      this.basemap.getCurrentBasemap().getOverlays().array_.forEach(overLayObj => {
        console.log(overLayObj, "drtfyghjk")
        if (overLayObj.id === 'CSFDI') {
          this.basemap.getCurrentBasemap().removeOverlay(overLayObj);
        }
      });
    }
    let overLay;
    const itemHeaderName = from; */
    //console.log(layerObj, layerObj.proj, layerObj.metadata[0], "testing layer obj");
    // console.log(layerObj.proj.split('PROJCS')[1], ' results ', layerObj.proj.split('PROJCS')[0]);
    /* console.log((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1], ' res ', (layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[0]);
    console.log(((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1], ' results ',
    (((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1]).split('PROJECTION')[1]);
    console.log((((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1]).split('PROJECTION')[1].split(''')[1]); */
    /*if (itemHeaderName === this.CONNECTION_HEADER) {
      if (this.commonService.isValid(layerObj.proj)) {
        const dialogRef = this.dialog.open(CsfdiDialogComponent, {
          width: '300px',
          height: '300px',
          data: { title: from } // Passing the title for context if needed
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
    
        alert("Connection here! 123")
        this.connectionComp.setPropertyValues(this._setConnectionPropertyJson(layerObj));
        overLay = this.connectionComp.getConnectionPopup();
      }
    } else if (itemHeaderName === this.DEPICTION_HEADER) {
      if (layerObj.fileType == '.zip') {
        if (layerObj.metadata[0].features[0].geometry.type == "Multipolygon" || "Polygon") {
          TowerItemComponent.selectedLayergeometrictype = layerObj.metadata[0].features[0].geometry.type;
          console.log(layerObj, 'layer obj depiction')
          console.log(this.setcolor, "check colorrr")
          TowerItemComponent.towertodepiction = layerObj
        } else if (layerObj.metadata[0].features[0].geometry.type == "Point") {
          TowerItemComponent.selectedLayergeometrictype = layerObj.metadata[0].features[0].geometry.type;
        } else if (layerObj.metadata[0].features[0].geometry.type == "LineString" || "PolyLine") {
          TowerItemComponent.selectedLayergeometrictype = layerObj.metadata[0].features[0].geometry.type;
        } else {
          TowerItemComponent.selectedLayergeometrictype = "Notgeometric"
        }
      } else {
        TowerItemComponent.selectedLayergeometrictype = "Notgeometric"
      }
      if(layerObj.fileType == '.xlsx') {
        TowerItemComponent.selectedLayergeometrictype = 'Point';
        TowerItemComponent.towertodepiction = layerObj
      }
      this.depictionComp.setPropertyValues(this._setDepictionPropertyJson());
      overLay = this.depictionComp.getDepctionPopup();
    } else if (itemHeaderName === this.FUNCTION_HEADER) {
      this.functionComp.setPropertyValues(this._setFunctionPropertyJson());
      overLay = this.functionComp.getFunctionPopup();
    } else if (itemHeaderName === this.INTERACTION_HEADER) {
      this.interactionComp.setPropertyValues(this._setInteractionPropertyJson(layerObj));
      overLay = this.interactionComp.getInteractionPopup();
    } else if (itemHeaderName === this.SPECIFICATION_HEADER) {
      this.specificationComp.setPropertyValues(this._setSpecificationPropertyJson(layerObj));
      overLay = this.specificationComp.getSpecificationPopup();
    }
    if (this.commonService.isValid(overLay)) {
      console.log(this.basemap.getCurrentBasemap().getView().getCenter(), "testlocations");


      overLay.setPosition(this.basemap.getCurrentBasemap().getView().getCenter());

      // overLay.setPosition(0, window.innerHeight - overLay.clientHeight, 0);
      overLay.getElement().classList.add("popup-left");
      overLay.id = 'CSFDI';
      this.basemap.getCurrentBasemap().addOverlay(overLay);
    }
  }*/

    depictionData: any = {};


showItemPropertyWindow(event: any, layerObj: any, from: string, tab: string): any {
  this.openModal(tab);  
  this.setActiveTab(tab, layerObj);  
}
 
  
    setFunctionValues(functionProperties: any): void {
      this.funProperties = [];
      Object.entries(functionProperties).forEach(([key, value]) => {
        this.funProperties.push({
          title: key,
          value: value
        });
      });
      console.log('Mapped Function Properties:', this.funProperties);
    }
  
    setInteractionValues(interactionProperties: any): void {
      this.intProperties = [];
      Object.entries(interactionProperties).forEach(([key, value]) => {
        this.intProperties.push({
          title: key,
          value: value
        });
      });
      console.log('Mapped Interaction Properties:', this.intProperties);
    }
  
    setPropertyValues(connectionProperties): void {
      this.connProperties = [];
      Object.entries(connectionProperties).forEach(([key, value]) => {
        this.connProperties.push({
          title: key,
          value: value
        });
      });
      console.log('Mapped Connection Properties:', this.connProperties);  
    }
  
    setSpecificationValues(specificationProperties): void {
      this.specProperties = [];
      Object.entries(specificationProperties).forEach(([key, value]) => {
        this.specProperties.push({
          title: key,
          value: value
        });
      });
      console.log('Mapped Specification Properties:', this.specProperties);
    }


    closeModal(): void {
      this.isModalOpen = false;  
    }
  
  isValid(str): any {
    return this.commonService.isValid(str);
  }

  _setConnectionPropertyJson(layerObj): any {
    let projection = '-';
    let datum = '-';
    let prjCoodSys = '-';

    if (layerObj.proj.split('PROJCS')[1] === undefined) {
      datum = ((layerObj.proj.split('PROJCS')[0]).split('GEOGCS')[1]).split('DATUM')[1].split('"')[1];
    } else {
      projection = (((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1]).split('PROJECTION')[1].split('"')[1];
      datum = ((layerObj.proj.split('PROJCS')[1]).split('GEOGCS')[1]).split('DATUM')[1].split('"')[1];
      prjCoodSys = layerObj.proj.split('PROJCS')[1].split('"')[1];
    }
    return {
      ReferenceType: '',
      Datum: datum,
      Projection: projection,
      CoordinateSystem: prjCoodSys,
      Transformation: '-',
      GeoFrame: 'OpenLayer',
      BoundingBox: ''
    };
  }

  _setSpecificationPropertyJson(layerObj): any {
    
    let storage = '';
    if (this.commonService.isValid(layerObj.metadata)) {
      if (this.commonService.isValid(layerObj.metadata[0])) {
        storage = layerObj.metadata[0].features[0].geometry.type;
      }
    }
    return {
      Represntation: 'Vector',
      Source: 'User',
      'File Name': layerObj.name,
      'File Location': '',
      Storage: storage,
      Specifics: ''
    };
  }
  onDepictionUpdated(updatedData: any) {
    this.depictionData = updatedData;
    console.log('Updated depiction data received from child:', this.depictionData);
  }

  /*_setDepictionPropertyJson(layerObj: any): any {
    return {
     'Outline Color': '#000000',
    'Fill Color': '#FFFFFF',
    'Geometry Type': 'MultiPolygon'
    };
  }*/
    _setDepictionPropertyJson(layerObj: any): any {
      const geometryType = layerObj.metadata?.[0]?.features[0]?.geometry?.type || 'Notgeometric';
      const defaultOutlineColor = this.outlinecolor || '#000000';
      const defaultFillColor = this.setfillcolor || '#FFFFFF';
      
      return {
        'Outline Color': defaultOutlineColor,
        'Fill Color': defaultFillColor,
        'Geometry Type': geometryType
      };
    }

  _setFunctionPropertyJson(layerObj: any): any {
    return {
      'Attributes/Bands': '',
      DataFilter: '-'
    };
  }

  _setInteractionPropertyJson(layerObj): any {
    return {
      'Date/Time Added': layerObj.timestamp,
      'User Initials': '',
      Gpad: ''
    };
  }
  getReverseList(list: any[]): any[] {
    return list.slice().reverse();
  }
  saveTowerLayerFun(event): any {
    this.saveTowerLayer.emit(event);
  }

  zoomToLayer(layer): any {
    console.log('Layer data for testing ', layer, this.basemap.getCurrentBasemap().getLayers());
    this.basemap.setLoadScaleLine();
    if (layer.previewLayer) {
      let extent = [];
      if (layer.isServer && layer.metadata !== null) {
        if (layer.type === 'zip') {
          console.log(layer.metadata);
          this.basemap.getCurrentBasemap().getView().fit(JSON.parse(layer.metadata));
          this.basemap.getCurrentBasemap().getView().setZoom(this.basemap.getCurrentBasemap().getView().getZoom() - 1);
        } else if (layer.type === 'kml') {
          this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
            if (layer.name === currentLayer.values_.name) {
              const extentValue = currentLayer.values_.source.getExtent();
              this.basemap.getCurrentBasemap().getView().fit(extentValue);
              this.basemap.getCurrentBasemap().getView().setZoom(this.basemap.getCurrentBasemap().getView().getZoom() - 1);
            }
          });
        } else {
          console.log(layer.metadata, layer.metadata.split(','), JSON.stringify(layer.metadata));
          let metadataInfo = layer.metadata;
          if (typeof metadataInfo === 'string' && metadataInfo.length > 0) {
            try {
              metadataInfo = window.JSON.parse(metadataInfo);
            } catch (e) {
              console.log(e);
            }
          }
          let centerPosition = [0, 0];
          if (metadataInfo.extent !== undefined) {
            extent = metadataInfo.extent;
            centerPosition = getCenter(extent);
          } else {
            extent = layer.metadata.split(',');
            centerPosition = getCenter(extent);
            /* extent.push(Number(extents[0]));
            extent.push(Number(extents[1])); */
          }
          /* this.basemap.getCurrentBasemap().values_.view.setCenter(centerPosition);
          this.basemap.getCurrentBasemap().getView().setZoom(8); */
          this.basemap.getCurrentBasemap().getView().fit(extent);
          this.basemap.getCurrentBasemap().getView().setZoom(this.basemap.getCurrentBasemap().getView().getZoom() - 1);
        }
      } else {
        this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
          console.log(currentLayer);
          if (layer.name === currentLayer.values_.name) {
            let extentValue: any;
            if (layer.fileType === 'url') {
              // extentValue = currentLayer.values_.extent;
              extentValue = currentLayer.values_.source.getExtent();
            } else if (layer.fileType === this.JPG_EXTENSION_CONSTANT||layer.fileType === this.JPEG_EXTENSION_CONSTANT) {
              extentValue = currentLayer.values_.source.getImageExtent();
            }else if (layer.fileType === this.TIF_EXTENSION_CONSTANT) {
              extentValue = currentLayer.values_.extent;
            } 
            else if (layer.fileType === this.PDF_EXTENSION_CONSTANT) {
              extentValue = currentLayer.values_.extent;
            } 
            else {
              extentValue = currentLayer.values_.source.getExtent();
            }
            this.basemap.getCurrentBasemap().getView().fit(extentValue);
            this.basemap.getCurrentBasemap().getView().setZoom(this.basemap.getCurrentBasemap().getView().getZoom() - 1);
          }
        });
      }
    } else {
      // alert('Please select Layer preview ');
      // alert('Please turn the layer on, before zooming to it.');
      this.showLayer(layer);

      setTimeout(() => {
        this.zoomToLayer(layer);
      }, 500);
    }
  }
  showLayer(layer, preview: boolean = null): any {
    // console.log(event);
    console.log(this);
    this.showlayervisibility = !this.showlayervisibility
    console.log(this.showlayervisibility, "showlayertest")
    console.log(layer.previewLayer);
    let previewLayer = !layer.previewLayer;

    if (this.commonService.isValid(preview)) {
      previewLayer = preview;
    }
    // let isLayerVisible = false;
    // this.basemap.getCurrentBasemap().getLayers().forEach(currentLayer => {
    //   console.log(currentLayer);
    //   if (layer.name === currentLayer.values_.name && currentLayer.values_.visible) {
    //     isLayerVisible = true;
    //   }
    // });
    // if (isLayerVisible) {
    //   console.log('LAYER VISIBLE. SO SET IT TO INVISIBLE');
    //   previewLayer = false;
    // } else {
    //   console.log('LAYER NOT VISIBLE. SO SET IT TO VISIBLE');
    //   previewLayer = true;
    // }

    // console.log('what is the slider value ', this.sliderValue);
    const options = {
      previewIsActive: previewLayer,
      layerObj: layer,
      // previewIsActiveEmit: this.previewIsActiveEmit,
      mapLayersList: this.mapLayersList, geotowerService: this.geotowerService, sliderValue: this.sliderValue
    };
    this.previewIsActiveEmit({
      previewIsActive: previewLayer,
      layerData: layer
    });
    // this.ngProgress.start();
    // if (!this.previewIsActive) {
    //   this.previewIsActive = true;
    // } else {
    //   this.previewIsActive = false;
    // }
    const index: number = this.geotowerService.prevActiveServerLayersList.
      findIndex(layerName => layerName === layer.name);

    if (index !== -1) {
      console.log('removing the prevLayer ', layer.name, this.geotowerService.prevActiveServerLayersList);
      this.geotowerService.prevActiveServerLayersList.splice(index, 1);
    }
    if (layer.previewLayer) {
      this.geotowerService.prevActiveServerLayersList.push(layer.name);
    }
    this.geotowerService.activateEvent(options, 'DisplayLayer');

  }

  isConnectionDataAvailable(layer): boolean {
    // console.log('testing c-disable ', layer)
    if (layer.fileType === '.jpg') {
      if (this.commonService.isValid(layer.metadata.geodata)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  showPreviewFun(layer): void {
    this.showpreview = !this.showpreview
    console.log(layer);
    // if (layer.fileType === '.jpg'){
    //   if (this.commonService.isValid(layer.metadata.geodata)){
    //     console.log('DON\'T SHOW PREVIEW');
    //   } else {
    console.log('SHOW PREVIEW');
    this.selectedLayerForPreview = layer.name;
    console.log(this.selectedLayerForPreview, 'SHOW PREVIEW');
    this.showPreview.emit({ layer, show: true });
    //   }
    // } else {
    //   console.log('DON\'T SHOW PREVIEW');
    // }
  }

  showLayerCapFun(): any {
    if (this.layerIndex > 0) {
      if (this.layersList[this.layerIndex - 1].maximized) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (this.layerIndex === 0) {
      return true;
    }
    else {
      return false;
    }
  }
  previewORgrouplayer() {
    console.log('Long press triggered');
    this.searchorhand = !this.searchorhand;
  }
  showlayerORdeletelayer() {
    this.showlayerordeletelayer = !this.showlayerordeletelayer;
  }
  tableORdatabase() {
    this.tableordatabase = !this.tableordatabase;
  }
  selectionORlabel() {
    this.selectionorlabel = !this.selectionorlabel;
  }
  displayingLables(layer, isgeotables) {
    // Doing operation for geometry properties and adding lables
    console.log('lables adding layer is ', layer)
    console.log('what is event and layer data ', layer);
    const featureProperties = [];
    const featurePropertieHeders = [];
    if(layer.fileType !== '.zip') {
      this.basemap.getCurrentBasemap().getLayers().forEach((layerObj) => {
        if (layerObj.values_.name === layer.name) {
          console.log(layerObj)
          console.log('features ', layerObj.getSource().getFeatures())
          layerObj.getSource().getFeatures().forEach((feature, index) => {
            const featureAttr = (layer.fileType === '.xlsx') ? feature.values_.id : feature.values_;
            if (index === 0) {
              for (const [key, value] of Object.entries(featureAttr)) {
                console.log(key,value)
                featurePropertieHeders.push(key);
              }
            }
            featureProperties.push(featureAttr);
          });
        }
      })
    } else {
      layer.metadata[0].features.forEach((feature, index) => {
        if (index === 0) {
          for (const [key, value] of Object.entries(feature.properties)) {
            featurePropertieHeders.push(key);
          }
        }
        featureProperties.push(feature.properties);
      });
    }
    // this.basemap.getCurrentBasemap().getLayers().forEach((layerObj) => {
    //   if (layerObj.values_.name === layer.name) {
    //     console.log(layerObj)
    //     console.log('features ', layerObj.getSource().getFeatures())
    //     layerObj.getSource().getFeatures().forEach((feature, index) => {
    //       const featureAttr = (layer.fileType === '.xlsx') ? feature.id : feature.values_;
    //       if (index === 0) {
    //         for (const [key, value] of Object.entries(featureAttr)) {
    //           console.log(key,value)
    //           featurePropertieHeders.push(key);
    //         }
    //       }
    //       featureProperties.push(featureAttr);
    //     });
    //   }
    // })
    // layer.metadata[0].features.forEach((feature, index) => {
    //   if (index === 0) {
    //     for (const [key, value] of Object.entries(feature.properties)) {
    //       featurePropertieHeders.push(key);
    //     }
    //   }
    //   featureProperties.push(feature.properties);
    // });
    console.log('final dbf data is ', featureProperties, featurePropertieHeders);
    if(isgeotables) {
      if (this.basemap.getCurrentBasemap().getOverlays().array_.length > 0) {
        this.basemap.getCurrentBasemap().getOverlays().array_.forEach(overLayObj => {
          if (overLayObj.id === 'geotablepopup') {
            this.basemap.getCurrentBasemap().removeOverlay(overLayObj);
          }
        });
      }
      let overLay;
      // this.labledropdownComp.setPropertyValues(featureProperties, featurePropertieHeders);
      overLay = this.geotablesComp.getgeotablesPopup(featureProperties, featurePropertieHeders, layer);
      overLay.setPosition(this.basemap.getCurrentBasemap().getView().getCenter());
      overLay.id = 'geotablepopup';
      this.basemap.getCurrentBasemap().addOverlay(overLay);
    } else {
      if (this.basemap.getCurrentBasemap().getOverlays().array_.length > 0) {
        this.basemap.getCurrentBasemap().getOverlays().array_.forEach(overLayObj => {
          if (overLayObj.id === 'labledropdown') {
            this.basemap.getCurrentBasemap().removeOverlay(overLayObj);
          }
        });
      }
      let overLay;
      // this.labledropdownComp.setPropertyValues(featureProperties, featurePropertieHeders);
      overLay = this.labledropdownComp.getlabledropdownPopup(featurePropertieHeders, layer);
      overLay.setPosition(this.basemap.getCurrentBasemap().getView().getCenter());
      overLay.id = 'labledropdown';
      this.basemap.getCurrentBasemap().addOverlay(overLay);

    }
    // this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
    //   if (layerObj !== undefined) {
    //     if (layerObj.values_.name === layer.name) {
    //       console.log('current layer ', layerObj, layerObj.getSource());
    //       console.log('features ', layerObj.getSource().getFeatures())
    //       console.log('styles ', layerObj.getStyle())          
    //       layerObj.getStyle().setText(
    //          new Text({
    //           font: '12px Calibri,sans-serif',
    //           fill: new Fill({ color: '#000' }),
    //           stroke: new Stroke({
    //             color: '#fff', width: 2
    //           }),
    //           // get the text from the feature - `this` is ol.Feature
    //           // and show only under certain resolution
    //           // text: this.basemap.getCurrentBasemap().getView().getZoom() > 12 ? this.get('description') : ''
    //           text: "0.12354" //Feature.get('SHAPE_Leng')
    //         })
          
    //       )
    //     }
    //   }
    // });
  }
  zoomORpan() {
    this.zoomorpan = !this.zoomorpan;
  }
  pantolayer() {
    console.log("i am pan")
    this.showPanToLayer.emit(true);
    TowerItemOptionsComponent.panToLayer(this.basemap, this.layer)
  }
  deletelayer() {
    console.log(this.layer.layerId, "i am delete")
    TowerItemOptionsComponent.deleteLayer(this.layer, this.towerId, this.layersService, this.geotowerService, this.basemap, {})
    this.basemap.deletLayerFromGeotower(this.layer);

  }
  savelayer(event) {
    TowerItemOptionsComponent.saveLayer(this.layer, this.basemap)
    this.saveTowerLayerFun(TowerItemOptionsComponent.options)
  }
  showDBFData(layer, basemapser): any {
    // this.showDBFtable = true;
    console.log('what is event and layer data ', layer);
    const featureProperties = [];
    const featurePropertieHeders = [];
    layer.metadata[0].features.forEach((feature, index) => {
      if (index === 0) {
        for (const [key, value] of Object.entries(feature.properties)) {
          featurePropertieHeders.push(key);
        }
      }
      featureProperties.push(feature.properties);
    });
    console.log('final dbf data is ', featureProperties, featurePropertieHeders);
    if (basemapser.getCurrentBasemap().getOverlays().array_.length > 0) {
      basemapser.getCurrentBasemap().getOverlays().array_.forEach(overLayObj => {
        if (overLayObj.id === 'dbftable') {
          basemapser.getCurrentBasemap().removeOverlay(overLayObj);
        }
      });
    }
    let overLay;
    this.dbftableComp.setPropertyValues(featureProperties, featurePropertieHeders, layer);
    overLay = this.dbftableComp.getdbfTablePopup();
    overLay.setPosition(basemapser.getCurrentBasemap().getView().getCenter());
    overLay.id = 'dbftable';
    basemapser.getCurrentBasemap().addOverlay(overLay);
    // const popupComp = this.popupComponent;
    // this._overlay = popupComp.getGeoPopup();
    // popupComp.setContent('multi-layer-info', featureProperties);
    // this._overlay.setPosition(evt.coordinate);
    // this.basemap.addOverlay(this._overlay);
  }
  tables(event) {
    console.log(event.ctrlKey)
    if(event.ctrlKey) {
      console.log('ctrl key clicked');
      this.displayingLables(this.layer, true);
    } else {
      this.showDBFData(this.layer, this.basemap);
    }
    // TowerItemOptionsComponent.showDBFData(this.layer,this.basemap);
    // const url = 'http://143.198.234.223/download';

    //   this.http.get(url, { responseType: 'arraybuffer' })
    //     .subscribe(response => {
    //       // Convert the file contents to a string
    //       const fileContents = String.fromCharCode.apply(null, new Uint8Array(response));

    //       // Save the file contents in the browser's cache
    //       localStorage.setItem('cachedFile', fileContents);

    //       console.log('File downloaded and cached successfully');
    //     });
  }
  onScroll(event) {
    const target = event.target as HTMLDivElement; // Get the target element that fired the event
    // Check the direction of scroll
    if (target.scrollTop > 0) {
      // Scrolling down
      this.value--;
    } else {
      // Scrolling up
      this.value++;
    }
  }
  layerSlider(event): any {
    const sliderValue = event.target.value;
    this.sliderValue = event.target.value;
    this.backgroundcolorvalue = this.sliderValue;
    this.backgroundcolorwhitevalue = 100 - this.sliderValue;
    console.log(this.sliderValue, "sliderValue")
    this.sliderVal.emit(sliderValue);
    const layersLength = this.basemap.getCurrentBasemap().getLayers().getLength();
    if (layersLength > 0) {
      this.basemap.getCurrentBasemap().getLayers().forEach(layerObj => {
        if (layerObj !== undefined) {
          if (layerObj.values_.name === this.layer.name) {
            console.log('current layer ', layerObj, sliderValue);
            layerObj.setOpacity(event.target.value / 100);
          }
        }
      });
    }
  }
  dragfeature(drag) {
    TowerItemComponent.isdrag = drag;
    this.dragen = drag;
    console.log(TowerItemComponent.isdrag, "this is drag feature value")
    this.DragvalueChanged.emit(this.dragen);
    if (drag == false) {
      this.showslidervalue = true;
    }
    else {
      this.showslidervalue = false;
    }
  }

  // Here python  script rest API calling
  pythonScriptAPI(layerObj): any {
    const fileInput: any = layerObj;
    console.log(fileInput.zipfile);
    const formdata = new FormData();
    formdata.append("file", fileInput.zipfile, fileInput.name);
    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    // $('.loader').fadeIn()
    fetch("http://146.190.140.219:5000", {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        // this.rasterFileClickEvent()
        console.log(result, this.rasters)
        // 
        this.rasters = result;
        this.layerInfoRestAPI(result)
        // this.rasterFileClickEvent()
        // let allLayer = map.getAllLayers()
        // allLayer.forEach(layer => {
        //   if(layer.getProperties().title != undefined){
        //     map.removeLayer(layer)
        //   }
        // });
        if(fileInput.name.includes('.zip') || fileInput.name.includes('.kml') || fileInput.name.includes('.geojson')){
          let vectorLayer = fileInput.name.split('.')[0]
          // this.loadWms(fileInput.name, '')
          // document.getElementById("vector-layer").style.display = "block";
        }
        else{
          // document.getElementById("vector-layer").style.display = "none";
          // this.publish(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  layerInfoRestAPI(fileName):any {
    let url = "http://146.190.140.219:5000/info?filename=" + fileName
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        console.log(result.length)
        console.log(result[0], result[1])
        this.loadShpRestAPI(result[0], 'Elevation')
      })
  }

  loadShpRestAPI(shpFilePath, elevationName): any {
    let url = "http://146.190.140.219:5000/loadshp?shp_file_path=" + shpFilePath + "&elev=" + elevationName
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.demRestAPI(result[0], result[1], result[2])
      })

  }
  demRestAPI(fileName, epsg, elevationIndex):any {
    let url = "http://146.190.140.219:5000/dem?filename=" + fileName + "&utm_zone=" + epsg + "&elevation_index=" + elevationIndex
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        console.log(result)
        window.alert('Firebase URL ' + result)
        this.publish(result)
      })
  }

  // rasterFileClickEvent(): any {
  //   console.log(this.rasters)
  //   var requestOptions = {
  //     method: 'GET',
  //     redirect: 'follow'
  //   };
  //   let url = "http://146.190.140.219:5000/dem?filename=" + "/root/uploadFile/extract/" + this.rasters + ".shp"
  //   // $('.loader').fadeIn()
  //   fetch(url, {
  //     method: 'GET',
  //     redirect: 'follow'
  //   })
  //     .then(response => response.text())
  //     .then(result => {
  //       console.log(result)
  //       // 
  //       this.publish(result)
  //     })
  // }
  publish(filename){  
    console.log(filename)
    var formdata = new FormData(); 
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    let url = "http://146.190.140.219:5000/publish?filename=" + filename
    fetch(url, {
      method: 'GET',
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        // 
        console.log(result)
        // this.loadWms(result, '')
      })
      .catch(error => console.log('error', error));
  }
  
  loadWms(layerName, vector_flag): any {
    const layer = 'giskernel:' + layerName;
    const tiled = new TileLayer({
      visible: true,
      title: layerName,
      source: new TileWMS({
        url: 'https://qa.fuse.earth:4433/geoserver/giskernel/wms',
        params: {'FORMAT': 'image/png', 
                 'VERSION': '1.1.1',
                 tiled: true,
              "STYLES": '',
              "LAYERS": layer,
              "exceptions": 'application/vnd.ogc.se_inimage',
           tilesOrigin: -104.92041666666667 + "," + 36.59374999999999
        },
        projection: 'EPSG:4326',
      }),
      zIndex:2
    });
  
    
    // LayerGr.getLayers().push(tiled);
    // document.getElementById("raster-layer").style.display = "block";
    this.basemap.getCurrentBasemap().addLayer(tiled)
    // $('.loader').fadeOut()
  }
  selectionToggle(): any {
    this.selectionToogle = !this.selectionToogle;
    this.commonService.isSelectionActive = this.selectionToogle;
    // this.commonService.setIsSelectionActive(this.selectionToogle);
    console.log('Selection Toogle ', this.selectionToogle, this.commonService.isSelectionActive);
  }
}