import { IBasemap } from './IBasemap';
import {Tile as TileLayer} from 'ol/layer.js';
import XYZ from 'ol/source/XYZ.js';

export class USGSTopo implements IBasemap {
  getMapTileLayer(): TileLayer {
    const source = new XYZ({
      url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
      
    });
    return new TileLayer({
      source: source,
      name: 'USGSTopo',
      visible: false
    });
  }
}
