
<div class="user-login-header">
    <div class="user-login-container" style="display: flex;">
      
      <div class="login-details-container">
        <img matTooltip="Welcome! Click to login and begin using fuse™" matTooltipClass="mat-custom-tooltip" *ngIf="isGuest"
          src="assets/svgs/new-logos/login_icon.svg" class="login-btn" (click)="showLoginPrompt = true"/>

        <div *ngIf="!isGuest" class="profile-data-container" (click)="updateTimeStamp();" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
          <img src="{{imageSrc}}" class="profile-img" (mousedown)="mouseDown()" (click)="showHideProfileData()"/>
        </div>
        <div [ngClass]="{'user-drop-down': true, 'align-right': pageType==='COVID19' }" *ngIf="showProfileData" (focusout)="hideDropDown($event)"
        (click)="updateTimeStamp();" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
       
        <div class="user-drop-down-body">
            
            <div class="user-detail-container">
              <div class="user-img-container">
                <img src={{imageSrc}}>
              </div>
              <div class="user-name-container">
                <p class="user-name" >{{ name }}</p>
                <p class="user-mail" >{{ email }}</p>
              </div>
            </div>
            <div class="org-name-container" *ngIf="userRole.toLowerCase() !== 'solve'">
              <p class="org-name">{{orgName}}</p>
              <p class="user-role">{{userRole | lowercase}}</p>
            </div>
            <div class="admin-ops-container" >
              <div class="admin-op" (click)="openProfileModal(); $event.stopPropagation()">
                <p>Profile</p>
              </div>
              <!-- *ngIf="userRole === 'USER_ADMIN'" -->
              <div *ngIf="userRole === 'USER_ADMIN'" class="admin-op" (click)="openPageFun('user-mgmt')">
                <p>User Management</p>
              </div>
              <!-- *ngIf="userRole === 'USER_ADMIN'" -->
              <div *ngIf="userRole === 'USER_ADMIN'" class="admin-op" (click)="openPageFun('proj-mgmt')">
                <p>Project Management</p>
              </div>
              <!-- *ngIf="userRole === 'USER_ADMIN'" -->
              <div *ngIf="userRole === 'USER_ADMIN'" class="admin-op"  (click)="openPageFun('team-mgmt')">
                <p>Team Management</p>
              </div>
              <!-- *ngIf="userRole === 'USER_ADMIN'" -->
              <div class="admin-op"  (click)="openSubscriptionModal()">
                <p>Subscription/Billing</p>
              </div>
            </div>
            <div class="logout-container" [ngStyle]="{'text-align': 'center'}">
                <button mat-raised-button (click)="signOut($event)">Logout</button>
            </div>
        </div>
    </div>

          <!-- Custom Tooltip -->
          <div class="custom-tooltip-container" *ngIf="showTooltip">
            <div class="msg-container">
              <p class="msg">Welcome, begin here by logging in!</p>
              <span class="close-icon" (click)="showTooltip=false;">&#10006;</span>
            </div>
            <div class="tooltip-arrow"></div>
          </div>
      </div>
      <!-- <div class="help-container">
        <a class="help-link" target="_blank" href="https://mapsolgeo.com/fuseearth/index.html">
          <img matTooltip="Help" matTooltipClass="mat-custom-tooltip" src="assets/images/login/help.png"/>
        </a>
      </div> -->
    </div>
</div>

<!--Profile model-->
<!--<div *ngIf="isProfileModalVisible" class="model" id="profileModal" tabindex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="profileModalLabel">
          <span class="material-icons">account_circle</span> Profile
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeProfileModal()"  aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <img [src]="imageSrc" class="rounded-circle mb-3" width="80" height="80">
        <h5 class="mb-2">{{ firstName }} {{ lastName }}</h5>
        <p class="text-muted mb-4">{{ email }}</p>

        <div class="mb-3">
          <label for="email" class="form-label fw-bold">Email:</label>
          <input type="text" class="form-control" id="email" value="{{ email }}" readonly>
        </div>
        <div class="mb-3">
          <label for="firstName" class="form-label fw-bold">First Name:</label>
          <input type="text" class="form-control" id="firstName" value="{{ firstName }}" readonly>
        </div>
        <div class="mb-3">
          <label for="lastName" class="form-label fw-bold">Last Name:</label>
          <input type="text" class="form-control" id="lastName" value="{{ lastName }}" readonly>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary w-100">Manage Details</button>
      </div>
    </div>
  </div>
</div>-->
<!-- Profile Modal -->
<div *ngIf="isProfileModalVisible" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="profileModalLabel" aria-hidden="true" style="display: block;" (click)="closeProfileModal()">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: rgba(252, 252, 252, 0.9); ">
    <div class="modal-header">
      <h5 class="modal-title d-flex align-items-center" id="profileModalLabel" style="font-size: 1rem;">
        <span class="material-icons">account_circle</span> Profile
      </h5>
      <button type="button" class="btn-close" (click)="closeProfileModal()" aria-label="Close"></button>
    </div>
    <div class="modal-body text-center">
      <img [src]="imageSrc" class="rounded-circle mb-3" width="80" height="80">
      <h5 class="mb-2">{{ firstName }} {{ middleName ? middleName + ' ' : '' }}{{ lastName }}</h5>
      <p class="text-muted mb-4">{{ email }}</p>
    
      <div class="mb-3 text-start">
        <label for="email" class="form-label fw-bold">Email:</label>
        <input type="text" class="form-control" id="email" value="{{ email }}" readonly>
      </div>
      <div class="mb-3 text-start">
        <label for="firstName" class="form-label fw-bold">First Name:</label>
        <input type="text" class="form-control" id="firstName" value="{{ firstName }}" readonly>
      </div>
      <div class="mb-3 text-start">
        <label for="middleName" class="form-label fw-bold">Middle Name:</label>
        <input type="text" class="form-control" id="middleName" value="{{ middleName }}" readonly>
      </div>
      <div class="mb-3 text-start">
        <label for="lastName" class="form-label fw-bold">Last Name:</label>
        <input type="text" class="form-control" id="lastName" value="{{ lastName }}" readonly>
      </div>
    </div>
    </div>
  </div>
</div>

<!-- Subscription Modal -->
<div id="subscriptionModal" class="modal fade" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 700px;"> 
    <div class="modal-content rounded-3" style="background-color: rgba(252, 252, 252, 0.9); min-height: 410px;"> 
      <div class="modal-header bg-white border-bottom-0 rounded-top p-1" style="padding-top: 10px; padding-bottom: 10px; display: flex; justify-content: space-between;">
        <h5 class="modal-title d-flex align-items-center" id="modalTitle" style="font-size: 1rem;">
          <span class="material-icons me-2"></span>
          Subscription and Billing
        </h5>
        <button type="button" class="close-btn" style="font-size: 1.5rem; font-weight: 100; background: none; border: none;" data-bs-dismiss="modal" aria-label="Close">
          &#10006;
        </button>
      </div>
      <div class="modal-body d-flex flex-column justify-content-center align-items-center text-center p-2">
        <img src="assets/svgs/coming_soon_icon.svg" alt="Coming Soon" class="mb-3" style="width: 120px; height: auto; margin-left: -25px;"> 
        <h2 class="mb-3">Coming Soon...</h2>

        <p class="mb-0">
          On this Subscription page, you can manage your billing, select packages, and check the details of your current plan. Easily view your package validity and make changes to suit your needs.
        </p>
      </div>
    </div>
  </div>
</div>


<!-- LOGIN PROMPT SCREEN START -->
<div class="login-prompt-container" *ngIf="showLoginPrompt">
  <div class="login-prompt-area">

    <!-- LOGIN PAGE -->
    <div class="screens-btns-container" *ngIf="mainPage === mainPages.LOGIN">
      <button class="close-btn" (click)="cancelLogin()">
        &#10006;
      </button>
      <div class="screens-image-container">
        <!-- <img class="tScreens" #tScreen [ngStyle]="{'margin-top': 'calc(50% - ' + (tScreen.height/2) + 'px)'}" 
            src="assets/images/login/3screens.png"> -->
        <div style="height: 25%;">

        </div>
        <div style="height: 50%;">
          <img class="tScreens" #tScreen [ngStyle]="{'margin-top': 'calc(20% - ' + (tScreen.height/2) + 'px)'}" 
              src="assets/svgs/new-logos/fe-logo.svg">
        </div>
        <div style="height: 25%;">
          <!-- <img class="org-logo" src="assets/svgs/new-logos/mapsol.svg"> -->
          <img class="org-logo" src="assets/image.png">
        </div>
      </div>
      <div class="logo-and-login-btn-container">
        <app-user-sign-up
          [authStatusMsgs]="authStatusMsgs"
          (showPage)="showPageFun($event)"
          (closeLogin)="cancelLogin()"
          (createOrg)="createOrganization($event)">

        </app-user-sign-up>
      </div>

    </div>

    <!-- CREATE ORGANIZATION PAGE -->
    <div class="screens-btns-container" style="background: transparent; padding: 0px"
        *ngIf="mainPage === mainPages.CREATE_ORG">
      <button class="close-btn" (click)="cancelLogin()">
        &#10006;
      </button>
      <div class="orgazaniation-container">
        <app-organization
          (closeLogin)="cancelLogin()"
          (showPage)="showPageFun($event)"
          style="height: 100%;
          ">
          
        </app-organization>
      </div>
    </div>
  </div>
</div>

<!-- LOGIN PROMPT SCREEN END -->