<div #container ngDraggable id="popup" class="ol-popup loc-pop-up-container" [style.min-width.px]="minWidth" >
  <!-- <div  cdkDrag [cdkDragFreeDragPosition]="dragPosition" #container id="popup" class="ol-popup loc-pop-up-container" [style.min-width.px]="minWidth" > -->
  <!-- <a #closer id="popup-closer" class="ol-popup-closer loc-pop-up-close"></a> -->
  <div id="popup-content" style="/*display: table-cell;*/">
      <div class="loc-popup-container">
        <!-- <div class="loc-popup-header">
          <span class="header-title">{{headerTitle}}</span>
          
        </div> -->
        <a #closer id="popup-closer" class="ol-popup-closer loc-pop-up-close" style="margin: -0.3rem -0.4rem;"></a>
        <div #content class="loc-popup-body" style="display: block;border: 2px solid loc-popup-body;border-radius: 12px;">
          
        </div>
      </div>
  </div>
</div>
<div #multiLayerContent class="multi-layer-content-cls">
  <ngb-tabset type="pills" [orientation]="vertical" class="tabset-cls">
    <ngb-tab *ngFor="let content of multiLayerContentList" class="tab-cls">
      <ng-template ngbTabTitle class="tab-title-cls">{{content.title}}</ng-template>
      <ng-template ngbTabContent class="tab-content-cls">
        <!-- <p [innerHTML]="getLayerContent(content.content, content.length, content.title, content.isCovidPage)"></p> -->
        <p [innerHTML]="content.tabContent"></p>
        <!-- <table class="table table-striped" [innerHTML]="getLayerContent(content.content, content.length)"> -->
            <!-- <tbody [innerHTML]="getLayerContent(content.content, content.length)"></tbody> -->
          <!-- </table> -->
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<div #featureContent>
  <table class="table table-striped">
    <thead>
    </thead>
    <tbody>
      <tr *ngFor="let feature of featureInfoContentList; index as i">
        <td>
          {{ feature.title }}
        </td>
        <td>: {{ feature.content }} <br> {{ feature.contentNextLine }} </td>
      </tr>
    </tbody>
  </table>
</div>
<div #radiusContent style="display: flex; width: 130px;">
  <input placeholder="{{units}}" (keyup.enter)="getRadiusValue($event, inputRadiusVal.value)" #inputRadiusVal type="text" 
    style="color: black; width: 62%; padding-left: 5px;border: none;" />
    <img src="assets/svgs/geo-tray/caret-down.svg" (click)="getUnitsChange()">
  <img (click)="getRadiusValue($event, inputRadiusVal.value)" 
        style="cursor: pointer; height: 20px; margin: 0.3rem 0.1rem;" 
        src="assets/images/carrot-right.png">
        <div class="row" *ngIf="showUnitsDropdown">
          <div style="background: #2B2E34;position: absolute;width: 40px;margin: 2rem -3rem;">
            <div class="row" *ngFor="let item of getAllunits" style="margin: 0;padding:0;margin-right: 0.2rem;">
              <div style="color: white;font-size: 12px;width: 100%;height: 100%;border-bottom: 1px solid white;text-align: right;" (click)="checkPopDropdownClickFunc(item.name)">{{item.name}}</div>
          </div>
          </div>
        
        </div>
</div>

<div style="background:lightgray;width:200px;height:150px;display: flex;">

</div>
<div #rotateContent>
  <input placeholder="deg" (keyup.enter)="getRotateValue($event, inputRotateVal.value)" #inputRotateVal type="text" 
    style="color: black; width: 50px;" />
  <img (click)="getRotateValue($event, inputRotateVal.value)" 
  style="cursor: pointer; padding-left: 5px;" 
  src="/assets/images/rotate-radius-icon.png">
</div>

<div #geopadSiteContent>
  <table class="table table-striped">
    <thead>
    </thead>
    <tbody>
      <tr>
        <td>
          Name
        </td>
        <td>: {{geopadSiteData.name}} </td>
      </tr>
      <!-- //   geopadSiteData.name: data.site.locationName,
      //   desc: data.site.description,
      //   location: data.site.latitudeLongitude,
      //   projectName: data.project.name,
      //   placeName: data.place.name,
      //   topicName: data.topic.name -->
    </tbody>
  </table>
</div>